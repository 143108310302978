import { Client, Conversation, JSONObject, Message, Participant } from "@twilio/conversations";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { map, shareReplay } from "rxjs/operators";

import { AxisFormData, MediaApi } from "@/api/media";
import { IParticipantsRepository } from "@/context/twilioContext/ChatConversation/IParticipantsRepository";
import { ITwilioConversation } from "@/context/twilioContext/ChatConversation/ITwilioConversation";
import { TwilioChatChannelAttributes } from "@/context/twilioContext/types/TwilioChatChannelAttributes";
import { TwilioChatMessageAttributes } from "@/context/twilioContext/types/TwilioChatMessageAttributes";
import { TwilioConversationEvent } from "@/context/twilioContext/types/TwilioConversationEvent";
import { RolesEnum } from "@/types/Roles.enum";

class TwilioConversation implements ITwilioConversation {
  private readonly uid: string;
  private readonly _conversation: Conversation;
  private readonly _unconsumedMessagesCount = new BehaviorSubject<number>(0);
  private _unconsumedMessagesCountPreviousValue = 0;
  private readonly _channelEvent$ = new Subject<TwilioConversationEvent>();
  private readonly _membersRepo: IParticipantsRepository;

  constructor(uid: string, conversation: Conversation, membersRepo: IParticipantsRepository) {
    this.uid = uid;
    this._conversation = conversation;
    this._membersRepo = membersRepo;
  }

  get channelEvent$(): Observable<TwilioConversationEvent> {
    return this._channelEvent$.asObservable();
  }

  get dateCreated(): Date | null {
    return this._conversation.dateCreated;
  }

  get sid(): string {
    return this._conversation.sid;
  }

  get friendlyName(): string | null {
    return this._conversation.friendlyName;
  }

  get attributes(): TwilioChatChannelAttributes {
    return this._conversation.attributes as TwilioChatChannelAttributes;
  }

  get unconsumedMessagesCount(): BehaviorSubject<number> {
    return this._unconsumedMessagesCount;
  }

  get unconsumedMessagesDelta(): Observable<number> {
    return this._unconsumedMessagesCount.pipe(
      map((newValue) => {
        const ret = newValue - this._unconsumedMessagesCountPreviousValue;
        this._unconsumedMessagesCountPreviousValue = newValue;
        return ret;
      }),
      shareReplay(1),
    );
  }

  public async initialize() {
    this._conversation.getUnreadMessagesCount().then((count) => {
      const newVal = count ?? 0;
      this._unconsumedMessagesCount.next(newVal);
    });
    this._conversation?.on(Conversation.messageAdded, (payload: Message) => {
      this._channelEvent$.next({ type: Client.messageAdded, payload });
    });
    this._conversation?.on(Conversation.messageUpdated, (payload) =>
      this._channelEvent$.next({ type: Conversation.messageUpdated, payload }),
    );
    this._conversation?.on(Conversation.participantJoined, (payload) => {
      this._channelEvent$.next({ type: Conversation.participantJoined, payload });
    });

    this._conversation?.on(Conversation.updated, (payload) =>
      this._channelEvent$.next({ type: Conversation.updated, payload }),
    );
    this._conversation?.on(Conversation.typingStarted, (payload) => {
      this._membersRepo.isTyping = true;
    });
    this._conversation?.on(Conversation.typingEnded, () => {
      this._membersRepo.isTyping = false;
    });
  }

  public async consumeMessages(): Promise<void> {
    try {
      await this._conversation?.setAllMessagesRead();
    } catch {
      this._unconsumedMessagesCount.next(0);
      return;
    }
    this._unconsumedMessagesCount.next(0);
  }

  public async getUnconsumedMessagesCount(): Promise<number | null> {
    return this._conversation.getUnreadMessagesCount();
  }

  public async getMessages(
    pageSize?: number,
    anchor?: number,
    direction?: "backwards" | "forward",
  ): ReturnType<typeof Conversation.prototype.getMessages> {
    return this._conversation.getMessages(pageSize, anchor, direction);
  }

  public async listParticipants(): Promise<Participant[]> {
    return this._conversation.getParticipants();
  }

  public async sendMessage(text: string | null, value: any, respondsTo: string, files?: File[]): Promise<void> {
    const msgText = text ? text.trim() : null;
    const messageAttributes: Partial<TwilioChatMessageAttributes> = {
      isHidden: !text,
      value,
      respondsTo,
    };
    if (msgText || value) {
      await this._conversation
        .sendMessage(msgText, messageAttributes as JSONObject)
        .catch((err) => console.error("SendMessage error: ", err))
        .finally(() => this.consumeMessages());
    } else if (files?.length) {
      await this.sendFiles(files);
    }
  }

  public async typing(): Promise<void> {
    await this._conversation.typing();
  }

  private async sendFiles(files: File[]): Promise<void> {
    if (!this._conversation) {
      console.error("TwilioChannel is not present.");
      return;
    }
    while (files.length) {
      const file = files[0];

      const user = this._membersRepo.listParticipants().find((member) => member.uid === this.uid);
      const formData = new FormData();
      formData.append("component", "chat");
      formData.append("componentId", this._conversation?.sid);
      formData.append("file", file);
      formData.append(
        "patientId",
        user?.role === RolesEnum.Patient
          ? this.uid
          : this._membersRepo.interlocutor?.uid
          ? this._membersRepo.interlocutor.uid
          : "unknown USER TEMP",
      );
      const data = await MediaApi.create(formData as AxisFormData);
      await this._conversation.sendMessage(data[0].name, {
        msgType: "gallery",
        url: data[0].url,
        isHidden: false,
      });
      files.shift();
    }
  }
}

export default TwilioConversation;
