<template>
  <div class="full-width d-inline-flex flex-column">
    <div class="placeholder align-self-center">
      <div class="placeholder-body">' '</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FloatingPlaceholderRow",
};
</script>

<style scoped lang="scss">
.placeholder {
  min-height: 30px;
  margin: 17px auto;
  border: 1px solid #e5e4e8;
  box-sizing: border-box;
  border-radius: 100px;
  opacity: 0;

  &-body {
    padding: 5px 16px 5px 16px;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 0.25px;
    color: #33343e;
    opacity: 0.66;
    text-align: center;
  }
}

.visible {
  opacity: 1;
  transition: all 0.2s;
}
</style>
