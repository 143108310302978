<template>
  <v-card class="private-note">
    <v-card-text class="private-note__text">{{ text }}</v-card-text>
    <v-card-text class="text--disabled">Last update {{ formatDateTime(sent) }}</v-card-text>
    <v-menu class="private-note__edit-menu" left offset-y top>
      <template v-slot:activator="{ on, attrs }">
        <v-icon class="private-note__edit-menu-btn" v-bind="attrs" v-on="on">mdi-dots-horizontal</v-icon>
      </template>
      <v-list dense>
        <v-list-item @click="editNote">
          <v-list-item-title class="private-note__edit action-btn">
            {{ $t("general.edit") }}
          </v-list-item-title>
        </v-list-item>
        <v-list-item @click="deleteNote">
          <v-list-item-title class="private-note__delete action-btn">
            {{ $t("general.delete") }}
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </v-card>
</template>

<script>
import moment from "moment-timezone";
import { mapActions, mapState } from "pinia";

import { useAuthStore } from "@/pinia-store/auth";
import { useMedicalRecordStore } from "@/pinia-store/medicalRecord";

export default {
  name: "PrivateNote",
  props: ["text", "sent", "id"],
  computed: {
    ...mapState(useAuthStore, ["uid"]),
  },
  methods: {
    ...mapActions(useMedicalRecordStore, ["deletePrivateNote", "getPrivateNotes"]),
    editNote(id) {
      this.$emit("openAddNoteDialog");
    },
    async deleteNote() {
      await this.deletePrivateNote(this.id);
      const { patientId } = this.$route.params;
      await this.getPrivateNotes({ patientId, practitionerId: this.uid });
    },
    formatDateTime(value) {
      return moment(value).format("MM/DD/YYYY HH:mm");
    },
  },
};
</script>

<style lang="scss" scoped>
.private-note {
  border: 1px solid #e7e8f2;
  border-radius: 3px;
  box-shadow: none !important;
  position: relative;
  margin-bottom: 15px;

  &__text {
    font-size: 14px;
    color: #33343e !important;
  }

  &__edit-menu-btn {
    position: absolute;
    top: 10px;
    right: 18px;
  }
}
</style>
