import axios, { AxiosInstance } from "axios";

const apiRequest: AxiosInstance = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL,
});
apiRequest.interceptors.request.use((config) => {
  console.info("apiRequest: ", config.url);
  return config;
});
export { apiRequest };
