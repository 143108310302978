<template>
  <div class="ps_container">
    <div class="ps_header">
      <span class="heading-2">{{ $t("settings.profile.title") }}</span>
      <PrimaryButton :loading="buttonLoading" :text="$t('settings.profile.save')" @onClick="saveChanges" />
    </div>
    <v-divider class="ps_divider"></v-divider>
    <v-card outlined>
      <v-progress-linear v-if="loading" color="primary" indeterminate />
      <UploadHeader />
      <div class="ps_details">
        <span class="heading-4">{{ $t("settings.profile.personalDetails") }}</span>
        <v-row class="ps_row">
          <v-col class="ps_input-group" cols="4">
            <span>{{ $t("general.inputs.firstName") }}</span>
            <BaseInput
              v-model="firstName"
              :errors="firstNameErrors"
              fieldName="firstName"
              @blur="$v.email.$touch()"
              @focus="onInputFocus('firstName')"
            />
          </v-col>
          <v-col class="ps_input-group" cols="4">
            <span>{{ $t("general.inputs.lastName") }}</span>
            <BaseInput
              v-model="lastName"
              :errors="lastNameErrors"
              fieldName="lastName"
              @blur="$v.email.$touch()"
              @focus="onInputFocus('firstName')"
            />
          </v-col>
        </v-row>
      </div>
      <div class="ps_details">
        <span class="heading-4">{{ $t("settings.profile.accountDetails") }}</span>
        <v-row class="ps_row">
          <v-col class="ps_input-group" cols="4">
            <span>{{ $t("general.inputs.email") }}</span>
            <BaseInput
              v-model="email"
              :disabled="true"
              :errors="emailErrors"
              fieldName="email"
              @blur="$v.email.$touch()"
            />
          </v-col>

          <v-col class="ps_input-group" cols="4">
            <span>{{ $t("registrar.settings.timezone") }}</span>
            <Select v-model="timeZone" :hideDetails="false" :items="timezones" />
          </v-col>
        </v-row>
      </div>
      <ChangePassword />
      <TwoFactorAuthenticationSettings />
    </v-card>
  </div>
</template>
<script>
import { getTimeZones } from "@vvo/tzdb";
import { mapActions, mapState } from "pinia";
import { email, required } from "vuelidate/lib/validators";

import ChangePassword from "@/components/settings/changePassword";
import TwoFactorAuthenticationSettings from "@/components/settings/TwoFactorAuthenticationSettings";
import UploadHeader from "@/components/settings/uploadHeader";
import BaseInput from "@/components/uikit/BaseInput";
import PrimaryButton from "@/components/uikit/PrimaryButton";
import Select from "@/components/uikit/Select";
import { snackBarEventBus, snackBarEventName } from "@/eventBuses/snackBar.eventBus";
import { useProfileSettingsStore } from "@/pinia-store/profileSettings";
import { defaultLocale } from "@/utils/constants";

export default {
  name: "RegistrarSettings",
  components: {
    PrimaryButton,
    UploadHeader,
    BaseInput,
    Select,
    ChangePassword,
    TwoFactorAuthenticationSettings,
  },
  validations: {
    email: { required, email },
    firstName: { required },
    lastName: { required },
  },
  data: () => ({
    loading: false,
    buttonLoading: false,
    firstNameModel: "",
    lastNameModel: "",
    localeModel: defaultLocale,
    emailModel: "",
    timeZoneModel: "",
    timezones: getTimeZones().map((tz) => ({
      text: `${tz.abbreviation} ${tz.name} ${tz.rawFormat.slice(0, 6)}`,
      value: tz.name,
    })),
    focusedField: null,
  }),
  computed: {
    ...mapState(useProfileSettingsStore, ["registrarSettings", "avatar"]),
    emailErrors() {
      const errors = [];
      if (!this.$v.email.$dirty) return errors;
      !this.$v.email.email && errors.push(this.$t("validation.email"));
      !this.$v.email.required &&
        errors.push(this.$t("validation.required", { field: this.$t("general.inputs.email") }));
      return errors;
    },
    firstNameErrors() {
      const errors = [];
      if (!this.$v.firstName.$dirty) return errors;
      !this.$v.firstName.required &&
        errors.push(this.$t("validation.required", { field: this.$t("general.inputs.firstName") }));
      return errors;
    },
    lastNameErrors() {
      const errors = [];
      if (!this.$v.lastName.$dirty) return errors;
      !this.$v.lastName.required &&
        errors.push(this.$t("validation.required", { field: this.$t("general.inputs.lastName") }));
      return errors;
    },
    firstName: {
      get() {
        return this.registrarSettings.firstName && this.focusedField !== "firstName"
          ? this.registrarSettings.firstName
          : this.firstNameModel;
      },
      set(v) {
        this.firstNameModel = v;
      },
    },
    lastName: {
      get() {
        return this.registrarSettings.lastName && this.focusedField !== "lastName"
          ? this.registrarSettings.lastName
          : this.lastNameModel;
      },
      set(v) {
        this.lastNameModel = v;
      },
    },
    email: {
      get() {
        return this.emailModel || this.registrarSettings.email;
      },
      set(v) {
        this.emailModel = v;
      },
    },
    locale: {
      get() {
        return this.localeModel || this.registrarSettings.locale;
      },
      set(v) {
        this.localeModel = v;
      },
    },
    timeZone: {
      get() {
        return this.timeZoneModel || this.registrarSettings.timeZone;
      },
      set(v) {
        this.timeZoneModel = v;
      },
    },
  },
  methods: {
    ...mapActions(useProfileSettingsStore, ["getRegistrarSettings", "saveRegistrarChanges"]),
    onInputFocus(field) {
      this.focusedField = field;
      if (this[`${field}Model`] === "") {
        this[`${field}Model`] = this.registrarSettings[field];
      }
    },
    async saveChanges() {
      this.buttonLoading = true;
      try {
        await this.saveRegistrarChanges({
          firstName: this.firstName,
          lastName: this.lastName,
          email: this.email,
          locale: this.locale,
          timeZone: this.timeZone,
        });
        snackBarEventBus.$emit(snackBarEventName, {
          message: this.$t("settings.success"),
          type: "success",
        });
        this.$root.$i18n.locale = this.locale;
      } catch (err) {
        console.error(err);
        snackBarEventBus.$emit(snackBarEventName, {
          message: (err && err.response && err.response.data && err.response.data.message) || err,
          type: "error",
        });
      } finally {
        this.buttonLoading = false;
      }
    },
  },
};
</script>
