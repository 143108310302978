<template>
  <v-dialog :value="value" max-width="420" @input="$emit('value')" @click:outside="onClose">
    <v-card class="card">
      <v-icon class="card-close" color="icon" size="medium" @click="onClose"> mdi-close</v-icon>
      <v-card-title>Assign practitioner to another Registrar</v-card-title>
      <v-card-text>Choose registrar to assign current practitioner</v-card-text>
      <Select
        v-model="registrarId"
        :errors="registrarIdErrors"
        :hideDetails="false"
        :items="registrarOptions"
        label="Choose registrar to assign"
      />
      <slot />
      <v-card-actions class="actions">
        <v-btn class="btn btn-cancel" color="#E5E5E5" outlined @click="onClose">
          <span>{{ $t("general.dialog.cancel") }}</span>
        </v-btn>
        <v-btn
          :dark="!this.$v.$invalid"
          :disabled="this.$v.$invalid"
          :light="this.$v.$invalid"
          class="btn"
          color="success"
          @click="assign"
        >
          <v-progress-circular v-if="loading" color="white" indeterminate size="25" width="3" />
          <span v-else>{{ $t("patients.assign.button") }}</span>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapState } from "pinia";
import { required } from "vuelidate/lib/validators";

import Select from "@/components/uikit/Select";
import { snackBarEventBus, snackBarEventName } from "@/eventBuses/snackBar.eventBus";
import { usePractitionersStore } from "@/pinia-store/practitioners";
import { useRegistrarsStore } from "@/pinia-store/registrars";

export default {
  name: "AssignPractitionerDialog",
  props: {
    value: Boolean,
    practitioner: Object,
  },
  data() {
    return {
      loading: false,
      registrarsLoading: false,
      registrarId: null,
    };
  },
  validations: {
    registrarId: { required },
  },
  computed: {
    ...mapState(useRegistrarsStore, ["registrars"]),
    registrarIdErrors() {
      const errors = [];
      if (!this.$v.registrarId.$dirty) return errors;
      !this.$v.registrarId.required &&
        errors.push(this.$t("validation.required", { field: this.$t("patients.assign.practitioner") }));
      return errors;
    },
    registrarOptions() {
      return this.registrars.map((registrar) => ({
        text: `${registrar.firstName} ${registrar.lastName}`,
        value: registrar.id,
      }));
    },
  },
  components: {
    Select,
  },
  methods: {
    ...mapActions(useRegistrarsStore, ["getAllRegistrars"]),
    ...mapActions(usePractitionersStore, ["getPractitioners", "reAssignPractitioner"]),
    onClose() {
      this.$emit("value");
      this.$emit("input", false);
    },
    async assign() {
      this.loading = true;
      try {
        await this.reAssignPractitioner({ practitionerId: this.practitioner.id, registrarId: this.registrarId });
        snackBarEventBus.$emit(snackBarEventName, {
          message: "Registrar Was Successfully Changed!",
          type: "success",
        });
      } catch (err) {
        console.error(err);
        let message = err.message || err;
        if (err.isAxiosError) {
          message = err.response.data.message;
        }
        snackBarEventBus.$emit(snackBarEventName, { message, type: "error" });
      }
      this.loading = false;
      await this.getPractitioners();
      this.onClose();
    },
  },
  async mounted() {
    this.registrarsLoading = true;
    try {
      await this.getAllRegistrars();
    } catch (err) {
      snackBarEventBus.$emit(snackBarEventName, { message: err.message || err, type: "error" });
    }
    this.registrarsLoading = false;
  },
};
</script>

<style lang="scss" scoped>
.card {
  padding: 1rem;
  position: relative;

  &-close {
    position: absolute !important;
    top: 17px;
    right: 16px;
    width: 0.1rem;
    height: 0.1rem;
  }
}

.input {
  margin: 10px 20px;
}

.btn {
  text-transform: capitalize;
  font-weight: 600;

  &-cancel {
    span {
      color: #ccccce;
    }
  }
}

.actions {
  justify-content: flex-end;
  display: flex;
}
</style>
