export enum GenderEnum {
  Male = "male",
  Female = "female",
  Other = "other",
  Unknown = "unknown",
}

export interface RegistrarResponseDto {
  id: string;
  firstName: string;
  lastName: string;
  gender: GenderEnum;
  photoURL: string;
}

export default interface PractitionerResponseDto {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  state: string;
  isTestingUser?: boolean;
  gender: GenderEnum;
  qualification: string;
  registrars: RegistrarResponseDto[];
  photoURL: string;
}
