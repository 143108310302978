<template>
  <TextConfirm
    :cancelText="$t('general.dialog.noThanks')"
    :confirmText="$t('general.dialog.cancelVisit')"
    :dialog="value"
    :inputPlaceholder="`${$t('practitioner.appointments.cancel.reason')}*`"
    :loading="loading"
    :text="$t('practitioner.appointments.cancel.text')"
    :title="$t('practitioner.appointments.cancel.title')"
    @confirm="cancelVisit"
    @toggleDialog="toggleDialog"
  />
</template>

<script>
import { mapActions, mapState } from "pinia";

import { OwnersAPI } from "@/api/owners";
import { snackBarEventBus, snackBarEventName } from "@/eventBuses/snackBar.eventBus";
import { useAppointmentsStore } from "@/pinia-store/appointments";
import { useAuthStore } from "@/pinia-store/auth";
import { useVideoRoomStore } from "@/pinia-store/videoRoom";
import { RolesEnum } from "@/types/Roles.enum";
import { errToText } from "@/utils/conversion";

import TextConfirm from "../shared/TextConfirm";

export default {
  name: "CancelVisit",
  props: [
    "success",
    "value",
    "appointmentId",
    "slotId",
    "closeEventModal",
    "updateCalendarInfo",
    "practitionerId",
    "isFromAppointments",
  ],
  components: {
    TextConfirm,
  },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    ...mapState(useVideoRoomStore, ["encounter"]),
    ...mapState(useAuthStore, ["uid"]),
    ...mapState(useAuthStore, ["role"]),
  },
  methods: {
    ...mapActions(useAppointmentsStore, ["cancelAppointment"]),
    ...mapActions(useVideoRoomStore, ["getRoomStatus"]),
    toggleDialog() {
      this.$emit("input", !this.value);
    },
    async cancelVisit(text) {
      this.loading = true;
      let wasError = false;
      try {
        if (this.appointmentId) {
          await this.cancelAppointment({
            id: this.appointmentId,
            cancellationReason: text,
          });
        } else if (this.slotId) {
          await OwnersAPI.rejectCheckout(this.slotId);
        } else {
          await this.cancelAppointment({
            id: this.encounter.appointmentId,
            cancellationReason: text,
          });
          await this.getRoomStatus();
        }
      } catch (err) {
        console.error(err);
        snackBarEventBus.$emit(snackBarEventName, {
          message: errToText(err),
          type: "error",
        });
        wasError = true;
      }
      if (this.updateCalendarInfo) {
        if (this.role === RolesEnum.Registrar || this.role === RolesEnum.Practitioner) {
          await this.updateCalendarInfo(this.uid);
        } else {
          await this.updateCalendarInfo(this.practitionerId || null);
        }
      }
      this.closeEventModal && this.closeEventModal();
      this.$emit("success");
      if (!this.isFromAppointments && this.updateCalendarInfo) {
        if (this.practitionerId) {
          await this.updateCalendarInfo(this.practitionerId);
        } else if (this.role === "registrar") {
          await this.updateCalendarInfo(this.uid);
        } else {
          await this.updateCalendarInfo();
        }
        this.closeEventModal();
      }
      if (!wasError)
        snackBarEventBus.$emit(snackBarEventName, {
          message: this.$t("practitioner.appointments.cancel.success"),
          type: "success",
        });
      this.toggleDialog();
      this.loading = false;
    },
  },
};
</script>

<style scoped></style>
