import { Participant } from "@twilio/conversations";

import { IParticipantsRepository } from "@/context/twilioContext/ChatConversation/IParticipantsRepository";
import ChatUser from "@/context/twilioContext/TwilioChatMember/ChatUser";
import { TwilioParticipantAttributes } from "@/context/twilioContext/types/TwilioChatMemberAttirbutes";

class ParticipantsRepository implements IParticipantsRepository {
  get participants(): ChatUser[] {
    return this._participants;
  }
  get interlocutor(): ChatUser | null {
    return this._interlocutor;
  }

  get user(): ChatUser {
    return this._user;
  }

  get isAnyParticipantBot(): boolean {
    return this._isAnyParticipantBot;
  }

  get isTyping(): boolean {
    return this._isTyping;
  }
  set isTyping(value: boolean) {
    this._isTyping = value;
  }

  private readonly channelSid: string;
  private readonly uid: string;

  private readonly _participants: ChatUser[] = [];
  private _interlocutor: ChatUser | null = null;
  private _user: ChatUser;
  private _isAnyParticipantBot = false;
  private _isTyping = false;

  constructor(channelSid: string, user: ChatUser) {
    this.channelSid = channelSid;
    this._user = user;
    this.uid = this._user.uid;
  }

  public addParticipant = async (user: ChatUser): Promise<void> => {
    await user.assignChannel(this.channelSid);
    if (user.attributes.isBot) this._isAnyParticipantBot = true;

    this._participants.push(user);
  };

  public setInterlocutor = (uid: string): void => {
    const foundParticipant = this._participants.find((participant) => participant?.uid === uid) ?? null;
    if (foundParticipant === null) this._interlocutor = null;
    this._interlocutor = foundParticipant;
  };

  public removeParticipant = (participant: Participant): void => {
    const participantAttributes = participant.attributes as TwilioParticipantAttributes;
    if (participantAttributes.isBot) {
      this._isAnyParticipantBot = false;
    }

    const chatMemberIndex = this._participants.findIndex((chatMember) => chatMember.uid === participant.identity);
    if (chatMemberIndex === -1) {
      throw new Error(
        `TwilioChannel ${this.channelSid}: Cannot remove member ${participant.identity}. Member has not been found.`,
      );
    }
    this._participants.splice(chatMemberIndex, 1);
  };
  public listParticipants = (): ChatUser[] => {
    return this._participants;
  };
}

export default ParticipantsRepository;
