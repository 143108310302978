<template>
  <v-dialog :value="isOpened" max-width="470" width="90%" @click:outside="onClose">
    <v-card class="visit-note-dialog">
      <span class="visit-notes_card_header_title"> Rename conversation </span>
      <v-row class="align-center mb-5 mt-4" no-gutters>
        <v-col cols="12" sm="4"> New name</v-col>
        <v-col cols="12" sm="8">
          <BaseInput :value="newName" fieldName="newName" placeholder="Enter channel name" @change="onChange" />
        </v-col>
      </v-row>
      <v-row class="align-center" no-gutters>
        <v-col class="bottom-actions justify-space-between" cols="12">
          <div>
            <OutlinedButton color="danger" text="Close" @onClick="onClose" />
          </div>
          <div>
            <PrimaryButton :disabled="isSubmitDisabled" :loading="loading" text="Save" @onClick="onSubmit" />
          </div>
        </v-col>
      </v-row>
    </v-card>
  </v-dialog>
</template>

<script>
import { ChatApi } from "@/api/chat";
import BaseInput from "@/components/uikit/BaseInput.vue";
import OutlinedButton from "@/components/uikit/OutlinedButton.vue";
import PrimaryButton from "@/components/uikit/PrimaryButton.vue";

export default {
  name: "ChannelRename",
  components: { BaseInput, PrimaryButton, OutlinedButton },
  props: {
    isOpened: {
      type: Boolean,
      required: true,
      default: () => false,
    },
    channel: {
      type: Object,
      required: true,
    },
  },
  watch: {
    isOpened: {
      handler() {
        this.newName = this.channel?.channelAttributes?.chiefComplaint || "";
      },
    },
  },
  data() {
    return {
      isSubmitDisabled: false,
      loading: false,
      newName: "",
    };
  },
  methods: {
    onChange(value) {
      this.newName = value;
    },
    onClose() {
      this.$emit("onClose");
    },
    async onSubmit() {
      this.loading = true;
      try {
        await ChatApi.updateChannel(this.channel.sid, { chiefComplaint: this.newName });
      } catch (e) {
        console.error(e);
      }
      this.loading = false;
      this.onClose();
    },
  },
};
</script>

<style>
:host {
  display: block;
}
</style>
