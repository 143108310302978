<template>
  <Confirm
    :confirmText="`${mode === 'edit' ? 'Update' : 'Add'} Note`"
    :dialog="dialog"
    :hideDescription="true"
    :loading="buttonLoading"
    :title="`${mode === 'edit' ? 'Edit' : 'Add'} Note`"
    cancelText="Cancel"
    maxWidth="90%"
    rootClass="add-note-form"
    text=""
    width="600px"
    @confirm="save"
    @toggleDialog="close"
  >
    <v-container class="padding-0 my-6">
      <Textarea
        :height="110"
        :hide-details="true"
        :value="privateNote"
        fieldName="privateNote"
        placeholder="Private Note"
        @change="onPrivateNoteInput"
      />
    </v-container>
  </Confirm>
</template>

<script>
import { mapActions, mapState } from "pinia";

import { CommunicationApi } from "@/api/communication";
import Confirm from "@/components/shared/Confirm";
import Textarea from "@/components/uikit/Textarea";
import { useAuthStore } from "@/pinia-store/auth";
import { useMedicalRecordStore } from "@/pinia-store/medicalRecord";

export default {
  name: "AddNote",
  props: ["dialog", "mode", "editId"],
  components: { Textarea, Confirm },
  data: () => ({
    privateNote: "",
    buttonLoading: false,
  }),
  computed: {
    ...mapState(useAuthStore, ["uid"]),
  },
  methods: {
    ...mapActions(useMedicalRecordStore, ["createPrivateNote", "updatePrivateNote"]),
    close() {
      this.$emit("close");
    },
    async save() {
      this.buttonLoading = true;
      if (this.privateNote !== "") {
        if (this.mode === "edit") {
          await this.updatePrivateNote({ id: this.editId, text: this.privateNote });
        } else {
          const { patientId } = this.$route.params;
          await this.createPrivateNote({
            patientId,
            practitionerId: this.uid,
            privateNote: this.privateNote,
          });
        }
        this.$emit("save");
      }
      this.buttonLoading = false;
    },
    onPrivateNoteInput(value) {
      this.privateNote = value;
    },
  },
  watch: {
    async dialog() {
      if (this.mode === "edit" && this.editId) {
        const note = await CommunicationApi.getById(this.editId);
        this.privateNote = note.privateNote;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.padding-0 {
  padding: 0 !important;
}

.add-note-modal {
  position: relative;
  text-align: center;
  padding: 30px;

  &__title {
    font-size: 20px;
    font-weight: 500;
    text-align: center;
  }

  &__close-btn {
    position: absolute;
    top: 20px;
    right: 20px;
  }
}
</style>
