<template>
  <v-text-field
    v-if="maskPattern"
    ref="baseInput"
    v-mask="maskPattern"
    :autocomplete="autocomplete"
    :browserAutocomplete="autocomplete"
    :class="className"
    :clearable="clearable"
    :dense="dense"
    :disabled="disabled"
    :error-messages="errors"
    :flat="!outlined"
    :height="height"
    :hide-details="hideDetails"
    :hint="hint"
    :label="placeholder"
    :outlined="!!errors.length || outlined"
    :persistent-hint="persistentHint"
    :solo="!outlined"
    :suffix="suffix"
    :value="value"
    class="base-input"
    light
    @blur="onBlur"
    @focus="onFocus"
    @input="onChange"
  >
    <template v-slot:append>
      <v-icon color="icon" size="16"> {{ appendIcon }}</v-icon>
    </template>
    <template v-slot:prepend-inner>
      <v-icon color="icon" size="16"> {{ prependInnerIcon }}</v-icon>
    </template>
  </v-text-field>
  <v-text-field
    v-else
    ref="baseInput"
    :autocomplete="autocomplete"
    :browserAutocomplete="autocomplete"
    :class="className"
    :clearable="clearable"
    :dense="dense"
    :disabled="disabled"
    :error-messages="errors"
    :flat="!outlined"
    :height="height"
    :hide-details="hideDetails"
    :hint="hint"
    :label="placeholder"
    :outlined="!!errors.length || outlined"
    :persistent-hint="persistentHint"
    :rules="rules"
    :solo="!outlined"
    :suffix="suffix"
    :value="value"
    class="base-input"
    light
    @blur="onBlur"
    @focus="onFocus"
    @input="onChange"
  >
    <template v-slot:append>
      <v-icon color="icon" size="16"> {{ appendIcon }}</v-icon>
    </template>
    <template v-slot:prepend-inner>
      <v-icon color="icon" size="16"> {{ prependInnerIcon }}</v-icon>
    </template>
  </v-text-field>
</template>
<script>
import { mask } from "vue-the-mask";

export default {
  name: "BaseInput",
  directives: { mask },
  props: {
    value: [String, Number],
    fieldName: String,
    height: { default: 47, type: Number },
    placeholder: { default: "", type: String },
    className: { default: "", type: String },
    errors: { default: () => [], type: Array },
    rules: { default: () => [], type: Array },
    suffix: { default: "", type: String },
    appendIcon: { default: "", type: String },
    prependInnerIcon: { default: "", type: String },
    dense: { default: true, type: Boolean },
    disabled: { default: false, type: Boolean },
    fullWidth: { default: false, type: Boolean },
    maskPattern: { default: null, type: String },
    hint: { default: "", type: String },
    persistentHint: { default: false, type: Boolean },
    hideDetails: { default: true, type: Boolean },
    clearable: { default: false, type: Boolean },
    autocomplete: { default: "off", type: String },
  },
  data: () => ({
    outlined: false,
  }),
  methods: {
    onFocus() {
      if (!this.outlined) this.outlined = true;
      this.$emit("focus");
    },
    onChange(value) {
      this.$emit("change", value, this.fieldName);
      this.$emit("input", value);
    },
    onBlur() {
      this.outlined = false;
      this.$emit("blur");
    },
  },
};
</script>
