import { DateTime } from "luxon";
import moment from "moment-timezone";
import { defineStore } from "pinia";

import { ChatApi } from "@/api/chat";
import { EncountersAPI } from "@/api/encounters";
import { PatientsApi } from "@/api/patients";
import { VideoRoomsApi } from "@/api/videoRooms";
import { useAuthStore } from "@/pinia-store/auth";
import { useEncountersStore } from "@/pinia-store/encounters";
import { Encounter } from "@/pinia-store/interfaces/Encounter";
import { RoomSettings } from "@/pinia-store/interfaces/RoomSettings";
import { RoomStatus } from "@/pinia-store/interfaces/RoomStatus";
import { UserResponse } from "@/pinia-store/interfaces/UserResponse";
import { useVisitNoteStore } from "@/pinia-store/visitNote";
import { RolesEnum } from "@/types/Roles.enum";

interface VideoRoomState {
  encounter: Encounter | null;
  room: RoomStatus | null;
  settings: RoomSettings | null;
  accessToken: string | null;
  isVideo: boolean;
  isAudio: boolean;
  hasVideoDevice: boolean;
  hasAudioDevice: boolean;
  isFullScreen: boolean;
  isPermissionsAccepted: boolean;
  userIsReady: boolean;
  connectedParticipant: UserResponse | null;
  practitionerProfile: any;
  patientProfile: any;
  roomNotFound: boolean;
}

export const useVideoRoomStore = defineStore({
  id: "video-room",
  state: (): VideoRoomState => ({
    room: null,
    encounter: null,
    settings: null,
    accessToken: null,
    isVideo: true,
    isAudio: true,
    isFullScreen: false,
    hasAudioDevice: true,
    hasVideoDevice: true,
    isPermissionsAccepted: false,
    userIsReady: false,
    connectedParticipant: null,
    practitionerProfile: {},
    patientProfile: {},
    roomNotFound: false,
  }),
  actions: {
    setConnectedParticipant(payload: any) {
      this.connectedParticipant = payload;
    },
    setEncounter(payload: any) {
      this.encounter = payload;
    },
    setUserIsReady(payload: any) {
      this.userIsReady = payload;
    },
    setRoomSettings(payload: { videoInput: any; audioInput: any; audioOutput: any }) {
      this.settings = payload;
    },
    setIsVideo(payload: boolean) {
      this.isVideo = payload;
    },
    setIsAudio(payload: boolean) {
      this.isAudio = payload;
    },
    setHasAudioDevice(payload: any) {
      this.hasAudioDevice = payload;
    },
    setHasVideoDevice(payload: any) {
      this.hasVideoDevice = payload;
    },
    setIsPermissionsAccepted(payload: boolean) {
      this.isPermissionsAccepted = payload;
    },
    setIsFullScreen(payload: boolean) {
      this.isFullScreen = payload;
    },

    async getConnectedParticipant(userId: string) {
      const participant = await VideoRoomsApi.getConnectedParticipantById(userId);
      this.connectedParticipant = participant;
      return participant;
    },
    async startRoom() {
      if (this.room) {
        const room = await EncountersAPI.start(this.room.id);
        this.room = room;
        await this.getRoomAccessToken();
        return room;
      }
      return null;
    },
    async finishRoom() {
      if (this.room) {
        const room = await EncountersAPI.finish(this.room.id);
        if (this?.encounter?.id) {
          const encounter = await EncountersAPI.getById(this.encounter.id);
          // todo: fix this
          const visitNoteStore = useVisitNoteStore();
          visitNoteStore.$patch({ encounter });
        }
        this.room = room;
        return room;
      }
      return null;
    },
    async getRoomAccessToken() {
      if (!this.room) {
        return null;
      }
      const accessToken = await VideoRoomsApi.access(this.room.id);
      this.accessToken = accessToken;
      return accessToken;
    },
    async getEncounter(id: string) {
      const visitNoteStore = useVisitNoteStore();
      visitNoteStore.clearEncounterNoteFields();
      const encountersStore = useEncountersStore();
      if (this.encounter && this.encounter.id === id) {
        return this.encounter;
      }
      const encounter = await EncountersAPI.getById(id);
      this.encounter = encounter;
      visitNoteStore.$patch({ encounter });
      encountersStore.$patch({ currentEncounter: encounter });
      try {
        const patientInfo = await PatientsApi.patientGetProfile(encounter?.patient.id);
        visitNoteStore.$patch({ patientInfo });
        encountersStore.$patch({ patientId: patientInfo.id });
        visitNoteStore.getObservationsSummary(encounter?.patient.id);
      } catch (err) {
        console.error(err);
      }
      try {
        this.practitionerProfile = await ChatApi.chatMember(encounter?.practitioner?.id);
      } catch (e) {
        console.info("Practitioner identity not found");
      }
      try {
        this.patientProfile = await ChatApi.chatMember(encounter?.patient?.id);
      } catch (e) {
        console.info("Patient identity not found");
      }
      await this.getRoom(id);
      return encounter;
    },

    async getRoom(id: string) {
      if (this.room && this.room.id === id) {
        return this.room;
      }
      let room;
      try {
        room = await VideoRoomsApi.getById(id);
      } catch (err) {
        this.roomNotFound = true;
      }
      try {
        if (room) {
          this.practitionerProfile = await ChatApi.chatMember(room?.practitioner?.id);
          this.patientProfile = await ChatApi.chatMember(room?.patient?.id);
        }
      } catch (err) {
        console.error(err);
      }
      this.room = room;
      return room;
    },
    async getRoomStatus() {
      if (this.room && this.room.id) {
        try {
          const room = await VideoRoomsApi.getById(this.room.id);
          this.room = room;
          return room;
        } catch (err) {
          console.error("Room not found");
        }
      }
    },
    async setDefaultRoomSettings() {
      if (this.settings) {
        return this.settings;
      }
      const devices = await navigator.mediaDevices.enumerateDevices();
      const videoInputDevices = devices.filter((device) => device.kind === "videoinput");
      const audioInputDevices = devices.filter((device) => device.kind === "audioinput");
      const audioOutputDevices = devices.filter((device) => device.kind === "audiooutput");
      if (!this.settings) {
        this.settings = {
          videoInput: videoInputDevices[0] && videoInputDevices[0].deviceId,
          audioInput: audioInputDevices[0] && audioInputDevices[0].deviceId,
          audioOutput: audioOutputDevices[0] && audioOutputDevices[0].deviceId,
        };
      }
    },
    async clear() {
      this.encounter = null;
      this.room = null;
      this.userIsReady = false;
      this.accessToken = null;
    },
    async calculateTimer() {
      if (!this.room || !this.room.startedDate) {
        return;
      }
      const diff = DateTime.fromISO(this.room.startedDate).diffNow("seconds").negate();
      if (diff.as("hours") >= 1) {
        return diff.toFormat("hh:mm:ss");
      }
      return diff.toFormat("mm:ss");
    },
  },
  getters: {
    roomIsReady(state) {
      const authStore = useAuthStore();
      if (authStore.role === RolesEnum.Practitioner && state.room && state.room.startedDate) {
        return true;
      }
      if (
        [RolesEnum.Patient, RolesEnum.EncounterParticipant].includes(authStore.role) &&
        state.room &&
        state.room.startedDate
      ) {
        return state.userIsReady;
      }
      return false;
    },
    roomIsStarted(state) {
      return state.room && state.room.startedDate;
    },
    roomIsExpired(state) {
      return state.room && state.room.expectedEndDate && moment().isAfter(state.room.expectedEndDate);
    },
    roomIsFinished(state) {
      return typeof state.room?.finishedDate === "string";
    },
  },
});
