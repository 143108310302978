<template>
  <div>
    <Confirm
      :dialog="value"
      :loading="loading"
      cancelText="Close"
      hide-confirm
      rootClass="media-dialog"
      text="Please select the patient for attach files to patient chart"
      title="Add an Attachment"
      @toggleDialog="toggleDialog"
    >
      <v-container class="no-padding-bottom no-padding-top">
        <v-row>
          <v-col class="no-padding-left no-padding-right" cols="12">
            <v-autocomplete
              :allow-overflow="false"
              :filter="customFilter"
              :items="patientsList"
              :search-input.sync="predictionsSearch"
              :value="patientId"
              append-icon=""
              class="autocomplete-search"
              filled
              height="46"
              hide-details
              item-value="id"
              placeholder="First/Last name"
              @input="onPatientSelect"
            >
              <template v-slot:selection="data">
                <template>
                  <v-list-item-avatar>
                    <Avatar v-if="data.item.id !== 'generated'" :src="data.item.photoURL" :userId="data.item.id" />
                    <v-avatar v-else color="primary" size="36">
                      <span class="white--text text-h5">
                        {{ data.item.firstName || "" }} {{ data.item.lastName || "" }}
                      </span>
                    </v-avatar>
                  </v-list-item-avatar>
                  <v-list-item-content class="list-content-invite-meeting-room">
                    <v-list-item-title>
                      {{ data.item.firstName || "" }} {{ data.item.lastName || "" }}
                    </v-list-item-title>
                    <v-list-item-subtitle v-if="data.item.medicalRecordNumber"
                      >{{ data.item.medicalRecordNumber }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </template>
              </template>
              <template v-slot:item="data">
                <template>
                  <v-list-item-avatar>
                    <Avatar v-if="data.item.id !== 'generated'" :src="data.item.photoURL" :userId="data.item.id" />
                    <v-avatar v-else color="primary" size="36">
                      <span class="white--text text-h5">
                        {{ data.item.firstName || "" }} {{ data.item.lastName || "" }}
                      </span>
                    </v-avatar>
                  </v-list-item-avatar>
                  <v-list-item-content class="list-content-invite-meeting-room">
                    <v-list-item-title>
                      {{ data.item.firstName || "" }} {{ data.item.lastName || "" }}
                    </v-list-item-title>
                    <v-list-item-subtitle v-if="data.item.medicalRecordNumber"
                      >{{ data.item.medicalRecordNumber }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </template>
              </template>
            </v-autocomplete>
          </v-col>
          <v-col v-if="files.length" class="no-padding-left no-padding-right" cols="12">
            <FileRow
              v-for="file in files"
              :key="file.id"
              :editable="true"
              :file="file"
              @onChange="onFileChange"
              @onEdit="onFileEdit"
              @onRemove="onFileRemove"
            />
          </v-col>
          <v-col v-if="patientId" class="no-padding-left no-padding-right no-padding-top" cols="12">
            <MediaUploader @onUploaded="onFileLoaded" />
          </v-col>
        </v-row>
      </v-container>
    </Confirm>
    <MediaRename
      v-model="mediaRenameDialog"
      :file="editFile"
      @onChange="onFileChange"
      @toggleDialog="toggleRenameDialog"
    />
  </div>
</template>
<script>
import { mapState as mapPiniaState } from "pinia";
import { mapActions as mapPiniaActions } from "pinia";
import { required } from "vuelidate/lib/validators";

import Avatar from "@/components/Avatar/Index.vue";
import FileRow from "@/components/media/FileRow";
import MediaUploader from "@/components/media/MediaUploader";
import Confirm from "@/components/shared/Confirm";
import { useAuthStore } from "@/pinia-store/auth";
import { useMediaStore } from "@/pinia-store/media";
import { usePatientsStore } from "@/pinia-store/patients";
import { filterSearchOfPatients } from "@/utils/tableHeaders";

import MediaRename from "./MediaRename";

export default {
  name: "MediaDialog",
  props: ["value"],
  components: {
    Avatar,
    MediaRename,
    MediaUploader,
    Confirm,
    FileRow,
  },
  data: () => ({
    editFile: {},
    mediaRenameDialog: false,
    files: [],
    patientsList: [],
    patientId: "",
    predictionsSearch: "",
    loading: false,
  }),
  validations: {
    patientId: { required },
  },
  computed: {
    ...mapPiniaState(usePatientsStore, ["patients"]),
    ...mapPiniaState(useAuthStore, ["uid", "role"]),
  },
  async mounted() {
    await this.getAllPatients();
    this.patientsList = this.patients.map(($patient) => ({
      ...$patient,
      firstName: $patient.firstName || "",
      lastName: $patient.lastName || "",
      text: `${$patient.firstName || ""} ${$patient.lastName || ""}`,
      value: $patient.id,
    }));
  },
  watch: {
    predictionsSearch(query) {
      this.patientsList = filterSearchOfPatients(query, this.patientsList);
    },
    value(status) {
      if (status && !this.patients.length) {
        this.patientId = "";
        this.files = [];
        this.getAllPatients();
      }
    },
  },
  methods: {
    ...mapPiniaActions(useMediaStore, ["setUid"]),
    ...mapPiniaActions(usePatientsStore, ["getAllPatients"]),
    customFilter() {
      return true;
    },
    toggleRenameDialog() {
      this.mediaRenameDialog = false;
    },
    async onPatientSelect(patientId, rest) {
      this.patientId = patientId;
      this.files = [];
      await this.setUid(patientId);
    },
    onFileChange(newFile) {
      this.files = this.files.map((file) => {
        return file.id === newFile.id ? { ...newFile } : { ...file };
      });
    },
    onFileEdit(file) {
      this.editFile = file;
      this.mediaRenameDialog = true;
    },
    onFileRemove(removedFile) {
      this.files = this.files.filter((file) => file.id !== removedFile.id);
    },
    onFileLoaded(files) {
      this.files = [...this.files, ...files];
    },
    toggleDialog() {
      this.$emit("toggleDialog");
    },
  },
};
</script>
<style lang="scss">
.media-dialog {
  :deep .autocomplete-search {
    margin-bottom: 24px;

    .v-input__control > .v-input__slot {
      &:hover {
        background: transparent !important;
      }

      background: transparent;
      border: 1px solid #ddd;
    }
  }

  .card-title {
    font-weight: 500 !important;
    font-size: 25px !important;
    line-height: 31px !important;
    color: #25233a;
    padding: 16px 0px 10px !important;
  }

  .v-card__title + .v-card__text {
    padding: 0 0 20px 0;
    font-size: 14px;
    line-height: 17px;
    color: #33343d;
  }

  .container {
    margin-bottom: 10px;
  }
}
</style>
