var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"practitioner-header"},[_c('div',{staticClass:"status-div"},[_c('div',{class:{
        status: _vm.status,
        'status--draft': _vm.renderedStatus === 'draft',
        'status--sent': _vm.renderedStatus === 'sent',
      }},[_c('span',[_vm._v(_vm._s(_vm.$t("visits.summary.status"))+" "+_vm._s(_vm.renderedStatus === "draft" ? _vm.$t("visits.summary.draft") : _vm.$t("visits.summary.sent")))])]),_c('div',{class:{
        triangle: true,
        'triangle--draft': _vm.renderedStatus === 'draft',
        'triangle--sent': _vm.renderedStatus === 'sent',
      }})]),(_vm.status !== 'signed')?_c('v-btn',{staticClass:"btn btn-margin",attrs:{"color":"rgb(239,246,255)","depressed":"","large":""},on:{"click":_vm.changeMode}},[_c('v-icon',{attrs:{"color":"#2177e0","dark":"","size":"small"}},[_vm._v(" mdi-pencil")]),_c('span',{staticClass:"btn-blue"},[_vm._v(_vm._s(_vm.$t("visits.summary.editDraft")))])],1):_vm._e(),(_vm.status !== 'signed')?_c('v-btn',{staticClass:"btn",attrs:{"disabled":_vm.disabledSigning,"color":"success","dark":"","depressed":"","large":""},on:{"click":_vm.submitSummary}},[_vm._v(" "+_vm._s(_vm.$t("visits.summary.signSend"))+" ")]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }