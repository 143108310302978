<template>
  <div v-if="privateNotes.length" class="my-7">
    <PrivateNote
      v-for="privateNote in privateNotes"
      :id="privateNote.id"
      :key="privateNote.id"
      :sent="privateNote.sent"
      :text="privateNote.privateNote"
      @openAddNoteDialog="openAddNoteDialog(privateNote.id)"
    />
  </div>
  <div v-else-if="!loadingPrivateNotes" class="my-7">
    <div class="text-center">No results.</div>
  </div>
</template>

<script>
import { mapActions, mapState } from "pinia";

import PrivateNote from "@/components/MedicalRecord/PrivateNotes/PrivateNote";
import { useAuthStore } from "@/pinia-store/auth";
import { useMedicalRecordStore } from "@/pinia-store/medicalRecord";

export default {
  name: "PrivateNotes",
  components: { PrivateNote },
  computed: {
    ...mapState(useMedicalRecordStore, ["loadingPrivateNotes", "privateNotes"]),
    ...mapState(useAuthStore, ["uid"]),
  },
  methods: {
    ...mapActions(useMedicalRecordStore, ["getPrivateNotes"]),
    openAddNoteDialog(id) {
      this.$emit("openAddNoteDialog", id);
    },
  },
  async mounted() {
    const { patientId } = this.$route.params;
    await this.getPrivateNotes({ patientId, practitionerId: this.uid });
  },
};
</script>

<style scoped></style>
