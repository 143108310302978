export enum RolesEnum {
  Patient = "patient",
  Practitioner = "practitioner",
  Admin = "admin",
  EncounterParticipant = "encounterParticipant",
  Owner = "owner",
  Registrar = "registrar",
  ChatBot = "chatBot",
  Anonymous = "anonymous",
}
