import { apiRequest } from "./apiRequest";

export interface Templates {
  name: string;
  assessment?: string;
  plan?: string;
  followUp?: string;
}

const getAll = async (): Promise<Templates[]> => {
  const { data } = await apiRequest.get(`/templates`);
  return data;
};

const create = async (body: Templates): Promise<Templates> => {
  const { data } = await apiRequest.post(`/templates`, body);
  return data;
};

const update = async (id: string, body: Templates): Promise<Templates> => {
  const { data } = await apiRequest.post(`/templates/${id}`, body);
  return data;
};

const destroy = async (id: string): Promise<boolean> => {
  const { data } = await apiRequest.delete(`/templates/${id}`);
  return data;
};

const bulkDelete = async (ids = []): Promise<boolean> => {
  const { data } = await apiRequest.delete(`/templates`, { params: [...ids] });
  return data;
};

export const TemplatesAPI = {
  getAll,
  create,
  update,
  destroy,
  bulkDelete,
};
