<template>
  <OwnerScheduleVisit />
</template>
<script>
import Vue from "vue";

import OwnerScheduleVisit from "@/components/Checkout/OwnerScheduleVisit";

export default Vue.extend({
  name: "OwnerRequestVisitView",
  components: {
    OwnerScheduleVisit,
  },
});
</script>
