<template>
  <v-dialog :value="dialog" max-width="400" @click:outside="closeDialog">
    <v-card class="dialog">
      <div class="dialog__head">
        <v-card-title class="dialog__head__title">
          {{ $t("practitioner.schedule.title") }}
        </v-card-title>
        <v-icon class="dialog__head__close-icon" size="small" @click="closeDialog">mdi-close</v-icon>
      </div>
      <Stepper :step="step" :texts="texts" className="dialog__stepper" />
      <FirstStep v-if="step === 1" :selectedTime="selectedTime" :step="goToPage" mode="create" @submit="submit" />
    </v-card>
    <v-card v-if="step === 2" class="dialog">
      <SuccessPayment :step="goToPage" class="ma-0" @done="closeDialog" @update:step="step = $event" />
    </v-card>
  </v-dialog>
</template>

<script>
import { DateTime } from "luxon";
import { mapActions, mapState } from "pinia";

import FirstStep from "@/components/Checkout/OwnerScheduleVisit/FirstStep";
import SuccessPayment from "@/components/Checkout/SuccessPayement/Index";
import Stepper from "@/components/shared/Stepper";
import { snackBarEventBus, snackBarEventName } from "@/eventBuses/snackBar.eventBus";
import { useAuthStore } from "@/pinia-store/auth";
import { useCheckoutStore } from "@/pinia-store/checkout";
import { useOwnersStore } from "@/pinia-store/owners";
import { usePatientStore } from "@/pinia-store/patient";
import { usePatientsStore } from "@/pinia-store/patients";
import { usePractitionersStore } from "@/pinia-store/practitioners";
import { useProfileSettingsStore } from "@/pinia-store/profileSettings";
import { useRegistrarsStore } from "@/pinia-store/registrars";
import { useSlotsStore } from "@/pinia-store/slots";

export default {
  props: {
    dialog: Boolean,
    selectedTime: Object,
    closeScheduleDialog: Function,
  },
  components: {
    Stepper,
    FirstStep,
    SuccessPayment,
  },
  data() {
    return {
      step: 1,
      datepickerOpen: false,
      timeslotsOpen: false,
      buttonLoading: false,
      practitionersFreeSlots: {},
    };
  },
  computed: {
    ...mapState(useAuthStore, ["uid"]),
    ...mapState(usePatientsStore, ["patients"]),
    ...mapState(useProfileSettingsStore, ["locale"]),
    ...mapState(useCheckoutStore, [
      "slotId",
      "complaintDescription",
      "chiefComplaint",
      "visitDate",
      "patientId",
      "reassign",
      "reassignPractitionerId",
    ]),
    ...mapState(useSlotsStore, ["slotsArray", "currentSlot"]),
    ...mapState(usePractitionersStore, ["practitioners"]),
    ...mapState(useOwnersStore, ["practitionerFreeSlots", "practitionersWithSlots"]),
    timeslot() {
      if (this.practitionerFreeSlots && this.slotId) {
        const slot = this.practitionerFreeSlots.find((slot) => slot.id === this.slotId);
        if (slot) {
          return slot.time;
        } else return this.$t("visits.schedule.second.selectTime");
      } else {
        return this.$t("visits.schedule.second.selectTime");
      }
    },
    date() {
      if (!this.visitDate) return this.$t("visits.schedule.second.selectDate");
      return DateTime.fromJSDate(new Date(this.visitDate)).setLocale(this.locale).toLocaleString({
        day: "numeric",
        month: "long",
        weekday: "long",
        year: "numeric",
      });
    },
    patientsOptions() {
      return this.patients.map(({ firstName, lastName, id }) => ({
        text: `${firstName} ${lastName}`,
        value: id,
      }));
    },
    chiefComplaints() {
      const chiefComplaints = this.$t("general.chiefComplaints");
      if (!chiefComplaints) {
        return [];
      }
      return Object.keys(chiefComplaints).map((key) => ({ text: chiefComplaints[key], value: key }));
    },
    texts() {
      return this.$i18n.t("visits.schedule.practitionerSteps");
    },
    slotsDates() {
      if (this.visitDate) {
        if (this.practitionerFreeSlots.length) {
          const newSlots = this.practitionerFreeSlots.filter((slot) => {
            return slot.start.split("T")[0] === this.visitDate;
          });
          return newSlots;
        } else return [];
      } else {
        return [];
      }
    },
    maxScheduleDate() {
      return DateTime.fromJSDate(new Date()).plus({ days: 8 }).toJSDate().toISOString().substr(0, 10);
    },
    minScheduleDate() {
      return DateTime.fromJSDate(new Date()).toJSDate().toISOString().substr(0, 10);
    },
    availableDates() {
      const dates = [];
      const date = DateTime.fromJSDate(new Date());
      for (let i = 0; i < 8; i++) {
        dates.push(date.plus({ days: i }).day);
      }

      return dates;
    },
    isButtonDisabled() {
      if (this.selectedTime && this.selectedTime.category) {
        return (
          !this.slotId ||
          !this.complaintDescription ||
          !this.chiefComplaint ||
          !this.visitDate ||
          !this.selectedTime.category.value ||
          !this.patientId
        );
      } else {
        return (
          !this.slotId ||
          !this.complaintDescription ||
          !this.chiefComplaint ||
          !this.visitDate ||
          !this.reassignPractitionerId ||
          !this.patientId
        );
      }
    },
    practitionersList() {
      return this.practitionersWithSlots.map(({ firstName, lastName, id }) => ({
        text: `${firstName} ${lastName}`,
        value: id,
      }));
    },
    isPractitionerAvailable() {
      if (this.reassignPractitionerId && this.visitDate && this.slot) {
        return this.practitionersFreeSlots[this.reassignPractitionerId]?.some((slot) => {
          return slot.start === `${this.visitDate}T${this.slot.slice(0, 5)}:00.000Z`;
        });
      } else {
        return true;
      }
    },
    reassignPatient: {
      get() {
        return this.reassign;
      },
      set(value) {
        this.setStringFieldByName({ value, fieldName: "reassign" });
      },
    },
    practitionerOwnsPatient() {
      const pat = this.patients.find((p) => p.id === this.patientId);
      return pat?.practitioner?.id === this.reassignPractitionerId;
    },
  },
  methods: {
    ...mapActions(useCheckoutStore, ["setStringFieldByName", "setVisitDate"]),
    ...mapActions(usePatientsStore, ["getAllPatients"]),
    ...mapActions(usePatientStore, ["getPatientProfile"]),
    ...mapActions(usePatientsStore, ["getAllByPractitionerId"]),
    ...mapActions(useCheckoutStore, ["practitionerRequestVisit", "clearAllFields"]),
    ...mapActions(useOwnersStore, ["chosePractitioner", "getAllPractitioners"]),
    ...mapActions(useRegistrarsStore, ["chosePractitioner", "getRegistrarPractitionersWithSlots"]),
    ...mapActions(useSlotsStore, ["getPractitionerFreeSlots", "getSlotById"]),
    ...mapActions(usePractitionersStore, ["getPractitionerById"]),
    closeDialog() {
      let value = "";
      this.setStringFieldByName({ value, fieldName: "slotId" });
      this.$emit("on-close");
      this.step = 1;
    },
    goToPage(number) {
      this.step = number;
    },
    nextStep() {
      this.setStringFieldByName({
        value: this.practitionersFreeSlots[this.reassignPractitionerId].find(
          (s) => s.start === `${this.visitDate}T${this.slot.slice(0, 5)}:00.000Z`,
        ).id,
        fieldName: "slotId",
      });
      this.$router.push({ path: "/owner/encounters/schedule?step=2" });
    },
    async submit() {
      this.buttonLoading = true;
      try {
        this.step = 2;
        await this.getPractitionerById(this.reassignPractitionerId);
        await this.getSlotById(this.slotId);
        await this.getPatientProfile(this.patientId);
      } catch (err) {
        console.error(err);
        if (err && err.response && err.response.status === 409) {
          this.errorType = "conflict";
          this.step = 4;
          snackBarEventBus.$emit(snackBarEventName, {
            message: this.$t("general.errors.slotConflict"),
            type: "error",
          });
          await this.getPractitionerFreeSlots({ practitionerId: this.uid });
        }
        snackBarEventBus.$emit(snackBarEventName, {
          message: (err && err.response && err.response.data && err.response.data.message) || err,
          type: "error",
        });
      }
      this.buttonLoading = false;
    },
  },
};
</script>
