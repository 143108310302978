<template>
  <div class="ps_container">
    <div class="ps_header">
      <span class="heading-2">{{ $t("settings.profile.title") }}</span>
      <PrimaryButton :loading="buttonLoading" :text="$t('settings.profile.save')" @onClick="saveChanges" />
    </div>
    <v-divider class="ps_divider"></v-divider>
    <v-card outlined>
      <v-progress-linear v-if="loading" color="primary" indeterminate />
      <UploadHeader />
      <div class="ps_details">
        <span class="heading-4">{{ $t("settings.profile.personalDetails") }}</span>
        <v-row class="ps_row">
          <v-col class="ps_input-group" cols="4">
            <span>{{ $t("general.inputs.firstName") }}</span>
            <BaseInput
              v-model="firstName"
              :errors="firstNameErrors"
              fieldName="firstName"
              @blur="$v.email.$touch()"
              @focus="onInputFocus('firstName')"
            />
          </v-col>
          <v-col class="ps_input-group" cols="4">
            <span>{{ $t("general.inputs.lastName") }}</span>
            <BaseInput
              v-model="lastName"
              :errors="lastNameErrors"
              fieldName="lastName"
              @blur="$v.email.$touch()"
              @focus="onInputFocus('lastName')"
            />
          </v-col>
        </v-row>
      </div>
      <div class="ps_details">
        <span class="heading-4">{{ $t("settings.profile.accountDetails") }}</span>
        <v-row class="ps_row">
          <v-col class="ps_input-group" cols="4">
            <span>{{ $t("general.inputs.email") }}</span>
            <BaseInput
              v-model="email"
              :disabled="true"
              :errors="emailErrors"
              fieldName="email"
              @blur="$v.email.$touch()"
            />
          </v-col>

          <v-col class="ps_input-group" cols="4">
            <span>{{ $t("owner.settings.timezone") }}</span>
            <BaseInput v-model="timeZone" :disabled="true" />
          </v-col>
        </v-row>
      </div>
      <ChangePassword />
      <div class="ps_details">
        <span class="heading-4">Visit settings</span>
        <v-row class="ps_row">
          <v-col class="ps_input-group" cols="3">
            <span>How far in to the future visit schedules can be?</span>
            <Select v-model="scheduleHorizonLimit" :items="scheduleHorizonLimitOptions" />
          </v-col>
          <v-col class="ps_input-group" cols="3">
            <span>Requests aren't allowed earlier than:</span>
            <Select v-model="scheduleToStartLimit" :items="scheduleToStartLimitOptions" />
          </v-col>
          <v-col class="ps_input-group" cols="3">
            <span>Visit duration</span>
            <Select v-model="slotDuration" :items="slotDurationOptions" />
          </v-col>
          <v-col class="ps_input-group" cols="3">
            <span>Visit can't be canceled earlier than :</span>
            <Select v-model="scheduleToCancelLimit" :items="scheduleToCancelLimitOptions" />
          </v-col>
        </v-row>
      </div>
    </v-card>
  </div>
</template>
<script>
import moment from "moment-timezone";
import { mapActions, mapState } from "pinia";
import { email, required } from "vuelidate/lib/validators";

import ChangePassword from "@/components/settings/changePassword";
import UploadHeader from "@/components/settings/uploadHeader";
import BaseInput from "@/components/uikit/BaseInput";
import PrimaryButton from "@/components/uikit/PrimaryButton";
import Select from "@/components/uikit/Select";
import { snackBarEventBus, snackBarEventName } from "@/eventBuses/snackBar.eventBus";
import { useProfileSettingsStore } from "@/pinia-store/profileSettings";
import { defaultLocale } from "@/utils/constants";

export default {
  name: "AdminSettings",
  components: {
    PrimaryButton,
    UploadHeader,
    BaseInput,
    Select,
    ChangePassword,
  },
  validations: {
    email: { required, email },
    firstName: { required },
    lastName: { required },
  },
  data: () => ({
    loading: false,
    buttonLoading: false,
    scheduleHorizonLimit: 30,
    scheduleHorizonLimitOptions: [7, 15, 30, 45, 60].map((day) => ({ value: day, text: `${day} days` })),
    scheduleToStartLimit: "00:30",
    scheduleToStartLimitOptions: Array.from(Array((60 / 15) * 24)).map((value, key) => {
      const time = moment("00:00", "HH:mm").add({ minute: key * 15 });
      return {
        value: time.format("HH:mm"),
        text: time.format(time.hour() > 0 ? "H[h]mm[min]" : "m[min]"),
      };
    }),
    slotDuration: "",
    slotDurationOptions: Array.from(Array((60 / 5) * 2 - 2)).map((value, key) => {
      const time = moment("00:15", "HH:mm").add({ minute: key * 5 });
      return {
        value: time.format("HH:mm"),
        text: time.format(time.hour() > 0 ? "H[h]mm[min]" : "m[min]"),
      };
    }),
    scheduleToCancelLimit: "",
    scheduleToCancelLimitOptions: Array.from(Array(60 / 5)).map((value, key) => {
      const time = moment("00:05", "HH:mm").add({ minute: key * 5 });
      return {
        value: time.format("HH:mm"),
        text: time.format(time.hour() > 0 ? "H[h]mm[min]" : "m[min]"),
      };
    }),
    firstName: "",
    lastName: "",
    locale: defaultLocale,
    email: "",
    timeZone: "",
    focusedField: null,
  }),
  computed: {
    ...mapState(useProfileSettingsStore, ["adminSettings", "avatar"]),
    emailErrors() {
      const errors = [];
      if (!this.$v.email.$dirty) return errors;
      !this.$v.email.email && errors.push(this.$t("validation.email"));
      !this.$v.email.required &&
        errors.push(this.$t("validation.required", { field: this.$t("general.inputs.email") }));
      return errors;
    },
    firstNameErrors() {
      const errors = [];
      if (!this.$v.firstName.$dirty) return errors;
      !this.$v.firstName.required &&
        errors.push(this.$t("validation.required", { field: this.$t("general.inputs.firstName") }));
      return errors;
    },
    lastNameErrors() {
      const errors = [];
      if (!this.$v.lastName.$dirty) return errors;
      !this.$v.lastName.required &&
        errors.push(this.$t("validation.required", { field: this.$t("general.inputs.lastName") }));
      return errors;
    },
  },
  methods: {
    ...mapActions(useProfileSettingsStore, ["getAdminSettings", "saveAdminChanges"]),
    onInputFocus(field) {
      this.focusedField = field;
      if (this[`${field}Model`] === "") {
        this[`${field}Model`] = this.adminSettings[field];
      }
    },
    async saveChanges() {
      this.buttonLoading = true;
      try {
        await this.saveAdminChanges({
          email: this.email,
          firstName: this.firstName,
          lastName: this.lastName,
          locale: this.locale,
          timeZone: this.timeZone,
          slotDuration: this.slotDuration,
          scheduleHorizonLimit: this.scheduleHorizonLimit,
          scheduleToStartLimit: this.scheduleToStartLimit,
          scheduleToCancelLimit: this.scheduleToCancelLimit,
        });
        snackBarEventBus.$emit(snackBarEventName, {
          message: this.$t("settings.success"),
          type: "success",
        });
        this.$root.$i18n.locale = this.locale;
      } catch (err) {
        console.error(err);
        snackBarEventBus.$emit(snackBarEventName, {
          message: (err && err.response && err.response.data && err.response.data.message) || err,
          type: "error",
        });
      } finally {
        this.buttonLoading = false;
      }
    },
    fillInfo() {
      this.email = this.adminSettings.email;
      this.firstName = this.adminSettings.firstName;
      this.lastName = this.adminSettings.lastName;
      this.timeZone = this.adminSettings.timeZone;
      this.locale = this.adminSettings.locale;
      this.slotDuration = this.adminSettings.slotDuration;
      this.scheduleHorizonLimit = this.adminSettings.scheduleHorizonLimit;
      this.scheduleToStartLimit = this.adminSettings.scheduleToStartLimit;
      this.scheduleToCancelLimit = this.adminSettings.scheduleToCancelLimit;
    },
  },
  async mounted() {
    this.loading = true;
    await this.getAdminSettings();
    this.fillInfo();
    this.loading = false;
  },
};
</script>
