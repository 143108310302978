<template>
  <v-container class="contact-info" fluid>
    <v-row>
      <slot name="header" />
      <v-col class="item pb-0" cols="12">
        <div v-if="showFullName" class="label">
          <img :src="require('@/assets/icons/gender.svg')" alt="Gender" />
          <span>{{ _gender }}</span>
        </div>
      </v-col>
      <v-col class="item" cols="6" md="4" sm="12">
        <div v-if="showFullName" class="label dark">
          <img :src="require('@/assets/icons/profile.svg')" alt="Gender" />
          <span>{{ patientFullName || "" }}</span>
        </div>
        <div v-if="!showFullName" class="label">
          <img :src="require('@/assets/icons/gender.svg')" alt="Gender" />
          <span>{{ _gender }}</span>
        </div>
        <div class="label">
          <img :src="require('@/assets/icons/profile.svg')" alt="Gender" />
          <span>{{ _birthDate }}</span>
        </div>
        <div class="label">
          <img :src="require('@/assets/icons/male_alt.svg')" alt="Gender" />
          <span>{{ practitionerFullName }}</span>
        </div>
      </v-col>
      <v-col class="item" cols="6" md="4" sm="12">
        <div class="label">
          <img :src="require('@/assets/icons/location.svg')" alt="Location" />
          <span>{{ location }}</span>
        </div>
        <div class="label">
          <img :src="require('@/assets/icons/home.svg')" alt="Gender" />
          <span>{{ patient.address || "" }}</span>
        </div>
        <div class="label">
          <img :src="require('@/assets/icons/postcode.svg')" alt="Zip Code" />
          <span>{{ patient.postalCode || "" }}</span>
        </div>
      </v-col>
      <v-col class="item" cols="6" md="4" sm="12">
        <div class="label">
          <img :src="require('@/assets/icons/mail.svg')" alt="Email" />
          <span>{{ patient.email || "" }}</span>
        </div>
        <div class="label">
          <img :src="require('@/assets/icons/phone.svg')" alt="Home phone number" />
          <span>{{ patient.homePhoneNumber || "" }}</span>
        </div>
        <div class="label">
          <img :src="require('@/assets/icons/mobile_alt.svg')" alt="Mobile phone number" />
          <span>{{ patient.phoneNumber || "" }}</span>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { upperFirst } from "lodash";
import { mapState } from "pinia";

import { timeDisplayFormat } from "@/components/Clinicians/constants";
import { useAuthStore } from "@/pinia-store/auth";
import { usePatientStore } from "@/pinia-store/patient";

export default {
  name: "PatientSummaryContactInfo",
  props: {
    item: [Object],
    minimalInfo: {
      type: Boolean,
      default: false,
    },
    showFullName: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState(useAuthStore, ["userIsPatient"]),
    ...mapState(usePatientStore, ["patient"]),
    _birthDate() {
      return this.item.birthDate ? this.$moment(this.item.birthDate).format(timeDisplayFormat) : "";
    },
    practitionerFullName() {
      return `${this.item.practitioner?.firstName || ""} ${this.item.practitioner?.lastName || ""}`;
    },
    patientFullName() {
      return `${this.item?.patient?.firstName || ""} ${this.item?.patient?.lastName || ""}`;
    },
    _gender() {
      return upperFirst(this.item.gender);
    },
    location() {
      return `${(this.item.city && `${this.item.city},`) || ""} ${this.item.state || ""}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.contact-info {
  padding: 20px;

  .item {
    display: flex;
    flex-direction: column;
    gap: 16px;

    .label {
      gap: 8px;
      flex-wrap: wrap;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      display: flex;
      align-items: center;
      color: #33343e;

      img {
        width: 16px;
      }
    }
  }
}
</style>
