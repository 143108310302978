import { email, required } from "vuelidate/lib/validators";

import { isValidPhoneNumber, isValidSSN, isValidZip } from "@/utils/validations";

export const validatorErrors = {
  data: () => ({
    validatorErrors: [],
    validateFields: [],
  }),
  validations() {
    const validationRules = {
      locale: { required },
      firstName: { required },
      lastName: { required },
      socialSecurityNumber: { required, isValidSSN },
      birthDate: { required },
      homePhoneNumber: { required, isValidPhoneNumber },
      faxNumber: { required, isValidPhoneNumber },
      city: { required },
      address: { required },
      state: { required },
      postalCode: { required, isValidZip },
      email: { required, email },
      familyRelationship: { required },
      message: { required },
      subject: { required },
      recipientName: { required },
    };
    const validations = {};
    this.validateFields.forEach((element) => {
      if (validationRules[element]) validations[element] = validationRules[element];
    });
    return validations;
  },
  computed: {
    recipientNameErrors() {
      if (!this.validateFields.includes("recipientName")) return [];
      const errors = [];
      if (!this.$v.recipientName.$dirty) return errors;
      !this.$v.recipientName.required &&
        errors.push(this.$t("validation.required", { field: "Recipient Name is required" }));
      return errors;
    },
    messageErrors() {
      if (!this.validateFields.includes("message")) return [];
      const errors = [];
      if (!this.$v.message.$dirty) return errors;
      !this.$v.message.required && errors.push(this.$t("validation.required", { field: "Message is required" }));
      return errors;
    },
    subjectErrors() {
      if (!this.validateFields.includes("subject")) return [];
      const errors = [];
      if (!this.$v.subject.$dirty) return errors;
      !this.$v.subject.required && errors.push(this.$t("validation.required", { field: "Subject is required" }));
      return errors;
    },
    firstNameErrors() {
      if (!this.validateFields.includes("firstName")) return [];
      const errors = [];
      if (!this.$v.firstName.$dirty) return errors;
      !this.$v.firstName.required &&
        errors.push(this.$t("validation.required", { field: this.$t("general.inputs.firstName") }));
      return errors;
    },

    lastNameErrors() {
      if (!this.validateFields.includes("lastName")) return [];
      const errors = [];
      if (!this.$v.lastName?.$dirty) return errors;
      !this.$v.lastName?.required &&
        errors.push(this.$t("validation.required", { field: this.$t("general.inputs.lastName") }));
      return errors;
    },
    cityErrors() {
      if (!this.validateFields.includes("city")) return [];

      const errors = [];
      if (!this.$v.city.$dirty) return errors;
      !this.$v.city.required && errors.push(this.$t("validation.required", { field: this.$t("general.inputs.city") }));
      return errors;
    },
    homePhoneNumberErrors() {
      if (!this.validateFields.includes("homePhoneNumber")) return [];

      const errors = [];
      if (!this.$v.homePhoneNumber.$dirty) return errors;
      (!this.$v.homePhoneNumber.required || !this.$v.homePhoneNumber.isValidPhoneNumber) &&
        errors.push(this.$t("validation.required", { field: "Mobile phone number" }));
      return errors;
    },
    faxNumberErrors() {
      if (!this.validateFields.includes("faxNumber")) return [];

      const errors = [];
      if (!this.$v.faxNumber.$dirty) return errors;
      (!this.$v.faxNumber.required || !this.$v.faxNumber.isValidPhoneNumber) &&
        errors.push(this.$t("validation.required", { field: "Fax number" }));
      return errors;
    },
    socialSecurityNumberErrors() {
      if (!this.validateFields.includes("socialSecurityNumber")) return [];
      if (!this.$v.socialSecurityNumber.$dirty) return [];

      const errors = [];
      if (!this.$v.socialSecurityNumber.required || !this.$v.socialSecurityNumber.isValidSSN)
        errors.push(this.$t("validation.required", { field: "Social security number" }));

      return errors;
    },
    stateErrors() {
      if (!this.validateFields.includes("state")) return [];

      const errors = [];
      if (!this.$v.state.$dirty) return errors;
      !this.$v.state.required &&
        errors.push(this.$t("validation.required", { input: this.$t("general.inputs.state") }));
      return errors;
    },
    emailErrors() {
      if (!this.validateFields.includes("email")) return [];
      const errors = [];
      if (!this.$v?.email?.$dirty) return errors;
      !this.$v?.email?.email && errors.push(this.$t("validation.email"));
      !this.$v?.email?.required &&
        errors.push(this.$t("validation.required", { field: this.$t("general.inputs.email") }));
      return errors;
    },
    dateOfBirthErrors() {
      if (!this.validateFields.includes("dateOfBirth")) return [];
      const errors = [];
      if (!this.$v?.dateOfBirth?.$dirty) return errors;
      !this.$v?.dateOfBirth?.required &&
        errors.push(this.$t("validation.required", { field: this.$t("general.inputs.dateOfBirth") }));
      return errors;
    },
    birthDateErrors() {
      if (!this.validateFields.includes("birthDate")) return [];
      const errors = [];
      if (!this.$v?.birthDate?.$dirty) return errors;
      !this.$v?.birthDate?.required &&
        errors.push(this.$t("validation.required", { field: this.$t("general.inputs.dateOfBirth") }));
      return errors;
    },
    phoneNumberErrors() {
      if (!this.validateFields.includes("phoneNumber")) return [];

      const errors = [];
      if (!this.$v?.phoneNumber?.$dirty) return errors;
      (!this.$v?.phoneNumber?.required || !this.$v?.phoneNumber?.isValidPhoneNumber) &&
        errors.push(this.$t("validation.required", { field: "Mobile phone number" }));
      return errors;
    },
    postalCodeErrors() {
      if (!this.validateFields.includes("postalCode")) return [];

      const errors = [];
      if (!this.$v.postalCode.$dirty) return errors;
      (!this.$v.postalCode.required || !this.$v.postalCode.isValidZip) &&
        errors.push(this.$t("validation.required", { field: "Zip Code" }));
      return errors;
    },
    addressErrors() {
      if (!this.validateFields.includes("address")) return [];
      const errors = [];

      if (!this.$v.address.$dirty) return errors;
      !this.$v.address.required &&
        errors.push(this.$t("validation.required", { field: this.$t("general.inputs.address") }));
      return errors;
    },
    familyRelationshipErrors() {
      if (!this.validateFields.includes("familyRelationship")) return [];

      const errors = [];
      if (!this.$v.familyRelationship.$dirty) return errors;
      !this.$v.familyRelationship.required &&
        errors.push(this.$t("validation.required", { field: "familyRelationship" }));
      return errors;
    },
  },
  methods: {
    validateFieldComputed(field, propertyName, errorMsg = "Required") {
      return this[propertyName].find((e) => e.property === field) ? [errorMsg] : [];
    },
    validateField(field, errorMsg) {
      const errorObject = this.validatorErrors.find((e) => e.property === field);
      if (!errorObject) return;
      let errorKeys = Object.keys(errorObject.constraints || {});
      if (!errorKeys) return;
      return errorKeys.map((key) => errorMsg || errorObject?.constraints[key]);
    },
    setResponseErrors(error) {
      this.validatorErrors = error?.validatorErrors || [];
    },
  },
};
