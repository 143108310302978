<template>
  <div class="patient-media">
    <v-row>
      <v-col>
        <v-card class="patient-media__container">
          <v-row>
            <v-col class="patient-media__title-wrap" cols="12">
              <div class="title">Attachments</div>
              <v-select
                :items="selectValues"
                :value="fileType"
                class="patient-media__select"
                dense
                hide-details="true"
                outlined
                placeholder="Day"
                @change="changeFileType($event)"
              ></v-select>
            </v-col>
          </v-row>
          <MediaUploader v-if="component === 'dependents' ? role !== 'patient' : true" class="mt-3" />
          <MediaList :hideActions="role === 'patient' && component === 'dependents'" />
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapActions, mapState } from "pinia";

import MediaList from "@/components/media/MediaList";
import MediaUploader from "@/components/MedicalRecord/MediaUploader";
import { useAuthStore } from "@/pinia-store/auth";
import { useMediaStore } from "@/pinia-store/media";

export default {
  name: "PatientMedia",
  props: {
    component: { type: String, default: "" },
  },
  components: {
    MediaList,
    MediaUploader,
  },
  data: () => {
    return {
      selectValues: [
        {
          text: "All",
          value: "all",
        },
        {
          text: "Image",
          value: "image",
        },
        {
          text: "Documents",
          value: "document",
        },
      ],
      settings: {
        dots: false,
        arrows: true,
        centerMode: true,
        centerPadding: "20px",
        variableWidth: true,
      },
      loading: false,
      slideOptions: {
        prevNextButtons: true,
      },
    };
  },
  methods: {
    ...mapActions(useMediaStore, ["fetchFiles", "emptyFiles", "setFileType", "fetchDependentsFiles"]),
    ...mapActions(useMediaStore, ["setUid", "setComponent"]),
    changeFileType(value) {
      this.setFileType(value);
      if (this.component === "dependents") {
        this.setComponent("dependents");
        this.fetchDependentsFiles();
      } else {
        this.fetchFiles();
      }
    },
    show(image) {
      this.$viewerApi({
        images: [image],
      });
    },
  },
  computed: {
    ...mapState(useMediaStore, ["files", "fileType"]),
    ...mapState(useAuthStore, ["role"]),
  },
};
</script>

<style lang="scss">
.patient-media {
  &__select {
    max-width: 115px;
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;
    z-index: 3;
  }

  &__title-wrap {
    display: flex;
    justify-content: space-between;

    .title {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      color: #25233a;
      opacity: 0.4;
    }

    .link {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      text-align: right;
      color: #2196f3;
      cursor: pointer;
    }
  }

  &__container {
    padding: 15px 20px;
    box-shadow: none !important;
  }
}
</style>
