<template>
  <div class="d-appoinments">
    <v-row class="d-appoinments_header" justify="space-between" no-gutters>
      <v-col class="d-appoinments_header_title">
        <span v-if="this.$route.meta.layout === 'default'" class="heading-2"> Clinic Schedule </span>
        <template v-if="this.$route.meta.layout === 'edit'">
          <span class="heading-2">
            {{ $t("practitioner.appointments.editAvailability.title") }}
          </span>
          <PrimaryButton
            :loading="loading"
            :text="$t('practitioner.appointments.saveChanges')"
            size="medium"
            @onClick="saveAvailability"
          />
        </template>
      </v-col>

      <v-col v-if="this.$route.meta.layout === 'default'" class="d-appoinments_header_actions">
        <PrimaryButton
          :text="$t('practitioner.appointments.scheduleVisit')"
          leftIcon="mdi-plus"
          size="medium"
          @onClick="toSchedule"
        />
      </v-col>
    </v-row>
    <Tabs :tabs="tabs" />
    <router-view></router-view>

    <ScheduleDialog :dialog="scheduleDialog" @on-close="scheduleDialog = false" />
  </div>
</template>
<script>
import { mapActions } from "pinia";

import Tabs from "@/components/shared/Tabs";
import PrimaryButton from "@/components/uikit/PrimaryButton";
import { snackBarEventBus, snackBarEventName } from "@/eventBuses/snackBar.eventBus";
import { useSlotsStore } from "@/pinia-store/slots";
import { routesEnum } from "@/types/Routes.enum";
import ScheduleDialog from "@/views/Owner/Appoinments/ScheduleDialog";

export default {
  name: "AppointmentsView",
  components: {
    Tabs,
    PrimaryButton,
    ScheduleDialog,
  },
  data: () => ({
    loading: false,
    scheduleDialog: false,
  }),
  computed: {
    tabs() {
      return [
        {
          value: this.$t("practitioner.appointments.scheduleCalendar"),
          to: "/registrar/appointments",
        },
        {
          value: `${this.$t("general.status.scheduled")} ${this.$t("owner.appointments.title")}`,
          to: "/registrar/appointments/scheduled",
        },
      ];
    },
  },
  methods: {
    ...mapActions(useSlotsStore, ["saveOwnerAvailability"]),
    async saveAvailability() {
      this.loading = true;
      try {
        await this.saveOwnerAvailability();
        await this.$router.push({ name: routesEnum.practitionerSchedule });
        snackBarEventBus.$emit(snackBarEventName, {
          message: this.$t("owner.appointments.availabilityWasChanged"),
          type: "success",
        });
      } catch (err) {
        console.error(err);
        snackBarEventBus.$emit(snackBarEventName, {
          message: (err && err.response && err.response.data && err.response.data.message) || err.message || err,
          type: "error",
        });
      }
      this.loading = false;
    },
    async toEditAvailabilty() {
      await this.$router.push({ path: "/owner/appointments/edit" });
    },
    toSchedule() {
      this.scheduleDialog = true;
    },
  },
};
</script>
