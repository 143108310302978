<template>
  <Confirm
    :dialog="inviteDialog"
    :hide-cancel="true"
    :hide-confirm="true"
    cancelText="Close"
    max-width="90%"
    rootClass="media-dialog"
    text="Please complete required information to invite patient to visit via phone or email."
    title="Invite to meeting room"
    width="470px"
    @toggleDialog="toggleInviteDialog"
  >
    <v-container class="no-padding-bottom no-padding-top">
      <MeetingRoomCreateForm kind="dialog" />
    </v-container>
  </Confirm>
</template>

<script>
import { mapActions, mapState } from "pinia";

import Confirm from "@/components/shared/Confirm";
import { useMeetingRoomStore } from "@/pinia-store/meetingRoom";
import MeetingRoomCreateForm from "@/views/Practitioner/MeetingRoom/Create/Form";

export default {
  name: "CreateMeetingRoomModal",
  components: { Confirm, MeetingRoomCreateForm },
  computed: {
    ...mapState(useMeetingRoomStore, ["inviteDialog"]),
  },
  methods: {
    ...mapActions(useMeetingRoomStore, ["toggleInviteDialog"]),
  },
};
</script>
