
import Vue from "vue";

import ChatButton from "@/components/Chat/Message/ChatButton.vue";
import BaseMessage from "@/components/Chat/Message/MessageTypes/BaseMessage.vue";
import ButtonDto from "@/types/ButtonDto";

export default Vue.extend({
  name: "ChatLogMessageWithButtons",

  components: { BaseMessage, ChatButton },

  props: {
    message: {
      type: Object,
      required: true,
    },
    responseDelegate: Function,
  },
  data: () => ({
    value: "",
    clickedButtons: {} as Record<string | number, boolean>,
    allDisabled: true,
  }),
  methods: {
    hasBeenClicked(button: ButtonDto) {
      const payload = this.message.attributes.selectedOption?.payload ?? this.message.attributes.selectedOption;
      if (payload === button.value) {
        this.allDisabled = true;
        return true;
      }
      return false;
    },
    onButtonClick() {
      this.allDisabled = true;
    },
    isLastButton(buttons: [], index: number): boolean {
      return index === buttons.length - 1;
    },
  },
});
