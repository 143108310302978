<template>
  <v-menu
    ref="menu"
    v-model="menu"
    :close-on-content-click="true"
    transition="scale-transition"
    offset-y
    min-width="200px"
  >
    <template v-slot:activator="{ on }">
      <v-text-field
        class="base-input data-picker-input"
        :value="value"
        :placeholder="$t('general.calendar.year')"
        solo
        :height="height"
        flat
        readonly
        dense
        hide-details
        v-on="on"
      >
        <template v-slot:append>
          <v-icon v-on="on" color="icon" size="15">$vuetify.icons.calendar</v-icon>
        </template>
      </v-text-field>
    </template>
    <v-date-picker ref="picker" no-title @input="onYearChange" :max="maxYear" :min="minYear" reactive color="primary" />
  </v-menu>
</template>
<script>
export default {
  name: "YearPicker",
  props: ["value", "height"],
  data: () => ({
    menu: false,
    maxYear: new Date().toISOString().substr(0, 10),
    minYear: new Date("1920").toISOString().substr(0, 10),
  }),
  watch: {
    menu(val) {
      val && this.$nextTick(() => (this.$refs.picker.activePicker = "YEAR"));
    },
  },
  methods: {
    onYearChange(value) {
      const year = value.split("-")[0];
      this.menu = false;
      this.$emit("change", year);
    },
  },
};
</script>

<style lang="scss">
.data-picker-input {
  .v-input__slot {
    margin-bottom: 0 !important;
  }
}
</style>
