<template>
  <v-col cols="12" md="6" class="p-success-payment_middle_left">
    <div class="heading-5 p-success-payment_middle_left_title">
      {{ $t("practitioner.appointments.success.visitDetails") }}
    </div>
    <div>
      <span class="p-success-payment_middle_left_pre-text">{{ $t("practitioner.type.therapist") }}: </span>
      <span>{{ therapist }}</span>
    </div>
    <div>
      <span class="p-success-payment_middle_left_pre-text">{{ $t("visits.waiting.patient") }}: </span>
      <span>{{ name }}</span>
    </div>
    <div>
      <span class="p-success-payment_middle_left_pre-text">{{ $t("general.inputs.email") }}: </span>
      <span>{{ email }}</span>
    </div>
  </v-col>
</template>

<script>
export default {
  props: ["patient", "practitioner"],
  name: "SuccessPaymentPatientDetails",
  computed: {
    name() {
      return this.patient && this.patient.firstName ? `${this.patient.firstName} ${this.patient.lastName}` : "-";
    },
    email() {
      return this.patient ? this.patient.email : "-";
    },
    therapist() {
      return this.practitioner && this.practitioner.firstName
        ? `${this.practitioner.firstName} ${this.practitioner.lastName}`
        : "-";
    },
  },
};
</script>
