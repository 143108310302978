<template>
  <v-menu
    v-model="menu"
    :close-on-content-click="false"
    content-class="date-picker"
    min-width="290px"
    offset-y
    transition="scale-transition"
  >
    <template v-slot:activator="{ on }">
      <v-text-field
        :class="{ 'base-input': true, [className]: true }"
        :dense="dense"
        :error-messages="errors"
        :flat="!outlined"
        :height="height"
        :hide-details="hideDetails"
        :label="placeholder"
        :outlined="!!errors.length || outlined"
        :solo="!outlined"
        :value="dateRangeText"
        light
        readonly
        @blur="onBlur"
        @focus="outlined = true"
        v-on="on"
      >
        <template v-slot:append>
          <v-icon color="icon" size="15" v-on="on">$vuetify.icons.calendar</v-icon>
        </template>
      </v-text-field>
    </template>
    <v-date-picker
      ref="picker"
      v-model="dates"
      :disabled="disabled"
      :no-title="hideTitle"
      :value="dates"
      class="picker"
      color="primary"
      range
      reactive
      @click:date="updateDate"
    />
  </v-menu>
</template>

<script>
import moment from "moment-timezone";
import { mapState } from "pinia";

import { timeDisplayFormat } from "@/components/Clinicians/constants";
import { useAuthStore } from "@/pinia-store/auth";

export default {
  name: "RangeDatePicker",
  props: {
    className: { default: "", type: String },
    dense: { default: false, type: Boolean },
    height: { default: 47, type: Number },
    placeholder: String,
    errors: { default: () => [], type: Array },
    value: Array,
    maxDate: String,
    isYearFirst: { default: true, type: Boolean },
    inputValue: { default: null, type: Array },
    hideDetails: { default: false, type: Boolean },
    hideTitle: { default: false, type: Boolean },
    minDate: { default: undefined, type: String },
    disabled: { default: false, type: Boolean },
    clearRangeDatePicker: { default: false, type: Boolean },
  },
  watch: {
    clearRangeDatePicker(newValue, oldValue) {
      if (newValue && !oldValue) {
        this.dates = [this.$moment.tz(this.timeZone).format("YYYY-MM-DD")];
      }
    },
    value: {
      immediate: true,
      handler(newValue) {
        if (newValue) {
          this.dates = newValue;
        }
      },
    },
    menu(val) {
      this.isYearFirst && val && this.$nextTick(() => (this.$refs.picker.activePicker = "YEAR"));
    },
  },
  data: () => ({
    picker: new Date().toISOString().substr(0, 10),
    menu: false,
    outlined: false,
    dates: [moment().format("YYYY-MM-DD")],
  }),
  computed: {
    ...mapState(useAuthStore, ["timeZone"]),
    dateRangeText() {
      return this.value.map((val) => moment(val).format(timeDisplayFormat)).join(" - ");
    },
  },
  methods: {
    updateDate(date) {
      this.$emit("setDate", this.dates);
      this.$emit("input", this.dates);
    },
    onBlur() {
      this.outlined = false;
      this.$emit("blur");
    },
  },
};
</script>

<style scoped></style>
