<template>
  <v-dialog :value="dialog" width="500" @click:outside="closeDialog">
    <v-card>
      <div class="dialog__head px-7 pt-7 d-flex justify-space-between align-center">
        <v-card-title class="dialog__head__title">
          Availabilities on {{ availabilities.length && formatDate(availabilities[0].start).format("MMMM D") }}
        </v-card-title>
        <v-icon class="pa-2" size="medium" @click="closeDialog">mdi-close</v-icon>
      </div>
      <v-list>
        <v-list-item-group>
          <v-list-item v-for="availability in availabilities" :key="availability.id || availability.repeat_id">
            <v-list-item-icon
              v-if="
                availability.source &&
                availability.source.frequencyType &&
                availability.source &&
                availability.source.frequencyType === 'none'
              "
            >
              <v-icon color="primary"> mdi-calendar-today</v-icon>
            </v-list-item-icon>
            <v-list-item-icon v-else>
              <img alt="repeat" src="@/assets/icons/repeat-blue.svg" width="20px" />
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title
                >{{ `${formatHour(availability.start)}-${formatHour(availability.end)}` }}
              </v-list-item-title>
            </v-list-item-content>
            <v-list-item-action>
              <v-btn icon @click="openDeleteDialog(availability)">
                <v-icon color="error">mdi-trash-can-outline</v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
        </v-list-item-group>
      </v-list>
      <v-card-actions class="justify-end pr-7 py-5">
        <OutlinedButton
          :text="$t('general.dialog.cancel')"
          additional-class-name="pa-5"
          color="danger"
          @onClick="closeDialog"
        />
      </v-card-actions>
    </v-card>
    <Delete
      :closeDialog="closeDeleteModal"
      :closeUpdateHoursDialog="closeDialog"
      :dialog="deleteModal"
      @onConfirm="onDeleteClick"
    />
  </v-dialog>
</template>
<script>
import { mapActions, mapState } from "pinia";

import Delete from "@/components/EditAvailability/Modals/Delete";
import OutlinedButton from "@/components/uikit/OutlinedButton";
import { useSlotsStore } from "@/pinia-store/slots";
import { FrequencyTypeEnum } from "@/types/FrequencyType.enum";

export default {
  name: "More",
  props: ["dialog", "closeDialog", "availabilities"],
  components: {
    OutlinedButton,
    Delete,
  },
  data() {
    return {
      deleteModal: false,
      updatedEvent: {},
    };
  },

  computed: {
    ...mapState(useSlotsStore, ["customAvailabilities"]),
  },
  methods: {
    ...mapActions(useSlotsStore, ["setAvailabilities", "setCustomAvailabilities"]),
    formatDate(date = undefined) {
      return this.$moment(date);
    },
    formatHour(date = undefined) {
      return this.$moment(date).format("HH:mm");
    },
    openDeleteDialog(event) {
      this.updatedEvent = { ...event };
      this.deleteModal = true;
    },
    onDeleteClick() {
      let newCustomAvailabilities = [...this.customAvailabilities];
      if (this.updatedEvent.source.frequencyType === FrequencyTypeEnum.none) {
        newCustomAvailabilities = newCustomAvailabilities.filter((i) => {
          return i.id !== this.updatedEvent.source.id;
        });
      }

      if (this.updatedEvent.source.frequencyType === FrequencyTypeEnum.weekdays) {
        let index = newCustomAvailabilities.findIndex((i) => i.id !== this.updatedEvent.source.id);
        // remove one hour
        newCustomAvailabilities[index].hours = newCustomAvailabilities[index].hours.filter(
          (i) =>
            this.$moment(`${i.date} ${i.start}`).isSame(this.$moment(this.updatedEvent.start)) &&
            this.$moment(`${i.date} ${i.end}`).isSame(this.$moment(this.updatedEvent.end)),
        );
        // if aren't any, hours remove event
        if (newCustomAvailabilities[index].hours.length === 0)
          newCustomAvailabilities = newCustomAvailabilities.filter((i) => i.id !== this.updatedEvent.source.id);
      }
      this.setCustomAvailabilities([...newCustomAvailabilities]);
      this.closeDeleteModal();
      this.closeDialog();
    },
    closeDeleteModal() {
      this.deleteModal = false;
    },
  },
};
</script>
