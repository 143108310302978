enum DialogNamesEnum {
  OpeningDialog = "OpeningDialog",
  MedicalDialog = "MedicalDialog",
  ConsultationDialog = "ConsultationDialog",
  TriageDialog = "TriageDialog",
  AppointmentDispatchDialog = "AppointmentDispatchDialog",
  CreateAppointmentDialog = "CreateAppointmentDialog",
  EditAppointmentDialog = "EditAppointmentDialog",
  CancelAppointmentDialog = "CancelAppointmentDialog",
  SelectPractitionerDialog = "SelectPractitionerDialog",
  QnaDialog = "QnaDialog",
}
export default DialogNamesEnum;
