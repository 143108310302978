import { defineStore } from "pinia";

export enum voipCurrentState {
  notStarted = "notStarted",
  loading = "loading",
  accepted = "accepted",
}

export enum voipDialogType {
  none = "none",
  floating = "floating",
  dialog = "dialog",
}

export interface VoIPState {
  state: voipCurrentState;
  recordingOpened: boolean;
  phoneNumber: string;
  dialogType: voipDialogType;
}

export const useVoipStore = defineStore({
  id: "voip",
  state: (): VoIPState => ({
    recordingOpened: false,
    state: voipCurrentState.notStarted,
    phoneNumber: "",
    dialogType: voipDialogType.none,
  }),
  actions: {
    setRecordingOpened(payload: boolean) {
      this.recordingOpened = payload;
    },
    changePhone(payload: string) {
      this.phoneNumber = payload;
    },
    changeState(payload: voipCurrentState) {
      this.state = payload;
    },
    setDialog(payload: voipDialogType) {
      this.dialogType = payload;
    },
  },
});
