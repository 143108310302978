import { apiRequest } from "./apiRequest";

const searchMedication = async (name: string) => {
  const { data } = await apiRequest.get("/dosespot/medication", { params: { name } });
  return data;
};
const searchPharmacy = async ({ name = "" } = {}) => {
  const { data } = await apiRequest.get(`/dosespot/pharmacies`, { params: { name } });
  return data;
};
const initUI = async (patientId: string) => {
  return new Promise((resolve, reject) => {
    apiRequest
      .get("/dosespot/ui", { params: { patientId } })
      .then((r) => resolve(r.data))
      .catch(({ response }) => reject(response));
  });
};

const createUserProfile = async (patientId: string) => {
  const { data } = await apiRequest.post("/dosespot/create-user", { patientId });
  return data;
};

export const DosespotApi = {
  searchMedication,
  createUserProfile,
  searchPharmacy,
  initUI,
};
