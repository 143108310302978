<template>
  <v-dialog :max-width="maxWidth" :value="value" :width="width" @click:outside="handleClose">
    <v-card class="card">
      <v-btn class="close-btn" icon @click="handleClose">
        <img alt="Close" src="@/assets/icons/close-light.svg" />
      </v-btn>
      <v-card-title class="card-title"> Select period</v-card-title>
      <div class="advanced-search-modal-date">
        <div class="advanced-search-modal-date-text align-center">Add dates</div>
        <div class="date-ranges">
          <AdvancedSearchDatePicker
            v-model="startDate"
            :dense="true"
            :height="46"
            :hideDetails="true"
            :inputValue="startDate"
            :isYearFirst="false"
            class="advanced-search-modal-date-picker align-center"
            @setDate="changeCurrentDate"
          />
          <div class="advanced-search-modal-date-spacer align-center">-</div>
          <AdvancedSearchDatePicker
            v-model="endDate"
            :dense="true"
            :height="46"
            :hideDetails="true"
            :inputValue="endDate"
            :isYearFirst="false"
            class="advanced-search-modal-date-picker align-center"
            @setDate="changeCurrentDate"
          />
        </div>
      </div>
      <slot />
      <v-card-actions class="actions d-fle">
        <v-btn class="mr-3" color="icon" height="46" outlined width="71" @click="handleClear">Clear</v-btn>
        <v-btn class="elevation-0" color="primary" height="46" width="75" @click="handleApply">Apply</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import moment from "moment-timezone";

import AdvancedSearchDatePicker from "./AdvancedSearchDatePicker";

export default {
  name: "AdvancedSearchModal",
  props: {
    value: Boolean,
    maxWidth: {
      type: String,
      default: `90%`,
    },
    width: {
      type: String,
      default: `524px`,
    },
    rootClass: {
      type: String,
      default: "",
    },
    wrapClass: {
      type: String,
      default: "",
    },
  },

  components: {
    AdvancedSearchDatePicker,
  },
  data: () => ({
    startDate: new Date(moment().subtract(1, "day").valueOf()),
    endDate: new Date(moment().valueOf()),
  }),
  methods: {
    changeCurrentDate(newDate) {
      this.currentDate = newDate;
    },
    handleApply() {
      this.$emit("filter-by-date", { startDate: this.startDate, endDate: this.endDate });
      this.handleClose();
    },
    handleClear() {
      this.startDate = null;
      this.endDate = null;
    },
    handleClose() {
      this.$emit("close-adv-search-modal");
    },
  },
};
</script>

<style lang="scss" scoped>
.card {
  padding: 1rem;
  position: relative;

  .close-btn {
    position: absolute !important;
    top: 17px;
    right: 16px;
    width: 26px;
    height: 26px;
  }

  .actions {
    padding-top: 24px;
    justify-content: flex-end;
    display: flex;
  }
}

.advanced-search-modal-buttons {
  margin: 6px 30px 0 30px;
  justify-content: flex-end;
  flex-flow: row nowrap;
  flex: 1 1 auto;
  display: flex;
}

.align-center {
  align-self: center;
}

.advanced-search-modal-date {
  padding: 8px 16px;
  margin-top: 22px;
  flex-flow: row nowrap;
  flex: 1 1 auto;
  align-items: center;
  display: flex;
  gap: 10px;
  justify-content: space-between;

  .date-ranges {
    display: flex;
    gap: 4px;
    justify-content: flex-end;
  }

  &-spacer {
    padding: 0 10px 0 10px;
    opacity: 0.4;
  }
}

.advanced-search-modal-main {
  margin-top: 10px;
  margin-left: 16px;
  width: 524px;
  height: 248px;
  position: absolute;
  border: 1px solid #e7e8f2;
  box-sizing: border-box;
  box-shadow: 0 18px 24px rgba(0, 0, 0, 0.14);
  border-radius: 4px;
  background: white;
}

.advanced-search-modal-header-close {
  position: absolute;
  right: 13px;
  top: 15px;
  width: 24px;
  height: 24px;
  border: 1px solid #e7e8f2;
  background: white;
  border-radius: 50%;

  &:hover {
    background: #ececec;
  }
}

.advanced-search-modal-header {
  position: relative;
  //background: red;
  padding-top: 32px;
  padding-left: 30px;
  text-align: start;

  &-text {
    font-style: normal;
    font-weight: 500;
    font-size: 25px;
    line-height: 31px;

    color: #25233a;
  }
}

.close {
  background: #ffffff;
  border: 1px solid #e7e8f2;
  border-radius: 100px;
  position: absolute;

  &:before,
  &:after {
    top: 6px;
    position: absolute;
    left: 10px;
    content: " ";
    height: 10px;
    width: 2px;
    background-color: #e7e8f2;
  }

  &:hover {
    background-color: #b1b2bb;
  }

  &:before {
    transform: rotate(45deg);
  }

  &:after {
    transform: rotate(-45deg);
  }
}
</style>
