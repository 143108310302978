<template>
  <v-row justify="center">
    <v-dialog
      :value="dialog"
      max-width="300px"
      scrollable
      @input="$emit('dialog')"
      @click:outside="closeRegistrarsModal"
    >
      <v-card :loading="loading" class="card">
        <v-icon class="card-close" color="icon" size="medium" @click="closeRegistrarsModal"> mdi-close</v-icon>
        <v-card-title>Registrars</v-card-title>
        <v-divider></v-divider>
        <v-card-text style="height: 300px">
          <v-list flat>
            <v-list-item v-for="registrar in registrars" :key="registrar.id">
              <v-list-item-content>
                <v-list-item-title>{{ registrar.name }}</v-list-item-title>
              </v-list-item-content>
              <v-list-item-action>
                <v-btn icon @click="removeRegistrar(registrar.id)">
                  <v-icon color="grey lighten-1" small>mdi-close</v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
            <v-list-item v-if="!registrars.length">
              <v-list-item-content>No items</v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="justify-end">
          <v-btn class="btn btn-cancel" color="#b1b1b1" outlined @click="closeRegistrarsModal">
            <span>{{ $t("general.dialog.cancel") }}</span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { mapActions } from "pinia";

import { snackBarEventBus, snackBarEventName } from "@/eventBuses/snackBar.eventBus";
import { usePractitionersStore } from "@/pinia-store/practitioners";

export default {
  name: "RegistrarsModal",
  props: ["dialog", "practitionerId", "registrars"],
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    ...mapActions(usePractitionersStore, ["getPractitioners", "removeAssignedRegistrar"]),
    closeRegistrarsModal() {
      this.$emit("dialog");
      this.$emit("closeRegistrarsModal");
    },
    async removeRegistrar(registrarId) {
      this.loading = true;
      try {
        await this.removeAssignedRegistrar({ practitionerId: this.practitionerId, registrarId });
        this.closeRegistrarsModal();
        await this.getPractitioners();
        snackBarEventBus.$emit(snackBarEventName, {
          message: "Registrar Was Successfully Removed!",
          type: "success",
        });
      } catch (err) {
        console.error(err);
        snackBarEventBus.$emit(snackBarEventName, {
          message: err.message || err,
          type: "error",
        });
      }
      this.loading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.card {
  position: relative;

  &-close {
    position: absolute !important;
    top: 17px;
    right: 16px;
    width: 0.1rem;
    height: 0.1rem;
  }
}

.btn {
  text-transform: capitalize;
  font-weight: 600;

  &_cancel {
    color: #b1b1b1;
  }
}

.actions {
  justify-content: flex-end;
  display: flex;
}
</style>
