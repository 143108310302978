<template>
  <div class="ps_details">
    <span class="heading-4">{{ $t("settings.changePassword.title") }}</span>
    <v-row class="ps_row d-flex align-center">
      <v-col class="ps_input-group" cols="12" md="3">
        <span class="inputPasswords">{{ $t("settings.changePassword.oldPassword") }}</span>
        <PasswordInput
          v-model="oldPassword"
          :errors="oldPasswordErrors"
          :hideDetails="false"
          class-name="field-details-absolute"
          @blur="$v.oldPassword.$touch()"
          @change="onOldPasswordChange"
        />
      </v-col>
      <v-col class="ps_input-group" cols="12" md="3">
        <span class="inputPasswords">{{ $t("settings.changePassword.newPassword") }}</span>
        <PasswordInput
          v-model="password"
          :errors="passwordErrors"
          :hideDetails="false"
          :isPasswordHelper="true"
          :passwordRootChecks="passwordChecks"
          class-name="field-details-absolute"
          @blur="$v.password.$touch()"
          @change="onPasswordChange"
        />
      </v-col>
      <v-col class="ps_input-group" cols="12" md="3">
        <span class="inputPasswords">{{ $t("settings.changePassword.confirm") }}</span>
        <PasswordInput
          v-model="confirmedPassword"
          :errors="confirmedPasswordErrors"
          :hideDetails="false"
          class-name="field-details-absolute"
          @blur="$v.confirmedPassword.$touch()"
          @change="onConfirmedPasswordChange"
        />
      </v-col>
      <v-col class="ps_btn-wrapper" cols="12" lg="1" md="4">
        <PrimaryButton
          :disabled="disabledButton"
          :loading="loading"
          :text="$t('settings.changePassword.change')"
          class="col-12 fontSize14"
          size="large"
          @onClick="submit"
        />
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { mapActions } from "pinia";
import { required } from "vuelidate/lib/validators";

import PasswordInput from "@/components/shared/PasswordInput";
import PrimaryButton from "@/components/uikit/PrimaryButton";
import { snackBarEventBus, snackBarEventName } from "@/eventBuses/snackBar.eventBus";
import { useProfileSettingsStore } from "@/pinia-store/profileSettings";

const passwordMatch = (value, vm) => {
  return value === vm.password;
};
const passwordValid = (value, vm) => {
  return vm.passwordChecks.some((pc) => pc.value);
};
const passwordMinLength = (value, vm) => {
  return value.length >= 6;
};
export default {
  name: "ChangePassword",
  components: {
    PrimaryButton,
    PasswordInput,
  },
  validations: {
    password: { required, passwordValid, passwordMinLength },
    confirmedPassword: { required, passwordMatch },
    oldPassword: { required },
  },
  data: () => ({
    oldPassword: "",
    password: "",
    confirmedPassword: "",
    loading: false,
  }),
  computed: {
    disabledButton() {
      const checks = [
        this.oldPassword,
        this.password,
        this.confirmedPassword,
        !this.passwordErrors.length,
        !this.confirmedPasswordErrors.length,
      ];
      return checks.some((el) => !el);
    },
    passwordErrors() {
      const errors = [];
      if (!this.$v.password.$dirty) return errors;
      !this.$v.password.required &&
        errors.push(this.$t("validation.required", { field: this.$t("settings.changePassword.password") }));
      !this.$v.password.passwordMinLength && errors.push(this.$t("validation.passwordMinLength"));
      !this.$v.password.passwordValid && errors.push(this.$t("validation.passwordMustApply"));
      return errors;
    },
    oldPasswordErrors() {
      const errors = [];
      if (!this.$v.oldPassword.$dirty) return errors;
      !this.$v.oldPassword.required &&
        errors.push(this.$t("validation.required", { field: this.$t("settings.changePassword.password") }));
      return errors;
    },
    confirmedPasswordErrors() {
      const errors = [];
      if (!this.$v.confirmedPassword.$dirty) return errors;
      !this.$v.confirmedPassword.passwordMatch && errors.push(this.$t("validation.passwordMatch"));
      !this.$v.confirmedPassword.required &&
        errors.push(this.$t("validation.required", { field: this.$t("settings.changePassword.password") }));
      return errors;
    },
    passwordChecks() {
      return [
        {
          value: this.password.length >= 8,
          text: this.$t("settings.changePassword.hint.length"),
          id: "characters",
        },
        {
          value: /(?=.*[a-z])(?=.*[A-Z])/.test(this.password),
          text: this.$t("settings.changePassword.hint.uppercase"),
          id: "letters",
        },
        {
          value: /(?=.*\d)/.test(this.password),
          text: this.$t("settings.changePassword.hint.number"),
          id: "digits",
        },
      ];
    },
  },
  methods: {
    ...mapActions(useProfileSettingsStore, ["resetPassword"]),
    async submit() {
      this.loading = true;
      try {
        await this.resetPassword({ oldPassword: this.oldPassword, newPassword: this.password });
        snackBarEventBus.$emit(snackBarEventName, {
          message: this.$t("settings.changePassword.resetSuccess"),
          type: "success",
        });
      } catch (err) {
        console.error(err);
        snackBarEventBus.$emit(snackBarEventName, {
          message: (err && err.response && err.response.data && err.response.data.message) || err,
          type: "error",
        });
      } finally {
        this.oldPassword = "";
        this.password = "";
        this.confirmedPassword = "";
        this.$v.$reset();
        this.loading = false;
      }
    },
    onPasswordChange(password) {
      this.password = password;
    },
    onConfirmedPasswordChange(password) {
      this.confirmedPassword = password;
    },
    onOldPasswordChange(password) {
      this.oldPassword = password;
    },
  },
};
</script>
<style lang="scss">
.marginTop12 {
  margin-top: 12px !important;
}

.ps_input-group {
  .inputPasswords {
    margin-bottom: 14px !important;
  }
}

.ps_details {
  align-items: center;

  .ps_btn-wrapper {
    padding-top: 20px;

    button {
      font-size: 14px !important;
    }

    //padding-bottom: 20px;
  }
}
</style>
