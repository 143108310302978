<template>
  <div class="p-schedule-visit">
    <Stepper :isPrevStepRequired="true" :step="step" :stepChange="goToPage" :texts="steps" />
    <div class="p-schedule-visit__content mt-5">
      <v-progress-linear v-if="loading" color="primary" indeterminate />
      <section v-else>
        <v-card v-if="step === 1" class="p-schedule-visit__content__card pa-7" max-width="464px" outlined>
          <FirstStep :isRedirectNeeded="false" :mode="mode" :step="goToPage" @submit="submit" />
        </v-card>
        <SuccessPayment v-if="step === 2" :step="goToPage" @update:step="step = $event" />
        <ExpireError v-if="step === 4" :errorType="errorType" />
      </section>
    </div>
    <Snackbar v-model="snackbar.value" :text="snackbar.text" :timeout="5000" :type="snackbar.type" />
  </div>
</template>

<script>
import { mapActions, mapState } from "pinia";
import Vue from "vue";

import FirstStep from "@/components/Checkout/OwnerScheduleVisit/FirstStep";
import ExpireError from "@/components/Checkout/PatientScheduleVisit/ExpireError";
import SuccessPayment from "@/components/Checkout/SuccessPayement/Index";
import Snackbar from "@/components/shared/Snackbar";
import Stepper from "@/components/shared/Stepper";
import { snackBarEventBus, snackBarEventName } from "@/eventBuses/snackBar.eventBus";
import { useAppointmentsStore } from "@/pinia-store/appointments";
import { useAuthStore } from "@/pinia-store/auth";
import { useCheckoutStore } from "@/pinia-store/checkout";
import { usePatientStore } from "@/pinia-store/patient";
import { usePatientsStore } from "@/pinia-store/patients";
import { usePractitionersStore } from "@/pinia-store/practitioners";
import { useSlotsStore } from "@/pinia-store/slots";

export default Vue.extend({
  name: "OwnerScheduleVisitView",
  components: {
    FirstStep,
    SuccessPayment,
    Snackbar,
    Stepper,
    ExpireError,
  },
  data() {
    return {
      loading: false,
      buttonLoading: false,
      step: 1,
      mode: "create",
      errorType: "conflict",
      snackbar: {
        value: false,
        text: "",
        type: "success",
      },
    };
  },
  computed: {
    ...mapState(useAuthStore, ["uid"]),
    ...mapState(useCheckoutStore, ["patientId", "slotId", "reassignPractitionerId"]),
    steps() {
      return this.$i18n.t("visits.schedule.practitionerSteps");
    },
  },
  methods: {
    ...mapActions(useSlotsStore, ["getPractitionerFreeSlots", "getSlotById"]),
    ...mapActions(usePatientStore, ["getPatientProfile"]),
    ...mapActions(usePatientsStore, ["getAllByPractitionerId"]),
    ...mapActions(useCheckoutStore, ["ownerRequestVisit"]),
    ...mapActions(usePractitionersStore, ["getPractitionerById"]),
    ...mapActions(useAppointmentsStore, ["clearAllFields"]),
    goToPage(number) {
      this.step = number;
    },
    async submit() {
      this.buttonLoading = true;
      try {
        await this.getPractitionerById(this.reassignPractitionerId);
        await this.getSlotById(this.slotId);
        await this.getPatientProfile(this.patientId);
        this.step = 2;
      } catch (err) {
        if (err && err.response && err.response.status === 409) {
          this.errorType = "conflict";
          this.step = 4;
          snackBarEventBus.$emit(snackBarEventName, {
            message: this.$t("general.errors.slotConflict"),
            type: "error",
          });
          await this.getPractitionerFreeSlots({ practitionerId: this.uid });
        }
        console.error(err);
        snackBarEventBus.$emit(snackBarEventName, {
          message: (err && err.response && err.response.data && err.response.data.message) || err,
          type: "error",
        });
      }
      this.buttonLoading = false;
    },
  },
  beforeDestroy() {
    this.clearAllFields();
  },
  async mounted() {
    if (location.href.slice(-1) == 2) {
      this.step = 2;
    }
    if (!this.$route.query.step) {
      try {
        if (this.step === 1) {
          await this.getPractitionerFreeSlots({ practitionerId: this.uid });
          await this.getAllByPractitionerId();
        }
      } catch (err) {
        console.error(err);
        snackBarEventBus.$emit(snackBarEventName, {
          message: (err && err.response && err.response.data && err.response.data.message) || err,
          type: "error",
        });
      } finally {
        this.loading = false;
      }
    }
  },
});
</script>
