<template>
  <v-row>
    <v-col cols="12">
      <v-progress-linear v-if="loading" color="primary" indeterminate></v-progress-linear>
      <v-card class="elevation-0" outlined>
        <PatientSummaryContactInfo :item="patientInfo" />
      </v-card>
    </v-col>
    <v-col cols="12" md="4" sm="12">
      <PatientSummaryGeneralMedicalInfo />
      <div class="my-6"></div>
      <PatientSummaryAllergies />
      <div class="my-6"></div>
      <PatientSummaryMedications />
    </v-col>
    <v-col cols="12" md="4" sm="12">
      <PatientSummaryVitals />
      <div class="my-6"></div>
      <PatientSummaryPastMedicalInfo />
    </v-col>
    <v-col cols="12" md="4" sm="12">
      <PatientSummaryPharmacies />
    </v-col>
  </v-row>
</template>

<script>
import { mapActions as mapPiniaActions } from "pinia";
import { mapActions, mapState } from "pinia";

import PatientSummaryAllergies from "@/components/MedicalRecord/PatientSummary/Allergies";
import PatientSummaryContactInfo from "@/components/MedicalRecord/PatientSummary/ContactInfo";
import PatientSummaryGeneralMedicalInfo from "@/components/MedicalRecord/PatientSummary/GeneralMedicalInfo";
import PatientSummaryMedications from "@/components/MedicalRecord/PatientSummary/Medications";
import PatientSummaryPastMedicalInfo from "@/components/MedicalRecord/PatientSummary/PastMedicalInfo";
import PatientSummaryPharmacies from "@/components/MedicalRecord/PatientSummary/Pharmacies";
import PatientSummaryVitals from "@/components/MedicalRecord/PatientSummary/Vitals";
import { useAllergiesStore } from "@/pinia-store/allergies";
import { useConditionsStore } from "@/pinia-store/conditions";
import { useMedicationsStore } from "@/pinia-store/medications";
import { useObservationsStore } from "@/pinia-store/observations";
import { usePatientStore } from "@/pinia-store/patient";

export default {
  name: "MedicalRecordPatientSummary",
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    ...mapState(usePatientStore, ["patient"]),
    patientInfo() {
      return { ...(this.patient || {}), patient: this.patient || {} };
    },
  },
  components: {
    PatientSummaryPharmacies,
    PatientSummaryPastMedicalInfo,
    PatientSummaryMedications,
    PatientSummaryAllergies,
    PatientSummaryVitals,
    PatientSummaryGeneralMedicalInfo,
    PatientSummaryContactInfo,
  },
  methods: {
    ...mapActions(useObservationsStore, ["getSummary"]),
    ...mapPiniaActions(useAllergiesStore, ["getPatientAllergies"]),
    ...mapActions(useMedicationsStore, ["getPatientMedications"]),
    ...mapActions(useConditionsStore, ["getPatientConditions"]),
    ...mapActions(usePatientStore, ["getPatientPharmacies"]),
  },
  async mounted() {
    this.loading = true;
    await Promise.all([
      this.getSummary(this.$route.params.patientId),
      this.getPatientAllergies(this.$route.params.patientId),
      this.getPatientConditions(this.$route.params.patientId),
      this.getPatientMedications({ patientId: this.$route.params.patientId }),
      this.getPatientPharmacies(this.$route.params.patientId),
    ]);
    this.loading = false;
  },
};
</script>

<style scoped></style>
