<template>
  <div style="width: 100%">
    <v-btn
      v-if="isVisible && theme === 'green'"
      :height="btnHeight"
      :max-height="btnHeight"
      :width="fullWidth ? '100%' : 'auto'"
      color="success"
      elevation="0"
      @click="onOpen"
    >
      <v-icon dark left> mdi-microphone</v-icon>
      Speech to text
    </v-btn>
    <v-btn
      v-if="isVisible && theme !== 'green'"
      :height="btnHeight"
      :max-height="btnHeight"
      class="outlined-btn__green"
      elevation="0"
      outlined
      @click="onOpen"
    >
      Speech to text
    </v-btn>
  </div>
</template>

<script>
import { mapActions, mapState } from "pinia";

import { useAuthStore } from "@/pinia-store/auth";
import { useVoipStore } from "@/pinia-store/voip";
import { RolesEnum } from "@/types/Roles.enum";

export default {
  name: "SpeechToTextBtn",
  props: {
    phoneNumber: { default: "", type: String },
    fullWidth: { default: false, type: Boolean },
    toUserId: { default: "", type: String },
    theme: { default: "", type: String },
    btnHeight: {
      type: Number,
      default: 36,
    },
  },
  computed: {
    ...mapState(useAuthStore, ["role"]),
    isVisible() {
      return this.role === RolesEnum.Practitioner;
    },
  },
  methods: {
    ...mapActions(useVoipStore, ["setRecordingOpened"]),
    onOpen() {
      this.setRecordingOpened(true);
    },
  },
};
</script>

<style scoped></style>
