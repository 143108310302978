import { AvailabilitySlots } from "@/types/Availability";

import { apiRequest } from "./apiRequest";

interface OwnerUpdateSettingsBody {
  timeZone: string;
  availabilitySlots: AvailabilitySlots;
  locale: string;
}

interface OwnerUpdateProfileBody {
  name: {
    lastName: string;
    firstName: string;
  };
}

const ownerUpdateSettings = async (ownerId: string, body: Partial<OwnerUpdateSettingsBody>) => {
  const { data } = await apiRequest.put(`/owner/${ownerId}/settings`, body);
  return data;
};

const ownerGetSettings = async (ownerId: string) => {
  const { data } = await apiRequest.get(`/owner/${ownerId}/settings`);
  return data;
};

const getOwnerById = async (ownerId: string) => {
  const { data } = await apiRequest.get(`/owner/${ownerId}`);
  return data;
};

const updateProfile = async (ownerId: string, body: OwnerUpdateProfileBody) => {
  const { data } = await apiRequest.put(`/owner/${ownerId}`, body);
  return data;
};

const avatarUpload = async (ownerId: string, formData: FormData) => {
  const { data } = await apiRequest.post(`/owner/${ownerId}/avatar`, formData, {
    headers: { "Content-Type": "multipart/form-data" },
  });
  return data;
};

const getAllOwners = async () => {
  const { data } = await apiRequest.get(`/owner`);
  return data;
};
const getSlotInformation = async (id: string) => {
  const { data } = await apiRequest.get(`/appointment/slot/${id}`);
  return data;
};
const rejectCheckout = async (id: string) => {
  const { data } = await apiRequest.put(`/checkout/slot/${id}`);
  return data;
};

export const OwnersAPI = {
  ownerUpdateSettings,
  ownerGetSettings,
  getOwnerById,
  updateProfile,
  avatarUpload,
  getAllOwners,
  getSlotInformation,
  rejectCheckout,
};
