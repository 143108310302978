import { apiRequest } from "@/api/apiRequest";
import { MeetingRoomInvite, MeetingRoomStatus } from "@/pinia-store/meetingRoom";

const segment = "meeting-rooms";

const create = async (body: MeetingRoomInvite) => {
  const { data } = await apiRequest.post(`/${segment}`, body);
  return data;
};

const getAll = async () => {
  const { data } = await apiRequest.get(`/${segment}`);
  return data;
};
/**
 * Return access token for video room with room.
 */
const getByIdWithAccess = async (body: { meetingRoomId: string; identity: string }) => {
  const { data } = await apiRequest.get(`/${segment}/${body.meetingRoomId}/access/${body.identity}`);
  return data;
};
const finish = async (id: string) => {
  const { data } = await apiRequest.put(`/${segment}/${id}/finish`);
  return data;
};
const joinToRoom = async (id: string, identity: string) => {
  const { data } = await apiRequest.put(`/${segment}/${id}/join/${identity}`);
  return data;
};
const getById = async (id: string, filter?: { status: MeetingRoomStatus }) => {
  const { data } = await apiRequest.get(`/${segment}/${id}`, { params: filter });
  return data;
};

export const MeetingRoomApi = {
  create,
  joinToRoom,
  finish,
  getById,
  getByIdWithAccess,
  getAll,
};
