<template>
  <div
    :class="{
      'channel-item-active': active,
      'channel-item-passive': !active,
      'channel-item-hidden': !channel?._twilioChannel && participant && !channel,
    }"
    class="channel-item d-flex align-center"
  >
    <div class="d-flex channel-item-body">
      <v-badge :value="participantOnline" color="success" dot offset-x="18" offset-y="9">
        <Avatar v-if="participantMeta" :user="participantMeta" class="mr-3" size="36" />
      </v-badge>
      <div class="d-inline-flex flex-column channel-item-body-con justify-space-between">
        <div v-if="participant && participant.displayName" class="channel-item-body-title">
          <div v-html="friendlyName(participant.displayName)" />
          <img
            v-if="isPatientPractitioner"
            :src="require('@/assets/chat/star-fil.svg')"
            alt="Primary clinician"
            title="Primary clinician"
          />
        </div>
        <div v-else class="channel-item-body-title" v-html="friendlyName(channel.friendlyName)" />

        <div v-if="chiefComplaint" class="channel-item-body-chief text-justify">
          {{ chiefComplaint }}
        </div>
        <div v-if="lastMessage && !messageMatchCount" class="channel-item-body-preview text-justify">
          {{ lastMessage.body }}
        </div>
        <div v-if="messageMatchCount" class="channel-item-body-preview text-justify" v-html="messageMatchCount" />
      </div>
    </div>
    <div class="d-inline-flex fill-height flex-column align-end justify-start channel-item-info justify-space-between">
      <div class="channel-item-info-time">{{ lastMessageDate }}</div>
      <div v-if="unconsumedMessageCount" class="channel-item-info-count">
        <span> {{ unconsumedMessageCount }} </span>
      </div>
    </div>
  </div>
</template>

<script>
import { DateTime } from "luxon";
import moment from "moment-timezone";
import { mapState } from "pinia";

import { useAuthStore } from "@/pinia-store/auth";
import { useChatStore } from "@/pinia-store/chat";
import { usePatientStore } from "@/pinia-store/patient";
import { RolesEnum } from "@/types/Roles.enum";

import Avatar from "../../Avatar/Index.vue";

function replaceAllIgnoreCase(str, searchKeyword, replacement) {
  const regex = new RegExp(searchKeyword, "gi");
  return str.replace(regex, replacement);
}

export default {
  name: "ChannelItem",
  props: { channel: Object, active: Boolean },
  components: {
    Avatar,
  },
  data() {
    return {
      messages: [],
      otherMembers: [],
      participantOnline: false,
    };
  },

  watch: {
    participant(newVal) {
      if (newVal !== null) {
        this.participantStatusSubscription?.unsubscribe();

        this.participantStatusSubscription = this.channel.interlocutor
          ?.getChannelStatusObservable(this.channel.sid)
          .subscribe((val) => {
            this.participantOnline = val === "online";
          });
      } else {
        this.participantStatusSubscription?.unsubscribe();
        this.participantStatusSubscription = null;
      }
    },
  },
  mounted() {
    this.messages = this.channel.messages ?? [];
    if (this.channel.interlocutor) {
      const obs = this.channel.interlocutor.getChannelStatusObservable(this.channel.sid);
      this.participantOnline = obs.getValue();
      this.participantStatusSubscription = obs.subscribe((val) => {
        this.participantOnline = val === "online";
      });
    }
  },
  methods: {
    getDate() {
      return moment.unix(this.channel.dateCreated / 1000).format("MM/DD/YYYY HH:MM");
    },
    friendlyName(value, searchKeyword) {
      if (!searchKeyword) return value;
      return replaceAllIgnoreCase(value, searchKeyword, `<b class="match-string">${this.filterQuery}</b>`);
    },
  },
  computed: {
    ...mapState(useChatStore, ["filterQuery", "filterParticipantName"]),
    ...mapState(useAuthStore, ["uid", "role"]),
    ...mapState(usePatientStore, ["patient"]),
    messageMatchCount() {
      if (!this.filterQuery?.length) return false;
      const messages = this.messages.filter((message) => {
        return message.body?.toLowerCase().indexOf(this.filterQuery.toLowerCase().trim()) !== -1;
      });
      if (!messages.length) return false;
      return messages.length === 1
        ? this.friendlyName(messages[0].body, this.filterQuery)
        : `${messages.length} messages matching your search`;
    },

    isPatientPractitioner() {
      return this.role === RolesEnum.Patient && this.patient?.practitioner?.id === this.participant?.uid;
    },
    chiefComplaint() {
      console.log(this.channel?.channelAttributes);
      return this.channel?.channelAttributes?.chiefComplaint;
    },
    participant() {
      return this.channel.interlocutor;
    },
    participantMeta() {
      return this.channel?.interlocutor?.meta ?? null;
    },
    // participant() {
    //   if (this.channel.interlocutor?.uid === this.uid) return this.channel.user;
    //   if (this.channel.interlocutor) return this.channel.interlocutor;
    //   if (this.channel._participantsRepository?._participants?.[0]?._userMeta?.uid !== this.uid)
    //     return this.channel._participantsRepository?._participants?.[0]?._userMeta;
    //   if (this.channel._participantsRepository?._user?._userMeta?.uid !== this.uid)
    //     return this.channel._participantsRepository?._user?._userMeta;
    //   return null;
    // },
    unconsumedMessageCount() {
      return this.channel.unconsumedMessagesCount;
    },
    lastMessage() {
      return this.channel.lastMessageInstance;
    },
    lastMessageDate() {
      if (!this.lastMessage?.dateCreated) {
        return;
      }
      const date = DateTime.fromISO(this.lastMessage.dateCreated);
      if (date < DateTime.local().startOf("day")) {
        return date.toFormat("MMM d");
      } else {
        return date.toFormat("t");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.channel-item {
  border-bottom: 1px solid #ebebeb;
  box-sizing: content-box;
  padding-bottom: 17px;
  padding-top: 16px;
  height: 44px;

  :deep .match-string {
    color: black;
  }

  &-active {
    background: #d9edff;
    box-shadow: 1.5px 0 0 0 #2196f3 inset;
    cursor: pointer;
  }

  &-passive:hover {
    background: #f7fcff;
    cursor: pointer;
  }

  &-hidden {
    display: none !important;
  }

  &-info {
    width: 100px;
    padding-right: 18px;

    &-time {
      font-style: normal;
      font-weight: normal;
      font-size: 11.5px;
      line-height: 20px;
      color: #6c757d;
    }

    &-count {
      width: 23px;
      height: 23px;
      border-radius: 50%;
      background: #0073ff;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 23px;
      letter-spacing: 0.25px;
      color: #ffffff;
      text-align: center;
    }
  }

  &-body {
    margin-left: 18px;
    box-sizing: border-box;
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    &-con {
      width: calc(100% - (36px + 10px));
    }

    &-title {
      display: flex;
      align-items: center;
      font-style: normal;
      font-weight: bold;
      font-size: 13.5px;
      letter-spacing: 0.25px;
      color: #1e85d9;

      text-overflow: ellipsis;
      white-space: break-spaces;
      overflow: hidden;
      width: 100%;
    }

    &-preview {
      font-weight: 500;
      font-size: 12px;
      line-height: 15px;
      text-align: center;
      color: #33343e;

      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      width: 100%;
      font-stretch: condensed;
    }

    &-chief {
      font-weight: bold;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.25px;
      color: $primaryblack2;
      margin-bottom: 2px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }
}

.hidden {
  visibility: hidden;
}
</style>
