import { apiRequest } from "./apiRequest";

const search = async (filter: { name: string }) => {
  const { data } = await apiRequest.get(`/pharmacy`, { params: filter });
  return data;
};

const add = async (body: { pharmacyId: string; patientId: string; primary?: boolean }) => {
  const { data } = await apiRequest.post(`/pharmacy/patient`, body);
  return data;
};

const addToEncounter = async (encounterId: string, body: { pharmacyId: string }) => {
  const { data } = await apiRequest.post(`/pharmacy/encounter/${encounterId}`, body);
  return data;
};

const addToEncounterFreeText = async (encounterId: string, body: { name: string; city: string; address: string }) => {
  const { data } = await apiRequest.post(`/pharmacy/encounter/${encounterId}/free-text`, body);
  return data;
};

const getForEncounter = async (encounterId: string) => {
  if (!encounterId) return { data: null };
  const { data } = await apiRequest
    .get(`/pharmacy/encounter/${encounterId}`)
    .then((res) => res)
    .catch(() => ({ data: null }));
  return data;
};

const setActive = async (body: { pharmacyId: string; patientId: string }) => {
  const { data } = await apiRequest.post(`/pharmacy/patient/active`, body);
  return data;
};

const destroy = async (body: { pharmacyId: string; patientId: string }) => {
  const { data } = await apiRequest.delete(`/pharmacy/patient`, { params: body });
  return data;
};

const getPatientPharmacies = async (body: { patientId: string; _count?: number }) => {
  const { data } = await apiRequest
    .get(`/pharmacy/patient`, {
      params: { ...body },
    })
    .then((r) => r)
    .catch((err) => ({ data: null }));
  return data;
};

export const PharmacyApi = {
  search,
  add,
  addToEncounter,
  addToEncounterFreeText,
  getForEncounter,
  getPatientPharmacies,
  destroy,
  setActive,
};
