import { render, staticRenderFns } from "./Conversation.vue?vue&type=template&id=577c0604&scoped=true"
import script from "./Conversation.vue?vue&type=script&lang=js"
export * from "./Conversation.vue?vue&type=script&lang=js"
import style0 from "./Conversation.vue?vue&type=style&index=0&id=577c0604&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "577c0604",
  null
  
)

export default component.exports