<template>
  <Layout :navTabs="navTabs" :quickActions="quickActions" role="owner">
    <router-view></router-view>
  </Layout>
</template>
<script>
import { mapActions, mapState } from "pinia";

import Layout from "@/components/Layout";
import { useOwnersStore } from "@/pinia-store/owners";
import { useProfileSettingsStore } from "@/pinia-store/profileSettings";
import { layoutOwnerNavTabs, layoutOwnerQuickActions } from "@/router/utils";

export default {
  name: "ownerView",
  components: {
    Layout,
  },
  computed: {
    ...mapState(useProfileSettingsStore, ["ownerSettings", "avatar"]),
    navTabs() {
      return layoutOwnerNavTabs(this.$i18n);
    },
    quickActions() {
      return layoutOwnerQuickActions(this.$i18n);
    },
  },
  methods: {
    ...mapActions(useProfileSettingsStore, ["getOwnerSettings"]),
    ...mapActions(useOwnersStore, ["getAllPractitioners"]),
  },
  async mounted() {
    await this.getOwnerSettings();
    if (this.ownerSettings.locale) {
      this.$root.$i18n.locale = this.ownerSettings.locale;
    }
  },
};
</script>
