<template>
  <div class="free-text">
    <v-row class="free-text__header" justify="space-between" no-gutters>
      <v-col class="free-text__header_title">
        <span class="heading-2">{{ "Free text" }}</span>
      </v-col>
      <v-col class="free-text__header__actions"></v-col>
    </v-row>
    <v-data-table
      v-model="selected"
      :class="`table data-table data-table__green-border table-bordered `"
      :fixed-header="true"
      :footer-props="{
        showFirstLastPage: true,
      }"
      :header-props="{ sortIcon: selected ? null : 'mdi-chevron-down' }"
      :headers="headers"
      :items="tableItems"
      mobile-breakpoint="0"
      show-select
    >
      <template v-slot:[`header.data-table-select`]="{ on, props }">
        <v-simple-checkbox v-if="!selected.length" color="purple" v-bind="props" v-on="on" />
        <div v-else class="free-text__header-action">
          <v-simple-checkbox color="purple" v-bind="props" v-on="on" />
        </div>
      </template>
      <template v-slot:[`header.removeAction`]>
        <v-btn class="free-text__remove-btn" fab small text @click="performDelete">
          <v-icon>mdi-delete</v-icon>
        </v-btn>
      </template>
      <template v-slot:item="props">
        <tr>
          <td class="free-text__checkbox-cell">
            <v-checkbox :input-value="props.isSelected" @change="props.select($event)"></v-checkbox>
          </td>
          <td class="free-text__name-cell">{{ props.item.name }}</td>
          <td>{{ props.item.relatedName }}</td>
          <td class="text-end free-text__action-wrap">
            <PrimaryButton :text="$t('general.edit')" size="medium" @onClick="editItem(props.item)" />
          </td>
        </tr>
      </template>
      <template v-slot:no-data>
        <span class="data-table__no-items">
          {{ $t("general.table.noItems") }}
        </span>
      </template>
    </v-data-table>
    <EditSynonymDialog v-model="dialog" :item="editableItem" @onToggleDialog="toggleDialog" />
  </div>
</template>

<script>
import { SynonymsApi } from "@/api/synonyms";
import PrimaryButton from "@/components/uikit/PrimaryButton.vue";
import { snackBarEventBus, snackBarEventName } from "@/eventBuses/snackBar.eventBus";

import EditSynonymDialog from "./Edit.vue";

export default {
  name: "FreeText",
  components: {
    EditSynonymDialog,
    PrimaryButton,
  },
  data: function () {
    return {
      editableItem: {},
      selected: [],
      items: [],
      loading: true,
      loaded: false,
      dialog: false,
    };
  },
  computed: {
    tableItems() {
      return this.items.map((item) => ({
        ...item,
        id: item.id,
        name: item.name,
        relatedName: item.relatedName,
      }));
    },
    headers() {
      if (this.selected.length)
        return [
          {},
          {},
          {
            text: this.$t("free-text.name"),
            value: "removeAction",
            align: "end",
          },
        ];
      return [
        {
          text: "Free text",
          value: "name",
          width: "30%",
          align: "start",
        },
        {
          text: "Official name",
          value: "relatedName",
          width: "60%",
        },
        { text: "", value: "action", width: 28, align: "end" },
      ];
    },
  },
  methods: {
    async performDelete() {
      this.loading = true;
      try {
        const ids = this.selected.map((i) => i.id);
        await SynonymsApi.bulkDelete({ ids });
      } catch (err) {
        console.error(err);
        if (err && err.response && err.response.status === 409) {
          snackBarEventBus.$emit(snackBarEventName, {
            message: this.$t("general.errors.err"),
            type: "error",
          });
        }
      }
      await this.fetchData();
      this.loading = false;
    },
    async toggleDialog() {
      this.dialog = !this.dialog;
      await this.fetchData();
    },
    editItem(item) {
      const data = this.items.find((i) => i.id === item.id);
      this.editableItem = { ...data };
      this.dialog = true;
    },
    async fetchData() {
      const data = await SynonymsApi.getAll();
      this.items = data || [];
    },
  },
  async mounted() {
    await this.fetchData();
  },
};
</script>

<style lang="scss">
.free-text {
  min-height: calc(100vh - 60px);
  overflow: auto;
  padding: 20px;
  @media (max-width: 767.98px) {
    padding: 20px;
  }

  .v-data-table-header {
    th:nth-of-type(1) {
      padding-right: 0 !important;
    }

    th:nth-of-type(2) {
      padding-left: 0 !important;
    }
  }

  &__header-action {
    display: flex;
    justify-content: space-between;
  }

  &__checkbox-cell {
    padding-right: 0 !important;
  }

  &__remove-btn {
    .v-btn__content {
      opacity: 1;

      .v-icon {
        color: red !important;
      }
    }
  }

  &__name-cell {
    padding-left: 0 !important;
  }

  &__header {
    margin-bottom: 28px;

    @media (max-width: 768px) {
      flex-direction: column;
    }

    &__title {
      display: flex;
      justify-content: space-between;
      @media (max-width: 768px) {
        flex-direction: column;
        max-width: fit-content;
      }
    }

    &__align-right {
      display: flex;
      justify-content: flex-end;
    }

    &__actions {
      display: flex;
      justify-content: flex-end;
      @media (max-width: 768px) {
        margin-top: 10px;
        justify-content: space-between;
      }
      @media (max-width: 480px) {
        flex-direction: column;
        max-width: 100%;
        width: 100%;
        button {
          width: 100%;
        }
      }

      &__margin {
        margin-right: 12px;

        @media (max-width: 480px) {
          margin-bottom: 12px;
          margin-right: 0;
        }
      }
    }
  }

  &__edit-menu {
    .v-list {
      padding-top: 0;
      padding-bottom: 0;
    }

    text-align: center;
    background: #ffffff;
    border: 1px solid #eeeff7;
    box-shadow: none;
    box-sizing: border-box;
    border-radius: 4px;

    .v-list-item:not(:last-child) {
      border-bottom: 1px solid #eeeff7;
    }

    .v-list-item:hover {
      cursor: pointer;
      background: rgba(34, 148, 242, 0.08);
    }
  }

  &__edit {
    color: $primary;
  }

  &__action-wrap {
    .table__actions {
      margin-right: 8px;
    }
  }

  &__delete {
    color: $error;
  }

  &__bottom-actions {
    justify-content: space-between;
    display: flex;
    gap: 15px;
  }

  &__form {
    .label {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      color: #33343e;
      opacity: 0.88;
    }

    label .v-text-field__details {
      display: none;
    }

    padding: 16px 24px;
  }
}
</style>
