import { defineStore } from "pinia";

import { AppointmentsApi } from "@/api/appointment";
import { CheckoutApi } from "@/api/checkout";
import { PatientsApi } from "@/api/patients";
import { useAppointmentsStore } from "@/pinia-store/appointments";
import { useAuthStore } from "@/pinia-store/auth";
import { CheckoutResponseDto } from "@/pinia-store/interfaces/checkoutI";

export const checkoutState = {
  slotId: "",
  patientId: "",
  complaintDescription: "",
  chiefComplaint: "",
  visitDate: null,
  height: "",
  weight: "",
  practitionerId: "",
  isSmoking: false,
  allergies: [],
  confirmedAccurace: false,
  medications: [],
  pmh: [],
  session: {
    id: "",
    status: "",
    rejectDate: "",
    price: "$0",
  },
  reassign: false,
  reassignPractitionerId: "",
  pendingCheckouts: [],
  pendingRoomInfo: {},
};

export const useCheckoutStore = defineStore({
  id: "checkout",
  state: () => checkoutState,
  actions: {
    setStringFieldByName(payload: { value: any; fieldName: any }) {
      const { fieldName, value } = payload;
      this[fieldName] = value;
    },
    setVisitDate(payload: string) {
      this.visitDate = payload;
    },
    async submitSecondStep(practitionerSettings: { isAutoAccept: any }) {
      const authState = useAuthStore();
      const { uid } = authState;
      const appointmentsStore = useAppointmentsStore();
      const slotId = appointmentsStore.slotId;
      const chiefComplaint = appointmentsStore.chiefComplaint;
      const complaintDescription = appointmentsStore.complaintDescription;
      const virtualService = appointmentsStore.virtualService;
      if (practitionerSettings.isAutoAccept) {
        await AppointmentsApi.createVisit(
          [],
          uid,
          slotId,
          "busy",
          chiefComplaint,
          virtualService,
          complaintDescription,
          false,
        );
      } else {
        await AppointmentsApi.createVisit(
          [],
          uid,
          slotId,
          "pending",
          chiefComplaint,
          virtualService,
          complaintDescription,
          true,
        );
      }
    },
    async submitEditMode() {
      const { slotId, chiefComplaint, complaintDescription, visitDate, confirmedAccurace, session, practitionerId } =
        this;
      const authStore = useAuthStore();
      const { uid } = authStore;
      const checkout = await CheckoutApi.update(
        {
          slotId,
          practitionerId,
          patientId: uid,
          information: {
            confirmedAccurace,
            visitDate,
            chiefComplaint,
            complaintDescription,
          },
        },
        session.id,
      );
      await this.completeCheckout(checkout);
    },
    async completeCheckout(checkout: CheckoutResponseDto) {
      const { id } = checkout;
      await CheckoutApi.complete(id);
    },
    async practitionerRequestVisit() {
      const { slotId, patientId, chiefComplaint, complaintDescription, virtualService } = this;
      await AppointmentsApi.createVisit(
        [],
        patientId,
        slotId,
        "pending",
        chiefComplaint,
        virtualService,
        complaintDescription,
        false,
      );
    },
    async ownerRequestVisit() {
      const {
        slotId,
        patientId,
        chiefComplaint,
        complaintDescription,
        visitDate,
        height,
        weight,
        isSmoking,
        allergies,
        pmh,
        medications,
        reassignPractitionerId,
        reassign,
        practitionerId,
      } = this;
      await CheckoutApi.create({
        slotId,
        patientId,
        practitionerId,
        information: {
          chiefComplaint,
          complaintDescription,
          visitDate,
          generalInformation: {
            height,
            weight,
            isSmoking,
          },
          medicalInformation: {
            allergies,
            pmh,
            medications,
          },
        },
        ...(reassign && { reassignPractitionerId }),
      });
    },
    async preloadPatientProfile(patientId?: string) {
      const authStore = useAuthStore();
      let uid = "";
      if (patientId) {
        uid = patientId;
      } else {
        uid = authStore.uid;
      }
      const settings = await PatientsApi.getSettings(uid);
      const { medicalInformation, generalInformation } = settings;
      if (medicalInformation) {
        this.pmh = medicalInformation.pmh;
        this.allergies = medicalInformation.allergies;
        this.medications = medicalInformation.medications;
      }
      if (generalInformation) {
        this.height = generalInformation.height;
        this.weight = generalInformation.weight;
        this.isSmoking = generalInformation.isSmoking;
      }
    },
    async getSessionInfo(sessionId: string) {
      const { slotId, status, practitionerId, expectedRejectDate, information, id, price } = await CheckoutApi.getById(
        sessionId,
      );
      const priceInDollars = `$${parseInt(price, 10) / 100}`;
      this.session = { id, status, rejectDate: expectedRejectDate, price: priceInDollars };
      this.slotId = slotId;
      this.practitionerId = practitionerId;
      this.chiefComplaint = information.chiefComplaint;
      this.height = information.generalInformation?.height;
      this.weight = information.generalInformation?.weight;
      this.medications = information.medicalInformation?.medications;
      this.allergies = information.medicalInformation?.allergies;
      this.pmh = information.medicalInformation?.pmh;
      this.visitDate = information.visitDate || null;
      this.confirmedAccurace = information.confirmedAccurace;
      this.isSmoking = information.generalInformation?.isSmoking;
      this.complaintDescription = information.complaintDescription;
    },
    clearAllFields() {
      this.slotId = "";
      this.patientId = "";
      this.chiefComplaint = "";
      this.height = "";
      this.weight = "";

      this.medications = [];
      this.allergies = [];
      this.pmh = [];
      this.visitDate = null;
      this.confirmedAccurace = false;
      this.complaintDescription = "";
    },
    async getPendingRoomInfo(slotId: string) {
      try {
        this.pendingRoomInfo = await CheckoutApi.getPendingRoomInfo(slotId);
      } catch (err) {
        console.error("Room not found");
      }
    },
  },
});
