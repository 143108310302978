<template>
  <div>
    <v-row>
      <v-col>
        <v-sheet height="64">
          <v-toolbar flat>
            <v-toolbar-title>
              {{ formatDate(picker).format("MMMM YYYY") }}
            </v-toolbar-title>
            <v-btn
              :disabled="formatDate(picker).format('YYYY-MM') === formatDate(currentMonth).format('YYYY-MM')"
              color="grey darken-2"
              fab
              small
              text
              @click="prevMonth"
            >
              <v-icon> mdi-chevron-left</v-icon>
            </v-btn>
            <v-btn color="grey darken-2" fab small text @click="nextMonth">
              <v-icon> mdi-chevron-right</v-icon>
            </v-btn>
            <v-spacer></v-spacer>
            <OutlinedButton
              class="font-weight-medium transparent text--secondary"
              icon="mdi-trash-can-outline"
              text="Reset Calendar"
              @onClick="resetDialog = true"
            />
          </v-toolbar>
        </v-sheet>
        <v-sheet height="600">
          <v-calendar
            ref="calendar"
            v-model="picker"
            :events="events"
            :short-weekdays="false"
            :type="$vuetify.breakpoint.smAndDown ? 'day' : 'week'"
            class="custom-hours-calendar"
            color="primary"
            event-color="transparent"
            event-text-color="#33343E"
            @click:time="onMonthDateClick"
            @click:date="onMonthDateClick"
            @click:day="onMonthDateClick"
            @click:more="onMoreClick"
            @click:event="onEventClick"
          >
            <template v-slot:event="{ event }">
              <div class="d-flex align-center px-2">
                <v-tooltip
                  v-if="
                    event.source &&
                    event.source.frequencyType &&
                    event.source &&
                    event.source.frequencyType === 'weekdays'
                  "
                  top
                >
                  <template v-slot:activator="{ on, attrs }">
                    <img alt="repeat" src="@/assets/icons/repeat-blue.svg" v-bind="attrs" v-on="on" />
                  </template>
                </v-tooltip>
                <v-tooltip v-else top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon color="primary" size="18" v-bind="attrs" v-on="on"> mdi-calendar-today</v-icon>
                  </template>
                </v-tooltip>
                <span class="ml-2" style="font-size: 14px"
                  >{{ formatDate(event.start).format("HH:mm") }} - {{ formatDate(event.end).format("HH:mm") }}</span
                >
              </div>
            </template>
          </v-calendar>
        </v-sheet>
      </v-col>
    </v-row>
    <NewHours :closeDialog="closeNewHoursDialog" :dialog="newHoursDialog" :start="newHoursStartDate" />
    <UpdateHours
      :closeDialog="closeUpdateHoursDialog"
      :dialog="updateHoursDialog"
      :start="newHoursStartDate"
      :updatedEvent="updatedEvent"
    />
    <Reset :closeDialog="closeResetDialog" :dialog="resetDialog" />
    <More :availabilities="moreCustomAvailabilities" :closeDialog="closeMoreDialog" :dialog="moreDialog" />
  </div>
</template>

<script>
import moment from "moment-timezone";
import { mapState } from "pinia";

import More from "@/components/EditAvailability/Modals/More";
import NewHours from "@/components/EditAvailability/Modals/NewHours";
import Reset from "@/components/EditAvailability/Modals/Reset";
import UpdateHours from "@/components/EditAvailability/Modals/UpdateHours";
import OutlinedButton from "@/components/uikit/OutlinedButton";
import { useSlotsStore } from "@/pinia-store/slots";

export default {
  name: "CustomHours",
  components: {
    NewHours,
    Reset,
    OutlinedButton,
    UpdateHours,
    More,
  },
  data: () => ({
    picker: new Date().toISOString().substr(0, 10),
    newHoursDialog: false,
    resetDialog: false,
    newHoursStartDate: "",
    updateHoursDialog: false,
    moreDialog: false,
    updatedEvent: {},
    moreCustomAvailabilities: [],
    formattedEvents: [],
    events: [],
    // data example
    // customAvailabilities: [
    //   {
    //     date: "2022-07-06",
    //     frequencyConfig: { days: [1, 4, 5] },
    //     hours: [
    //       { start: "04:22", end: "08:30" },
    //       { start: "08:22", end: "10:30" },
    //     ],
    //     frequencyType: "weekdays",
    //   },
    //   {
    //     date: "2022-07-08",
    //     hours: [{ start: "07:22", end: "09:30" }],
    //     frequencyType: "none",
    //   },
    // ],
  }),
  watch: {
    customAvailabilities: {
      immediate: true,
      handler() {
        this.formatEvents();
      },
    },
  },
  computed: {
    ...mapState(useSlotsStore, ["customAvailabilities"]),
    currentMonth() {
      return new Date().toISOString().substr(0, 10);
    },
  },
  methods: {
    formatEvents() {
      let hFormat = "HH:mm";
      let events = [];
      let startOfMonth = moment(this.picker).startOf("month");
      let endOfMonth = moment(this.picker).endOf("month");
      if (this.customAvailabilities) {
        this.customAvailabilities.map((ev) => {
          if (ev.frequencyType === "none") {
            (ev.hours || []).map((hour) => {
              events.push({
                start: moment(ev.date)
                  .add({
                    hour: moment(hour.start, hFormat).hour(),
                    minutes: moment(hour.start, hFormat).minutes(),
                  })
                  .valueOf(),
                end: moment(ev.date)
                  .add({
                    hour: moment(hour.end, hFormat).hour(),
                    minutes: moment(hour.end, hFormat).minutes(),
                  })
                  .valueOf(),
                source: JSON.parse(JSON.stringify({ ...ev })),
              });
            });
          }
          if (
            ev.frequencyType === "weekdays" &&
            moment(ev.date).isSameOrBefore(endOfMonth) &&
            ev.frequencyConfig?.days?.length
          ) {
            const firstDayOfMonth = startOfMonth.format("YYYY-MM-DD");
            const daysInMonth = startOfMonth.daysInMonth();
            for (let i = 0; i < daysInMonth; i++) {
              // if there are repeat days
              (ev.frequencyConfig.days || []).map((day) => {
                // for repeat events with date before current calendar selected end of month
                if (moment(ev.date).isSameOrBefore(endOfMonth)) {
                  if (
                    moment(firstDayOfMonth).add(i, "days").day() === day - 1 &&
                    moment(firstDayOfMonth).add(i, "days").isSameOrAfter(moment(ev.date))
                  ) {
                    // getting all hour for that event add adding to calendar events
                    (ev.hours || []).map((hour) => {
                      events.push({
                        source: JSON.parse(JSON.stringify({ ...ev })),
                        start: moment(firstDayOfMonth)
                          .add({
                            days: i + 1,
                            hour: moment(hour.start, hFormat).hour(),
                            minutes: moment(hour.start, hFormat).minutes(),
                          })
                          .valueOf(),
                        end: moment(firstDayOfMonth)
                          .add({
                            days: i + 1,
                            hour: moment(hour.end, hFormat).hour(),
                            minutes: moment(hour.end, hFormat).minutes(),
                          })
                          .valueOf(),
                      });
                    });
                  }
                }
              });
            }
          }
        });
      }
      this.events = events;
    },
    formatDate(datetime = undefined) {
      return moment(datetime);
    },
    prevMonth() {
      this.picker = moment(this.picker).subtract(1, "month").format();
      this.$refs.calendar.prev();
      this.formatEvents();
    },
    nextMonth() {
      this.picker = moment(this.picker).add(1, "month").format();
      this.$refs.calendar.next();
      this.formatEvents();
    },
    closeNewHoursDialog() {
      this.newHoursDialog = false;
      this.newHoursStartDate = "";
    },
    closeUpdateHoursDialog() {
      this.updateHoursDialog = false;
    },
    closeResetDialog() {
      this.resetDialog = false;
    },
    closeMoreDialog() {
      this.moreDialog = false;
    },
    onMonthDateClick(e) {
      if (!this.updateHoursDialog && !this.moreDialog) {
        this.newHoursDialog = true;
        this.newHoursStartDate = e.date;
      }
      this.formatEvents();
    },
    onMoreClick(e) {
      this.moreCustomAvailabilities = this.events.filter(
        (availability) => moment(availability.start).format("YYYY:MM:DD") === moment(e.date).format("YYYY:MM:DD"),
      );
      this.moreDialog = true;
    },
    onEventClick(e) {
      this.updateHoursDialog = true;
      this.updatedEvent = JSON.parse(JSON.stringify(e.event));
      this.newHoursStartDate = e.day.date;
    },
  },
};
</script>

<style lang="scss">
.custom-hours-calendar {
  .v-calendar-weekly__head-weekday {
    background: rgba(188, 202, 213, 0.2);
    height: 52px;
    line-height: 52px;

    &.v-past,
    &.v-present,
    &.v-future {
      color: #000000 !important;
    }
  }

  .v-calendar-weekly__day {
    border: 1px solid #e0e0e0;

    &:hover {
      background: #eff6ff !important;
      cursor: pointer;
    }
  }
}
</style>
