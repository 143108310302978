<template>
  <div :class="{ active: isActive }" class="participant-box">
    <div class="wrap-details">
      <Avatar :user-id="item.recipient.id" class="participant-box__avatar" />
      <div class="participant-box__title-wrap">
        <p class="participant-box__title-wrap__text">
          {{ item.recipient.fullName }}
        </p>
        <p v-if="!item.recipientJoinedAt" class="participant-box__title-wrap__info">
          Created {{ createdData(item.sentAt) }}
        </p>
        <p v-else class="participant-box__title-wrap__info">Person joined {{ createdData(item.recipientJoinedAt) }}</p>
      </div>
    </div>
    <div class="wrap-action">
      <PrimaryButton :fullWidth="true" text="Join" @onClick="openRoom" />
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "pinia";

import Avatar from "@/components/Avatar/Index.vue";
import PrimaryButton from "@/components/uikit/PrimaryButton.vue";
import { useAuthStore } from "@/pinia-store/auth";
import { useMeetingRoomStore } from "@/pinia-store/meetingRoom";

export default {
  props: ["item"],
  name: "ParticipantBox",
  components: { PrimaryButton, Avatar },
  computed: {
    ...mapState(useAuthStore, ["userIsPractitioner", "uid"]),
    ...mapState(useAuthStore, ["timeZone"]),
    isActive() {
      return this.$route.params?.roomId === this.item.id;
    },
  },
  methods: {
    ...mapActions(useMeetingRoomStore, [
      "setRoomId",
      "setIdentityId",
      "setIsModalMode",
      "setOpenedListDialog",
      "setOpenedMeetingRoom",
    ]),
    createdData(date) {
      return date && this.$moment.tz(date, this.timeZone).isValid() ? this.$moment(date).fromNow() : "";
    },
    openRoom() {
      if (this.userIsPractitioner) {
        this.setRoomId(this.item.id);
        this.setIdentityId(this.uid);
        this.setIsModalMode(true);
        this.setOpenedListDialog(false);
        this.setOpenedMeetingRoom(true);
      }
      // this.$router.push({
      //   name: routesEnum.practitionerMeetingRoomJoin,
      //   params: { roomId: this.item.id, identity: this.uid },
      // });
    },
  },
};
</script>

<style lang="scss">
.participant-box {
  cursor: pointer;
  padding: 15px 18px;
  background: white;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  box-shadow: 0px 2px 12px 0px #0000000f;

  .wrap-details {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .wrap-action {
    padding-top: 16px;
    border-top: 1px solid rgba(246, 246, 250, 1);
  }

  &.active {
    border-bottom: 2px solid blue;
  }

  &__avatar {
    flex-shrink: 0;
    width: 40px !important;
    height: 40px !important;
    min-width: inherit !important;
    margin-right: 16px;
  }

  &__title-wrap {
    font-family: "Inter";
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    > p {
      margin-bottom: 0 !important;
    }

    &__text {
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 16px;
      color: #33343e;
    }

    &__info {
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 15px;
      color: #91909c;
    }
  }

  img {
    width: 200px;
    height: 200px;
    object-fit: cover;
  }
}
</style>
