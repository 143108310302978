<template>
  <v-dialog @click:outside="onClose" :value="dialog" @input="$emit('dialog')" max-width="410">
    <v-card class="card">
      <v-icon class="card-close" size="medium" color="icon" @click="onClose"> mdi-close </v-icon>
      <v-card-title>
        {{ title }}
      </v-card-title>
      <v-card-text>
        {{ text }}
      </v-card-text>
      <BaseInput
        class="input"
        v-model="input"
        :placeholder="inputPlaceholder"
        :errors="inputErrors"
        fieldName="input"
        @change="onInputChange"
      />
      <slot />
      <v-card-actions class="actions">
        <v-btn class="btn outlined-btn btn-cancel" outlined depressed color="#E5E5E5" @click="onClose">
          <span>{{ cancelText }}</span>
        </v-btn>
        <v-btn
          class="btn btn-accept"
          color="success"
          @click="onConfirm"
          :light="this.$v.$invalid"
          :dark="!this.$v.$invalid"
          :disabled="this.$v.$invalid"
          depressed
        >
          <v-progress-circular v-if="loading" indeterminate color="white" width="3" size="25" />
          <span v-else>{{ confirmText }}</span>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { required } from "vuelidate/lib/validators";

import BaseInput from "../uikit/BaseInput";
export default {
  name: "TextConfirm",
  props: {
    dialog: Boolean,
    confirmText: String,
    title: String,
    text: String,
    loading: Boolean,
    cancelText: String,
    inputPlaceholder: String,
    inputRequiredText: String,
  },
  data() {
    return {
      input: "",
    };
  },
  validations: {
    input: { required },
  },
  computed: {
    inputErrors() {
      const errors = [];
      if (!this.$v.input.$dirty) return errors;
      !this.$v.input.required && errors.push(this.inputRequiredText);
      return errors;
    },
  },
  components: {
    BaseInput,
  },
  methods: {
    onClose() {
      this.$emit("dialog");
      this.$emit("toggleDialog");
      this.input = "";
    },
    onConfirm() {
      this.$emit("confirm", this.input);
      this.input = "";
    },
    onInputChange(value, fieldName) {
      this[fieldName] = value;
    },
  },
};
</script>
<style lang="scss" scoped>
.card {
  padding: 1rem;
  position: relative;

  &-close {
    position: absolute !important;
    top: 17px;
    right: 16px;
    width: 0.1rem;
    height: 0.1rem;
  }
}
.input {
  margin: 10px 20px;
}
.btn {
  text-transform: capitalize;
  font-weight: 600;

  &-cancel {
    span {
      color: $primarylight3;
      font-size: 16px;
      letter-spacing: 0;
    }
  }
  &-accept {
    font-size: 16px;
    letter-spacing: 0;
    padding: 0 16px !important;
  }
}
.theme--light.v-btn.v-btn--disabled.v-btn--has-bg {
  background-color: #eeeff7 !important;
}
.actions {
  justify-content: flex-end;
  display: flex;
}
</style>
