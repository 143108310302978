<template>
  <div>
    <p v-if="item.name" class="text-h6 mb-0">{{ item.name }}</p>
    <p v-if="item.address" class="text--secondary mb-0">{{ item.address }}</p>
    <p v-if="item.city" class="text--secondary mb-0">
      {{ `${item.city || ""} ${item.state || ""} ${item.zipCode || ""}` }}
    </p>
    <p v-if="item.phone || item.fax" class="text--secondary mb-0 gap-1">
      <span v-if="item.phone">{{ `Tel: ${item.phone} ` }}</span>
      <span v-if="item.fax">{{ `Fax: ${item.fax}` }}</span>
    </p>
  </div>
</template>

<script>
export default {
  name: "PharmacyDetails",
  props: ["item"],
};
</script>

<style scoped></style>
