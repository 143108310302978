<template>
  <div class="visit-room">
    <v-progress-linear v-if="loading" color="primary" indeterminate />
    <div v-if="encounter">
      <div class="visit-room-header">
        <v-chip v-if="roomIsStarted && !roomIsFinished && timer" color="red darken-1" dark label>
          {{ timer }}
        </v-chip>
        <span class="visit-room-header-select">{{ $t("visits.visitRoomWith") }} {{ encounter.patient.name }}</span>
        <div class="visit-room-header-bordered">
          <v-icon color="icon">mdi-calendar-blank</v-icon>
          <p>{{ dateFormated }}</p>
        </div>
        <div class="visit-room-header-bordered">
          <v-icon color="icon">mdi-clock-outline</v-icon>
          <p>{{ timeRange }}</p>
        </div>
      </div>
      <v-row class="visit-room-content">
        <v-col cols="12" lg="8" sm="12">
          <VideoRoom v-if="!roomIsFinished && room" :call-time="timer" class="visit-room-content-video" />
          <VisitSummary
            v-if="visitNoteSigned || notesMode !== 'notes'"
            :loading="innerLoading"
            containerClassName="container mt-3"
            @changeNotesMode="changeNotesMode"
            @submit="sign"
          />
          <VisitNotes v-else :loading="innerLoading" @previewDraft="previewDraft" @sign="sign" />
        </v-col>
        <v-col cols="12" lg="4" sm="12">
          <VisitDescription />
        </v-col>
      </v-row>
    </div>
  </div>
</template>
<script>
import { mapActions, mapState } from "pinia";

import VideoRoom from "@/components/VideoRoom";
import VisitDescription from "@/components/VisitDescription/Practitioner";
import VisitNotes from "@/components/VisitNotes";
import VisitSummary from "@/components/VisitSummary/PractitionerSummary";
import { snackBarEventBus, snackBarEventName } from "@/eventBuses/snackBar.eventBus";
import { visitRoomMixin } from "@/mixins/visitRoom";
import { useEncountersStore } from "@/pinia-store/encounters";
import { useVisitNoteStore } from "@/pinia-store/visitNote";

export default {
  components: {
    VideoRoom,
    VisitNotes,
    VisitDescription,
    VisitSummary,
  },
  mixins: [visitRoomMixin],
  data() {
    return {
      notesMode: "notes",
      innerLoading: false,
    };
  },
  computed: {
    ...mapActions(useEncountersStore, ["currentEncounter"]),
    ...mapState(useVisitNoteStore, ["status"]),
    visitNoteSigned() {
      return this.status === "signed";
    },
  },
  methods: {
    ...mapActions(useVisitNoteStore, ["getVisitNote", "saveVisitNote", "signVisitNote"]),
    ...mapActions(useEncountersStore, ["getCurrentEncounterById"]),
    ...mapActions(useVisitNoteStore, ["setAppointmentId"]),
    changeNotesMode(newMode) {
      this.notesMode = newMode;
    },
    async previewDraft() {
      this.notesMode = "summary";
    },
    async sign() {
      this.innerLoading = true;
      try {
        await this.saveVisitNote();
        await this.signVisitNote();
        snackBarEventBus.$emit(snackBarEventName, {
          message: this.$t("visits.signSuccess"),
          type: "success",
        });
      } catch (err) {
        console.error(err);
        snackBarEventBus.$emit(snackBarEventName, {
          message: err.message || err,
          type: "error",
        });
      } finally {
        this.innerLoading = false;
      }
      await this.getVisitNote();
    },
    async fetchVisitNote() {
      try {
        const { encounterId } = this.$route.params;
        await this.getCurrentEncounterById(encounterId);
        const appointmentId = this.currentEncounter && this.currentEncounter.appointmentId;
        this.setAppointmentId(appointmentId);
        await this.getVisitNote();
      } catch (err) {
        console.error(err, "ERR");
      }
    },
  },
  async mounted() {
    await this.fetchVisitNote();
  },
  watch: {
    async $route() {
      await this.fetchVisitNote();
    },
  },
};
</script>

<style lang="scss" scoped>
.visit-room-content {
  padding: 30px 12px 12px 12px;
  background: $white-light-1;
  height: 100%;

  &-video {
    height: 80vh;
    min-height: 600px;

    margin-left: 12px;
    margin-right: 12px;
  }
}
</style>
