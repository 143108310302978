import { defineStore } from "pinia";

import { TaskApi, TaskFilterType } from "@/api/task";
import { TaskDto, ValueSetTaskStatusEnum } from "@/pinia-store/interfaces/Task";

export const useTasksStore = defineStore({
  id: "tasks",
  state: () => ({
    items: [],
    isLoading: false,
  }),
  actions: {
    async getTasks(filter?: TaskFilterType) {
      this.isLoading = true;
      let data = { results: [] };
      try {
        data = await TaskApi.search(filter);
        this.items = data.results;
      } catch (e) {
        console.error(e);
      } finally {
        this.isLoading = false;
      }
      return data;
    },
    updateStateTask(payload: TaskDto): void {
      const index = this.items.findIndex((i: TaskDto) => i.id === payload.id);
      if (index !== -1) {
        this.items[index] = payload;
      } else this.items.unshift(payload);
    },
    async updateTask(payload: Partial<TaskDto> & { id: string }) {
      await TaskApi.update(payload.id, payload);
    },
  },
  getters: {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    clinicalRequestsDraftCount: (state) => state.items.filter((i) => i.status === ValueSetTaskStatusEnum.draft).length,
  },
});
