<template>
  <v-dialog :value="dialog" max-width="400" @click:outside="close">
    <v-card class="event__more__dialog">
      <v-btn icon class="dialog__head__close-icon">
        <v-icon @click="close" size="small">mdi-close</v-icon>
      </v-btn>
      <div>
        <v-card-text class="text--secondary text-center pb-0 pt-8">{{ formatDate(date).format("dddd") }}</v-card-text>
        <v-card-title class="text-center d-block">{{ formatDate(date).format("MMMM, DD") }}</v-card-title>
      </div>
      <v-list dense class="body-wrap">
        <v-list-item-group>
          <v-list-item class="more__list__item" v-for="slot in events" :key="slot.id">
            <v-list-item-content @click="onClickEvent(slot)">
              <v-list-item-title
                ><v-icon large :color="eventColor(slot)">mdi-circle-small</v-icon>
                <span class="mr-2">{{ formatDate(slot.start).format("HH:mm") }}</span>
                <span>{{ patientFullName(slot.participant) }}</span>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-card>
  </v-dialog>
</template>

<script>
import { AppointmentStatusEnum } from "@/types/AppointmentQueryFilter";

export default {
  name: "EventMore",
  props: ["dialog", "events", "date"],
  methods: {
    close() {
      this.$emit("close");
    },
    onClickEvent(item) {
      this.$emit("onClickEvent", item);
      this.$emit("close");
    },
    formatDate(date) {
      return this.$moment(date);
    },
    eventColor(slot) {
      let color = "#2196F3";
      switch (slot.status) {
        case AppointmentStatusEnum.booked: {
          color = "#2196F3";
          break;
        }
        case AppointmentStatusEnum.cancelled: {
          color = "#E53935";
          break;
        }
        case AppointmentStatusEnum.pending: {
          color = "#F2994A";
          break;
        }
      }
      return color;
    },
    patientFullName(participants) {
      const patient = participants.find((participant) => participant?.actor?.reference.split("/")[0] === "Patient");
      return patient?.actor?.display;
    },
  },
};
</script>

<style scoped lang="scss">
.event__more__dialog {
  position: absolute;
  top: 50px;
  left: 0;
  right: 0;
  margin: auto;
  width: 250px;
  .body-wrap {
    overflow-y: auto;
    max-height: 255px;
    :deep .v-list-item__content {
      padding: 0 !important;
    }
  }
  .dialog__head__close-icon {
    position: absolute;
    top: 10px;
    right: 10px;
  }
}
</style>
