<template>
  <div class="patients-list">
    <div class="patients-list-header">
      <div class="patients-list-header-block">
        <h1>{{ $t("conversation.logs") }}</h1>
      </div>
      <div class="patients-list-header-block">
        <v-text-field
          v-model="search"
          prepend-inner-icon="mdi-magnify"
          solo
          dense
          class="patients-list-header-block-wide-input"
          hide-details
          ontouchstart="this.removeAttribute('readonly');"
          onfocus="this.removeAttribute('readonly');"
          :placeholder="$t('patients.searchPlaceholder')"
        />
      </div>
    </div>
    <v-divider class="patients-list-divider" />
    <ChatBotConversationList :headers="conversation" :loading="loading" :search="search" />
  </div>
</template>

<script>
import ChatBotConversationList from "@/components/Chat/ChatBotLog/ChatBotConversationList";
import { conversation } from "@/components/Patients/constants";

export default {
  name: "ConversationLogsTable",
  components: { ChatBotConversationList },
  data() {
    return {
      loading: false,
      conversation,
      search: "",
    };
  },
};
</script>

<style scoped lang="scss">
.patients-list {
  :deep .v-input .v-input__slot {
    margin-top: 0 !important;
  }
  padding: 0 30px;
  @media screen and (min-width: 600px) {
    max-width: calc(100vw - 62px);
  }
  &-last-updated {
    color: rgba(0, 0, 0, 0.5);
    margin-bottom: 40px;
  }
  &-divider {
    margin-bottom: 20px;
  }
  &-header {
    margin-top: 20px;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    @media (max-width: 480px) {
      flex-direction: column;
      margin: 0;
    }

    &-actions {
      display: flex;
      align-items: flex-start;
    }

    &-block {
      padding-bottom: 24px;
      max-width: 100%;
      @media (max-width: 480px) {
        max-width: 100%;
      }
    }
  }

  &-tabs {
    .v-tabs-bar {
      border-bottom: 1px solid $primarylight;
    }

    &-tab {
      #text {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;

        color: $primaryblack;

        mix-blend-mode: normal;
      }

      &-item {
        padding: 0;
      }
    }
  }

  &-advance-search {
    &-title {
      font-weight: 500;
      font-size: 25px;
      line-height: 31px;
      color: $primaryblack;
    }

    &-close-btn {
      cursor: pointer;
      border: 1px solid $primarylight2;
      box-sizing: border-box;
      border-radius: 50%;
      position: absolute;
      top: -14px;
      right: -7px;
      height: 24px;
      justify-content: center;

      i {
        color: #d2d3e1;
        font-size: 6px !important;
        padding: 5px;
      }
    }

    &-row {
      display: flex;
      align-items: center;
      padding-top: 7px;
      padding-bottom: 7px;
      @include tablet {
        flex-direction: column;
        align-items: start;
      }

      .v-list-item-title {
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        color: $primaryblack2;
      }

      .col {
        padding: 0;
        @include tablet {
          max-width: 100%;
          flex-basis: 100%;
        }
      }

      .v-text-field {
        .v-input__control {
          .v-input__slot {
            margin-bottom: 0 !important;
          }
        }
      }
    }
  }
}

.success-dialog {
  display: flex !important;
  flex-direction: column;
  align-items: center;
  padding: 2rem;

  img {
    display: inline-block;
  }

  &_title {
    font-style: normal;
    font-weight: 500;
    font-size: 21px;
    line-height: 26px;
    text-align: center;
    word-break: break-word !important;
  }

  &_close-icon {
    top: 10px;
    right: 10px;
  }
}

.advance-search-dialog {
  .v-card {
    padding-bottom: 34px;
    padding-left: 16px;
    padding-right: 16px;

    .v-card__actions {
      .v-btn {
        text-transform: none;

        &:not(.primary) {
          color: $primaryblack2;
        }
      }
    }
  }

  @media only screen and (max-width: 600px) {
    position: fixed;
    top: 5% !important;
    margin: 0 auto !important;
    left: 0 !important;
    right: 0 !important;
    max-height: calc(100% - 10%);
    overflow-x: auto;
    max-width: 90%;
    .v-card__actions {
      flex-direction: column;

      button {
        margin-top: 16px;
        margin-left: 0 !important;
      }
    }
  }
}
</style>
