class UserActivityTracker {
  public handlerEvent = "userActivityTrackerIsInactive";
  private activityTime: number;
  private timer: number | null;
  private active: boolean;

  constructor(activityTime: number) {
    this.activityTime = activityTime;
    this.timer = null;
    this.active = false;
    this.onActivity = this.onActivity.bind(this);
  }

  public destroy(): void {
    clearTimeout(this.timer as number);
    this.removeEvents();
  }

  public startTimer(): void {
    clearTimeout(this.timer as number);
    this.removeEvents();
    this.addEvents();
    this.active = true;
    this.timer = window.setTimeout(() => {
      this.active = false;
      this.triggerInactive();
    }, this.activityTime);
  }

  public triggerInactive(): void {
    this.destroy();
    window.dispatchEvent(new Event(this.handlerEvent));
  }

  private addEvents(): void {
    window.addEventListener("mousemove", this.onActivity);
    window.addEventListener("click", this.onActivity);
    window.addEventListener("scroll", this.onActivity);
    document.addEventListener("scroll", this.onActivity);
  }

  private removeEvents(): void {
    window.removeEventListener("mousemove", this.onActivity);
    window.removeEventListener("click", this.onActivity);
    window.removeEventListener("scroll", this.onActivity);
    document.removeEventListener("scroll", this.onActivity);
  }

  private onActivity(): void {
    this.startTimer();
  }
}

// export default new UserActivityTracker(1000 * 10);
export default new UserActivityTracker(1000 * 60 * 15);
