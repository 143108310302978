<template>
  <PractitionerScheduleVisit />
</template>
<script>
import { mapActions, mapState } from "pinia";
import Vue from "vue";

import PractitionerScheduleVisit from "@/components/Checkout/PractitionerScheduleVisit/Index";
import { useAppointmentsStore } from "@/pinia-store/appointments";
import { useAuthStore } from "@/pinia-store/auth";

export default Vue.extend({
  name: "PractitionerRequestVisitView",
  components: {
    PractitionerScheduleVisit,
  },
  computed: {
    ...mapState(useAuthStore, ["uid"]),
  },
  methods: {
    ...mapActions(useAppointmentsStore, ["setStringFieldByName"]),
  },
  mounted() {
    this.setStringFieldByName({ fieldName: "practitionerId", value: this.uid });
  },
});
</script>
