<template>
  <Confirm
    :confirmText="`${form.id ? 'Change' : 'Add'} order`"
    :dialog="formOpened"
    :loading="loading"
    :title="`${form.id ? 'Change' : 'Add'} Blood Glucose order`"
    cancelText="Cancel"
    maxWidth="90%"
    rootClass="order-form"
    text=""
    width="600px"
    @confirm="performStore"
    @toggleDialog="toggleFormOpened"
  >
    <v-container class="no-padding-bottom no-padding-top">
      <v-row>
        <v-col cols="3">
          <v-subheader> Normal Before Meal *</v-subheader>
        </v-col>
        <v-col class="inline-inputs" cols="9">
          <BaseInput
            :errors="validateField('beforeMealLow')"
            :value="form.beforeMealLow"
            class="field-details-absolute"
            fieldName="beforeMealLow"
            placeholder="Enter"
            @change="onFieldChange"
          />
          <v-icon slot="prepend" color="green"> mdi-minus</v-icon>
          <BaseInput
            :errors="validateField('beforeMealHigh')"
            :value="form.beforeMealHigh"
            class="field-details-absolute"
            fieldName="beforeMealHigh"
            placeholder="Enter"
            @change="onFieldChange"
          />
          <span class="text--secondary pl-1">mg/dL</span>
        </v-col>
        <v-col cols="3">
          <v-subheader>Normal After Meal *</v-subheader>
        </v-col>
        <v-col class="inline-inputs" cols="9">
          <BaseInput
            :errors="validateField('afterMealLow')"
            :value="form.afterMealLow"
            fieldName="afterMealLow"
            placeholder="Enter"
            @change="onFieldChange"
          />
          <v-icon slot="prepend" color="green"> mdi-minus</v-icon>
          <BaseInput
            :errors="validateField('afterMealHigh')"
            :value="form.afterMealHigh"
            fieldName="afterMealHigh"
            placeholder="Enter"
            @change="onFieldChange"
          />
          <span class="text--secondary pl-1">mg/dL</span>
        </v-col>
        <v-col cols="3">
          <v-subheader>Frequency</v-subheader>
        </v-col>
        <v-col cols="9">
          <Select
            :errors="validateField('patientInstruction')"
            :hideDetails="true"
            :items="frequencyList"
            :value="form.patientInstruction"
            item-text="name"
            item-value="id"
            label="Select frequency"
            @change="onChangeInstruction"
          />
        </v-col>
        <v-col cols="3">
          <v-subheader> Duration</v-subheader>
        </v-col>
        <v-col class="inline-inputs" cols="9">
          <DatePicker
            :dense="true"
            :errors="validateField('startDate')"
            :height="47"
            :hideDetails="true"
            :value="form.startDate"
            placeholder="Start date"
            @setDate="onChangeStartDate"
          />
          <v-icon slot="prepend" color="green"> mdi-minus</v-icon>
          <DatePicker
            :dense="true"
            :errors="validateField('endDate')"
            :height="47"
            :hideDetails="true"
            :max-date="maxDate"
            :value="form.endDate"
            placeholder="End date"
            @setDate="onChangeEndDate"
          />
        </v-col>
        <v-col cols="3">
          <v-subheader> Notes</v-subheader>
        </v-col>
        <v-col cols="9">
          <Textarea :hideDetails="true" :value="form.note" placeholder="Enter Notes" @change="onNoteChange" />
        </v-col>
      </v-row>
    </v-container>
  </Confirm>
</template>
<script>
import { mapActions, mapState } from "pinia";

import { ServiceRequestApi } from "@/api/serviceRequest";
import Confirm from "@/components/shared/Confirm";
import DatePicker from "@/components/shared/DatePicker";
import BaseInput from "@/components/uikit/BaseInput";
import Select from "@/components/uikit/Select";
import Textarea from "@/components/uikit/Textarea";
import { BLOOD_GLUCOSE_ORDER_ADDED } from "@/eventBuses/monitoringEvents";
import { monitoringOrder } from "@/mixins/monitoringOrder";
import { validatorErrors } from "@/mixins/validatorErrors.js";
import { useBloodGlucoseOrderStore } from "@/pinia-store/bodyGlucoseOrder";
import { ServiceRequestStatus } from "@/types/ServiceRequestStatus.enum";

export default {
  name: "AddBloodGlucoseOrder",
  components: {
    Textarea,
    DatePicker,
    BaseInput,
    Confirm,
    Select,
  },
  props: {
    isStatusActive: {
      default: () => false,
      type: Boolean,
    },
  },
  mixins: [validatorErrors, monitoringOrder],
  computed: {
    ...mapState(useBloodGlucoseOrderStore, ["formOpened", "form"]),
  },
  methods: {
    ...mapActions(useBloodGlucoseOrderStore, ["changeFormField", "toggleFormOpened"]),
    async performStore() {
      try {
        this.loading = true;
        this.startSave();
        const {
          beforeMealLow,
          beforeMealHigh,
          afterMealHigh,
          afterMealLow,
          id,
          startDate,
          encounterId,
          note,
          patientInstruction,
          endDate,
        } = this.form;
        await ServiceRequestApi.createBloodGlucose({
          id,
          note,
          patientInstruction,
          startDate,
          endDate,
          encounterId,
          status: this.isStatusActive ? ServiceRequestStatus.active : ServiceRequestStatus.draft,
          beforeMealLow: parseFloat(beforeMealLow),
          beforeMealHigh: parseFloat(beforeMealHigh),
          afterMealHigh: parseFloat(afterMealHigh),
          afterMealLow: parseFloat(afterMealLow),
        });
        this.loading = false;
        this.toggleFormOpened();
        this.$root.$emit(BLOOD_GLUCOSE_ORDER_ADDED);

        this.endSave();
      } catch (err) {
        this.setResponseErrors(err);
        this.loading = false;
        this.endSave();
      }
    },
  },
};
</script>
<style lang="scss">
.order-form {
  .inline-inputs {
    flex-direction: row;
    display: flex;
    align-items: center;
  }

  .card-title {
    font-weight: 500 !important;
    font-size: 25px !important;
    line-height: 31px !important;
    color: #25233a;
  }

  .v-card__title + .v-card__text {
    font-size: 14px;
    line-height: 17px;
    color: #33343d;
  }
}
</style>
