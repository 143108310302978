<template>
  <v-btn
    v-blur
    :block="fullWidth || size === 'x-large'"
    :class="className"
    :disabled="disabled"
    :height="height"
    :loading="loading"
    :ripple="false"
    :rounded="rounded"
    :type="type"
    :width="width"
    outlined
    @click="onClick"
  >
    <v-icon v-if="icon" :size="iconSize" class="iconic-btn_icon" dark left>
      {{ icon }}
    </v-icon>
    {{ text }}
    <v-icon v-if="iconRight" :size="iconSize" class="iconic-btn_icon" dark right>
      {{ iconRight }}
    </v-icon>
    <slot name="icon-right"></slot>
  </v-btn>
</template>
<script>
export default {
  name: "OutlinedButton",
  props: {
    loading: { default: false, type: Boolean },
    rounded: { default: false, type: Boolean },
    size: { default: "medium", type: String },
    text: { required: true, type: String },
    disabled: { default: false, type: Boolean },
    width: { default: undefined, type: String },
    type: { default: "button", type: String },
    icon: { default: "", type: String },
    iconRight: { default: "", type: String },
    color: { default: "", type: String },
    fullWidth: { default: false, type: Boolean },
    additionalClassName: String,
  },
  computed: {
    className() {
      if (this.icon || this.iconRight) return `iconic-btn iconic-btn__${this.size} ${this.additionalClassName}`;
      return `outlined-btn outlined-btn__${this.size} ${this.additionalClassName}`;
    },
    height() {
      let height = null;
      switch (this.size) {
        case "x-large":
          height = 60;
          break;
        case "large":
          height = 46;
          break;
        case "medium":
          height = 36;
          break;
        case "small":
          height = 30;
          break;
      }
      return height;
    },
    iconSize() {
      let iconSize = "";
      switch (this.size) {
        case "large":
          iconSize = "14";
          break;
        case "small":
          iconSize = "12";
          break;
      }
      return iconSize;
    },
  },
  methods: {
    onClick() {
      this.$emit("onClick");
    },
  },
};
</script>
