<template>
  <Confirm
    :dialog="dialog"
    :loading="loading"
    cancelText="Cancel"
    confirmText="Sign & Add"
    maxWidth="90%"
    rootClass="add-note-form"
    text=""
    title="Addendum"
    width="600px"
    @confirm="save"
    @toggleDialog="close"
  >
    <v-container class="no-padding-bottom no-padding-top">
      <v-row>
        <v-col cols="12">
          <Textarea
            :height="110"
            :hide-details="true"
            :value="privateNote"
            fieldName="privateNote"
            placeholder="Type any relevant information not already covered"
            @change="onPrivateNoteInput"
          />
        </v-col>
      </v-row>
    </v-container>
  </Confirm>
</template>

<script>
import { mapActions, mapState } from "pinia";

import { CommunicationApi } from "@/api/communication";
import Confirm from "@/components/shared/Confirm";
import Textarea from "@/components/uikit/Textarea";
import { ADDENDUM_NOTE_ADDED } from "@/eventBuses";
import { useAuthStore } from "@/pinia-store/auth";
import { useMedicalRecordStore } from "@/pinia-store/medicalRecord";

export default {
  name: "AddAddendumNote",
  props: ["dialog", "mode", "editId", "encounterId", "patientId"],
  components: { Textarea, Confirm },
  data: () => ({
    privateNote: "",
    loading: false,
  }),
  computed: {
    ...mapState(useAuthStore, ["uid"]),
  },

  methods: {
    ...mapActions(useMedicalRecordStore, ["createPrivateNote", "updatePrivateNote"]),
    close() {
      this.$emit("close");
    },
    async save() {
      this.loading = true;
      if (this.privateNote !== "") {
        if (this.mode === "edit") {
          await this.updatePrivateNote({ encounterId: this.encounterId, id: this.editId, text: this.privateNote });
        } else {
          await this.createPrivateNote({
            patientId: this.patientId,
            practitionerId: this.uid,
            encounterId: this.encounterId,
            privateNote: this.privateNote,
          });
        }
        this.$emit("save");
        this.$root.$emit(ADDENDUM_NOTE_ADDED);
        this.loading = false;
      }
    },
    onPrivateNoteInput(value) {
      this.privateNote = value;
    },
  },
  watch: {
    async dialog(val) {
      if (!val) {
        this.privateNote = "";
      }
      if (this.mode === "edit" && this.editId) {
        const note = await CommunicationApi.getById(this.editId);
        this.privateNote = note.privateNote;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.add-note-modal {
  position: relative;
  text-align: center;
  padding: 30px;

  &__title {
    font-size: 20px;
    font-weight: 500;
    text-align: center;
  }

  &__close-btn {
    position: absolute;
    top: 20px;
    right: 20px;
  }
}
</style>
