import { defineStore } from "pinia";

interface AvatarItem {
  id: string;
  photoURL: string | null;
}

interface AvatarState {
  items: AvatarItem[];
}

export const useAvatarsStore = defineStore({
  id: "avatars",
  state: (): AvatarState => ({
    items: [],
  }),
  actions: {
    setItem(payload: AvatarItem) {
      const item = this.items.find((i: AvatarItem) => i.id === payload.id);
      if (item) return;
      this.items = [...this.items, payload];
    },
  },
  getters: {
    avatarOf:
      (state) =>
      (id: string): AvatarItem | undefined => {
        return state.items.find((i) => i.id === id);
      },
  },
});
