export enum ObservationEnum {
  bodyTemperature = "bodyTemperature",
  bloodPressure = "bloodPressure",
  heartRate = "heartRate",
  oxygenSaturation = "oxygenSaturation",
  bloodGlucose = "bloodGlucose",
  respiratoryRate = "respiratoryRate",
  bodyHeight = "bodyHeight",
  bodyWeight = "bodyWeight",
  smokingBehavior = "smokingBehavior",
  unknown = "unknown",
}
