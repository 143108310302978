<template>
  <PasswordHelper :isOpen="isPasswordHelper && showHelper" :passwordRootChecks="passwordRootChecks" :password="value">
    <v-text-field
      :value="value"
      :type="showPassword ? 'text' : 'password'"
      light
      :class="`${className} base-input`"
      :solo="!outlined"
      :flat="!outlined"
      :dense="dense"
      :height="height"
      :outlined="!!errors.length || outlined"
      :label="placeholder"
      :error-messages="errors"
      :hide-details="hideDetails && !errors.length"
      @input="onChange"
      @blur="onBlur"
      @focus="onFocus"
      autocomplete="new-password"
    >
      <template v-slot:append>
        <v-icon @click="showPassword = !showPassword" size="16" color="icon">
          {{ showPassword ? "mdi-eye" : "mdi-eye-off" }}</v-icon
        >
      </template>
    </v-text-field>
  </PasswordHelper>
</template>
<script>
import PasswordHelper from "./PasswordHelper";
export default {
  name: "PasswordInput",
  components: {
    PasswordHelper,
  },
  props: {
    className: { default: "", type: String },
    value: String,
    fieldName: String,
    passwordRootChecks: Array,
    type: { default: "text", type: String },
    height: { default: 47, type: Number },
    placeholder: { default: "", type: String },
    errors: { default: () => [], type: Array },
    dense: { default: true, type: Boolean },
    isPasswordHelper: { default: false, type: Boolean },
    hideDetails: { default: true, type: Boolean },
  },
  data: () => ({
    showPassword: false,
    showHelper: false,
    outlined: false,
  }),
  methods: {
    onChange(value) {
      this.$emit("change", value, this.fieldName);
    },
    onBlur() {
      this.showHelper = false;
      this.outlined = false;
      this.$emit("blur");
    },
    onFocus() {
      this.showHelper = true;
      this.outlined = true;
    },
  },
};
</script>
