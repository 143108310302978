<template>
  <div class="maintenance-page">
    <h1>Maintenance</h1>
  </div>
</template>
<script>
export default {
  name: "MaintenancePage",
  mounted() {
    document.body.classList.add("under-maintenance");
  },
};
</script>
<style lang="scss">
.under-maintenance {
  overflow: hidden;
  width: 100vw;
  height: 100vh;
}

.maintenance-page {
  z-index: 999;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  background-color: rgba(51, 163, 255, 1);
}
</style>
