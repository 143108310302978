<template>
  <v-card class="calendar">
    <v-progress-linear v-if="loading" color="primary" indeterminate></v-progress-linear>
    <div class="calendar_header">
      <OutlinedButton :text="controlPanelButtonText" class="calendar_header_today-btn" @onClick="moveToday" />
      <v-btn
        :min-width="30"
        :ripple="false"
        :width="30"
        class="calendar_header_btn__switch outlined-btn outlined-btn__medium"
        outlined
        @click="movePrev"
        ><img alt="Prev" class="calendar_header_icon__rotate" src="@/assets/icons/arrow.svg" />
      </v-btn>
      <v-btn
        :min-width="30"
        :ripple="false"
        :width="30"
        class="calendar_header_btn__switch outlined-btn outlined-btn__medium"
        depressed
        outlined
        @click="moveNext"
      >
        <img alt="Next" src="@/assets/icons/arrow.svg" />
      </v-btn>
      <DatePicker
        :dense="true"
        :height="34"
        :hideDetails="true"
        :inputValue="datePickerValue"
        :isYearFirst="false"
        :max-date="maxScheduleDate"
        :value="currentDate"
        className="date-picker__box-shadow calendar_header_date-picker-calendar v-input--hide-details2"
        @setDate="changeCurrentDate"
      />
      <v-select
        v-if="$vuetify.breakpoint.xsOnly"
        :items="calendarValues"
        :value="calendarValue"
        class="calendar_header_date-picker"
        dense
        hide-details="true"
        outlined
        placeholder="Day"
        @change="changeCalendarValue($event)"
      ></v-select>
      <v-btn
        v-for="item in calendarValues"
        v-else
        :key="item.value"
        :color="item.value === calendarValue ? 'primary' : ''"
        :ripple="false"
        class="calendar_header_btn__switch outlined-btn outlined-btn__medium"
        depressed
        outlined
        @click="changeCalendarValue(item.value)"
      >
        {{ item.text }}
      </v-btn>
    </div>
    <div class="calendar-content">
      <v-sheet
        :class="
          calendarValue === 'day'
            ? 'one_doctor full-height-mobile'
            : calendarValue === 'week'
            ? 'full-height-mobile full-width'
            : 'full-width'
        "
        class="calendar-content_sheet"
        height="700"
      >
        <v-calendar
          ref="calendar"
          :class="calendarValue === 'day' ? 'daily__calendar-day' : ''"
          :event-color="getEventColor"
          :events="events"
          :start="currentDate"
          :type="calendarValue"
          category-show-all
          first-interval="7"
          interval-count="17"
          interval-height="96"
          @click:time="startTime"
          @click:event="openModalEvent"
          @click:more="onMoreClick"
          @click:date="startTime"
        >
          <template v-slot:event="{ event }">
            <div
              v-if="calendarValue !== 'day'"
              :class="{
                onGoing: event.onGoing,
                pending: event.status === 'pending',
                scheduled: ['booked', 'fulfilled'].includes(event.status),
                cancelled: ['cancelled', 'noshow'].includes(event.status),
              }"
              class="week-day-slot"
            >
              <span v-if="$vuetify.breakpoint.width < 900" class="pr-1">
                {{ makeRoundtime(event.start, event.end, true) }}
              </span>
              <span v-else class="pr-1">{{ makeRoundtime(event.start, event.end, true) }}</span>
              <span>{{ event.participant[1].actor.display }} </span>
            </div>
            <div
              v-else
              :class="{
                pending: event.status === 'pending',
                scheduled: ['booked', 'fulfilled'].includes(event.status),
                cancelled: ['cancelled', 'noshow'].includes(event.status),
                onGoing: event.onGoing,
              }"
              class="d-flex justify-space-around align-center single_doctor"
            >
              <span class="pr-4">{{ makeRoundtime(event.start, event.end) }}</span>

              <p :class="['booked', 'pending', 'fulfilled'].includes(event.status) ? 'text-dark' : ''" class="mb-0">
                {{ event.participant[1].actor.display }}
              </p>
              <p class="status mb-0 d-flex align-center">
                <span class="dot d-block"></span>
                {{ event.status === "pending" ? "Pending" : event.status === "booked" ? "Scheduled" : "" }}
                {{ event.status === "noshow" ? "Expired" : "" }}
                {{ event.status === "cancelled" ? "Cancelled" : "" }}
                {{ event.status === "fulfilled" ? "Fulfilled" : "" }}
              </p>
            </div>
          </template>
          <template v-slot:day-body="{ date, week }">
            <div :class="{ first: date === week[0].date }" :style="{ top: nowY }" class="v-current-time"></div>
          </template>
          <template v-if="calendarValue === 'day'" v-slot:day-label-header>
            <p class="my-3 doctors-schedule__title text-left ml-5">{{ `${displayName}'s schedule` }}</p>
          </template>
        </v-calendar>
      </v-sheet>
      <v-card v-if="calendarValue === 'day'" class="one_doctor_upcoming_info">
        <UpcomingAppointment
          :appointmentInformation="appointmentInformation"
          @cancel="cancelVisitDialog = true"
          @openScheduleDialog="openScheduleDialog"
          @viewDetails="viewDetails"
        />
      </v-card>
    </div>

    <ScheduleDialog v-model="dialog" :dialog="dialog" @on-close="dialog = false" />
    <ModalEdit
      :closeEventModal="closeEventModal"
      :dialog="isOpenSlotModal"
      :loading="editModalLoading"
      :practitionerId="appointmentInformation && appointmentInformation.practitionerId"
      :selectedTime="selectedTime"
      :updateCalendarInfo="getPractitionerFreeSlots"
      role="practitioner"
    />
    <CancelVisit
      v-if="cancelVisitDialog"
      v-model="cancelVisitDialog"
      :appointmentId="appointmentInformation && appointmentInformation.id"
      :closeEventModal="closeEventModal"
      :practitionerId="appointmentInformation && appointmentInformation.practitionerId"
      :slotId="appointmentInformation && appointmentInformation.slotId"
      :updateCalendarInfo="getScheduledAppointments"
      @toggleDialog="cancelVisitDialog = !cancelVisitDialog"
    />

    <EventMore
      :date="eventMoreDate"
      :dialog="eventMoreDialog"
      :events="moreSlots"
      @close="eventMoreDialog = false"
      @onClickEvent="onClickOnEventMore"
    />
  </v-card>
</template>
<script>
import { mapActions, mapState } from "pinia";

import { AppointmentsApi } from "@/api/appointment";
import { PatientsApi } from "@/api/patients";
import EventMore from "@/components/Calendar/modals/More";
import UpcomingAppointment from "@/components/Calendar/modals/UpcomingAppointment";
import CancelVisit from "@/components/CancelVisit";
import { timeISO8601OFormat } from "@/components/Clinicians/constants";
import DatePicker from "@/components/shared/DatePicker";
import OutlinedButton from "@/components/uikit/OutlinedButton";
import { calendarMixin } from "@/mixins/calendar.ts";
import { useAppointmentsStore } from "@/pinia-store/appointments";
import { useAuthStore } from "@/pinia-store/auth";
import { useEncountersStore } from "@/pinia-store/encounters";
import { AppointmentInformationTab } from "@/pinia-store/interfaces/CancelAppointment";
import { usePatientsStore } from "@/pinia-store/patients";
import { usePatientVisitScheduleStore } from "@/pinia-store/patientVisitSchedule";
import { useProfileSettingsStore } from "@/pinia-store/profileSettings";
import { useSlotsStore } from "@/pinia-store/slots";
import { useVisitSettingsStore } from "@/pinia-store/visitSettings";
import { routesEnum } from "@/types/Routes.enum";
import ScheduleDialog from "@/views/Practitioner/Appoinments/ScheduleDialog";

import ModalEdit from "../../views/Owner/Appoinments/ModalEdit";

export default {
  name: "Calendar",
  mixins: [calendarMixin],
  components: {
    EventMore,
    DatePicker,
    OutlinedButton,
    ScheduleDialog,
    CancelVisit,
    ModalEdit,
    UpcomingAppointment,
  },
  data: () => ({
    events: [],
    ready: false,
    schedulesList: [],
    loading: false,
    dialog: false,
    cancelDialog: false,
    scheduleInDialog: {},
    isReadOnly: true,
    currentDate: new Date(),
    calendarValue: "day",
    createStart: null,
    isOpenSlotModal: false,
    editModalLoading: false,
    selectedTime: {},
    eventMoreDialog: false,
    moreSlots: [],
    eventMoreDate: "",
    cancelVisitDialog: false,
    updateTimeInterval: null,
  }),
  watch: {
    currentDate(val) {
      this.$router.push({
        name: routesEnum.practitionerSchedule,
        query: { calendarValue: this.calendarValue, currentDate: this.$moment(val).unix() },
      });
    },
    calendarValue(val) {
      this.$router.push({
        name: routesEnum.practitionerSchedule,
        query: { calendarValue: val, currentDate: this.$moment(this.currentDate).unix() },
      });
    },
  },
  computed: {
    ...mapState(useEncountersStore, ["encounters"]),
    ...mapState(usePatientVisitScheduleStore, ["maxScheduleDate"]),
    calendarValues() {
      return [
        {
          text: "Day",
          value: "day",
        },
        {
          text: "Week",
          value: "week",
        },
        {
          text: "Month",
          value: "month",
        },
      ];
    },
    ...mapState(useSlotsStore, [
      "availabilities",
      "slots",
      "weekends",
      "slotsArray",
      "upcomingSlot",
      "otherSlots",
      "slotInformation",
    ]),
    ...mapState(useAuthStore, ["timeZone", "uid", "displayName"]),
    ...mapState(useProfileSettingsStore, ["practitionerSettings"]),
    ...mapState(useAppointmentsStore, ["appointmentInformation"]),
    nowY() {
      return this.cal ? this.cal.timeToY(this.cal.times.now) + "px" : "-10px";
    },
    cal() {
      return this.ready ? this.$refs.calendar : null;
    },
    controlPanelButtonText() {
      return this.$t("general.status.upcoming");
    },
    datePickerValue() {
      return this.$moment(this.currentDate).format("MMM, D");
    },
  },
  methods: {
    ...mapActions(useVisitSettingsStore, ["preloadVisitSettings"]),
    ...mapActions(useAppointmentsStore, ["setStringFieldByName", "setAppointmentInformationTab"]),
    ...mapActions(useEncountersStore, ["getAllEncounters"]),
    ...mapActions(useSlotsStore, [
      "getPractitionerAvailability",
      "getPractitionerFreeSlots",
      "getSlotInformation",
      "cleanSlotInformation",
    ]),
    ...mapActions(usePatientsStore, ["getAllByPractitionerId"]),
    ...mapActions(useAppointmentsStore, ["getAppointmentInformation", "cleanAppointmentInformation"]),
    ...mapActions(useAppointmentsStore, ["setAppointmentInformation", "setAppointmentInformationLoading"]),
    openScheduleDialog() {
      this.dialog = true;
    },
    async onClickOnEventMore(item) {
      this.isOpenSlotModal = true;
      this.editModalLoading = true;
      this.cleanAppointmentInformation();
      this.setAppointmentInformationLoading(true);
      await this.getAppointmentInformation(item.id);
      this.editModalLoading = false;
    },
    async openModalEvent({ nativeEvent, event }) {
      nativeEvent.stopPropagation();
      if (this.calendarValue !== "day") {
        this.isOpenSlotModal = true;
        this.editModalLoading = true;
      } else {
        this.cleanAppointmentInformation();
      }
      this.setAppointmentInformationTab(AppointmentInformationTab.selected);
      this.setAppointmentInformationLoading(true);
      await this.getAppointmentInformation(event.id);
      this.editModalLoading = false;
    },
    viewDetails() {
      if (this.appointmentInformation && this.appointmentInformation.encounterId)
        this.$router.push(`/practitioner/encounters/${this.appointmentInformation.encounterId}`);
    },
    makeRoundtime(start, end, small = false) {
      const startDate = this.$moment.tz(start, this.timeZone);
      const endDate = this.$moment.tz(end, this.timeZone);
      return small ? `${startDate.format("HH:mm")}` : `${startDate.format("HH:mm")} - ${endDate.format("HH:mm")}`;
    },
    startTime(tms) {
      if (tms.future === true) {
        const mouse = this.toTime(tms);
        this.createStart = this.roundTime(mouse);
        const slot = this.slotsArray.find(
          (slot) => this.$moment(slot.start).format() === this.$moment(this.createStart).format(),
        );
        if (slot) {
          let value = slot.id;
          this.setStringFieldByName({ value, fieldName: "slotId" });
        } else {
          let value = "";
          this.setStringFieldByName({ value, fieldName: "slotId" });
        }
        this.createEvent = {
          color: "blue",
          timed: true,
          date: tms.date,
        };
        this.setStringFieldByName({ value: tms.date, fieldName: "visitDate" });
        this.selectedTime = this.createEvent;

        this.dialog = true;
      }
    },
    toTime(tms) {
      return new Date(tms.year, tms.month - 1, tms.day, tms.hour, tms.minute).getTime();
    },
    changeCalendarValue(e) {
      this.calendarValue = e;
      this.$router.push({
        name: routesEnum.practitionerSchedule,
        query: { calendarValue: e, currentDate: this.currentDate },
      });
      this.getScheduledAppointments();
    },
    toggleDialog() {
      this.dialog = !this.dialog;
    },
    toggleCancelDialog() {
      this.dialog = !this.dialog;
    },
    onCancelVisit() {
      this.dialog = false;
      this.cancelDialog = true;
    },
    onSuccessCancel() {
      const { id } = this.scheduleInDialog;
      this.schedulesList = this.schedulesList.filter((sl) => sl.id !== id);
    },
    movePrev() {
      if (this.calendarValue === "day") {
        this.currentDate = this.$moment(this.currentDate).subtract(1, "days").toDate();
      }
      if (this.calendarValue === "week") {
        this.currentDate = this.$moment(this.currentDate).subtract(7, "days").toDate();
      }
      if (this.calendarValue === "month") {
        this.currentDate = this.$moment(this.currentDate).subtract(1, "M").toDate();
      }
      this.getScheduledAppointments();
    },
    moveNext() {
      if (this.calendarValue === "day") {
        this.currentDate = this.$moment(this.currentDate).add(1, "days").toDate();
      }
      if (this.calendarValue === "week") {
        this.currentDate = this.$moment(this.currentDate).add(7, "days").toDate();
      }
      if (this.calendarValue === "month") {
        this.currentDate = this.$moment(this.currentDate).add(1, "M").toDate();
      }
      this.getScheduledAppointments();
    },
    moveToday() {
      this.currentDate = new Date();
      this.getScheduledAppointments();
    },
    changeCurrentDate(newDate) {
      this.currentDate = newDate;
      this.getScheduledAppointments();
    },
    roundTime(time, down = true) {
      const roundTo = 15; // minutes
      const roundDownTime = roundTo * 60 * 1000;
      return down ? time - (time % roundDownTime) : time + (roundDownTime - (time % roundDownTime));
    },
    formatDate(date = undefined) {
      return this.$moment(date);
    },
    getEventColor(event) {
      return event.color;
    },
    closeEventModal() {
      this.isOpenSlotModal = false;
      this.cleanSlotInformation();
      if (this.appointmentInformation?.id) this.getAppointmentInformation(this.appointmentInformation.id);
    },
    onMoreClick(event) {
      this.moreSlots = this.events.filter((slot) => {
        const eventTime = this.$moment(event.date).format("YYYY-MM-DD");
        const slotTime = this.$moment(slot.start).format("YYYY-MM-DD");
        return eventTime === slotTime;
      });
      this.eventMoreDialog = true;
      this.eventMoreDate = event.date;
    },
    scrollToTime() {
      const time = this.getCurrentTime();
      const first = Math.max(0, time - (time % 30) - 30);
      if (this.cal) {
        this.cal.scrollToTime(first);
      }
    },
    getCurrentTime() {
      return this.cal ? this.cal.times.now.hour * 60 + this.cal.times.now.minute : 0;
    },
    updateTime() {
      this.updateTimeInterval = setInterval(() => {
        if (this.cal) this.cal.updateTimes();
      }, 60 * 1000);
    },
    translateChiefComplaint(val) {
      return this.$t("general.chiefComplaints")[val] || val || "-";
    },
    needConfirmBy(participants) {
      const tentative = participants.find((participant) => participant.status === "tentative");
      if (!tentative?.actor) return "";
      return tentative.actor.reference.split("/")[0].toLowerCase();
    },
    async patientEmail(patientId) {
      const patient = await PatientsApi.patientGetProfile(patientId);
      return patient.email;
    },
    async getScheduledAppointments() {
      this.loading = true;
      const filters = {
        practitionerId: this.uid,
      };
      const currentDateInst = this.$moment(this.currentDate);
      if (this.calendarValue === "week") {
        filters.startDate = currentDateInst.startOf("week").format(timeISO8601OFormat);
        filters.endDate = currentDateInst.endOf("week").format(timeISO8601OFormat);
      }
      if (this.calendarValue === "day") {
        filters.date = currentDateInst.format(timeISO8601OFormat);
      }
      const appointments = await AppointmentsApi.getAppointmentByStatus(filters);
      this.events = (appointments?.results || []).map((event) => {
        // here are converted to timeZone, future manipulation doesn't require conversion
        return {
          ...event,
          start: this.$moment(event.start).tz(this.timeZone).format("YYYY-MM-DD HH:mm"),
          end: this.$moment(event.end).tz(this.timeZone).format("YYYY-MM-DD HH:mm"),
        };
      });
      this.loading = false;
    },
  },
  beforeDestroy() {
    clearInterval(this.updateTimeInterval);
  },
  async mounted() {
    await this.preloadVisitSettings();
    this.calendarValue = this.$route.query.calendarValue || "day";
    this.currentDate = this.$route.query.currentDate
      ? this.$moment.unix(this.$route.query.currentDate).toDate()
      : new Date();
    try {
      this.loading = true;
      await this.getAllEncounters();
      await this.getPractitionerAvailability();
      await this.getAllByPractitionerId();
      await this.getScheduledAppointments();
      this.ready = true;
      this.scrollToTime();
      this.updateTime();
      this.$refs.calendar && this.$refs.calendar.checkChange();
    } catch (err) {
      console.error(err);
    }
    this.loading = false;
  },
};
</script>
<style lang="scss" scoped>
:deep .v-calendar-daily_head-day-label {
  .v-btn {
    width: 45px !important;
    height: 45px !important;
  }

  @include tablet {
    .v-btn {
      width: 35px !important;
      height: 35px !important;
    }
  }
  @include mobile {
    .v-btn {
      width: 25px !important;
      height: 25px !important;
    }
  }
}

$cancelledColor: rgba(229, 57, 53, 0.5);
$cancelledBorder: rgba(229, 57, 53, 1);
.calendar {
  padding: 0;
  //margin: 25px 20px;
  box-shadow: none !important;
  border: 1px solid #e7e8f2;
  border-radius: 0;

  &_header {
    padding: 10px 10px;
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
  }
}

.v-future .v-current-time {
  display: none;
}

.v-past .v-current-time {
  background: repeating-linear-gradient(to right, #ea4335, #ea4335 8px, transparent 8px, transparent 15px);
}

.v-present .v-current-time::before {
  content: "";
  position: absolute;
  background-color: #ea4335;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  margin-top: -5px;
  margin-left: -6.5px;
}

.v-present .v-calendar-category__column:not(:first-child) .v-current-time::before {
  display: none;
}

.v-current-time {
  height: 2px;
  background-color: #ea4335;
  position: absolute;
  left: -1px;
  right: 0;
  pointer-events: none;
}

.onGoing {
  span,
  p {
    color: #fff !important;
  }

  .dot {
    background: #fff !important;
  }
}

.single_doctor.scheduled .text-dark {
  color: #33343e;
}

.one_doctor_upcoming_info {
  width: 40%;
}

.full-height-mobile {
  @include mobile {
    height: inherit !important;
  }
}

.one_doctor {
  width: 60%;
  @include mobile {
    height: inherit !important;
  }
}

.up_next--divider {
  border-bottom: 2px solid #33a3ff;
}

.single_doctor.scheduled {
  background: rgba(30, 133, 217, 0.1);
  border-left: 2px solid #2196f3;
}

.single_doctor.pending {
  background: #f6f6f6;
  border-left: 2px solid #f2994a;
}

.single_doctor.pending span,
.single_doctor.pending .status {
  color: #f2994a;
}

.single_doctor.pending .status .dot {
  width: 4px;
  height: 4px;
  background: #f2994a;
  border-radius: 50%;
  margin-right: 6px;
}

.single_doctor .text-dark {
  color: #33343e;
}

.single_doctor {
  &.scheduled {
  }
}

.single_doctor.scheduled span,
.single_doctor.scheduled .status {
  color: #2196f3;
}

.single_doctor.scheduled .status .dot {
  width: 4px;
  height: 4px;
  background: #2196f3;
  border-radius: 50%;
  margin-right: 6px;
}

:deep .single_doctor {
  padding-left: 24px;
  padding-right: 24px;

  &.cancelled {
    border-left: 2px solid $cancelledBorder !important;
    background: #fcfcfd;
    text-decoration-line: line-through;
    color: $cancelledColor;

    span {
      text-decoration-line: line-through;
      color: rgba(229, 57, 53, 0.5);
    }

    .status {
      color: rgba(229, 57, 53, 0.5);

      .dot {
        width: 4px;
        height: 4px;
        border-radius: 50%;
        margin-right: 6px;
        background: rgba(229, 57, 53, 0.5);
      }
    }
  }
}

.single_doctor.scheduled > span {
  border-right: 2px solid #ffffff;
}

.more__list__item {
  &:hover {
    transition: 0.3s ease;
    background: #f6f6f6;
  }

  .dot {
    width: 8px;
    height: 8px;
    background: red;
  }
}

.calendar-content {
  display: flex;

  :deep .week-day-slot {
    background: linear-gradient(0deg, rgba(242, 153, 74, 0.1), rgba(242, 153, 74, 0.1)), #ffffff;
    border-bottom: 1px solid #1e85d9;
    padding: 0 20px;

    span {
      color: #2196f3;
      font-size: 11px;
    }

    &.cancelled {
      border-bottom: 1px solid $cancelledBorder;
      color: $cancelledColor;
      text-decoration-line: line-through;

      span {
        text-decoration-line: line-through;
        color: $cancelledColor;
      }
    }
  }
}

.v-calendar-daily,
.v-calendar-monthly {
  border-left: 1px solid #e7e8f2 !important;
  border-top: 1px solid #e7e8f2 !important;

  .v-calendar-daily_head-day {
    .doctors-schedule__title {
      color: #33343e;
      opacity: 0.5;
    }

    background: linear-gradient(0deg, rgba(231, 232, 242, 0.21), rgba(231, 232, 242, 0.21)), #ffffff;
    border-right: 1px solid #e7e8f2 !important;
    border-bottom: 1px solid #e7e8f2 !important;
  }

  .v-calendar-daily__intervals-head {
    background: linear-gradient(0deg, rgba(231, 232, 242, 0.21), rgba(231, 232, 242, 0.21)), #ffffff;
    border-right: 1px solid #e7e8f2 !important;
    position: relative;

    &:before {
      position: absolute;
      top: 50%;
      transform: translate(5px, -50%);
      left: 0;
      right: 0;
      content: "";
      background: url('data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="24" height="24" viewBox="0 0 24 24"> <path fill="gray" d="M12 20C16.4 20 20 16.4 20 12S16.4 4 12 4 4 7.6 4 12 7.6 20 12 20M12 2C17.5 2 22 6.5 22 12S17.5 22 12 22C6.5 22 2 17.5 2 12C2 6.5 6.5 2 12 2M15.3 16.2L14 17L11 11.8V7H12.5V11.4L15.3 16.2Z" /> </svg>')
        no-repeat;
      display: block;
      width: 24px;
      height: 24px;
      margin: auto;
    }

    &:after {
      background: linear-gradient(90deg, transparent, #e7e8f2) !important;
    }
  }

  .v-calendar-daily__intervals-body {
    border-right: 1px solid #e7e8f2 !important;
  }

  .v-calendar-daily__day-interval {
    border-top: 1px solid #e7e8f2 !important;
  }

  .v-calendar-daily__day,
  .v-calendar-weekly__day {
    border-right: 1px solid #e7e8f2 !important;
    border-bottom: 1px solid #e7e8f2 !important;
  }

  .v-calendar-daily__head {
    margin: 0;
  }

  .v-calendar-daily_head-weekday {
    display: none;
  }
}

.calendar {
  :deep .v-calendar-weekly {
    .v-calendar-weekly__day {
      margin-right: 0 !important;
    }
  }
}

.v-calendar {
  .v-event-timed {
    overflow: hidden !important;
  }
}
</style>
