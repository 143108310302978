<template>
  <v-data-table
    v-if="bodyTemperature.length"
    :class="`table fixed data-table data-table__green-border table-bordered `"
    :headers="headers"
    :items="historyItems"
    mobile-breakpoint="0"
  >
    <template v-if="!disableEdit" v-slot:item.action="{ item }">
      <div class="table_actions" @click="onEdit(item)">Edit</div>
    </template>
    <template v-slot:item.performer="{ item }">
      <span v-for="performer in item.performer" :key="performer.id"
        >{{ performer.firstName }} {{ performer.lastName }}</span
      >
    </template>
    <template v-slot:item.value="{ item }">
      <div v-if="item.value" :class="`${isDanger(item.value, order.normalLow, order.normalHigh) && 'danger'} mark-row`">
        <span class="text--accent-1">{{ item.value }}</span>
        <span v-if="Object.keys(order).length" class="text--secondary"
          >({{ order.normalLow }}-{{ order.normalHigh }})</span
        >
        <v-icon v-if="item.value > order.normalHigh"> mdi-arrow-up-thin</v-icon>
        <v-icon v-if="item.value < order.normalLow"> mdi-arrow-down-thin</v-icon>
      </div>
    </template>
    <template v-slot:item.name="{ item }">
      <div class="table_actions">
        {{ item.name }}
      </div>
    </template>
  </v-data-table>
</template>

<script>
import inRange from "lodash/inRange";
import { mapActions, mapState } from "pinia";

import { timeDisplayFormat } from "@/components/Clinicians/constants";
import { useAuthStore } from "@/pinia-store/auth";
import { useObservationsStore } from "@/pinia-store/observations";

export default {
  name: "BodyTemperatureTable",
  props: {
    disableEdit: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState(useObservationsStore, { order: "bodyTemperatureOrder" }),
    ...mapState(useObservationsStore, ["bodyTemperature"]),
    ...mapState(useAuthStore, ["uid"]),
    title() {
      return "Body Temperature";
    },
    historyItems() {
      return this.bodyTemperature.map((it) => ({
        ...it,
        datetime: `${this.$moment(it.date).format(timeDisplayFormat)} ${it.time}`,
      }));
    },
    headers() {
      return [
        {
          text: "Body Temperature(F)",
          value: "value",
          width: "200px",
        },
        {
          text: "Last update",
          value: "datetime",
          width: "175px",
          separator: ",",
        },
        {
          text: "Updated by",
          value: "performer",
          width: "150px",
          separator: ",",
        },
        {
          text: "Context",
          value: "note",
        },
        { text: "", value: "action", width: "100px" },
      ];
    },
  },
  methods: {
    ...mapActions(useObservationsStore, ["getBodyTemperature"]),
    ...mapActions(useObservationsStore, [
      "setBodyTemperatureOrder",
      "clearEditForm",
      "changeEditFormField",
      "setPatientId",
      "clearBodyTemperatureForm",
      "fillBodyTemperatureForm",
      "toggleBodyTemperatureFormOpened",
    ]),
    isDanger(value, low, high) {
      if (!Object.keys(this.order).length) return false;
      return !inRange(value, low - 0.1, high + 0.1);
    },
    async reload() {
      this.loading = true;
      await this.getBodyTemperature();
      this.loading = false;
    },
    onCreate() {
      this.clearBodyTemperatureForm();
      this.toggleBodyTemperatureFormOpened();
    },
    async onEdit(item) {
      await this.fillBodyTemperatureForm(item);
      this.toggleBodyTemperatureFormOpened();
    },
  },
};
</script>

<style scoped></style>
