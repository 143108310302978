<template>
  <v-container class="contact-info" fluid>
    <v-row>
      <v-col class="item" cols="6" md="5" sm="12">
        <div class="label">
          <span>{{ patientFullName }}</span>
        </div>
        <slot name="first-row"></slot>
      </v-col>
      <v-col class="item" cols="6" md="3" sm="12">
        <div class="label">
          <img :src="require('@/assets/icons/gender.svg')" alt="Gender" />
          <span>{{ _gender }}</span>
        </div>
        <div class="label">
          <img :src="require('@/assets/icons/location.svg')" alt="Location" />
          <span>{{ location }}</span>
        </div>
      </v-col>
      <v-col class="item" cols="6" md="4" sm="12">
        <div class="label">
          <img :src="require('@/assets/icons/phone.svg')" alt="Home phone number" />
          <span>{{ patient.homePhoneNumber || "" }}</span>
        </div>
        <div class="label">
          <img :src="require('@/assets/icons/postcode.svg')" alt="Zip Code" />
          <span>{{ patient.postalCode || "" }}</span>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { upperFirst } from "lodash";
import { mapState } from "pinia";

import { usePatientStore } from "@/pinia-store/patient";

export default {
  name: "PatientSummaryContactInfoMinimal",
  props: {
    item: [Object],
    minimalInfo: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState(usePatientStore, ["patient"]),
    patientFullName() {
      return `${this.patient?.firstName || ""} ${this.patient?.lastName || ""}`;
    },
    _gender() {
      return upperFirst(this.item.gender);
    },
    location() {
      return `${this.item.state || ""}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.contact-info {
  padding: 20px;

  .item {
    display: flex;
    flex-direction: column;
    gap: 16px;

    .label {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      display: flex;
      align-items: center;
      color: #33343e;

      img {
        width: 16px;
        margin-right: 8px;
      }
    }
  }
}
</style>
