<template>
  <Layout :navTabs="navTabs" :quickActions="quickActions" role="admin">
    <router-view></router-view>
  </Layout>
</template>

<script>
import { mapActions, mapState } from "pinia";

import Layout from "@/components/Layout";
import { useAdminStore } from "@/pinia-store/admin";
import { useProfileSettingsStore } from "@/pinia-store/profileSettings";
import { layoutAdminNavTabs, layoutAdminQuickActions } from "@/router/utils";

export default {
  name: "adminView",
  components: {
    Layout,
  },
  computed: {
    ...mapState(useProfileSettingsStore, ["adminSettings", "avatar"]),
    navTabs() {
      return layoutAdminNavTabs(this.$i18n);
    },
    quickActions() {
      return layoutAdminQuickActions(this.$i18n);
    },
  },
  methods: {
    ...mapActions(useProfileSettingsStore, ["getAdminSettings"]),
    ...mapActions(useAdminStore, ["getAllPractitioners"]),
  },
  async mounted() {
    await this.getAdminSettings();
    if (this.adminSettings.locale) {
      this.$root.$i18n.locale = this.adminSettings.locale;
    }
  },
};
</script>
