export const observationTypes: { kind: string; display: string; unit: string }[] = [
  {
    kind: "bloodPressure",
    display: "Blood pressure",
    unit: "mg/dl",
  },
  {
    kind: "heartRate",
    display: "Heart rate",
    unit: "beats/minute",
  },
  {
    kind: "respiratoryRate",
    display: "Respiratory rate",
    unit: "beats/minute",
  },
  {
    kind: "bodyTemperature",
    display: "Temperature",
    unit: "Fah",
  },
  {
    kind: "oxygenSaturation",
    display: "Oxygen Saturation (SpO2)",
    unit: "%",
  },

  {
    kind: "bloodGlucose",
    display: "Blood Glucose",
    unit: "mg/dL",
  },
];
export const observationTypesFull: { kind: string; display: string; unit: string }[] = [
  ...observationTypes,
  {
    kind: "bloodGlucoseAM",
    display: "Blood Glucose AM",
    unit: "mg/dL",
  },
  {
    kind: "bloodGlucoseBM",
    display: "Blood Glucose BM",
    unit: "mg/dL",
  },
];
export enum observationsEnum {
  bloodPressure = "bloodPressure",
  heartRate = "heartRate",
  respiratoryRate = "respiratoryRate",
  bodyTemperature = "bodyTemperature",
  oxygenSaturation = "oxygenSaturation",
  bloodGlucose = "bloodGlucose",
  bloodGlucoseAM = "bloodGlucoseAM",
}

export const getObservationNameByKind = (kind: string): string => {
  return observationTypesFull.find((i) => i.kind === kind)?.display || " ";
};
