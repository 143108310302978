<template>
  <div>
    <v-autocomplete
      v-model="allergyName"
      :items="allergyResults"
      :label="$t('settings.enterAllergies')"
      :loading="loading"
      :placeholder="$t('settings.enterAllergies')"
      :search-input.sync="allergySearch"
      cache-items
      class="bd-grey patient__allergy-search"
      clearable
      fieldName="name"
      hide-details
      hide-no-data
      solo
      @change="onSubmit"
    ></v-autocomplete>
  </div>
</template>
<script>
import { mapActions, mapState } from "pinia";
import { numeric, required } from "vuelidate/lib/validators";

import { AllergiesApi } from "@/api/allerigies";
import { useAllergiesStore } from "@/pinia-store/allergies";
import { useAuthStore } from "@/pinia-store/auth";
import { useAutoSaveStore } from "@/pinia-store/autoSave";

export default {
  name: "PatientAllergyEdit",
  props: ["patientId"],

  data: () => ({
    allergyResults: [],
    allergies: [],
    allergySearch: null,
    allergyName: "",
    loading: false,
    id: "",
    date: new Date(),
    name: "",
  }),
  validations: {
    name: {
      required,
      numeric,
    },
    date: {
      required,
    },
  },
  computed: {
    ...mapState(useAuthStore, ["uid"]),
  },
  watch: {
    editItem(newValue) {
      if (newValue) {
        this.id = newValue?.id;
        this.name = newValue?.name?.toString() || "";
        this.date = new Date(newValue?.date);
      }
    },
    allergySearch(val) {
      if (!val || val === this.allergyName || val?.length < 3) return;
      clearTimeout(this._timerId);
      this._timerId = setTimeout(() => {
        this.loading = true;
        AllergiesApi.search(val).then((values) => {
          this.loading = false;
          this.allergies = [...values, { name: val }];
          this.allergyResults = [val, ...(values.map((value) => value.name) || [])];
        });
      }, 300);
    },
  },
  methods: {
    ...mapActions(useAutoSaveStore, ["startSave", "endSave"]),
    ...mapActions(useAllergiesStore, ["addPatientAllergy"]),
    onFieldChange(value, fieldName) {
      this[fieldName] = value;
    },
    async onSubmit(value) {
      let item = this.allergies.find((al) => al.name === value);
      this.startSave();
      this.allergyName = "";
      await this.addPatientAllergy(item);
      this.endSave();
    },
  },
};
</script>
<style lang="scss">
.patient__allergy-search {
  .v-input__slot {
    border: 1px solid #eeeff7;
    box-shadow: none !important;
  }
}

.media-rename {
  .card-title {
    font-weight: 500 !important;
    font-size: 25px !important;
    line-height: 31px !important;
    color: #25233a;
  }

  .v-card__title + .v-card__text {
    font-size: 14px;
    line-height: 17px;
    color: #33343d;
  }
}
</style>
