<template>
  <v-dialog :value="dialog" width="500" @click:outside="closeDialog">
    <v-card>
      <div class="dialog__head px-7 pt-7 d-flex justify-space-between align-center">
        <v-card-title class="dialog__head__title">Delete availability</v-card-title>
        <v-icon @click="closeDialog" size="medium" class="pa-2">mdi-close</v-icon>
      </div>
      <v-card-actions class="justify-end pr-7 py-5">
        <OutlinedButton
          :text="$t('general.dialog.cancel')"
          additional-class-name="pa-5"
          color="danger"
          @onClick="closeDialog"
        />
        <OutlinedButton
          :text="$t('general.buttons.delete')"
          additional-class-name="pa-5"
          @onClick="$emit('onConfirm')"
          style="color: #e53935; border: 1px solid #e53935; opacity: 1"
        />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import OutlinedButton from "@/components/uikit/OutlinedButton";

export default {
  name: "Delete",
  props: ["dialog", "closeDialog"],
  components: {
    OutlinedButton,
  },
  data: () => ({
    radioGroup: "1",
  }),
};
</script>
