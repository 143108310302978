<template>
  <v-row class="pharmacy-field ma-1 mb-2">
    <v-col cols="9">
      <template v-if="pharmacyIsEmpty">
        <p class="text-h6">Add Pharmacy</p>
        <p class="text--secondary pb-0">
          Pharmacy not selected yet, please add Pharmacy, it is necessary to make an order
        </p>
      </template>
      <template v-else>
        <p v-if="pharmacy.name" class="text-h6 mb-0">{{ pharmacy.name }}</p>
        <p v-if="pharmacy.address" class="text--secondary mb-0">{{ pharmacy.address }}</p>
        <p v-if="pharmacy.city" class="text--secondary mb-0">
          {{ `${pharmacy.city || ""} ${pharmacy.state || ""} ${pharmacy.zipCode || ""}` }}
        </p>
        <p v-if="pharmacy.phone || pharmacy.fax" class="text--secondary mb-0 gap-1">
          <span v-if="pharmacy.phone">{{ `Tel: ${pharmacy.phone} ` }}</span>
          <span v-if="pharmacy.fax">{{ `Fax: ${pharmacy.fax}` }}</span>
        </p>
      </template>
    </v-col>
    <v-col class="justify-end d-flex align-self-baseline" cols="3">
      <IconicButton :text="pharmacyIsEmpty ? 'Add' : 'Change'" @onClick="toggleDialog" />
    </v-col>
    <PharmacySelectTextFree :opened="addPharmacyModal" @onClose="toggleDialog" @onSelect="onSelectPharmacyFreeText" />
  </v-row>
</template>
<script>
import debounce from "lodash/debounce";
import { mapActions, mapState } from "pinia";

import { PharmacyApi } from "@/api/pharmacy";
import IconicButton from "@/components/uikit/IconicButton";
import PharmacySelectTextFree from "@/components/VisitNotes/PharmacySelectTextFree.vue";
import { useAutoSaveStore } from "@/pinia-store/autoSave";
import { useTerminologyStore } from "@/pinia-store/terminology";
import { useVisitNoteStore } from "@/pinia-store/visitNote";
import { STATES } from "@/utils/states";

export default {
  name: "PharmacyField",
  components: {
    PharmacySelectTextFree,
    IconicButton,
  },
  data: () => ({
    states: STATES,
    loading: false,
    addPharmacyModal: false,
    medicalTab: 0,
    pharmacyName: "",
    isLoadingPharmacy: false,
    advanceSearchMenu: false,
    filter: {
      specialty: "",
    },
    searchPharmacyResults: [],
    pharmacySearch: "",
    pharmacyId: "",
  }),
  methods: {
    ...mapActions(useVisitNoteStore, ["getPharmacy"]),
    ...mapActions(useTerminologyStore, ["preloadPharmacySpecialties"]),
    ...mapActions(useAutoSaveStore, ["startSave", "endSave"]),
    toggleDialog() {
      this.addPharmacyModal = !this.addPharmacyModal;
    },
    async onSelectPharmacyFreeText(pharmacy) {
      this.toggleDialog();
      if (pharmacy) {
        await this.startSave();
        try {
          await PharmacyApi.addToEncounterFreeText(this.encounter.id, { ...pharmacy });
        } catch (err) {
          console.error(err);
        }
        await this.endSave();
        await this.getPharmacy();
      }
    },
    async onSelectPharmacy(pharmacy) {
      this.toggleDialog();
      if (pharmacy) {
        await this.startSave();
        try {
          await PharmacyApi.addToEncounter(this.encounter.id, { pharmacyId: pharmacy.id });
        } catch (err) {
          console.error(err);
        }
        await this.endSave();
        await this.getPharmacy();
      }
    },
    async onSelectPharmacyTextFree(pharmacy) {
      this.toggleDialog();
      if (pharmacy) {
        await this.startSave();
        try {
          await PharmacyApi.addToEncounter(this.encounter.id, { pharmacyId: pharmacy.id });
        } catch (err) {
          console.error(err);
        }
        await this.endSave();
        await this.getPharmacy();
      }
    },
    performSearch() {
      this.advanceSearchMenu = false;
      PharmacyApi.search({
        name: this.pharmacyName,
        ...this.filter,
        specialty: this.filter.specialty ? [parseInt(this.filter.specialty)] : [],
      }).then((values) => {
        this.isLoadingPharmacy = false;
        this.searchPharmacyResults = [...values];
      });
    },
  },
  computed: {
    ...mapActions(useTerminologyStore, ["pharmacySpecialties"]),
    ...mapState(useVisitNoteStore, ["otherInfo", "pharmacy", "encounter"]),

    pharmacyIsEmpty() {
      return !this.pharmacy || Object.keys(this.pharmacy).length === 0;
    },
  },
  watch: {
    pharmacySearch: debounce(function (val) {
      if (!val || val === this.pharmacyName || val.length < 3) return;
      this.isLoadingPharmacy = true;
      this.pharmacyName = val;
      this.performSearch();
    }),
    "$store.state.visitNote.pharmacy": {
      handler(val) {
        if (val?.id) {
          this.pharmacyName = val.id;
          this.searchPharmacyResults = [{ ...val }];
        }
      },
    },
    "$store.state.visitNote.encounter.id": {
      immediate: true,
      handler(val) {
        if (val) {
          this.getPharmacy();
        }
      },
    },
  },
  async mounted() {
    await this.preloadPharmacySpecialties();
  },
};
</script>
<style lang="scss" scoped>
.pharmacy-field {
  border: 1px solid #eeeff7;
  border-radius: 4px;

  .col {
    align-items: center;
  }

  &-btn {
    box-shadow: none !important;
  }

  .searchWrap {
    display: flex;
    align-items: center;
    gap: 10px;
  }
}
</style>
