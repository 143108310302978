import Conversation from "@/components/Chat/ChatBotLog/Conversation.vue";
import ConversationLogsTable from "@/components/Chat/ChatBotLog/ConversationLogsTable.vue";
import ScheduleView from "@/components/Checkout/OwnerScheduleVisit/index.vue";
import { routesEnum } from "@/types/Routes.enum";
import MiddleComponent from "@/views/MiddleComponent.vue";
import EditAvailability from "@/views/Owner/Appoinments/EditAvailability.vue";
import ScheduleAppointment from "@/views/Owner/Appoinments/Schedule.vue";
import ScheduledAppointments from "@/views/Owner/Appoinments/ScheduledAppointments.vue";
import AppointmentsView from "@/views/Owner/Appoinments/View.vue";
import Clinician from "@/views/Owner/Clinician.vue";
import FreeText from "@/views/Owner/FreeText/Index.vue";
import Patients from "@/views/Owner/Patients.vue";
import OwnerSettings from "@/views/Owner/Settings.vue";
import OwnerView from "@/views/Owner/View.vue";
import Schedule from "@/views/Owner/Visits/Request.vue";
import VisitNotes from "@/views/Owner/Visits/VisitDetails.vue";

import {
  appointmentsBreadcrumbs,
  chatBreadcrumbs,
  clinicianListBreadcrumbs,
  conversationLogs,
  conversationsBot,
  EditAvailabilityBreadcrumbs,
  freeTextBreadcrumbs,
  ifAuthenticated,
  ownerScheduleVisitBreadcrumbs,
  patientListBreadcrumbs,
  practitionerScheduleVisitBreadcrumbs,
  profileSettingsBreadcrumbs,
  scheduledAppointmentsBreadcrumbs,
} from "./utils";

const OwnerRoutes = [
  {
    path: "/owner",
    component: OwnerView,
    meta: {
      authorize: ["owner"],
    },
    redirect: "/owner/appointments",
    beforeEnter: ifAuthenticated,
    children: [
      {
        path: "chat",
        name: routesEnum.ownerChat,
        meta: { breadcrumbs: chatBreadcrumbs },
        component: (): unknown => import("@/views/Owner/Chat/Index.vue"),
      },
      {
        path: "settings",
        name: routesEnum.ownerSettings,
        component: OwnerSettings,
        meta: { breadcrumbs: profileSettingsBreadcrumbs },
      },
      {
        path: "free-text",
        name: routesEnum.freeText,
        component: FreeText,
        meta: { breadcrumbs: freeTextBreadcrumbs },
      },
      {
        path: "patients",
        name: routesEnum.ownerPatients,
        component: Patients,
        meta: { breadcrumbs: patientListBreadcrumbs },
      },
      {
        path: "clinicians",
        name: routesEnum.ownerClinicians,
        component: Clinician,
        meta: { breadcrumbs: clinicianListBreadcrumbs },
      },
      {
        path: "conversation",
        name: routesEnum.conversationLogs,
        component: ConversationLogsTable,
        meta: { breadcrumbs: conversationLogs },
      },
      {
        path: "conversation/:id",
        name: routesEnum.chatBot,
        meta: { breadcrumbs: conversationsBot },
        component: Conversation,
      },
      {
        path: "appointments",
        component: AppointmentsView,
        children: [
          {
            path: "/",
            name: routesEnum.ownerSchedule,
            component: ScheduleAppointment,
            meta: { breadcrumbs: appointmentsBreadcrumbs, layout: "default" },
          },
          {
            path: "/owner/appointments/scheduled",
            name: routesEnum.ownerScheduledAppointments,
            component: ScheduledAppointments,
            meta: { breadcrumbs: scheduledAppointmentsBreadcrumbs, layout: "default" },
          },
          {
            path: "edit",
            name: routesEnum.ownerEditAvailability,
            component: EditAvailability,
            meta: { breadcrumbs: EditAvailabilityBreadcrumbs, layout: "edit" },
          },
        ],
      },
      {
        path: "makeSchedule",
        name: routesEnum.ownerScheduleVisit,
        component: ScheduleView,
        meta: { breadcrumbs: practitionerScheduleVisitBreadcrumbs },
      },
      {
        path: "encounters",
        component: MiddleComponent,
        children: [
          {
            path: "",
            component: MiddleComponent,
            redirect: "/owner/appointments",
          },
          {
            path: "schedule",
            name: routesEnum.ownerEncounterSchedule,
            component: Schedule,
            meta: { breadcrumbs: ownerScheduleVisitBreadcrumbs },
          },
          {
            name: routesEnum.ownerEncounter,
            path: ":encounterId",
            component: VisitNotes,
            meta: { collapseSidebar: true },
          },
        ],
      },
      {
        name: routesEnum.ownerPending,
        path: "pending/:slotId",
        component: VisitNotes,
        meta: { collapseSidebar: true },
      },
    ],
  },
];
export default OwnerRoutes;
