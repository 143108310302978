<template>
  <div class="medication-statement-root">
    <div :class="`medication-statement `">
      <div class="source">
        <img v-if="item.informationSource === 'practitioner'" :src="require('@/assets/icons/careIcon.svg')" alt="" />
        <img v-else :src="require('@/assets/icons/patientRelated.svg')" alt="" />
      </div>
      <MedicationDetails :item="item" />
      <div class="action">
        <div v-if="item.status" class="medication-status">
          <v-btn-toggle>
            <v-btn
              :class="`status-option ${item.status === 'active' && !isEditingStatus && 'status-active'}`"
              height="100%"
              value="active"
              @click="onChangeStatus('active')"
            >
              Active
            </v-btn>
            <v-btn
              :class="`status-option ${(item.status === 'differently' || isEditingStatus) && 'status-active'}`"
              class="status-option"
              height="100%"
              value="differently"
              @click="onEdit"
            >
              Differently
            </v-btn>
            <v-btn
              :class="`status-option ${item.status === 'not-taken' && !isEditingStatus && 'status-active'}`"
              class="status-option"
              height="100%"
              value="not-taken"
              @click="onChangeStatus('not-taken')"
            >
              Not taking
            </v-btn>
          </v-btn-toggle>
        </div>
        <div v-if="isEditingStatus" class="edit-wrap">
          <Textarea
            :value="additionalInstruction"
            fieldName="additionalInstruction"
            placeholder="Enter additional instructions"
            @change="onFieldChange"
          />
          <div class="edit-actions">
            <v-btn color="primary" depressed @click="onSaveEditDifferently"> Save</v-btn>
            <v-btn color="primaryLight1" depressed @click="onCancel"> Cancel</v-btn>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment-timezone";
import { mapActions, mapState } from "pinia";

import { MedicationApi } from "@/api/medication";
import Textarea from "@/components/uikit/Textarea";
import MedicationDetails from "@/components/VisitNotes/medicationReconsiliation/MedicationDetails";
import { useAutoSaveStore } from "@/pinia-store/autoSave";
import { useTerminologyStore } from "@/pinia-store/terminology";
import { useVisitNoteStore } from "@/pinia-store/visitNote";

export default {
  name: "MedicationField",
  components: { Textarea, MedicationDetails },

  props: {
    isEditing: {
      type: Boolean,
      default: () => false,
    },
    withStatus: {
      type: Boolean,
      default: () => false,
    },
    withEdit: {
      type: Boolean,
      default: () => false,
    },
    item: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      isEditingStatus: false,
      additionalInstruction: this.item.additionalInstruction,
    };
  },
  computed: {
    ...mapActions(useTerminologyStore, ["practitionerFavMedication"]),
    ...mapState(useVisitNoteStore, ["patientInfo", "encounter"]),
    date() {
      let date = moment(this.item?.startDate);
      return date.isValid() ? date.format("MMM DD, YYYY") : undefined;
    },
  },
  methods: {
    ...mapActions(useVisitNoteStore, ["getMedicationStatement"]),
    ...mapActions(useAutoSaveStore, ["startSave", "endSave"]),
    async onChangeStatus(status) {
      this.isEditingStatus = false;
      if (this.item.status === status) return;
      this.startSave();
      await MedicationApi.patch(this.item.id, {
        ...this.item,
        status,
        informationSource: "patient",
        quantity: this.item.quantity || "1",
        context: "encounter",
        contextId: this.encounter.id,
        informationSourceId: this.patientInfo.id,
      });
      await this.getMedicationStatement();
      this.endSave();
    },
    async onSaveEditDifferently(status) {
      this.startSave();
      await MedicationApi.patch(this.item.id, {
        ...this.item,
        informationSource: "patient",
        context: "encounter",
        contextId: this.encounter.id,
        quantity: this.item.quantity || "1",
        informationSourceId: this.patientInfo.id,
        additionalInstruction: this.additionalInstruction,
        status: "differently",
      });
      this.isEditingStatus = false;
      await this.getMedicationStatement();
      this.endSave();
    },
    onEdit(e) {
      e.preventDefault();
      e.stopPropagation();
      this.isEditingStatus = !this.isEditingStatus;
    },
    onChangeDifferently() {
      this.$emit("onUpdated");
    },
    onFieldChange(value, fieldName) {
      this[fieldName] = value;
    },
    onCancel() {
      this.isEditingStatus = false;
    },
  },
};
</script>

<style lang="scss">
.medication-statement-edit {
  padding: 22px 20px;
}

.medication-statement {
  display: grid;
  grid-template-columns: 40px auto auto;
  grid-gap: 15px 10px;
  width: 100%;
  align-items: start;
  padding: 20px 28px 20px 27px;
  border-bottom: 1px solid var(--v-primarylight2-base);
  @include tablet {
    padding: 20px 5px 20px 5px !important;
  }
  @include desktop {
    display: flex !important;
    flex-wrap: wrap !important;
  }

  &.intended {
    background-color: #f7fdf9;
  }

  &.stopped {
    background-color: rgba(229, 57, 53, 0.08);
  }

  &.on-hold {
    background-color: #e5e5e5;
  }

  .source {
    flex-basis: 20px;
  }

  .action {
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    flex-wrap: wrap;

    .edit-wrap {
      flex-basis: 100%;
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 16px;

      .edit-actions {
        display: flex;
        gap: 16px;
        justify-content: flex-end;
      }
    }

    .edit-btn {
      background-color: $primarylight1 !important;
      color: #2196f3;
    }

    .remove-btn {
      background-color: #fdefef !important;
      color: #e53935;
    }

    .edit {
      display: flex;
      gap: 12px;
      flex-wrap: wrap;
      justify-content: flex-end;

      button {
        text-transform: none;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;

        .v-btn__content {
          padding: 8px 11px;
        }
      }
    }
  }
}

.medication-statement-root {
  .medication-status {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 10px;

    .status-option {
      color: #91909c;
      box-shadow: none;
      border: 1px solid #eeeff7;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      letter-spacing: 0;
      line-height: 17px;
      text-align: center;
      padding-top: 8px;
      padding-bottom: 8px;
      background-color: white !important;
      text-transform: none;

      &.status-active {
        color: #2196f3;
        background: #ffffff;
        border: 1px solid #2196f3 !important;
      }
    }
  }
}
</style>
