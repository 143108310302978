
import * as AdaptiveCards from "adaptivecards";
import Vue from "vue";

import BaseMessage from "@/components/Chat/Message/MessageTypes/BaseMessage.vue";

export default Vue.extend({
  name: "MessageWithAdaptiveCard",
  components: { BaseMessage },
  props: {
    message: {
      type: Object,
      required: true,
    },
    responseDelegate: {
      type: Function,
      required: true,
    },
    isArchived: {
      type: Boolean,
      required: true,
    },
  },
  data: () => ({
    value: [] as string[],
    renderedCard: Object as unknown as HTMLElement,
    adaptiveCards: new AdaptiveCards.AdaptiveCard(),
    anySelected: false,
  }),
  created: function () {
    let choicesInputElements: HTMLInputElement[] | null;
    let choicesLabelElements: HTMLLabelElement[] | null;
    this.adaptiveCards.hostConfig = new AdaptiveCards.HostConfig({
      adaptiveCard: {
        allowCustomStyle: true,
      },
      textBlock: {
        wrap: true,
      },
    });
    this.adaptiveCards.onExecuteAction = (action: AdaptiveCards.Action) => {
      if (action instanceof AdaptiveCards.SubmitAction && action?.data) {
        if (Object.keys(action.data).length === 0) {
          this.responseDelegate(null, { payload: null, botMsgId: this.message.attributes.botMsgId }, this.message.sid);
        } else {
          this.responseDelegate(
            null,
            {
              payload: action.data,
              botMsgId: this.message.attributes.botMsgId,
            },
            this.message.sid,
          );
        }
        if (choicesInputElements && choicesLabelElements) {
          choicesInputElements.forEach((input) => {
            input.setAttribute("disabled", "true");
          });
          choicesLabelElements.forEach((label) => {
            label.setAttribute("disabled", "true");
          });
        }
        if (actionSetButtons.length > 1) {
          actionSetButtons.forEach((button) => {
            button.setAttribute("disabled", "disabled");
            button.style.cursor = "default";
            if ((action.data as unknown as string) === button.getAttribute("aria-label"))
              button.classList.add("clicked");
            if (button.getAttribute("aria-posinset") === "1" || actionSetButtons.length === 1) {
              button.classList.add("last");
            }
          });
        } else {
          const button = actionSetButtons[0];
          button.setAttribute("disabled", "disabled");
          button.style.cursor = "default";
          button.classList.add("clicked");
          button.classList.add("last");
        }
      } else if (action instanceof AdaptiveCards.OpenUrlAction) {
        this.action("updateInformation");
      }
    };

    this.adaptiveCards.parse(this.message.attributes.meta);
    this.renderedCard = this.adaptiveCards.render() as HTMLElement;

    const selectedOption = this.message.attributes?.selectedOption?.payload ?? this.message.attributes?.selectedOption;
    const selectedChoicesArray: any[] = selectedOption?.choices?.split(",");

    const multiSelectElement = this.renderedCard?.querySelector(".ac-choiceSetInput-multiSelect");

    const actionSetElement = this.renderedCard?.querySelector(".ac-actionSet");
    let actionSetButtons: HTMLElement[];

    if (actionSetElement) {
      actionSetButtons = Array.from(actionSetElement.getElementsByTagName("button")) as HTMLElement[];
      if (actionSetButtons.length > 1) {
        actionSetButtons.forEach((button) => {
          if (button.getAttribute("aria-posinset") === "1" || actionSetButtons.length === 1) {
            button.classList.add("last");
          }
          if (this.message.lastUpdatedBy !== null) {
            button.setAttribute("disabled", "disabled");
            button.style.cursor = "default";
            if (this.message.attributes?.selectedOption === button.getAttribute("aria-label")) {
              button.classList.add("clicked");
            }
          }
        });
      } else {
        const button = actionSetButtons[0];
        button.classList.add("last");
        if (this.message.lastUpdatedBy !== null) {
          button.setAttribute("disabled", "disabled");
          button.style.cursor = "default";
          button.classList.add("clicked");
        }
      }
    }

    if (multiSelectElement) {
      choicesInputElements = Array.from(multiSelectElement.getElementsByTagName("input"));
      choicesLabelElements = Array.from(multiSelectElement.getElementsByTagName("label"));

      if (choicesInputElements?.length > 0 && this.message.lastUpdatedBy !== null)
        choicesInputElements.forEach((input) => {
          input.setAttribute("disabled", "true");
          if (selectedChoicesArray?.includes(input.getAttribute("value"))) {
            input.checked = true;
          }
        });
      if (choicesLabelElements?.length > 0 && this.message.lastUpdatedBy !== null)
        choicesLabelElements.forEach((label) => {
          label.setAttribute("disabled", "true");
        });
    }
  },
  methods: {
    action(val: string) {
      this.$emit("action", val);
    },
    setValue(value: string) {
      this.value.push(value);
    },
  },
  mounted() {
    if (this.$refs["cardRoot"] && this.renderedCard) {
      const cardRoot = this.$refs["cardRoot"] as HTMLDivElement;
      cardRoot.appendChild(this.renderedCard);
    }
  },
});
