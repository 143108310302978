import identity from "lodash/identity";
import pickBy from "lodash/pickBy";

import { ActivatePatientProfileInvite, ActivateProfileSignUpDto, Invite } from "@/types";
import { InviteQueryFilterDto } from "@/types/InviteQueryFilter";

import { apiRequest } from "./apiRequest";

interface InviteCodeLogin {
  code: string;
  firstName: string;
  lastName: string;
  birthDate: Date;
  email: string;
}

const login = async (body: InviteCodeLogin): Promise<any> => {
  const { data } = await apiRequest.post("/invite/login", body);
  return data;
};
const generateCodes = async (count: number): Promise<any> => {
  const { data } = await apiRequest.post("/invite/generate-codes", { count });
  return data;
};

const sendInvite = async (invite: Invite) => {
  const { data } = await apiRequest.post("/invite", pickBy(invite, identity));
  return data;
};

const getInvite = async (id: string) => {
  const { data } = await apiRequest.get(`/invite/${id}`);
  return data;
};

const getAll = async (query?: InviteQueryFilterDto) => {
  const { data } = await apiRequest.get("/invite", { params: query });
  return data;
};

const deleteById = async (id: string) => {
  const { data } = await apiRequest.delete(`/invite/${id}`);
  return data;
};

//todo: unused, may need to be removed, `sendInvite` handle create and update
const resendInvite = async (invite: Invite) => {
  const { data } = await apiRequest.post("/invite", invite);
  return data;
};

const sendActivationProfile = async (invite: ActivatePatientProfileInvite) => {
  const { data } = await apiRequest.post("/invite/send-activate-profile", pickBy(invite, identity));
  return data;
};

const activateLoginProfile = async (invite: ActivateProfileSignUpDto) => {
  return new Promise((resolve, reject) => {
    apiRequest
      .post("/invite/login/activate", invite)
      .then((r) => {
        resolve(r.data);
      })
      .catch((err) => {
        reject(err?.response?.data);
      });
  });
};

const getActivationCode = async (invitePin: string) => {
  const { data } = await apiRequest.get(`/invite/activation-code?invitePin=${invitePin}`);
  return data;
};

const importPatients = async (formData: FormData) => {
  return new Promise((resolve, reject) => {
    apiRequest
      .post(`/invite/import-patients`, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((r) => resolve(r.data))
      .catch((err) => reject(err && err.response?.data));
  });
};

export const InvitesApi = {
  generateCodes,
  sendInvite,
  sendActivationProfile,
  activateLoginProfile,
  login,
  getInvite,
  getAll,
  deleteById,
  resendInvite,
  importPatients,
  getActivationCode,
};
