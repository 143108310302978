<template>
  <div class="details">
    <span class="name">{{ item.name }}</span>
    <span v-if="item.quantity" class="quantity">{{ item.quantity || "" }}</span>
    <span v-if="item.refills" class="quantity">{{ item.refills || "" }} Refills</span>
    <span v-if="date" class="quantity">{{ date || "" }}</span>
    <span v-if="item.additionalInstruction" class="quantity">{{ item.additionalInstruction || " " }}</span>
  </div>
</template>

<script>
import moment from "moment-timezone";

export default {
  name: "MedicationDetails",
  props: {
    item: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  computed: {
    date() {
      const date = moment(this.item.startDate);
      return date.isValid() ? date.format("MMM, DD YYYY") : "--";
    },
  },
};
</script>

<style lang="scss" scoped>
.details {
  display: flex;
  flex-direction: column;
  .name {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #33343e;
    opacity: 0.9;
  }
  .quantity {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #25233a;
    mix-blend-mode: normal;
    opacity: 0.5;
  }
}
</style>
