<template>
  <div class="pagination-wrap">
    <div v-if="!hideShowing" class="pagination-info-block">
      <p class="mb-0">
        <span class="sub-heading-3">Showing </span>
        <span class="primary--text font-weight-bold">{{ perPage > total ? total : perPage }}</span>
        <span class="sub-heading-3"> of </span>
        <span class="font-weight-bold">{{ total }}</span>
      </p>
      <v-divider class="patients-cards-footer__middle-divider mx-5" vertical></v-divider>
      <div class="d-flex align-center">
        <p class="sub-heading-3 mb-0 mr-2">Rows per Page:</p>
        <v-select
          :items="_perPageItems"
          :value="perPage"
          append-icon="mdi-chevron-down"
          class="per-page-select"
          dense
          height="32"
          hide-details
          outlined
          @change="onChangePerPage"
        ></v-select>
      </div>
    </div>
    <div class="pagination">
      <v-btn class="nav-btn-text nav-btn" small @click="onFirst">
        <v-icon>mdi-chevron-left</v-icon>
        First
      </v-btn>
      <v-btn class="nav-btn" small @click="onPrev">
        <v-icon>mdi-chevron-left</v-icon>
      </v-btn>
      <span v-if="!hidePageNumber">{{ page }}</span>
      <v-btn class="nav-btn" small @click="onNext">
        <v-icon>mdi-chevron-right</v-icon>
      </v-btn>
    </div>
  </div>
</template>

<script>
export default {
  name: "Pagination",
  props: {
    hidePageNumber: {
      type: Boolean,
      default: false,
    },
    hideShowing: {
      type: Boolean,
      default: false,
    },
    total: {
      type: [Number, String],
      default: () => 1,
    },
    index: {
      type: [Number, String],
      default: () => 1,
    },
    page: {
      type: [Number, String],
      default: () => 1,
    },
    perPage: {
      type: [Number, String],
      default: () => 10,
    },
    perPageItems: {
      type: [Array],
      default: () => [],
    },
  },
  methods: {
    onChangePerPage(value) {
      this.$emit("onPerPageChange", value);
    },
    onFirst() {
      this.$emit("onFirst");

      this.$emit("onPageChange", 1);
    },
    onPrev() {
      this.$emit("onPrev");

      if (this.page > 1) {
        this.$emit("onPageChange", this.page - 1);
      }
    },
    onNext() {
      this.$emit("onNext");

      if (this.page < Math.ceil(this.total / this.perPage)) {
        this.$emit("onPageChange", this.page + 1);
      }
    },
  },
  computed: {
    _perPageItems() {
      return this.perPageItems.length
        ? this.perPageItems
        : [
            {
              text: 5,
              value: 5,
            },
            {
              text: 10,
              value: 10,
            },
            {
              text: 15,
              value: 15,
            },
            {
              text: "All",
              value: this.total,
            },
          ];
    },
  },
};
</script>

<style lang="scss" scoped>
.pagination-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 16px 0;
  gap: 8px;
  flex-wrap: wrap;

  .pagination-info-block {
    display: flex;
    align-items: center;
  }

  :deep .nav-btn {
    background-color: rgba(255, 255, 255, 1) !important;
    width: 32px;
    min-width: inherit;
    border-radius: 4px;
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.12);

    .v-btn__content {
      color: #33343e !important;
    }

    &.nav-btn-text {
      width: 75px;
    }
  }

  :deep .per-page-select {
    background-color: white;
    height: 32px;

    .v-input__append-inner {
      margin-top: 4px !important;
    }

    .v-input__control {
      padding: 0 6px !important;
    }

    .v-input__slot {
      padding: 0 6px !important;
    }

    width: 80px;
    border: 2px solid $primarylight;
    border-radius: 4px;

    fieldset {
      border: none;
    }

    [role="button"] {
      height: 28px !important;
      min-height: inherit !important;
    }
  }
}

.pagination {
  display: flex;
  gap: 8px;
  flex-direction: row;
  align-items: center;

  .count {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    text-align: center;

    color: #91909c;

    .index {
      color: #33343e;
    }
  }
}
</style>
