import identity from "lodash/identity";
import pickBy from "lodash/pickBy";

import { EncounterQueryFilterDto, EncounterQueryPended } from "@/types/EncounterQueryFilter";

import { apiRequest } from "./apiRequest";

const segment = "encounter";
const getAll = async (query?: EncounterQueryFilterDto) => {
  const { data } = await apiRequest.get(segment, { params: pickBy(query, identity) });
  return data;
};
const getPended = async (query?: EncounterQueryPended) => {
  const { data } = await apiRequest.get(`${segment}/pended`, {
    params: { reasonCode: query?.reasonCode, practitionerId: query?.practitionerId },
  });
  return data;
};

const setPended = async (id: string) => {
  const { data } = await apiRequest.put(`${segment}/${id}/pended`);
  return data;
};

const createPastVisitEncounter = async (formData: Record<any, any>) => {
  return new Promise((resolve, reject) => {
    apiRequest
      .post(`/${segment}/past-visit`, pickBy(formData, identity))
      .then((r) => resolve(r.data))
      .catch((err) => reject(err && err.response?.data));
  });
};
const getById = async (id: string) => {
  try {
    const { data } = await apiRequest.get(`/${segment}/${id}`);
    return data;
  } catch (e) {
    return null;
  }
};

const start = async (id: string) => {
  const { data } = await apiRequest.put(`/${segment}/${id}`, { start: true });
  return data;
};

const finish = async (id: string) => {
  const { data } = await apiRequest.put(`/${segment}/${id}`, { finish: true });
  return data;
};

const saveNotes = async (id: string, body: Record<any, any>) => {
  const { data } = await apiRequest.post(`/clinical-impression/encounter/${id}`, body);
  return data;
};

const sendVisitNoteToPatient = async (id: string) => {
  const { data } = await apiRequest.post(`/clinical-impression/encounter/${id}/patient-send-visit-note`);
  return data;
};

const signDocument = async (encounterId: string) => {
  const { data } = await apiRequest.get(`/composition/encounter/${encounterId}/sign`);
  return data;
};

const getNotes = async (id: string) => {
  const { data } = await apiRequest.get(`/clinical-impression/encounter/${id}`);
  return data;
};

const getDocument = async (id: string) => {
  const { data } = await apiRequest.get(`/composition/encounter/${id}/document`);
  return data;
};

const getSummary = async (encounterId: string) => {
  const { data } = await apiRequest.get(`/encounter/${encounterId}/summary`);
  return data;
};

export const EncountersAPI = {
  sendVisitNoteToPatient,
  setPended,
  createPastVisitEncounter,
  getPended,
  getAll,
  getById,
  saveNotes,
  signDocument,
  getNotes,
  getDocument,
  start,
  finish,
  getSummary,
};
