<template>
  <div class="actions">
    <v-btn small depressed class="margin-bottom" fab disabled>
      <v-icon>mdi-printer</v-icon>
    </v-btn>
    <v-btn small depressed class="margin-bottom" fab disabled>§</v-btn>
    <v-btn small depressed fab disabled><v-icon> mdi-send </v-icon></v-btn>
  </div>
</template>
<script>
export default {
  name: "VisitSummaryActions",
};
</script>
<style scoped lang="scss">
.actions {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  left: -56px;
}
.margin-bottom {
  margin-bottom: 10px;
}
</style>
