<template>
  <div class="media-row">
    <v-card class="media-row__container">
      <v-row>
        <v-col class="media-row__title-wrap" cols="12">
          <div class="title">Attachments</div>
          <v-select
            :items="selectValues"
            :value="fileType"
            class="media-row__select"
            dense
            hide-details="true"
            outlined
            placeholder="Day"
            @change="changeFileType($event)"
          ></v-select>
        </v-col>
      </v-row>
      <MediaList :disableInitialFetch="true" />
    </v-card>
  </div>
</template>

<script>
import moment from "moment-timezone";
import { mapActions, mapState } from "pinia";

import MediaList from "@/components/media/MediaList";
import { useMediaStore } from "@/pinia-store/media";

export default {
  name: "FloatingMediaRow",
  props: {
    channelId: {
      default: "",
      type: String,
    },
  },
  components: {
    MediaList,
  },
  data: () => {
    return {
      selectValues: [
        {
          text: "All",
          value: "all",
        },
        {
          text: "Image",
          value: "image",
        },
        {
          text: "Documents",
          value: "document",
        },
      ],
      loading: false,
    };
  },
  methods: {
    ...mapActions(useMediaStore, ["fetchChatFiles", "emptyFiles", "setFileType"]),
    ...mapActions(useMediaStore, ["setUid"]),
    changeFileType(value) {
      this.setFileType(value);
      this.fetchChatFiles(this.channelId);
    },

    createdData(date) {
      return date && moment(date).isValid() ? moment(date).fromNow() : "";
    },
    show(image) {
      this.$viewerApi({
        images: [image],
      });
    },
  },
  computed: {
    ...mapState(useMediaStore, ["files", "fileType"]),
  },
  async mounted() {
    await this.fetchChatFiles(this.channelId);
  },
};
</script>

<style lang="scss">
.media-row {
  border: 1px solid #e7e8f2;
  box-sizing: border-box;
  border-radius: 6px;

  &__select {
    max-width: 115px;
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;
  }

  &__title-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .title {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      color: #25233a;
      opacity: 0.4;
    }

    .link {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      text-align: right;
      color: #2196f3;
      cursor: pointer;
    }
  }

  &__container {
    padding: 15px 20px;
    box-shadow: none !important;
  }
}
</style>
