<template>
  <v-overlay :value="true" absolute>
    <div class="circle">
      <v-layout class="circle-inner" justify-center align-center>
        <v-icon class="circle-inner-icon"> mdi-alert-octagon </v-icon>
        <span class="circle-inner-text">
          {{ $t("visits.errors.connection") }}
        </span>
      </v-layout>
    </div>
  </v-overlay>
</template>

<script>
export default {
  name: "ErrorConnection",
};
</script>

<style scoped lang="scss">
.circle {
  width: 280px;
  height: 85px;
  border-radius: 20px;
  background: rgba(39, 39, 39, 4.26);
  padding: 13px;
  &-inner {
    border-radius: 11px;
    height: 100%;
    background: #b8271c;
    z-index: 1;
    color: $white;
    &-icon {
      margin-right: 5px;
    }
  }
}
</style>
