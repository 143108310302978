<template>
  <div class="full-width fill-height">
    <div class="fill-height full-width d-flex flex-column">
      <div class="header d-flex flex-shrink-0 full-width">
        <div class="header-c1 d-inline-flex align-center fill-height flex-grow-1 flex-shrink-0">
          <div class="d-inline-flex header-body">
            <v-btn v-if="$vuetify.breakpoint.xsOnly && !attachmentsTab" icon @click.stop="$emit('back')">
              <v-icon>mdi-chevron-left</v-icon>
            </v-btn>
            <v-btn v-if="attachmentsTab" class="header-body-back-btn" icon @click.stop="toggleAttachments">
              <v-icon>mdi-chevron-left</v-icon>
            </v-btn>
            <Avatar v-if="!attachmentsTab && channel.interlocutor" :user="participantMeta" class="mr-3" size="36" />
            <div :class="`d-inline-flex flex-column  ${attachmentsTab && 'justify-center'}`">
              <div class="header-body-title">
                {{ attachmentsTab ? "Back to " : "" }} {{ participantDisplayName }}
                <img
                  v-if="userIsPractitioner"
                  :src="require('@/assets/icons/mrn.svg')"
                  alt="medicalRecordNumber"
                  class="ml-2 pointer"
                  title="Medical record number"
                  @click="redirectToMedicalRecord(participantMeta.id)"
                />
                <img
                  v-if="isPatientPractitioner"
                  :src="require('@/assets/chat/star-fil.svg')"
                  alt="Primary clinician"
                  class="ml-2"
                  title="Primary clinician"
                />
              </div>
              <div v-if="!attachmentsTab && channel.interlocutor" class="header-body-subtitle">
                <span>
                  {{ participantOnline ? this.$t("chat.online") : this.$t("chat.offline") }}
                </span>
                <span v-if="Boolean(chiefComplaint)"> | {{ chiefComplaint }} </span>
                <span v-if="Boolean(participantMeta.qualification)"> | {{ participantMeta.qualification }} </span>
              </div>
            </div>
          </div>
        </div>
        <div class="header-c2 d-inline-flex align-center fill-height pr-6">
          <IconicButton
            v-if="isStartedVisitNote"
            :lite="true"
            :vertical="true"
            icon
            text="Visit note"
            @onClick="openVisitNote"
          >
            <template v-slot:left>
              <img :src="require('@/assets/add-past-visit.svg')" alt="Encounter Notes" title="Encounter Notes" />
            </template>
          </IconicButton>
          <IconicButton
            v-if="!isArchived"
            :lite="true"
            :vertical="true"
            icon
            text="Archive"
            @onClick="archiveDialog = true"
          >
            <template v-slot:left>
              <img :src="require('@/assets/chat/archive-blue.svg')" alt="Archive" title="Archive" />
            </template>
          </IconicButton>

          <v-menu bottom content-class="header__actions-menu" left offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-icon class="more-icon" v-bind="attrs" v-on="on">mdi-dots-horizontal</v-icon>
            </template>
            <v-list>
              <v-list-item @click="toggleAttachments()">
                <v-list-item-icon class="mr-1">
                  <v-icon color="primary" size="18"> mdi-file-multiple-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-title class="action-btn"> Attachments</v-list-item-title>
              </v-list-item>
              <v-list-item v-if="!isArchived && userIsPractitioner && !isStartedVisitNote" @click="initVisitNote">
                <v-list-item-icon class="mr-1">
                  <img
                    :src="require('@/assets/add-past-visit.svg')"
                    alt="Encounter Notes"
                    title="Encounter Notes"
                    width="18"
                  />
                </v-list-item-icon>
                <v-list-item-title class="action-btn"> Create visit note</v-list-item-title>
              </v-list-item>
              <v-list-item v-if="!isArchived && userIsPractitioner" @click="openRenameDialog">
                <v-list-item-icon class="mr-1">
                  <v-icon color="primary" size="18"> mdi-grease-pencil</v-icon>
                </v-list-item-icon>
                <v-list-item-title class="action-btn"> Rename conversation</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
      </div>
      <div ref="chatContainer" v-chat="{ always: false }" class="chat flex-grow-1">
        <Preview v-if="!messagesComp.length" :text="$t('chat.preview.noMessages')" />
        <template v-else>
          <div v-for="msg in messagesComp" :key="msg.sid" class="border-radius-inherit">
            <div v-if="!attachmentsTab">
              <MessageRow
                v-if="!msg.attributes.isHidden"
                :is-archived="isArchived"
                :message="msg"
                :response-delegate="send"
                @action="action"
              >
                <template v-if="isStartedVisitNote" v-slot:append>
                  <v-menu :left="msg.my" :right="!msg.my" bottom class="visit-note-action" offset-y>
                    <template v-slot:activator="{ on, attrs }">
                      <div class="visit-note-action" v-bind="attrs" v-on="on">
                        <img
                          :src="require('@/assets/add-past-visit.svg')"
                          alt="Encounter Notes"
                          title="Encounter Notes"
                        />
                      </div>
                    </template>
                    <v-list>
                      <v-list-item
                        v-for="field in encounterFields"
                        :key="field.name"
                        @click="addFieldToVisitNote(msg, field.name)"
                      >
                        <v-list-item-title class="action-btn">
                          Add to <b>{{ field.label }}</b></v-list-item-title
                        >
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </template>
              </MessageRow>
            </div>
          </div>
        </template>
        <MediaRow v-if="attachmentsTab" :channelId="channel.sid" />
        <StatusRow
          :connected="participantOnline"
          :is-floating-chat="channel.channelAttributes.isFloatingChat"
          :is-service-online="serviceOnline"
          :participant-is-bot="channel.isAnyParticipantBot"
          :typing="channel.isTyping"
        />
      </div>
      <div v-if="!isArchived" class="input flex-shrink-0 d-flex flex-column">
        <div v-if="files" class="mb-1 d-flex flex-wrap">
          <v-chip v-for="file in files" :key="file.name" class="mr-2 mb-1" color="primary" dark label small>
            {{ file.name }}
          </v-chip>
        </div>
        <div v-if="channel.chatConversationType === 'full'" class="input-body d-flex">
          <div class="flex-grow-1">
            <v-textarea
              v-model="message"
              :placeholder="$t('chat.inputPlaceholder')"
              auto-grow
              class="input-body-textarea"
              dense
              flat
              hide-details
              row-height="20"
              rows="1"
              solo
              v-on:keydown="() => this.channel.typing()"
              v-on:keydown.enter.exact.prevent
              v-on:keyup.enter.exact="send(message, null)"
            />
          </div>
          <div class="input-body-actions d-flex flex-column flex-nowrap">
            <div class="input-body-actions-buttons">
              <input v-show="false" ref="uploads" multiple name="uploads" type="file" @change="updateFileLists" />
              <v-btn color="primary" icon @click="openFileUploads">
                <v-icon>mdi-paperclip</v-icon>
              </v-btn>
              <v-btn color="primary" icon @click="send(message, null)">
                <v-progress-circular v-if="sendLoading" color="primary" indeterminate size="23" width="3" />
                <v-icon v-else>mdi-send</v-icon>
              </v-btn>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ChannelRename :channel="renameChannel" :is-opened="isRenameOpened" @onClose="isRenameOpened = false" />
    <Confirm
      :cancelText="$t('general.dialog.cancel')"
      :confirmText="$t('chat.archiveDialog.confirm')"
      :dialog="archiveDialog"
      :loading="archiveLoading"
      :title="$t('chat.archiveDialog.title')"
      confirm-color="primary"
      @confirm="archive"
      @toggleDialog="closeArchiveDialog"
    />
  </div>
</template>

<script>
import { DateTime } from "luxon";
import { mapActions, mapState } from "pinia";

import ChannelRename from "@/components/Chat/Channel/ChannelRename.vue";
import IconicButton from "@/components/uikit/IconicButton.vue";
import ChatConversation from "@/context/twilioContext/ChatConversation/ChatConversation";
import { INIT_CHAT_VISIT_NOTE_FIELD, UPDATE_CHAT_VISIT_NOTE_FIELD } from "@/eventBuses/socketEvents";
import { useAuthStore } from "@/pinia-store/auth";
import { useChatStore } from "@/pinia-store/chat";
import { usePatientStore } from "@/pinia-store/patient";
import { RolesEnum } from "@/types/Roles.enum";
import { scrollToBottom } from "@/utils/scrollToBottom";

import Avatar from "../../Avatar/Index";
import Confirm from "../../shared/Confirm";
import Preview from "./Preview";
import MediaRow from "./Rows/MediaRow";
import MessageRow from "./Rows/MessageRow.vue";
import StatusRow from "./Rows/StatusRow";

export default {
  name: "ChatChannel",
  props: {
    channel: ChatConversation,
    active: {
      type: Boolean,
      required: true,
    },
  },
  components: {
    ChannelRename,
    IconicButton,
    MediaRow,
    StatusRow,
    MessageRow,
    Preview,
    Confirm,
    Avatar,
  },
  data: () => ({
    isRenameOpened: false,
    encounterFields: [
      {
        label: "Visit Reason",
        name: "visitReason",
      },
      {
        label: "Chief complaint",
        name: "chiefComplaint",
      },
      {
        label: "Diagnosis",
        name: "dx",
      },
      {
        label: "Complaint description",
        name: "complaintDescription",
      },
      {
        label: "Assessment",
        name: "assessment",
      },
      {
        label: "Patient instructions",
        name: "plan",
      },
      {
        label: "Follow up note",
        name: "followUp",
      },
    ],

    message: "",
    files: null,
    sendLoading: false,
    archiveDialog: false,
    archiveLoading: false,
    attachmentsTab: false,
    messages: [],
    participantOnline: false,
    serviceOnline: true,
    participantStatusSubscription: null,
  }),
  watch: {
    participant(newVal) {
      if (newVal !== null) {
        this.participantStatusSubscription?.unsubscribe();
        this.participantStatusSubscription = this.channel.interlocutor
          ?.getChannelStatusObservable(this.channel.sid)
          .subscribe((val) => {
            this.participantOnline = val === "online";
          });
      } else {
        this.participantStatusSubscription?.unsubscribe();
        this.participantStatusSubscription = null;
      }
    },
  },
  computed: {
    ...mapState(useChatStore, ["startedVisitNoteChannel"]),
    ...mapState(useAuthStore, ["uid", "timeZone", "role", "userIsPractitioner", "userIsPatient"]),
    ...mapState(usePatientStore, ["patient"]),
    renameChannel() {
      return this.channel;
    },
    participant() {
      return this.channel.interlocutor;
    },
    participantDisplayName() {
      if (!this.channel.interlocutor) return "No other participants in the channel ";
      return this.channel.interlocutor?.displayName ?? "Loading...";
    },
    participantMeta() {
      return this.channel?.interlocutor?.meta ?? null;
    },
    chiefComplaint() {
      return this.channel?.channelAttributes?.chiefComplaint;
    },
    messagesComp() {
      const messages = this.messages ?? [];
      if (!messages || messages.length === 0) {
        return [];
      }
      let currAuthor = null;
      for (let i = 0; i < messages.length; i++) {
        messages[i].my = messages[i].author === this.uid;
        if (messages[i].author !== currAuthor) {
          messages[i].head = true;
          currAuthor = messages[i].author;
          if (i > 0) {
            messages[i].head = false;
            messages[i - 1].last = true;
          }
        } else if (
          i > 0 &&
          DateTime.fromJSDate(messages[i].dateCreated)
            .diff(DateTime.fromJSDate(messages[i - 1].dateCreated), "minutes")
            .as("minutes") > 10
        ) {
          messages[i].head = true;
          messages[i - 1].last = true;
        }
      }
      messages[messages.length - 1].last = true;
      if (this.channel?.channelAttributes?.chiefComplaint && messages?.[0]?.author !== "system")
        messages.unshift({
          author: "system",
          body: `${this.channel?.channelAttributes?.chiefComplaint} | ${this.$moment(this.channel.created)
            .tz(this.timeZone)
            .format("DD MMMM YYYY [at] HH:mm")}`,
          attributes: { isHidden: false },
        });
      return messages;
    },
    isArchived() {
      return this.channel.chatConversationType === "log";
    },
    isPatientPractitioner() {
      return this.userIsPatient && this.patient?.practitioner?.id === this.participantMeta?.id;
    },
    isStartedVisitNote() {
      return this.startedVisitNoteChannel === this.channel.sid && this.userIsPractitioner;
    },
  },
  methods: {
    ...mapActions(useChatStore, ["getStartConversationParticipants"]),
    ...mapActions(useChatStore, ["setStartedVisitNoteChannel", "setCurrentGroup"]),
    openRenameDialog() {
      this.isRenameOpened = true;
    },
    initVisitNote() {
      this.$root.$emit(INIT_CHAT_VISIT_NOTE_FIELD);
      this.$root.$emit(UPDATE_CHAT_VISIT_NOTE_FIELD, { field: "channelId", value: this.channel.sid });
      this.setStartedVisitNoteChannel(this.channel.sid);
      this.$root.$emit(UPDATE_CHAT_VISIT_NOTE_FIELD, {
        field: "patientId",
        value: this.channel?.interlocutor?.id || this.channel?.interlocutor?.uid,
      });
      this.$root.$emit(UPDATE_CHAT_VISIT_NOTE_FIELD, {
        field: "patient",
        value: this.channel?.interlocutor?._userMeta,
      });
    },
    openVisitNote() {
      this.$root.$emit(UPDATE_CHAT_VISIT_NOTE_FIELD, { field: "isOpened", value: true });
    },
    addFieldToVisitNote(msg, field) {
      if (field === "encounterDate") {
        let start = this.$moment(msg.dateUpdated).tz(this.timeZone).format();
        let end = this.$moment(msg.dateUpdated).tz(this.timeZone).add(15, "minutes").format();
        this.$root.$emit(UPDATE_CHAT_VISIT_NOTE_FIELD, { field: "start", value: start });
        this.$root.$emit(UPDATE_CHAT_VISIT_NOTE_FIELD, { field: "end", value: end });

        return;
      }
      if (field === "assessment" && msg.body && msg.attributes?.msgType !== "gallery" && msg.type !== "gallery") {
        this.$root.$emit(UPDATE_CHAT_VISIT_NOTE_FIELD, { field, value: msg.body });
        return;
      }
      if (field === "plan" && msg.body && msg.attributes?.msgType !== "gallery" && msg.type !== "gallery") {
        this.$root.$emit(UPDATE_CHAT_VISIT_NOTE_FIELD, { field, value: msg.body });
        return;
      }
      if (field === "followUp" && msg.body && msg.attributes?.msgType !== "gallery" && msg.type !== "gallery") {
        this.$root.$emit(UPDATE_CHAT_VISIT_NOTE_FIELD, { field, value: msg.body });
        return;
      }
      this.$root.$emit(UPDATE_CHAT_VISIT_NOTE_FIELD, { field, value: msg.body });
    },
    action(action) {
      this.$emit("action", action);
    },
    async redirectToMedicalRecord(patientId) {
      if (this.role !== RolesEnum.Patient) await this.$router.push(`/practitioner/record/patient/${patientId}`);
    },
    toggleAttachments() {
      this.attachmentsTab = !this.attachmentsTab;
    },
    openFileUploads() {
      this.$refs.uploads.click();
    },
    updateFileLists() {
      this.files = [];
      for (const file of this.$refs.uploads.files) {
        this.files.push(file);
      }
    },
    scroll() {
      let element = this.$refs.chatContainer;
      scrollToBottom(element, false);
    },
    checkScroll() {
      let el = this.$refs.chatContainer;
      const scrolled = el.scrollTop + el.clientHeight + 1 < el.scrollHeight;
      if (scrolled && el.scrollTop < el.scrollHeight / 10) {
        el.dispatchEvent(new Event("chatTopReached"));
      }
    },
    async send(text, value, respondsTo) {
      this.sendLoading = true;
      await this.channel.sendMessage(text, value, respondsTo, this.files);
      this.message = "";
      this.sendLoading = false;
    },
    closeArchiveDialog() {
      this.archiveDialog = false;
    },
    async archive() {
      this.archiveLoading = true;
      await this.channel.requestArchivation();
      this.archiveLoading = false;
      // code added
      // this.injectable.twilioContext?.shutdown();
      // this.injectable.twilioContext = new TwilioContext();
      // this.injectable.twilioContext?.setCurrentFloatingChannel(null);
      // await this.injectable.twilioContext.initialize(this.uid);
      // end code added
      this.archiveDialog = false;
    },
  },
  deactivated() {
    this.participantStatusSubscription?.unsubscribe();
  },
  async mounted() {
    this.messages = this.channel.messages ?? [];
    if (this.channel.interlocutor) {
      const obs = this.channel.interlocutor.getChannelStatusObservable(this.channel.sid);
      this.participantOnline = obs.getValue();
      this.participantStatusSubscription = obs.subscribe((val) => {
        this.participantOnline = val === "online";
      });
    }
  },
  updated() {
    this.active && this.$route.path.includes("chat") ? this.channel.consumeMessages() : undefined;
    this.scroll();
  },
};
</script>

<style lang="scss" scoped>
.header {
  height: 75px;

  &-c2 {
    gap: 16px;
  }

  .more-icon {
    color: $primary;
  }

  &__actions-menu {
    .v-list {
      padding-top: 0;
      padding-bottom: 0;
    }

    text-align: left;
    background: #ffffff;
    border: 1px solid #eeeff7;
    box-shadow: none;
    box-sizing: border-box;
    border-radius: 4px;

    .v-list-item:not(:last-child) {
      border-bottom: 1px solid #eeeff7;
    }

    .v-list-item:hover {
      cursor: pointer;
      background: rgba(34, 148, 242, 0.08);
    }
  }

  &-c1 {
    overflow: hidden;
  }

  &-body {
    margin-left: 24px;
    @include tablet {
      margin-left: 10px;
    }

    &-back-btn {
      width: 28px;
      height: 28px;
      box-shadow: 0 1px 4px rgba(0, 0, 0, 0.12);
      margin-right: 24px;

      @include tablet {
        margin-right: 10px;
      }
    }

    &-title {
      display: flex;
      align-items: center;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
    }

    &-subtitle {
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 20px;
      color: #000000;
      opacity: 0.46;
    }
  }
}

.chat {
  border-bottom: 1px solid #efeff0;
  border-top: 1px solid #efeff0;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 0 22px 22px 22px;
  scroll-padding: 22px;
}

.visit-note-action {
  display: flex;
  align-self: center;
  padding: 0 10px;
}

.input {
  padding: 10px;
  min-height: 61px;

  &-body {
    border: 1px solid #d2d3e1;
    box-sizing: border-box;
    border-radius: 5px;
    overflow: hidden;

    //&-textarea {
    //  margin-bottom: -25px;
    //}

    &-actions {
      width: 80px;
      align-self: center;

      &-buttons {
        margin-bottom: 2px;
      }
    }
  }
}

.hidden {
  display: none !important;
}
</style>
