<template>
  <div class="medical-record-search-result">
    <div class="medical-record-search-result_datatable" v-for="(data, index) in tableData" :key="index">
      <p class="heading-4 text-left">{{ data.caption }}</p>
      <v-data-table :headers="data.headers" :items="data.items" disable-sort>
        <template v-slot:item.name="{ item }">
          <p v-html="item.name"></p>
        </template>
      </v-data-table>
    </div>
  </div>
</template>

<script>
export default {
  props: ["result", "search"],
  data() {
    return {
      tableData: [],
    };
  },
  name: "MedicalRecordSearchResult",
  methods: {
    prepareTableData() {
      this.tableData = [];
      this.result.forEach((result) => {
        const [key] = Object.keys(result);
        if (typeof result[key][0] === "object") {
          const headers = Object.keys(result[key][0]).map((header) => ({
            text: header.replace(/^\w/, (c) => c.toUpperCase()),
            value: header,
          }));
          let items = result[key].map((res) => res);
          items = items.filter((item, index) => items.findIndex((t) => t.id === item.id) === index);
          this.tableData.push({
            caption: key.length > 3 ? key.replace(/^\w/, (c) => c.toUpperCase()) : key.toUpperCase(),
            headers,
            items,
          });
        } else {
          const regexp = new RegExp(this.search, "gi");
          this.tableData.push({
            caption: key.length > 3 ? key.replace(/^\w/, (c) => c.toUpperCase()) : key.toUpperCase(),
            headers: [{ text: "Name", value: "name" }],
            items: result[key].map((res) => ({
              name: res.replace(regexp, `<span class="highlighted-search">${this.search}</span>`),
            })),
          });
        }
      });
    },
  },
  watch: {
    result() {
      this.prepareTableData();
    },
  },
  mounted() {
    this.prepareTableData();
  },
};
</script>

<style lang="scss">
.highlighted-search {
  background: rgba(30, 133, 217, 0.1);
  color: #2196f3;
  padding: 3px;
}
</style>
