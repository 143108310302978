<template>
  <v-flex class="participant-waiting">
    <video ref="videoElement" autoplay playsinline />
    <v-layout align-center class="overlay" column justify-center>
      <div class="buttons">
        <v-btn
          :large="!minimized"
          :small="minimized"
          class="buttons-start"
          color="success"
          dark
          depressed
          @click="onJoin"
        >
          {{ $t("visits.waiting.joinVisit") }}
        </v-btn>
      </div>
    </v-layout>
  </v-flex>
</template>

<script>
import { DateTime } from "luxon";
import { mapActions, mapState } from "pinia";

import { useAuthStore } from "@/pinia-store/auth";
import { useMeetingRoomStore } from "@/pinia-store/meetingRoom";
import { routesEnum } from "@/types/Routes.enum";
import { sleep } from "@/utils/sleep";

export default {
  name: "WaitingRoom",
  data() {
    return {
      interval: null,
      timerInterval: null,
      timer: "",
      timerInSeconds: 0,
      cancelDialog: false,
      stream: null,
    };
  },

  computed: {
    ...mapState(useMeetingRoomStore, [
      "isVideo",
      "isModalMode",
      "roomId",
      "identityId",
      "meetingRoom",
      "roomIsStarted",
      "connectedParticipant",
      "minimized",
    ]),
    ...mapState(useAuthStore, ["role"]),
  },
  methods: {
    ...mapActions(useMeetingRoomStore, ["getRoomStatus", "joinToRoom"]),
    ...mapActions(useMeetingRoomStore, ["setUserIsReady", "setLocalParticipantAcceptedJoin"]),
    async turnOnVideo() {
      const videoElement = this.$refs.videoElement;
      if (videoElement && navigator.mediaDevices.getUserMedia) {
        this.stream = await navigator.mediaDevices.getUserMedia({
          video: true,
        });
        videoElement.srcObject = this.stream;
      }
    },
    async turnOffVideo() {
      const videoElement = this.$refs.videoElement;
      if (videoElement) {
        videoElement.srcObject = null;
      }
      if (this.stream) {
        this.stream.getTracks().forEach((track) => {
          this.stream.removeTrack(track);
          track.stop();
        });
        this.stream = null;
      }
    },
    async onJoin() {
      await this.turnOffVideo();

      setTimeout(() => {
        this.setUserIsReady(true);
        this.setLocalParticipantAcceptedJoin(true);
      }, 200);
      await this.joinToRoom({
        meetingRoomId: this.isModalMode ? this.roomId : this.$route.params.roomId,
        identity: this.isModalMode ? this.roomId : this.$route.params.identity,
      });
    },
    timerUpdate() {
      if (!this.meetingRoom?.sentAt) return "";
      let diff = DateTime.fromISO(this.meetingRoom.sentAt).diffNow("seconds").as("seconds");
      if (diff < 0) {
        diff = 0;
      }
      const hours = Math.floor(diff / 3600);
      const minutes = Math.floor((diff % 3600) / 60);
      const seconds = Math.floor((diff % 3600) % 60);
      const hoursStr = hours ? `${hours} hours, ` : "";
      const minutesStr = minutes ? `${minutes} minutes, ` : "";
      const secondsStr = `${seconds} seconds`;
      this.timer = `${hoursStr}${minutesStr}${secondsStr}`;
      this.timerInSeconds = diff;
      if (!this.timerInSeconds) {
        window.clearInterval(this.timerInterval);
        this.timerInterval = null;
      }
    },
    async onCancel() {
      await sleep(5000);
      await this.$router.push({ name: routesEnum.practitionerHome });
    },
  },
  async mounted() {
    if (this.isVideo) {
      await this.turnOnVideo();
    }
    this.interval = setInterval(() => {
      this.getRoomStatus(this.isModalMode ? this.roomId : this.$route.params.roomId);
    }, 3000);
    this.timerInterval = setInterval(this.timerUpdate, 1000);
  },
  async beforeDestroy() {
    if (this.interval) {
      window.clearInterval(this.interval);
      this.interval = null;
    }
    if (this.timerInterval) {
      window.clearInterval(this.timerInterval);
      this.timerInterval = null;
    }
    await this.turnOffVideo();
  },
  watch: {
    async isVideo() {
      if (this.isVideo) {
        await this.turnOnVideo();
      } else {
        await this.turnOffVideo();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.message {
  padding-left: 15px;
  padding-right: 15px;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 35px;
  right: 20px;
  top: 21px;
  background: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(16px);
  border-radius: 100px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: $white;
  opacity: 0.86;

  &-dot {
    display: inline-block;
    width: 4px;
    height: 4px;
    left: 794px;
    top: 135px;
    background: $success;
    border-radius: 6px;
    margin-right: 5px;
  }
}

.overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  bottom: 0;
  right: 0;
  z-index: 1;
  color: white;

  .title {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    opacity: 0.68;
  }

  .sub-title {
    margin-top: 20px;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    mix-blend-mode: normal;
    opacity: 0.4;
  }

  .timer {
    margin-top: 10px;
    font-style: normal;
    font-weight: normal;
    font-size: 25px;
    line-height: 30px;
    text-align: center;

    @media (max-width: 767.98px) {
      padding-bottom: 57px;
    }
  }

  .buttons {
    margin-top: 15px;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    text-align: center;

    &-cancel {
      opacity: 0.4;
    }
  }
}
</style>
