import { AvailabilitySlots } from "@/types/Availability";

import { apiRequest } from "./apiRequest";

interface AdminUpdateSettingsBody {
  timeZone: string;
  availabilitySlots: AvailabilitySlots;
  locale: string;
  slotDuration: string;
  scheduleHorizonLimit: number;
  scheduleToStartLimit: string;
  scheduleToCancelLimit: string;
}

interface AdminUpdateProfileBody {
  name: {
    lastName: string;
    firstName: string;
  };
}

const adminUpdateSettings = async (adminId: string, body: Partial<AdminUpdateSettingsBody>) => {
  const { data } = await apiRequest.put(`/admin/${adminId}/settings`, body);
  return data;
};

const adminGetSettings = async (adminId: string) => {
  const { data } = await apiRequest.get(`/admin/${adminId}/settings`);
  return data;
};

const getAdminById = async (adminId: string) => {
  const { data } = await apiRequest.get(`/admin/${adminId}`);
  return data;
};

const adminUpdateProfile = async (adminId: string, body: AdminUpdateProfileBody) => {
  const { data } = await apiRequest.put(`/admin/${adminId}`, body);
  return data;
};

const adminAvatarUpload = async (adminId: string, formData: FormData) => {
  const { data } = await apiRequest.post(`/admin/${adminId}/avatar`, formData, {
    headers: { "Content-Type": "multipart/form-data" },
  });
  return data;
};

const getAllAdmin = async () => {
  const { data } = await apiRequest.get(`/admin`);
  return data;
};
const getSlotInformation = async (id: string) => {
  const { data } = await apiRequest.get(`/appointment/slot/${id}`);
  return data;
};
const getVisitSettings = async () => {
  const { data } = await apiRequest.get(`/admin/visit-settings`);
  return data;
};
const rejectCheckout = async (id: string) => {
  const { data } = await apiRequest.put(`/checkout/slot/${id}`);
  return data;
};

export const AdminAPI = {
  getVisitSettings,
  adminUpdateSettings,
  adminGetSettings,
  getAdminById,
  adminUpdateProfile,
  adminAvatarUpload,
  getAllAdmin,
  getSlotInformation,
  rejectCheckout,
};
