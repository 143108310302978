import axios, { CancelTokenSource } from "axios";
import { defineStore } from "pinia";

import { MediaApi } from "@/api/media";

const defaultAcceptedFiles =
  "image/JPEG,image/JPG,image/PNG,image/GIF,image/BMP,image/PNG,image/GIF,.pdf, .png, .PNG, .JPEG, .jpeg, .JPG, .jpg";

export interface IMedia {
  id: string;
  name: string;
  type: string;
  size: number;
  url: string;
  component?: string;
  componentId?: string;
}

interface IMediaStore {
  acceptFiles: string;
  files: IMedia[];
  patientAllFiles: IMedia[];
  uid: string;
  fileType: string;
  name: string;
  component: string;
  cancelRequestToken: CancelTokenSource | null;
  isLoading: boolean;
}

export const useMediaStore = defineStore({
  id: "media",
  state: (): IMediaStore => ({
    acceptFiles: defaultAcceptedFiles,
    files: [],
    patientAllFiles: [],
    uid: "",
    fileType: "all",
    name: "",
    component: "",
    cancelRequestToken: null,
    isLoading: false,
  }),
  actions: {
    setFiles(payload: IMedia[]) {
      this.files = payload;
    },
    replaceFile(newFile: IMedia) {
      this.files = this.files.map((file: IMedia) => {
        return file.id === newFile.id ? { ...newFile } : { ...file };
      });
    },
    async fetchFiles() {
      const cancelRequestToken = this.cancelRequestToken;
      if (cancelRequestToken) cancelRequestToken.cancel && cancelRequestToken.cancel();
      const cancelToken = axios.CancelToken.source();
      this.cancelRequestToken = cancelToken;
      this.isLoading = true;
      const { uid, fileType, name, component } = this;
      this.files = await MediaApi.getFiles(
        {
          uid: uid,
          fileType: fileType,
          name: name,
          component: component,
        },
        cancelToken,
      );
      this.cancelRequestToken = null;
      this.isLoading = false;
    },
    async fetchPatientAllFiles(patientId: string) {
      this.patientAllFiles = await MediaApi.getFiles({
        uid: patientId,
      });
    },
    setComponent(component: string) {
      this.component = component;
    },
    async fetchChatFiles(roomId: string) {
      const { fileType } = this;
      this.files = await MediaApi.getChatFiles(roomId, { fileType: fileType });
    },
    async fetchDependentsFiles() {
      this.isLoading = true;
      const { component } = this;
      if (component !== "dependents") this.component = "dependents";
      await this.fetchFiles();
    },
    async fetchComponentFiles(data: { component: string; componentId: string }) {
      const fileType = this.fileType;
      this.files = await MediaApi.getComponentFiles({ fileType: fileType, ...data });
    },
    emptyFiles() {
      this.files = [];
    },
    setUid(uid: string) {
      this.uid = uid;
    },
    setFileType(type: string) {
      this.fileType = type;
    },
    setName(name: string) {
      this.name = name;
    },
  },
});
