export enum routesEnum {
  adminTemplates = "AdminTemplates",
  adminOwnerChat = "AdminOwnerChat",
  adminSettings = "AdminSettings",
  adminOwnerPatients = "AdminOwnerPatients",
  adminPractitioners = "AdminPractitioners",
  adminOwnerClinicians = "AdminOwnerClinicians",
  adminOwnerSchedule = "AdminOwnerSchedule",
  adminOwnerScheduledAppointments = "AdminOwnerScheduledAppointments",
  adminOwnerEditAvailability = "AdminOwnerEditAvailability",
  adminOwnerScheduleVisit = "AdminOwnerScheduleVisit",
  adminOwnerEncounterSchedule = "AdminOwnerEncounterSchedule",
  adminOwnerEncounter = "AdminOwnerEncounter",
  adminOwnerPending = "AdminOwnerPending",

  sso = "sso",
  signin = "Signin",
  code = "Code",
  resetPassword = "ResetPassword",
  requestResetPassword = "RequestResetPassword",
  signup = "Signup",
  patientSignUp = "PatientSignUp",
  publicRoomCall = "PublicRoomCall",
  publicMeetingRoomCall = "publicMeetingRoomCall",
  publicChatRoomCall = "publicChatRoomCall",
  ownerChat = "OwnerChat",
  ownerSettings = "OwnerSettings",
  freeText = "FreeText",
  ownerPatients = "OwnerPatients",
  ownerClinicians = "OwnerClinicians",
  conversationLogs = "ConversationLogs",
  chatBot = "ChatBot",
  ownerSchedule = "OwnerSchedule",
  ownerScheduledAppointments = "OwnerScheduledAppointments",
  ownerEditAvailability = "OwnerEditAvailability",
  ownerScheduleVisit = "OwnerScheduleVisit",
  ownerEncounterSchedule = "OwnerEncounterSchedule",
  ownerEncounter = "OwnerEncounter",
  ownerPending = "OwnerPending",

  patientHome = "PatientHome",
  patientNotifications = "PatientNotifications",
  patientChat = "PatientChat",
  patientDependents = "PatientDependents",
  patientDependentsRegister = "PatientDependentsRegister",
  patientMonitoring = "PatientMonitoring",
  patientMonitoringBloodPressure = "PatientMonitoringBloodPressure",
  patientMonitoringBodyTemperature = "PatientMonitoringBodyTemperature",
  patientMonitoringHeartRate = "PatientMonitoringHeartRate",
  patientMonitoringOxygenSaturation = "PatientMonitoringOxygenSaturation",
  patientMonitoringRespiratoryRate = "PatientMonitoringRespiratoryRate",
  patientMonitoringBloodGlucose = "PatientMonitoringBloodGlucose",
  patientQuickMonitoring = "PatientQuickMonitoring",
  patientMedia = "PatientMedia",
  patientMedicalRecords = "PatientMedicalRecords",
  patientSettings = "PatientSettings",
  patientUpcomingVisits = "PatientUpcomingVisits",
  patientCheckoutComplete = "patientCheckoutComplete",
  patientEncounter = "PatientEncounter",
  patientVisitNote = "PatientVisitNote",

  practitionerNotifications = "PractitionerNotifications",
  practitionerHome = "PractitionerHome",
  practitionerMedia = "PractitionerMedia",
  practitionerChat = "PractitionerChat",
  practitionerClinicalRequests = "PractitionerClinicalRequests",
  practitionerSettings = "PractitionerSettings",
  practitionerPendedNotes = "PractitionerPendedNotes",
  practitionerMeetingRoomCreate = "PractitionerMeetingRoomCreate",
  practitionerMeetingRoomJoin = "PractitionerMeetingRoomJoin",
  practitionerPendedNotesPending = "PractitionerPendedNotesPending",
  registerPatient = "RegisterPatient",
  practitionerPatients = "PractitionerPatients",
  patientMedicalRecord = "PatientMedicalRecord",
  practitionerMedicalRecordVisitNotes = "PractitionerMedicalRecordVisitNotes",
  practitionerMedicalRecordMonitoringOrders = "PractitionerMedicalRecordMonitoringOrders",
  practitionerMedicalRecordFiles = "PractitionerMedicalRecordFiles",
  practitionerMedicalRecordDependents = "PractitionerMedicalRecordDependents",
  practitionerMedicalRecordPrivateNotes = "PractitionerMedicalRecordPrivateNotes",
  practitionerMonitoringListKindOrders = "PractitionerMonitoringListKindOrders",
  practitionerSchedule = "PractitionerSchedule",
  practitionerScheduledAppointments = "PractitionerScheduledAppointments",
  practitionerEditAvailability = "PractitionerEditAvailability",
  practitionerEncounterSchedule = "PractitionerEncounterSchedule",
  practitionerCheckoutComplete = "PractitionerCheckoutComplete",
  practitionerAddPastVisit = "PractitionerAddPastVisit",
  practitionerVisitNote = "PractitionerVisitNote",
  practitionerEncounterMedication = "PractitionerEncounterMedication",
  practitionerEncounterReview = "PractitionerEncounterReview",
  practitionerPending = "PractitionerPending",

  registrarChat = "RegistrarChat",
  registrarSettings = "RegistrarSettings",
  registrarClinician = "RegistrarClinician",
  registrarSchedule = "RegistrarSchedule",
  registrarScheduledAppointments = "RegistrarScheduledAppointments",
  registrarArchive = "RegistrarArchive",
  registrarEditAvailability = "RegistrarEditAvailability",
  registrarScheduleVisit = "RegistrarScheduleVisit",
  registrarEncounterSchedule = "RegistrarEncounterSchedule",
  registrarEncounter = "RegistrarEncounter",
  registrarPending = "RegistrarPending",
}
