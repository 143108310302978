<template>
  <div class="patients-list-cards">
    <v-progress-linear v-if="loading" color="primary" indeterminate></v-progress-linear>
    <div v-for="item in renderItems" :key="item.id" class="data-table patients-list-card">
      <div class="main-details pointer">
        <Avatar :size="40" :src="item.photoURL" />
        <div>
          <div class="title">{{ item.firstName || "" }} {{ item.lastName || "" }}</div>
          <div class="description pointer d-flex align-center" @click="redirectToMedicalRecord(item)">
            {{ item.medicalRecordNumber }}
            <img
              :src="require('@/assets/icons/mrn.svg')"
              alt="medicalRecordNumber"
              class="ml-2"
              title="Medical record number"
            />
          </div>
          <template v-if="withInvite">
            <div v-if="isInviteActive(item)" class="patient-info_email">
              <v-icon> mdi-circle-small</v-icon>
              Active
            </div>
            <div v-if="isInviteRequired(item)" class="patient-info_email">
              <v-icon> mdi-circle-small</v-icon>
              Invitation required
            </div>
            <div v-if="isInviteSent(item)" class="patient-info_email">
              <v-icon> mdi-circle-small</v-icon>
              Invitation sent
            </div>
          </template>
        </div>
      </div>
      <div class="contact-details">
        <ul>
          <li>
            <img :src="require('@/assets/icons/profile.svg')" alt="Birth Date" />
            {{ birthDate(item.birthDate) }}
          </li>
          <li>
            <img :src="require('@/assets/icons/location.svg')" alt="city" />
            {{ item.city || "--" }}
          </li>
          <li>
            <img :src="require('@/assets/icons/mail.svg')" alt="email" />
            <a v-if="item.email" :href="`mailto:${item.email}`">{{ item.email || "--" }}</a>
            <template v-else>--</template>
          </li>
          <li>
            <img :src="require('@/assets/icons/male_alt.svg')" alt="male_alt" />
            {{ item.practitioner || "--" }}
          </li>
          <li>
            <img :src="require('@/assets/icons/phone.svg')" alt="homePhoneNumber" />
            <a v-if="item.homePhoneNumber" :href="`tel:${item.homePhoneNumber}`">{{ item.homePhoneNumber || "--" }}</a>
            <template v-else>--</template>
          </li>
          <li>
            <img :src="require('@/assets/icons/mobile_alt.svg')" alt="phoneNumber" />
            <a v-if="item.phoneNumber" :href="`tel:${item.phoneNumber}`">{{ item.phoneNumber }}</a>
            <template v-else>--</template>
          </li>
        </ul>
      </div>
      <div class="contact-actions">
        <slot :item="item" name="actions"></slot>
        <template v-if="userIsPatient">
          <PrimaryButton
            v-if="showRequestConsult"
            class="primary-btn__blue request-consult-btn"
            text="Request a Consult"
            @onClick="onRequestConsultClick(item)"
          />
        </template>
        <template v-else>
          <OutlinedButton text="Assign Patient" @onClick="$emit('onAssign', item)" />
          <MeetNowMenu :recipient="item" />
          <CallNowAction :phone="item.phoneNumber" />
          <ChatNowAction :phone-number="item.phoneNumber" :to-user-id="item.id" />
        </template>
      </div>
    </div>

    <Pagination
      :page="page"
      :perPage="perPage"
      :total="items.length"
      @onPageChange="onPageChange"
      @onPerPageChange="onItemsPerPageChange"
    />
  </div>
</template>
<script>
import { mapState } from "pinia";

import Avatar from "@/components/Avatar/Index";
import { timeDisplayFormat } from "@/components/Clinicians/constants";
import ChatNowAction from "@/components/FloatingChat/ChatNowAction.vue";
import Pagination from "@/components/Pagination/View.vue";
import MeetNowMenu from "@/components/Patients/MeetNowMenu";
import OutlinedButton from "@/components/uikit/OutlinedButton";
import PrimaryButton from "@/components/uikit/PrimaryButton";
import CallNowAction from "@/components/VoIP/CallNowAction.vue";
import { useAuthStore } from "@/pinia-store/auth";
import { paginate } from "@/pinia-store/slots";
import { RolesEnum } from "@/types/Roles.enum";

export default {
  name: "PatientsListCards",
  components: { Pagination, ChatNowAction, CallNowAction, PrimaryButton, OutlinedButton, Avatar, MeetNowMenu },
  props: {
    headers: Array,
    items: Array,
    search: String,
    loading: Boolean,
    actions: Array,
    showRequestConsult: {
      default: true,
      type: Boolean,
    },
    withInvite: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      perPage: 10,
      page: 1,
    };
  },
  computed: {
    ...mapState(useAuthStore, ["userIsPatient", "role"]),
    pagination() {
      return {
        itemsPerPage: this.itemsPerPageItems,
        perPage: this.perPage,
        page: this.page,
      };
    },

    itemsLength() {
      return Math.ceil(this.items.length / this.perPage);
    },

    renderItems() {
      return paginate(this.items || [], this.perPage, this.page);
    },
    itemsPerPageItems() {
      return [
        {
          text: 5,
          value: 5,
        },
        {
          text: 10,
          value: 10,
        },
        {
          text: 15,
          value: 15,
        },
        {
          text: "All",
          value: this.items.length,
        },
      ];
    },
  },
  methods: {
    isInviteActive(item) {
      return Boolean(item?.invite?.useDate);
    },
    isInviteRequired(item) {
      return !item?.invite?.id;
    },
    isInviteSent(item) {
      return Boolean(item?.invite?.id && !item?.invite?.useDate);
    },
    onRequestConsultClick(item) {
      this.$emit("openRequestConsultDialog", item);
    },
    async redirectToMedicalRecord(item) {
      if (this.role !== RolesEnum.Patient) await this.$router.push(`/practitioner/record/patient/${item.id}`);
    },
    birthDate(value) {
      return this.$moment(value, "MM/DD/YYYY").format(timeDisplayFormat);
    },
    practitioner(item) {
      return item.practitioner ? `${item?.practitioner?.firstName || ""} ${item?.practitioner?.lastName || ""}` : "";
    },
    onPageChange(page) {
      this.page = page;
    },
    onItemsPerPageChange(perPage) {
      this.perPage = perPage;
      if (this.page > this.itemsLength) {
        this.page = this.itemsLength;
      }
    },
  },
};
</script>

<style lang="scss">
.patients-list-cards {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 24px;

  .request-consult-btn {
    max-width: 180px;
  }

  .patients-list-card {
    background: #ffffff;
    border: 1px solid #e7e8f2;
    border-radius: 3px;
    display: flex;
    padding: 20px;
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 20px;
    @include tablet {
      flex-direction: column;
    }

    .main-details {
      display: flex;
      flex-basis: 230px;
      gap: 14px;
      align-items: center;
      flex-shrink: 0;

      .title {
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        color: #33343e;
      }

      .description {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        color: #33343e;
        opacity: 0.4;
      }

      @include tablet {
        flex-basis: 100%;
      }
    }

    .contact-details {
      align-items: center;
      display: flex;
      flex-basis: 100%;
      max-width: 710px;
      @include tablet {
        flex-basis: 100%;
        max-width: 100%;
      }

      ul {
        padding-left: 0;
        column-count: 3;
        width: 100%;
        @include extraLargeUp {
          column-count: 3;
        }
        @include extraLarge {
          column-count: 2;
        }
        @include large {
          column-count: 2;
        }
        @include desktop {
          column-count: 2;
        }
        @include tablet {
          column-count: 2;
        }
        @include mobile {
          column-count: 1;
        }

        li {
          list-style: none;
          display: flex;
          align-items: center;
          color: #33343e;
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 24px;
          max-width: 210px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis ellipsis;

          a {
            text-decoration: none;
            color: #33343e;
          }

          img {
            width: 16px;
            margin-right: 8px;
          }
        }
      }
    }

    .contact-actions {
      flex-basis: 260px;
      display: flex;
      gap: 16px;
      flex-wrap: wrap;
      justify-content: flex-end;
      flex-direction: column;

      > button {
        flex: 1 auto;
        @media screen and (min-width: $mobile-width) and (max-width: $desktop-width) {
          &:not(.request-consult-btn) {
            max-width: 118px;
          }
        }
      }

      @include largeUp {
        flex-basis: auto;
        flex-direction: row;
      }
      @include large {
        flex-basis: 260px;
        flex-direction: row;
      }
      @include desktop {
        flex-basis: 100%;
        flex-direction: row;
      }
      @include tablet {
        flex-basis: 100%;
        flex-direction: row;
        width: 100%;
      }
      @include mobile {
        flex-basis: 100%;
        width: 100%;
        flex-direction: row;
      }
    }
  }
}

.pointer {
  cursor: pointer;
}

.patients-cards-footer {
  &__per-page-select {
    width: 100px;
    border: 2px solid $primarylight;
    border-radius: 4px;

    fieldset {
      border: none;
    }
  }
}

.patients-cards-pagination {
  ul {
    justify-content: flex-end;
    @media screen and (max-width: 960px) {
      justify-content: flex-start;
    }

    li {
      button {
        box-shadow: none;
        border: 2px solid $primarylight;
        box-sizing: border-box;
        border-radius: 4px;

        &.v-pagination__navigation {
          box-shadow: 0 1px 4px rgba(0, 0, 0, 0.12);
        }

        &.v-pagination__item {
          font-weight: bold;
          color: $primarylight3 !important;
        }

        &.v-pagination__item--active {
          color: #ffffff !important;
        }
      }
    }
  }
}
</style>
