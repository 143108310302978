<template>
  <v-row class="past-visit-chart-review mt-4" no-gutters>
    <v-col cols="12">
      <SignedVisitNote v-if="!userIsPatient" :item="item" />
      <SignedVisitNotePatient v-else :item="item" />
    </v-col>
  </v-row>
</template>

<script>
import { mapState } from "pinia";

import { EncountersAPI } from "@/api/encounters";
import { useAuthStore } from "@/pinia-store/auth";
import { useVisitNoteStore } from "@/pinia-store/visitNote";
import SignedVisitNote from "@/views/Practitioner/SignedVisit/SignedVisitNote";
import SignedVisitNotePatient from "@/views/Practitioner/SignedVisitPatient/SignedVisitNotePatient.vue";

export default {
  name: "PastVisitSummary",
  components: {
    SignedVisitNotePatient,
    SignedVisitNote,
  },
  data: () => ({ item: {} }),
  computed: {
    ...mapState(useAuthStore, ["userIsPatient"]),
    ...mapState(useVisitNoteStore, ["encounter"]),
  },
  methods: {
    async getVisitNote() {
      this.item = await EncountersAPI.getDocument(this.encounter.id);
      this.isLoading = false;
    },
  },
  async mounted() {
    this.isLoading = true;
    await this.getVisitNote();
  },
};
</script>

<style lang="scss" scoped>
.past-visit-chart-review {
  /*min-height: 100%;*/
  /*max-width: 90%;*/
  /*width: 1360px;*/
}
</style>
