import { Conversation, LastMessage, Message, Participant } from "@twilio/conversations";
import { Observable, Subject } from "rxjs";

import { ChatApi } from "@/api/chat";
import { IParticipantsRepository } from "@/context/twilioContext/ChatConversation/IParticipantsRepository";
import { ITwilioConversation } from "@/context/twilioContext/ChatConversation/ITwilioConversation";
import { IMessagesRepository } from "@/context/twilioContext/ChatConversation/MessagesRepository";
import IConversation from "@/context/twilioContext/interfaces/IConversation";
import { ITwilioMessage } from "@/context/twilioContext/interfaces/ITwilioMessage";
import { ChatLogRecordDto } from "@/context/twilioContext/TwilioChannelLog";
import ChatUser from "@/context/twilioContext/TwilioChatMember/ChatUser";
import { MessageEvents } from "@/context/twilioContext/types/MessageEvents";
import { MessagesState } from "@/context/twilioContext/types/MessagesState";
import { TwilioChatChannelAttributes } from "@/context/twilioContext/types/TwilioChatChannelAttributes";
import { TwilioChatMessageAttributes } from "@/context/twilioContext/types/TwilioChatMessageAttributes";
import { TwilioConversationEvent } from "@/context/twilioContext/types/TwilioConversationEvent";
import { TwilioConversationUpdatePayload } from "@/context/twilioContext/types/TwilioEventPayloadTypes";
import { snackBarEventBus, snackBarEventName } from "@/eventBuses/snackBar.eventBus";
import { RolesEnum } from "@/types/Roles.enum";

export type ChatConversationType = "dummy" | "full" | "log";

class ChatConversation implements IConversation {
  public readonly uid: string;
  public readonly sid: string | null;
  public _twilioChannel?: ITwilioConversation;
  public _participantsRepository: IParticipantsRepository;
  private readonly created: Date;
  private _messagesRepo: IMessagesRepository;
  private readonly _messagesState$ = new Subject<MessagesState>();
  private readonly conversationLogRecord: ChatLogRecordDto | null;

  constructor(
    conversationType: ChatConversationType,
    uid: string,
    messagesRepository: IMessagesRepository,
    membersRepository: IParticipantsRepository,
    twilioChannel?: ITwilioConversation,
    conversationLog?: ChatLogRecordDto,
  ) {
    if (conversationType === "full" && !twilioChannel) {
      throw new Error(
        "ChatConversation constructor: Constructing 'full' conversation typ but twilioChannel is missing",
      );
    }
    if (conversationType === "log" && !conversationLog) {
      throw new Error(
        "ChatConversation constructor: Constructing 'log' conversation type but conversationLog is missing",
      );
    }
    this.uid = uid;
    this.sid = twilioChannel?.sid ? twilioChannel.sid : conversationLog?.channelSid ? conversationLog.channelSid : null;
    this.created = twilioChannel?.dateCreated ? twilioChannel.dateCreated : new Date(Date.now());

    this._chatConversationType = conversationType;
    this.conversationLogRecord = conversationLog ?? null;

    this._participantsRepository = membersRepository;
    this._messagesRepo = messagesRepository;
    this._twilioChannel = twilioChannel;
  }

  get channelAttributes(): TwilioChatChannelAttributes {
    return this.conversationLogRecord
      ? this.conversationLogRecord.conversationData?.channelAttributes
      : this._twilioChannel?.attributes ?? ({} as TwilioChatChannelAttributes);
  }

  get dateCreated(): Date {
    return this._twilioChannel?.dateCreated ?? this.created;
  }

  get addMember(): (user: ChatUser) => Promise<void> {
    if (this._chatConversationType === "dummy")
      throw new Error("This property is unavailable for this type of conversation.");
    return this._participantsRepository.addParticipant;
  }

  get messages(): ITwilioMessage[] {
    return this._messagesRepo.listMessages();
  }

  get friendlyName(): string {
    return this._twilioChannel?.friendlyName ?? "Dummy conversation with anonymous user.";
  }

  get user(): ChatUser {
    return this._participantsRepository.user;
  }

  get interlocutor(): ChatUser | null {
    return this._participantsRepository.interlocutor;
  }

  get setInterlocutor(): (uid: string) => void {
    return this._participantsRepository.setInterlocutor;
  }

  get isTyping(): boolean {
    return this._participantsRepository.isTyping;
  }

  get isAnyParticipantBot(): boolean {
    return this._participantsRepository.isAnyParticipantBot;
  }

  get lastMessageMeta(): LastMessage {
    return this._messagesRepo.lastMessageMeta;
  }

  get lastMessageInstance(): ITwilioMessage {
    return this._messagesRepo.lastMessageInstance;
  }

  get unconsumedMessagesCount(): number {
    if (this._chatConversationType === "log") return 0;
    else if (this._chatConversationType === "dummy" || !this._twilioChannel)
      throw new Error("This property is unavailable for this type of conversation.");
    return this._twilioChannel?.unconsumedMessagesCount.getValue();
  }

  get unconsumedMessagesDelta(): Observable<number> {
    if (this._chatConversationType === "log") return new Observable<number>();
    else if (this._chatConversationType === "dummy" || !this._twilioChannel)
      throw new Error("This property is unavailable for this type of conversation.");

    return this._twilioChannel?.unconsumedMessagesDelta;
  }

  private _chatConversationType: ChatConversationType;

  get chatConversationType(): ChatConversationType {
    return this._chatConversationType;
  }

  public updateWith(twilioChannel: ITwilioConversation, membersRepository: IParticipantsRepository): ChatConversation {
    this._messagesRepo.update(membersRepository);
    this._twilioChannel = twilioChannel;
    this._participantsRepository = membersRepository;
    this._chatConversationType = "full" as const;
    return this;
  }

  public async initialize(shouldInitializeChannel?: boolean): Promise<void> {
    if (this._chatConversationType === "dummy" || !this._twilioChannel)
      throw new Error("This method is unavailable for this type of conversation.");
    await this._twilioChannel.initialize();

    this._twilioChannel.channelEvent$.subscribe((event) => this.channelEventsHandler(event));
    this._messagesState$.next("loading");
    await this._twilioChannel
      .getMessages()
      .then(async (messagesPage) => {
        messagesPage.items.forEach((message) => this._messagesRepo.addMessage(message));
        if (messagesPage.hasNextPage) {
          let messages = await messagesPage.nextPage();
          messages.items.forEach((message) => this._messagesRepo.addMessage(message));
          while (messages.hasNextPage) {
            messages = await messages.nextPage();
            messages.items.forEach((message) => this._messagesRepo.addMessage(message));
          }
        }
      })
      .finally(() => this._messagesState$.next("loaded"))
      .catch(() => this._messagesState$.next("failed"));
  }

  public async requestArchivation(): Promise<void> {
    if (this._chatConversationType === "dummy" || !this._twilioChannel || !this.sid)
      throw new Error("This method is unavailable for this type of conversation.");
    try {
      await ChatApi.archiveChannel({
        sid: this.sid,
        messages: this.messages,
        channelAttributes: this.channelAttributes,
        friendlyName: this.friendlyName,
        interlocutorUid: this.interlocutor?.uid ?? null,
        lastMessageInstance: this.lastMessageInstance,
        lastMessageMeta: this.lastMessageMeta,
        unconsumedMessagesCount: 0,
      });
      await this.archive();
    } catch (err) {
      snackBarEventBus.$emit(snackBarEventName, { message: err.message || err, type: "error" });
    }
  }

  public async archive() {
    this._chatConversationType = "log";
    // this._twilioChannel = undefined;
  }

  public removeMember = (participant: Participant): void => {
    if (this._chatConversationType === "dummy")
      throw new Error("This property is unavailable for this type of conversation.");
    this._participantsRepository.removeParticipant(participant);
  };

  public async sendMessage(text: string, value: any, respondsTo: string, files?: File[]): Promise<void> {
    // if (!this._twilioChannel) throw new Error("This method is unavailable for this type of conversation.");
    await this._twilioChannel?.sendMessage(text, value, respondsTo, files);
  }

  public async consumeMessages(): Promise<void> {
    if (this._chatConversationType === "log") return;
    else if (this._chatConversationType === "dummy" || !this._twilioChannel)
      throw new Error("This method is unavailable for this type of conversation.");
    await this._twilioChannel?.consumeMessages();
  }

  public async typing(): Promise<void> {
    if (this._chatConversationType === "dummy" || !this._twilioChannel)
      throw new Error("This method is unavailable for this type of conversation.");
    await this._twilioChannel.typing();
  }

  private channelEventsHandler(event: TwilioConversationEvent) {
    switch (event.type) {
      case Conversation.messageAdded:
        return this.messageAddedHandler(event.payload as Message);
      case Conversation.messageUpdated:
        return this.messageUpdatedHandler(event.payload as MessageEvents);
      case Conversation.updated:
        return this.channelUpdatedHandler(event.payload as TwilioConversationUpdatePayload);
    }
  }

  private messageUpdatedHandler = async (payload: MessageEvents): Promise<void> => {
    if (!this._twilioChannel) return;
    if (payload.updateReasons.includes("attributes")) {
      this._messagesRepo.updateMessage(payload.message);
    }
  };

  private messageAddedHandler = async (message: Message): Promise<void> => {
    if (!this._twilioChannel) return;
    const attributes = message.attributes as TwilioChatMessageAttributes;
    this._messagesRepo.addMessage(message);

    if (message.author !== this.uid && !attributes.isHidden) {
      await this._twilioChannel.getUnconsumedMessagesCount().then((response: number | null) => {
        const newVal = response ?? 0;
        this._twilioChannel?.unconsumedMessagesCount.next(newVal);
      });
    }
    if (this.messages.length === 2 && message.author && this.sid && this.user.role === RolesEnum.Patient) {
      await ChatApi.notifyPractitioner(this.sid, message.author);
    }
  };

  private channelUpdatedHandler = async (event: TwilioConversationUpdatePayload): Promise<void> => {
    if (!this._twilioChannel || !this._participantsRepository) return;
    if (event.updateReasons.includes("attributes")) {
      const attributes = this._twilioChannel.attributes;
      this._participantsRepository.isTyping = attributes.isBotTyping;
    }
  };
}

export default ChatConversation;
