import { defineStore } from "pinia";

import { InvitesApi } from "@/api/invites";
import { Invite } from "@/types";

interface CareCoordinationState {
  isOpened: boolean;
  form: Partial<CareCoordinationForm>;
}

export interface CareCoordinationForm {
  email: string;
  faxNumber: string;
  lastName: string;
  subject: string;
  message: string;
  files: Record<any, any>[];
  method: CareCoordinationMethods;
}

export enum CareCoordinationMethods {
  fax = "fax",
  email = "email",
}

export const useCareCoordinationStore = defineStore({
  id: "care-coordination",
  state: () => ({
    form: {
      method: CareCoordinationMethods.fax,
      files: [],
      subject: "",
      message: "",
      faxNumber: "",
      email: "",
      recipientName: "",
    },
    isOpened: false,
  }),
  actions: {
    clearForm() {
      this.form = {
        method: CareCoordinationMethods.fax,
        files: [],
        subject: "",
        message: "",
        faxNumber: "",
        email: "",
        recipientName: "",
      };
      this.isOpened = false;
    },
    setFormField(payload: { field: string; value: string }) {
      this.form[payload.field] = payload.value;
    },
    async sendInvite(invite: Invite) {
      await InvitesApi.sendInvite(invite);
    },
    setIsOpened(payload: boolean) {
      this.isOpened = payload;
    },
  },
});
