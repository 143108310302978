<template>
  <div class="">
    <v-progress-linear v-if="loading" color="primary" indeterminate />
    <VisitNotesMedicalInfo :editable="editable" />
  </div>
</template>
<script>
import { mapActions, mapState } from "pinia";
import { mask } from "vue-the-mask";
import { required } from "vuelidate/lib/validators";

import { usePastVisitStore } from "@/pinia-store/pastVisit";
import { useVisitNoteStore } from "@/pinia-store/visitNote";

import VisitNotesMedicalInfo from "./MedicalInfo.vue";

export default {
  name: "VisitNotes",
  components: {
    VisitNotesMedicalInfo,
  },
  validations: {
    state: { required },
    firstName: { required },
    lastName: { required },
    phoneNumber: { required },
  },
  directives: {
    mask,
  },
  props: {
    loading: Boolean,
    buttonLoading: { default: false, type: Boolean },
    editable: { default: true, type: Boolean },
  },
  computed: {
    ...mapState(useVisitNoteStore, ["patientInfo", "disabledSigning", "observationsSummary"]),
    ...mapState(usePastVisitStore, ["getFile"]),
    isButtonDisabled() {
      return !this.encounterNotes.dx || !this.chiefComplaint;
    },
    firstName() {
      return this.patientInfo.firstName;
    },
    lastName() {
      return this.patientInfo.lastName;
    },
    state() {
      return this.patientInfo.state;
    },
    phoneNumber() {
      return this.patientInfo.phoneNumber;
    },

    firstNameErrors() {
      const errors = [];
      if (!this.$v.firstName.$dirty) return errors;
      !this.$v.firstName.required &&
        errors.push(this.$t("validation.required", { field: this.$t("general.inputs.firstName") }));
      return errors;
    },
    lastNameErrors() {
      const errors = [];
      if (!this.$v.lastName.$dirty) return errors;
      !this.$v.lastName.required &&
        errors.push(this.$t("validation.required", { field: this.$t("general.inputs.lastName") }));
      return errors;
    },
    phoneNumberErrors() {
      const errors = [];
      if (!this.$v.phoneNumber.$dirty) return errors;
      !this.$v.phoneNumber.required &&
        errors.push(this.$t("validation.required", { field: this.$t("general.inputs.phone") }));
      return errors;
    },
  },
  methods: {
    ...mapActions(useVisitNoteStore, [
      "savePatientInfo",
      "saveVisitNote",
      "clearPatientInfoFields",
      "saveVisitNote",
      "autosave",
    ]),
    ...mapActions(useVisitNoteStore, ["setPatientInfoField"]),
    sign() {
      this.$emit("sign");
      this.$emit("closeEditView");
    },
  },
};
</script>

<style lang="scss" scoped>
.button-continue {
  width: 326px;
  margin: 0 auto;
}

.adaptive-row {
  @media (max-width: 480px) {
    flex-direction: column;
  }

  &__genders {
    @media (max-width: 480px) {
      max-width: 100%;
    }
  }

  &__state {
    @media (max-width: 480px) {
      max-width: 60%;
    }
  }
}
</style>
