<template>
  <Layout :navTabs="navTabs" :quickActions="quickActions">
    <keep-alive include="practitionerChat">
      <router-view></router-view>
    </keep-alive>
    <VisitReminder />
    <MeetingReminder />
    <CreateMeetingRoomModal />
    <VoIP />
    <SendSMS />
    <MeetingRoomListDialog />
    <CareCoordination />
    <FloatingMeetingRoom v-if="openedMeetingRoom" />
    <SpeechToText />
  </Layout>
</template>
<script>
import { mapActions, mapState } from "pinia";

import chat from "@/assets/sounds/chat.mp3";
import CareCoordination from "@/components/CareCoordination/Index.vue";
import Layout from "@/components/Layout";
import SendSMS from "@/components/SendSMS/SendSMS.vue";
import SpeechToText from "@/components/SpeechToText/SpeechToText.vue";
import MeetingReminder from "@/components/VideoRoom/MeetingReminder";
import VisitReminder from "@/components/VisitReminder";
import VoIP from "@/components/VoIP/Index.vue";
import { snackBarEventBus, snackBarEventName } from "@/eventBuses/snackBar.eventBus";
import {
  WS_NOTIFICATION,
  WS_TASKS_CREATED,
  WS_TASKS_NOTIFY_WAITING_COUNT,
  WS_TASKS_UPDATED,
} from "@/eventBuses/socketEvents";
import { useAppointmentsStore } from "@/pinia-store/appointments";
import { useAuthStore } from "@/pinia-store/auth";
import { useChatStore } from "@/pinia-store/chat";
import { useEncountersStore } from "@/pinia-store/encounters";
import { useMeetingRoomStore } from "@/pinia-store/meetingRoom";
import { useNotificationsStore } from "@/pinia-store/notifications";
import { useProfileSettingsStore } from "@/pinia-store/profileSettings";
import { useTasksStore } from "@/pinia-store/tasks";
import { layoutPractitionerNavTabs, layoutPractitionerQuickActions } from "@/router/utils";
import { NotificationTypes } from "@/types/notificationTypes";
import { RolesEnum } from "@/types/Roles.enum";
import { audioVolume } from "@/utils/constants";
import CreateMeetingRoomModal from "@/views/Practitioner/MeetingRoom/Create/Modal";
import FloatingMeetingRoom from "@/views/Practitioner/MeetingRoom/FloatingMeetingRoom.vue";
import MeetingRoomListDialog from "@/views/Practitioner/MeetingRoom/List.vue";

export default {
  name: "practitionerView",
  components: {
    SpeechToText,
    CareCoordination,
    FloatingMeetingRoom,
    MeetingRoomListDialog,
    SendSMS,
    VoIP,
    CreateMeetingRoomModal,
    MeetingReminder,
    Layout,
    VisitReminder,
  },
  data() {
    return {
      audioPlayer: new Audio(chat),
      audioTimeout: null,
      unconsumedMessageCount: 0,
    };
  },
  inject: ["injectable"],
  computed: {
    ...mapState(useEncountersStore, ["plannedEncounters"]),
    ...mapState(useAppointmentsStore, ["practitionerTodayAppointmentsCount"]),
    ...mapState(useAuthStore, ["uid", "isLoggedIn"]),
    ...mapState(useProfileSettingsStore, ["practitionerSettings", "avatar"]),
    ...mapState(useMeetingRoomStore, ["openedMeetingRoom"]),
    navTabs() {
      return layoutPractitionerNavTabs(this.$i18n);
    },
    quickActions() {
      return layoutPractitionerQuickActions(this.$i18n);
    },
  },
  methods: {
    ...mapActions(useProfileSettingsStore, ["getPractitionerSettings"]),
    ...mapActions(useAppointmentsStore, ["getPractitionerTodayAppointmentsCount"]),
    ...mapActions(useEncountersStore, ["getPendedEncounters", "getPendedPendingEncounters"]),
    ...mapActions(useChatStore, ["setUnreadChannels"]),
    ...mapActions(useTasksStore, ["getTasks", "updateStateTask", "addTask"]),
    ...mapActions(useNotificationsStore, ["getNotifications", "addNotification"]),
  },
  beforeDestroy() {
    this.sockets.unsubscribe(WS_NOTIFICATION);
    this.sockets.unsubscribe(WS_TASKS_UPDATED);
    this.sockets.unsubscribe(WS_TASKS_CREATED);
    this.sockets.unsubscribe(WS_TASKS_NOTIFY_WAITING_COUNT);
    if (this.intervalUpdateCount) window.clearInterval(this.intervalUpdateCount);
  },
  watch: {
    isLoggedIn(val) {
      if (this.intervalUpdateCount && !val) window.clearInterval(this.intervalUpdateCount);
    },
  },
  async mounted() {
    this.audioPlayer.volume = audioVolume;
    await this.getPractitionerSettings();
    await this.getNotifications();
    this.sockets.subscribe(WS_NOTIFICATION, (item) => {
      if (item?.kind === NotificationTypes.APPOINTMENT_ADDED && item.content) {
        snackBarEventBus.$emit(snackBarEventName, { message: item.content, type: "success" });
      }
      this.audioPlayer.play();
      this.getNotifications();
    });
    this.$root.$i18n.locale = this.practitionerSettings.locale;

    this.$socket.emit("connected", { uid: this.uid, role: RolesEnum.Practitioner });

    this.injectable.twilioContext.unconsumedMessagesDeltaSum.subscribe(async (val) => {
      if (this.$router.currentRoute.path !== "/practitioner/chat" && val > 0 && this.unconsumedMessageCount !== val) {
        if (this.audioTimeout) clearTimeout(this.audioTimeout);
        this.audioTimeout = setTimeout(() => {
          this.audioPlayer.play();
        }, 500);
      }
      this.unconsumedMessageCount = val;
    });
    await Promise.all([
      this.getPendedPendingEncounters(),
      this.getPractitionerTodayAppointmentsCount(),
      this.getTasks(),
    ]);
    this.intervalUpdateCount = setInterval(() => {
      this.getPractitionerTodayAppointmentsCount();
    }, 3000);
    if (this.practitionerTodayAppointmentsCount) {
      this.audioPlayer.play();
    }
    this.sockets.subscribe(WS_TASKS_UPDATED, (task) => {
      this.updateStateTask(task);
    });
    this.sockets.subscribe(WS_TASKS_NOTIFY_WAITING_COUNT, ({ text }) => {
      snackBarEventBus.$emit(snackBarEventName, {
        message: text,
        type: "success",
      });
      this.audioPlayer.play();
    });
    this.sockets.subscribe(WS_TASKS_CREATED, (task) => {
      // todo: may need to add check for acceptTasks option
      this.audioPlayer.play();
      snackBarEventBus.$emit(snackBarEventName, {
        message: `Patient requested a ${task.businessStatus} ${
          task.valueCode === "phoneCall" ? "Phone" : "Video"
        } Call consult. PhoneNumber: ${task.phoneNumber}`,
        type: "success",
      });
      this.addTask(task);
    });
  },
};
</script>
