import { apiRequest } from "./apiRequest";

const getAll = async () => {
  const { data } = await apiRequest.get("/synonyms");
  return data;
};

const update = async (id: number, body: Record<string, any>) => {
  const { data } = await apiRequest.post(`/synonyms/${id}`, body);
  return data;
};
const bulkDelete = async ({ ids }: { ids: number[] }): Promise<boolean> => {
  const { data } = await apiRequest.delete(`/synonyms`, { params: { ids } });
  return data;
};
export const SynonymsApi = {
  getAll,
  update,
  bulkDelete,
};
