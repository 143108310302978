<template>
  <CliniciansList />
</template>
<script>
import CliniciansList from "@/components/Clinicians";
export default {
  name: "OwnerClinicianList",
  components: {
    CliniciansList,
  },
};
</script>
