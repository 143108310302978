<template>
  <v-dialog :value="dialog" width="400" @click:outside="closeHoursDialog">
    <v-card>
      <div class="dialog__head px-7 pt-7 d-flex justify-space-between align-center">
        <v-card-title class="dialog__head__title">{{ formatDate(start).format("MMMM D") }}</v-card-title>
        <v-icon class="pa-2" size="medium" @click="closeHoursDialog">mdi-close</v-icon>
      </div>
      <v-container class="px-7 pb-7">
        <p class="pa-0 my-3">Starts</p>
        <Select
          :items="getCurrentMonthDays"
          :value="formatDate(start).format('MMMM D, YYYY')"
          hide-details
          @change="onStartDateChange"
        />
        <p class="pa-0 my-3">What hours are you available</p>
        <v-list>
          <div>
            <v-list-item class="justify-space-between align-baseline">
              <p v-if="!availabilities.length" class="text--secondary mb-0 ml-7">Unavailable</p>
              <v-col class="pa-0">
                <div v-if="availabilities.length">
                  <div v-for="(availability, index) in availabilities" :key="index" class="d-flex align-center mb-3">
                    <v-autocomplete
                      :hide-details="true"
                      :items="options"
                      :value="formatAvailabilityHours(availability.start)"
                      append-icon=""
                      dense
                      full-width
                      outlined
                      @change="onAvailableHoursChange($event, 'start', index)"
                    ></v-autocomplete>
                    <span class="mx-3"><v-icon small>mdi-minus</v-icon></span>
                    <v-autocomplete
                      :hide-details="true"
                      :items="options"
                      :value="formatAvailabilityHours(availability.end)"
                      append-icon=""
                      dense
                      full-width
                      outlined
                      type="text"
                      @change="onAvailableHoursChange($event, 'end', index)"
                    ></v-autocomplete>
                    <v-btn class="ml-4" fab small text @click="deleteAvailability(index)">
                      <v-icon>mdi-trash-can-outline</v-icon>
                    </v-btn>
                  </div>
                </div>
              </v-col>
              <v-btn fab small text @click="onPlusButtonClick()">
                <v-icon small>mdi-plus</v-icon>
              </v-btn>
            </v-list-item>
          </div>
        </v-list>
        <p class="pa-0 my-3">Repeat on</p>
        <Select :items="repeatItems" :value="frequencyType" hide-details @change="onRepeatChange" />
        <v-chip-group v-if="frequencyType === 'weekdays'" class="weekdays-chip-group">
          <v-chip
            v-for="index in weekDays"
            :key="index"
            :color="repeatWeekDays.includes(index) ? 'primary' : ''"
            @click="onRepeatDayClick(index)"
          >
            {{ getWeekDay(index) }}
          </v-chip>
        </v-chip-group>
      </v-container>
      <v-divider></v-divider>
      <v-card-actions class="justify-end px-7 py-5">
        <div>
          <OutlinedButton
            :text="$t('general.dialog.cancel')"
            additional-class-name="pa-5"
            @onClick="closeHoursDialog"
          />
          <PrimaryButton
            :disabled="isDisabledSaveButton"
            :text="$t('settings.profile.save')"
            class-name="primary-btn__blue px-7 py-5 ml-3"
            color="primary"
            @onClick="onNewHoursSave"
          />
        </div>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { DateTime } from "luxon";
import moment from "moment-timezone";
import { mapActions, mapState } from "pinia";

import OutlinedButton from "@/components/uikit/OutlinedButton";
import PrimaryButton from "@/components/uikit/PrimaryButton";
import Select from "@/components/uikit/Select";
import { useSlotsStore } from "@/pinia-store/slots";
import { availabilityHours } from "@/utils/availabilityHours";

export default {
  name: "NewHours",
  components: {
    Select,
    OutlinedButton,
    PrimaryButton,
  },
  props: ["dialog", "start", "closeDialog"],
  data: () => ({
    options: availabilityHours(),
    repeatItems: [
      { value: "none", text: "Never" },
      { value: "weekdays", text: "Every week" },
    ],
    showRepeatWeekdays: false,
    frequencyType: "none",
    repeatWeekDays: [],
    weekDays: [1, 2, 3, 4, 5, 6, 7],
    deleteModal: false,
    changedStartDate: null,
    availabilities: [],
  }),
  computed: {
    ...mapState(useSlotsStore, ["customAvailabilities", "customInitialAvailabilities"]),
    getCurrentMonthDays() {
      const firstDayOfMonth = moment(this.start).startOf("month").format("YYYY-MM-DD");
      const daysInMonth = moment(this.start).startOf("month").daysInMonth();
      const monthDays = [];
      for (let i = 0; i < daysInMonth; i++) {
        monthDays.push(moment(firstDayOfMonth).add(i, "days").format("MMMM D, YYYY"));
      }
      return monthDays;
    },
    isDisabledSaveButton() {
      if (this.availabilities.length) {
        let result = false;
        let length = this.availabilities.length;
        for (let i = 0; i < length; i++) {
          if (!this.availabilities[i].start || !this.availabilities[i].end) {
            result = true;
            break;
          }
        }
        return result;
      } else return true;
    },
  },
  methods: {
    ...mapActions(useSlotsStore, ["setAvailabilities"]),
    ...mapActions(useSlotsStore, ["setAvailabilities", "setCustomAvailabilities"]),
    ...mapActions(useSlotsStore, ["getPractitionerAvailability"]),
    onRepeatChange(e) {
      this.frequencyType = e;
    },
    getWeekDay(number) {
      return moment().day(number).format("dd")[0];
    },
    onRepeatDayClick(index) {
      if (this.repeatWeekDays.includes(index)) this.repeatWeekDays = this.repeatWeekDays.filter((i) => i !== index);
      else this.repeatWeekDays.push(index);
    },
    onStartDateChange(e) {
      this.changedStartDate = e;
    },
    formatDate(date = undefined) {
      return moment(date);
    },
    closeHoursDialog() {
      this.changedStartDate = null;
      this.availabilities = [];
      this.closeDialog();
    },
    onNewHoursSave() {
      // let repeatDays = [];
      const body = {
        date: this.start,
        frequencyType: this.frequencyType,
        frequencyConfig: this.frequencyType === "weekdays" ? { days: this.repeatWeekDays } : {},
        hours: this.availabilities,
      };

      this.customAvailabilities.push(body);
      this.setCustomAvailabilities(this.customAvailabilities);
      this.availabilities = [];
      this.frequencyType = "none";
      this.repeatWeekDays = [];
      this.closeHoursDialog();
    },
    closeDeleteModal() {
      this.deleteModal = false;
    },
    formatAvailabilityHours(datetime) {
      return DateTime.fromISO(datetime).toFormat("HH:mm");
    },
    onPlusButtonClick() {
      this.availabilities.push({
        date: this.start,
        start: "",
        end: "",
        repeat: [],
      });
    },
    onAvailableHoursChange(e, type, index) {
      if (type === "start") {
        this.availabilities[index].start = e;
      } else {
        this.availabilities[index].end = e;
      }
    },
    deleteAvailability(index) {
      if (this.availabilities.length === 1) {
        this.availabilities = [];
      }
      this.availabilities.splice(index, 1);
    },
  },
};
</script>

<style lang="scss">
.weekdays-chip-group {
  .v-slide-group__content {
    justify-content: space-between;
  }
}
</style>
