import { defineStore } from "pinia";

import { ConditionsApi } from "@/api/conditions";

interface ConditionsState {
  search?: Record<any, any>[];
  isLoadingPatientConditions: boolean;
  patientConditions: Record<any, any>[];
  patientId: string;
}

export const useConditionsStore = defineStore({
  id: "conditions",
  state: (): ConditionsState => ({
    search: [],
    isLoadingPatientConditions: false,
    patientConditions: [],
    patientId: "",
  }),

  actions: {
    setPatientId(payload?: string) {
      this.patientId = payload;
    },
    setConditionsPatientId(payload?: string) {
      this.patientId = payload;
    },
    async getPatientConditions(payload?: string) {
      this.isLoadingPatientConditions = true;
      if ((this.patientId && this.patientId?.length) || payload?.length) {
        this.patientConditions = await ConditionsApi.getPatientConditions(payload || this.patientId);
      }
      this.isLoadingPatientConditions = false;
    },
    async addPatientCondition(payload?: Record<any, any>) {
      const allergy = await ConditionsApi.add(payload, this.patientId);
      this.patientConditions.push(allergy);
    },
    async removePatientCondition(payload: string) {
      await ConditionsApi.destroy(payload, this.patientId);
      this.patientConditions = this.patientConditions.filter((i: { id: string }) => i.id !== payload);
    },
  },
});
