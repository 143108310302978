import { defineStore } from "pinia";

import { ITwoFactorBody, TwoFactorApi } from "@/api/twoFactor";

interface Twofactor {
  qrcode: string;
  code: number;
  success: boolean;
}

export const useTwoFactorStore = defineStore({
  id: "twofactor",
  state: (): Twofactor => ({
    qrcode: "",
    code: 0,
    success: false,
  }),

  actions: {
    setQrcode(payload: string) {
      this.qrcode = payload;
    },
    successVerify(payload: boolean) {
      this.success = payload;
    },
    async getQrcode() {
      this.qrcode = await TwoFactorApi.getQrcode();
    },
    async sendVerifynumbers(body: ITwoFactorBody) {
      this.success = await TwoFactorApi.sendVerifynumbers(body);
    },
  },
  getters: {
    getQrcod(state) {
      return state.qrcode;
    },
    getSucces(state) {
      return state.success;
    },
  },
});
