import { defineStore } from "pinia";

export interface BloodGlucoseOrderState {
  formOpened: boolean;
  form: Record<string, any>;
}

export const useBloodGlucoseOrderStore = defineStore({
  id: "blood-glucose-order",
  state: () => ({
    formOpened: false,
    form: {},
  }),
});
