<template>
  <v-btn class="settings-btn" fab small @click="showSetting">
    <v-icon color="white" dark>mdi-cog</v-icon>
    <v-dialog
      v-model="dialog"
      class="video-room-settings-dialog"
      dark
      hide-overlay
      max-width="90%"
      transition="dialog-bottom-transition"
      width="340px"
    >
      <v-card>
        <v-card-title>
          <v-spacer></v-spacer>
          <v-btn dark icon @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col class="pt-0 pb-0 input-group" cols="12">
                <span class="input-group-title">{{ $t("settings.video.videoSource") }}</span>
                <Select
                  v-model="selectedVideoInput"
                  :items="videoInputDevices"
                  :label="$t('settings.video.videoSource')"
                  item-text="label"
                  item-value="deviceId"
                />
              </v-col>

              <v-col class="pt-0 pb-0 input-group" cols="12">
                <span class="input-group-title">{{ $t("settings.video.audioOutput") }}</span>
                <Select
                  v-model="selectedAudioOutput"
                  :items="audioOutputDevices"
                  :label="$t('settings.video.audioOutput')"
                  item-text="label"
                  item-value="deviceId"
                />
              </v-col>

              <v-col class="pt-0 pb-0 input-group" cols="12">
                <span class="input-group-title">{{ $t("settings.video.audioInput") }}</span>
                <Select
                  v-model="selectedAudioInput"
                  :items="audioInputDevices"
                  :label="$t('settings.video.audioInput')"
                  item-text="label"
                  item-value="deviceId"
                />
              </v-col>

              <v-col class="pt-0 pb-0 input-group" cols="12">
                <PrimaryButton :fullWidth="true" :text="$t('settings.video.button')" @onClick="save" />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-btn>
</template>
<script>
import { mapActions, mapState } from "pinia";

import PrimaryButton from "@/components/uikit/PrimaryButton";
import Select from "@/components/uikit/Select";
import { useVideoRoomStore } from "@/pinia-store/videoRoom";

export default {
  name: "SettingsBtn",
  components: {
    Select,
    PrimaryButton,
  },
  data() {
    return {
      audioOutputDevices: [],
      audioInputDevices: [],
      videoInputDevices: [],
      dialog: false,
    };
  },
  computed: {
    ...mapState(useVideoRoomStore, ["settings"]),
    selectedVideoInput: {
      get() {
        return this.settings && this.settings.videoInput;
      },
      set(value) {
        this.setRoomSettings({
          videoInput: value,
          audioInput: this.settings && this.settings.audioInput,
          audioOutput: this.settings && this.settings.audioOutput,
        });
        this.$emit("onChangeVideoInput");
      },
    },
    selectedAudioInput: {
      get() {
        return this.settings && this.settings.audioInput;
      },
      set(value) {
        this.setRoomSettings({
          videoInput: this.settings && this.settings.videoInput,
          audioInput: value,
          audioOutput: this.settings && this.settings.audioOutput,
        });
        this.$emit("onChangeAudioInput");
      },
    },
    selectedAudioOutput: {
      get() {
        return this.settings && this.settings.audioOutput;
      },
      set(value) {
        this.setRoomSettings({
          videoInput: this.settings && this.settings.videoInput,
          audioInput: this.settings && this.settings.audioInput,
          audioOutput: value,
        });
        this.$emit("onChangeAudioOutput");
      },
    },
  },
  methods: {
    ...mapActions(useVideoRoomStore, ["setDefaultRoomSettings"]),
    ...mapActions(useVideoRoomStore, ["setRoomSettings"]),

    async updateDevices() {
      const devices = await navigator.mediaDevices.enumerateDevices();
      this.videoInputDevices = devices.filter((device) => device.kind === "videoinput");
      this.audioInputDevices = devices.filter((device) => device.kind === "audioinput");
      this.audioOutputDevices = devices.filter((device) => device.kind === "audiooutput");
    },
    showSetting() {
      this.dialog = true;
    },
    save() {
      this.setRoomSettings({
        videoInput: this.settings && this.settings.videoInput,
        audioInput: this.settings && this.settings.audioInput,
        audioOutput: this.settings && this.settings.audioOutput,
      });
      this.$emit("onChangeVideoInput");
      this.$emit("onChangeAudioOutput");
      this.$emit("onChangeAudioInput");
      this.dialog = false;
    },
  },
  async mounted() {
    await this.updateDevices();
    await this.setDefaultRoomSettings();
  },
};
</script>
<style lang="scss" scoped>
:deep .video-room-settings-dialog {
}

.settings-btn {
  position: absolute;
  top: 100px !important;
  right: 20px;
  z-index: 2;
  background: transparent !important;
  //background: rgba(15, 15, 15, 0.36);
  width: 45px;
  height: 45px;
  border: 1px solid #666769;

  :deep i {
    font-size: 18px;
  }
}

.input-group-title {
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 10px;
  display: flex;
  color: $white;
}

h1 {
  font-weight: normal;
  font-size: 25px;
  line-height: 30px;
  color: $white;
  margin-bottom: 24px;
}
</style>
