import { defineStore } from "pinia";

import { InvitesApi } from "@/api/invites";
import { Invite } from "@/types";
import { InviteQueryFilterDto } from "@/types/InviteQueryFilter";

interface DerivedInvite {
  id: string;
  email?: string;
  phoneNumber?: string;
  firstName: string;
  lastName: string;
}

interface InvitesState {
  invite: DerivedInvite | null;
  invites: DerivedInvite[];
  invitePin: string;
  activationCode: string;
}

export const useInvitesStore = defineStore({
  id: "invites",
  state: (): InvitesState => ({
    invite: {
      id: "",
      email: "",
      phoneNumber: "",
      firstName: "",
      lastName: "",
    },
    invites: [],
    invitePin: "",
    activationCode: "",
  }),

  actions: {
    setInvitePin(pin: string) {
      this.invitePin = pin;
    },
    setActivationCode(code: string) {
      this.activationCode = code;
    },
    async sendInvite(invite: Invite) {
      await InvitesApi.sendInvite(invite);
    },
    async importPatients(file: string | Blob) {
      const data = new FormData();
      data.append("file", file);
      return await InvitesApi.importPatients(data);
    },
    async getInvite(id: string) {
      const invite = await InvitesApi.getInvite(id);
      if (!invite) {
        throw new Error("Invite doesn't exist");
      }
      this.invite = invite;
    },
    async getAllInvites(query?: InviteQueryFilterDto) {
      this.invites = await InvitesApi.getAll(query);
    },
    async deleteInvitation(id: string) {
      await InvitesApi.deleteById(id);
      this.invites = this.invites.filter((inv: { id: any }) => inv.id !== id);
    },
    async resendInvite(invite: Invite) {
      await InvitesApi.sendInvite(invite);
    },
    async getActivationCode(invitePin: string) {
      this.activationCode = await InvitesApi.getActivationCode(invitePin);
    },
  },
});
