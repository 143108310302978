<template>
  <div>
    <div>
      <v-progress-linear v-if="loading || isLoading" color="primary" indeterminate />
      <div v-if="!todayItems.length" class="ap-list_no-items">
        <img alt="No Items" src="@/assets/no-items.svg" />
        <p class="heading-4">There is no appointments to display here</p>
        <p class="sub-heading-3">Book a visit with a patient first.</p>
        <OutlinedButton
          color="primary"
          style="color: #2196f3; opacity: 1; border: 1px solid #2196f3"
          text="Schedule a New Visit"
          @onClick="$emit('toSchedule')"
        />
      </div>
      <div class="ap-list">
        <div v-for="(item, key) in todayItems" :key="key" class="ap-list">
          <h1 class="ap-list_wrapTitle">{{ item.date }}</h1>
          <AppointmentsListItem
            v-for="encounter in item.items"
            :key="encounter.id"
            :item="encounter"
            place="upcoming"
            role="practitioner"
            @onButtonClick="joinRoom"
            @onCancel="onCancel"
          />
        </div>
      </div>
      <CancelVisit v-model="dialog" :appointmentId="cancelledId" @success="getPractitionerUpcomingEncounters" />
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "pinia";

import AppointmentsListItem from "@/components/AppointmentsListItem";
import CancelVisit from "@/components/CancelVisit";
import OutlinedButton from "@/components/uikit/OutlinedButton";
import { todaySchedulePractitionerMixin } from "@/mixins/todaySchedulePractitioner";
import { useAuthStore } from "@/pinia-store/auth";
import { useEncountersStore } from "@/pinia-store/encounters";
import { usePatientsStore } from "@/pinia-store/patients";

export default {
  name: "Today",
  props: ["isLoading", "getListScheduledEncounters", "isSwitchTab"],

  components: {
    AppointmentsListItem,
    CancelVisit,
    OutlinedButton,
  },
  mixins: [todaySchedulePractitionerMixin],
  computed: {
    ...mapState(useEncountersStore, ["upcomingTabFilter"]),
    ...mapState(useAuthStore, ["uid"]),
    ...mapState(usePatientsStore, ["patients"]),
    ...mapState(useAuthStore, ["timeZone"]),
  },
  methods: {
    ...mapActions(useEncountersStore, ["setUpcomingTabFilter"]),
    ...mapActions(useEncountersStore, ["getPractitionerUpcomingEncounters"]),
  },
  async mounted() {
    await this.getTodayListEncounters();
  },
};
</script>

<style lang="scss" scoped>
.container-filters {
  display: grid;
  grid-gap: 18px;
  grid-template-columns: repeat(auto-fit, minmax(min-content, 320px));
}
</style>
