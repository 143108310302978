<template>
  <v-card width="500">
    <div class="expire-error_section1">
      <img src="../../../assets/warning-circle.svg" />
      <div v-if="this.errorType === 'conflict'">
        <p class="heading-4 expire-error__margin">Sorry, we couldn't complete your request at this time</p>
        <p class="expire-error_sub-title">Please check your visit details and try again.</p>
        <div class="expire-error_divider mx-auto"></div>
        <p class="expire-error_sub-title">
          If you continue to encounter the problems, feel free to
          <router-link class="text-decoration-none" to="/support">Contact us</router-link>
        </p>
      </div>
      <div v-else-if="this.errorType === 'completed'">
        <p class="heading-4 expire-error__margin">The visit has been already confirmed</p>
      </div>
      <div v-else>
        <p class="heading-4 expire-error__margin">Session expired</p>
        <p class="expire-error_sub-title">Please choose your visit details again</p>
      </div>
    </div>
    <div class="expire-error_section2">
      <OutlinedButton
        :fullWidth="true"
        :text="$t('visits.expire.button')"
        additionalClassName="outlined-btn__blue"
        size="large"
        @onClick="toVisitCenter"
      />
    </div>
  </v-card>
</template>
<script>
import { mapActions, mapState } from "pinia";

import OutlinedButton from "@/components/uikit/OutlinedButton";
import { useAuthStore } from "@/pinia-store/auth";
import { usePatientVisitScheduleStore } from "@/pinia-store/patientVisitSchedule";
import { RolesEnum } from "@/types/Roles.enum";

export default {
  name: "ExpireError",
  components: {
    OutlinedButton,
  },
  computed: {
    ...mapState(usePatientVisitScheduleStore, ["errorType"]),
    ...mapState(useAuthStore, ["role"]),
  },
  methods: {
    ...mapActions(usePatientVisitScheduleStore, ["cleanUp"]),
    async toVisitCenter() {
      switch (this.role) {
        case RolesEnum.Owner:
          await this.$router.push("/owner/appointments/scheduled");
          break;
        case RolesEnum.Practitioner:
          await this.$router.push("/practitioner/appointments/scheduled");
          break;
        case RolesEnum.Patient:
          await this.$router.push("/patient/visits/upcoming");
          break;
        case RolesEnum.Admin:
          await this.$router.push("/admin/appointments/scheduled");
          break;
        default:
          await this.$router.push("/");
      }
    },
  },
  beforeDestroy() {
    this.cleanUp();
  },
};
</script>
