import { apiRequest } from "@/api/apiRequest";
import { TaskDto, ValueSetTaskStatusEnum } from "@/pinia-store/interfaces/Task";

export enum TaskOwnerEnum {
  practitioner = "practitioner",
}

export enum TaskBusinessStatusEnum {
  pediatric = "pediatric",
  adult = "adult",
}

export type TaskFilterType = {
  ownerId?: string;
  authoredOn?: string | [string, string];
  ownerRole: TaskOwnerEnum;
  businessStatus: TaskBusinessStatusEnum[];
  status: ValueSetTaskStatusEnum;
};

const addNote = async (id: string, note: string) => {
  const { data } = await apiRequest.post(`/task/${id}/note`, { note });
  return data;
};
const update = async (id: string, body: Partial<TaskDto>) => {
  const { data } = await apiRequest.put(`/task/${id}`, body);
  return data;
};
const create = async (body: Record<any, any>) => {
  const { data } = await apiRequest.post(`/task`, body);
  return data;
};
const find = async (id: string) => {
  const { data } = await apiRequest.get(`/task/${id}`);
  return data;
};
const search = async (filter = {}) => {
  const { data } = await apiRequest.get(`/task`, { params: filter });
  return data;
};
export const TaskApi = {
  find,
  search,
  create,
  update,
  addNote,
};
