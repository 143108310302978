import { Availabilities } from "@/types/Availability";
import PractitionerResponseDto from "@/types/PractitionerResponseDto";

import { apiRequest } from "./apiRequest";

interface PractitionerUpdateSettingsBody {
  timeZone: string;
  availabilitySlots: Availabilities | undefined;
  locale: string;
  customAvailabilitySlots: Availabilities | undefined;
  clinicalRole: string;
  isAutoTimeZone: boolean;
  acceptTasks: boolean;
  acceptPediatricTasks: boolean;
  acceptAdultTasks: boolean;
  isAutoAccept: boolean;
  workDays?: number[];
  dosespotId?: number;
  availableToOtherPatients?: boolean;
}

interface PractitionerUpdateProfileBody {
  name: {
    lastName: string;
    firstName: string;
  };
  qualification: [];
}

const practitionerUpdateSettings = async (practitionerId: string, body: Partial<PractitionerUpdateSettingsBody>) => {
  const { data } = await apiRequest.put(`/practitioner/${practitionerId}/settings`, body);
  return data;
};

const create = async (body: Partial<PractitionerUpdateSettingsBody>) => {
  return new Promise((resolve, reject) => {
    apiRequest
      .post(`/practitioner`, body)
      .then((r) => resolve(r.data))
      .catch((err) => reject(err?.response?.data));
  });
};

const practitionerGetSettings = async (practitionerId: string) => {
  const { data } = await apiRequest.get(`/practitioner/${practitionerId}/settings`);
  return data;
};

const getPractitionerById = async (practitionerId: string): Promise<PractitionerResponseDto> => {
  const { data } = await apiRequest.get(`/practitioner/${practitionerId}`);
  return data;
};

const updateProfile = async (practitionerId: string, body: PractitionerUpdateProfileBody) => {
  const { data } = await apiRequest.put(`/practitioner/${practitionerId}`, body);
  return data;
};

const avatarUpload = async (practitionerId: string, formData: FormData) => {
  const { data } = await apiRequest.post(`/practitioner/${practitionerId}/avatar`, formData, {
    headers: { "Content-Type": "multipart/form-data" },
  });
  return data;
};

const getAllPractitioners = async () => {
  const { data } = await apiRequest.get(`/practitioner/slots`);
  return data;
};

const getPractitioners = async () => {
  const { data } = await apiRequest.get("/practitioner");
  return data;
};

const updateRegistrar = async (practitionerId: string, registrarId: string) => {
  const { data } = await apiRequest.post(`/practitioner/${practitionerId}/registrar`, {
    registrarId,
  });
  return data;
};

const removeAssignedRegistrar = async (practitionerId: string, registrarId: string) => {
  const { data } = await apiRequest.delete(`/practitioner/${practitionerId}/registrar/${registrarId}`);
  return data;
};

export const PractitionersAPI = {
  create,
  practitionerUpdateSettings,
  practitionerGetSettings,
  getPractitionerById,
  updateProfile,
  avatarUpload,
  getAllPractitioners,
  getPractitioners,
  updateRegistrar,
  removeAssignedRegistrar,
};
