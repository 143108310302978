<template>
  <Confirm
    :dialog="oxygenSaturationTableOpened"
    :hide-cancel="true"
    :title="title"
    confirm-text="Close"
    maxWidth="90%"
    rootClass="monitoring-drawer"
    text=""
    width="800px"
    @confirm="toggleOxygenSaturationTableOpened"
    @toggleDialog="toggleOxygenSaturationTableOpened"
  >
    <v-progress-linear v-if="loading" color="primary" indeterminate></v-progress-linear>
    <OxygenSaturationTable :disable-edit="disableEdit" />
  </Confirm>
</template>

<script>
import { mapActions, mapState } from "pinia";

import Confirm from "@/components/shared/Confirm";
import { useObservationsStore } from "@/pinia-store/observations";
import OxygenSaturationTable from "@/views/Patient/Monitoring/oxygenSaturation/Table";

export default {
  name: "DrawerOxygenSaturation",
  components: { OxygenSaturationTable, Confirm },
  props: {
    disableEdit: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      title: "Oxygen Saturation",
      loading: true,
    };
  },
  watch: {
    "$store.state.observations.oxygenSaturationTableOpened": {
      immediate: true,
      async handler(val) {
        if (val) {
          this.loading = true;
          await this.getOxygenSaturation();
          this.loading = false;
        }
      },
    },
  },
  computed: {
    ...mapState(useObservationsStore, ["oxygenSaturationTableOpened"]),
  },
  methods: {
    ...mapActions(useObservationsStore, ["toggleOxygenSaturationTableOpened"]),
    ...mapActions(useObservationsStore, ["getOxygenSaturation"]),
  },
};
</script>
<style lang="scss">
@import "../monitoring-drawer";
</style>
