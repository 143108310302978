<template>
  <v-overlay :value="true" absolute>
    <v-row justify="center" class="icons">
      <v-icon color="white"> fa-microphone-slash </v-icon>
      <v-icon color="white"> fa-video-slash </v-icon>
    </v-row>
    <v-row justify="center" class="title-text">
      <span>{{ $t("visits.access.title") }}</span>
    </v-row>
    <v-row justify="center" class="helper-text">
      <span>{{ $t("visits.access.hint") }}</span>
    </v-row>
    <v-row justify="center" class="helper-text">
      <span>{{ $t("visits.access.hint2") }}</span>
    </v-row>
  </v-overlay>
</template>
<script>
export default {
  name: "AllowAccess",
};
</script>
<style lang="scss" scoped>
.icons {
  :first-child {
    margin-right: 10px;
  }
}
.title-text {
  margin: 20px 0 10px 0;
  text-align: center;
  width: 100%;
  span {
    font-style: normal;
    font-weight: normal;
    font-size: 1.3rem;
    line-height: 24px;
  }
}
.helper-text {
  text-align: center;
  span {
    font-style: normal;
    font-weight: normal;
    font-size: 0.8em;
    line-height: 16px;
    color: $white;
    opacity: 0.56;
  }
  @media (max-width: 480px) {
    width: 100%;
  }
}
</style>
