export const BLOOD_PRESSURE_ADDED = "BLOOD_PRESSURE_ADDED";
export const BODY_TEMPERATURE_ADDED = "BODY_TEMPERATURE_ADDED";
export const BODY_WEIGHT_ADDED = "BODY_WEIGHT_ADDED";
export const BODY_WEIGHT_REMOVED = "BODY_WEIGHT_REMOVED";
export const BODY_HEIGHT_ADDED = "BODY_HEIGHT_ADDED";
export const BODY_HEIGHT_REMOVED = "BODY_HEIGHT_REMOVED";
export const SMOKING_BEHAVIOR_ADDED = "SMOKING_BEHAVIOR_ADDED";
export const SMOKING_BEHAVIOR_REMOVED = "SMOKING_BEHAVIOR_REMOVED";
export const HEART_RATE_ADDED = "HEART_RATE_ADDED";
export const OXYGEN_SATURATION_ADDED = "OXYGEN_SATURATION_ADDED";
export const BLOOD_GLUCOSE_ADDED = "BLOOD_GLUCOSE_ADDED";
export const RESPIRATORY_RATE_ADDED = "RESPIRATORY_RATE_ADDED";
export const BLOOD_PRESSURE_ORDER_ADDED = "BLOOD_PRESSURE_ORDER_ADDED";
export const BLOOD_GLUCOSE_ORDER_ADDED = "BLOOD_GLUCOSE_ORDER_ADDED";
export const BODY_TEMPERATURE_ORDER_ADDED = "BODY_TEMPERATURE_ORDER_ADDED";
export const RESPIRATORY_RATE_ORDER_ADDED = "RESPIRATORY_RATE_ORDER_ADDED";
export const HEART_RATE_ORDER_ADDED = "HEART_RATE_ORDER_ADDED";
export const OXYGEN_SATURATION_ORDER_ADDED = "OXYGEN_SATURATION_ORDER_ADDED";

export const OBSERVATION_ORDERS_ANY_ADDED = [
  BLOOD_PRESSURE_ORDER_ADDED,
  BLOOD_GLUCOSE_ORDER_ADDED,
  BODY_TEMPERATURE_ORDER_ADDED,
  RESPIRATORY_RATE_ORDER_ADDED,
  HEART_RATE_ORDER_ADDED,
  OXYGEN_SATURATION_ORDER_ADDED,
];
export const OBSERVATION_ANY_ADDED = [
  BLOOD_PRESSURE_ADDED,
  BODY_TEMPERATURE_ADDED,
  BODY_WEIGHT_ADDED,
  BODY_HEIGHT_ADDED,
  SMOKING_BEHAVIOR_ADDED,
  HEART_RATE_ADDED,
  OXYGEN_SATURATION_ADDED,
  BLOOD_GLUCOSE_ADDED,
  RESPIRATORY_RATE_ADDED,
];
