<template>
  <v-expansion-panels v-model="panel" accordion class="encounter-visit-history" flat>
    <v-expansion-panel>
      <v-expansion-panel-header>
        <span class="visit-notes_card_header_title">
          <img :src="require('@/assets/medication.svg')" alt="Medication reconciliation" class="title-icon" />
          Medication reconciliation
        </span>
      </v-expansion-panel-header>
      <v-expansion-panel-content class="section-two">
        <div v-if="editable" class="py-5">
          <PharmacyField />
        </div>
        <div v-if="editable" class="py-5">
          <MedicationSearch @onSelect="addNewMedication" />
        </div>
        <div v-for="item in medicationStatement" :key="item.id" class="">
          <MedicationField :item="item" @onChangeStatus="(e) => onChangeStatus(e, item.id)" />
        </div>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>
<script>
import { mapActions, mapState } from "pinia";

import { MedicationApi } from "@/api/medication";
import MedicationSearch from "@/components/Medications/MedicationSearch";
import PharmacyField from "@/components/VisitNotes/PharmacyField";
import { useAutoSaveStore } from "@/pinia-store/autoSave";
import { useVisitNoteStore } from "@/pinia-store/visitNote";

import MedicationField from "./MedicationField";

export default {
  name: "MedicationReconciliation",
  components: { PharmacyField, MedicationSearch, MedicationField },
  data: () => ({
    medicalTab: 0,
    panel: 1,
  }),
  props: {
    editable: {
      default: true,
      type: Boolean,
    },
  },
  methods: {
    ...mapActions(useVisitNoteStore, ["getMedicationStatement"]),
    ...mapActions(useAutoSaveStore, ["startSave", "endSave"]),
    async onChangeStatus(status, medicationId) {
      this.startSave();
      await MedicationApi.patch(medicationId, { status });
      await this.getMedicationStatement();
      this.endSave();
    },
    async addNewMedication(item) {
      this.startSave();
      await MedicationApi.add({
        ...item,
        informationSourceId: this.patientInfo.id,
        informationSource: "patient",
        subjectId: this.patientInfo.id,
        subject: "patient",
        context: "encounter",
        contextId: this.encounter.id,
      });
      this.endSave();
      await this.getMedicationStatement();
    },
  },
  computed: {
    ...mapState(useVisitNoteStore, ["medicationStatement", "otherInfo", "pharmacy", "patientInfo", "encounter"]),
  },
  mounted() {
    this.panel = this.$vuetify.breakpoint.smAndUp ? 0 : 1;
    if (this.otherInfo?.encounterId) this.getMedicationStatement();
    const visitNoteStore = useVisitNoteStore();
    visitNoteStore.$subscribe((state) => {
      if (state.pharmacy?.name) this.pharmacyName = state.pharmacy.name;
      if (state.encounter?.id) this.getMedicationStatement();
    });
  },
};
</script>

<style scoped></style>
