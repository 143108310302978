export enum EncounterStatusEnum {
  "pending" = "pending",
  "planned" = "planned",
  "triaged" = "triaged", // status for pending summary
  "in-progress" = "in-progress",
  "finished" = "finished", // status when summary is signed
  "cancelled" = "cancelled",
  //when is pended, practitioner will return later to finish
  "onleave" = "onleave",
}
