<template>
  <div>
    <Chat class="chat-container" @action="action" :class="{ 'chat-container-mobile': $vuetify.breakpoint.xsOnly }" />
    <v-dialog v-model="dialog" max-width="463" :fullscreen="$vuetify.breakpoint.xsOnly" persistent>
      <StartNewConversation v-if="dialog" @close="dialog = false" />
    </v-dialog>
  </div>
</template>

<script>
import Chat from "@/components/Chat/Index.vue";
import StartNewConversation from "@/components/Chat/StartNewConversation";

export default {
  name: "PractitionerChat",
  components: {
    Chat,
    StartNewConversation,
  },
  data() {
    return {
      dialog: false,
    };
  },
  methods: {
    action(val) {
      if (this[val]) {
        this[val]();
      }
    },
    startConversation() {
      this.dialog = true;
    },
  },
};
</script>
