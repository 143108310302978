import PastVisit from "@/components/AddPastVisit/PastVisit.vue";
import PractitionerEncounterMedicationOrder from "@/components/VisitNotes/medicationOrder/PractitionerEncounterMedicationOrder.vue";
import PractitionerEncounterMedicationReview from "@/components/VisitNotes/PractitionerEncounterMedicationReview.vue";
import { linkToPractitionerMeetingRoom, linkToPractitionerPendedNotes } from "@/router/linkGenerator";
import { routesEnum } from "@/types/Routes.enum";
import MiddleComponent from "@/views/MiddleComponent.vue";
import EditAvailability from "@/views/Practitioner/Appoinments/EditAvailability.vue";
import ScheduleAppointment from "@/views/Practitioner/Appoinments/Schedule.vue";
import ScheduledAppointments from "@/views/Practitioner/Appoinments/ScheduledAppointments.vue";
import AppointmentsView from "@/views/Practitioner/Appoinments/View.vue";
import MedicalRecordPatientDependents from "@/views/Practitioner/MedicalRecord/PatientDependents/Index.vue";
import MedicalRecordPatientFiles from "@/views/Practitioner/MedicalRecord/PatientFiles/Index.vue";
import MedicalRecordPatientMonitoring from "@/views/Practitioner/MedicalRecord/PatientMonitoring/Index.vue";
import MedicalRecordPatientPrivateNotes from "@/views/Practitioner/MedicalRecord/PatientPrivateNotes/Index.vue";
import MedicalRecordPatientSummary from "@/views/Practitioner/MedicalRecord/PatientSummary/Index.vue";
import MedicalRecord from "@/views/Practitioner/MedicalRecord/View.vue";
import PractitionerMedicalRecordVisitNotes from "@/views/Practitioner/MedicalRecord/VisitNotes/Index.vue";
import Patients from "@/views/Practitioner/Patients.vue";
import PractitionerSettings from "@/views/Practitioner/Settings.vue";
import PractitionerVisitNote from "@/views/Practitioner/SignedVisit/PractitionerVisitNote.vue";
import PractitionerView from "@/views/Practitioner/View.vue";
import PractitionerVisitCall from "@/views/Practitioner/Visits/Call.vue";
import PendingVisit from "@/views/Practitioner/Visits/Pending.vue";
import Schedule from "@/views/Practitioner/Visits/Request.vue";

import {
  addPastVisit,
  appointmentsBreadcrumbs,
  chatBreadcrumbs,
  clinicalRequestsBreadcrumbs,
  EditAvailabilityBreadcrumbs,
  ifAuthenticated,
  mediaBreadcrumbs,
  meetingRoomBreadcrumbs,
  notificationsBreadcrumbs,
  patientListBreadcrumbs,
  patientMedicalRecordBreadcrumbs,
  pendingNotesBreadcrumbs,
  practitionerScheduleVisitBreadcrumbs,
  profileSettingsBreadcrumbs,
  requestVisitBreadcrumbs,
  scheduledAppointmentsBreadcrumbs,
} from "./utils";

const PractitionerRoutes = [
  {
    path: "/practitioner",
    component: PractitionerView,
    name: routesEnum.practitionerHome,
    meta: {
      authorize: ["practitioner"],
    },
    redirect: "/practitioner/appointments",
    beforeEnter: ifAuthenticated,
    children: [
      {
        path: "notifications",
        name: routesEnum.practitionerNotifications,
        meta: { breadcrumbs: notificationsBreadcrumbs },
        component: (): unknown => import("@/views/Practitioner/Notifications/Index.vue"),
      },
      {
        path: "media/:patientId",
        name: routesEnum.practitionerMedia,
        meta: { breadcrumbs: mediaBreadcrumbs },
        component: (): unknown => import("@/views/Practitioner/Media/PractitionerMedia.vue"),
      },
      {
        path: "chat",
        name: routesEnum.practitionerChat,
        meta: { breadcrumbs: chatBreadcrumbs },
        component: (): unknown => import("@/views/Practitioner/Chat/Index.vue"),
      },
      {
        path: "clinical-requests",
        name: routesEnum.practitionerClinicalRequests,
        meta: { breadcrumbs: clinicalRequestsBreadcrumbs },
        component: (): unknown => import("@/views/Practitioner/MedicalRequests/MedicalRequests.vue"),
      },
      {
        path: "settings",
        name: routesEnum.practitionerSettings,
        component: PractitionerSettings,
        meta: { breadcrumbs: profileSettingsBreadcrumbs },
      },
      {
        path: linkToPractitionerPendedNotes(),
        component: (): unknown => import("@/views/Practitioner/PendedNotes/View.vue"),
        meta: { breadcrumbs: pendingNotesBreadcrumbs },
        children: [
          {
            path: "",
            name: routesEnum.practitionerPendedNotes,
            component: (): unknown => import("@/views/Practitioner/PendedNotes/Draft.vue"),
            meta: { breadcrumbs: pendingNotesBreadcrumbs },
          },
          {
            path: "pending",
            name: routesEnum.practitionerPendedNotesPending,
            component: (): unknown => import("@/views/Practitioner/PendedNotes/Pending.vue"),
            meta: { breadcrumbs: pendingNotesBreadcrumbs },
          },
        ],
      },
      {
        path: linkToPractitionerMeetingRoom(),
        component: (): unknown => import("@/views/Practitioner/MeetingRoom/Index.vue"),
        meta: { breadcrumbs: meetingRoomBreadcrumbs },
        children: [
          {
            path: "",
            name: routesEnum.practitionerMeetingRoomCreate,
            component: (): unknown => import("@/views/Practitioner/MeetingRoom/Create.vue"),
            meta: { breadcrumbs: meetingRoomBreadcrumbs },
          },
          {
            path: ":roomId/:identity",
            name: routesEnum.practitionerMeetingRoomJoin,
            component: (): unknown => import("@/views/Practitioner/MeetingRoom/Join.vue"),
            meta: { breadcrumbs: meetingRoomBreadcrumbs },
          },
        ],
      },
      {
        path: "register-patient",
        name: routesEnum.registerPatient,
        component: (): unknown => import("@/views/Practitioner/RegisterPatient/Index.vue"),
        meta: { breadcrumbs: patientListBreadcrumbs },
      },
      {
        path: "patients",
        name: routesEnum.practitionerPatients,
        component: Patients,
        meta: { breadcrumbs: patientListBreadcrumbs },
      },

      {
        path: "record/patient/:patientId",
        component: MedicalRecord,
        meta: { breadcrumbs: patientMedicalRecordBreadcrumbs },
        children: [
          {
            path: "",
            name: routesEnum.patientMedicalRecord,
            component: MedicalRecordPatientSummary,
            meta: { breadcrumbs: patientMedicalRecordBreadcrumbs, layout: "default" },
          },
          {
            path: "visit-notes",
            name: routesEnum.practitionerMedicalRecordVisitNotes,
            component: PractitionerMedicalRecordVisitNotes,
            meta: { breadcrumbs: patientMedicalRecordBreadcrumbs, layout: "default" },
          },
          {
            path: "monitoring",
            name: routesEnum.practitionerMedicalRecordMonitoringOrders,
            component: MedicalRecordPatientMonitoring,
            meta: { breadcrumbs: patientMedicalRecordBreadcrumbs, layout: "default" },
          },
          {
            path: "monitoring/:kind",
            name: routesEnum.practitionerMedicalRecordMonitoringOrders,
            component: MedicalRecordPatientMonitoring,
            meta: { breadcrumbs: patientMedicalRecordBreadcrumbs, layout: "default" },
          },
          {
            path: "files",
            name: routesEnum.practitionerMedicalRecordFiles,
            component: MedicalRecordPatientFiles,
            meta: { breadcrumbs: patientMedicalRecordBreadcrumbs, layout: "default" },
          },
          {
            path: "dependents",
            name: routesEnum.practitionerMedicalRecordDependents,
            component: MedicalRecordPatientDependents,
            meta: { breadcrumbs: patientMedicalRecordBreadcrumbs, layout: "default" },
          },
          {
            path: "private-notes",
            name: routesEnum.practitionerMedicalRecordPrivateNotes,
            component: MedicalRecordPatientPrivateNotes,
            meta: { breadcrumbs: patientMedicalRecordBreadcrumbs, layout: "default" },
          },
        ],
      },

      {
        path: "orders/patient/:patientId/monitoring/:kind",
        name: routesEnum.practitionerMonitoringListKindOrders,
        component: (): unknown => import("@/views/Practitioner/PractitionerMonitoringListKindOrders/View.vue"),
        meta: { breadcrumbs: patientMedicalRecordBreadcrumbs },
      },
      {
        path: "appointments",
        component: AppointmentsView,
        children: [
          {
            path: "/",
            name: routesEnum.practitionerSchedule,
            component: ScheduleAppointment,
            meta: { breadcrumbs: appointmentsBreadcrumbs, layout: "default" },
          },
          {
            path: "/practitioner/appointments/scheduled",
            name: routesEnum.practitionerScheduledAppointments,
            component: ScheduledAppointments,
            meta: { breadcrumbs: scheduledAppointmentsBreadcrumbs, layout: "default" },
          },
          {
            path: "edit",
            name: routesEnum.practitionerEditAvailability,
            component: EditAvailability,
            meta: { breadcrumbs: EditAvailabilityBreadcrumbs, layout: "edit" },
          },
        ],
      },
      {
        path: "encounters",
        component: MiddleComponent,
        children: [
          {
            path: "",
            component: MiddleComponent,
            redirect: "/practitioner/appointments",
          },
          {
            path: "schedule",
            name: routesEnum.practitionerEncounterSchedule,
            component: Schedule,
            meta: { breadcrumbs: practitionerScheduleVisitBreadcrumbs },
          },
          {
            path: "schedule/:sessionId/:status",
            name: routesEnum.practitionerCheckoutComplete,
            component: (): unknown => import("@/components/Checkout/SuccessPayement/Index.vue"),
            meta: { breadcrumbs: requestVisitBreadcrumbs },
          },
          {
            path: "pastVisit",
            name: routesEnum.practitionerAddPastVisit,
            component: PastVisit,
            meta: { breadcrumbs: addPastVisit },
          },
          {
            path: ":encounterId/visitNote",
            name: routesEnum.practitionerVisitNote,
            component: PractitionerVisitNote,
            meta: { collapseSidebar: true },
          },
          {
            path: ":encounterId",
            component: PractitionerVisitCall,
            meta: { collapseSidebar: true },
            children: [
              {
                name: routesEnum.practitionerEncounterMedication,
                path: "medicationReconsiliation",
                component: PractitionerEncounterMedicationOrder,
                meta: { collapseSidebar: true },
              },
              {
                name: routesEnum.practitionerEncounterReview,
                path: "",
                component: PractitionerEncounterMedicationReview,
                meta: { collapseSidebar: true },
              },
            ],
          },
        ],
      },
      {
        name: routesEnum.practitionerPending,
        path: "pending/:appointmentId",
        component: PendingVisit,
        meta: { collapseSidebar: true },
      },
    ],
  },
];
export default PractitionerRoutes;
