<template>
  <div class="visit-notes_card_medical-info white">
    <div>
      <v-row>
        <v-col>
          <v-expansion-panels v-model="panel" accordion class="encounter-visit-history" flat>
            <v-expansion-panel>
              <v-expansion-panel-header>
                <span class="visit-notes_card_header_title">
                  <img :src="require('@/assets/pencilBtn.svg')" alt="Medication reconciliation" class="title-icon" />
                  Prescriptions
                </span>
              </v-expansion-panel-header>
              <v-expansion-panel-content class="panel-content-wrap py-0">
                <div v-if="editable" class="visit-notes_card_medical-info_section1 pt-5 pb-8">
                  <PharmacyField />
                </div>
                <div v-if="editable" class="visit-notes_card_medical-info_section1 pt-5 pb-8">
                  <MedicationSearch :keep-selected="false" :use-favorite="true" @onSelect="addNewMedication" />
                </div>
                <v-tabs v-model="tab" :show-arrows="true" active-class="tabs_tab_active" class="tabs" color="primary">
                  <v-tab class="tabs_tab pl-6">Orders</v-tab>
                  <v-tab class="tabs_tab">Order history</v-tab>
                  <v-tab-item class="patients-list_tabs_tab_item">
                    <div
                      v-if="serviceRequestObservationsActive.length"
                      class="visit-notes_card_medical-info_section1 order-header"
                    >
                      Monitoring orders
                    </div>
                    <ServiceRequestObservationField
                      v-for="item in serviceRequestObservationsActive"
                      :key="item.id"
                      :item="item"
                      :with-edit="true"
                      :with-status="false"
                      @onDelete="onDeleteOrder(item)"
                      @onEdit="onEditOrder(item)"
                    />
                  </v-tab-item>
                  <v-tab-item class="patients-list_tabs_tab_item">
                    <div
                      v-if="serviceRequestObservationsHistory.length"
                      class="visit-notes_card_medical-info_section1 order-header"
                    >
                      Monitoring orders history
                    </div>
                    <ServiceRequestObservationField
                      v-for="item in serviceRequestObservationsHistory"
                      :key="item.id"
                      :item="item"
                      :show-add="false"
                      :show-edit="false"
                      :show-view="false"
                      :show-view-details="false"
                    />
                  </v-tab-item>
                </v-tabs>
                <div
                  v-if="medicationStatementActive.length"
                  class="visit-notes_card_medical-info_section1 order-header"
                >
                  Medications
                </div>
                <MedicationField
                  v-for="item in medicationStatementActive"
                  :key="item.id"
                  :isEditing="item.id === editableId"
                  :item="item"
                  :with-edit="true"
                  :with-status="false"
                  @onCancelEdit="() => (editableId = '')"
                  @onChangeStatus="(e) => onChangeStatus(e, item.id)"
                  @onEditStart="onEditStart(item.id)"
                  @onSave="onSaveEditable"
                  @onSaveAsFavorite="(e) => onSaveAsFavorite(e, item.id)"
                />
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
      </v-row>
    </div>
    <EncounterNotes />
  </div>
</template>
<script>
import { mapActions as mapPiniaActions } from "pinia";
import { mapActions, mapState } from "pinia";

import { MedicationApi } from "@/api/medication";
import { ServiceRequestApi } from "@/api/serviceRequest";
import MedicationSearch from "@/components/Medications/MedicationSearch";
import EncounterNotes from "@/components/VisitNotes/EncounterNotes";
import MedicationField from "@/components/VisitNotes/medicationOrder/MedicationField";
import ServiceRequestObservationField from "@/components/VisitNotes/medicationOrder/ServiceRequestObservationField";
import PharmacyField from "@/components/VisitNotes/PharmacyField";
import { useAllergiesStore } from "@/pinia-store/allergies";
import { useAuthStore } from "@/pinia-store/auth";
import { useAutoSaveStore } from "@/pinia-store/autoSave";
import { useBloodGlucoseOrderStore } from "@/pinia-store/bodyGlucoseOrder";
import { useBloodPressureOrderStore } from "@/pinia-store/bodyPresureOrder";
import { useBodyTemperatureOrderStore } from "@/pinia-store/bodyTemperatureOrder";
import { useHeartRateOrderStore } from "@/pinia-store/heartRateOrder";
import { useOxygenSaturationOrderStore } from "@/pinia-store/oxygenStaturationOrder";
import { usePastVisitStore } from "@/pinia-store/pastVisit";
import { useRespiratoryRateOrderStore } from "@/pinia-store/respiratoryRateOrder";
import { useTerminologyStore } from "@/pinia-store/terminology";
import { useVisitNoteStore } from "@/pinia-store/visitNote";
import { observationsEnum } from "@/utils/observationTypes";
import { tableHeaders } from "@/utils/tableHeaders.ts";

export default {
  name: "PractitionerEncounterMedicationOrder",
  components: {
    ServiceRequestObservationField,
    PharmacyField,
    EncounterNotes,
    MedicationSearch,
    MedicationField,
  },

  data: () => ({
    ...tableHeaders(),
    tab: 0,
    panel: 1,
    fileRecords: [],
    medicalTab: 0,
    uploadHeaders: { "X-Test-Header": "vue-file-agent" },
    fileRecordsForUpload: [],
    editableId: "",
  }),
  props: {
    editable: {
      default: true,
      type: Boolean,
    },
  },
  computed: {
    ...mapState(useVisitNoteStore, [
      "medicalInfo",
      "medicationStatement",
      "otherInfo",
      "encounter",
      "serviceRequestObservations",
      "serviceRequestObservationsHistory",
      "serviceRequestObservationsActive",
    ]),
    ...mapActions(useTerminologyStore, ["practitionerFavMedication"]),
    ...mapState(useAuthStore, ["uid"]),
    ...mapState(usePastVisitStore, ["getFile"]),

    medicationStatementActive() {
      return this.medicationStatement.filter((i) => i.status !== "not-taken");
    },
  },
  watch: {
    // todo : change watch to use pinia store
    "$store.state.visitNote.encounter": {
      immediate: true,
      handler(val) {
        if (val?.id) {
          this.getMedicationStatement();
          this.getPharmacy();
        }
      },
    },
  },
  methods: {
    ...mapActions(useVisitNoteStore, ["setMedicalInfoField"]),
    ...mapActions(useVisitNoteStore, ["getMedicationStatement", "getPharmacy", "getServiceRequestObservations"]),
    ...mapActions(useAutoSaveStore, ["startSave", "endSave"]),
    ...mapPiniaActions(useAllergiesStore, ["getPatientAllergies"]),
    ...mapActions(useTerminologyStore, ["getPractitionerFavMedication"]),
    ...mapActions(useBloodPressureOrderStore, { toggleBPFormOpened: "toggleFormOpened" }),
    ...mapActions(useBloodPressureOrderStore, { fillBPForm: "fillForm" }),
    ...mapActions(useHeartRateOrderStore, { fillHeartRateOrderForm: "fillForm" }),
    ...mapActions(useHeartRateOrderStore, { toggleHeartRateOrderFormOpened: "toggleFormOpened" }),
    ...mapActions(useBodyTemperatureOrderStore, { fillBodyTemperatureOrderForm: "fillForm" }),
    ...mapActions(useBodyTemperatureOrderStore, { toggleBodyTemperatureOrderFormOpened: "toggleFormOpened" }),
    ...mapActions(useRespiratoryRateOrderStore, { fillRespiratoryRateOrderForm: "fillForm" }),
    ...mapActions(useRespiratoryRateOrderStore, { toggleRespiratoryRateOrderFormOpened: "toggleFormOpened" }),
    ...mapActions(useBloodGlucoseOrderStore, { fillBloodGlucoseOrderForm: "fillForm" }),
    ...mapActions(useBloodGlucoseOrderStore, { toggleBloodGlucoseOrderFormOpened: "toggleFormOpened" }),
    ...mapActions(useOxygenSaturationOrderStore, { fillOxygenSaturationOrderForm: "fillForm" }),
    ...mapActions(useOxygenSaturationOrderStore, { toggleOxygenSaturationOrderFormOpened: "toggleFormOpened" }),
    onEditOrder(item) {
      switch (item.kind) {
        case observationsEnum.bloodPressure: {
          this.fillBPForm({ ...item });
          this.toggleBPFormOpened();
          break;
        }
        case observationsEnum.heartRate: {
          this.fillHeartRateOrderForm({ ...item });
          this.toggleHeartRateOrderFormOpened();
          break;
        }
        case observationsEnum.bodyTemperature: {
          this.fillBodyTemperatureOrderForm({ ...item });
          this.toggleBodyTemperatureOrderFormOpened();
          break;
        }
        case observationsEnum.respiratoryRate: {
          this.fillRespiratoryRateOrderForm({ ...item });
          this.toggleRespiratoryRateOrderFormOpened();
          break;
        }
        case observationsEnum.bloodGlucose: {
          this.fillBloodGlucoseOrderForm({ ...item });
          this.toggleBloodGlucoseOrderFormOpened();
          break;
        }
        case observationsEnum.oxygenSaturation: {
          this.fillOxygenSaturationOrderForm({ ...item });
          this.toggleOxygenSaturationOrderFormOpened();
          break;
        }
      }
    },
    async onDeleteOrder(order) {
      this.startSave();
      await ServiceRequestApi.destroyObservationOrder(order.id);
      this.endSave();
      await this.getServiceRequestObservations();
    },
    async onChangeStatus(status, medicationId) {
      this.startSave();
      await MedicationApi.patch(medicationId, { status });
      await this.getMedicationStatement();
      this.endSave();
    },

    async onSaveAsFavorite(data) {
      this.startSave();
      await MedicationApi.addForPractitioner(this.uid, data);
      await this.getPractitionerFavMedication(this.uid);
      this.endSave();
    },
    async onSaveEditable(data) {
      this.startSave();
      await MedicationApi.updateForEncounter(this.encounter.id, data.id, data);
      await this.getMedicationStatement();
      this.editableId = "";
      this.endSave();
    },
    onEditStart(itemId) {
      this.editableId = itemId;
    },

    async addNewMedication(item) {
      this.startSave();
      await MedicationApi.addForEncounter(this.encounter.id, item);
      this.endSave();
      await this.getMedicationStatement();
    },
  },
  async mounted() {
    this.panel = this.$vuetify.breakpoint.smAndUp ? 0 : 1;
    await this.getPatientAllergies();
    await this.getPractitionerFavMedication(this.uid);
  },
};
</script>

<style lang="scss" scoped>
:deep .panel-content-wrap {
  .v-expansion-panel-content__wrap {
    padding-left: 0;
    padding-right: 0;
  }
}

.visit-notes_card_medical-info {
  .order-header {
    background: rgba(231, 232, 242, 0.21);
    border: 1px solid #e7e8f2;
    color: #aaaab1;
    font-size: 14px;
    line-height: 17px;
    padding: 14px 26px;
  }
}
</style>
