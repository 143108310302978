import { defineStore } from "pinia";

export enum AutoSaveVariants {
  success = "success",
  error = "error",
}

interface AutoSaveState {
  state: string;
  variant: AutoSaveVariants;
  endMessage: string;
}

interface ModuleContext {
  state: AutoSaveState;
  rootState: any;
  rootGetters: any;
  commit: any;
}

const defaultEndMessage = "Competed!";
export const useAutoSaveStore = defineStore({
  id: "autoSave",
  state: () => ({
    state: "ended",
    variant: AutoSaveVariants.success,
    endMessage: defaultEndMessage,
  }),
  actions: {
    startSave(payload?: string) {
      this.state = "started";
    },
    endSave(payload?: Record<any, any>) {
      this.state = "ended";
    },
    async end() {
      this.state = "ended";
    },
  },
});
