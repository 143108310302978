import "tui-calendar/dist/tui-calendar.css";
import "tui-date-picker/dist/tui-date-picker.css";
import "tui-time-picker/dist/tui-time-picker.css";

interface Time {
  hour: number;
}
export const calendarMixin = {
  data: () => ({
    view: "week",
    scheduleView: ["time"],
    taskView: false,
    theme: {
      "month.dayname.height": "30px",
      "month.dayname.borderLeft": "1px solid #ff0000",
      "month.dayname.textAlign": "center",
      "week.daygridLeft.width": "65px",
      "week.timegridLeft.width": "65px",
      "week.dayname.height": "52px",
      "week.timegridOneHour.height": "101px",
      "week.today.backgroundColor": "rgba(33,150,243, 0.05)",
      "week.currentTime.color": "#E53935",
      "week.currentTimeLinePast.border": "1px dashed #E53935",
      "week.currentTimeLineBullet.backgroundColor": "#E53935",
      "week.currentTimeLineToday.border": "1px solid #E53935",
    },
    template: {
      timegridDisplayPrimaryTime: (time: Time) => {
        const { hour } = time;
        return `<span class="c-week-time">${hour}:00</span>`;
      },
    },
  }),
};
