<template>
  <div class="ps_upload">
    <v-list-item class="ps_upload_list-item p-0">
      <v-list-item-avatar class="text-center pa-0" size="46">
        <v-img v-if="imagePreview" :src="imagePreview"></v-img>
        <img v-else src="../../assets/no-avatar.svg" />
      </v-list-item-avatar>
      <v-list-item-content class="ps_upload_content pa-0">
        <span class="ps_upload_content_title">{{ $t("settings.upload.title") }}</span>
        <span class="ps_upload_content_sub-title">{{ $t("settings.upload.text") }}</span>
      </v-list-item-content>
    </v-list-item>
    <div class="buttonUpload pa-0">
      <OutlinedButton
        :text="$t('settings.upload.button')"
        class="text-none mx-auto"
        color="primary"
        outlined
        @onClick="onButtonClick"
      />
      <input ref="uploader" accept="image/*" class="d-none" type="file" @change="onFileChanged" />
    </div>
  </div>
</template>
<script>
import { mapActions, mapState } from "pinia";

import OutlinedButton from "@/components/uikit/OutlinedButton";
import { useAuthStore } from "@/pinia-store/auth";
import { useProfileSettingsStore } from "@/pinia-store/profileSettings";

export default {
  name: "UploadHeader",
  components: {
    OutlinedButton,
  },
  data: () => ({
    localAvatar: "",
  }),
  computed: {
    ...mapState(useAuthStore, ["photoURL"]),
    ...mapState(useProfileSettingsStore, ["avatar"]),
    imagePreview() {
      return this.localAvatar || this.photoURL;
    },
  },
  methods: {
    ...mapActions(useProfileSettingsStore, ["uploadAvatar"]),
    ...mapActions(useProfileSettingsStore, ["setAvatar"]),
    onButtonClick() {
      this.$refs.uploader.click();
    },
    async onFileChanged(e) {
      const file = e.target.files[0];
      const reader = new FileReader();
      reader.onload = (e) => {
        this.localAvatar = e.target.result;
      };
      reader.readAsDataURL(file);
      this.setAvatar(file);
    },
  },
};
</script>
<style lang="scss">
.ps_upload {
  padding: 20px !important;
}

.ps_upload_list-item {
  padding-left: 0px !important;
  padding-right: 26px;
}

.ps_upload_content_sub-title {
  margin-top: 3px;
}

@media only screen and (max-width: 680px) {
  .ps_upload {
    display: flex;
    flex-direction: column;
  }
  .ps_upload_list-item {
    padding-right: 0px !important;
    margin-bottom: 20px;
  }
  .buttonUpload {
    min-width: 100%;
    text-align: center;

    button {
      width: 100%;
    }
  }
}
</style>
