<template>
  <div class="d-flex chat-message-row">
    <template v-if="message.author === 'system'">
      <div class="full-width d-inline-flex flex-column">
        <div class="message-system align-self-center">
          <div class="message-system-body">{{ message.body }}</div>
        </div>
      </div>
    </template>
    <template v-else-if="message.my">
      <div class="full-width d-inline-flex flex-column border-radius-inherit">
        <div v-if="message.head" class="full-width d-inline-flex justify-end mb-1">
          <span class="message-head">{{ message.creationDateTime }}</span>
        </div>
        <div class="full-width d-inline-flex justify-end border-radius-inherit">
          <div
            class="message-my border-radius-inherit"
            v-bind:class="{ 'message-my-tail': !message.head, 'message-my-head': message.head }"
          >
            <template v-if="message.type === 'media' || gallery">
              <div>
                <v-chip v-if="gallery" color="primary" label small @click="openGalleryFile">
                  {{ message.body }}
                </v-chip>
                <v-chip v-if="message.type === 'media'" color="primary" label small @click="openFile">
                  {{ message.media.filename }}
                </v-chip>
              </div>
            </template>
            <span v-else class="border-radius-inherit">
              <component
                :is="component()"
                :message="message"
                :responseDelegate="responseDelegate"
                @action="passAction"
              />
            </span>
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <Avatar v-if="message.head && message.author" :user="message.authorMeta" class="mr-2" size="28" />
      <div class="full-width d-inline-flex flex-column border-radius-inherit">
        <div v-if="message.head" class="border-radius-inherit full-width d-inline-flex justify-start mb-1">
          <span class="message-head">{{ message.authorMeta.displayName }}, {{ message.creationDateTime }} </span>
        </div>
        <div class="full-width d-inline-flex justify-start border-radius-inherit" v-bind:class="{ mar: !message.head }">
          <div
            class="message-participant border-radius-inherit"
            v-bind:class="{
              bot: message.author === 'bot',
              'message-participant-tail': !message.last,
              'message-participant-last': message.last,
            }"
          >
            <template v-if="message.type === 'media' || gallery">
              <div>
                <v-chip v-if="gallery" color="primary" label small @click="openGalleryFile">
                  {{ message.body }}
                </v-chip>
                <v-chip v-if="message.type === 'media'" color="primary" label small @click="openFile">
                  {{ message.media.filename }}
                </v-chip>
              </div>
            </template>
            <component
              :is="component()"
              v-else
              :message="message"
              :responseDelegate="responseDelegate"
              class="border-radius-inherit"
              @action="passAction"
            />
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import Avatar from "@/components/Avatar/Index";
import ChatLogBaseMessage from "@/components/Chat/ChatBotLog/ChatLogMessages/ChatLogBaseMessage";
import ChatLogMessageWithAdaptiveCard from "@/components/Chat/ChatBotLog/ChatLogMessages/ChatLogMessageWithAdaptiveCard";
import ChatLogMessageWithButtons from "@/components/Chat/ChatBotLog/ChatLogMessages/ChatLogMessageWithButtons";
import ChatLogMessageWithCheckList from "@/components/Chat/ChatBotLog/ChatLogMessages/ChatLogMessageWithCheckList";

export default {
  name: "ChatLogMessageRow",
  props: {
    message: Object,
    responseDelegate: Function,
  },
  components: {
    Avatar,
    ChatLogBaseMessage,
    ChatLogMessageWithButtons,
    ChatLogMessageWithCheckList,
    ChatLogMessageWithAdaptiveCard,
  },
  methods: {
    async openFile() {
      const url = await this.message?.media?.getContentTemporaryUrl();
      window.open(url, "_blank");
    },
    async openGalleryFile() {
      const url = await this.message.attributes?.url;
      window.open(url, "_blank");
    },
    passAction(action) {
      this.$emit("action", action);
    },
    component: function () {
      return "ChatLog" + this.message?.attributes?.msgType;
    },
  },
  computed: {
    gallery() {
      return this.message?.attributes?.msgType === "gallery";
    },
  },
};
</script>

<style lang="scss" scoped>
.message {
  &-head {
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;
    color: #bec5cc;
  }

  &-my {
    background: #dbf4fe;

    &-head {
      border-radius: 10px 10px 0 10px;
    }

    &-tail {
      border-radius: 10px 0 0 10px;
    }
  }

  &-participant {
    background: #f2f6f9;

    &-last {
      border-radius: 0 10px 10px 10px;
    }

    &-tail {
      border-radius: 0 10px 10px 0;
    }
  }

  &-system {
    background: white;

    min-height: 30px;
    margin: 17px auto;
    border: 1px solid #e5e4e8;
    box-sizing: border-box;
    border-radius: 100px;

    &-body {
      padding: 5px 16px 5px 16px;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 20px;
      letter-spacing: 0.25px;
      color: #33343e;
      opacity: 0.66;
      text-align: center;
    }
  }

  &-avatar-placeholder {
    width: 18px;
    margin-right: 8px;
  }
}

.chat-message-row {
  margin-top: 8px;
  width: 100%;
}

.mar {
  margin-left: 36px;
}
</style>
