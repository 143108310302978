<template>
  <div v-if="true" class="notification-header">
    <v-menu bottom offset-y right>
      <template v-slot:activator="{ on, attrs }">
        <div class="menu-wrap" v-bind="attrs" v-on="on">
          <v-divider :vertical="true"></v-divider>
          <span class="mr-1">Notifications</span>
          <v-btn v-if="notificationsCountUnread" color="primary" dark rounded small
            >New {{ notificationsCountUnread }}
          </v-btn>
          <img
            v-if="!notificationsCountUnread"
            :src="require('@/assets/sidebar/bell-blue.svg')"
            alt="bell"
            class="notification-bell"
          />
        </div>
      </template>

      <v-list class="list-wrap">
        <v-list-item class="notification-title-root">
          <v-list-item-title class="notification-title-wrap" @click.stop.prevent>
            <span class="notification-title">Notifications</span>
            <IconicButton
              v-if="notificationsCountUnread"
              :lite="true"
              :loading="inProgressMarkSeen"
              left-icon="mdi-check-all"
              text="Mark as read"
              @onClick="markAll"
            />
          </v-list-item-title>
        </v-list-item>
        <div class="items-list-wrap">
          <NotificationItem v-for="item in notificationsList" :key="item.id" :item="item" />
        </div>
        <v-list-item>
          <v-list-item-title class="view-all" @click="gotoList">View all notifications</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
import { mapActions, mapState } from "pinia";

import NotificationItem from "@/components/Notifications/NotificationItem.vue";
import IconicButton from "@/components/uikit/IconicButton.vue";
import { useAuthStore } from "@/pinia-store/auth";
import { useNotificationsStore } from "@/pinia-store/notifications";
import { NotificationTypes } from "@/types/notificationTypes";
import { routesEnum } from "@/types/Routes.enum";

export default {
  components: { IconicButton, NotificationItem },
  props: {
    item: {
      type: Object,
      default: () => ({}),
    },
  },
  name: "NotificationHeader",
  computed: {
    ...mapState(useAuthStore, ["userIsPatient", "userIsPractitioner"]),
    ...mapState(useNotificationsStore, ["notifications", "notificationsCountUnread", "inProgressMarkSeen"]),

    notificationsList() {
      return this.notifications.slice(0, 4);
    },
    createdAt() {
      return this.$moment(this.item.createdAt).fromNow();
    },
  },
  methods: {
    ...mapActions(useNotificationsStore, ["markSeen"]),
    async markAll() {
      await this.markSeen({ seenContent: true, kind: Object.keys(NotificationTypes) });
    },
    gotoList() {
      this.$router.push({
        name: this.userIsPatient ? routesEnum.patientNotifications : routesEnum.practitionerNotifications,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.list-wrap {
  padding: 30px 30px 24px;
  width: 416px;
  max-width: 100%;
  @include mobile {
    padding: 20px 15px 20px;
  }

  .items-list-wrap {
    > div {
      border-top: 1px solid #e7e8f2;
      padding: 9px 0 !important;

      &:last-child,
      &:first-child {
        border-color: transparent;
      }

      &:first-child:hover + div {
        border-top-color: transparent;
      }

      &:hover + div {
        border-top-color: transparent;
      }
    }
  }

  .view-all {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: $primary;
    cursor: pointer;
    @include mobile {
      text-align: center;
    }

    &:hover {
      opacity: 0.7;
    }
  }

  .notification-title-root {
    padding: 0;

    .notification-title-wrap {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .notification-title {
        font-style: normal;
        font-weight: 500;
        font-size: 25px;
        line-height: 31px;
        color: #25233a;
        @include mobile {
          font-size: 18px;
        }
      }

      .mark-all-btn {
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        text-transform: none;
        color: $primary;
        cursor: pointer;
        @include mobile {
          font-size: 14px;
        }

        &:hover {
          opacity: 0.7;
        }

        i {
          color: $primary;
          @include mobile {
            font-size: 14px;
          }
        }
      }
    }
  }
}

.notification-header {
  display: flex;
  align-items: center;

  .menu-wrap {
    display: flex;
    align-items: center;
    gap: 15px;
    margin-right: 10px;
  }

  .notification-bell {
    padding: 5px;
    width: 28px;
    height: 28px;
    background: linear-gradient(0deg, rgba(8, 25, 86, 0.02), rgba(8, 25, 86, 0.02)), #eff6ff;
    border-radius: 50%;
  }
}
</style>
