<template>
  <v-dialog v-model="dialog" max-width="420" scrollable @click:outside="closeReminder">
    <v-card class="visit-reminder-dialog">
      <v-btn :ripple="false" class="visit-reminder-dialog_icon-close" icon text @click="closeReminder">
        <v-icon color="icon" size="large"> mdi-close</v-icon>
      </v-btn>
      <v-card-title class="visit-reminder-dialog_title">
        {{ $te("general.reminder.title") ? $t("general.reminder.title") : "Reminder" }}
      </v-card-title>
      <v-card-text class="visit-reminder-dialog_message">{{ message }}</v-card-text>
      <v-card-actions class="mt-4 justify-end">
        <div class="mx-0 justify-end d-flex">
          <v-btn
            :ripple="false"
            class="visit-reminder-dialog_action"
            color="primary"
            depressed
            large
            @click="onReminderConfirm"
          >
            {{ btn }}
          </v-btn>
        </div>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from "pinia";

import soon from "@/assets/sounds/visit/soon.mp3";
import join from "@/assets/sounds/visit/soon.mp3";
import started from "@/assets/sounds/visit/soon.mp3";
import { useAuthStore } from "@/pinia-store/auth";
import { AppointmentReminderStatusEnum } from "@/types/AppointmentReminderStatus.enum";
import { audioVolume } from "@/utils/constants";

export default {
  name: "VisitReminder",
  data() {
    return {
      message: "",
      encounterId: null,
      status: null,
      dialog: false,
      titleInterval: null,
      sound: "",
    };
  },
  computed: {
    ...mapState(useAuthStore, ["role"]),
    btn() {
      if (this.status === AppointmentReminderStatusEnum.started) {
        return this.$t("visits.waiting.joinVisit");
      }
      return this.$t("patients.cancelDialog.confirm");
    },
  },
  methods: {
    onReminderConfirm() {
      clearInterval(this.titleInterval);
      document.title = process.env.VUE_APP_BRAND_DOMAIN;
      this.closeReminder();
      if (
        [
          AppointmentReminderStatusEnum.started,
          AppointmentReminderStatusEnum.doctorJoined,
          AppointmentReminderStatusEnum.patientJoined,
        ].includes(this.status) &&
        this.encounterId
      ) {
        this.$router.push(`/${this.role}/encounters/${this.encounterId}`);
      }
    },
    closeReminder() {
      this.dialog = false;
      clearInterval(this.titleInterval);
      document.title = process.env.VUE_APP_BRAND_DOMAIN;
    },
  },
  mounted() {
    this.sockets.subscribe("reminder", (data) => {
      this.status = data.status;
      if (data.encounterId) {
        this.encounterId = data.encounterId;
      }
      let audio;
      switch (data.status) {
        case AppointmentReminderStatusEnum.soon:
          this.message = this.$te("general.reminder.startingSoon")
            ? this.$t("general.reminder.startingSoon")
            : "Your visit starts in 10 minutes";
          audio = new Audio(soon);
          break;
        case AppointmentReminderStatusEnum.started:
          this.message = this.$te("general.reminder.started")
            ? this.$t("general.reminder.started")
            : "Your visit has started, please join the Visit Room";
          audio = new Audio(started);
          break;
        case AppointmentReminderStatusEnum.doctorJoined:
          this.message = this.$te("general.reminder.doctorJoined")
            ? this.$t("general.reminder.doctorJoined")
            : "Doctor has joined the Visit Room";
          audio = new Audio(join);
          break;
        case AppointmentReminderStatusEnum.patientJoined:
          this.message = this.$te("general.reminder.patientJoined")
            ? this.$t("general.reminder.patientJoined")
            : "Patient has joined the Visit Room";
          audio = new Audio(join);
          break;
      }
      this.dialog = true;
      audio.volume = audioVolume;
      audio.play();
      this.titleInterval = setInterval(() => {
        if (document.title === process.env.VUE_APP_BRAND_DOMAIN) {
          document.title = this.message;
        } else {
          document.title = process.env.VUE_APP_BRAND_DOMAIN;
        }
      }, 1000);
    });
  },
};
</script>

<style lang="scss" scoped>
.visit-reminder-dialog {
  position: relative;

  &_icon-close {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 26px;
    height: 26px;
    border: 1px solid #e7e8f2;
    border-radius: 50%;
  }

  &_title {
    font-size: 25px !important;
  }

  &_message {
    font-size: 14px;
  }

  &_action {
    font-size: 16px;
  }
}
</style>
