import uniqBy from "lodash/uniqBy";
import { defineStore } from "pinia";

import { NotificationFilterDto, NotificationsApi, NotificationSeenDto } from "@/api/Notifications";
import { NotificationItem } from "@/types/Notification";
import { NotificationsState } from "@/types/NotificationsState";
import { NotificationTypes } from "@/types/notificationTypes";

export const useNotificationsStore = defineStore({
  id: "notifications",
  state: (): NotificationsState => ({
    notifications: [] as NotificationItem[],
    userNotifications: {},
    isLoading: false,
    inProgressMarkSeen: false,
  }),
  actions: {
    async getNotifications(filter?: NotificationFilterDto) {
      this.isLoading = true;
      let data = [];
      try {
        data = await NotificationsApi.getNotifications(filter);
        this.notifications = data;
      } catch (e) {
        console.error(e);
      } finally {
        this.isLoading = false;
      }
      return data;
    },
    addNotification(payload: NotificationItem): void {
      this.notifications = [...this.notifications, payload];
    },
    cleanUp(state: NotificationsState): void {
      this.notifications = [];
    },
    async getUserNotifications(filter: { userId: string } & NotificationFilterDto) {
      this.isLoading = true;
      let data = [];
      try {
        data = await NotificationsApi.getNotifications(filter);
        this.userNotifications[filter.userId] = data;
      } catch (e) {
        console.error(e);
      } finally {
        this.isLoading = false;
      }
      return data;
    },
    async markSeen(filter: NotificationSeenDto) {
      this.inProgressMarkSeen = true;

      try {
        await NotificationsApi.markSeen(filter);
        await this.getNotifications();
      } catch (e) {
        console.error(e);
      } finally {
        this.inProgressMarkSeen = false;
      }
    },
  },
  getters: {
    notificationsCountUnread: (state): number =>
      state.notifications.filter(($notification: NotificationItem) => !$notification.seenContent).length,
    notificationCountOfUser: (state) => (userId: string) => {
      return state.userNotifications?.[userId]?.length || 0;
    },
    monitoringCount: (state) =>
      uniqBy(
        state.notifications.filter(
          (item: NotificationItem) => Object.keys(NotificationTypes).includes(item.kind) && !item.seenBadge,
        ),
        "kind",
      ).length,
    monitoringOrders: (state) =>
      uniqBy(
        state.notifications.filter((item: NotificationItem) => Object.keys(NotificationTypes).includes(item.kind)),
        "kind",
      ),
  },
});
