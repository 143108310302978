<template>
  <div>
    <Confirm
      :cancelText="$t('general.dialog.cancel')"
      :confirmText="id ? $t('templates.update') : $t('templates.save')"
      :dialog="value"
      :loading="loading"
      :maxWidth="970"
      :text="$t('templates.subtitle')"
      :title="id ? $t('templates.update') : $t('templates.createNew')"
      rootClass="templates-dialog"
      width="680px"
      @confirm="onSubmit"
      @toggleDialog="toggleDialog"
    >
      <v-container class="no-padding-bottom no-padding-top">
        <v-row>
          <v-col cols="12" sm="12">
            <BaseInput
              v-model="name"
              :errors="nameErrors"
              :placeholder="$t('templates.name')"
              @blur="$v.name.$touch()"
              @change="onFieldChange"
            />
          </v-col>
          <v-col cols="12" sm="12">
            <Select
              v-model="term"
              :errors="termErrors"
              :hideDetails="false"
              :items="templateTypes"
              :label="$t('templates.type')"
              itemText="name"
              itemValue="value"
            />
          </v-col>
          <v-col cols="12" sm="12">
            <div>
              Include replacement:
              <div class="replacement-actions">
                <PrimaryButton size="small" text="Patient first name" @onClick="includeText('firstName')" />
                <PrimaryButton size="small" text="Patient last name" @onClick="includeText('lastName')" />
                <PrimaryButton size="small" text="Patient gender" @onClick="includeText('sex')" />
              </div>
            </div>
            <quill-editor ref="quillEditor" v-model="content"></quill-editor>
          </v-col>
        </v-row>
      </v-container>
    </Confirm>
  </div>
</template>
<script>
import { mapState } from "pinia";
import { required } from "vuelidate/lib/validators";

import { TemplatesAPI } from "@/api/templates";
import Confirm from "@/components/shared/Confirm";
import BaseInput from "@/components/uikit/BaseInput";
import PrimaryButton from "@/components/uikit/PrimaryButton";
import Select from "@/components/uikit/Select";
import { snackBarEventBus, snackBarEventName } from "@/eventBuses/snackBar.eventBus";
import { useAuthStore } from "@/pinia-store/auth";

export default {
  name: "CreateTemplateDialog",
  props: ["item", "value"],
  components: {
    Confirm,
    Select,
    BaseInput,
    PrimaryButton,
  },
  data: function () {
    return {
      loading: false,
      templateTypes: [
        {
          value: "assessment",
          name: this.$t("templates.assessment"),
        },
        {
          value: "plan",
          name: this.$t("templates.plan"),
        },
        {
          value: "followUp",
          name: this.$t("templates.followUp"),
        },
      ],
      id: "",
      name: "",
      term: "",
      content: "",
    };
  },
  validations: {
    name: { required },
    term: { required },
  },
  computed: {
    ...mapState(useAuthStore, ["uid"]),
    editor() {
      return this.$refs.quillEditor.quill;
    },
    termErrors() {
      const errors = [];
      if (!this.$v.term.$dirty) return errors;
      !this.$v.term.required && errors.push(this.$t("validation.required", { field: this.$t("templates.name") }));
      return errors;
    },
    isSubmitDisabled() {
      return Boolean(!this.name || this.nameErrors.length);
    },
    nameErrors() {
      const errors = [];
      if (!this.$v.name.$dirty) return errors;
      const field = this.$t("templates.name");
      !this.$v.name.required && errors.push(this.$t("validation.required", { field }));
      return errors;
    },
  },
  watch: {
    item(val, oldVal) {
      this.id = val.id;
      this.name = val.name;
      this.term = val.term;
      this.content = val.content;
    },
  },
  methods: {
    includeText(value) {
      const caretPosition = this.editor.getSelection(true);
      this.editor.insertText(caretPosition, ` %${value}% `);
    },
    toggleDialog() {
      this.$emit("onToggleDialog");
    },
    onFieldChange(value, fieldName) {
      this[fieldName] = value;
    },
    async onSubmit() {
      this.loading = true;
      try {
        const body = {
          name: this.name,
          term: this.term,
          content: this.content,
        };
        let data = {};
        if (this.id) {
          data = await TemplatesAPI.update(this.id, body);
        } else {
          data = await TemplatesAPI.create(body);
        }
        this.id = data.id;
        this.$v.$reset();
        snackBarEventBus.$emit(snackBarEventName, {
          message: this.$t("settings.success"),
          type: "success",
        });
      } catch (err) {
        console.error(err);
        if (err && err.response && err.response.status === 400) {
          snackBarEventBus.$emit(snackBarEventName, {
            message: this.$t("templates.saveError"),
            type: "error",
          });
        }
      }
      this.loading = false;
    },
  },
};
</script>
<style lang="scss">
.templates-dialog {
  .replacement-actions {
    display: flex;
    gap: 10px;
    padding-bottom: 10px;
  }

  .quill-editor {
    display: flex;
    flex-direction: column;
  }

  &.v-card {
    .card-title {
      font-weight: 500;
      font-size: 25px;
      line-height: 31px;
      color: #25233a;
      padding-left: 12px;
      padding-right: 12px;
    }

    .v-card__title + .v-card__text {
      font-size: 14px;
      line-height: 17px;
      color: #33343d;
      padding-left: 12px;
      padding-right: 12px;
    }
  }
}
</style>
