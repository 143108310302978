<template>
  <div>
    <v-expansion-panels v-model="panel" accordion class="elevation-0" flat>
      <v-expansion-panel>
        <v-expansion-panel-header> General Medical Information</v-expansion-panel-header>
        <v-divider class="full-width"></v-divider>
        <v-expansion-panel-content class="">
          <v-skeleton-loader
            v-if="isLoadingSummary"
            :boilerplatstartTimee="true"
            class="mx-0 full-width"
            type="list-item-two-line, list-item-two-line, list-item-two-line"
          ></v-skeleton-loader>
          <div v-else class="pt-4">
            <ActionField
              :show-add="false"
              :value="`${(summary.bodyHeight && formatHeightValue(summary.bodyHeight.value)) || '--'}`"
              title="Height (ft)"
              @onView="onViewBodyHeight"
            />
            <ActionField
              :show-add="false"
              :value="`${(summary.bodyWeight && summary.bodyWeight.value) || '--'}`"
              title="Weight (lbs)"
              @onView="onViewBodyWeight"
            />
            <ActionField
              :show-add="false"
              :value="`${(summary.smokingStatus && summary.smokingStatus.value) || '--'}`"
              title="Smoking Status"
              @onView="onViewSmokingBehavior"
            />
          </div>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <DrawerBodyWeight />
    <DrawerBodyHeight />
    <DrawerSmokingBehavior />
  </div>
</template>

<script>
import { mapActions, mapState } from "pinia";

import ActionField from "@/components/VisitNotes/ActionField";
import { useObservationsStore } from "@/pinia-store/observations";
import { feetToFeetInchesDisplay } from "@/utils/conversion";
import DrawerBodyHeight from "@/views/Patient/Monitoring/bodyHeight/Drawer";
import DrawerBodyWeight from "@/views/Patient/Monitoring/bodyWeight/Drawer";
import DrawerSmokingBehavior from "@/views/Patient/Monitoring/smokingBehavior/Drawer";

export default {
  name: "PatientSummaryGeneralMedicalInfo",
  components: { DrawerBodyWeight, DrawerBodyHeight, DrawerSmokingBehavior, ActionField },
  data() {
    return {
      panel: 1,
    };
  },
  computed: {
    ...mapState(useObservationsStore, ["summary", "isLoadingSummary"]),
  },
  methods: {
    ...mapActions(useObservationsStore, [
      "toggleBloodGlucoseTableOpened",
      "toggleBloodGlucoseFormOpened",
      "toggleBloodPressureTableOpened",
      "toggleBloodPressureFormOpened",
      "toggleBodyTemperatureTableOpened",
      "toggleBodyTemperatureFormOpened",
      "toggleHeartRateTableOpened",
      "toggleHeartRateFormOpened",
      "toggleOxygenSaturationTableOpened",
      "toggleOxygenSaturationFormOpened",
      "toggleRespiratoryRateTableOpened",
      "toggleRespiratoryRateFormOpened",
      "toggleSmokingBehaviorTableOpened",
      "toggleSmokingBehaviorFormOpened",
      "toggleBodyHeightTableOpened",
      "toggleBodyHeightFormOpened",
      "toggleBodyWeightTableOpened",
      "toggleBodyWeightFormOpened",
      "clearBloodGlucoseForm",
      "clearOxygenSaturationForm",
      "clearBodyTemperatureForm",
      "clearRespiratoryRateForm",
      "clearHeartRateForm",
      "clearBloodPressureForm",
      "clearBodyHeightForm",
      "clearBodyWeightForm",
    ]),
    onViewBodyHeight() {
      this.toggleBodyHeightTableOpened();
    },
    onViewBodyWeight() {
      this.toggleBodyWeightTableOpened();
    },
    onViewSmokingBehavior() {
      this.toggleSmokingBehaviorTableOpened();
    },
    formatHeightValue(value) {
      return feetToFeetInchesDisplay(value);
    },
  },
};
</script>
