<template>
  <v-row>
    <v-col cols="12">
      <v-progress-linear v-if="loading" color="primary" indeterminate></v-progress-linear>
      <MedicalRecordEncounters :show-files="false" />
    </v-col>
  </v-row>
</template>

<script>
import { mapActions } from "pinia";

import MedicalRecordEncounters from "@/components/MedicalRecord/Index";
import { useMedicalRecordStore } from "@/pinia-store/medicalRecord";
import { EncounterStatusEnum } from "@/types/EncounterStatusEnum";

export default {
  name: "MedicalRecordPatientSummary",
  components: {
    MedicalRecordEncounters,
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    ...mapActions(useMedicalRecordStore, ["getEncounters"]),
  },
  async mounted() {
    this.loading = true;
    await this.getEncounters({
      patientId: this.$route.params.patientId,
      status: [EncounterStatusEnum.finished, EncounterStatusEnum.onleave],
    });
    this.loading = false;
  },
};
</script>

<style scoped></style>
