import deepClone from "deep-clone";
import { DateTime } from "luxon";
import moment from "moment-timezone";
import { defineStore } from "pinia";
import shortid from "shortid";

import { OwnersAPI } from "@/api/owners";
import { PractitionersAPI } from "@/api/practitioners";
import { SlotsApi } from "@/api/slots";
import { useAuthStore } from "@/pinia-store/auth";
import { useCheckoutStore } from "@/pinia-store/checkout";
import { usePatientStore } from "@/pinia-store/patient";
import { Availabilities, Availability, AvailabilitySlots, CurrentSlot } from "@/types/Availability";
import { getWeek, WeekDay } from "@/utils/getWeek";

interface SlotsState {
  availabilities: Availabilities;
  initialAvailabilities: Availabilities;
  customAvailabilities: Availabilities;
  initialCustomAvailabilities: Availabilities;
  slots?: AvailabilitySlots;
  slotsArray: Availability[];
  myDoctorSlot: Availability[];
  weekends: string[];
  currentSlot: CurrentSlot | null;
  slotInformation: any;
}

export const useSlotsStore = defineStore({
  id: "slots",
  state: (): SlotsState => ({
    availabilities: [],
    initialAvailabilities: [],
    slots: { "1": [], "2": [], "3": [], "4": [], "5": [], "6": [], "7": [] },
    weekends: [],
    slotsArray: [],
    myDoctorSlot: [],
    currentSlot: null,
    slotInformation: {},
    customAvailabilities: [],
    initialCustomAvailabilities: [],
  }),
  actions: {
    setAvailabilities(payload: Availabilities) {
      this.availabilities = payload || { "1": [], "2": [], "3": [], "4": [], "5": [], "6": [], "7": [] };
    },
    setCustomAvailabilities(payload: Availabilities) {
      this.customAvailabilities = payload && payload.map ? payload.map((i) => ({ ...i, id: shortid() })) : [];
    },
    setCurrentSlot(payload: CurrentSlot | null) {
      this.currentSlot = payload;
    },

    async savePractitionerAvailability() {
      let availabilitySlotsString = JSON.stringify(this.availabilities);
      availabilitySlotsString = availabilitySlotsString.replaceAll("true", "false");
      const availabilitySlotsBody: Availabilities = JSON.parse(availabilitySlotsString);
      const customAvailabilitySlotsBody = cleanShortId(this.customAvailabilities);
      const authStore = useAuthStore();
      const { uid } = authStore;
      const { availabilitySlots, customAvailabilitySlots } = await PractitionersAPI.practitionerUpdateSettings(uid, {
        availabilitySlots: availabilitySlotsBody as Availabilities,
        customAvailabilitySlots: customAvailabilitySlotsBody,
      });
      this.initialAvailabilities = availabilitySlots;
      this.initialCustomAvailabilities = customAvailabilitySlots;
    },
    async getPractitionerAvailability() {
      const authStore = useAuthStore();

      const { uid } = authStore;
      const { availabilitySlots, customAvailabilitySlots } = await PractitionersAPI.practitionerGetSettings(uid);
      const weekends = getWeekends(availabilitySlots);
      for (const key in availabilitySlots) {
        availabilitySlots[key].menu = false;
      }
      this.availabilities = availabilitySlots;
      this.initialAvailabilities = deepClone(availabilitySlots);
      this.customAvailabilities = customAvailabilitySlots;
      this.initialCustomAvailabilities = deepClone(customAvailabilitySlots);
      this.slots = deepClone(availabilitySlots);
      this.weekends = weekends;
    },
    async getPractitionerFreeSlots({
      practitionerId,
      date,
      status,
    }: {
      practitionerId?: string;
      date?: string;
      status?: string;
    }) {
      try {
        const patientStore = usePatientStore();
        const id = practitionerId || patientStore?.patientsPractitioner?.id;

        this.slotsArray = await SlotsApi.getAllFiltered({ practitionerId: practitionerId || id, date, status });
      } catch (e) {
        console.error(e);
      }
    },
    async getSlotInformation(id: string) {
      this.slotInformation = await OwnersAPI.getSlotInformation(id);
    },
    async getSlotById(slotId: string) {
      const slot = await SlotsApi.getById(slotId);
      const checkoutStore = useCheckoutStore();
      checkoutStore.setVisitDate(DateTime.fromISO(slot.start).toFormat("yyyy-MM-d"));
      this.currentSlot = slot;
      return slot;
    },
    cleanSlotInformation() {
      this.slotInformation = {};
    },
  },
  getters: {
    customInitialAvailabilities(state) {
      return state.initialCustomAvailabilities;
    },
    freeSlots(state) {
      return state.slotsArray.filter((slot) => slot.status === "free");
    },
    myDoctorFreeSlots(state) {
      return state.myDoctorSlot;
    },
    upcomingSlot(state) {
      const currentTime = moment().format().valueOf();
      if (state.slotsArray.length) {
        const busySlots = state.slotsArray.filter(
          (slot) => slot.status !== "free" && moment(slot.start).format().valueOf() > currentTime,
        );
        if (busySlots) {
          const upComing = busySlots.sort((a, b) => moment(a.start).valueOf() - moment(b.start).valueOf());
          return upComing[0];
        } else return null;
      } else return null;
    },
    otherSlots(state) {
      if (state.slotsArray.length) {
        return state.slotsArray
          .filter((slot) => slot.status !== "free")
          .map((slot) => {
            return {
              ...slot,
              name: "TEST NAME",
              start: new Date(slot.start).getTime(),
              end: new Date(slot.end).getTime(),
              timed: true,
            };
          });
      } else return [];
    },
  },
});

export const getWeekends = (slots: AvailabilitySlots) => {
  if (!slots) return [];
  return Object.keys(slots).reduce((acc: string[], val) => (slots[val].length ? acc : [...acc, val]), []);
};
export const cleanShortId = (slots: Availabilities) => {
  if (slots) {
    return slots.map((customAvailability) => {
      if (customAvailability.id) {
        if (!/\b[0-9a-f]{8}\b-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-\b[0-9a-f]{12}\b/.test(customAvailability.id)) {
          delete customAvailability.id;
          return customAvailability;
        } else return customAvailability;
      } else {
        if (
          customAvailability.repeat_id &&
          !/\b[0-9a-f]{8}\b-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-\b[0-9a-f]{12}\b/.test(customAvailability.repeat_id)
        ) {
          delete customAvailability.repeat_id;
          return customAvailability;
        } else return customAvailability;
      }
    });
  }
  return [];
};

// TODO refactor and add unit tests
export const serializeSlots = (slots: AvailabilitySlots) => {
  if (!slots) return [];
  const week = getWeek(new Date());

  return week.reduce((acc: Availabilities, val: WeekDay, idx: number) => {
    const { year, month, day } = val;
    return acc.concat(
      slots[idx + 1].map((slot) => {
        const [startH, startM] = slot.start.split(":");
        let [endH, endM] = slot.end.split(":");
        if (+endH === 0 && +endM === 0) {
          endH = "23";
          endM = "59";
        }
        return {
          id: shortid.generate(),
          start: DateTime.fromJSDate(new Date(year, month, day, +startH, +startM)).toISO(),
          end: DateTime.fromJSDate(new Date(year, month, day, +endH, +endM)).toISO(),
        };
      }),
    );
  }, []);
};
export const uniqBy = (arr: Availabilities[], predicate: string) => {
  const cb = typeof predicate === "function" ? predicate : (o: any) => o[predicate];

  return [
    ...arr
      .reduce((map, item) => {
        const key = item === null || item === undefined ? item : cb(item);

        map.has(key) || map.set(key, item);

        return map;
      }, new Map())
      .values(),
  ];
};

export const paginate = (array: [], pageSize: number, pageNumber: number) => {
  // human-readable page numbers usually start with 1, so we reduce 1 in the first argument
  return array.slice((pageNumber - 1) * pageSize, pageNumber * pageSize);
};
export const cleanPhone = (phone: string) => {
  if (!phone) return "";
  return phone.trim().replace("(", "").replace(")", "").replaceAll(" ", "").replaceAll("-", "");
};
