<template>
  <div class="d-visit-desc">
    <v-card flat outlined>
      <div class="d-visit-desc_header">
        <span>{{ $t("visits.description.title") }}</span>
      </div>
      <div class="d-visit-desc_contacts">
        <span class="subtitle">
          {{ $t("visits.description.patientInformation") }}
        </span>
        <div class="patient-name-wrap">
          <span class="d-visit-desc_contacts_name">{{ `${patientInfo.firstName} ${patientInfo.lastName}` }}</span>
        </div>
        <div class="patient-info_contacts d-flex">
          <div v-if="patientEmail" class="patient-info_email">
            <v-icon> mdi-circle-small</v-icon>
            {{ patientEmail }}
          </div>
          <template v-if="patientPhoneNumber">
            <div class="patient-info_phone">
              <v-icon> mdi-circle-small</v-icon>
              {{ patientPhoneNumber }}
            </div>
          </template>
          <template v-if="patientHomePhoneNumber">
            <div class="patient-info_phone">
              <v-icon> mdi-circle-small</v-icon>
              {{ patientHomePhoneNumber }}
            </div>
          </template>
        </div>
        <div class="patient-info_profile-wrap">
          <div>
            <span class="subtitle">
              {{ $t("general.inputs.dateOfBirth") }}
            </span>
            <v-card-text class="patient-info_reason">
              {{ patientBirthDate }}
            </v-card-text>
          </div>
          <div>
            <span class="subtitle"> Gender </span>
            <v-card-text class="patient-info_reason">
              {{ patientInfo.gender }}
            </v-card-text>
          </div>
          <div>
            <span class="subtitle"> State </span>
            <v-card-text class="patient-info_reason">
              {{ patientInfo.state }}
            </v-card-text>
          </div>
          <div>
            <span class="subtitle"> Zip Code </span>
            <v-card-text class="patient-info_reason">
              {{ patientInfo.postalCode || "-" }}
            </v-card-text>
          </div>
        </div>
        <div>
          <span>
            <img alt="icon" class="d-visit-desc_tick-icon" src="../../assets/icons/rounded-sick.svg" />
            <span class="subtitle">{{ $t("visits.chiefComplaint") }}</span>
          </span>
          <v-card-text class="patient-info_reason">
            {{ translateChiefComplaint(currentEncounter.chiefComplaint) }}
          </v-card-text>
          <span class="subtitle">
            {{ $t("visits.description.visitReason") }}
          </span>
          <v-card-text class="patient-info_reason">
            {{ currentEncounter.complaintDescription }}
          </v-card-text>
          <template v-if="pharmacy">
            <span class="subtitle">Pharmacy</span>
            <v-card-text class="patient-info_reason">
              {{ (pharmacy && pharmacy.name) || "" }}
            </v-card-text>
            <v-card-text class="patient-info_reason">
              {{ (pharmacy && pharmacy.address) || "" }}
            </v-card-text>
            <v-card-text class="patient-info_reason">
              {{ (pharmacy && pharmacy.city) || "" }}
            </v-card-text>
            <v-card-text class="patient-info_reason">
              {{ (pharmacy && pharmacy.state) || "" }}
            </v-card-text>
            <v-card-text class="patient-info_reason">
              {{ (pharmacy && pharmacy.zipCode) || "" }}
            </v-card-text>
          </template>
        </div>
      </div>
      <div class="d-visit-desc_general wrap-contact-actions">
        <div class="boxWrap">
          <PrimaryButton
            class="send-message-btn"
            left-icon="mdi-message-outline"
            text="Send text message"
            @onClick="openSendSMSDialog"
          />
          <ChatNowAction :phone-number="patientInfo.phoneNumber" :to-user-id="patientInfo.id" />
          <MeetNowMenu :recipient="patientInfo" />
          <CallNowAction :phone="patientInfo.phoneNumber" />
          <SpeechToTextBtn :full-width="true" theme="green" />
        </div>
      </div>
      <div class="d-visit-desc_mid-header d-flex justify-space-between align-center">
        <span>Files</span>
        <v-btn color="green" icon title="Reload files" @click="reloadPatientFiles">
          <v-icon>mdi-cached</v-icon>
        </v-btn>
      </div>
      <div class="d-visit-desc_general">
        <div v-if="patientAllFiles.length" class="media-all-files-wrap">
          <FileRow v-for="file in patientAllFiles" :key="file.id" :editable="true" :file="file" />
        </div>
        <MediaUploader
          v-if="room && room.id"
          :componentId="room ? room.id : null"
          :disabledAutoFetch="true"
          component="appointments"
          @onUploaded="onFileLoaded"
        />
      </div>
      <div class="d-visit-desc_mid-header">
        <span>{{ $t("visits.description.generalMedicalInformation") }}</span>
      </div>
      <div class="d-visit-desc_general">
        <div class="boxWrap">
          <ActionField
            :value="`${
              (observationsSummary.bodyHeight && formatHeightValue(observationsSummary.bodyHeight.value)) || '--'
            }`"
            title="Height (ft)"
            @onAdd="toggleHealthDataDialog(observationEnum.bodyHeight)"
            @onView="onViewBodyHeight"
          />
          <ActionField
            :value="`${(observationsSummary.bodyHeight && observationsSummary.bodyWeight.value) || '--'}`"
            title="Weight (lbs)"
            @onAdd="toggleHealthDataDialog(observationEnum.bodyWeight)"
            @onView="onViewBodyWeight"
          />
          <ActionField
            :value="`${(observationsSummary.smokingStatus && observationsSummary.smokingStatus.value) || '--'}`"
            title="Smoking Status"
            @onAdd="toggleSmokingBehaviorFormOpened"
            @onView="onViewSmokingBehavior"
          />
        </div>
      </div>
      <div class="d-visit-desc_mid-header">
        <span>Vitals</span>
      </div>
      <div class="d-visit-desc_general">
        <div class="boxWrap">
          <ActionField
            :title="`Blood glucose ${
              (observationsSummary.bloodGlucose && observationsSummary.bloodGlucose.unit) || ''
            }`"
            :value="`before meal: ${
              (observationsSummary.bloodGlucose && observationsSummary.bloodGlucose.beforeMeal) || '--'
            }, after meal: ${(observationsSummary.bloodGlucose && observationsSummary.bloodGlucose.afterMeal) || '--'}`"
            @onAdd="toggleHealthDataDialog(observationEnum.bloodGlucose)"
            @onView="toggleBloodGlucoseTableOpened"
          />
          <ActionField
            :title="`Blood pressure ${
              (observationsSummary.bloodPressure && observationsSummary.bloodPressure.unit) || ''
            }`"
            :value="`diastolic: ${
              (observationsSummary.bloodPressure && observationsSummary.bloodPressure.diastolic) || '--'
            }, systolic: ${(observationsSummary.bloodPressure && observationsSummary.bloodPressure.systolic) || '--'}`"
            @onAdd="toggleHealthDataDialog(observationEnum.bloodPressure)"
            @onView="toggleBloodPressureTableOpened"
          />
          <ActionField
            :title="`Body temperature ${
              (observationsSummary.bodyTemperature && observationsSummary.bodyTemperature.unit) || ''
            }`"
            :value="(observationsSummary.bodyTemperature && observationsSummary.bodyTemperature.value) || '--'"
            @onAdd="toggleHealthDataDialog(observationEnum.bodyTemperature)"
            @onView="toggleBodyTemperatureTableOpened"
          />
          <ActionField
            :title="`Heart rate ${(observationsSummary.heartRate && observationsSummary.heartRate.unit) || ''}`"
            :value="(observationsSummary.heartRate && observationsSummary.heartRate.value) || '--'"
            @onAdd="toggleHealthDataDialog(observationEnum.heartRate)"
            @onView="toggleHeartRateTableOpened"
          />
          <ActionField
            :title="`Oxygen saturation ${
              (observationsSummary.oxygenSaturation && observationsSummary.oxygenSaturation.unit) || ''
            }`"
            :value="(observationsSummary.oxygenSaturation && observationsSummary.oxygenSaturation.value) || '--'"
            @onAdd="toggleHealthDataDialog(observationEnum.oxygenSaturation)"
            @onView="toggleOxygenSaturationTableOpened"
          />
          <ActionField
            :title="`Respiratory rate ${
              (observationsSummary.respiratoryRate && observationsSummary.respiratoryRate.unit) || ''
            }`"
            :value="(observationsSummary.respiratoryRate && observationsSummary.respiratoryRate.value) || '--'"
            @onAdd="toggleHealthDataDialog(observationEnum.respiratoryRate)"
            @onView="toggleRespiratoryRateTableOpened"
          />
        </div>
      </div>
    </v-card>
    <v-card class="mt-6">
      <div class="d-visit-desc_general d-flex justify-space-between">
        <div>
          <p class="text--secondary">Patient DoseSpot Eligibility</p>

          <p v-if="eligibility.length" class="mb-0 green--text">{{ eligibility }}</p>
        </div>
        <OutlinedButton :loading="loadingEligibility" text="Check" @onClick="checkEligibility" />
      </div>
    </v-card>
    <v-card class="mt-6">
      <div class="d-visit-desc_general d-flex justify-center">
        <PrimaryButton :loading="loadingDosespot" text="Open Dosespot" @onClick="openDosespotDialog" />
      </div>
      <Confirm
        v-if="dosespotLoaded"
        :dialog="dosespotDialog"
        :hide-confirm="true"
        cancelText="Close"
        max-width="90%"
        title="ePrescription"
        width="1500px"
        @toggleDialog="toggleDosespotDialog"
      >
        <v-container v-if="dosespotLoaded" class="no-padding-bottom no-padding-top">
          <template v-if="dosespotPatientId">
            <embed
              id="dosespot-ember-src"
              :src="`${dosespotUIUrl}?b=2&SingleSignOnClinicId=${clinicId}&SingleSignOnUserId=${clinicianId}&SingleSignOnPhraseLength=32&SingleSignOnCode=${encryptedClinicId}&SingleSignOnUserIdVerify=${encryptedUserId}&Prefix=&PatientId=${dosespotPatientId}&HeightMetric=inch`"
              :style="{ width: '100%', 'min-height': embedHeight }"
              type=""
            />
          </template>
          <template v-else>
            <embed
              :src="`${dosespotUIUrl}?b=2&SingleSignOnClinicId=${clinicId}&SingleSignOnUserId=${clinicianId}&SingleSignOnPhraseLength=32&SingleSignOnCode=${encryptedClinicId}&SingleSignOnUserIdVerify=${encryptedUserId}&Prefix=&FirstName=${
                dosespotProfile.firstName
              }&MiddleName=&LastName=${dosespotProfile.lastName}&Suffix=${
                dosespotProfile.birthDate ? `&DateOfBirth=${encodeURIComponent(dosespotProfile.birthDate)}` : ''
              }${dosespotProfile.gender ? `&Gender=${dosespotGender}` : ''}${
                dosespotProfile.address ? `&Address1=${encodeURIComponent(dosespotProfile.address)}` : ''
              }${dosespotProfile.city ? `&City=${encodeURIComponent(dosespotProfile.city)}` : ''}${
                dosespotProfile.state ? `&State=${encodeURIComponent(dosespotProfile.state)}` : ''
              }${
                dosespotProfile.medicalRecordNumber
                  ? `&NonDoseSpotMedicalRecordNumber=${dosespotProfile.medicalRecordNumber}`
                  : ''
              }${dosespotProfile.postalCode ? `&ZipCode=${encodeURIComponent(dosespotProfile.postalCode)}` : ''}${
                dosespotProfile.homePhoneNumber && cleanDosespotPhone
                  ? `&PrimaryPhone=${encodeURIComponent(cleanDosespotPhone)}&PrimaryPhoneType=Home`
                  : ''
              }${
                !cleanDosespotPhone && dosespotProfile.phoneNumber && cleanDosespotCellPhone
                  ? `&PrimaryPhone=${encodeURIComponent(cleanDosespotCellPhone)}&PrimaryPhoneType=Cell`
                  : ''
              }&MRN=&Weight=140&WeightMetric=lb&Height=400&HeightMetric=inch`"
              style="width: 100%; min-height: 100vh"
              type=""
            />
          </template>
        </v-container>
      </Confirm>
    </v-card>
    <DrawerBloodGlucose />
    <AddBloodGlucose />
    <DrawerBloodPressure />
    <AddBloodPressure />
    <DrawerBodyTemperature />
    <AddBodyTemperature />
    <DrawerHeartRate />
    <AddHeartRate />
    <DrawerOxygenSaturation />
    <AddOxygenSaturation />
    <DrawerRespiratoryRate />
    <AddRespiratoryRate />
    <DrawerBodyHeight />
    <AddBodyHeight />
    <DrawerBodyWeight />
    <AddBodyWeight />
    <DrawerSmokingBehavior />
    <AddSmokingBehavior />
  </div>
</template>

<script>
import upperFirst from "lodash/upperFirst";
import { mapActions, mapState } from "pinia";

import { DosespotApi } from "@/api/dosespot";
import { PatientsApi } from "@/api/patients";
import { timeDisplayFormat } from "@/components/Clinicians/constants";
import ChatNowAction from "@/components/FloatingChat/ChatNowAction.vue";
import FileRow from "@/components/media/FileRow.vue";
import MediaUploader from "@/components/media/MediaUploader.vue";
import MeetNowMenu from "@/components/Patients/MeetNowMenu.vue";
import Confirm from "@/components/shared/Confirm";
import SpeechToTextBtn from "@/components/SpeechToText/SpeechToTextBtn.vue";
import OutlinedButton from "@/components/uikit/OutlinedButton";
import PrimaryButton from "@/components/uikit/PrimaryButton";
import ActionField from "@/components/VisitNotes/ActionField";
import CallNowAction from "@/components/VoIP/CallNowAction.vue";
import { OBSERVATION_ANY_ADDED } from "@/eventBuses/monitoringEvents";
import { snackBarEventBus, snackBarEventName } from "@/eventBuses/snackBar.eventBus";
import { useAuthStore } from "@/pinia-store/auth";
import { useChatStore } from "@/pinia-store/chat";
import { useEncountersStore } from "@/pinia-store/encounters";
import { useMediaStore } from "@/pinia-store/media";
import { useObservationsStore } from "@/pinia-store/observations";
import { useVideoRoomStore } from "@/pinia-store/videoRoom";
import { useVisitNoteStore } from "@/pinia-store/visitNote";
import { ObservationEnum } from "@/types/ObservationEnum";
import { RolesEnum } from "@/types/Roles.enum";
import { errToText, feetToFeetInchesDisplay } from "@/utils/conversion";
import AddBloodGlucose from "@/views/Patient/Monitoring/bloodGlucose/Add";
import DrawerBloodGlucose from "@/views/Patient/Monitoring/bloodGlucose/Drawer";
import AddBloodPressure from "@/views/Patient/Monitoring/bloodPressure/Add";
import DrawerBloodPressure from "@/views/Patient/Monitoring/bloodPressure/Drawer";
import AddBodyHeight from "@/views/Patient/Monitoring/bodyHeight/Add";
import DrawerBodyHeight from "@/views/Patient/Monitoring/bodyHeight/Drawer";
import AddBodyTemperature from "@/views/Patient/Monitoring/bodyTemperature/Add";
import DrawerBodyTemperature from "@/views/Patient/Monitoring/bodyTemperature/Drawer";
import AddBodyWeight from "@/views/Patient/Monitoring/bodyWeight/Add";
import DrawerBodyWeight from "@/views/Patient/Monitoring/bodyWeight/Drawer";
import AddHeartRate from "@/views/Patient/Monitoring/heartRate/Add";
import DrawerHeartRate from "@/views/Patient/Monitoring/heartRate/Drawer";
import AddOxygenSaturation from "@/views/Patient/Monitoring/oxygenSaturation/Add";
import DrawerOxygenSaturation from "@/views/Patient/Monitoring/oxygenSaturation/Drawer";
import AddRespiratoryRate from "@/views/Patient/Monitoring/respiratoryRate/Add";
import DrawerRespiratoryRate from "@/views/Patient/Monitoring/respiratoryRate/Drawer";
import AddSmokingBehavior from "@/views/Patient/Monitoring/smokingBehavior/Add";
import DrawerSmokingBehavior from "@/views/Patient/Monitoring/smokingBehavior/Drawer";

export default {
  name: "PractitionerVisitDescription",
  components: {
    MediaUploader,
    FileRow,
    SpeechToTextBtn,
    CallNowAction,
    ChatNowAction,
    MeetNowMenu,
    AddSmokingBehavior,
    AddBodyWeight,
    AddBodyHeight,
    AddBloodGlucose,
    AddBloodPressure,
    AddBodyTemperature,
    AddHeartRate,
    AddOxygenSaturation,
    AddRespiratoryRate,
    PrimaryButton,
    Confirm,
    OutlinedButton,
    DrawerSmokingBehavior,
    DrawerBodyWeight,
    DrawerBodyHeight,
    DrawerRespiratoryRate,
    DrawerOxygenSaturation,
    DrawerHeartRate,
    DrawerBodyTemperature,
    DrawerBloodPressure,
    DrawerBloodGlucose,
    ActionField,
  },
  data: () => ({
    embedHeight: "100vh",
    loadingEligibility: false,
    eligibility: false,
    dosespotDialog: false,
    dosespotUIUrl: "",
    dosespotLoaded: false,
    loadingDosespot: false,
    dosespotPatientId: false,
    dosespotProfile: {},
    clinicId: "",
    clinicianId: "",
    encryptedClinicId: "",
    encryptedUserId: "",
  }),
  props: {
    isPatientInfoRequired: { default: false, type: Boolean },
  },
  watch: {
    // todo: check watcher
    visitNote: {
      immediate: true,
      deep: true,
      async handler(val) {
        if (val?.patient?.id) {
          this.getMedicationStatement();
          this.getPharmacy();
          this.setUid(val?.patient?.id);
          await this.fetchPatientAllFiles(val?.patient?.id);
          this.setObservationPatientId(val?.patient?.id);
          await this.getObservationsSummary(val?.patient?.id);
          await this.getPatientInfo(val?.patient?.id);
        }
        if (val?.practitioner?.id) {
          await this.getPractitionerInfo(val.practitioner.id);
        }
      },
    },
  },
  computed: {
    ...mapState(useMediaStore, ["files", "uid", "patientAllFiles"]),
    ...mapState(useAuthStore, ["role"]),
    ...mapState(useAuthStore, { loggedUserId: "uid" }),
    ...mapState(useVideoRoomStore, ["room"]),
    ...mapState(useVisitNoteStore, [
      "patientInfo",
      "patientEmail",
      "patientPhoneNumber",
      "patientHomePhoneNumber",
      "visitDescription",
      "encounterNotes",
      "patientBodyWeights",
      "patientBodyHeights",
      "patientSmokingBehaviors",
      "observationsSummary",
      "pharmacy",
    ]),
    ...mapState(useEncountersStore, ["currentEncounter"]),
    observationEnum() {
      return ObservationEnum;
    },

    patientBirthDate() {
      let res = this.$moment(this.patientInfo.birthDate);
      return res.isValid() ? res.format(timeDisplayFormat) : "";
    },
    cleanDosespotPhone() {
      //required :  111 111-1111
      if (!this.dosespotProfile?.homePhoneNumber) return null;
      return this.dosespotProfile.homePhoneNumber
        .replace("+1", "")
        .replace("(", "")
        .replace(")", " ")
        .replaceAll("-", "");
    },
    cleanDosespotCellPhone() {
      //required :  111 111-1111
      if (!this.dosespotProfile?.phoneNumber) return null;
      return this.dosespotProfile.phoneNumber.replace("+1", "").replace("(", "").replace(")", " ").replaceAll("-", "");
    },
    gender() {
      return this.patientInfo.gender.replace(/^\w/, (c) => c.toUpperCase());
    },
    dosespotGender() {
      return upperFirst(this.dosespotProfile.gender || "");
    },
  },
  methods: {
    ...mapActions(useVisitNoteStore, ["getPharmacy"]),
    ...mapActions(useVisitNoteStore, ["getMedicationStatement"]),
    ...mapActions(useChatStore, ["toggleSendSMSDialog", "setSendSmsToPhoneNumber", "setSendSmsMessage"]),
    ...mapActions(useObservationsStore, [
      "toggleBloodGlucoseTableOpened",
      "toggleBloodGlucoseFormOpened",
      "toggleBloodPressureTableOpened",
      "toggleBloodPressureFormOpened",
      "toggleBodyTemperatureTableOpened",
      "toggleBodyTemperatureFormOpened",
      "toggleHeartRateTableOpened",
      "toggleHeartRateFormOpened",
      "toggleOxygenSaturationTableOpened",
      "toggleOxygenSaturationFormOpened",
      "toggleRespiratoryRateTableOpened",
      "toggleRespiratoryRateFormOpened",
      "toggleSmokingBehaviorTableOpened",
      "toggleSmokingBehaviorFormOpened",
      "toggleBodyHeightTableOpened",
      "toggleBodyHeightFormOpened",
      "toggleBodyWeightTableOpened",
      "toggleBodyWeightFormOpened",
      "clearBloodGlucoseForm",
      "clearOxygenSaturationForm",
      "clearBodyTemperatureForm",
      "clearRespiratoryRateForm",
      "clearHeartRateForm",
      "clearBloodPressureForm",
      "clearBodyHeightForm",
      "clearBodyWeightForm",
    ]),
    ...mapActions(useVisitNoteStore, ["getObservationsSummary", "getPatientInfo", "getPractitionerInfo"]),
    ...mapActions(useObservationsStore, { setObservationPatientId: "setPatientId" }),
    ...mapActions(useMediaStore, ["setUid"]),
    ...mapActions(useMediaStore, ["fetchComponentFiles", "fetchPatientAllFiles"]),
    reloadPatientFiles() {
      this.fetchPatientAllFiles(this.patientInfo.id);
    },
    async onFileLoaded() {
      await this.fetchComponentFiles({ component: "appointments", componentId: this.room.id });
    },
    openSendSMSDialog() {
      this.setSendSmsToPhoneNumber(this.patientPhoneNumber);
      this.setSendSmsMessage("");
      this.toggleSendSMSDialog();
    },
    toggleHealthDataDialog(kind) {
      switch (kind) {
        case this.observationEnum.bloodGlucose: {
          this.clearBloodGlucoseForm();
          this.toggleBloodGlucoseFormOpened();
          break;
        }
        case this.observationEnum.oxygenSaturation: {
          this.clearOxygenSaturationForm();
          this.toggleOxygenSaturationFormOpened();
          break;
        }
        case this.observationEnum.bodyTemperature: {
          this.clearBodyTemperatureForm();
          this.toggleBodyTemperatureFormOpened();
          break;
        }
        case this.observationEnum.respiratoryRate: {
          this.clearRespiratoryRateForm();
          this.toggleRespiratoryRateFormOpened();
          break;
        }
        case this.observationEnum.heartRate: {
          this.clearHeartRateForm();
          this.toggleHeartRateFormOpened();
          break;
        }
        case this.observationEnum.bloodPressure: {
          this.clearBloodPressureForm();
          this.toggleBloodPressureFormOpened();
          break;
        }
        case this.observationEnum.bodyHeight: {
          this.clearBodyHeightForm();
          this.toggleBodyHeightFormOpened();
          break;
        }
        case this.observationEnum.bodyWeight: {
          this.clearBodyWeightForm();
          this.toggleBodyWeightFormOpened();
          break;
        }
      }
    },
    formatHeightValue(value) {
      return feetToFeetInchesDisplay(value);
    },
    onViewSmokingBehavior() {
      this.toggleSmokingBehaviorTableOpened();
    },
    async openDosespotDialog() {
      if (!this.patientInfo.id) return;

      if (this.dosespotLoaded) {
        this.dosespotDialog = !this.dosespotDialog;
        return;
      }
      this.loadingDosespot = true;
      try {
        let data = await DosespotApi.initUI(this.patientInfo.id);
        this.encryptedUserId = encodeURIComponent(data.encryptedUserId);
        this.encryptedClinicId = encodeURIComponent(data.encryptedClinicId);
        this.dosespotUIUrl = data.url;
        this.clinicianId = data.clinicianId;
        this.clinicId = data.clinicId;
        this.dosespotProfile = data.patientProfile || {};
        this.dosespotPatientId = data.dosespotPatientId ?? null;
        setTimeout(() => {
          this.dosespotLoaded = true;
          this.dosespotDialog = true;
        }, 3000);
      } catch (e) {
        console.error(e);
        this.loadingDosespot = false;
        snackBarEventBus.$emit(snackBarEventName, {
          message: errToText(e),
          type: "error",
        });
      } finally {
        setTimeout(() => {
          this.loadingDosespot = false;
        }, 3000);
      }
    },
    toggleDosespotDialog() {
      this.dosespotDialog = !this.dosespotDialog;
    },
    async checkEligibility() {
      this.loadingEligibility = true;
      this.eligibility = await PatientsApi.getEligibility(this.patientInfo.id);
      this.loadingEligibility = false;
    },
    onViewBodyWeight() {
      this.toggleBodyWeightTableOpened();
    },
    onViewBodyHeight() {
      this.toggleBodyHeightTableOpened();
    },
    translateChiefComplaint(val) {
      return this.$t("general.chiefComplaints")[val] || val || "-";
    },
  },
  beforeDestroy() {
    this.$root.$off(OBSERVATION_ANY_ADDED, this.getObservationsSummary);
  },
  async mounted() {
    if (this.role === RolesEnum.Patient) {
      this.setUid(this.loggedUserId);
    }
    this.$root.$on(OBSERVATION_ANY_ADDED, this.getObservationsSummary);
  },
};
</script>
<style lang="scss" scoped>
@import "./practitioner";

.wrap-contact-actions {
  border-top: 1px solid var(--v-primarylight2-base);
}

.media-all-files-wrap {
  max-height: 300px;
  overflow-x: auto;
}

:deep .send-message-btn {
  .v-btn__content {
    font-size: 0.875rem !important;
  }
}

.patient-name-wrap {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}
</style>
