<template>
  <div class="visit-summary_letter">
    <span class="visit-summary_letter_title">
      {{ dearPatient }}
    </span>
    <v-card-text class="visit-summary_section-text visit-summary_letter_text">
      {{ encounterNotes.followUp }}
    </v-card-text>
    <div class="d-flex align-center">
      <Avatar :userId="currentEncounter.practitioner.id" class="mr-2" />
      <div>
        <p class="practitioner-name mb-0">
          {{ practitionerName }}
        </p>
        <p class="sub-heading-3 mb-0">{{ practitioner.qualification }}</p>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapState } from "pinia";

import Avatar from "@/components/Avatar/Index";
import { useEncountersStore } from "@/pinia-store/encounters";
import { usePractitionersStore } from "@/pinia-store/practitioners";
import { useVisitNoteStore } from "@/pinia-store/visitNote";

export default {
  name: "VisitSummaryLetter",
  components: {
    Avatar,
  },
  computed: {
    ...mapActions(useEncountersStore, ["currentEncounter"]),
    ...mapState(useVisitNoteStore, ["patientInfo", "encounterNotes"]),
    ...mapState(usePractitionersStore, ["practitioner"]),
    dearPatient() {
      return `${this.$t("visits.summary.dear")} ${this.patientInfo.firstName},`;
    },
    practitionerName() {
      if (!this.currentEncounter) return "";
      return `${this.$t("general.titles.dr")} ${this.currentEncounter.practitioner.name}`;
    },
  },
  methods: {
    ...mapActions(usePractitionersStore, ["getPractitionerById"]),
  },
  async mounted() {
    await this.getPractitionerById(this.currentEncounter.practitioner.id);
  },
};
</script>
