import { User } from "@twilio/conversations";
import { BehaviorSubject } from "rxjs";
import { Socket } from "socket.io-client";

import IChatUser from "@/context/twilioContext/interfaces/ITwilioChatUser";
import BotStatusUpdate from "@/context/twilioContext/types/BotStatusUpdate";
import { ChatMemberDto } from "@/context/twilioContext/types/ChatMemberDto";
import { TwilioParticipantAttributes } from "@/context/twilioContext/types/TwilioChatMemberAttirbutes";
import { RolesEnum } from "@/types/Roles.enum";

export type TwilioChatMemberStatus = "online" | "offline" | "noService";

class ChatUser implements IChatUser {
  private readonly _twilioUser: User;
  private readonly _userMeta: ChatMemberDto;
  private readonly _statusInChannel = new Map<string, BehaviorSubject<TwilioChatMemberStatus>>();
  private readonly _botStatusSocket: Socket;

  constructor(user: User, chatMemberDto: ChatMemberDto, socket: Socket) {
    this._twilioUser = user ? user : (chatMemberDto as unknown as User);

    this._userMeta = chatMemberDto;
    this._botStatusSocket = socket;
    this._botStatusSocket.on("botStatusUpdate", (value: BotStatusUpdate) => {
      this._statusInChannel.get(value.twilioChannelSid)?.next(value.status);
    });
  }

  get attributes(): TwilioParticipantAttributes {
    return (this._twilioUser?.attributes || {}) as TwilioParticipantAttributes;
  }

  get uid(): string {
    return this._twilioUser?.identity || this._userMeta?.uid;
  }

  get displayName(): string {
    return this._userMeta?.displayName ?? `UnnamedUser`;
  }

  get meta(): ChatMemberDto {
    return this._userMeta || {};
  }

  get role(): RolesEnum {
    return this._userMeta?.role ?? RolesEnum.Anonymous;
  }

  private static handleTwilioUserStatusUpdate(
    event: UserUpdateEvent,
    subject: BehaviorSubject<TwilioChatMemberStatus>,
  ) {
    if (event.updateReasons.includes("reachabilityOnline")) {
      switch (event.user.isOnline) {
        case true:
          subject.next("online");
          return;
        case false:
          subject.next("offline");
          return;
      }
    }
  }

  public getChannelStatusObservable(channelSid: string): BehaviorSubject<TwilioChatMemberStatus> {
    const statusObservable = this._statusInChannel.get(channelSid);
    if (!statusObservable) {
      console.error(`This channel as not been assigned to the member.`, this._statusInChannel.entries());
      throw new Error(`This channel as not been assigned to the member`);
    }
    return statusObservable;
  }

  public async assignChannel(channelSid: string) {
    const subject = new BehaviorSubject<TwilioChatMemberStatus>("offline");
    this._statusInChannel.set(channelSid, subject);
    subject.next(this._twilioUser?.isOnline ? "online" : "offline");
    if (this._twilioUser.on)
      this._twilioUser.on("updated", (event) => ChatUser.handleTwilioUserStatusUpdate(event, subject));
  }

  public async unassignChannel(channelSid: string) {
    const found = this._statusInChannel.get(channelSid);
    found?.unsubscribe();
    this._statusInChannel.delete(channelSid);
  }
}

export type UserUpdateReason = "friendlyName" | "attributes" | "reachabilityOnline" | "reachabilityNotifiable";
export type UserUpdateEvent = { user: User; updateReasons: UserUpdateReason[] };

export default ChatUser;
