import moment from "moment-timezone";
import { defineStore } from "pinia";

import { AdminAPI } from "@/api/admin";
import { CheckoutApi } from "@/api/checkout";
import { PractitionersAPI } from "@/api/practitioners";
import { CheckoutByStatusFilterDto } from "@/types/CheckoutByStatusFilterDto";
import { Practitioner } from "@/types/users";

export interface AdminState {
  practitionersWithSlots: Practitioner[];
  selectedPractitionerWithSlots: string;
  chosenPractitioner: string;
  slotInformation: any;
  pendingCheckouts: [];
}

export interface Category {
  text: string;
  value: string;
}

export interface Slot {
  end: string | number;
  id: string;
  scheduleId: string;
  start: string | number;
  status?: string;
  time?: string;
  category?: Category | string[] | string;
  color?: string;
  name?: string;
  times?: boolean;
}

export const useAdminStore = defineStore({
  id: "admin",
  state: () => ({
    selectedPractitionerWithSlots: "All",
    practitionersWithSlots: [],
    chosenPractitioner: "All",
    slotInformation: {},
    pendingCheckouts: [],
  }),

  actions: {
    async getAllPractitioners() {
      this.practitionersWithSlots = await PractitionersAPI.getAllPractitioners();
    },
    changeSelectedPractitioner(selectedPractitioner: string) {
      localStorage.setItem("selectedPractitioner", selectedPractitioner);
      this.selectedPractitionerWithSlots = selectedPractitioner;
    },
    chosePractitioner(id: string) {
      localStorage.setItem("chosenPractitioner", id);
      this.chosenPractitioner = id;
    },
    async getSlotInformation(id: string) {
      this.slotInformation = await AdminAPI.getSlotInformation(id);
    },
    cleanSlotInformation() {
      this.slotInformation = {};
    },
    async getPendingCheckouts(query?: CheckoutByStatusFilterDto) {
      this.pendingCheckouts = await CheckoutApi.getByStatus("initialized", query);
    },
  },
  getters: {
    practitionersWithSlotsName(state) {
      const practitionersName = state.practitionersWithSlots.map(
        (practitioner: Practitioner) => `${practitioner.firstName} ${practitioner.lastName}`,
      );
      return ["All", ...practitionersName];
    },
    practitionerWithSlotsName(state) {
      if (state.selectedPractitionerWithSlots && state.selectedPractitionerWithSlots !== "All") {
        return state.selectedPractitionerWithSlots;
      } else {
        return state.practitionersWithSlots.map(({ firstName, lastName, id }) => `${firstName} ${lastName},${id}`);
      }
    },
    practitionerFreeSlots(state) {
      if (state.practitionersWithSlots) {
        const selectedPractitioner = state.practitionersWithSlots.find(
          (practitioner: Practitioner) => practitioner.id === state.chosenPractitioner,
        );
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        if (selectedPractitioner && selectedPractitioner.slot) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          const freeSlots = selectedPractitioner.slot.filter((slot: Slot) => {
            return slot.status === "free" && slot.start.toString().slice(0, 10);
          });
          freeSlots.map((slot: Slot) => {
            slot.time = `${moment(slot.start).format("HH:mm")} - ${moment(slot.end).format("HH:mm")}`;
            return slot;
          });
          return freeSlots;
        } else return [];
      }
    },
    practitionerOtherSlots(state) {
      const otherSlots: Slot[] = [];
      const colors = [
        "#9B51E0",
        "#F2994A",
        "#2AB561",
        "#E84393",
        "#5F27CD",
        "#E17055",
        "#F2C94C",
        "#6FCF97",
        "#EC3D39",
      ];
      if (state.selectedPractitionerWithSlots === "All") {
        state.practitionersWithSlots.map((practitioner: Practitioner, index) => {
          const category = [`${practitioner.firstName} ${practitioner.lastName}`, practitioner.id];
          if (practitioner && practitioner.slot) {
            practitioner.slot.map((slot) => {
              if (slot && category && slot.status !== "free") {
                slot.name = category[0];
                slot.category = category.toString();
                slot.start = moment(slot.start).valueOf();
                slot.end = moment(slot.end).valueOf();
                slot.color = "rgba(242, 153, 74, 0.1)";
                slot.uiColor = colors[Math.floor(Math.random() * colors.length)];
                slot.timed = true;
                otherSlots.push(slot);
              } else return;
            });
          }
        });
      } else {
        state.practitionersWithSlots.map((practitioner: Practitioner, index) => {
          if (`${practitioner.firstName} ${practitioner.lastName}` === state.selectedPractitionerWithSlots) {
            const category = [`${practitioner.firstName} ${practitioner.lastName}`, practitioner.id];
            if (practitioner && practitioner.slot) {
              practitioner.slot.map((slot) => {
                if (slot && category && slot.status !== "free") {
                  slot.name = category[0];
                  slot.category = category.toString();
                  slot.start = moment(slot.start).valueOf();
                  slot.end = moment(slot.end).valueOf();
                  slot.color =
                    state.selectedPractitionerWithSlots === "All"
                      ? colors[Math.floor(Math.random() * colors.length)]
                      : "transparent";
                  slot.timed = true;
                  otherSlots.push(slot);
                } else return;
              });
            }
          } else {
            return;
          }
        });
      }
      return otherSlots;
    },
  },
});
