var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-expansion-panels',{staticClass:"elevation-0 vitals",attrs:{"accordion":"","flat":""},model:{value:(_vm.panel),callback:function ($$v) {_vm.panel=$$v},expression:"panel"}},[_c('v-expansion-panel',[_c('v-expansion-panel-header',[_vm._v(" Vitals")]),_c('v-divider',{staticClass:"full-width"}),_c('v-expansion-panel-content',{},[(_vm.isLoadingSummary)?_c('v-skeleton-loader',{staticClass:"mx-0 full-width",attrs:{"boilerplatstartTimee":true,"type":"list-item-three-line, list-item-three-line, list-item-two-line, list-item-two-line, list-item-two-line, list-item-two-line"}}):_c('div',{staticClass:"pt-5"},[_c('ActionField',{attrs:{"show-add":false,"title":`Blood glucose`,"unit":(_vm.summary.bloodGlucose && _vm.summary.bloodGlucose.unit) || '',"value":[
              `before meal: ${(_vm.summary.bloodGlucose && _vm.summary.bloodGlucose.beforeMeal) || '--'}`,
              `after meal: ${(_vm.summary.bloodGlucose && _vm.summary.bloodGlucose.afterMeal) || '--'}`,
            ]},on:{"onView":_vm.toggleBloodGlucoseTableOpened}}),_c('ActionField',{attrs:{"show-add":false,"title":`Blood pressure`,"unit":(_vm.summary.bloodPressure && _vm.summary.bloodPressure.unit) || '',"value":[
              `diastolic: ${(_vm.summary.bloodPressure && _vm.summary.bloodPressure.diastolic) || '--'}`,
              `systolic: ${(_vm.summary.bloodPressure && _vm.summary.bloodPressure.systolic) || '--'}`,
            ]},on:{"onView":_vm.toggleBloodPressureTableOpened}}),_c('ActionField',{attrs:{"show-add":false,"title":`Body temperature`,"unit":(_vm.summary.bodyTemperature && _vm.summary.bodyTemperature.unit) || '',"value":(_vm.summary.bodyTemperature && _vm.summary.bodyTemperature.value) || '--'},on:{"onView":_vm.toggleBodyTemperatureTableOpened}}),_c('ActionField',{attrs:{"show-add":false,"title":`Heart rate`,"unit":(_vm.summary.heartRate && _vm.summary.heartRate.unit) || '',"value":(_vm.summary.heartRate && _vm.summary.heartRate.value) || '--'},on:{"onView":_vm.toggleHeartRateTableOpened}}),_c('ActionField',{attrs:{"show-add":false,"title":`Oxygen saturation`,"unit":(_vm.summary.oxygenSaturation && _vm.summary.oxygenSaturation.unit) || '',"value":(_vm.summary.oxygenSaturation && _vm.summary.oxygenSaturation.value) || '--'},on:{"onView":_vm.toggleOxygenSaturationTableOpened}}),_c('ActionField',{attrs:{"show-add":false,"title":`Respiratory rate`,"unit":(_vm.summary.respiratoryRate && _vm.summary.respiratoryRate.unit) || '',"value":(_vm.summary.respiratoryRate && _vm.summary.respiratoryRate.value) || '--'},on:{"onView":_vm.toggleRespiratoryRateTableOpened}})],1)],1)],1)],1),_c('DrawerBloodGlucose'),_c('DrawerBloodPressure'),_c('DrawerBodyTemperature'),_c('DrawerHeartRate'),_c('DrawerOxygenSaturation'),_c('DrawerRespiratoryRate')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }