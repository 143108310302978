import { defineStore } from "pinia";

import { MonitoringApi, MonitoringFilters } from "@/api/monitoring";

export interface MonitoringState {
  orders: [];
}

export const useMonitoringStore = defineStore({
  id: "monitoring",
  state: (): MonitoringState => ({
    orders: [],
  }),
  actions: {
    setOrders(payload: []) {
      this.orders = payload;
    },
    async getOrders(payload: MonitoringFilters) {
      this.orders = await MonitoringApi.getOrders(payload);
    },
  },
  getters: {
    hasAnyMonitoringOrders: (state) => Boolean(state.orders.length > 0),
  },
});
