import { apiRequest } from "./apiRequest";

export interface ITwoFactorBody {
  twoFactorAuthenticationCode: number;
}

const getQrcode = async () => {
  const { data } = await apiRequest.post("/2fa/generate");
  return data;
};

const sendVerifynumbers = async (body: ITwoFactorBody) => {
  const { data } = await apiRequest.post("/2fa/authenticate", body);
  return data;
};

const checkOutQrcode = async () => {
  const response = await apiRequest.get(`/2fa/settings`);
  return response?.data;
};

export const TwoFactorApi = {
  getQrcode,
  sendVerifynumbers,
  checkOutQrcode,
};
