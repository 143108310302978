import moment from "moment-timezone";
import { defineStore } from "pinia";

import { OwnersAPI } from "@/api/owners";
import { RegistrarsAPI } from "@/api/registrars";
import { Practitioner, Registrar } from "@/types/users";

interface RegistrarsState {
  registrars: Registrar[];
  practitionersWithSlots: Practitioner[];
  selectedPractitionerWithSlots: string;
  chosenPractitioner: string;
  slotInformation: any;
  registrarPractitioners: Practitioner[];
}

interface Category {
  text: string;
  value: string;
}

interface Slot {
  end: string | number;
  id: string;
  scheduleId: string;
  start: string | number;
  status?: string;
  time?: string;
  category?: Category | string[] | string;
  color?: string;
  name?: string;
  times?: boolean;
}

export const useRegistrarsStore = defineStore({
  id: "registrars",
  state: (): RegistrarsState => ({
    registrars: [],
    registrarPractitioners: [],
    practitionersWithSlots: [],
    chosenPractitioner: "All",
    slotInformation: {},
    selectedPractitionerWithSlots: "All",
  }),
  actions: {
    async getAllRegistrars() {
      this.registrars = await RegistrarsAPI.getAllRegistrars();
    },
    async getRegistrarPractitionersWithSlots(id: string) {
      this.practitionersWithSlots = await RegistrarsAPI.getRegistrarPractitionersWithSlots(id);
    },
    async getRegistrarPractitioners(id: string) {
      this.registrarPractitioners = await RegistrarsAPI.getRegistrarPractitioners(id);
    },
    async getSlotInformation(id: string) {
      this.slotInformation = await OwnersAPI.getSlotInformation(id);
    },
    cleanSlotInformation() {
      this.slotInformation = {};
    },
    changeSelectedPractitioner(selectedPractitioner: string) {
      localStorage.setItem("selectedPractitioner", selectedPractitioner);
      this.selectedPractitionerWithSlots = selectedPractitioner;
    },
    chosePractitioner(id: string) {
      localStorage.setItem("chosenPractitioner", id);
      this.chosenPractitioner = id;
    },
  },
  getters: {
    practitionersWithSlotsName(state) {
      const practitionersName = state.practitionersWithSlots.map(
        (practitioner: Practitioner) => `${practitioner.firstName} ${practitioner.lastName}`,
      );
      return ["All", ...practitionersName];
    },
    practitionerWithSlotsName(state) {
      if (state.selectedPractitionerWithSlots && state.selectedPractitionerWithSlots !== "All") {
        return state.selectedPractitionerWithSlots;
      } else {
        return state.practitionersWithSlots.map(({ firstName, lastName, id }) => `${firstName} ${lastName},${id}`);
      }
    },
    practitionerFreeSlots(state) {
      if (state.practitionersWithSlots) {
        const selectedPractitioner = state.practitionersWithSlots.find(
          (practitioner: Practitioner) => practitioner.id === state.chosenPractitioner,
        );
        if (selectedPractitioner && selectedPractitioner.slot) {
          const freeSlots = selectedPractitioner.slot.filter((slot: Slot) => {
            return slot.status === "free" && slot.start.toString().slice(0, 10);
          });
          freeSlots.map((slot: Slot) => {
            slot.time = `${moment(slot.start).format("HH:mm")} - ${moment(slot.end).format("HH:mm")}`;
            return slot;
          });
          return freeSlots;
        } else return [];
      }
    },
    practitionerOtherSlots(state) {
      const otherSlots: Slot[] = [];
      const colors = ["#9B51E0", "#F2994A", "#2AB561", "#E84393", "#5F27CD", "#E17055", "#F2C94C", "#6FCF97"];
      if (state.selectedPractitionerWithSlots === "All") {
        state.practitionersWithSlots.map((practitioner: Practitioner, index) => {
          const category = [`${practitioner.firstName} ${practitioner.lastName}`, practitioner.id];
          if (practitioner && practitioner.slot) {
            practitioner.slot.map((slot) => {
              if (slot && category && slot.status !== "free") {
                slot.name = category[0];
                slot.category = category.toString();
                slot.start = moment(slot.start).valueOf();
                slot.end = moment(slot.end).valueOf();
                slot.color = "rgba(242, 153, 74, 0.1)";
                slot.uiColor = colors[Math.floor(Math.random() * colors.length)];
                slot.timed = true;
                otherSlots.push(slot);
              } else return;
            });
          }
        });
      } else {
        state.practitionersWithSlots.map((practitioner: Practitioner, index) => {
          if (`${practitioner.firstName} ${practitioner.lastName}` === state.selectedPractitionerWithSlots) {
            const category = [`${practitioner.firstName} ${practitioner.lastName}`, practitioner.id];
            if (practitioner && practitioner.slot) {
              practitioner.slot.map((slot) => {
                if (slot && category && slot.status !== "free") {
                  slot.name = category[0];
                  slot.category = category.toString();
                  slot.start = moment(slot.start).valueOf();
                  slot.end = moment(slot.end).valueOf();
                  slot.color =
                    state.selectedPractitionerWithSlots === "All"
                      ? colors[Math.floor(Math.random() * colors.length)]
                      : "transparent";
                  slot.timed = true;
                  otherSlots.push(slot);
                } else return;
              });
            }
          } else {
            return;
          }
        });
      }
      return otherSlots;
    },
  },
});
