
import Vue from "vue";

export default Vue.extend({
  name: "ChatButton",

  props: {
    button: {
      type: Object,
      required: true,
    },
    responseDelegate: {
      type: Function,
      required: true,
    },
  },
  data: () => ({
    value: "",
    clicked: false,
  }),
  methods: {
    buttonClicked(): void {
      this.clicked = true;
      this.responseDelegate(undefined, this.button.value);
    },
  },
});
