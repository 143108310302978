<template>
  <v-avatar :size="size">
    <v-img :lazy-src="defaultImage" :src="img" @error="imageError = true" />
  </v-avatar>
</template>

<script>
import { mapState as mapPiniaState } from "pinia";
import { mapActions as mapPiniaActions } from "pinia";

import { ChatApi } from "@/api/chat";
import { useAvatarsStore } from "@/pinia-store/avatars";

export default {
  name: "Avatar",
  props: {
    src: null,
    user: null,
    userId: null,
    size: null,
  },
  data() {
    return {
      defaultImage: require("@/assets/no-avatar.svg"),
      imgSrcByUserId: undefined,
      userProfile: null,
    };
  },

  methods: {
    ...mapPiniaActions(useAvatarsStore, ["setItem"]),
    async getUser() {
      if (!this.user && this.userId && !this.src) {
        this.userProfile = this.avatarOf(this.userId) || (await ChatApi.chatMember(this.userId));
        this.setItem(this.userProfile);
      } else {
        this.userProfile = this.user;
      }
    },
    getUserIdAvatar() {
      this.imgSrcByUserId = this.userProfile.photoURL;
    },
  },
  computed: {
    ...mapPiniaState(useAvatarsStore, ["avatarOf"]),
    img() {
      return this.imageError ? this.defaultImage : this.src ? this.src : this.imgSrcByUserId;
    },
  },
  mounted() {
    if (!this.src)
      this.getUser().then(() => {
        if (this.userProfile && !this.src) this.getUserIdAvatar();
      });
  },
  watch: {
    src: {
      immediate: true,
      handler(val) {
        if (val) this.imageError = false;
      },
    },
    user(newUserId) {
      this.getUser();
      if (!this.src) {
        this.getUserIdAvatar(newUserId);
      }
    },
  },
};
</script>
