import { apiRequest } from "@/api/apiRequest";

const createPastVisit = async (body: any) => {
  const { data } = await apiRequest.post("/audioVisitLog", body);
  return data;
};

export const PastVisitApi = {
  createPastVisit,
};
