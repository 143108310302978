import { apiRequest } from "@/api/apiRequest";

const add = async (body: Record<any, any> = {}, patientId: string) => {
  const { data } = await apiRequest.post(`/patient/${patientId}/allergies`, body);
  return data;
};

const destroy = async (allergyId: string, patientId: string) => {
  const { data } = await apiRequest.delete(`/patient/${patientId}/allergies/${allergyId}`);
  return data;
};

const search = async (name: string) => {
  const { data } = await apiRequest.get(`/allergies/search`, { params: { name } });
  return data;
};
const getSmokingBehavior = async () => {
  const { data } = await apiRequest.get(`/terminology/smoking-behavior`);
  return data;
};
const getPatientAllergies = async (patientId: string) => {
  const { data } = await apiRequest.get(`/patient/${patientId}/allergies`);
  return data;
};
const getConditions = async (filter = {}) => {
  const { data } = await apiRequest.get(`/terminology/conditions`, { params: filter });
  return data;
};
export const AllergiesApi = {
  getConditions,
  getPatientAllergies,
  search,
  getSmokingBehavior,
  add,
  destroy,
};
