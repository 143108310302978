<template>
  <div class="p-success-payment">
    <div class="p-success-payment_top">
      <div class="p-success-payment_top_avatars">
        <div class="p-success-payment_top_avatars_circle_outer p-success-payment_top_avatars_circle_left">
          <div class="p-success-payment_top_avatars_circle_inner">
            <span>{{ partnerInitials }}</span>
          </div>
        </div>
        <Avatar
          :userId="partnerId"
          class="p-success-payment_top_avatars_circle_inner p-success-payment_top_avatars_circle_right"
          size="52"
        />
      </div>
      <div class="p-success-payment_top_background"></div>
      <span v-if="!loading" class="p-success-payment_top_title">
        <img alt="ok" src="@/assets/icons/green-tick-rounded.svg" />
        <span class="heading-4">{{ heading }}</span>
      </span>
      <span v-else>
        <span class="d-flex">
          <v-progress-circular class="mr-2" color="primary" indeterminate size="22" width="2"></v-progress-circular>
          <span class="heading-4 mb-4">Pending Request</span>
        </span>
      </span>
      <span class="p-success-payment_top_text">{{ topText }}</span>
    </div>
    <div class="p-success-payment_middle">
      <v-row no-gutters>
        <PatientDetails v-if="!showPaymentDetails" :patient="patient()" :practitioner="practitioner()" />
        <v-col class="p-success-payment_middle_right" cols="12">
          <v-row no-gutters>
            <v-col col="2">
              <img alt="calendar" src="@/assets/icons/calendar-blue.svg" />
            </v-col>
            <v-col class="p-success-payment_middle_right_column" cols="10">
              <span class="p-success-payment_middle_left_pre-text">{{ $t("visits.payment.scheduledDate") }}:</span>
              <span>{{ visitStartDate }}</span>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col col="2">
              <img alt="time" src="@/assets/icons/time-blue.svg" />
            </v-col>
            <v-col class="p-success-payment_middle_right_column" cols="10">
              <span class="p-success-payment_middle_left_pre-text">{{ $t("visits.payment.visitDuration") }}:</span>
              <span>{{ `${visitStart} - ${visitEnd}` }} {{ gmtText }}</span>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>
    <div class="p-success-payment_bottom">
      <PrimaryButton
        :disabled="loading"
        :fullWidth="true"
        :text="$t('general.buttons.done')"
        size="large"
        @onClick="redirectToVisitCenter"
      />
    </div>
  </div>
</template>
<script>
import { DateTime } from "luxon";
import moment from "moment-timezone";
import { mapActions, mapState } from "pinia";

import { AppointmentsApi } from "@/api/appointment";
import { PractitionersAPI } from "@/api/practitioners";
import { SlotsApi } from "@/api/slots";
import Avatar from "@/components/Avatar/Index";
import { timeDisplayFormat } from "@/components/Clinicians/constants";
import PrimaryButton from "@/components/uikit/PrimaryButton";
import { snackBarEventBus, snackBarEventName } from "@/eventBuses/snackBar.eventBus";
import { timeZoneMixin } from "@/mixins/timeZone";
import { useAppointmentsStore } from "@/pinia-store/appointments";
import { useAuthStore } from "@/pinia-store/auth";
import { useCheckoutStore } from "@/pinia-store/checkout";
import { usePatientStore } from "@/pinia-store/patient";
import { usePatientsStore } from "@/pinia-store/patients";
import { usePractitionersStore } from "@/pinia-store/practitioners";
import { useProfileSettingsStore } from "@/pinia-store/profileSettings";
import { useSlotsStore } from "@/pinia-store/slots";
import patient from "@/router/patient";
import practitioner from "@/router/practitioner";
import { RolesEnum } from "@/types/Roles.enum";
import { routesEnum } from "@/types/Routes.enum";

import PatientDetails from "./PatientDetails";

export default {
  name: "SuccessPaymentIndex",
  props: ["step", "mode"],
  components: {
    PrimaryButton,
    Avatar,
    PatientDetails,
  },
  data() {
    return {
      loading: false,
      visitStartDate: "",
      visitStart: "",
      visitEnd: "",
      practitionerFullName: {
        firstName: "",
        lastName: "",
      },
    };
  },
  computed: {
    ...mapState(useAuthStore, ["role", "uid"]),
    ...mapState(usePatientStore, ["patient", "patientsPractitioner"]),
    ...mapState(useAppointmentsStore, [
      "practitionerId",
      "patientId",
      "chiefComplaint",
      "visitDate",
      "complaintDescription",
      "slotId",
    ]),
    ...mapState(usePatientsStore, ["patients"]),
    ...mapState(useSlotsStore, ["slotsArray", "currentSlot"]),
    ...mapState(useProfileSettingsStore, ["locale"]),
    ...mapState(usePractitionersStore, ["practitioners", "practitioner"]),
    heading() {
      return this.$t("practitioner.appointments.success.title");
    },
    topText() {
      const practitionerName = `${this.$t("general.titles.dr")} ${this.practitionerFullName.firstName} ${
        this.practitionerFullName.lastName
      }`;
      const ops = {
        [RolesEnum.Patient]: `Thank you for booking the visit with ${practitionerName}`,
        [RolesEnum.Practitioner]: this.$t("practitioner.appointments.success.text"),
        [RolesEnum.Owner]: this.$t("practitioner.appointments.success.text"),
        [RolesEnum.Registrar]: this.$t("practitioner.appointments.success.text"),
        [RolesEnum.Admin]: this.$t("practitioner.appointments.success.text"),
      };
      return ops[this.role] || "";
    },
    partnerInitials() {
      if (this?.patientsPractitioner?.id !== "" || this.patient?.id !== "" || this.practitionerId !== "") {
        const ops = {
          [RolesEnum.Patient]: `${this.practitionerFullName?.firstName?.[0]?.toUpperCase() || ""} ${
            this.practitionerFullName?.lastName?.[0]?.toUpperCase() || ""
          }`,
          [RolesEnum.Practitioner]: `${this.patient?.firstName?.[0]?.toUpperCase() || ""} ${
            this.patient?.lastName?.[0]?.toUpperCase() || ""
          }`,
          [RolesEnum.Owner]: `${this.patient?.firstName?.[0]?.toUpperCase() || ""} ${
            this.patient?.lastName?.[0]?.toUpperCase() || ""
          }`,
          [RolesEnum.Registrar]: `${this.patient?.firstName?.[0]?.toUpperCase() || ""} ${
            this.patient?.lastName?.[0]?.toUpperCase() || ""
          }`,
          [RolesEnum.Admin]: `${this.patient?.firstName?.[0]?.toUpperCase() || ""} ${
            this.patient?.lastName?.[0]?.toUpperCase() || ""
          }`,
        };
        return ops[this.role] || "";
      }
      return "";
    },
    partnerId() {
      const ops = {
        [RolesEnum.Patient]: this.patientsPractitioner && this.patientsPractitioner.id,
        [RolesEnum.Practitioner]: this.patient && this.patient.id,
        [RolesEnum.Owner]: this.patient && this.patient.id,
        [RolesEnum.Registrar]: this.patient && this.patient.id,
        [RolesEnum.Admin]: this.patient && this.patient.id,
      };
      return ops[this.role];
    },
    price() {
      return this.session ? this.session.price : 0;
    },
    paymentDate() {
      return DateTime.local().toFormat("MMMM d, yyyy");
    },
    showPaymentDetails() {
      return this.role === RolesEnum.Patient;
    },
    localStep: {
      get() {
        return this.step;
      },
      set(newValue) {
        this.$emit("update:step", newValue);
      },
    },
  },
  mixins: [timeZoneMixin],
  methods: {
    patient() {
      return patient;
    },
    practitioner() {
      return practitioner;
    },
    ...mapActions(useCheckoutStore, ["setStringFieldByName", "setVisitDate"]),
    ...mapActions(useSlotsStore, ["setCurrentSlot"]),
    ...mapActions(usePractitionersStore, ["setPractitioner"]),
    ...mapActions(useCheckoutStore, ["submitSecondStep", "submitEditMode"]),
    ...mapActions(useSlotsStore, ["getSlotById"]),
    ...mapActions(usePatientStore, ["getPatientProfile"]),
    ...mapActions(useAppointmentsStore, ["updateAppointment", "clearAllFields"]),
    async redirectToVisitCenter() {
      this.$emit("done");
      return this.role === RolesEnum.Patient
        ? await this.$router.push("/patient/visits/upcoming")
        : await this.$router.push(`/${this.role}/appointments`);
    },
    async scheduleSlotInformation() {
      if (!this.currentSlot && this.mode) {
        const slot = await SlotsApi.getById(this.slotId);
        this.visitStartDate = moment(slot.start).tz(this.timeZone).format(timeDisplayFormat);
        this.visitStart = moment(slot.start).tz(this.timeZone).format("HH:mm A");
        this.visitEnd = moment(slot.end).tz(this.timeZone).format("HH:mm A");
      }
      this.visitStartDate = moment(this.currentSlot.start).tz(this.timeZone).format(timeDisplayFormat);
      this.visitStart = moment(this.currentSlot.start).tz(this.timeZone).format("HH:mm A");
      this.visitEnd = moment(this.currentSlot.end).tz(this.timeZone).format("HH:mm A");
    },
    async schedule() {
      let getCookiesClockId = "";
      if (this.$cookies.get("slots")) {
        getCookiesClockId = this.$cookies.get("slots").concat(",");
        getCookiesClockId = getCookiesClockId.concat(this.slotId);
        this.$cookies.set("slots", getCookiesClockId, "1h");
      } else {
        this.$cookies.set("slots", this.slotId, "1h");
      }

      try {
        this.loading = true;
        this.setStringFieldByName({ value: this.slotId, fieldName: "slotId" });
        await this.getSlotById(this.slotId);
        await this.getPatientProfile(this.patientId);
        if (this.role === RolesEnum.Patient) {
          if (this.mode === "edit") {
            await this.updateAppointment(this.$route.params.sessionId);
          } else {
            const settings = await PractitionersAPI.practitionerGetSettings(this.patientsPractitioner.id);
            const practitioner = await PractitionersAPI.getPractitionerById(this.practitionerId);
            this.practitionerFullName = {
              firstName: practitioner.firstName,
              lastName: practitioner.lastName,
            };
            await this.submitSecondStep(settings);
          }
        } else {
          await AppointmentsApi.createVisit(
            [],
            this.patientId,
            this.slotId,
            "pending",
            this.chiefComplaint,
            this.complaintDescription,
            false,
          );
        }
        let value = "";
        this.setStringFieldByName({ value, fieldName: "slotId" });
        this.loading = false;
      } catch (err) {
        console.error(err);
        if (err && err.response && err.response.status === 409) {
          this.errorType = "conflict";
          this.localStep = 4;
          snackBarEventBus.$emit(snackBarEventName, {
            message: this.$t("general.errors.slotConflict"),
            type: "error",
          });
          if (this.role === RolesEnum.Practitioner) {
            await this.getPractitionerFreeSlots({ practitionerId: this.uid });
          }
        }
        snackBarEventBus.$emit(snackBarEventName, {
          message: (err && err.response && err.response.data && err.response.data.message) || err,
          type: "error",
        });
      }
    },
  },
  async mounted() {
    if (this.$route.name === routesEnum.practitionerCheckoutComplete) {
      this.loading = true;
      const sessionId = this.$route.params.sessionId;
      const { slotId, status, patientId, practitionerId } = await AppointmentsApi.getById(sessionId);
      if (sessionId && status === "cancelled") {
        this.errorType = "conflict";
        this.localStep = 4;
        snackBarEventBus.$emit(snackBarEventName, {
          message: "The appointment has been canceled by other participant!",
          type: "error",
        });
        this.loading = false;
        await this.$router.push(`/${this.role}/appointments`);
        return;
      }
      const slot = await SlotsApi.getById(slotId);
      const practitioner = await PractitionersAPI.getPractitionerById(practitionerId);
      this.setPractitioner(practitioner);
      this.setCurrentSlot(slot);
      this.setStringFieldByName({ value: slotId, fieldName: "slotId" });
      this.setStringFieldByName({ value: patientId, fieldName: "patientId" });
      this.setStringFieldByName({ value: practitionerId, fieldName: "practitionerId" });
      await this.getPatientProfile(patientId);
      await this.scheduleSlotInformation();
      await AppointmentsApi.complete(sessionId);
      this.loading = false;
    } else {
      await this.schedule();
      await this.scheduleSlotInformation();
    }
  },
  beforeDestroy() {
    this.clearAllFields();
  },
};
</script>

<style scoped></style>
