import { Client, Conversation, ConversationUpdatedEventArgs, Participant } from "@twilio/conversations";
import PQueue from "p-queue";
import { Observable, Subject, Subscription } from "rxjs";
import { scan } from "rxjs/operators";
import { io } from "socket.io-client";

import { ChatApi } from "@/api/chat";
import ChatConversation from "@/context/twilioContext/ChatConversation/ChatConversation";
import ChatConversationBuilder from "@/context/twilioContext/ChatConversation/ChatConversationBuilder";
import MessagesRepository from "@/context/twilioContext/ChatConversation/MessagesRepository";
import ParticipantsRepository from "@/context/twilioContext/ChatConversation/ParticipantsRepository";
import TwilioConversation from "@/context/twilioContext/ChatConversation/TwilioConversation";
import ConversationRepository from "@/context/twilioContext/ConversationRepository";
import { ITwilioContext } from "@/context/twilioContext/interfaces/ITwilioContext";
import ITwilioClient from "@/context/twilioContext/ITwilioClient";
import { ChatLogRecordDto } from "@/context/twilioContext/TwilioChannelLog";
import ChatUser from "@/context/twilioContext/TwilioChatMember/ChatUser";
import TwilioClient from "@/context/twilioContext/TwilioClient";
import { ChatMemberDto } from "@/context/twilioContext/types/ChatMemberDto";
import { TwilioChatChannelAttributes } from "@/context/twilioContext/types/TwilioChatChannelAttributes";
import { TwilioClientEvent } from "@/context/twilioContext/types/TwilioClientEvent";
import { TwilioConversationUpdatePayload } from "@/context/twilioContext/types/TwilioEventPayloadTypes";
import { useAuthStore } from "@/pinia-store/auth";
import { useChatStore } from "@/pinia-store/chat";
import AnonymousChatCreateRequest from "@/types/AnonymousChatCreateRequest";
import ChatCreateRequest from "@/types/CreateChatRequestDto";
import CreateChatResponseDto from "@/types/CreateChatResponseDto";
import { RolesEnum } from "@/types/Roles.enum";
import { TicketCreateChatRequestDto } from "@/types/TicketCreateChatRequestDto";

class TwilioContext implements ITwilioContext {
  public readonly client: ITwilioClient;
  // todo: it do =>store all users that are participants in all chat channels, not only active, refactor later
  public readonly _activeUsers = new Map<string, ChatUser>();
  public readonly socket = io(`${process.env.VUE_APP_BASE_URL}chatStatus`);
  private _currentUserId: string | null = null;
  private readonly _channelRepository: ConversationRepository;
  private readonly _unconsumedMessagesDeltaSum = new Subject<number>();
  private readonly newConversationQueue = new PQueue({
    concurrency: 15,
    carryoverConcurrencyCount: false,
    autoStart: true,
  });

  constructor() {
    this.client = new TwilioClient();
    this._channelRepository = new ConversationRepository();
  }

  get unconsumedMessagesDeltaSum(): Observable<number> {
    return this._unconsumedMessagesDeltaSum.pipe(
      scan((acc, value = 1) => {
        return acc + value;
      }),
    );
  }

  get channelRepository(): ConversationRepository {
    return this._channelRepository;
  }

  get token(): string | null {
    return this.client.token;
  }

  private _channelsLoading = false;

  get channelsLoading(): boolean {
    return this._channelsLoading;
  }

  public initialize = async (uid?: string): Promise<void> => {
    const authStore = useAuthStore();
    this._channelsLoading = true;
    this._currentUserId = uid ?? null;
    this._activeUsers.clear();
    if (!this.client) {
      console.error("Twilio client is disconnected or missing");
      return;
    }
    await this.client.connectClient(uid);
    if (!process.env.VUE_APP_TWILIO_ANONYMOUS_USERNAME) throw new Error("TWILIO_ANONYMOUS_USERNAME is missing");

    await this.getUser(this._currentUserId ?? process.env.VUE_APP_TWILIO_ANONYMOUS_USERNAME);

    if (this._currentUserId && this._currentUserId !== process.env.VUE_APP_TWILIO_ANONYMOUS_USERNAME) {
      const channels = await this.client.listChannels();
      const chatLogs =
        authStore.role !== RolesEnum.Owner
          ? await ChatApi.listChatLogsByUserId(this._currentUserId)
          : await ChatApi.listAllChatLogs();

      const uniqueUsers = channels.reduce((prev, channel): Record<string, boolean> => {
        const attributes = channel.attributes as TwilioChatChannelAttributes;
        attributes.allMembersList?.forEach((memberId) => (prev[memberId] = true));
        return prev;
      }, {} as Record<string, boolean>);
      // todo: refactor this part to include all user ids
      chatLogs.reduce((prev, log): Record<string, boolean> => {
        prev[log.userId] = true;
        log.participantIds.forEach((memberId) => (prev[memberId] = true));
        return prev;
      }, uniqueUsers);
      await Promise.all(
        Object.keys(uniqueUsers).map((uid) => {
          return this.getUser(uid).catch((e) => {
            return;
          });
        }),
      );

      await Promise.all(
        channels.map(async (channel) => {
          // const attributes = channel.attributes as TwilioChatChannelAttributes;
          // todo: check later hidden channels
          // if (attributes.isHidden) {
          //   return;
          // }

          await this.newConversationQueue.add(async () => await this.getTwilioChannel(channel));
        }),
      );
      // chatLogs.forEach((log) => this.getConversationLog(log));

      await Promise.all(
        chatLogs.map(async (log) => {
          await this.newConversationQueue.add(async () => await this.getConversationLog(log));
        }),
      );
    }

    let sub: Subscription | null = null;
    this.client.clientStatus$.subscribe((val) => {
      if (!sub && val === "connected" && authStore.isLoggedIn) {
        setTimeout(() => (sub = this.client.clientEvents$.subscribe(this.clientEventsHandler)), 1000);
      } else if (sub && val !== "connected") {
        sub.unsubscribe();
      }
    });
    this._channelsLoading = false;
    const chatStore = useChatStore();
    await chatStore.setLoadingConversationParticipants(false);
  };

  public listActiveUsers(): ChatUser[] {
    const activeUsers: ChatUser[] = [];
    for (const user of this._activeUsers.values()) {
      activeUsers.push(user);
    }
    return activeUsers;
  }

  public async shutdown(): Promise<void> {
    await this.client.shutdown();
    this._activeUsers.clear();
    this.socket.close();
  }

  public async createNewConversation(chatCreateRequest: ChatCreateRequest): Promise<void> {
    const authStore = useAuthStore();
    let conversationParams: CreateChatResponseDto;
    try {
      conversationParams = await ChatApi.create(chatCreateRequest);
    } catch (e) {
      throw new Error(`Failed to create new conversation. ${e}`);
    }

    let newChannel;
    try {
      newChannel = await this.client.getChannelBySid(conversationParams.channelSid);
    } catch (err) {
      console.error(
        `TwilioContext.createNewConversation error: Channel Sid: ${conversationParams.channelSid} ${err}. Trying to connect again.`,
      );
      try {
        await this.client.connectClient(authStore.uid);
        newChannel = await this.client.getChannelBySid(conversationParams.channelSid);
      } catch (err) {
        console.error(
          `TwilioContext.createNewConversation error: Channel Sid: ${conversationParams.channelSid} ${err}. Trying to connect again.`,
        );
        try {
          await this.client.connectClient(authStore.uid);
          newChannel = await this.client.getChannelBySid(conversationParams.channelSid);
        } catch (err) {
          throw new Error(
            `TwilioContext.createNewConversation error: Failed to connect to channel with sid ${conversationParams.channelSid}. ${err}.`,
          );
        }
      }
    }
    if (!newChannel) return Promise.reject("Failed to create new conversation");
  }

  public async createNewTicketConversation(chatCreateRequest: TicketCreateChatRequestDto): Promise<void> {
    const authStore = useAuthStore();

    let conversationParams: CreateChatResponseDto;
    try {
      conversationParams = await ChatApi.createTicket(chatCreateRequest);
    } catch (e) {
      throw new Error(`Failed to create new conversation. ${e}`);
    }

    let newChannel;
    try {
      newChannel = await this.client.getChannelBySid(conversationParams.channelSid);
    } catch (err) {
      console.error(
        `TwilioContext.createNewConversation error: Channel Sid: ${conversationParams.channelSid} ${err}. Trying to connect again.`,
      );
      try {
        await this.client.connectClient(authStore.uid);
        newChannel = await this.client.getChannelBySid(conversationParams.channelSid);
      } catch (err) {
        console.error(
          `TwilioContext.createNewConversation error: Channel Sid: ${conversationParams.channelSid} ${err}. Trying to connect again.`,
        );
        try {
          await this.client.connectClient(authStore.uid);
          newChannel = await this.client.getChannelBySid(conversationParams.channelSid);
        } catch (err) {
          throw new Error(
            `TwilioContext.createNewConversation error: Failed to connect to channel with sid ${conversationParams.channelSid}. ${err}.`,
          );
        }
      }
    }
    if (!newChannel) return Promise.reject("Failed to create new conversation");
  }

  public createNewAnonymousConversation = async (
    chatCreateRequest: AnonymousChatCreateRequest,
    dummyConversation?: ChatConversation,
  ): Promise<void> => {
    if (!process.env.VUE_APP_TWILIO_ANONYMOUS_USERNAME)
      throw new Error(
        "Unable to create anonymous conversation as one of the enviroment variables is missing. envVar: VUE_APP_TWILIO_ANONYMOUS_USERNAME ",
      );

    const anonymousUser = this._activeUsers.get(process.env.VUE_APP_TWILIO_ANONYMOUS_USERNAME);

    if (!anonymousUser)
      throw new Error(
        "Unable to create anonymous conversation as there is no active user present that would represent Anonymous User envVar: VUE_APP_TWILIO_ANONYMOUS_USERNAME ",
      );

    if (dummyConversation) {
      const conversationParams = await ChatApi.createAnonymous(chatCreateRequest);
      const newChannel = await this.client?.getChannelBySid(conversationParams.channelSid);
      if (!newChannel) {
        throw new Error(
          `TwilioContext.createNewConversation error: Could not find channel with sid: ${conversationParams.channelSid}`,
        );
      }
      const chatConversationBuilder = new ChatConversationBuilder(dummyConversation);

      const membersRepository = new ParticipantsRepository(newChannel.sid, anonymousUser);

      const twilioChannel = new TwilioConversation(
        process.env.VUE_APP_TWILIO_ANONYMOUS_USERNAME,
        newChannel,
        membersRepository,
      );

      if (this._currentUserId === null) throw new Error("Unable to upgrade the conversation as the user ID is null.");
      const upgradedConversation = await chatConversationBuilder
        .setUser(anonymousUser)
        .addTwilioChannel(twilioChannel)
        .addMembersRepository(membersRepository)
        .upgradeToFull();

      await this._channelRepository.addChannel(upgradedConversation);

      await Promise.all(
        twilioChannel.attributes?.allMembersList.map((memberId) =>
          this.addUserToChannel(memberId, upgradedConversation),
        ),
      );

      const participants = await twilioChannel.listParticipants();
      participants.forEach((participant) => {
        if (participant.identity !== this._currentUserId && participant?.identity !== null) {
          membersRepository.setInterlocutor(participant.identity);
        }
      });

      await upgradedConversation.initialize();
      await this.setCurrentFloatingChannel(upgradedConversation);
    } else {
      const conversationBuilder = new ChatConversationBuilder();
      if (!process.env.VUE_APP_TWILIO_ANONYMOUS_USERNAME) throw new Error("TWILIO_ANONYMOUS_USERNAME is missing");
      const dummyChatConversation = await conversationBuilder.setUser(anonymousUser).buildDummyConversation();
      await this.setCurrentFloatingChannel(dummyChatConversation);
    }
  };

  public async moveChannelToMessages(channel: ChatConversation): Promise<void> {
    channel.unconsumedMessagesDelta.subscribe((val) => {
      this._unconsumedMessagesDeltaSum.next(val);
    });
  }

  public setCurrentChannel(channel: ChatConversation | null): void {
    const chatStore = useChatStore();
    chatStore.setCurrentChannel(channel);
  }

  public async setCurrentFloatingChannel(channel: ChatConversation | null): Promise<void> {
    const chatStore = useChatStore();
    chatStore.setCurrentFloatingChannel(channel);
  }

  public async listLogs(): Promise<ChatLogRecordDto[]> {
    return await ChatApi.listAllChatLogs();
  }

  private clientEventsHandler = async (event: TwilioClientEvent): Promise<void> => {
    switch (event.type) {
      case Client.conversationAdded:
        await this.channelAddedHandler(event.payload as Conversation);
        break;
      case Client.conversationRemoved:
        await this.channelRemovedHandler(event.payload as Conversation);
        break;
      case Client.conversationUpdated:
        await this.channelUpdatedHandler(event.payload as ConversationUpdatedEventArgs);

        break;
      case Client.messageAdded:
        await this._channelRepository.sortActiveChannels();
        await this._channelRepository.sortArchivedChannels();
        break;
      case Client.tokenAboutToExpire:
        break;
      case Client.tokenExpired:
        break;
      case Client.connectionError:
        break;
      case Conversation.participantJoined: {
        // todo: temp solution until channel is not ready
        await this.participantJoinedHandler(event.payload as Participant);
        break;
      }
      case Conversation.participantLeft: {
        const payload = event.payload as Participant;
        const foundChannel = this._channelRepository.getChannel(payload.conversation.sid);
        if (foundChannel) await foundChannel.removeMember(payload);
        return;
      }
    }
  };

  private getTwilioChannel = async (newChannel: Conversation, userId?: string): Promise<ChatConversation> => {
    const chatConversationBuilder = new ChatConversationBuilder();

    if (!process.env.VUE_APP_TWILIO_ANONYMOUS_USERNAME)
      throw new Error(
        "TwiloContext.getTwilioChannel. Enviromental variable is missing. envVar: VUE_APP_TWILIO_ANONYMOUS_USERNAME",
      );

    const user = this._activeUsers.get(this._currentUserId ?? process.env.VUE_APP_TWILIO_ANONYMOUS_USERNAME);

    if (!user) {
      throw new Error(
        "TwilioContext.getConversationLog: Unable to build Conversation Log as the user is not present in activeUsers.",
      );
    }

    // todo: in case user id not exists on init, it will go undefined
    const membersRepository = new ParticipantsRepository(newChannel.sid, user);
    const messagesRepository = new MessagesRepository(
      userId ?? this._currentUserId ?? process.env.VUE_APP_TWILIO_ANONYMOUS_USERNAME,
      membersRepository.listParticipants(),
    );
    const twilioChannel = new TwilioConversation(
      this._currentUserId ?? process.env.VUE_APP_TWILIO_ANONYMOUS_USERNAME,
      newChannel,
      membersRepository,
    );
    const chatConversation = await chatConversationBuilder
      .setUser(user)
      .addTwilioChannel(twilioChannel)
      .addMembersRepository(membersRepository)
      .addMessagesRepository(messagesRepository)
      .build();

    await Promise.all(
      twilioChannel.attributes.allMembersList?.map((memberId) => {
        return this.addUserToChannel(memberId, chatConversation);
      }) ?? [],
    );

    const participants = await twilioChannel.listParticipants();
    participants.forEach((participant) => {
      if (participant.identity !== this._currentUserId && participant?.identity !== null) {
        membersRepository.setInterlocutor(participant.identity);
      }
    });

    chatConversation.unconsumedMessagesDelta.subscribe((val: number) => {
      this._unconsumedMessagesDeltaSum.next(val);
    });
    await chatConversation.initialize(userId === this._currentUserId);

    await this._channelRepository.addChannel(chatConversation);
    return chatConversation;
  };

  private getConversationLog = async (conversationLog: ChatLogRecordDto): Promise<ChatConversation | void> => {
    if (conversationLog.conversationData.channelAttributes.isHidden) return;
    const chatConversationBuilder = new ChatConversationBuilder();
    const user = this._activeUsers.get(conversationLog.userId);
    if (!user) {
      return;
    }
    const membersRepository = new ParticipantsRepository(conversationLog.channelSid, user);

    const messagesRepository = new MessagesRepository(conversationLog.userId, membersRepository.listParticipants());

    conversationLog?.conversationData?.messages?.forEach((msg) => messagesRepository.addITwilioMessage(msg));
    const chatConversation = await chatConversationBuilder
      .setUser(user)
      .addMembersRepository(membersRepository)
      .addMessagesRepository(messagesRepository)
      .buildConversationLogFrom(conversationLog);
    // todo: refactor this part because it may miss interlocutor
    const uniqUserIds = (conversationLog?.participantIds || []).map((memberId) => memberId);
    if (conversationLog.userId) uniqUserIds.push(conversationLog.userId);
    if (conversationLog?.conversationData?.interlocutorUid)
      uniqUserIds.push(conversationLog?.conversationData?.interlocutorUid);
    await Promise.all(
      [...new Set([...uniqUserIds])]?.map(async (memberId) => {
        const user = await this.addUserToChannel(memberId, chatConversation);
        // if (user && user?.uid === conversationLog?.conversationData?.interlocutorUid) {
        if (user && user?.uid !== this._currentUserId) {
          await membersRepository.addParticipant(user);
          membersRepository.setInterlocutor(user.uid);
        }
        return;
      }) ?? [],
    );

    await this._channelRepository.addChannel(chatConversation);
    return chatConversation;
  };

  private participantJoinedHandler = async (payload: Participant, retry = 0): Promise<any> => {
    let foundChannel = null;
    try {
      foundChannel = this._channelRepository.getChannel(payload.conversation.sid);
    } catch (e) {
      console.log("Channel was not found");
    }
    if (!foundChannel || !payload.identity) {
      //  in case channel was recently created and is still loading, added retry
      if (retry < 5) {
        return setTimeout(async () => {
          await this.participantJoinedHandler(payload, retry + 1);
        }, 1000);
      }
      throw new Error(
        "TwilioContext.participantJoinedHandler: Unable to invite participant. There is no sucha a channel or the identity is unknown.",
      );
    }

    await this.addUserToChannel(payload.identity, foundChannel);
    foundChannel.setInterlocutor(payload.identity);
  };

  private channelAddedHandler = async (payload: Conversation): Promise<void> => {
    const chatStore = useChatStore();

    const attributes = payload.attributes as TwilioChatChannelAttributes;
    const newChannel = await this.getTwilioChannel(payload);
    if (newChannel && !attributes.isHidden && !attributes.isFloatingChat) {
      this.setCurrentChannel(newChannel);
    } else if (newChannel && attributes.isFloatingChat) {
      const currentChannel = chatStore.getCurrentChannel as ChatConversation | null;
      if (currentChannel) await this.setCurrentFloatingChannel(null);

      await this.setCurrentFloatingChannel(newChannel);
    }
  };

  private channelRemovedHandler = async (payload: Conversation): Promise<void> => {
    const attributes = payload.attributes as TwilioChatChannelAttributes;
    if (attributes.archive) return;
    if (attributes.isFloatingChat) {
      await this.setCurrentFloatingChannel(null);
      return;
    }
    await this._channelRepository.removeChannel(payload.sid);
  };

  private channelUpdatedHandler = async (payload: TwilioConversationUpdatePayload) => {
    const chatStore = useChatStore();
    const currentChannel = chatStore.getCurrentChannel as ChatConversation | null;
    const channelAttributes = payload.conversation.attributes as TwilioChatChannelAttributes;
    if (channelAttributes.isHidden) {
      await this._channelRepository.removeChannel(payload.conversation.sid);
      if (currentChannel?.sid === payload.conversation.sid) await this.setCurrentChannel(null);
    } else if (channelAttributes.archive) {
      await this._channelRepository.moveToArchive(payload.conversation.sid);
      if (currentChannel?.sid === payload.conversation.sid) {
        chatStore.setCurrentGroup("archive");
      }
    }
    await this._channelRepository.sortActiveChannels();
    await this._channelRepository.sortArchivedChannels();
  };

  private addUserToChannel = async (userUid: string, chatConversation: ChatConversation): Promise<ChatUser | null> => {
    if (userUid === "system") return null;
    try {
      const user = await this.getUser(userUid);

      if (user) {
        await chatConversation.addMember(user);
        return user;
      }
      return null;
    } catch (e) {
      return null;
    }
  };

  private getUser = async (uid: string): Promise<ChatUser | undefined> => {
    if (uid === "unknown" || uid === "" || uid === "system") return;
    let twilioUser = this._activeUsers.get(uid) ?? null;
    if (!twilioUser) {
      let chatMemberDto: ChatMemberDto | null;
      if (uid === process.env.VUE_APP_TWILIO_ANONYMOUS_USERNAME) {
        chatMemberDto = {
          uid: process.env.VUE_APP_TWILIO_ANONYMOUS_USERNAME,
          displayName: "Anonymous",
        };
      } else {
        try {
          chatMemberDto = await ChatApi.chatMember(uid);
        } catch (err) {
          chatMemberDto = null;
        }
      }
      let newUser;
      try {
        newUser = await this.client.getUser(uid);
      } catch (err) {
        newUser = null;
      }

      // todo: was undo to not newUser, force at least one user
      // if (!newUser || !chatMemberDto) throw new Error(`User with id: ${uid} does not exists`);
      if (!newUser && !chatMemberDto) throw new Error(`User with id: ${uid} does not exists`);
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      twilioUser = new ChatUser(newUser!, chatMemberDto!, this.socket);
      let finalUser = null;
      if (chatMemberDto) finalUser = chatMemberDto;
      if (twilioUser) finalUser = twilioUser;
      if (finalUser) this._activeUsers.set(finalUser.uid, finalUser as ChatUser);
    }
    return twilioUser;
  };
}

export default TwilioContext;
