<template>
  <v-select
    :error-messages="errors"
    :outlined="!!errors.length || outlined"
    :value="value"
    class="base-input"
    :solo="!outlined"
    :flat="!outlined"
    :menu-props="{ auto: true }"
    cache-items
    :items="states"
    item-text="name"
    :hide-details="hideDetails"
    dense
    height="47"
    item-value="shortName"
    :label="$t('general.inputs.state')"
    append-icon="mdi-menu-down"
    @blur="onBlur"
    @focus="outlined = true"
    @change="onChange"
  >
    <template v-slot:append>
      <v-icon size="24" color="icon"> mdi-menu-down</v-icon>
    </template>
  </v-select>
</template>
<script>
import { STATES } from "@/utils/states.ts";

export default {
  name: "StateSelect",
  props: {
    value: String,
    errors: { default: () => [], type: Array },
    fieldName: { default: "", type: String },
    hideDetails: { default: true, type: Boolean },
  },
  data: () => ({
    states: STATES,
    outlined: false,
  }),
  methods: {
    onChange(value) {
      this.$emit("change", value, this.fieldName);
      this.$emit("input", value);
    },
    onBlur() {
      this.outlined = false;
      this.$emit("blur");
    },
  },
};
</script>
