import { defineStore } from "pinia";

import { MedicationApi } from "@/api/medication";
import { TerminologyApi } from "@/api/terminology";
import { useAuthStore } from "@/pinia-store/auth";

interface DispenseUnit {
  name: string;
  id: number;
}

interface PharmacySpecialties {
  name: string;
  id: number;
}

interface SmokingBehavior {
  name: string;
  id: number;
  term?: string;
  code?: number;
}

interface TerminologyState {
  smokingBehavior: SmokingBehavior[];
  smokingBehaviorLoaded: boolean;

  dispenseUnits: DispenseUnit[];
  dispenseUnitsLoaded: boolean;
  pharmacySpecialties: PharmacySpecialties[];
  pharmacySpecialtiesLoaded: boolean;
  practitionerFavMedication: Record<any, any>[];
  practitionerFavMedicationLoaded: boolean;
}

// interface TerminologyContext {
//   state: TerminologyState;
//   rootState: any;
//   rootGetters: any;
//   commit: any;
// }

export const useTerminologyStore = defineStore({
  id: "terminology",
  state: (): TerminologyState => ({
    smokingBehavior: [],
    dispenseUnits: [],
    smokingBehaviorLoaded: false,
    dispenseUnitsLoaded: false,
    pharmacySpecialties: [],
    pharmacySpecialtiesLoaded: false,
    practitionerFavMedication: [],
    practitionerFavMedicationLoaded: false,
  }),
  actions: {
    async getDispenseUnits() {
      this.dispenseUnits = await TerminologyApi.getAllergies();
      this.dispenseUnitsLoaded = true;
    },
    async preloadDispenseUnits() {
      if (this.dispenseUnitsLoaded) return;
      this.dispenseUnits = await TerminologyApi.getAllergies();
      this.dispenseUnitsLoaded = true;
    },
    async preloadPharmacySpecialties() {
      if (this.pharmacySpecialtiesLoaded) return;
      this.pharmacySpecialties = await TerminologyApi.getPharmacySpecialties();
      this.pharmacySpecialtiesLoaded = true;
    },
    async preloadSmokingBehavior() {
      if (this.smokingBehaviorLoaded) return;
      this.smokingBehavior = await TerminologyApi.getSmokingBehavior();
      this.smokingBehaviorLoaded = true;
    },
    async getPractitionerFavMedication(payload?: string) {
      const authStore = useAuthStore();
      try {
        this.practitionerFavMedication = await MedicationApi.getPractitionerMedication(authStore.uid || payload);
        this.practitionerFavMedicationLoaded = true;
      } catch (e) {
        console.info("Practitioner Fav Medication not found");
      }
    },
    async preloadPractitionerFavMedication() {
      if (this.practitionerFavMedicationLoaded) return;
      this.getPractitionerFavMedication();
    },
  },
});
