<template>
  <v-row class="visit-summary_patient-info" no-gutters>
    <v-col cols="12">
      <v-list-item class="visit-summary_patient-info_list-item">
        <v-list-item-content>
          <v-list-item-subtitle>{{ $t("general.inputs.fullName") }}</v-list-item-subtitle>
          <v-card-title class="visit-summary_patient-info_name">{{ patientFullName }}</v-card-title>
        </v-list-item-content>
        <v-chip :ripple="false" color="primarylight" label text-color="primaryblack2">{{ patientEmail }}</v-chip>
      </v-list-item>
    </v-col>
    <v-col cols="12" md="8">
      <v-list-item>
        <v-list-item-content>
          <v-list-item-subtitle>{{ $t("practitioner.schedule.gender") }}</v-list-item-subtitle>
          <v-list-item-title class="visit-summary_patient-info_list-item-title--bold">{{ gender }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-subtitle>{{ $t("general.inputs.contactNumber") }}</v-list-item-subtitle>
          <v-list-item-title class="visit-summary_patient-info_list-item-title--bold"
            >{{ patientInfo.phoneNumber }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-subtitle>{{ $t("practitioner.schedule.visitDate") }}</v-list-item-subtitle>
          <v-list-item-title class="visit-summary_patient-info_list-item-title--bold"
            >{{ visitDate }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-col>
    <v-col cols="12" md="4">
      <v-list-item>
        <v-list-item-content>
          <v-list-item-subtitle>{{ $t("general.inputs.state") }}</v-list-item-subtitle>
          <v-list-item-title class="visit-summary_patient-info_list-item-title--bold">{{ state }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-subtitle>{{ $t("practitioner.schedule.duration") }}</v-list-item-subtitle>
          <v-list-item-title class="visit-summary_patient-info_list-item-title--bold">{{ duration }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-col>
  </v-row>
</template>
<script>
import { DateTime } from "luxon";
import { mapActions, mapState } from "pinia";

import { useEncountersStore } from "@/pinia-store/encounters";
import { useVisitNoteStore } from "@/pinia-store/visitNote";
import { getStateName } from "@/utils/states.ts";

export default {
  name: "VisitSummaryPatientInfo",
  computed: {
    ...mapState(useVisitNoteStore, ["patientInfo", "patientEmail"]),
    ...mapActions(useEncountersStore, ["currentEncounter"]),
    patientFullName() {
      return `${this.patientInfo.firstName} ${this.patientInfo.lastName}`;
    },
    gender() {
      return this.patientInfo.gender.replace(/^\w/, (c) => c.toUpperCase());
    },

    visitDate() {
      return DateTime.fromISO(this.currentEncounter.start).toFormat("d MMMM yyyy");
    },
    duration() {
      const start = DateTime.fromISO(this.currentEncounter.start).toFormat("H:mm");
      const end = DateTime.fromISO(this.currentEncounter.end).toFormat("H:mm");
      return `${start} - ${end}`;
    },
    state() {
      return getStateName(this.patientInfo.state);
    },
  },
};
</script>
