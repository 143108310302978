export const tableHeaders = (isVisitNote = true) => ({
  headersPMH: [
    {
      text: "PMH",
      value: "name",
      width: "80%",
      class: "table-header",
    },
    {
      text: "Year",
      value: "year",
      width: "10%",
      align: "center",
      class: "table-header",
    },
  ],
  headersPSH: [
    { text: "PSH", value: "name", width: "80%", class: "table-header" },
    {
      text: "Year",
      value: "year",
      width: "10%",
      align: "center",
      class: "table-header",
    },
  ],
  headersIssues: [
    {
      text: "Complaints",
      value: "firstValue",
      width: "20%",
      class: "table-header",
    },
    {
      text: isVisitNote ? "Physical Examination" : "Physical exam",
      value: "secondValue",
      width: "80%",
      class: "table-header",
    },
    { text: "", value: "action", width: "10%", align: "center" },
  ],
  headersAssesments: [
    {
      text: "Assessment",
      value: "firstValue",
      width: "20%",
      class: "table-header",
    },
    {
      text: isVisitNote ? "Note (Plan/Instructions)" : "Problem Notes",
      value: "secondValue",
      width: "80%",
      class: "table-header",
    },
    { text: "", value: "action", width: "10%", align: "center" },
  ],
  idFile: {},
});

type PartialPatientType = Record<string, any> & {
  firstName: string;
  lastName: string;
};

export const filterSearchOfPatients = (search: string, items: PartialPatientType[]): PartialPatientType[] => {
  const cleanSearch = search ? search.trim().toLowerCase() : "";
  const cleanedInput = cleanSearch.replace(/[^a-zA-Z ]/g, "").toLowerCase();
  const inputTerms = cleanedInput
    .toLowerCase()
    .split(" ")
    .filter((term) => term.length > 0);
  return items
    .map((i) => {
      if (inputTerms.length === 0) return { ...i, sortIndex: 0 };
      const fullName = `${i.firstName || ""} ${i.lastName || ""}`;
      const cleanedFullName = fullName.replace(/[^a-zA-Z ]/g, "");
      const fullNameTerms = cleanedFullName.toLowerCase().split(" ");
      let sortIndex = 0;
      for (const term of inputTerms) {
        const termFound = fullNameTerms.filter(($nameTerm) => {
          return $nameTerm.includes(term);
        });
        if (termFound.length) {
          sortIndex += termFound.length;
        }
      }
      return { ...i, sortIndex };
    })
    .sort((a, b) => b.sortIndex - a.sortIndex);
};
