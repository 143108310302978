import { Message } from "@twilio/conversations";
import { DateTime } from "luxon";

import { PMH } from "@/pinia-store/interfaces/checkoutI";
import { Medication } from "@/types/medication";

export enum ChatMessageType {
  BaseMessage = "BaseMessage",
  VisitNoteReason = "VisitNoteReason",
  MessageWithButtons = "MessageWithButtons",
  MessageWithCheckList = "MessageWithCheckList",
  MessageWithAdaptiveCard = "MessageWithAdaptiveCard",
  MessageWithPmhCard = "MessageWithPmhCard",
  MessageWithAllergiesCard = "MessageWithAllergiesCard",
  MessageWithMedicationsCard = "MessageWithMedicationsCard",
}

export default interface ChatMessage<TMeta> {
  index: string;
  body: string;
  author: string;
  dateCreated: number;
  time: DateTime;
  my: boolean;
  name: string;
  raw: Message;
  meta?: TMeta;
  value?: any;
  type: ChatMessageType;
}

export interface Button {
  label: string;
  type: string;
  value: string;
}

export interface ButtonsMeta {
  buttons: Button[];
}

export interface PmhMeta {
  pmh: PMH[];
}

export interface AllergiesMeta {
  allergies: string[];
}

export interface MedicationsMeta {
  medications: Medication[];
}

export interface CheckListItem {
  id: string;
  label: string;
  value: string;
}

export interface CheckListMeta {
  multiSelect: boolean;
  items: CheckListItem[];
}

export type AdaptiveCardMeta = any;

export type BaseChatMessage = ChatMessage<undefined>;
export type ChatMessageWithButtons = ChatMessage<ButtonsMeta>;
export type ChatMessageWithCheckList = ChatMessage<CheckListMeta>;
export type ChatMessageWithAdaptiveCard = ChatMessage<AdaptiveCardMeta>;
export type ChatMessageWithPmhCard = ChatMessage<PmhMeta>;
export type ChatMessageWithAllergiesCard = ChatMessage<AllergiesMeta>;
export type ChatMessageWithMedicationsCard = ChatMessage<MedicationsMeta>;

export type AnyChatMessage =
  | BaseChatMessage
  | ChatMessageWithButtons
  | ChatMessageWithCheckList
  | ChatMessageWithAdaptiveCard
  | ChatMessageWithPmhCard
  | ChatMessageWithAllergiesCard
  | ChatMessageWithMedicationsCard;
