<template>
  <div class="bottom-nav">
    <div :style="{ backgroundImage: `url('${require('@/assets/bottom-nav/bg.svg')}')` }" class="wrapper">
      <div class="left-actions inline-actions">
        <v-btn
          :class="isActiveRoute(routesEnum.practitionerScheduledAppointments) && 'active'"
          text
          @click="gotoAction(routesEnum.practitionerScheduledAppointments)"
        >
          <img :src="require('@/assets/bottom-nav/schedule.svg')" alt="" />
          <span>Calendar</span>
        </v-btn>
        <v-btn
          :class="isActiveRoute(routesEnum.practitionerPatients) && 'active'"
          text
          @click="gotoAction(routesEnum.practitionerPatients)"
        >
          <img :src="require('@/assets/bottom-nav/patients.svg')" alt="" />
          <span>Patients</span>
        </v-btn>
      </div>
      <div class="hover-actions">
        <v-btn
          :ripple="false"
          :style="{ backgroundImage: `url('${require('@/assets/bottom-nav/bg-more.svg')}')` }"
          class="toggle-btn"
          text
          @click="setDrawer(!drawer)"
        >
          <img :src="require('@/assets/icons/add.svg')" alt="" />
        </v-btn>
      </div>
      <div class="right-actions inline-actions">
        <v-btn
          :class="isActiveRoute(routesEnum.practitionerChat) && 'active'"
          text
          @click="gotoAction(routesEnum.practitionerChat)"
        >
          <img :src="require('@/assets/bottom-nav/msj.svg')" alt="" />
          <span>Messages</span>
        </v-btn>
        <v-btn
          :class="isActiveRoute(routesEnum.practitionerSettings) && 'active'"
          text
          @click="gotoAction(routesEnum.practitionerSettings)"
        >
          <img :src="require('@/assets/bottom-nav/profile.svg')" alt="" />
          <span>Profile</span>
        </v-btn>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapState } from "pinia";

import { useAppStore } from "@/pinia-store/app";
import { routesEnum } from "@/types/Routes.enum";

export default {
  name: "BottomNav",
  computed: {
    ...mapState(useAppStore, ["drawer"]),
    routesEnum() {
      return routesEnum;
    },
  },
  methods: {
    ...mapActions(useAppStore, ["setDrawer"]),

    gotoAction(name) {
      this.$router.push({ name });
    },
    isActiveRoute(name) {
      return this.$route.name === name;
    },
  },
};
</script>
<style lang="scss" scoped>
.bottom-nav {
  padding-top: 30px;
  position: sticky;
  bottom: 0;
  margin-top: auto;
  z-index: 2;
  left: 0;
  width: 100%;
  background-color: transparent;

  .wrapper {
    display: flex;
    width: 100%;
    height: 75px;
    justify-content: space-between;
    background-size: cover;
    background-position: center;
    background-color: transparent;

    fill: var(--other-white, #fff);
    filter: drop-shadow(0px 6px 24px rgba(0, 0, 0, 0.06));

    .hover-actions {
      :deep .toggle-btn {
        &:hover:before,
        &:focus:before {
          opacity: 0 !important;
        }

        margin-top: -28px;
        background-size: contain;
        background-position: center center;
        height: 55px !important;
        min-width: inherit !important;

        .v-ripple__container {
          display: none !important;
        }

        img {
          width: 42px;
          padding: 12px;
        }
      }
    }

    .inline-actions {
      align-items: flex-start;
      display: flex;
      justify-content: space-around;
      flex-direction: row;
      flex-basis: 40%;

      :deep button {
        height: inherit !important;
        min-width: inherit !important;
        padding: 5px 7px !important;
        filter: sepia(100%) hue-rotate(180deg);

        &.active {
          filter: none;
        }

        .v-btn__content {
          display: flex;
          flex-direction: column;

          img {
            width: 32px;
          }

          span {
            color: #2196f3;

            //color: #bccad5;
            text-align: center;
            font-size: 10px;
            font-style: normal;
            font-weight: 900;
            line-height: normal;
            text-transform: none;
          }
        }
      }
    }
  }
}
</style>
