<template>
  <v-btn
    v-if="isVisible"
    :height="btnHeight"
    :max-height="btnHeight"
    color="success"
    elevation="0"
    @click="onSubmitChat"
  >
    <v-icon dark left> mdi-message-outline</v-icon>
    Chat now
  </v-btn>
</template>

<script>
import { mapActions } from "pinia";

import { ChatTicketType } from "@/context/twilioContext/enums/ChatTicketType";
import { FloatingChatTabs, useChatStore } from "@/pinia-store/chat";

export default {
  name: "ChatNowAction",
  props: {
    phoneNumber: { default: "", type: String },
    toUserId: { default: "", type: String },
    btnHeight: {
      type: Number,
      default: 36,
    },
  },
  computed: {
    isVisible() {
      return true;
    },
  },
  methods: {
    ...mapActions(useChatStore, ["setNewFloatingChatData", "setFloatingChatTab", "toggleFloatingChatVisible"]),
    onSubmitChat() {
      this.setNewFloatingChatData({
        phoneNumber: this.phoneNumber,
        toUserId: this.toUserId,
        ticketCategory: ChatTicketType.clinical,
      });
      this.toggleFloatingChatVisible(true);
      this.setFloatingChatTab(FloatingChatTabs.start);
    },
  },
};
</script>

<style scoped></style>
