<template>
  <v-btn
    :class="`minimize-btn ${minimized ? 'minimized' : ''}`"
    color="rgba(15,15,15, 0.36)"
    fab
    small
    @click="onChange"
  >
    <v-icon v-if="minimized" color="white" dark>mdi-resize</v-icon>
    <v-icon v-else color="white" dark>mdi-move-resize-variant</v-icon>
  </v-btn>
</template>
<script>
import { mapActions, mapState } from "pinia";

import { useMeetingRoomStore } from "@/pinia-store/meetingRoom";

export default {
  name: "MinimizeBtn",
  computed: {
    ...mapState(useMeetingRoomStore, ["minimized"]),
  },

  destroyed() {
    this.setMinimize(false);
  },
  methods: {
    ...mapActions(useMeetingRoomStore, ["toggleMinimize", "setMinimize"]),
    onChange() {
      this.toggleMinimize();
    },
  },
};
</script>

<style lang="scss" scoped>
.minimize-btn {
  position: absolute;
  top: 21px;
  right: 70px;
  z-index: 3;
  font-size: 12px;
  background: rgba(15, 15, 15, 0.36) !important;

  &.minimized {
    top: -20px;
    right: 0px;
    opacity: 1;
    background: #33343e !important;
  }

  :deep i {
    font-size: 17px !important;
  }
}
</style>
