<template>
  <div>
    <PractitionerSettings />
  </div>
</template>

<script>
import PractitionerSettings from "@/components/settings/practitioner";
export default {
  name: "practitionerSettings",
  components: {
    PractitionerSettings,
  },
};
</script>
