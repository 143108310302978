import { defineStore } from "pinia";

import { AdminAPI } from "@/api/admin";

export interface VisitSettingsState {
  scheduleHorizonLimit: string;
  scheduleToCancelLimit: string;
  scheduleToStartLimit: string;
  slotDuration: string;
  isFetchedVisitSettings: boolean;
}

export const useVisitSettingsStore = defineStore({
  id: "visitSettings",
  state: (): VisitSettingsState => ({
    scheduleHorizonLimit: "7",
    scheduleToCancelLimit: "00:05",
    scheduleToStartLimit: "00:30",
    slotDuration: "00:15",
    isFetchedVisitSettings: false,
  }),
  actions: {
    async preloadVisitSettings(): Promise<void> {
      if (this.isFetchedVisitSettings) {
        return;
      }
      try {
        const data = await AdminAPI.getVisitSettings();
        this.scheduleHorizonLimit = data.scheduleHorizonLimit;
        this.scheduleToCancelLimit = data.scheduleToCancelLimit;
        this.scheduleToStartLimit = data.scheduleToStartLimit;
        this.slotDuration = data.slotDuration;
        this.isFetchedVisitSettings = true;
      } catch (e) {
        console.trace("Error fetching visit settings");
      }
    },
  },
});
