<template>
  <div class="edit-wrap">
    <v-autocomplete
      :cache-items="false"
      :errors="nameErrors"
      :items="searchResultsFormatted"
      :loading="isLoadingDrugs"
      :placeholder="label"
      :search-input.sync="search"
      :value="name"
      className="bd-grey"
      clearable
      fieldName="name"
      hide-details
      hide-no-data
      solo
      @change="onSelectMedication"
    ></v-autocomplete>
  </div>
</template>

<script>
import { mapState } from "pinia";
import { required } from "vuelidate/lib/validators";

import { TerminologyApi } from "@/api/terminology";
import { useTerminologyStore } from "@/pinia-store/terminology";

export default {
  name: "MedicationSearch",
  props: {
    label: {
      type: String,
      default() {
        return this.$t("visits.medical.medicationPlaceholder");
      },
    },
    text: {
      type: String,
      default() {
        return "";
      },
    },
    useFavorite: {
      type: Boolean,
      default() {
        return false;
      },
    },
    keepSelected: {
      type: Boolean,
      default: () => false,
    },
  },
  data: () => ({
    name: "",
    searchResultsFormatted: [],
    search: "",
    isLoadingDrugs: false,
    hideDosageFields: true,
    selectedItem: {},
  }),
  validations: {
    name: { required },
  },
  computed: {
    ...mapState(useTerminologyStore, ["practitionerFavMedication"]),
    nameErrors() {
      const errors = [];
      if (!this.$v.name.$dirty) return errors;
      const field = this.$t("visits.medical.name");
      !this.$v.name.required && errors.push(this.$t("validation.required", { field }));
      return errors;
    },
    favorite() {
      return this.practitionerFavMedication;
    },
  },
  watch: {
    text: {
      immediate: true,
      handler(val) {
        if (val && val.length) {
          this.searchResultsFormatted = [val];
          this.name = val;
        }
      },
    },
    practitionerFavMedication: {
      immediate: true,
      handler(val) {
        if (val && val.length) {
          this.searchResults = val;
          this.searchResultsFormatted = val.map((it) => `${it.name} ${it.quantity || ""}`);
        }
      },
    },
    search(val) {
      if (!val || val === this.name || val?.length < 3) {
        if (this.useFavorite) {
          this.searchResults = this.favorite;
          this.searchResultsFormatted = this.favorite.map((it) => `${it.name} ${it.quantity || ""}`);
        }
        return;
      }
      clearTimeout(this._timerId);
      this._timerId = setTimeout(() => {
        this.isLoadingDrugs = true;
        // DosespotApi.searchMedication(val).then((values) => {
        TerminologyApi.searchDrugs(val).then((values) => {
          this.isLoadingDrugs = false;
          this.searchResults = [...values, { name: val, quantity: "" }];
          this.searchResultsFormatted = [`${val} `, ...values.map((it) => `${it.name} ${it.quantity || ""}`)];
        });
      }, 300);
    },
  },
  methods: {
    async onSelectMedication(selectedValue) {
      let item = this.searchResults.find((result) => `${result.name} ${result.quantity || ""}` === selectedValue);
      if (item) {
        this.$emit("onSelect", item);
      }
      if (!this.keepSelected) {
        setTimeout(() => {
          this.name = null;
          this.search = null;
        }, 0);
      }
    },
  },
};
</script>

<style scoped>
:deep .v-input__slot {
  border: 1px solid #eeeff7;
  box-shadow: none !important;
}
</style>
