import { Client as ConversationsClient } from "@twilio/conversations";
import { defineStore } from "pinia";

import { ChatApi } from "@/api/chat";
import ChatConversation from "@/context/twilioContext/ChatConversation/ChatConversation";
import { ChatTicketType } from "@/context/twilioContext/enums/ChatTicketType";
import { ChatMemberDto } from "@/context/twilioContext/types/ChatMemberDto";
import { useAuthStore } from "@/pinia-store/auth";

declare global {
  interface Window {
    conversationsClient: ConversationsClient;
    ReactNativeWebView: any;
  }
}

export enum FloatingChatTabs {
  start = "start",
  channelsList = "channelsList",
  channel = "channel",
}

export interface ChatState {
  currentGroup: "active" | "archive";
  filterQuery: string;
  filterParticipantName: string;
  filterStartDate: string;
  filterEndDate: string;

  token: string;
  sendSmsToPhoneNumber: string;
  sendSmsIsOpened: boolean;
  sendSmsMessage: string;
  conversationsClient: ConversationsClient;
  loggedIn: boolean;
  newMessage: string;
  conversationsReady: boolean;
  conversations: [];
  messages: [];
  currentChannel: ChatConversation | null;
  currentFloatingChannel: ChatConversation | null;
  unreadChannels: Record<string, number>;
  chatLogTableOptions: any;
  loadingConversationParticipants: boolean;
  startedVisitNoteChannel: string;
  floatingChatVisible: boolean;
  floatingChatActiveTab: FloatingChatTabs;
  startConversationParticipants: ChatMemberDto[];
  newFloatingChatData: NewFloatingChatData;
}

export interface NewFloatingChatData {
  phoneNumber: string;
  toUserId: string;
  ticketCategory: ChatTicketType;
}

export const useChatStore = defineStore({
  id: "chat",
  state: (): ChatState & any => ({
    startedVisitNoteChannel: "",
    newFloatingChatData: {
      phoneNumber: "",
      toUserId: "",
      ticketCategory: ChatTicketType.clinical,
    },
    filterQuery: "",
    filterStartDate: "",
    filterEndDate: "",
    filterParticipantName: "",
    sendSmsToPhoneNumber: "",
    sendSmsIsOpened: false,
    sendSmsMessage: "",
    loggedIn: false,
    floatingChatActiveTab: FloatingChatTabs.start,
    conversationsClient: window.conversationsClient,
    token: "",
    conversationsReady: false,
    messages: [],
    newMessage: "",
    conversations: [],
    loadingConversationParticipants: true,
    startConversationParticipants: [],
    currentChannel: null,
    currentFloatingChannel: null,
    floatingChatVisible: false,
    unreadChannels: {},
    chatLogTableOptions: null,
    currentGroup: "active",
  }),
  actions: {
    setCurrentGroup(payload: "active" | "archive") {
      this.currentGroup = payload;
    },
    setFilterQuery(payload: string) {
      this.filterQuery = payload;
    },
    setFilterParticipantName(payload: string) {
      this.filterParticipantName = payload;
    },
    setFilterStartDate(payload: string) {
      this.filterStartDate = payload;
    },
    setFilterEndDate(payload: string) {
      this.filterEndDate = payload;
    },
    toggleSendSMSDialog() {
      this.sendSmsIsOpened = !this.sendSmsIsOpened;
    },
    setSendSmsToPhoneNumber(payload: string) {
      this.sendSmsToPhoneNumber = payload;
    },
    setStartedVisitNoteChannel(payload: string) {
      this.startedVisitNoteChannel = payload;
    },
    setNewFloatingChatData(payload: NewFloatingChatData) {
      this.newFloatingChatData = payload;
    },
    updateNewFloatingChatData(payload: { field: string; value: any }) {
      this.newFloatingChatData[payload.field] = payload.value;
    },

    setSendSmsMessage(payload: string) {
      this.sendSmsMessage = payload;
    },

    toggleFloatingChatVisible(payload?: boolean) {
      this.floatingChatVisible = typeof payload !== "boolean" ? !this.floatingChatVisible : payload;
    },

    setChatLogTableOptions(tableOptions: any) {
      this.chatLogTableOptions = tableOptions;
    },

    setFloatingChatTab(payload: FloatingChatTabs) {
      this.floatingChatActiveTab = payload;
    },
    setUnreadChannels(unreadChannels: Record<string, number>) {
      this.unreadChannels = { ...this.unreadChannels, ...unreadChannels };
    },

    setCurrentFloatingChannel(channel: ChatConversation) {
      this.currentFloatingChannel = channel;
    },
    async postSmsMessage() {
      if (!this.sendSmsToPhoneNumber?.length || !this.sendSmsMessage?.length) {
        return;
      }
      await ChatApi.sendSms({ message: this.sendSmsMessage, toPhoneNumber: this.sendSmsToPhoneNumber });
    },
    async getStartConversationParticipants() {
      this.loadingConversationParticipants = true;
      try {
        this.startConversationParticipants = await ChatApi.getAvailableIdentities();
      } catch (e) {
        console.info("Failed to fetch participants");
      }
      this.loadingConversationParticipants = false;
    },
    setCurrentChannel(channel: ChatConversation | null) {
      this.currentChannel = !channel ? null : channel;
    },
    setLoadingConversationParticipants(payload: boolean) {
      this.loadingConversationParticipants = payload;
    },
  },
  getters: {
    isFloatingChatVisible: (state: ChatState): boolean => {
      const authStore = useAuthStore();
      return state.floatingChatVisible && authStore.userIsPractitioner && authStore.isLoggedIn;
    },
    showStartConversation: (state: ChatState): boolean => Boolean(state.startConversationParticipants.length),
    getTableOptions: (state: ChatState): any => state.chatLogTableOptions,
    getCurrentChannel: (state: ChatState): ChatConversation | null => state.currentChannel,
    getCurrentFloatingChannel: (state: ChatState): ChatConversation | null => state.currentFloatingChannel,
    unreadMessagesCount: (state: ChatState) => {
      return Object.values(state.unreadChannels).reduce((a, e) => [(a += e), a][1], 0);
    },
  },
});
