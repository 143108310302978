import Vue from "vue";
import VueI18n from "vue-i18n";

import translations from "./translations.json";

const fallbackLocale = "en";

Vue.use(VueI18n);

export default function (): VueI18n {
  return new VueI18n({
    locale: fallbackLocale,
    fallbackLocale,
    messages: translations,
  });
}
