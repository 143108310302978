<template>
  <Confirm
    :dialog="bloodGlucoseTableOpened"
    :hide-cancel="true"
    :title="title"
    confirm-text="Close"
    maxWidth="90%"
    rootClass="monitoring-drawer"
    text=""
    width="1100px"
    @confirm="toggleBloodGlucoseTableOpened"
    @toggleDialog="toggleBloodGlucoseTableOpened"
  >
    <v-progress-linear v-if="loading" color="primary" indeterminate></v-progress-linear>
    <BloodGlucoseTable :disable-edit="disableEdit" />
  </Confirm>
</template>

<script>
import { mapActions, mapState } from "pinia";

import Confirm from "@/components/shared/Confirm";
import { useObservationsStore } from "@/pinia-store/observations";
import BloodGlucoseTable from "@/views/Patient/Monitoring/bloodGlucose/Table";

export default {
  name: "DrawerBloodGlucose",
  components: { BloodGlucoseTable, Confirm },
  props: {
    disableEdit: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      title: "Blood Glucose",
      loading: true,
    };
  },
  watch: {
    // todo: check
    "$store.state.observations.bloodGlucoseTableOpened": {
      immediate: true,
      async handler(val) {
        if (val) {
          this.loading = true;
          await this.getBloodGlucose();
          this.loading = false;
        }
      },
    },
  },
  computed: {
    ...mapState(useObservationsStore, ["bloodGlucoseTableOpened"]),
  },
  methods: {
    ...mapActions(useObservationsStore, ["toggleBloodGlucoseTableOpened"]),
    ...mapActions(useObservationsStore, ["getBloodGlucose"]),
  },
};
</script>
<style lang="scss">
@import "../monitoring-drawer";
</style>
