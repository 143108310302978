<template>
  <div>
    <Confirm
      :confirmText="$t('general.update')"
      :dialog="value"
      :loading="loading"
      cancelText="Close"
      rootClass="media-rename"
      text=""
      title="Edit Past Medical History"
      @confirm="performStore"
      @toggleDialog="toggleDialog"
    >
      <v-container class="no-padding-bottom no-padding-top">
        <v-row>
          <v-col cols="12">
            <v-autocomplete
              v-model="name"
              :errors="nameErrors"
              :items="searchResults"
              :label="$t('visits.medical.medicationPlaceholder')"
              :loading="isLoadingConditions"
              :placeholder="$t('settings.enterConditions')"
              :search-input.sync="conditionsSearch"
              cache-items
              class="bd-grey patient__allergy-search"
              clearable
              fieldName="name"
              hide-details
              hide-no-data
              solo
              @change="onSelectCondition"
            ></v-autocomplete>
          </v-col>
          <v-col cols="12">
            <YearPicker :height="48" :value="year" @change="onChangeDate" />
          </v-col>
        </v-row>
      </v-container>
    </Confirm>
  </div>
</template>
<script>
import { mapActions, mapState } from "pinia";
import { required } from "vuelidate/lib/validators";

import { TerminologyApi } from "@/api/terminology";
import Confirm from "@/components/shared/Confirm";
import YearPicker from "@/components/shared/YearPicker";
import { useAuthStore } from "@/pinia-store/auth";
import { useMediaStore } from "@/pinia-store/media";

export default {
  name: "PatientHistoryTableEdit",
  props: ["value", "editItem", "patientId"],
  components: {
    YearPicker,
    Confirm,
  },
  data: () => ({
    conditionsSearch: null,
    searchResults: [],
    isLoadingConditions: false,
    loading: false,
    id: "",
    year: "",
    name: "",
  }),
  validations: {
    name: {
      required,
    },
  },

  computed: {
    ...mapState(useAuthStore, ["uid"]),
    isSubmitDisabled() {
      return Boolean(!this.date || !this.value || this.dateErrors.length || this.valueErrors.length);
    },
    dateErrors() {
      const errors = [];
      if (!this.date) {
        if (!this.$v.year.$dirty) return errors;
      }
      const field = this.$t("general.inputs.date");
      !this.$v.year.required && errors.push(this.$t("validation.required", { field }));
      return errors;
    },
    nameErrors() {
      const errors = [];
      if (!this.$v.name.$dirty) return errors;
      const field = this.$t("general.inputs.height");
      !this.$v.name.required && errors.push(this.$t("validation.required", { field }));
      return errors;
    },
  },
  watch: {
    conditionsSearch(val) {
      if (!val || val === this.name) return;
      clearTimeout(this._timerId);
      this._timerId = setTimeout(() => {
        this.isLoadingDrugs = true;
        TerminologyApi.getConditions(val).then((values) => {
          this.isLoadingConditions = false;
          this.searchResults = [val, ...(values.map((value) => value.name) || [])];
        });
      }, 300);
    },
    editItem(newValue) {
      if (newValue) {
        this.id = newValue?.id;
        this.name = newValue?.name?.toString() || "";
        this.conditionsSearch = newValue?.name?.toString() || "";
        TerminologyApi.getConditions(newValue?.name).then((values) => {
          this.isLoadingConditions = false;
          this.searchResults = [newValue?.name, ...(values.map((value) => value.name) || [])];
        });
        this.year = newValue?.year ? newValue?.year : "";
      }
    },
  },
  methods: {
    ...mapActions(useMediaStore, ["setUid"]),
    onSelectCondition(selectedValue) {
      this.name = selectedValue;
    },
    onFieldChange(value, fieldName) {
      this[fieldName] = value;
    },
    onChangeDate(e) {
      this.year = e;
    },
    toggleDialog() {
      this.$emit("toggleDialog");
    },
    toggleSuccessDialog() {
      this.successDialog = !this.successDialog;
    },
    async performStore() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      const body = { id: this.id, name: this.name, year: this.year };
      this.$emit("onChange", { ...body });
    },
  },
};
</script>
<style lang="scss">
.media-rename {
  .card-title {
    font-weight: 500 !important;
    font-size: 25px !important;
    line-height: 31px !important;
    color: #25233a;
  }

  .v-card__title + .v-card__text {
    font-size: 14px;
    line-height: 17px;
    color: #33343d;
  }
}
</style>
