<template>
  <v-dialog :value="value" max-width="410" @input="$emit('value')" @click:outside="onClose">
    <v-card class="card">
      <v-icon class="card-close" color="icon" size="medium" @click="onClose"> mdi-close</v-icon>
      <v-card-title>
        {{ $t("patients.assign.title") }}
      </v-card-title>
      <v-card-text>
        {{ $t("patients.assign.text") }}
      </v-card-text>
      <Select
        v-model="practitionerId"
        :errors="practitionerIdErrors"
        :hideDetails="false"
        :items="practitionersFormatted"
        :label="$t('patients.assign.choose')"
        class="px-5"
        itemText="fullName"
        itemValue="id"
      />
      <slot />
      <v-card-actions class="actions">
        <v-btn class="btn btn-cancel" color="#E5E5E5" outlined @click="onClose">
          <span>{{ $t("general.dialog.cancel") }}</span>
        </v-btn>
        <v-btn
          :dark="!this.$v.$invalid"
          :disabled="this.$v.$invalid"
          :light="this.$v.$invalid"
          :loading="!practitionersLoaded"
          class="btn"
          color="success"
          @click="assign"
        >
          <v-progress-circular v-if="loading" color="white" indeterminate size="25" width="3" />
          <span v-else>{{ $t("patients.assign.button") }}</span>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapActions, mapState } from "pinia";
import { required } from "vuelidate/lib/validators";

import Select from "@/components/uikit/Select";
import { snackBarEventBus, snackBarEventName } from "@/eventBuses/snackBar.eventBus";
import { usePatientsStore } from "@/pinia-store/patients";
import { usePractitionersStore } from "@/pinia-store/practitioners";

export default {
  name: "AssignPatientDialog",
  props: {
    value: Boolean,
    patient: Object,
  },
  data() {
    return {
      loading: false,
      practitionersLoading: false,
      practitionersLoaded: false,
      practitionerId: null,
    };
  },
  validations: {
    practitionerId: { required },
  },
  computed: {
    ...mapState(usePractitionersStore, ["practitioners"]),
    practitionerIdErrors() {
      const errors = [];
      if (!this.$v.practitionerId.$dirty) return errors;
      !this.$v.practitionerId.required &&
        errors.push(this.$t("validation.required", { field: this.$t("patients.assign.practitioner") }));
      return errors;
    },
    practitionersFormatted() {
      return this.practitioners.map((i) => ({ ...i, fullName: `${i.firstName || ""} ${i.lastName || ""}` }));
    },
  },
  components: {
    Select,
  },
  watch: {
    value(val) {
      if (val && !this.practitionersLoaded) this.fetchPractitioners();
    },
  },
  methods: {
    ...mapActions(usePractitionersStore, ["getPractitioners"]),
    ...mapActions(usePatientsStore, ["getAllPatients", "reAssignPatient"]),
    onClose() {
      this.$emit("value");
      this.$emit("input", false);
    },
    async assign() {
      this.loading = true;
      try {
        await this.reAssignPatient({ patientId: this.patient.id, practitionerId: this.practitionerId });
        snackBarEventBus.$emit(snackBarEventName, {
          message: this.$t("patients.assign.success"),
          type: "success",
        });
      } catch (err) {
        console.error(err);
        let message = err.message || err;
        if (err.isAxiosError) {
          message = err.response.data.message;
        }
        snackBarEventBus.$emit(snackBarEventName, { message, type: "error" });
      }
      this.loading = false;
      await this.getAllPatients();
      this.onClose();
    },
    async fetchPractitioners() {
      if (this.practitionersLoaded) return;
      this.practitionersLoading = true;
      try {
        await this.getPractitioners();
      } catch (err) {
        console.error(err);
      }
      this.practitionersLoading = false;
      this.practitionersLoaded = true;
    },
  },
};
</script>
<style lang="scss" scoped>
.card {
  padding: 1rem;
  position: relative;

  &-close {
    position: absolute !important;
    top: 17px;
    right: 16px;
    width: 0.1rem;
    height: 0.1rem;
  }
}

.input {
  margin: 10px 20px;
}

.btn {
  text-transform: capitalize;
  font-weight: 600;

  &-cancel {
    span {
      color: #ccccce;
    }
  }
}

.actions {
  justify-content: flex-end;
  display: flex;

  :deep .v-btn__content {
    letter-spacing: 0;
  }
}
</style>
