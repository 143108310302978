<template>
  <div class="medication-statement-root">
    <v-expand-transition>
      <div v-if="isEditing" class="medication-statement-edit">
        <MedicationEdit
          :item="item"
          @onCancel="$emit('onCancelEdit')"
          @onSave="(e) => $emit('onSave', e)"
          @onSaveAsFavorite="(e) => $emit('onSaveAsFavorite', e)"
        />
      </div>
    </v-expand-transition>
    <div v-if="!isEditing" :class="`medication-statement ${item.status}`">
      <div class="source">
        <img v-if="item.informationSource === 'practitioner'" :src="require('@/assets/icons/careIcon.svg')" alt="" />
        <img v-else :src="require('@/assets/icons/patientRelated.svg')" alt="" />
      </div>
      <MedicationDetails :item="item" />
      <div class="action">
        <div class="medication-status">
          <div v-if="isEditingStatus" class="edit-wrap">
            <Textarea
              :value="additionalInstructions"
              fieldName="additionalInstructions"
              placeholder="Enter additional instructions"
              @change="onFieldChange"
            />
            <div class="edit-actions">
              <v-btn color="primary" depressed @click="onSave"> Save</v-btn>
              <v-btn color="primaryLight1" depressed @click="onCancel"> Cancel</v-btn>
            </div>
          </div>
        </div>

        <div class="edit">
          <template v-if="item.status !== 'stopped'">
            <template v-if="item.status !== 'on-hold'">
              <v-btn :disabled="isDisableFavorite" class="edit-btn" depressed @click="$emit('onSaveAsFavorite', item)">
                <img :src="require('@/assets/icons/star-bold.svg')" alt="" />
              </v-btn>
              <v-btn class="edit-btn" depressed @click="$emit('onEditStart')">
                <img :src="require('@/assets/icons/restore.svg')" alt="" />
                Change
              </v-btn>
              <v-btn class="edit-btn" depressed @click="onChangeStatus('on-hold')">
                <img :src="require('@/assets/icons/close.svg')" alt="" />
                Cancel
              </v-btn>
            </template>
            <v-btn v-else class="edit-btn" depressed @click="onChangeStatus('active')">
              <img :src="require('@/assets/icons/close.svg')" alt="" />
              Undo Cancellation
            </v-btn>
          </template>
          <v-btn v-if="item.status === 'stopped'" class="remove-btn" depressed @click="onChangeStatus('active')">
            Undo Removal
          </v-btn>
          <v-btn v-else class="remove-btn" depressed @click="onChangeStatus('stopped')">
            <img :src="require('@/assets/icons/trash.svg')" alt="" />
            Remove
          </v-btn>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment-timezone";
import { mapActions } from "pinia";

import Textarea from "@/components/uikit/Textarea";
import MedicationDetails from "@/components/VisitNotes/medicationReconsiliation/MedicationDetails";
import MedicationEdit from "@/components/VisitNotes/medicationReconsiliation/MedicationEdit";
import { useTerminologyStore } from "@/pinia-store/terminology";

export default {
  name: "MedicationField",
  components: { Textarea, MedicationDetails, MedicationEdit },
  data() {
    return {
      isEditingStatus: false,
      additionalInstructions: "",
    };
  },
  props: {
    isEditing: {
      type: Boolean,
      default: () => false,
    },
    withStatus: {
      type: Boolean,
      default: () => false,
    },
    withEdit: {
      type: Boolean,
      default: () => false,
    },
    item: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  computed: {
    ...mapActions(useTerminologyStore, ["practitionerFavMedication"]),
    date() {
      let date = moment(this.item?.startDate);
      return date.isValid() ? date.format("MMM DD, YYYY") : undefined;
    },
    isDisableFavorite() {
      return this.practitionerFavMedication.some((i) => i.id === this.item.id);
    },
  },
  methods: {
    onChangeStatus(e) {
      this.$emit("onChangeStatus", e);
    },
    onEdit(e) {
      e.preventDefault();
      e.stopPropagation();
      this.isEditingStatus = !this.isEditingStatus;
    },
    onChangeDifferently() {
      this.$emit("onUpdated");
    },
    onFieldChange(value, fieldName) {
      this[fieldName] = value;
    },
  },
};
</script>

<style lang="scss" scoped>
.medication-statement-edit {
  padding: 22px 20px;
}

.medication-statement {
  display: grid;
  grid-template-columns: 40px auto auto;
  grid-gap: 15px 10px;
  width: 100%;
  padding: 20px 28px 20px 27px;
  border-bottom: 1px solid var(--v-primarylight2-base);
  @include mobile {
    padding: 20px 5px 20px 5px !important;
  }

  &.intended {
    background-color: #f7fdf9;
  }

  &.stopped {
    background-color: rgba(229, 57, 53, 0.08);
  }

  &.on-hold {
    background-color: #e5e5e5;
  }

  .source {
    flex-basis: 20px;
  }

  .action {
    display: flex;
    justify-content: flex-end;

    .edit-btn {
      background-color: $primarylight1 !important;
      color: #2196f3;
    }

    .remove-btn {
      background-color: #fdefef !important;
      color: #e53935;
    }

    .edit {
      display: flex;
      gap: 12px;

      button {
        text-transform: none;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;

        .v-btn__content {
          padding: 8px 11px;
        }
      }
    }
  }
}
</style>
