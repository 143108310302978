import { defineStore } from "pinia";

import { MedicationApi } from "@/api/medication";

export const useMedicationsStore = defineStore({
  id: "medications",
  state: () => ({
    isLoadingPatientMedications: false,
    patientMedications: [],
  }),
  actions: {
    async getPatientMedications(payload: Record<any, any>) {
      this.isLoadingPatientMedications = true;
      this.patientMedications = await MedicationApi.getPatientMedication(payload);
      this.isLoadingPatientMedications = false;
    },
  },
});
