import { apiRequest } from "./apiRequest";

export interface RoomInviteCredentials {
  inviteId: string;
  password: string;
}

const getToken = async (roomInviteCredentials: RoomInviteCredentials) => {
  const { data } = await apiRequest.post(`/roomInvite/authorization`, roomInviteCredentials);
  return data;
};

export const RoomInviteApi = { getToken };
