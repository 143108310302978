<template>
  <div>
    <v-btn
      class="mx-2 btn-open"
      color="rgba(15,15,15, 0.36)"
      fab
      large
      @click="isOpenFileListWindow = !isOpenFileListWindow"
    >
      <v-icon color="white" dark> mdi-attachment</v-icon>
    </v-btn>
    <div v-if="$vuetify.breakpoint.mdAndUp && isOpenFileListWindow" class="video-room-media">
      <div class="heading-6 video-room-media__title">
        <span> Share file with {{ isPatient ? "Doctor" : "Patient" }} </span>
        <v-btn class="request-consult__close" icon rounded @click="isOpenFileListWindow = false">
          <v-icon small>mdi-close</v-icon>
        </v-btn>
      </div>
      <div class="video-room-media__body">
        <template v-if="files.length">
          <FileRow v-for="file in files" :key="file.id" :editable="fileIsEditable" :file="file" />
        </template>
      </div>
      <div class="video-room-media__footer">
        <MediaUploader
          :componentId="room.id"
          :disabledAutoFetch="true"
          component="appointments"
          @onUploaded="onFileLoaded"
        />
      </div>
    </div>
    <v-dialog
      v-if="!Boolean($vuetify.breakpoint.mdAndUp)"
      :value="isOpenFileListWindow"
      fullscreen
      max-width="485px"
      @click:outside="hideDialog"
    >
      <v-card class="video-room-media-card">
        <v-container class="pa-3">
          <div class="video-room-media-card__body">
            <div class="video-room-media-card__title">{{ files.length }} files Shared</div>
            <div class="video-room-media-card__subtitle">
              Please share files, including photos, with your {{ isPatient ? "doctor" : "patient" }} during a virtual
              visit
            </div>
            <template v-if="files.length">
              <FileRow v-for="file in files" :key="file.id" :editable="fileIsEditable" :file="file" />
            </template>
            <MediaUploader
              :componentId="room.id"
              :disabledAutoFetch="true"
              component="appointments"
              @onUploaded="onFileLoaded"
            />
            <v-btn
              block
              class="btn btn-cancel video-room-media-card__close-btn"
              color="#E5E5E5"
              outlined
              @click="onClose"
            >
              <span>Close</span>
            </v-btn>
          </div>
        </v-container>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapState } from "pinia";

import FileRow from "@/components/media/FileRow";
import MediaUploader from "@/components/media/MediaUploader";
import { useAuthStore } from "@/pinia-store/auth";
import { useMediaStore } from "@/pinia-store/media";
import { useVideoRoomStore } from "@/pinia-store/videoRoom";
import { RolesEnum } from "@/types/Roles.enum";

export default {
  name: "VideoRoomMedia",
  components: {
    MediaUploader,
    FileRow,
  },
  data: () => {
    return {
      fetchFileTimer: null,
      isOpenFileListWindow: false,
      isOpen: false,
    };
  },
  watch: {
    room(newValue) {
      if (this.uid !== newValue?.patient?.id && newValue?.patient?.id) this.setUid(newValue.patient.id);
    },
  },
  computed: {
    ...mapState(useMediaStore, ["files", "uid"]),
    ...mapState(useVideoRoomStore, ["room"]),
    ...mapState(useAuthStore, ["role"]),
    ...mapState(useAuthStore, { loggedUserId: "uid" }),
    fileIsEditable() {
      return this.role !== RolesEnum.Patient;
    },
    isPatient() {
      return this.role === RolesEnum.Patient;
    },
  },
  methods: {
    ...mapActions(useMediaStore, ["fetchComponentFiles"]),
    ...mapActions(useMediaStore, ["setUid", "setFiles"]),
    onClose() {
      this.isOpenFileListWindow = false;
    },
    hideDialog() {
      this.isOpenFileListWindow = false;
    },
    async onFileLoaded() {
      await this.fetchComponentFiles({ component: "appointments", componentId: this.room.id });
    },
    setIntervalForFetch() {
      this.fetchFileTimer = setInterval(() => {
        this.fetchComponentFiles({ component: "appointments", componentId: this.room.id });
      }, 5000);
    },
  },
  async mounted() {
    if (this.role === RolesEnum.Patient) {
      this.setUid(this.loggedUserId);
    }
    this.$userActivityTracker.destroy();
    this.setIntervalForFetch();
  },
  beforeDestroy() {
    this.$userActivityTracker.startTimer();

    clearInterval(this.fetchFileTimer);
  },
};
</script>

<style lang="scss">
.video-room-media-toggle-mobile {
  position: absolute;
  border-color: rgba(15, 15, 15, 0.36);
  top: calc(21px + 40px + 18px) !important;
  right: 20px;
  z-index: 3;
  background: rgba(15, 15, 15, 0.36);
}

.video-room-media-card {
  background: #e5e5e5 !important;

  &__close-btn {
    margin-top: 30px;
    padding-top: 15px;
    padding-bottom: 15px;

    .v-btn__content {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 17px;
      text-align: center;
      color: #91909c;
    }
  }

  &__body {
    width: 90%;
    max-width: 420px;
    background: white;
    padding: 24px 20px;
    border: 1px solid #e7e8f2;
    box-sizing: border-box;
    border-radius: 6px;
    margin: 0 auto;
  }

  &__title {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    justify-content: center;
    color: #33343e;
  }

  &__subtitle {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    letter-spacing: 0.32px;
    padding-bottom: 20px;
  }
}

.btn-open {
  @include mobile {
    height: 43px !important;
    width: 43px !important;
  }
}

.video-room-media {
  overflow-x: auto;
  z-index: 2;
  background: #ffffff;
  border: 1px solid #e7e8f2;
  box-shadow: 0 2px 26px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  position: absolute;
  width: 295px;
  bottom: 100px;
  left: 20px;

  &.mobile {
    width: 100%;
    height: 100%;
    left: 0px;
    top: 0px;
    bottom: 0px;
    position: fixed;
    z-index: 99;
  }

  @include large {
    width: 360px;
  }
  @include desktop {
    width: 320px;
  }

  &__title {
    padding: 17px;
    justify-content: space-between;
    display: flex;
    align-items: center;
    font-style: normal;
    font-weight: 500;
    font-size: 25px;
    line-height: 31px;
    color: $primary;
  }

  &__body {
    padding: 17px;
    overflow-x: auto;

    .file-row {
      margin-bottom: 0 !important;
    }

    max-height: 250px;
    @include desktop {
      max-height: 300px;
    }
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  &__footer {
    padding: 17px;
  }
}
</style>
