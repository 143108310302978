import moment from "moment-timezone";
import { defineStore } from "pinia";

import { AppointmentsApi } from "@/api/appointment";
import { PatientsApi } from "@/api/patients";
import { PharmacyApi } from "@/api/pharmacy";
import { PractitionersAPI } from "@/api/practitioners";
import { useAuthStore } from "@/pinia-store/auth";
import { defaultPagination } from "@/pinia-store/encounters";
import { AppointmentInformationTab, CancelAppointment } from "@/pinia-store/interfaces/CancelAppointment";
import { PostAppointmentBody } from "@/pinia-store/interfaces/PostAppointmentBody";
import { AppointmentGroup, AppointmentQueryFilterDto } from "@/types/AppointmentQueryFilter";

export const useAppointmentsStore = defineStore({
  id: "appointments",
  state: () => ({
    practitionerTodayAppointmentsCount: 0,
    showMoreDetailsOnId: "",
    appointmentInformationTab: "next",
    id: "",
    slotId: "",
    practitionerId: "",
    patientId: "",
    status: "",
    chiefComplaint: "",
    complaintDescription: "",
    visitDate: "",
    pendingAppointments: [],
    pastAppointments: [],
    upcomingAppointments: [],
    pendingAppointmentsPagination: {},
    todayAppointmentsPagination: defaultPagination,
    upcomingAppointmentsPagination: defaultPagination,
    todayAppointments: [],
    upcomingAppointment: {},
    appointmentInformation: {},
    appointmentInformationLoading: false,
    pastFilter: {
      practitionerId: "",
      patientId: "",
      startDate: moment().format("YYYY-MM-DD"),
      endDate: moment().subtract(7, "days").format("YYYY-MM-DD"),
    },
    pendingFilter: {
      practitionerId: "",
      patientId: "",
      startDate: moment().format("YYYY-MM-DD"),
      endDate: moment().add(7, "days").format("YYYY-MM-DD"),
    },
    upcomingFilter: {
      practitionerId: "",
      patientId: "",
      startDate: moment().format("YYYY-MM-DD"),
      endDate: moment().add(7, "days").format("YYYY-MM-DD"),
    },
  }),
  actions: {
    setShowMoreDetailsOnId(payload: null | string) {
      this.showMoreDetailsOnId = payload;
    },
    setAppointmentInformationLoading(payload: boolean) {
      this.appointmentInformationLoading = payload;
    },
    setAppointmentInformationTab(payload: AppointmentInformationTab) {
      this.appointmentInformationTab = payload;
    },

    setAppointmentInformation(payload: any) {
      this.appointmentInformation = payload;
    },
    setStringFieldByName(payload: { value: any; fieldName: any }) {
      const { fieldName, value } = payload;
      this[fieldName] = value;
    },

    // ---end
    async scheduleAppointment(payload: PostAppointmentBody) {
      return AppointmentsApi.create(payload);
    },
    async cancelAppointment(payload: CancelAppointment) {
      return AppointmentsApi.cancel(payload);
    },
    async getPendingAppointments(query?: AppointmentQueryFilterDto) {
      const appointments = await AppointmentsApi.getAppointmentByGroup(AppointmentGroup.pending, query);
      const data = (appointments.results || []).map((appointment: any) => {
        return {
          ...appointment,
          confirmedByPractitioner: appointment.participant[0].status === "accepted",
          confirmedByPatient: appointment.participant[1].status === "accepted",
        };
      });
      const pendingNotExpired = data.filter((i: any) => {
        return !moment().isAfter(i.start);
      });
      this.pendingAppointments = data;
      this.pendingAppointmentsPagination = pendingNotExpired.pagination || {};
    },
    async getTodayAppointments() {
      const appointments = await AppointmentsApi.getAppointmentByGroup(AppointmentGroup.today);
      this.todayAppointments = (appointments.results || []).map((appointment: any) => {
        return {
          ...appointment,
          confirmedByPractitioner: appointment.participant[0].status === "accepted",
          confirmedByPatient: appointment.participant[1].status === "accepted",
        };
      });
      this.todayAppointmentsPagination = appointments.pagination || {};
    },
    async getPractitionerTodayAppointmentsCount() {
      const data = await AppointmentsApi.getAppointmentsBadge();
      this.practitionerTodayAppointmentsCount = data.count;
    },
    async applyPendingAppointments(payload: Record<any, any>) {
      const filter = { ...this.pendingFilter, ...payload };
      this.pendingFilter = filter;
      const appointments = await AppointmentsApi.getAppointmentByStatus({ ...filter, status: "pending" });
      const data = (appointments.results || []).map((appointment: any) => {
        return {
          ...appointment,
          confirmedByPractitioner: appointment.participant[0].status === "accepted",
          confirmedByPatient: appointment.participant[1].status === "accepted",
        };
      });
      const pendingNotExpired = data.filter((i: any) => {
        return !moment().isAfter(i.start);
      });
      this.pendingAppointments = data;
      this.pendingAppointmentsPagination = pendingNotExpired.length || {};
    },
    async getPastAppointments(query?: AppointmentQueryFilterDto) {
      // const appointments = await AppointmentsApi.getAppointmentByStatus({ ...(query ? { ...query } : {}), past: true });
      const appointments = await AppointmentsApi.getAppointmentByGroup(AppointmentGroup.past, {
        ...(query ? { ...query } : {}),
        past: true,
      });
      // const authStore = useAuthStore();
      const data = (appointments.results || [])
        // .filter((i: any) => {
        //   return (
        //     ["finished", "planned", "cancelled"].includes(i.status) ||
        //     (i.status === "pending" && moment.tz(authStore.timeZone).isAfter(i.start))
        //   );
        // })
        .map((appointment: any) => {
          return {
            ...appointment,
            confirmedByPractitioner: appointment.participant[0].status === "accepted",
            confirmedByPatient: appointment.participant[1].status === "accepted",
          };
        });
      this.pastAppointments = data;
      this.pastAppointmentsPagination = appointments.length || {};
    },
    async getUpcomingAppointments(query?: AppointmentQueryFilterDto) {
      // const appointments = await AppointmentsApi.getAppointmentByStatus({ ...(query ? { ...query } : {}), past: true });
      const appointments = await AppointmentsApi.getAppointmentByGroup(AppointmentGroup.upcoming, {
        ...(query ? { ...query } : {}),
      });
      // const authStore = useAuthStore();
      this.upcomingAppointments = (appointments.results || [])
        // .filter((i: any) => {
        //   return (
        //     ["finished", "planned", "cancelled"].includes(i.status) ||
        //     (i.status === "pending" && moment.tz(authStore.timeZone).isAfter(i.start))
        //   );
        // })
        .map((appointment: any) => {
          return {
            ...appointment,
            confirmedByPractitioner: appointment.participant[0].status === "accepted",
            confirmedByPatient: appointment.participant[1].status === "accepted",
          };
        });
      this.upcomingAppointmentsPagination = appointments.length || {};
    },
    async getScheduledAppointmentInfo(appointmentId: string) {
      const { slotId, status, practitionerId, patientId, id, chiefComplaint, complaintDescription, start } =
        await AppointmentsApi.getById(appointmentId);
      const data = {
        slotId,
        status,
        practitionerId,
        patientId,
        id,
        chiefComplaint,
        complaintDescription,
        visitDate: moment(start).format("YYYY-MM-DD"),
      };
      this.slotId = slotId;
      this.status = status;
      this.id = id;
      this.practitionerId = practitionerId;
      this.patientId = patientId;
      this.chiefComplaint = chiefComplaint;
      this.complaintDescription = complaintDescription;
      this.visitDate = moment(start).format("YYYY-MM-DD");
      return data;
    },

    async updateAppointment(appointmentId: string) {
      const { slotId, practitionerId, chiefComplaint, complaintDescription } = this;

      await AppointmentsApi.update(appointmentId, {
        slotId,
        practitionerId,
        chiefComplaint,
        complaintDescription,
      });

      return await AppointmentsApi.complete(appointmentId);
    },
    async getAppointmentInformation(appointmentId: string) {
      try {
        const appointment = await AppointmentsApi.getById(appointmentId);
        const [patient, pharmacy, practitionerSettings] = await Promise.all([
          PatientsApi.patientGetProfile(appointment.patientId),
          PharmacyApi.getForEncounter(appointment.encounterId),
          PractitionersAPI.getPractitionerById(appointment.practitionerId),
        ]);
        appointment.patientEmail = patient.email;
        appointment.patientBirthDate = patient.birthDate;
        if (pharmacy) appointment.pharmacy = pharmacy;
        appointment.telephone = patient.phoneNumber || patient.homePhoneNumber;
        appointment.phoneNumber = patient.phoneNumber || "";
        appointment.homePhoneNumber = patient.homePhoneNumber || "";
        appointment.patientZipCode = patient.postalCode || "";

        appointment.practitionerQualification = practitionerSettings.qualification;
        this.appointmentInformation = appointment;
      } catch (e) {
        console.info("Error on fetching appointment", appointmentId, e);
      }
      this.appointmentInformationLoading = false;
    },
    cleanAppointmentInformation() {
      this.appointmentInformation = {};
    },
    clearAllFields() {
      this.slotId = "";
      this.chiefComplaint = "";
      this.height = "";
      this.weight = "";
      this.patientId = "";
      this.visitDate = "";
      this.complaintDescription = "";
    },
  },
  getters: {
    practitionerPendingAppointmentsCount(state) {
      const authStore = useAuthStore();
      return state.pendingAppointments.filter((i: any) => {
        return !moment.tz(authStore.timeZone).isAfter(i.start);
      }).length;
    },

    patientNavbarAppointmentsCount(state): number {
      const authStore = useAuthStore();

      const timeZone = authStore.timeZone;
      // todo: replace this getter
      // const plannedEncounters = rootGetters["encounters/plannedEncounters"];
      const plannedEncounters = [];
      const pendingAppointments = (state.pendingAppointments || [])
        .map((i: { start: moment.MomentInput }) => ({ ...i, _group: moment(i.start).format("YYYY-MM-DD") }))
        .filter((i: { start: moment.MomentInput }) => {
          return !moment().tz(timeZone).isAfter(i.start);
        });
      return plannedEncounters.length + (pendingAppointments?.length || 0);
    },
  },
});
