import { defineStore } from "pinia";

export interface BloodPressureOrderState {
  formOpened: boolean;
  form: Record<string, any>;
}

export const useBloodPressureOrderStore = defineStore({
  id: "blood-pressure-order",
  state: () => ({
    formOpened: false,
    form: {},
  }),
});
