import { AvailabilitySlots } from "@/types/Availability";

import { apiRequest } from "./apiRequest";

interface RegistrarUpdateSettingsBody {
  timeZone: string;
  availabilitySlots: AvailabilitySlots;
  locale: string;
}

interface RegistrarUpdateProfileBody {
  name: {
    lastName: string;
    firstName: string;
  };
}

const registrarUpdateSettings = async (registrarId: string, body: Partial<RegistrarUpdateSettingsBody>) => {
  const { data } = await apiRequest.put(`/registrar/${registrarId}/settings`, body);
  return data;
};

const registrarGetSettings = async (registrarId: string) => {
  const { data } = await apiRequest.get(`/registrar/${registrarId}/settings`);
  return data;
};

const getRegistrarById = async (registrarId: string) => {
  const { data } = await apiRequest.get(`/registrar/${registrarId}`);
  return data;
};

const updateProfile = async (registrarId: string, body: RegistrarUpdateProfileBody) => {
  const { data } = await apiRequest.put(`/registrar/${registrarId}`, body);
  return data;
};

const avatarUpload = async (registrarId: string, formData: FormData) => {
  const { data } = await apiRequest.post(`/registrar/${registrarId}/avatar`, formData, {
    headers: { "Content-Type": "multipart/form-data" },
  });
  return data;
};

const getAllRegistrars = async () => {
  const { data } = await apiRequest.get(`/registrar`);
  return data;
};

const getRegistrarPractitionersWithSlots = async (id: string) => {
  const { data } = await apiRequest.get(`/registrar/${id}/practitioners/slots`);
  return data;
};

const getRegistrarPractitioners = async (id: string) => {
  const { data } = await apiRequest.get(`/registrar/${id}/practitioners`);
  return data;
};

export const RegistrarsAPI = {
  registrarUpdateSettings,
  registrarGetSettings,
  getRegistrarById,
  updateProfile,
  avatarUpload,
  getAllRegistrars,
  getRegistrarPractitionersWithSlots,
  getRegistrarPractitioners,
};
