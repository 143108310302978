<template>
  <div class="full-width fill-height box-shadow-1 chat-log-main">
    <div>
      <h2 class="chat-log-header-participant">
        {{ $t(`conversation.with`) }} {{ channel.conversationLogRecord.userName }}
      </h2>
      <h5 class="chat-log-header-times">
        {{ formatDate(channel.conversationLogRecord.date) }} |
        {{ convertChatDuration(channel.conversationLogRecord.chatDuration) }}
      </h5>
    </div>
    <div class="fill-height full-width d-flex flex-column">
      <div ref="chatContainer" v-chat="{ always: false }" class="chat flex-grow-1">
        <Preview v-if="!messagesComp.length" :text="$t('chat.preview.noMessages')" />
        <div v-for="msg in messagesComp" v-else :key="msg.index" class="border-radius-inherit ma-4 mt-0">
          <ChatLogMessageRow v-if="!msg.attributes.isHidden" :message="msg" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { DateTime } from "luxon";
import moment from "moment-timezone";
import { mapState } from "pinia";

import Preview from "@/components/Chat/Channel/Preview";
import ChatLogMessageRow from "@/components/Chat/ChatBotLog/ChatLogMessageRow";
import { useChatStore } from "@/pinia-store/chat";
import { scrollToBottom } from "@/utils/scrollToBottom";

export default {
  name: "ChannelItem",
  components: {
    ChatLogMessageRow,
    Preview,
  },
  inject: ["injectable"],
  data: () => ({
    channel: null,
    role: true, //todo investigate how that was determined in previous implementation
    messages: [],
  }),
  watch: {
    getCurrentChannel(newVal) {
      this.channel = newVal;
    },
  },
  computed: {
    ...mapState(useChatStore, ["getCurrentChannel"]),
    messagesComp() {
      const messages = this.messages ?? [];
      if (!messages || messages.length === 0) {
        return [];
      }
      let currAuthor = null;
      for (let i = 0; i < messages.length; i++) {
        if (messages[i].author !== currAuthor) {
          messages[i].head = true;
          currAuthor = messages[i].author;
          if (i > 0) {
            messages[i - 1].last = true;
          }
        } else if (
          i > 0 &&
          DateTime.fromJSDate(messages[i].dateCreated)
            .diff(DateTime.fromJSDate(messages[i - 1].dateCreated), "minutes")
            .as("minutes") > 10
        ) {
          messages[i].head = true;
          messages[i - 1].last = true;
        }
      }
      messages[messages.length - 1].last = true;
      return messages;
    },
  },
  methods: {
    ...mapState(useChatStore, ["getChatLog"]),
    formatDate(timestamp) {
      return moment(parseInt(timestamp)).format("MM/DD/YYYY HH:MM");
    },
    convertChatDuration(chatDuration) {
      const seconds = Math.floor(chatDuration / 1000);
      const secondsDisplay = seconds % 60;
      const minutes = Math.floor(seconds / 60);
      const minutesDisplay = minutes % 60;
      const hours = Math.floor(minutes / 60);
      let sec, min, h;
      seconds <= 9 ? (sec = `0${secondsDisplay}`) : (sec = `${secondsDisplay}`);
      minutes < 10 && minutes >= 0 ? (min = `0${minutesDisplay}`) : (min = `${minutesDisplay}`);
      hours < 10 && hours >= 0 ? (h = `0${hours}`) : hours !== 0 ? (h = `${hours}`) : null;

      return `${h}:${min}:${sec}`;
    },
    scroll() {
      let element = this.$refs.chatContainer;
      scrollToBottom(element, false);
    },
    checkScroll() {
      let el = this.$refs.chatContainer;
      const scrolled = el.scrollTop + el.clientHeight + 1 < el.scrollHeight;
      if (scrolled && el.scrollTop < el.scrollHeight / 10) {
        el.dispatchEvent(new Event("chatTopReached"));
      }
    },
  },
  async created() {
    this.channel = this.injectable.twilioContext.channelRepository.getChannel(this.$route.params["id"]);
    this.messages = this.channel.conversationLogRecord.conversationData.messages ?? [];
  },
};
</script>

<style lang="scss" scoped>
.chat-log {
  &-main {
    margin: 43px 25px;
  }

  &-header {
    &-participant {
      padding: 17px 28px 0;
    }

    &-times {
      padding: 0 28px 20px;
      color: rgba(0, 0, 0, 0.55);
    }
  }
}

.header {
  height: 75px;

  &__actions-menu {
    .v-list {
      padding-top: 0;
      padding-bottom: 0;
    }

    text-align: left;
    background: #ffffff;
    border: 1px solid #eeeff7;
    box-shadow: none;
    box-sizing: border-box;
    border-radius: 4px;

    .v-list-item:not(:last-child) {
      border-bottom: 1px solid #eeeff7;
    }

    .v-list-item:hover {
      cursor: pointer;
      background: rgba(34, 148, 242, 0.08);
    }
  }

  &-c1 {
    overflow: hidden;
  }

  &-body {
    margin-left: 25px;

    &-title {
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
    }

    &-subtitle {
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 20px;
      color: #000000;
      opacity: 0.46;
    }
  }
}

.chat {
  border-bottom: 1px solid #efeff0;
  border-top: 1px solid #efeff0;
  overflow-y: scroll;
  overflow-x: hidden;
  padding: 0 22px 22px 22px;
  scroll-padding: 22px;
}

.input {
  padding: 10px;
  min-height: 61px;

  &-body {
    border: 1px solid #d2d3e1;
    box-sizing: border-box;
    border-radius: 5px;
    overflow: hidden;

    &-textarea {
      margin-bottom: -25px;
    }

    &-actions {
      width: 80px;

      &-buttons {
        margin-bottom: 2px;
      }
    }
  }
}

.hidden {
  display: none !important;
}
</style>
