import "vuetify/dist/vuetify.min.css";

import Vue from "vue";
import Vuetify, { UserVuetifyPreset } from "vuetify";

import Calendar from "@/components/icons/Calendar.vue";
import ChatArchive from "@/components/icons/ChatArchive.vue";
import ChatMessages from "@/components/icons/ChatMessages.vue";
import Details from "@/components/icons/Details.vue";
import EN from "@/components/icons/EN.vue";
import ES from "@/components/icons/ES.vue";
import Plug from "@/components/icons/Plug.vue";
import RadioChecked from "@/components/icons/RadioChecked.vue";

Vue.use(Vuetify);

const opts: UserVuetifyPreset = {
  icons: {
    iconfont: "mdi",
    values: {
      radioChecked: {
        component: RadioChecked,
      },
      chatArchive: {
        component: ChatArchive,
      },
      chatMessages: {
        component: ChatMessages,
      },
      calendar: {
        component: Calendar,
      },
      details: {
        component: Details,
      },
      plug: {
        component: Plug,
      },
      en: {
        component: EN,
      },
      es: {
        component: ES,
      },
    },
  },
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        success: "#2fcf6f",
        error: "#e53935",
        errorlight1: "#FFEFEF",
        primarylight3: "#91909c",
        primarylight2: "#e7e8f2",
        primarylight1: "#eff6ff",
        primarylight: "#EEEFF7",
        primary: "#2196f3",
        primaryblack: "#25233a",
        primaryblack2: "#33343e",
        primaryblack3: "#33343d",
        primaryblack4: "#1e85d9",
        primaryblack5: "#1565c0",
        icon: "#d2d3e1",
      },
    },
  },
};

export default new Vuetify(opts);
