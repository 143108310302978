export const initialPatientInfo = {
  firstName: "",
  lastName: "",
  phoneNumber: "",
  gender: "other",
  state: "",
};

export const initialMedicalInfo = {
  allergies: [],
  pmh: [],
  psh: [],
  medications: [],
  document: [],
};

export const initialEncounterNotes = {
  chiefComplaint: "",
  dx: "",
  assessment: "",
  plan: "",
  followUp: "",
};

export const initialVisitDescription = {
  complaintDescription: "",
  weight: "",
  height: "",
  isSmoking: false,
  pmh: [],
  allergies: [],
};
