import { RolesEnum } from "@/types/Roles.enum";
import { Patient, Practitioner } from "@/types/users";

export interface TaskRequester {
  profile: Patient | Practitioner;
  role: RolesEnum;
}

export interface TaskOwner {
  profile: Patient | Practitioner;
  role: RolesEnum;
}

export enum ValueSetTaskStatusEnum {
  draft = "draft",
  requested = "requested",
  received = "received",
  accepted = "accepted",
  rejected = "rejected",
  ready = "ready",
  cancelled = "cancelled",
  "in-progress" = "in-progress",
  "on-hold" = "on-hold",
  failed = "failed",
  completed = "completed",
  "entered-in-error" = "entered-in-error",
}

export enum ValueSetTaskIntentEnum {
  unknown = "unknown",
  proposal = "proposal",
  plan = "plan",
  order = "order",
  "original-order" = "original-order",
  "reflex-order" = "reflex-order",
  "filler-order" = "filler-order",
  "instance-order" = "instance-order",
  option = "option",
}

export enum ValueSetTaskPriorityEnum {
  routine = "routine",
  urgent = "urgent",
  asap = "asap",
  stat = "stat",
}

export enum ValueSetTaskCodeEnum {
  approve = "approve",
  fulfill = "fulfill",
  instantiate = "instantiate",
  abort = "abort",
  replace = "replace",
  change = "change",
  suspend = "suspend",
  resume = "resume",
}

export interface TaskDto {
  id?: string;

  status: ValueSetTaskStatusEnum;

  phoneNumber?: string;

  email?: string;

  intent: ValueSetTaskIntentEnum;

  priority: ValueSetTaskPriorityEnum;

  authoredOn: string;

  description?: string;

  businessStatus: "adult" | "pediatric";

  valueCode?: string;

  lastModified: string;

  requester?: TaskRequester;

  owner?: TaskOwner;
}
