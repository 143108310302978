import { DateTime } from "luxon";
import moment from "moment-timezone";
import { mapActions, mapState } from "pinia";
import Vue from "vue";

import { useAuthStore } from "@/pinia-store/auth";
import { useEncountersStore } from "@/pinia-store/encounters";
import { useRegistrarsStore } from "@/pinia-store/registrars";
import { RolesEnum } from "@/types/Roles.enum";

export const upcomingListMixin = Vue.extend({
  data: () => ({
    currentDate: [moment().format("YYYY-MM-DD"), moment().add(7, "days").format("YYYY-MM-DD")],
    dialog: false,
    loading: false,
    cancelledId: "",
  }),
  computed: {
    ...mapState(useEncountersStore, ["encounters"]),
    ...mapState(useRegistrarsStore, ["registrarPractitioners"]),
    ...mapState(useAuthStore, ["role", "uid", "timeZone"]),
    upcomingItems() {
      let now = this.$moment.tz(this.timeZone);
      return this.encounters
        .filter((e) => ["in-progress", "planned"].includes(e.status))
        .filter((e) => this.$moment(e.end).isAfter(now))
        .filter((e) =>
          this.role === RolesEnum.Registrar
            ? this.registrarPractitioners.find((p) => p.id === e.practitioner.id)
            : true,
        )
        .reduce((acc, val) => {
          const currentDate = DateTime.fromISO(val.start).toFormat("cccc, d MMMM yyyy");
          const foundIndex = acc.findIndex((el) => el.date === currentDate);
          if (foundIndex === -1) {
            acc.push({ date: currentDate, items: [val] });
          } else {
            acc[foundIndex].items.push(val);
          }
          return acc;
        }, [])
        .sort((a, b) => new Date(a.date) - new Date(b.date));
    },
  },
  methods: {
    ...mapActions(useEncountersStore, ["getListEncounters"]),
    ...mapActions(useRegistrarsStore, ["getRegistrarPractitioners"]),
    joinRoom(encounterId) {
      this.$router.push(`/${this.role}/encounters/${encounterId}`);
    },
    onCancel(id) {
      this.cancelledId = id;
      this.dialog = true;
    },
    async onVisitCancelled() {
      await this.getListEncounters();
    },
    async getListEncounters() {
      this.loading = true;
      try {
        await this.getAllEncounters({ future: true, date: this.$moment.tz(this.timeZone).format("YYYY-MM-DD") });
      } catch (e) {
        console.error(e);
      }
      this.loading = false;
    },
  },
  async mounted() {
    await this.getListEncounters();
    if (this.role === RolesEnum.Registrar) {
      await this.getRegistrarPractitioners(this.uid);
    }
  },
});
