<template>
  <v-col :class="{ mobile: $vuetify.breakpoint.mdAndDown }" class="visit-notes_order white" cols="12">
    <v-overlay :opacity="0.2" :value="fab" :z-index="1"></v-overlay>
    <div v-if="!Boolean($vuetify.breakpoint.mdAndDown)" class="leftBox">
      <v-btn :small="$vuetify.breakpoint.mdAndDown" class="fav-btn" depressed rounded @click="onPreviewSignNote">
        <img :src="require('@/assets/icons/eye.svg')" alt="" />
        {{ !$vuetify.breakpoint.mdAndDown ? "Preview Draft" : "" }}
      </v-btn>
      <v-btn :small="$vuetify.breakpoint.mdAndDown" class="fav-btn" depressed rounded @click="onSaveDraft">
        Save draft
      </v-btn>
      <v-btn
        :disabled="isPended"
        :small="$vuetify.breakpoint.mdAndDown"
        class="pend-btn"
        depressed
        rounded
        @click="onPendSignNote"
      >
        <img :src="require('@/assets/icons/time-yelow.svg')" alt="" />
        {{ !$vuetify.breakpoint.mdAndDown ? (isPended ? "Pended" : "Pend") : "" }}
      </v-btn>

      <v-btn
        :disabled="isSignDisabled"
        :small="$vuetify.breakpoint.mdAndDown"
        class="sign-btn"
        depressed
        rounded
        @click="onSignNote"
      >
        <v-icon color="white" x-small>mdi-check</v-icon>
        Sign & Send Encounter
      </v-btn>
    </div>
    <v-divider v-if="!Boolean($vuetify.breakpoint.mdAndDown)" class="divider"></v-divider>

    <div :class="`right-box-wrap align-center ${$vuetify.breakpoint.mdAndDown && 'full-width'}`">
      <div class="d-flex align-center">
        <v-divider v-if="!Boolean($vuetify.breakpoint.mdAndDown)" class="mr-3" vertical></v-divider>
        <div class="text-h6">
          Orders
          <v-chip color="blue" small
            >{{
              (intendedMedications.length || 0) +
              (stoppedMedications.length || 0) +
              (serviceRequestObservationsDraftStatus.length || 0)
            }}
          </v-chip>
        </div>
        <v-btn :small="$vuetify.breakpoint.mdAndDown" color="blue" icon @click="expanded = !expanded">
          <v-icon v-if="expanded"> mdi-chevron-up</v-icon>
          <v-icon v-if="!expanded"> mdi-chevron-down</v-icon>
        </v-btn>
      </div>
      <v-menu v-if="!Boolean($vuetify.breakpoint.mdAndDown)" content-class="order-menu" offset-y right top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn :small="$vuetify.breakpoint.mdAndDown" class="add-btn" v-bind="attrs" v-on="on">
            <v-icon small>mdi-plus</v-icon>
            Add Monitoring Order
          </v-btn>
        </template>
        <v-list>
          <v-list-item v-for="(item, key) in monitoringOrderOptions" :key="key" @click="item.handler">
            <v-list-item-title class="templates__edit action-btn"> {{ item.text }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>

      <v-speed-dial
        v-if="Boolean($vuetify.breakpoint.mdAndDown)"
        v-model="fab"
        :bottom="bottom"
        :direction="direction"
        :left="left"
        :open-on-hover="hover"
        :right="right"
        :top="top"
        :transition="transition"
      >
        <template v-slot:activator>
          <v-btn v-model="fab" color="#2FCF6F" dark fab>
            <v-icon v-if="fab"> mdi-close</v-icon>
            <img v-else :src="require('@/assets/icons/union.svg')" alt="Open" class="" />
          </v-btn>
        </template>

        <v-btn :disabled="isSignDisabled" class="relative" color="green" fab small @click="onSignNote">
          <v-btn class="fab-text-custom" elevation="4" light small> Sign & Send Encounter</v-btn>
          <img :src="require('@/assets/icons/approve-file.svg')" alt="" width="26px" />
        </v-btn>
        <v-btn class="relative" color="green" dark fab small @click="openMonitoringMenu">
          <v-btn class="fab-text-custom" elevation="4" light small> Add Monitoring Order</v-btn>
          <v-icon v-if="slideMonitoringItems">mdi-menu-down</v-icon>
          <v-icon v-else>mdi-menu-up</v-icon>
        </v-btn>
        <template v-if="slideMonitoringItems">
          <v-btn
            v-for="(item, key) in monitoringOrderOptions"
            :key="key"
            class="relative"
            fab
            small
            @click="item.handler"
          >
            <v-btn class="fab-text-custom" elevation="4" light small> {{ item.text }}</v-btn>
            <v-icon small>mdi-plus</v-icon>
          </v-btn>
        </template>
        <template v-else>
          <v-btn class="relative fav-btn" fab small @click="onPreviewSignNote">
            <v-btn class="fab-text-custom" elevation="4" light small> Preview Draft</v-btn>
            <img :src="require('@/assets/icons/eye.svg')" alt="" />
          </v-btn>

          <v-btn class="relative draft-btn" fab small @click="onSaveDraft">
            <v-btn class="fab-text-custom" elevation="4" light small> Save draft</v-btn>
            <img :src="require('@/assets/icons/approve-file.svg')" alt="" width="26px" />
          </v-btn>
          <v-btn :disabled="isPended" class="relative pend-btn" fab small @click="onPendSignNote">
            <v-btn class="fab-text-custom" elevation="4" light small>{{ isPended ? "Pended" : "Pend" }}</v-btn>
            <img :src="require('@/assets/icons/time-yelow.svg')" alt="" />
          </v-btn>
        </template>
      </v-speed-dial>
      <div :class="{ mobile: $vuetify.breakpoint.mdAndDown }" class="right-window">
        <v-expand-transition>
          <div v-if="expanded" class="wrap-body">
            <template
              v-if="
                Boolean(
                  serviceRequestObservationsDraftStatus.length + intendedMedications.length + stoppedMedications.length,
                )
              "
            >
              <div class="white right-box intended">
                <div class="order-title">
                  <v-icon>mdi-plus</v-icon>
                  New
                </div>
                <div v-if="serviceRequestObservationsDraftStatus.length" class="order-header">Health Data</div>
                <div class="body">
                  <ServiceRequestObservationDetails
                    v-for="item in serviceRequestObservationsDraftStatus"
                    :key="item.id"
                    :item="item"
                  />
                </div>
                <div v-if="intendedMedications.length" class="order-header">Medications</div>
                <div class="body">
                  <MedicationDetails v-for="item in intendedMedications" :key="item.id" :item="item" />
                </div>
              </div>
            </template>
            <template v-if="Boolean(stoppedMedications.length)">
              <div class="white right-box stopped">
                <div class="order-title">
                  <v-icon>mdi-delete</v-icon>
                  Removed
                </div>
                <div class="body">
                  <MedicationDetails v-for="item in stoppedMedications" :key="item.id" :item="item" />
                </div>
              </div>
            </template>
          </div>
        </v-expand-transition>
      </div>
    </div>

    <AddBloodPressureOrder />
    <AddTemperatureOrder />
    <AddOxygenSaturationOrder />
    <AddRespiratoryRateOrder />
    <AddHeartRateOrder />
    <AddBloodGlucoseOrder />
  </v-col>
</template>
<script>
import moment from "moment-timezone";
import { mapActions as mapPiniaActions } from "pinia";
import { mapActions, mapState } from "pinia";

import { EncountersAPI } from "@/api/encounters";
import { MedicationApi } from "@/api/medication";
import { PastVisitApi } from "@/api/pastVisitLog";
import AddBloodGlucoseOrder from "@/components/VisitNotes/bloodGlucoseOrder/Add";
import AddBloodPressureOrder from "@/components/VisitNotes/bloodPressureOrder/Add";
import AddHeartRateOrder from "@/components/VisitNotes/heartRateOrder/Add";
import ServiceRequestObservationDetails from "@/components/VisitNotes/medicationOrder/ServiceRequestObservationDetails";
import MedicationDetails from "@/components/VisitNotes/medicationReconsiliation/MedicationDetails";
import AddOxygenSaturationOrder from "@/components/VisitNotes/oxygenSaturationOrder/Add";
import AddRespiratoryRateOrder from "@/components/VisitNotes/respiratoryRateOrder/Add";
import AddTemperatureOrder from "@/components/VisitNotes/temperatureOrder/Add";
import { OBSERVATION_ORDERS_ANY_ADDED } from "@/eventBuses/monitoringEvents";
import { snackBarEventBus, snackBarEventName } from "@/eventBuses/snackBar.eventBus";
import { useAllergiesStore } from "@/pinia-store/allergies";
import { useAuthStore } from "@/pinia-store/auth";
import { useAutoSaveStore } from "@/pinia-store/autoSave";
import { useBloodGlucoseOrderStore } from "@/pinia-store/bodyGlucoseOrder";
import { useBloodPressureOrderStore } from "@/pinia-store/bodyPresureOrder";
import { useBodyTemperatureOrderStore } from "@/pinia-store/bodyTemperatureOrder";
import { useHeartRateOrderStore } from "@/pinia-store/heartRateOrder";
import { useOxygenSaturationOrderStore } from "@/pinia-store/oxygenStaturationOrder";
import { usePastVisitStore } from "@/pinia-store/pastVisit";
import { useRespiratoryRateOrderStore } from "@/pinia-store/respiratoryRateOrder";
import { useTerminologyStore } from "@/pinia-store/terminology";
import { useVisitNoteStore } from "@/pinia-store/visitNote";
import { EncounterStatusEnum } from "@/types/EncounterStatusEnum";
import { routesEnum } from "@/types/Routes.enum";
import { errToText } from "@/utils/conversion";

export default {
  name: "PractitionerEncounterOrder",
  components: {
    ServiceRequestObservationDetails,
    AddBloodGlucoseOrder,
    AddHeartRateOrder,
    AddRespiratoryRateOrder,
    AddOxygenSaturationOrder,
    AddTemperatureOrder,
    AddBloodPressureOrder,
    MedicationDetails,
  },
  data: () => ({
    slideMonitoringItems: false,
    direction: "top",
    fab: false,
    fling: false,
    hover: false,
    tabs: null,
    top: false,
    right: true,
    bottom: true,
    left: false,
    transition: "slide-y-reverse-transition",
    // end floating settings
    expanded: true,
    fileRecords: [],
    medicalTab: 0,
    uploadHeaders: { "X-Test-Header": "vue-file-agent" },
    fileRecordsForUpload: [],
    editableId: "",
  }),
  computed: {
    ...mapState(useVisitNoteStore, [
      "medicalInfo",
      "medicationStatement",
      "serviceRequestObservations",
      "serviceRequestObservationsActive",
      "serviceRequestObservationsActiveStatus",
      "serviceRequestObservationsDraftStatus",
      "patientInfo",
      "otherInfo",
      "encounterId",
      "encounterNotes",
      "encounter",
    ]),
    ...mapState(useTerminologyStore, ["practitionerFavMedication"]),
    ...mapState(useAuthStore, ["uid"]),
    ...mapState(usePastVisitStore, [
      "patientId",
      "chiefComplaint",
      "describeProblem",
      "dateStart",
      "dateEnd",
      "visitStart",
      "visitEnd",
      "getFile",
    ]),
    isPended() {
      return this.encounter.status === "onleave";
    },
    intendedMedications() {
      return this.medicationStatement.filter((i) => i.status === "intended");
    },
    stoppedMedications() {
      return this.medicationStatement.filter((i) => i.status === "stopped");
    },
    isSignDisabled() {
      return (
        ![EncounterStatusEnum.triaged, EncounterStatusEnum.onleave].includes(this.encounter?.status) ||
        !this.encounterNotes.chiefComplaint ||
        !this.encounterNotes.dx ||
        !this.encounterNotes.assessment ||
        !this.encounterNotes.plan ||
        !this.encounterNotes.followUp
      );
    },
    monitoringOrderOptions() {
      return [
        {
          text: "Blood pressure",
          handler: this.openBPressureOrderForm,
        },
        {
          text: "Blood glucose",
          handler: this.openBloodGlucoseOrderForm,
        },
        {
          text: "Temperature",
          handler: this.openBodyTemperatureOrderForm,
        },
        {
          text: "Oxygen Saturation",
          handler: this.openOxygenSaturationOrderForm,
        },
        {
          text: "Respiratory Rate",
          handler: this.openOxygenSaturationOrderForm,
        },
        {
          text: "Heart rate",
          handler: this.openHeartRateOrderForm,
        },
      ];
    },
  },
  watch: {
    watch: {
      // todo: change
      "$store.state.visitNote.patientId": {
        immediate: true,
        async handler(val) {
          if (val && val.length) {
            await this.getPatientAllergies();
          }
        },
      },
    },
    // todo: change

    "$store.state.visitNote.encounter": {
      immediate: true,
      handler(val) {
        if (val?.id) {
          this.getMedicationStatement();
          this.getServiceRequestObservations();
          this.getPharmacy();
        }
      },
    },
    fab: {
      immediate: true,
      handler(val) {
        if (!val) {
          this.slideMonitoringItems = false;
        }
      },
    },
  },
  methods: {
    ...mapActions(useVisitNoteStore, ["setMedicalInfoField", "setEncounter"]),
    ...mapActions(useRespiratoryRateOrderStore, { fillRespiratoryRateOrderForm: "fillForm" }),
    ...mapActions(useRespiratoryRateOrderStore, { toggleRespiratoryRateOrderFormOpened: "toggleFormOpened" }),
    ...mapActions(useBloodGlucoseOrderStore, { fillBloodGlucoseOrderForm: "fillForm" }),
    ...mapActions(useBloodGlucoseOrderStore, { toggleBloodGlucoseOrderFormOpened: "toggleFormOpened" }),
    ...mapActions(useHeartRateOrderStore, { fillHROrderForm: "fillForm" }),
    ...mapActions(useHeartRateOrderStore, { toggleHROrderFormOpened: "toggleFormOpened" }),
    ...mapActions(useBloodPressureOrderStore, { fillBPOrderForm: "fillForm" }),
    ...mapActions(useBloodPressureOrderStore, { toggleBPOrderFormOpened: "toggleFormOpened" }),
    ...mapActions(useBodyTemperatureOrderStore, { fillBodyTemperatureOrderForm: "fillForm" }),
    ...mapActions(useBodyTemperatureOrderStore, { toggleBodyTemperatureOrderFormOpened: "toggleFormOpened" }),
    ...mapActions(useOxygenSaturationOrderStore, { fillOxygenSaturationOrderForm: "fillForm" }),
    ...mapActions(useOxygenSaturationOrderStore, { toggleOxygenSaturationOrderFormOpened: "toggleFormOpened" }),
    ...mapActions(useVisitNoteStore, [
      "clearMedicalInfoFields",
      "autoSave",
      "getMedicationStatement",
      "getPharmacy",
      "getServiceRequestObservations",
      "saveVisitNote",
    ]),
    ...mapActions(useAutoSaveStore, ["startSave", "endSave"]),
    ...mapPiniaActions(useAllergiesStore, ["getPatientAllergies"]),
    ...mapActions(useTerminologyStore, ["getPractitionerFavMedication"]),
    ...mapActions(usePastVisitStore, ["setFile", "setStep"]),
    ...mapActions(useVisitNoteStore, ["togglePreviewSignNote"]),
    openMonitoringMenu(e) {
      e.preventDefault();
      e.stopPropagation();
      this.slideMonitoringItems = !this.slideMonitoringItems;
    },
    onPreviewSignNote() {
      this.togglePreviewSignNote();
    },
    async onPendSignNote() {
      this.startSave();
      const encounter = await EncountersAPI.setPended(this.encounterId);
      this.setEncounter(encounter);
      this.endSave();
    },
    async onSaveDraft() {
      this.startSave();
      await this.saveVisitNote();
      this.endSave();
    },
    openBPressureOrderForm() {
      this.fillBPOrderForm({ encounterId: this.encounterId, practitionerId: this.practitionerId });
      this.toggleBPOrderFormOpened();
    },
    openBloodGlucoseOrderForm() {
      this.fillBloodGlucoseOrderForm({ encounterId: this.encounterId, practitionerId: this.practitionerId });
      this.toggleBloodGlucoseOrderFormOpened();
    },
    openHeartRateOrderForm() {
      this.fillHROrderForm({ encounterId: this.encounterId, practitionerId: this.practitionerId });
      this.toggleHROrderFormOpened();
    },
    openBodyTemperatureOrderForm() {
      this.fillBodyTemperatureOrderForm({ encounterId: this.encounterId, practitionerId: this.practitionerId });
      this.toggleBodyTemperatureOrderFormOpened();
    },

    openOxygenSaturationOrderForm() {
      this.fillOxygenSaturationOrderForm({ encounterId: this.encounterId, practitionerId: this.practitionerId });
      this.toggleOxygenSaturationOrderFormOpened();
    },
    async onSignNote() {
      this.startSave();
      try {
        if (this.$route.name === routesEnum.practitionerAddPastVisit) {
          const data = {
            encounterId: this.encounterId,
            patientId: this.patientInfo.id,
            practitionerId: this.uid,
            chiefComplaint: this.chiefComplaint,
            start: this.visitStart,
            end: this.visitEnd,
            encounterNotes: {
              ...this.encounterNotes,
            },
            requestInformation: {
              visitDate: moment(this.visitStart).format("YYYY-MM-DD"),
              chiefComplaint: this.chiefComplaint,
              generalInformation: {
                height: this.height,
                weight: this.weight,
                isSmoking: this.isSmoking,
                allergies: this.allergies,
                document: [],
              },
            },
            patientInformation: {
              ...this.patientInfo,
            },
          };
          await PastVisitApi.createPastVisit(data);
          this.setStep(4);
        } else {
          await EncountersAPI.signDocument(this.encounterId);
          await this.$router.push({ name: routesEnum.practitionerVisitNote });
        }
      } catch (err) {
        console.error(err);
        snackBarEventBus.$emit(snackBarEventName, {
          message: errToText(err),
          type: "error",
        });
      }
      this.endSave();
    },
    async onChangeStatus(status, medicationId) {
      this.startSave();
      await MedicationApi.patch(medicationId, { status });
      await this.getMedicationStatement();
      this.endSave();
    },
  },
  beforeDestroy() {
    this.$root.$off(OBSERVATION_ORDERS_ANY_ADDED, this.getServiceRequestObservations);
  },
  async mounted() {
    this.$root.$on(OBSERVATION_ORDERS_ANY_ADDED, this.getServiceRequestObservations);
    await this.getPatientAllergies();
    await this.getPractitionerFavMedication(this.uid);
  },
  created() {
    window.addEventListener("scroll", () => {
      if (this.expanded) this.expanded = false;
    });
  },
};
</script>
<style lang="scss" scoped>
:deep .fab-text-custom {
  position: absolute;
  width: max-content;
  text-transform: none;
  transform: translate(-100%, 0);
  right: auto;
  left: -20px;
  font-size: 16px;
  line-height: 19px;
  font-style: normal;
  font-weight: 400;
  color: #91909c;
}

:deep .v-speed-dial--bottom {
  bottom: 0;
}

.right-box-wrap {
  .add-btn {
    text-transform: none !important;

    :deep .v-btn__content {
      font-size: 12px !important;
    }
  }
}

.order-menu {
  .v-list-item--link {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    transition: 0.2s;

    &:hover {
      color: #2fcf6f;
      background-color: #edfbf4;
    }

    mix-blend-mode: normal;
  }
}

.visit-notes_order {
  padding: 0 0 12px 12px;
  position: sticky;
  align-self: flex-end;
  align-items: center;
  bottom: 0;
  z-index: 4;
  display: flex;
  width: 100%;
  background-color: white;
  justify-content: space-between;
  border: 1px solid #e7e8f2;
  box-shadow: 0px -1px 4px rgba(0, 0, 0, 0.06);

  .wrap-body {
    overflow: auto;
    max-height: 80vh;

    .order-header {
      color: rgba(51, 52, 62, 0.4);
      padding: 14px 20px;
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
    }
  }

  .add-btn {
    background-color: #2fcf6f !important;
    color: white;
  }

  .fav-btn {
    background-color: $primarylight1 !important;
    color: #2196f3;
  }

  .draft-btn {
    background-color: #2fcf6f !important;
    color: #2196f3;
  }

  .pend-btn {
    background: rgba(242, 153, 74, 0.2) !important;
    color: #f2994a;
    opacity: 1 !important;
  }

  .sign-btn {
    margin-left: auto;
    background-color: #2fcf6f !important;

    i {
      color: white;
    }

    color: white;
  }

  .leftBox {
    width: 66%;

    button {
      text-transform: none;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 15px;
    }

    gap: 16px;
    display: flex;
    padding: 20px 26px;
  }

  .right-box-wrap {
    width: 33.333%;
    padding: 20px 14px;
    display: flex;
    align-items: center;
    flex-direction: row;
    position: relative;
    height: 100px;
    gap: 6px;
    justify-content: space-between;

    &.full-width {
      width: 100% !important;
    }

    .v-chip {
      color: white;
    }

    .v-btn--icon {
      &:before {
        background-color: transparent;
      }
    }

    .right-window {
      position: absolute;
      transform: translateY(-100px);
      bottom: 0;
      right: 0;
      min-width: 350px;
      max-height: calc(100vh - 100px);
      width: calc(100% - 20px);
      margin: 0 10px;
      //border: 1px solid #e7e8f2;
      //box-shadow: 0px -4px 26px rgba(0, 0, 0, 0.02), 0px -1px 6px rgba(0, 0, 0, 0.06);

      &.mobile {
        margin: 0;
        width: 100%;
      }

      .main-title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: row;
      }
    }
  }

  .right-box {
    display: flex;
    width: 100%;
    flex-direction: column;

    .body {
      > div {
        padding: 12px 20px;
        border-bottom: 1px solid #e7e8f2;
      }
    }

    .order-title {
      height: 48px;
      width: 100%;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      padding: 12px 20px;
      color: #25233a;
      background: #ffffff;
      border-top: 1px solid #e7e8f2;
      border-bottom: 1px solid #e7e8f2;
      position: sticky;
      top: 0;
      z-index: 1;

      i {
        height: 12px;
        font-size: 12px;
      }
    }

    &.intended {
      .body {
        border-left: 2px solid #2fcf6f !important;
      }

      .order-title {
        background: #d4f5e1;
        color: #2fcf6f;

        i {
          color: #2fcf6f;
        }
      }
    }

    &.active {
      .body {
        border-left: 2px solid #2296f3 !important;
      }

      .order-title {
        background: #fff;
        color: #2296f3;

        i {
          color: #2296f3;
        }
      }
    }

    &.stopped {
      .body {
        border-left: 2px solid #e53935 !important;
      }

      .order-title {
        background: rgba(229, 57, 53, 0.21);
        color: #e53935;

        i {
          color: #e53935;
        }
      }
    }
  }

  &.mobile {
    flex-direction: column-reverse;
    max-width: calc(100% - 6px);

    .divider {
      width: 100%;
    }

    .leftBox {
      width: 100%;
      padding-left: 20px;
      padding-right: 20px;
      flex-wrap: wrap;
      justify-content: center;

      .sign-btn {
        margin-left: inherit;
      }
    }

    .right-box-wrap {
      .text-h6 {
        font-size: 12px;
      }

      .right-window {
        transform: translateY(-75px);

        .wrap-body {
          max-height: calc(100vh - 175px);
        }
      }

      padding-left: 20px;
      padding-right: 20px;
      height: inherit;
      width: 100%;
    }
  }
}
</style>
