<template>
  <div :class="{ 'ps_container--mobile': $vuetify.breakpoint.smAndDown }" class="ps_container">
    <div class="ps_header">
      <span class="heading-2">{{ $t("settings.profile.title") }}</span>
      <PrimaryButton :loading="buttonLoading" :text="$t('settings.profile.save')" @onClick="saveChanges" />
    </div>
    <v-divider class="ps_divider"></v-divider>
    <v-card outlined>
      <v-progress-linear v-if="loading" color="primary" indeterminate />
      <UploadHeader />
      <div class="ps_details personalDetails">
        <span class="heading-4 colorBlack">{{ $t("settings.profile.personalDetails") }}</span>
        <v-row class="ps_row">
          <v-col v-if="isTestingUser" class="ps_input-group d-flex flex-row align-center" cols="12">
            <span class="d-flex align-center text-capitalize">
              <v-icon color="red" small>mdi-information-outline</v-icon>
              &nbsp;Testing account
            </span>
          </v-col>
          <v-col class="ps_input-group" cols="12" md="4">
            <span>
              {{ $t("general.inputs.firstName") }}
              <span class="p-schedule-visit__content__required">*</span>
            </span>
            <BaseInput
              v-model="firstName"
              :errors="firstNameErrors"
              class="marginTop12"
              fieldName="firstName"
              @blur="$v.firstName.$touch()"
              @focus="onInputFocus('firstName')"
            />
          </v-col>
          <v-col class="ps_input-group" cols="12" md="4">
            <span>{{ $t("general.inputs.lastName") }} <span class="p-schedule-visit__content__required">*</span></span>
            <BaseInput
              v-model="lastName"
              :errors="lastNameErrors"
              class="marginTop12"
              fieldName="lastName"
              @blur="$v.lastName.$touch()"
              @focus="onInputFocus('lastName')"
            />
          </v-col>
          <v-col class="ps_input-group" cols="12" md="4">
            <span>Credentials <span class="p-schedule-visit__content__required">*</span></span>
            <BaseInput
              v-model="qualification"
              class="marginTop12"
              fieldName="Medical"
              @blur="$v.qualification.$touch()"
              @focus="onInputFocus('qualification')"
            />
          </v-col>
        </v-row>
      </div>
      <div class="ps_details">
        <span class="heading-4">{{ $t("settings.profile.accountDetails") }}</span>
        <v-row class="ps_row">
          <v-col class="ps_input-group" cols="12" md="4">
            <span>{{ $t("general.inputs.email") }}</span>
            <BaseInput
              v-model="email"
              :disabled="true"
              :errors="emailErrors"
              class="marginTop12 selectStyleDisable"
              fieldName="email"
              @blur="$v.email.$touch()"
            />
          </v-col>

          <v-col class="ps_input-group" cols="12" md="4">
            <span>Dosespot ID</span>
            <BaseInput v-model="dosespotId" class="marginTop12" fieldName="dosespotId" />
          </v-col>
          <v-col class="ps_input-group" cols="12" md="4">
            <span>Timezone</span>
            <Select
              v-model="timeZone"
              :disabled="isAutoTimeZone"
              :items="timezoneOptions"
              class="marginTop12 selectStyleDisable field-details-absolute"
            />
            <Checkbox
              :checked="isAutoTimeZone === true"
              class="marginTop12"
              text="Set time zone automatically"
              @click="onChangeAutoTimezone(!isAutoTimeZone)"
            />
          </v-col>
        </v-row>
      </div>
      <ChangePassword class="changePassword" />
      <div class="ps_details">
        <span class="heading-4">Visit Settings</span>
        <v-row class="ps_row">
          <v-col class="ps_input-group" cols="12" lg="4" md="8">
            <span>Appointment Confirmation</span>
            <v-radio-group v-model="isAutoAccept" :hide-details="true" class="marginTop12 pt-0" row>
              <v-radio :value="true" label="Automatic"></v-radio>
              <v-radio :value="false" label="Manually"></v-radio>
            </v-radio-group>
          </v-col>
          <v-col class="ps_input-group" cols="12" md="4">
            <span>Is available label to other doctors' patients</span>
            <Checkbox
              :checked="availableToOtherPatients === true"
              class="marginTop12"
              text="Available"
              @click="onChangeAvailableToOtherPatients(!availableToOtherPatients)"
            />
          </v-col>
        </v-row>
      </div>
      <div class="ps_details">
        <span class="heading-4">Clinical requests</span>
        <v-row class="ps_row">
          <v-col class="ps_input-group col-12 col-lg-4 col-sm-6 d-flex flex-row align-center">
            <Checkbox
              :checked="acceptTasks === true"
              text="Accepting new requests"
              @click="acceptTasks = !acceptTasks"
            />
          </v-col>
          <v-col class="ps_input-group col-lg-4 col-sm-6 col-12 d-flex flex-row align-center">
            <Checkbox
              :checked="acceptPediatricTasks === true"
              text="Accepting pediatric patient queue"
              @click="acceptPediatricTasks = !acceptPediatricTasks"
            />
          </v-col>
          <v-col class="ps_input-group col-lg-4 col-sm-6 col-12 d-flex flex-row align-center">
            <Checkbox
              :checked="acceptAdultTasks === true"
              text="Accepting adult patient queue"
              @click="acceptAdultTasks = !acceptAdultTasks"
            />
          </v-col>
        </v-row>
      </div>
      <div class="ps_details">
        <v-row class="ps_row">
          <v-col
            :class="{ 'flex-column align-baseline': $vuetify.breakpoint.xsOnly }"
            class="ps_input-group col-lg-6 col-12 d-flex flex-row align-center"
          >
            <span class="d-flex align-center text-capitalize">
              <v-icon color="blue" small>mdi-information-outline</v-icon>
              &nbsp;Visit Can’t Be Canceled Earlier Than</span
            >
            <span>
              &nbsp;-&nbsp;
              <b>{{ scheduleToCancelLimit || "" }}</b>
            </span>
          </v-col>
          <v-col
            :class="{ 'flex-column align-baseline': $vuetify.breakpoint.xsOnly }"
            class="ps_input-group col-lg-6 col-12 d-flex flex-row align-center"
          >
            <span class="d-flex align-center text-capitalize">
              <v-icon color="blue" small>mdi-information-outline</v-icon>
              &nbsp;Patient can request no later than</span
            >
            <span>
              &nbsp;-&nbsp; <b>{{ scheduleToStartLimit || "" }}</b>
            </span>
          </v-col>
          <v-col
            :class="{ 'flex-column align-baseline': $vuetify.breakpoint.xsOnly }"
            class="ps_input-group col-lg-6 col-12 d-flex flex-row align-center"
          >
            <span class="d-flex align-center text-capitalize">
              <v-icon color="blue" small>mdi-information-outline</v-icon>
              &nbsp;Visit Duration</span
            >
            <span
              >&nbsp;-&nbsp; <b>{{ slotDuration || "" }}</b></span
            >
          </v-col>
          <v-col
            :class="{ 'flex-column align-baseline': $vuetify.breakpoint.xsOnly }"
            class="ps_input-group col-lg-6 col-12 d-flex flex-row align-center"
          >
            <span class="d-flex align-center text-capitalize">
              <v-icon color="blue" small>mdi-information-outline</v-icon>
              &nbsp;How far in to the future visit schedules can be?</span
            >
            <span>
              &nbsp;-&nbsp; <b>{{ scheduleHorizonLimit || "" }} days</b>
            </span>
          </v-col>
        </v-row>
      </div>
    </v-card>
  </div>
</template>
<script>
import moment from "moment-timezone";
import { mapActions, mapState } from "pinia";
import { email, required } from "vuelidate/lib/validators";

import { AdminAPI } from "@/api/admin";
import ChangePassword from "@/components/settings/changePassword";
import UploadHeader from "@/components/settings/uploadHeader";
import BaseInput from "@/components/uikit/BaseInput";
import Checkbox from "@/components/uikit/Checkbox";
import PrimaryButton from "@/components/uikit/PrimaryButton";
import Select from "@/components/uikit/Select";
import { snackBarEventBus, snackBarEventName } from "@/eventBuses/snackBar.eventBus";
import { useProfileSettingsStore } from "@/pinia-store/profileSettings";
import { CLINICAL_ROLES } from "@/utils/clinicalRoles";
import { defaultLocale } from "@/utils/constants";

export default {
  name: "PractitionerSettings",
  components: {
    Checkbox,
    PrimaryButton,
    UploadHeader,
    BaseInput,
    Select,
    ChangePassword,
  },
  validations: {
    email: { required, email },
    firstName: { required },
    lastName: { required },
  },
  data: () => ({
    visitSettings: {},
    dosespotId: "",
    loading: false,
    minutes: "",
    practitionerOnDuty: "",
    buttonLoading: false,
    firstName: "",
    lastName: "",
    locale: defaultLocale,
    buttonSelect: true,
    isAutoTimeZone: true,
    isAutoAccept: true,
    acceptPediatricTasks: true,
    acceptAdultTasks: true,
    acceptTasks: true,
    email: "",
    timeZone: "",
    clinicalRole: "",
    qualification: "",
    availableToOtherPatients: "",
    clinicalRoles: CLINICAL_ROLES.map((role) => ({ text: role, value: role })),
    focusedField: null,
    isTestingUser: false,
  }),
  computed: {
    ...mapState(useProfileSettingsStore, ["practitionerSettings", "avatar"]),
    timezoneOptions() {
      return this.$moment.tz.names().map((i) => ({ text: i, value: i }));
    },

    scheduleToStartLimit() {
      let time = moment(this.visitSettings.scheduleToStartLimit || "00:30", "HH:mm");
      return moment.duration({ hour: time.hour(), minute: time.minute() }).humanize();
    },
    scheduleToCancelLimit() {
      let time = moment(this.visitSettings.scheduleToCancelLimit || "00:05", "HH:mm");
      return moment.duration({ hour: time.hour(), minute: time.minute() }).humanize();
    },
    slotDuration() {
      let time = moment(this.visitSettings.slotDuration || "00:15", "HH:mm");
      return moment.duration({ hour: time.hour(), minute: time.minute() }).humanize();
    },
    scheduleHorizonLimit() {
      return this.visitSettings.scheduleHorizonLimit || 30;
    },
    emailErrors() {
      const errors = [];
      if (!this.$v.email.$dirty) return errors;
      !this.$v.email.email && errors.push(this.$t("validation.email"));
      !this.$v.email.required &&
        errors.push(this.$t("validation.required", { field: this.$t("general.inputs.email") }));
      return errors;
    },
    firstNameErrors() {
      const errors = [];
      if (!this.$v.firstName.$dirty) return errors;
      !this.$v.firstName.required &&
        errors.push(this.$t("validation.required", { field: this.$t("general.inputs.firstName") }));
      return errors;
    },
    lastNameErrors() {
      const errors = [];
      if (!this.$v.lastName.$dirty) return errors;
      !this.$v.lastName.required &&
        errors.push(this.$t("validation.required", { field: this.$t("general.inputs.lastName") }));
      return errors;
    },

    workDays: {
      get() {
        const days = moment.weekdays(true);
        const settingWorkingDays = this.practitionerSettings?.workDays || [];

        return settingWorkingDays.map((indexDay) => days[indexDay - 1]) || [];
      },
      set(values) {
        const days = moment.weekdays(true);
        let settWorkingDays = [];
        if (this.practitionerSettings?.workDays || [])
          settWorkingDays = values.map((day) => {
            return days.indexOf(day) + 1;
          });
        this.setKeyPractitionerProfile({ fieldName: "workDays", value: settWorkingDays });
      },
    },
  },
  methods: {
    ...mapActions(useProfileSettingsStore, ["getPractitionerSettings", "savePractitionerChanges"]),
    ...mapActions(useProfileSettingsStore, ["setKeyPractitionerProfile"]),
    onInputFocus(field) {
      this.focusedField = field;
      if (this[`${field}Model`] === "") {
        this[`${field}Model`] = this.practitionerSettings[field];
      }
    },
    onChangeAutoTimezone(value) {
      if (value) {
        this.timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      }
      this.isAutoTimeZone = value;
    },
    onChangeAvailableToOtherPatients(value) {
      this.availableToOtherPatients = value;
    },
    async saveChanges() {
      this.buttonLoading = true;
      try {
        await this.savePractitionerChanges({
          acceptTasks: this.acceptTasks,
          acceptPediatricTasks: this.acceptPediatricTasks,
          acceptAdultTasks: this.acceptAdultTasks,
          dosespotId: this.dosespotId,
          firstName: this.firstName,
          lastName: this.lastName,
          email: this.email,
          locale: this.locale,
          timeZone: this.timeZone,
          isAutoTimeZone: this.isAutoTimeZone,
          isAutoAccept: this.isAutoAccept,
          clinicalRole: this.clinicalRole,
          qualification: this.qualification,
          workDays: this.workDays || [],
          availableToOtherPatients: this.availableToOtherPatients,
        });
        snackBarEventBus.$emit(snackBarEventName, {
          message: this.$t("settings.success"),
          type: "success",
        });
        this.$root.$i18n.locale = this.locale;
      } catch (err) {
        console.error(err);
        snackBarEventBus.$emit(snackBarEventName, {
          message: (err && err.response && err.response.data && err.response.data.message) || err,
          type: "error",
        });
      } finally {
        this.buttonLoading = false;
      }
    },
    fillInfo() {
      this.email = this.practitionerSettings.email;
      this.firstName = this.practitionerSettings.firstName;
      this.lastName = this.practitionerSettings.lastName;
      this.clinicalRole = this.practitionerSettings.clinicalRole || "";
      this.locale = this.practitionerSettings.locale || "en";
      this.qualification = this.practitionerSettings?.qualification || "";
      this.acceptTasks = this.practitionerSettings?.acceptTasks || "";
      this.acceptPediatricTasks = this.practitionerSettings?.acceptPediatricTasks || "";
      this.acceptAdultTasks = this.practitionerSettings?.acceptAdultTasks || "";
      this.isAutoAccept = Boolean(this.practitionerSettings?.isAutoAccept);
      this.isAutoTimeZone = Boolean(this.practitionerSettings?.isAutoTimeZone);
      this.timeZone = this.practitionerSettings.timeZone || Intl.DateTimeFormat().resolvedOptions().timeZone;
      this.dosespotId = this.practitionerSettings.dosespotId;
      this.availableToOtherPatients = Boolean(this.practitionerSettings?.availableToOtherPatients);
      this.isTestingUser = Boolean(this.practitionerSettings?.isTestingUser);
    },
  },
  async mounted() {
    this.loading = true;
    this.fillInfo();
    await this.getPractitionerSettings();
    this.fillInfo();
    this.visitSettings = await AdminAPI.getVisitSettings();

    this.loading = false;
  },
};
</script>
<style lang="scss">
.ps_container--mobile {
  padding: 16px;
}

.fontSize14 {
  font-size: 14px !important;
}

.textStyle {
  font-style: normal;
}

.colorBlack {
  color: #33343e;
}

.marginTop12 {
  margin-top: 12px !important;
}

.paddingTop14 {
  padding-top: 14px;
}

.marginTop14 {
  margin-top: 14px !important;
}

.personalDetails {
  padding: 23px 26px 36px 26px !important;
}

.ps_input-group {
  //padding-top: 14px !important;
  .v-input {
    .v-input__control .v-input__slot {
      margin-top: 0px !important;
    }
  }
}

.heading-4 {
  font-weight: 500 !important;
}

.changePassword {
  min-width: 100%;
}

.buttonSelectContainer {
  .buttonSelectActive {
    background: #eff6ff !important;
    border: 1px solid #2196f3;
    border-radius: 4px;
    box-shadow: none !important;

    .v-btn__content {
      text-transform: capitalize;
      font-style: normal;
      font-weight: 500;
      font-size: 14px !important;
      color: #2196f3 !important;
    }
  }

  .buttonSelect {
    border: 1px solid #eeeff7;
    border-radius: 4px;
    box-shadow: none !important;
    background: transparent !important;

    .v-btn__content {
      font-style: normal;
      text-transform: capitalize;
      font-weight: normal;
      font-size: 14px !important;
      color: #91909c !important;
    }
  }
}

.v-input .v-input__slot {
  margin: 0px !important;
}

.v-input {
  flex-direction: column;

  .v-input__control {
    justify-content: flex-end;
  }
}

.ps_btn-wrapper {
  padding-bottom: 0px !important;
  place-self: self-end;
}

.ps_input-group {
  padding-top: 24px;
}

.ps_details {
  padding: 24px 26px 36px 26px !important;
}

.p-schedule-visit__content__required {
  color: var(--v-error-base) !important;
}

.selectStyleDisable {
  background: rgba(238, 239, 247, 0.36);
  /* Light Gray - 2 */
  .v-label {
    border: 1px solid #eeeff7;
    border-radius: 4px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    mix-blend-mode: normal;
    opacity: 0.4;
  }
}

.inputStyle {
  font-weight: 500;
  font-size: 14px;
  color: #33343e;
}

.selectStyle {
  .v-label {
    font-weight: 500 !important;
    font-size: 14px;
    color: #33343e !important;
    mix-blend-mode: normal;
  }
}

.patient {
  &__allergy-wrap {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    margin-bottom: 20px;
  }

  &__allergy-chip {
    width: fit-content;
    display: flex;
    font-weight: 600;
    font-size: 14px;
    align-self: center;

    &.mobile-size {
      display: inline-flex;
    }

    &:hover {
      cursor: pointer;
    }

    i {
      margin-left: 10px;
      color: rgba(0, 0, 0, 0.87);

      &:before {
        color: rgba(0, 0, 0, 0.87);
      }
    }
  }
}

@media only screen and (max-width: 680px) {
  .buttonSelect {
    max-width: 100px;
  }
  .ps_input-group {
    padding-top: 19px !important;
  }
  .buttonSelectActive {
    max-width: 100px;
  }
}
</style>
