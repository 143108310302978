<template>
  <div class="d-flex chat-message-row">
    <template>
      <div class="full-width d-inline-flex flex-column border-radius-inherit">
        <div class="border-radius-inherit full-width d-inline-flex justify-start mb-1">
          <span class="message-head">{{ displayName }}, {{ getDate() }} </span>
        </div>
        <div class="full-width d-inline-flex justify-start border-radius-inherit">
          <div
            class="message-participant border-radius-inherit"
            v-bind:class="{
              bot: true,
              'message-participant-tail': true,
              'message-participant-last': true,
            }"
          >
            <section class="faq">
              <div class="faq__left">
                <h1 class="faq__left__title">{{ $t("landing.patients.faq.title") }}</h1>
                <p class="faq__left__text">{{ $t("landing.patients.faq.text") }}</p>
              </div>
              <div class="faq__accordion">
                <v-expansion-panels>
                  <v-expansion-panel v-for="item in faqItems" :key="item.question" class="faq__accordion__item">
                    <v-expansion-panel-header class="faq__accordion__question">{{
                      item.question
                    }}</v-expansion-panel-header>
                    <v-expansion-panel-content class="faq__accordion__answer">
                      {{ item.answer }}
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </div>
            </section>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { DateTime } from "luxon";

export default {
  name: "FloatingFaqMessageRow",
  props: {
    isFloatingChat: Boolean,
  },
  data() {
    return {
      faqItems: [
        {
          question: this.$t("landing.patients.faq.question1"),
          answer: this.$t("landing.patients.faq.answer1"),
        },
        {
          question: this.$t("landing.patients.faq.question2"),
          answer: this.$t("landing.patients.faq.answer2"),
        },
        {
          question: this.$t("landing.patients.faq.question3"),
          answer: this.$t("landing.patients.faq.answer3"),
        },
        {
          question: this.$t("landing.patients.faq.question4"),
          answer:
            " Yes, we are able to prescribe a wide range of medications tradionally given by your primary care physician, urgent care or emergency department. We do not prescribe or refill any type of controlled substances such as narcotics or lifestyle medications (eg., weight loss, erectile disfunction). ",
        },
        {
          question: this.$t("landing.patients.faq.question5"),
          answer: this.$t("landing.patients.faq.answer5"),
        },
        {
          question: this.$t("landing.patients.faq.question6"),
          answer: this.$t("landing.patients.faq.answer6"),
        },
        {
          question: this.$t("landing.patients.faq.question7"),
          answer: this.$t("landing.patients.faq.answer7"),
        },
        {
          question: this.$t("landing.patients.faq.question8"),
          answer: this.$t("landing.patients.faq.answer8"),
        },
        {
          question: this.$t("landing.patients.faq.question9"),
          answer: this.$t("landing.patients.faq.answer9"),
        },
      ],
    };
  },
  methods: {
    getDate() {
      return DateTime.fromJSDate(new Date(Date.now())).toFormat("t");
    },
  },
  computed: {
    displayName() {
      const displayName = this.message?.authorMeta?.displayName;
      return displayName ? displayName : "Axis HealthBot";
    },
  },
};
</script>

<style scoped lang="scss">
.faq {
  padding: 16px 8px;
  display: flex;
  flex-flow: column nowrap;
  position: relative;

  &__accordion {
    max-width: 670px;

    &__item {
      background-color: transparent !important;
      border-bottom: 1px solid $primaryblack2;

      &:before,
      &:after {
        display: none;
      }
    }

    &__question {
      font-size: 14px;
      font-weight: bold;
      padding: 4px;
    }
  }
}
.message {
  &-head {
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;
    color: #bec5cc;
  }

  &-my {
    background: #dbf4fe;

    &-head {
      border-radius: 10px 10px 0 10px;
    }

    &-tail {
      border-radius: 10px 0 0 10px;
    }
  }

  &-participant {
    background: #f2f6f9;

    &-last {
      border-radius: 0 10px 10px 10px;
    }

    &-tail {
      border-radius: 0 10px 10px 0;
    }
  }

  &-system {
    background: white;

    min-height: 30px;
    margin: 17px auto;
    border: 1px solid #e5e4e8;
    box-sizing: border-box;
    border-radius: 100px;

    &-body {
      padding: 5px 16px 5px 16px;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 20px;
      letter-spacing: 0.25px;
      color: #33343e;
      opacity: 0.66;
      text-align: center;
    }
  }

  &-avatar-placeholder {
    width: 18px;
    margin-right: 8px;
  }
}

.chat-message-row {
  margin-top: 8px;
  width: 100%;
}
</style>
