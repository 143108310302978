<template>
  <Layout :navTabs="navTabs" :quickActions="quickActions" role="registrar">
    <router-view></router-view>
  </Layout>
</template>

<script>
import { mapActions, mapState } from "pinia";

import Layout from "@/components/Layout";
import { useProfileSettingsStore } from "@/pinia-store/profileSettings";

export default {
  name: "registrarView",
  components: {
    Layout,
  },
  computed: {
    ...mapState(useProfileSettingsStore, ["registrarSettings", "avatar"]),

    navTabs() {
      return [
        {
          id: "visitCenter",
          title: "Clinic Schedule",
          icon: "sidebar/calendar.svg",
          to: "/registrar/appointments",
        },
        {
          id: "clinician",
          title: this.$t("patients.title"),
          icon: "sidebar/user.svg",
          to: "/registrar/clinician",
        },
        {
          id: "messages",
          title: this.$t("general.sidebar.messages"),
          icon: "sidebar/messages.svg",
          to: "/registrar/chat",
        },
      ];
    },
    quickActions() {
      return [
        {
          id: "scheduleVisit",
          title: this.$t("general.sidebar.scheduleVisit"),
          icon: "sidebar/visit.svg",
          to: "/registrar/encounters/schedule",
        },
        {
          id: "invitePatient",
          title: this.$t("general.sidebar.invitePatient"),
          icon: "sidebar/user-plus.svg",
          action: "toggleInviteDialog",
        },
        {
          id: "generateCode",
          title: "Generate code",
          icon: "sidebar/key-plus.svg",
          action: "toggleGenerateCode",
        },
        {
          id: "addAttachment",
          title: "Add an Attachment",
          icon: "sidebar/plusBox.svg",
          action: "toggleMediaDialog",
        },
      ];
    },
  },
  methods: {
    ...mapActions(useProfileSettingsStore, ["getRegistrarSettings"]),
  },
  async mounted() {
    await this.getRegistrarSettings();
    if (this.registrarSettings.locale) {
      this.$root.$i18n.locale = this.registrarSettings.locale;
    }
  },
};
</script>
