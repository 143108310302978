<template>
  <div class="appointment-more-info">
    <template v-if="loading">
      <v-progress-linear color="primary" indeterminate></v-progress-linear>
      <v-skeleton-loader
        v-if="loading"
        :boilerplatstartTimee="true"
        class="mx-0 full-width"
        elevation="2"
        type="list-item-three-line, list-item-three-line"
      ></v-skeleton-loader>
    </template>

    <template v-else>
      <PatientSummaryContactInfo :item="patientInfo" />

      <v-card class="elevation-0 general-medical-info" outlined>
        <v-card-title class="card-title px-5 pt-5"> General Medical Information</v-card-title>
        <v-divider></v-divider>
        <v-row class="px-5">
          <v-col cols="12" md="4">
            <ActionFieldMultiple
              :show-add="false"
              :unit="bodyHeight && bodyHeight[0] && bodyHeight[0].unit"
              :value="bodyHeightInfo"
              title="Height"
              @onView="onViewBodyHeight"
            />
          </v-col>
          <v-col cols="12" md="4">
            <ActionFieldMultiple
              :show-add="false"
              :unit="bodyWeight && bodyWeight[0] && bodyWeight[0].unit"
              :value="bodyWeightInfo"
              title="Weight"
              @onView="onViewBodyWeight"
            />
          </v-col>
          <v-col cols="12" md="4">
            <ActionFieldMultiple
              :show-add="false"
              :value="smokingBehaviorInfo"
              title="Smoking Status"
              @onView="onViewSmokingBehavior"
            />
          </v-col>
          <v-col cols="12" md="4">
            <ActionFieldMultiple
              :show-add="false"
              :unit="bloodPressure && bloodPressure[0] && bloodPressure[0].unit"
              :value="bloodPressureInfo"
              title="Blood Pressure"
              @onView="onViewSmokingBehavior"
            />
          </v-col>
          <v-col cols="12" md="4">
            <ActionFieldMultiple
              :show-add="false"
              :unit="heartRate && heartRate[0] && heartRate[0].unit"
              :value="heartRateInfo"
              title="Heart Rate"
              @onView="onViewSmokingBehavior"
            />
          </v-col>
          <v-col cols="12" md="4">
            <ActionFieldMultiple
              :show-add="false"
              :unit="heartRate && heartRate[0] && heartRate[0].unit"
              :value="bodyTemperatureInfo"
              title="Temperature"
              @onView="onViewSmokingBehavior"
            />
          </v-col>
          <v-col cols="12" md="4">
            <ActionFieldMultiple
              :show-add="false"
              :unit="bloodGlucose && bloodGlucose[0] && bloodGlucose[0].unit"
              :value="bloodGlucoseInfo"
              title="Blood Glucose"
              @onView="onViewSmokingBehavior"
            />
          </v-col>

          <v-col cols="12" md="4">
            <ActionFieldMultiple
              :show-add="false"
              :unit="respiratoryRate && respiratoryRate[0] && respiratoryRate[0].unit"
              :value="respiratoryRateInfo"
              title="Respiratory Rate"
              @onView="onViewSmokingBehavior"
            />
          </v-col>
          <v-col cols="12" md="4">
            <ActionFieldMultiple
              :show-add="false"
              :unit="oxygenSaturation && oxygenSaturation[0] && oxygenSaturation[0].unit"
              :value="oxygenSaturationInfo"
              title="Oxygen Saturation"
              @onView="onViewSmokingBehavior"
            />
          </v-col>
        </v-row>
        <DrawerBodyWeight />
        <DrawerBodyHeight />
        <DrawerSmokingBehavior />
      </v-card>
      <PatientSummaryPharmacies />
      <v-card class="elevation-0 general-medical-info" outlined>
        <v-card-title class="card-title px-5 pt-5"> Allergies</v-card-title>
        <v-divider></v-divider>
        <v-row class="px-5">
          <v-col class="mt-5" cols="12">
            <PatientAllergiesView :editable="false" />
          </v-col>
        </v-row>
      </v-card>
      <v-card class="elevation-0 general-medical-info" outlined>
        <v-card-title class="card-title px-5 pt-5"> Medications</v-card-title>
        <v-divider></v-divider>
        <v-row class="px-5">
          <v-col v-for="item in patientMedications" :key="item.id" class="mt-5" cols="12" md="4">
            <p class="name">{{ item.name }}</p>
            <p class="subtitle">{{ item.year }}</p>
          </v-col>
        </v-row>
      </v-card>
      <v-card class="elevation-0 general-medical-info" outlined>
        <v-card-title class="card-title px-5 pt-5"> Past Medical History</v-card-title>
        <v-divider></v-divider>
        <v-row class="px-5">
          <v-col v-for="item in patientConditions" :key="item.id" class="mt-5" cols="12" md="4">
            <p class="name">{{ item.name }}</p>
            <p class="subtitle">{{ item.year }}</p>
          </v-col>
        </v-row>
      </v-card>
    </template>
  </div>
</template>

<script>
import { mapActions as mapPiniaActions, mapState as mapPiniaState } from "pinia";
import { mapActions, mapState } from "pinia";

import PatientSummaryContactInfo from "@/components/MedicalRecord/PatientSummary/ContactInfo";
import PatientSummaryPharmacies from "@/components/MedicalRecord/PatientSummary/Pharmacies";
import PatientAllergiesView from "@/components/PatientAllergies/View";
import ActionFieldMultiple from "@/components/VisitNotes/ActionFieldMultiple";
import { useAllergiesStore } from "@/pinia-store/allergies";
import { useConditionsStore } from "@/pinia-store/conditions";
import { useMedicationsStore } from "@/pinia-store/medications";
import { useObservationsStore } from "@/pinia-store/observations";
import { usePatientStore } from "@/pinia-store/patient";
import DrawerBodyHeight from "@/views/Patient/Monitoring/bodyHeight/Drawer";
import DrawerBodyWeight from "@/views/Patient/Monitoring/bodyWeight/Drawer";
import DrawerSmokingBehavior from "@/views/Patient/Monitoring/smokingBehavior/Drawer";

export default {
  name: "AppointmentListItemMoreInfo",
  components: {
    PatientAllergiesView,
    PatientSummaryPharmacies,
    ActionFieldMultiple,
    DrawerSmokingBehavior,
    DrawerBodyHeight,
    DrawerBodyWeight,
    PatientSummaryContactInfo,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    ...mapPiniaState(useAllergiesStore, ["patientAllergies"]),
    ...mapState(useConditionsStore, ["patientConditions"]),
    ...mapState(usePatientStore, ["patient", "pharmacies"]),
    ...mapState(useMedicationsStore, ["patientMedications"]),
    ...mapState(useObservationsStore, [
      "bodyWeight",
      "bodyHeight",
      "bodyTemperature",
      "smokingBehavior",
      "bloodPressure",
      "bloodGlucose",
      "heartRate",
      "respiratoryRate",
      "oxygenSaturation",
    ]),
    bloodPressureInfo() {
      return (this.bloodPressure || []).slice(0, 3).map((i) => `systolic: ${i.systolic}, diastolic: ${i.diastolic}`);
    },
    bloodGlucoseInfo() {
      return (this.bloodGlucose || [])
        .slice(0, 3)
        .map((i) => `before meal: ${i.beforeMeal}, after meal: ${i.afterMeal}`);
    },
    respiratoryRateInfo() {
      return (this.respiratoryRate || []).slice(0, 3).map((i) => i.value);
    },
    oxygenSaturationInfo() {
      return (this.oxygenSaturation || []).slice(0, 3).map((i) => i.value);
    },
    heartRateInfo() {
      return (this.heartRate || []).slice(0, 3).map((i) => i.value);
    },
    bodyWeightInfo() {
      return (this.bodyWeight || []).slice(0, 3).map((i) => i.value);
    },
    bodyHeightInfo() {
      return (this.bodyHeight || []).slice(0, 3).map((i) => i.value);
    },
    bodyTemperatureInfo() {
      return (this.bodyTemperature || []).slice(0, 3).map((i) => i.value);
    },
    smokingBehaviorInfo() {
      return (this.smokingBehavior || []).slice(0, 3).map((i) => i.value);
    },

    patientInfo() {
      return this.item.patient;
    },
  },
  methods: {
    ...mapActions(useObservationsStore, [
      "getBodyWeight",
      "getBodyHeight",
      "getBodyTemperature",
      "getSmokingBehavior",
      "getBloodPressure",
      "getBloodGlucose",
      "getHeartRate",
      "getRespiratoryRate",
      "getOxygenSaturation",
    ]),
    ...mapActions(useObservationsStore, [
      "setPatientId",
      "toggleBodyWeightFormOpened",
      "toggleBodyHeightFormOpened",
      "toggleSmokingBehaviorFormOpened",
      "toggleBodyWeightTableOpened",
      "toggleBodyHeightTableOpened",
      "toggleSmokingBehaviorTableOpened",
    ]),
    ...mapActions(useConditionsStore, { setConditionsPatientId: "setPatientId" }),
    ...mapPiniaActions(useAllergiesStore, ["getPatientAllergies"]),
    ...mapActions(useMedicationsStore, ["getPatientMedications"]),
    ...mapActions(useConditionsStore, ["getPatientConditions"]),
    ...mapActions(usePatientStore, ["getPatientPharmacies", "getPatientProfile"]),
    onViewBodyWeight() {
      this.toggleBodyWeightTableOpened();
    },
    onViewSmokingBehavior() {
      this.toggleSmokingBehaviorTableOpened();
    },
    onViewBodyHeight() {
      this.toggleBodyHeightTableOpened();
    },
    translateChiefComplaint(val) {
      return this.$t("general.chiefComplaints")[val] || val || "-";
    },
  },
  async mounted() {
    const patientId = this.item.patient.id;
    this.setPatientId(patientId);
    this.setConditionsPatientId(patientId);
    this.loading = true;
    await Promise.all([
      this.getPatientProfile(patientId),
      this.getBodyWeight(),
      this.getBodyHeight(),
      this.getBodyTemperature(),
      this.getSmokingBehavior(),
      this.getBloodGlucose(),
      this.getBloodPressure(),
      this.getHeartRate(),
      this.getRespiratoryRate(),
      this.getOxygenSaturation(),
      this.getPatientConditions(),
      this.getPatientAllergies(patientId),
      this.getPatientMedications({ patientId }),
      this.getPatientPharmacies(patientId),
    ]);

    this.loading = false;
  },
};
</script>
