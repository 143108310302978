<template>
  <div>
    <v-data-table
      :custom-filter="filterBy"
      :headers="headersTranslated"
      :items="items"
      :items-per-page="perPage"
      :loading="loading"
      :options="options"
      :page.sync="page"
      :search="search"
      class="data-table patients-list_table"
      hide-default-footer
      mobile-breakpoint="940"
    >
      <template v-slot:item.userName="{ item }">
        <div class="table-item-user-name">
          {{ item.conversationLogRecord.userName }}
        </div>
      </template>
      <template v-slot:item.email="{ item }">
        <div class="table-item-email">
          {{ item.conversationLogRecord.email }}
        </div>
      </template>
      <template v-slot:item.date="{ item }">
        <div class="table-item-date">
          {{ formatDate(item.conversationLogRecord.date) }}
        </div>
      </template>
      <template v-slot:item.totalMessage="{ item }">
        <div class="table-item-total-message">
          {{ item.conversationLogRecord.totalMessage }}
        </div>
      </template>
      <template v-slot:item.chatDuration="{ item }">
        <div class="table-item-chat-duration">
          {{ convertChatDuration(item.conversationLogRecord.chatDuration) }}
        </div>
      </template>
      <template v-slot:item.view="{ item }">
        <v-btn
          Large
          class="table-item-view"
          color="primary"
          elevation="0"
          style="text-transform: capitalize"
          @click="openConversation(item.conversationLogRecord.channelSid, item)"
          >View
        </v-btn>
      </template>
      <template v-slot:footer="">
        <div class="patients-table-footer mt-8 row align-center justify-space-between">
          <div class="d-flex align-center col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
            <p class="mb-0">
              <span class="sub-heading-3">Showing </span>
              <span class="primary--text font-weight-bold">{{ page }}</span>
              <span class="sub-heading-3"> of </span>
              <span class="font-weight-bold">{{ Math.ceil(items.length / perPage) }}</span>
            </p>
            <v-divider class="patients-table-footer__middle-divider mx-5" vertical></v-divider>
            <div class="d-flex align-center">
              <p class="sub-heading-3 mb-0 mr-2">Rows per Page:</p>
              <v-select
                :items="itemsPerPageItems"
                :value="perPage"
                append-icon="mdi-chevron-down"
                class="patients-table-footer__per-page-select"
                dense
                hide-details
                outlined
                @change="onItemsPerPageChange"
              ></v-select>
            </div>
          </div>
          <v-pagination
            v-model="page"
            :length="Math.ceil(items.length / perPage)"
            :total-visible="0"
            class="patients-table-pagination col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6"
          ></v-pagination>
        </div>
      </template>
      <template v-slot:items.view="{ item }">
        <v-btn>{{ item }}</v-btn>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import moment from "moment-timezone";
import { mapActions, mapState } from "pinia";

import { useChatStore } from "@/pinia-store/chat";

export default {
  name: "ChatBotConversationList",
  props: {
    headers: Array,
    search: String,
  },
  inject: ["injectable"],
  data() {
    return {
      loading: false,
      options: {},
      items: [],
      perPage: 10,
      page: null,
      twilioContext: null,
    };
  },
  watch: {
    items() {
      this.loading = false;
    },
    options: {
      handler(options) {
        this.setChatLogTableOptions(options);
      },
      deep: true,
    },
  },
  computed: {
    ...mapState(useChatStore, ["getTableOptions"]),
    headersTranslated() {
      return this.headers.map((item) => ({
        ...item,
        text: item.text
          ? this.$te(`patients.table.headers.${item.text}`)
            ? this.$t(`patients.table.headers.${item.text}`)
            : item.text
          : "",
      }));
    },
    itemsLength() {
      return Math.ceil(this.items.length / this.perPage);
    },
    itemsPerPageItems() {
      return [
        {
          text: 5,
          value: 5,
        },
        {
          text: 10,
          value: 10,
        },
        {
          text: 15,
          value: 15,
        },
        {
          text: "All",
          value: this.items.length,
        },
      ];
    },
  },
  methods: {
    ...mapActions(useChatStore, ["setChatLogTableOptions", "setChatLog"]),
    formatDate(timestamp) {
      return moment.unix(timestamp / 1000).format("MM/DD/YYYY HH:mm:ss");
    },
    filterBy(value, search, item) {
      if (!search || search.length === 0) return true;
      const lowerCaseSearch = search.toLowerCase();
      const lowerCaseUserName = item.conversationLogRecord.userName.toLowerCase();
      const lowerCaseEmail = item.conversationLogRecord.email.toLowerCase();
      return lowerCaseUserName.includes(lowerCaseSearch) || lowerCaseEmail.includes(lowerCaseSearch);
    },
    convertChatDuration(chatDuration) {
      const seconds = Math.floor(chatDuration / 1000);
      const secondsDisplay = seconds % 60;
      const minutes = Math.floor(seconds / 60);
      const minutesDisplay = minutes % 60;
      const hours = Math.floor(minutes / 60);
      let sec, min, h;
      seconds <= 9 ? (sec = `0${secondsDisplay}`) : (sec = `${secondsDisplay}`);
      minutes < 10 && minutes >= 0 ? (min = `0${minutesDisplay}`) : (min = `${minutesDisplay}`);
      hours < 10 && hours >= 0 ? (h = `0${hours}`) : hours !== 0 ? (h = `${hours}`) : null;

      return `${h}:${min}:${sec}`;
    },
    onItemsPerPageChange(perPage) {
      this.perPage = perPage;
    },
    async openConversation(id, item) {
      await this.$router.push(`/owner/conversation/${id}`);
    },
  },
  async mounted() {
    this.loading = true;
    this.twilioContext = this.injectable.twilioContext;
    this.options = this.getTableOptions;
    this.items = this.injectable.twilioContext.channelRepository.getFilteredArchivedChannels();
    this.page = this.options?.page ?? 1;
  },
};
</script>

<style lang="scss" scoped>
.pointer {
  cursor: pointer;
}

.patients-table-footer {
  &__per-page-select {
    width: 100px;
    border: 2px solid $primarylight;
    border-radius: 4px;

    fieldset {
      border: none;
    }
  }
}

.table-item {
  &-user-name {
    //width: 180px;
    //background: #167516;
  }

  &-email {
    //width: 220px;
    //background: darkseagreen;
  }

  &-date {
    //background: #4d5f73;
  }

  &-total-message {
    //background: khaki;
  }

  &-chat-duration {
    //background: #e53935;
  }

  &-view {
    //background: #9d7324;
  }
}

:deep .text-start {
  text-align: center !important;
}

:deep th {
  span {
    //background: #e53935;
    text-align: center !important;
  }
}

.patients-table-pagination {
  ul {
    justify-content: flex-end;
    @media screen and (max-width: 960px) {
      justify-content: flex-start;
    }

    li {
      button {
        box-shadow: none;
        border: 2px solid $primarylight;
        box-sizing: border-box;
        border-radius: 4px;

        &.v-pagination__navigation {
          box-shadow: 0 1px 4px rgba(0, 0, 0, 0.12);
        }

        &.v-pagination__item {
          font-weight: bold;
          color: $primarylight3 !important;
        }

        &.v-pagination__item--active {
          color: #ffffff !important;
        }
      }
    }
  }
}
</style>
