<template>
  <v-data-table
    :class="`table data-table data-table__green-border table-bordered ${className}`"
    :headers="headers"
    :height="'auto'"
    :items="historyItems"
    hide-default-footer
    mobile-breakpoint="0"
  >
    <template v-if="!disableEdit" v-slot:item.action="{ item }">
      <div class="table_actions">
        <v-menu bottom content-class="patient-body-weight__edit-menu" left offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-bind="attrs" v-on="on">mdi-dots-horizontal</v-icon>
          </template>
          <v-list>
            <v-list-item @click="onEdit(item)">
              <v-list-item-title class="patient-body-weight__edit action-btn">
                {{ $t("general.edit") }}
              </v-list-item-title>
            </v-list-item>
            <v-list-item @click="deleteItem(item.id)">
              <v-list-item-title class="patient-body-weight__delete action-btn">
                {{ $t("general.delete") }}
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
    </template>
    <template v-slot:item.date="{ item }">
      {{ formatDate(item.date) }}
    </template>
    <template v-slot:item.performer="{ item }">
      <span v-for="performer in item.performer" :key="performer.id"
        >{{ performer.firstName }} {{ performer.lastName }}</span
      >
    </template>
    <template v-slot:no-data>
      <span class="data-table__no-items">
        {{ $t("general.table.noItems") }}
      </span>
    </template>
  </v-data-table>
</template>

<script>
import moment from "moment-timezone";
import { mapActions, mapState } from "pinia";

import { PatientsApi } from "@/api/patients";
import { timeDisplayFormat } from "@/components/Clinicians/constants";
import { snackBarEventBus, snackBarEventName } from "@/eventBuses/snackBar.eventBus";
import { useAuthStore } from "@/pinia-store/auth";
import { useAutoSaveStore } from "@/pinia-store/autoSave";
import { useObservationsStore } from "@/pinia-store/observations";

export default {
  name: "SmokingBehaviorTable",
  props: {
    className: {
      default: "",
      type: String,
    },

    disableEdit: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState(useObservationsStore, ["smokingBehavior"]),
    ...mapState(useAuthStore, ["uid", "timeZone"]),

    title() {
      return "Smoking Behavior";
    },
    historyItems() {
      return this.smokingBehavior.map((it) => ({ ...it, date: `${it.date || ""}` }));
    },
    headers() {
      return [
        {
          text: "Smoking Behavior",
          value: "value",
          width: "120px",
        },
        {
          text: "Last update",
          value: "date",
          width: "150px",
          separator: ",",
        },
        {
          text: "Updated by",
          value: "performer",
          width: "150px",
          separator: ",",
        },
        { text: "", value: "action", width: 28 },
      ];
    },
  },
  methods: {
    ...mapActions(useObservationsStore, ["getSmokingBehavior"]),
    ...mapActions(useObservationsStore, [
      "clearEditForm",
      "changeEditFormField",
      "setPatientId",
      "clearSmokingBehaviorForm",
      "fillSmokingBehaviorForm",
      "toggleSmokingBehaviorFormOpened",
    ]),
    ...mapActions(useAutoSaveStore, ["startSave", "endSave"]),
    formatDate(value) {
      let date = moment(value);
      return date.isValid() ? date.tz(this.timeZone).format(timeDisplayFormat) : "";
    },
    async deleteItem(id) {
      this.loading = true;

      try {
        this.startSave();
        await PatientsApi.destroySmokingBehavior(id, this.uid);
        await this.getSmokingBehavior();
      } catch (err) {
        console.error(err);
        if (err && err.response && err.response.status === 409) {
          snackBarEventBus.$emit(snackBarEventName, {
            message: this.$t("general.errors.err"),
            type: "error",
          });
        }
      }
      this.endSave();
      this.loading = false;
    },
    async reload() {
      this.loading = true;
      await this.getSmokingBehavior();
      this.loading = false;
    },
    onCreate() {
      this.clearSmokingBehaviorForm();
      this.toggleSmokingBehaviorFormOpened();
    },
    async onEdit(item) {
      await this.fillSmokingBehaviorForm(item);
      this.toggleSmokingBehaviorFormOpened();
    },
  },
};
</script>

<style scoped></style>
