<template>
  <v-expansion-panels v-model="panel" accordion class="encounter-visit-history" flat>
    <v-expansion-panel>
      <v-expansion-panel-header>
        <span class="visit-notes_card_header_title">
          <img class="title-icon" src="../../assets/heart.svg" />
          {{ $t("visits.summary.medicalInformation") }}
        </span>
      </v-expansion-panel-header>
      <v-expansion-panel-content class="">
        <v-row>
          <v-col>
            <v-tabs v-model="medicalTab">
              <v-tab>{{ $t("visits.summary.allergies") }}</v-tab>
              <v-tab>Past Medical History</v-tab>
            </v-tabs>
            <v-tabs-items v-model="medicalTab">
              <v-tab-item>
                <v-card class="tab-content-wrap pt-5 pb-8" flat>
                  <PatientAllergiesView :editable="editable" />
                  <PatientAllergyEdit v-if="editable" />
                </v-card>
              </v-tab-item>
              <v-tab-item>
                <v-card flat>
                  <PatientHistoryTable
                    id="PMH"
                    :editable="editable"
                    :items="patientConditions"
                    class="pt-5 pb-8"
                    place="visitNotes"
                    tableClassName="ph-table_visit-notes_table"
                    @change="onPMHChange"
                  />
                </v-card>
              </v-tab-item>
            </v-tabs-items>
          </v-col>
        </v-row>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>
<script>
import { mapActions as mapPiniaActions } from "pinia";
import { mapActions, mapState } from "pinia";

import PatientAllergyEdit from "@/components/PatientAllergies/Edit";
import PatientAllergiesView from "@/components/PatientAllergies/View";
import PatientHistoryTable from "@/components/PatientHistoryTable/Table";
import { useAllergiesStore } from "@/pinia-store/allergies";
import { useAutoSaveStore } from "@/pinia-store/autoSave";
import { useConditionsStore } from "@/pinia-store/conditions";
import { usePastVisitStore } from "@/pinia-store/pastVisit";
import { useVisitNoteStore } from "@/pinia-store/visitNote";
import { tableHeaders } from "@/utils/tableHeaders.ts";

export default {
  name: "VisitNotesMedicalInfo",
  components: {
    PatientAllergyEdit,
    PatientAllergiesView,
    PatientHistoryTable,
  },
  data: () => ({
    ...tableHeaders(),
    panel: 1,
    fileRecords: [],
    medicalTab: 0,
    fileRecordsForUpload: [],
  }),
  props: {
    editable: { default: true, type: Boolean },
  },
  computed: {
    ...mapState(useVisitNoteStore, ["medicalInfo"]),
    ...mapState(useConditionsStore, ["patientConditions"]),
    ...mapState(useVisitNoteStore, ["patientId"]),
    ...mapState(usePastVisitStore, ["getFile"]),
  },
  methods: {
    ...mapActions(useVisitNoteStore, ["setMedicalInfoField"]),
    ...mapActions(useVisitNoteStore, ["clearMedicalInfoFields", "autoSave"]),
    ...mapPiniaActions(useAllergiesStore, ["getPatientAllergies"]),
    ...mapActions(usePastVisitStore, ["setFile"]),
    ...mapActions(useConditionsStore, [
      "getPatientConditions",
      "addPatientCondition",
      "removePatientCondition",
      "setConditionsPatientId",
    ]),
    ...mapActions(useAutoSaveStore, ["startSave", "endSave"]),
    ...mapActions(useConditionsStore, { setConditionsPatientId: "setPatientId" }),

    async onPMHChange(value) {
      this.setMedicalInfoField({ value, fieldName: "pmh" });
      this.autoSave();
      this.setConditionsPatientId(this.patientId);
      this.startSave();
      await this.addPatientCondition(value);
      await this.getPatientConditions();
      this.endSave();
    },
  },
  async mounted() {
    this.panel = this.$vuetify.breakpoint.smAndUp ? 0 : 1;

    await this.getPatientAllergies(this.patientId);
    this.setConditionsPatientId(this.patientId);
    await this.getPatientConditions();
  },
};
</script>
<style lang="scss">
.visit-notes_card_medical-info_section1 {
  .v-tab {
    text-transform: none !important;
  }
}

.tab-content-wrap {
  padding-top: 15px;
}
</style>
