<template>
  <div>
    <div v-if="loading" class="d-flex justify-center align-center flex-column fill-height full-width">
      <v-icon class="channel-list-icon" size="92">mdi-loading</v-icon>
    </div>
    <div
      v-else-if="!loading && !(channelsList && channelsList.length)"
      class="d-flex justify-center align-center flex-column fill-height full-width preview-text"
    >
      {{ $t("chat.preview.noConversations") }}
    </div>
    <ChannelItem
      v-for="channel in channelsList"
      v-else
      :key="channel.sid"
      :active="channel.sid === selectedChannelSid"
      :channel="channel"
      v-on:click.native="$emit('selectChannel', channel)"
    />
  </div>
</template>

<script>
import ChannelItem from "./ChannelItem.vue";

export default {
  name: "ChannelsList",
  props: ["channelsList", "loading", "selectedChannelSid"],
  components: {
    ChannelItem,
  },
};
</script>

<style lang="scss" scoped>
.preview-text {
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: $primaryblack2;
  opacity: 0.75;
}

.channel-list-icon {
  color: $primary;
  animation: 1.2s loading linear infinite;
}

@keyframes loading {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>
