<template>
  <v-btn
    v-blur
    :block="size === 'x-large'"
    :class="`iconic-btn  outlined-btn__${size}  ${lite && 'lite'} ${vertical && 'vertical'} ${danger && 'danger'}`"
    :disabled="disabled"
    :height="height"
    :loading="loading"
    :ripple="false"
    :rounded="rounded"
    :type="type"
    :width="width"
    @click="onClick"
  >
    <v-icon v-if="leftIcon" class="primary-btn_icon-left" size="20">{{ leftIcon }}</v-icon>
    <slot name="left"></slot>
    <span>{{ text }}</span>
    <slot name="right"></slot>
    <v-icon v-if="rightIcon" class="primary-btn_icon-right" size="16">{{ rightIcon }}</v-icon>
  </v-btn>
</template>
<script>
export default {
  name: "IconicButton",
  props: {
    rightIcon: { default: "", type: String },
    leftIcon: { default: "", type: String },
    lite: { default: false, type: Boolean },
    danger: { default: false, type: Boolean },
    vertical: { default: false, type: Boolean },
    rounded: { default: false, type: Boolean },
    size: { default: "medium", type: String },
    text: { required: true, type: String },
    disabled: { default: false, type: Boolean },
    loading: { default: false, type: Boolean },
    width: { default: undefined, type: String },
    type: { default: "button", type: String },
  },
  computed: {
    height() {
      let height = null;
      switch (this.size) {
        case "x-large":
          height = 60;
          break;
        case "large":
          height = 46;
          break;
        case "medium":
          height = 36;
          break;
        case "small":
          height = 30;
          break;
      }
      return height;
    },
  },
  methods: {
    onClick() {
      this.$emit("onClick");
    },
  },
};
</script>

<style lang="scss" scoped>
$primary: var(--v-primary-base);
$red: #ff0036;
.iconic-btn {
  color: $primary !important;
  background: #ffffff !important;
  border: 1px solid #2196f3 !important;

  &.lite {
    border: none !important;
    box-shadow: none !important;
    padding: 0 6px !important;
  }

  &.danger {
    color: $red !important;
    background: #ffffff !important;
    border: 1px solid $red !important;

    .v-btn__content {
      i {
        color: $red !important;
      }
    }
  }

  span {
    text-transform: none;
  }

  &.vertical {
    height: 40px !important;

    :deep .v-btn__content {
      flex-direction: column !important;
      justify-content: center !important;
      gap: 3px;

      img {
        max-height: 21px;
        object-fit: contain;
      }

      span {
        font-size: 10px !important;
        text-transform: none;
      }
    }
  }

  .v-btn__content {
    color: #2196f3;

    > .v-icon {
      caret-color: $primary !important;
      color: $primary !important;
    }
  }

  &:hover {
    opacity: 1 !important;
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.32), rgba(255, 255, 255, 0.32)), $primarylight1;
  }

  &:active {
    opacity: 1 !important;
    background: linear-gradient(0deg, rgba(8, 25, 86, 0.02), rgba(8, 25, 86, 0.02)), $primarylight1;
  }

  &__large {
    padding: 0 20px !important;
  }

  :deep &.theme--light.v-btn.v-btn--disabled {
    opacity: 0.4;
    color: $primary !important;

    .v-icon {
      caret-color: $primary !important;
      color: $primary !important;
    }
  }
}
</style>
