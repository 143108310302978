import "firebase/auth";

import * as firebase from "firebase/app";
import { defineStore } from "pinia";

import { AuthApi } from "@/api/auth";
import { ChatApi, ChatCredentials } from "@/api/chat";
import { PatientsApi } from "@/api/patients";
import { PractitionersAPI } from "@/api/practitioners";
import { RoomInviteApi, RoomInviteCredentials } from "@/api/roomInvite";
import { RolesEnum } from "@/types/Roles.enum";

export type Role =
  | RolesEnum.Patient
  | RolesEnum.Practitioner
  | RolesEnum.EncounterParticipant
  | RolesEnum.Owner
  | RolesEnum.Registrar
  | RolesEnum.Admin
  | RolesEnum.ChatBot
  | RolesEnum.Anonymous;

export const userRoles = [
  RolesEnum.Patient,
  RolesEnum.Practitioner,
  RolesEnum.EncounterParticipant,
  RolesEnum.Owner,
  RolesEnum.Registrar,
  RolesEnum.Admin,
  RolesEnum.ChatBot,
  RolesEnum.Anonymous,
];

export interface AuthState {
  email: string | null;
  role: Role | null;
  token: string | null;
  twoFaToken: string | null;
  uid: string | null;
  isLoggedIn: boolean;
  displayName: string | null;
  photoURL: string | null;
  timeZone: string;
  isAutoTimeZone: boolean;
  customToken: string | null;
  twoFactor: boolean;
  isDependent?: boolean;
  isFromApp: boolean;
}

export const useAuthStore = defineStore({
  id: "auth",
  state: (): AuthState => {
    return {
      twoFaToken: null,
      twoFactor: false,
      email: null,
      role: null,
      token: null,
      isDependent: false,
      uid: null,
      isLoggedIn: false,
      isFromApp: typeof window.ReactNativeWebView !== "undefined",
      displayName: null,
      photoURL: null,
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      isAutoTimeZone: true,
      customToken: null,
    };
  },
  actions: {
    async logOut() {
      if (window.ReactNativeWebView && window.ReactNativeWebView.postMessage) {
        window.ReactNativeWebView.postMessage(JSON.stringify({ type: "ACTION", action: "LOGOUT" }));
      }
      if (firebase.auth().currentUser !== null) {
        try {
          await AuthApi.logout(firebase.auth().currentUser?.uid, firebase.auth().currentUser?.email);
        } catch (e) {
          console.info("Logout successful");
        }
        await firebase.auth().signOut();
      }
      this.clearLoginInfo();
    },
    clearLoginInfo() {
      this.isLoggedIn = false;
      this.uid = null;
      this.token = null;
      this.role = null;
      this.email = null;
      localStorage.twoFaToken = "";
      localStorage.removeItem("isOk");
    },
    setTwoFactor(ok: boolean): void {
      this.twoFaToken = ok;
    },
    async submitLogin(data: { email: any; password: any; rememberMe: any }) {
      if (window.ReactNativeWebView && window.ReactNativeWebView.postMessage) {
        window.ReactNativeWebView.postMessage(JSON.stringify({ type: "ACTION", action: "LOGOUT" }));
      }
      if (firebase.auth().currentUser !== null) {
        try {
          await AuthApi.logout(firebase.auth().currentUser?.uid, firebase.auth().currentUser?.email);
        } catch (e) {
          console.info("Logout successful");
        }
        await firebase.auth().signOut();
      }

      await this.actualizeUserInfo();
      this.isLoggedIn = false;
      this.uid = null;
      this.token = null;
      this.role = null;
      this.email = null;
      const { email, password, rememberMe } = data;

      try {
        if (email)
          await firebase
            .auth()
            .signInWithEmailAndPassword(email.trim(), password.trim())
            .then((r) => r)
            .catch(() => {
              return firebase.auth().signInWithEmailAndPassword(email.trim(), password.trim());
            });
      } catch (e) {
        throw Error(e);
      }

      if (!rememberMe) {
        await firebase.auth().setPersistence(firebase.auth.Auth.Persistence.SESSION);
      } else {
        await firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL);
      }
      try {
        const currentUser = await firebase.auth().currentUser;
        if (currentUser) {
          const idTokenResult = await currentUser.getIdTokenResult();
          if (idTokenResult) {
            const role = userRoles.find((r: string | number) => idTokenResult.claims[r]);
            this.isDependent = idTokenResult.claims.isDependent === true;
            if (role === RolesEnum.EncounterParticipant) {
              this.token = idTokenResult.token;
              this.isLoggedIn = false;
              this.role = role;
            } else if (role === RolesEnum.Anonymous) {
              this.isLoggedIn = false;
              this.token = idTokenResult.token;
              this.email = email;
              this.role = role;
              this.uid = currentUser.uid;
              this.displayName = currentUser.displayName;
              this.photoURL = currentUser.photoURL;
            } else if (role && !localStorage.twoFaToken) {
              this.isLoggedIn = true;
              this.token = idTokenResult.token;
              this.email = email;
              this.role = role;
              this.uid = currentUser.uid;
              this.displayName = currentUser.displayName;
              this.photoURL = currentUser.photoURL;
            } else if (role && ((this.twoFactor && localStorage.isOk) || localStorage.twoFaToken)) {
              this.isLoggedIn = true;
              this.token = idTokenResult.token;
              this.email = email;
              this.role = role;
              this.uid = currentUser.uid;
              this.displayName = currentUser.displayName;
              this.photoURL = currentUser;
            } else {
              this.isLoggedIn = false;
            }
          }
        } else {
          this.isLoggedIn = false;
        }
      } catch (err) {
        this.isLoggedIn = false;
        throw err;
      }
      const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

      if (this.uid && this.role === RolesEnum.Practitioner) {
        try {
          const settingsData = await PractitionersAPI.practitionerGetSettings(this.uid);
          this.isAutoTimeZone = settingsData.isAutoTimeZone;
          this.timeZone = settingsData.timeZone;
          if (settingsData.isAutoTimeZone && settingsData.timeZone !== timeZone) {
            try {
              if (this.isAutoTimeZone) {
                await PractitionersAPI.practitionerUpdateSettings(this.uid, { timeZone });
                this.timeZone = timeZone;
              }
            } catch (e) {
              console.error("Update timeZone failed", e);
            }
          }
        } catch (err) {
          console.error(err);
        }
      }
      if (this.uid && this.role === RolesEnum.Patient) {
        try {
          const settingsData = await PatientsApi.getSettings(this.uid);
          this.isAutoTimeZone = settingsData.isAutoTimeZone;
          this.timeZone = settingsData.timeZone;
          if (settingsData.isAutoTimeZone && settingsData.timeZone !== timeZone) {
            const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
            try {
              if (this.isAutoTimeZone) {
                await PatientsApi.updateSettings(this.uid, { timeZone });
                this.timeZone = timeZone;
              }
            } catch (e) {
              console.error("Update timeZone failed", e);
            }
          }
        } catch (err) {
          console.error(err);
        }
      }
    },
    async setCustomToken(customToken: string) {
      this.customToken = customToken;
    },
    async signInWithCustomToken() {
      const currentUser = await firebase.auth().currentUser;
      if (currentUser) await firebase.auth().signOut();
      await firebase.auth().setPersistence(firebase.auth.Auth.Persistence.SESSION);
      if (this.customToken) {
        await firebase.auth().signInWithCustomToken(this.customToken);
        await this.actualizeUserInfo();
      } else {
        throw Error("Auth.signInWithCustomToken Error: No Custom token to authorize");
      }
    },
    async getCustomTokenRoom(roomInviteCredentials: RoomInviteCredentials) {
      const token = await RoomInviteApi.getToken(roomInviteCredentials);
      if (token) {
        this.customToken = token;
      }
    },
    async getCustomTokenChat(chatCredentials: ChatCredentials) {
      const token = await ChatApi.getCustomToken(chatCredentials);
      if (token) {
        this.customToken = token;
      }
    },
    async setRefreshToken(newToken = undefined) {
      if (!newToken && !this.twoFactor) {
        const idTokenResult = await firebase.auth().currentUser?.getIdTokenResult(true);
        if (idTokenResult) {
          this.token = idTokenResult.token;
        }
      } else {
        this.token = newToken;
      }
    },
    async setToken(newToken: undefined) {
      this.twoFaToken = newToken;
      localStorage.twoFaToken = newToken;
      await this.actualizeUserInfo();
    },
    async resetPassword(email: string) {
      await firebase.auth().sendPasswordResetEmail(email);
    },
    async updateTimeZone() {
      if (!this.uid || !this.role) {
        return;
      }
      const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      try {
        if (this.role === RolesEnum.Patient && this.isAutoTimeZone) {
          await PatientsApi.updateSettings(this.uid, { timeZone });
          this.timeZone = timeZone;
        } else if (this.role === RolesEnum.Practitioner && this.isAutoTimeZone) {
          await PractitionersAPI.practitionerUpdateSettings(this.uid, { timeZone });
          this.timeZone = timeZone;
        }
      } catch (e) {
        console.error("Update timeZone failed", e);
      }
    },
    async actualizeUserInfo() {
      try {
        const currentUser = await firebase.auth().currentUser;
        if (currentUser) {
          const idTokenResult = await currentUser.getIdTokenResult();
          if (idTokenResult) {
            const role = userRoles.find((r) => idTokenResult.claims[r]);
            this.isDependent = role === RolesEnum.EncounterParticipant;
            if (role === RolesEnum.EncounterParticipant) {
              this.token = idTokenResult.token;
              this.isLoggedIn = true;
              this.role = role;
            } else if (role === RolesEnum.Anonymous) {
              this.isLoggedIn = false;
              this.token = idTokenResult.token;
              this.email = currentUser.email;
              this.role = role;
              this.uid = currentUser.uid;
              this.displayName = currentUser.displayName;
              this.photoURL = currentUser.photoURL;
            } else if (role && !localStorage.twoFaToken) {
              this.isLoggedIn = true;
              this.refreshToken = idTokenResult.token;
              this.role = role;
              this.uid = currentUser.uid;
              this.email = currentUser.email;
              this.displayName = currentUser.displayName;
              this.photoURL = currentUser.photoURL;
            } else if (role && ((this.twoFactor && localStorage.isOk) || localStorage.twoFaToken)) {
              this.isLoggedIn = true;
              this.refreshToken = idTokenResult.token;
              this.role = role;
              this.refreshToken = localStorage.twoFaToken;
              this.uid = currentUser.uid;
              this.email = currentUser.email;
              this.displayName = currentUser.displayName;
              this.photoURL = currentUser.photoURL;
            } else {
              this.isLoggedIn = false;
            }
          }
        } else {
          this.isLoggedIn = false;
        }
      } catch (err) {
        this.isLoggedIn = false;
        throw err;
      }
    },
  },
  getters: {
    userIsPatient: (state) => state.role === RolesEnum.Patient,
    userIsDependent: (state) => state.role === RolesEnum.Patient && state.isDependent,
    userIsAnonymous: (state) => state.role === RolesEnum.Anonymous,
    userIsPractitioner: (state): boolean => state.role === RolesEnum.Practitioner,
    userIsRegistrar: (state) => state.role === RolesEnum.Registrar,
    userIsAdmin: (state) => state.role === RolesEnum.Admin,
    isPractitionerFromApp: (state) => state.isFromApp && state.role === RolesEnum.Practitioner,
  },
});
