import { DateTime } from "luxon";
import { mapActions, mapState } from "pinia";

import { snackBarEventBus, snackBarEventName } from "@/eventBuses/snackBar.eventBus";
import { useVideoRoomStore } from "@/pinia-store/videoRoom";

export const visitRoomMixin = {
  data() {
    return {
      loading: false,
      encounter: null,
      error: null,
      timerInterval: null,
      timer: null,
    };
  },
  computed: {
    ...mapState(useVideoRoomStore, ["room", "roomIsFinished", "roomIsStarted"]),
    dateFormated() {
      if (!this.encounter) {
        return "";
      }
      return DateTime.fromISO(this.encounter.start).toFormat("dd MMMM y");
    },
    timeRange() {
      if (!this.encounter) {
        return "";
      }
      const start = DateTime.fromISO(this.encounter.start).toFormat("t");
      const end = DateTime.fromISO(this.encounter.end).toFormat("t");
      return `${start} - ${end}`;
    },
  },
  methods: {
    ...mapActions(useVideoRoomStore, ["getEncounter", "calculateTimer", "clear"]),
    async fetchRoom() {
      this.loading = true;
      this.error = null;
      const roomId = this.$route.params.roomId || this.$route.params.encounterId;
      try {
        await this.clear();
        this.encounter = await this.getEncounter(roomId);
      } catch (e) {
        console.error(e);
        snackBarEventBus.$emit(snackBarEventName, { message: e.message || e, type: "error" });
      }
      this.loading = false;
    },
    async timerUpdate() {
      this.timer = await this.calculateTimer();
    },
  },
  watch: {
    // async $route() {
    //   await this.fetchRoom();
    // },
  },
  async mounted() {
    await this.fetchRoom();
    this.timerInterval = setInterval(this.timerUpdate, 1000);
  },
  beforeDestroy() {
    window.clearInterval(this.timerInterval);
    if (this.timer) {
      window.clearInterval(this.timerInterval);
      this.timerInterval = null;
    }
  },
};
