<template>
  <div>
    <Confirm
      v-if="!successDialog"
      :cancelText="$t('general.dialog.cancel')"
      :confirmText="$t('patients.invite.button')"
      :dialog="value"
      :loading="loading"
      :text="$t('patients.invite.text')"
      :title="$t('patients.invite.title')"
      @confirm="submitInvite"
      @toggleDialog="toggleDialog"
    >
      <v-container class="no-padding-bottom no-padding-top">
        <v-row>
          <v-col cols="6">
            <BaseInput
              v-model="firstName"
              :errors="firsNameErrors"
              :placeholder="$t('general.inputs.firstName')"
              @blur="$v.firstName.$touch()"
              @change="onFirstNameChange"
            />
          </v-col>
          <v-col cols="6">
            <BaseInput
              v-model="lastName"
              :errors="lastNameErrors"
              :placeholder="$t('general.inputs.lastName')"
              @blur="$v.lastName.$touch()"
              @change="onLastNameChange"
            />
          </v-col>
          <v-col cols="12">
            <v-tabs :value="tabValue" background-color="transparent" class="tab_methods">
              <v-tab class="tab_methods__tab" @click="inviteMethod = 'email'">Invite by Email</v-tab>
              <v-tab class="tab_methods__tab" @click="inviteMethod = 'phone'">Invite by Phone</v-tab>
            </v-tabs>
            <BaseInput
              v-if="inviteMethod === 'email'"
              v-model="email"
              :errors="emailErrors"
              :placeholder="$t('general.inputs.email')"
              @blur="$v.email.$touch()"
              @change="onEmailChange"
            />
            <PhoneInput
              v-else
              v-model="phoneNumber"
              :errors="phoneNumberErrors"
              :placeholder="$t('general.inputs.phone')"
              @blur="$v.phoneNumber.$touch()"
              @change="onPhoneNumberChange"
            />
          </v-col>
          <v-col cols="12">
            <Select
              v-if="!isPractitioner"
              v-model="invitingId"
              :errors="invitingIdErrors"
              :items="practitionersList"
              :label="$t('patients.assign.choose')"
            />
          </v-col>
        </v-row>
      </v-container>
    </Confirm>
    <SuccessDialog :dialog="successDialog" :text="$t(`patients.invite.success`)" @on-close="successDialog = false" />
  </div>
</template>
<script>
import { mapActions, mapState } from "pinia";
import { alpha, email, required, requiredIf } from "vuelidate/lib/validators";

import Confirm from "@/components/shared/Confirm";
import PhoneInput from "@/components/shared/PhoneInput";
import SuccessDialog from "@/components/shared/SuccessDialog";
import BaseInput from "@/components/uikit/BaseInput";
import Select from "@/components/uikit/Select";
import { snackBarEventBus, snackBarEventName } from "@/eventBuses/snackBar.eventBus";
import { useAuthStore } from "@/pinia-store/auth";
import { useInvitesStore } from "@/pinia-store/invites";
import { usePractitionersStore } from "@/pinia-store/practitioners";
import { RolesEnum } from "@/types/Roles.enum";
import { defaultLocale } from "@/utils/constants";
import { isValidEmail, isValidPhoneNumber } from "@/utils/validations";

export default {
  name: "InvitePatientDialog",
  props: ["value"],
  components: {
    PhoneInput,
    Confirm,
    BaseInput,
    Select,
    SuccessDialog,
  },
  data: () => ({
    inviteMethod: "email",
    successDialog: false,
    email: "",
    phoneNumber: "",
    firstName: "",
    lastName: "",
    locale: defaultLocale,
    loading: false,
    invitingId: "",
  }),
  validations() {
    const validations = {
      email: {},
      phoneNumber: {},
      firstName: { required, alpha },
      lastName: { required, alpha },
      invitingId: {
        required: requiredIf(function () {
          return !this.isPractitioner;
        }),
      },
    };
    if (this.inviteMethod === "phone") {
      validations.phoneNumber = { required };
    }
    if (this.inviteMethod === "email") {
      validations.email = { required, email };
    }
    return validations;
  },

  computed: {
    ...mapState(useAuthStore, ["uid"]),
    ...mapState(useAuthStore, ["role"]),
    ...mapState(usePractitionersStore, ["practitioners"]),
    tabValue() {
      return this.inviteMethod === "email" ? 0 : 1;
    },
    isPractitioner() {
      return this.role === RolesEnum.Practitioner;
    },
    phoneNumberErrors() {
      const errors = [];

      if (!this.$v.phoneNumber.$dirty || isValidEmail(this.email)) return errors;
      if (!this.cleanPhone || !isValidPhoneNumber(this.cleanPhone))
        errors.push(this.$t("validation.required", { field: this.$t("general.inputs.phone") }));
      return errors;
    },
    cleanPhone() {
      if (!this.phoneNumber) return "";
      return this.phoneNumber.trim().replace("(", "").replace(")", "").replaceAll(" ", "").replaceAll("-", "");
    },
    emailErrors() {
      const errors = [];
      if (!this.$v.email.$dirty || isValidPhoneNumber(this.cleanPhone)) return errors;
      !this.$v.email.email && errors.push(this.$t("validation.email"));
      !this.$v.email.required &&
        errors.push(this.$t("validation.required", { field: this.$t("general.inputs.email") }));
      return errors;
    },
    invitingIdErrors() {
      const errors = [];
      if (!this.$v.invitingId.$dirty) return errors;
      !this.$v.invitingId.required &&
        errors.push(this.$t("validation.required", { field: this.$t("patients.assign.practitioner") }));
      return errors;
    },
    firsNameErrors() {
      const errors = [];
      if (!this.$v.firstName.$dirty) return errors;
      !this.$v.firstName.alpha && errors.push(this.$t("validation.name"));
      !this.$v.firstName.required &&
        errors.push(this.$t("validation.required", { field: this.$t("general.inputs.firstName") }));
      return errors;
    },
    practitionersList() {
      return this.practitioners?.map
        ? this.practitioners.map(({ firstName, lastName, id }) => ({
            text: `${firstName} ${lastName}`,
            value: id,
          }))
        : [];
    },
    lastNameErrors() {
      const errors = [];
      if (!this.$v.lastName.$dirty) return errors;
      !this.$v.lastName.alpha && errors.push(this.$t("validation.name"));
      !this.$v.lastName.required &&
        errors.push(this.$t("validation.required", { field: this.$t("general.inputs.lastName") }));
      return errors;
    },
  },
  methods: {
    ...mapActions(useInvitesStore, ["sendInvite", "getAllInvites"]),
    toggleDialog() {
      this.$emit("toggleDialog");
    },
    toggleSuccessDialog() {
      this.successDialog = !this.successDialog;
    },
    onFirstNameChange(value) {
      this.firstName = value;
    },
    onLastNameChange(value) {
      this.lastName = value;
    },
    onInvitingIdChange(value) {
      this.invitingId = value;
    },
    onEmailChange(value) {
      this.email = value;
    },
    onPhoneNumberChange(value) {
      this.phoneNumber = value;
    },
    clearFields() {
      this.email = "";
      this.firstName = "";
      this.lastName = "";
      this.phoneNumber = "";
      this.invitingId = "";
    },
    async submitInvite() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      try {
        this.loading = true;
        await this.sendInvite({
          ...(this.inviteMethod === "phone" ? { phoneNumber: this.phoneNumber } : {}),
          ...(this.inviteMethod === "email" ? { email: this.email } : {}),
          firstName: this.firstName,
          lastName: this.lastName,
          locale: this.locale,
          invitingId: this.invitingId,
        });
        this.toggleDialog();
        this.toggleSuccessDialog();
        await this.getAllInvites(this.role === RolesEnum.Practitioner ? { practitionerId: this.uid } : {});
        this.clearFields();
        this.$emit("success");
      } catch (err) {
        console.error(err);

        if (err.isAxiosError) {
          err.message = err.response.data.message;
        }
        snackBarEventBus.$emit(snackBarEventName, { message: err.message || err, type: "error" });
      }
      this.loading = false;
    },
  },
};
</script>
<style lang="scss">
.tab_methods {
  width: 100%;
  margin-bottom: 30px;

  &__tab {
    width: 100%;
    text-transform: none;
    font-size: 14px;
  }
}
</style>
