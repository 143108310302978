<template>
  <Calendar />
</template>
<script>
import Calendar from "@/components/Calendar/RegistrarCalendar";
export default {
  name: "Schedule",
  components: {
    Calendar,
  },
};
</script>
