<template>
  <Confirm
    :dialog="opened"
    :hideCancel="true"
    :hideConfirm="true"
    :loading="loading"
    :title="title"
    cancelText="Close"
    maxWidth="90%"
    rootClass="monitoring-form"
    text=""
    width="600px"
    @toggleDialog="$emit('onClose')"
  >
    <v-row class="pharmacy-select">
      <v-col cols="3">
        <v-subheader>Name *</v-subheader>
      </v-col>
      <v-col cols="9">
        <BaseInput
          :errors="validateField('name')"
          :value="name"
          fieldName="name"
          placeholder="Name"
          @change="onFieldChange"
        />
      </v-col>

      <v-col class="advance-form" cols="12">
        <v-row>
          <v-col cols="3">
            <v-subheader> Address *</v-subheader>
          </v-col>
          <v-col cols="9">
            <BaseInput
              :height="46"
              :value="address"
              fieldName="address"
              placeholder="Address"
              @change="onFieldChange"
            />
          </v-col>
          <v-col cols="3">
            <v-subheader>City *</v-subheader>
          </v-col>
          <v-col cols="9">
            <BaseInput v-model="city" :height="46" fieldName="city" placeholder="City" @change="onFieldChange" />
          </v-col>
        </v-row>
      </v-col>
      <v-spacer></v-spacer>
      <v-col class="justify-space-between d-flex" cols="12">
        <div class="gap-1 justify-end">
          <v-btn outlined text @click="$emit('onClose')"> Cancel</v-btn>
          <v-btn :disabled="filterIsEmpty" :loading="isLoading" color="primary" depressed @click="onSelectPharmacy">
            Add
          </v-btn>
        </div>
      </v-col>
    </v-row>
  </Confirm>
</template>
<script>
import { mapActions, mapState } from "pinia";

import Confirm from "@/components/shared/Confirm";
import BaseInput from "@/components/uikit/BaseInput";
import { validatorErrors } from "@/mixins/validatorErrors";
import { useAutoSaveStore } from "@/pinia-store/autoSave";
import { useVisitNoteStore } from "@/pinia-store/visitNote";

export default {
  name: "PharmacySelectTextFree",
  props: {
    title: {
      type: String,
      default: "Change Pharmacy",
    },
    opened: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    BaseInput,
    Confirm,
  },
  data: () => ({
    loading: false,
    medicalTab: 0,
    name: "",
    specialty: "",
    city: "",
    isLoading: false,
    advanceSearchMenu: false,
    address: "",
    results: [],
    pharmacySearch: "",
    pharmacyId: "",
  }),
  mixins: [validatorErrors],
  methods: {
    ...mapActions(useVisitNoteStore, ["getPharmacy"]),
    ...mapActions(useAutoSaveStore, ["startSave", "endSave"]),
    onFieldChange(value, fieldName) {
      this[fieldName] = value;
    },

    async onSelectPharmacy() {
      this.$emit("onSelect", { name: this.name, city: this.city, address: this.address });
    },
  },
  computed: {
    ...mapState(useVisitNoteStore, ["otherInfo", "pharmacy"]),
    filterIsEmpty() {
      return !this.name.length || !this.city.length || !this.address.length;
    },
  },
  watch: {
    // todo: change if used
    "$store.state.visitNote.pharmacy": {
      handler(val) {
        if (val?.id) {
          this.pharmacyName = val.id;
          this.searchresults = [{ ...val }];
        }
      },
    },
    "$store.state.visitNote.encounter.id": {
      immediate: true,
      handler(val) {
        if (val) {
          this.getPharmacy();
        }
      },
    },
  },
};
</script>
<style lang="scss">
.pharmacy-select {
  .col {
    align-items: center;
  }

  .searchWrap {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .resultsWrap {
    max-height: 500px;
    overflow-y: auto;

    > div {
      transition: 0.3s;

      &:hover {
        opacity: 0.8;
      }

      padding: 13px 5px;
      cursor: pointer;
      border-top: 1px solid #e7e8f2;
    }
  }

  .advance-form {
    .col {
      display: flex;
      align-items: center;
    }
  }
}
</style>
