<template>
  <v-dialog
    v-model="isActive"
    content-class="auto-save"
    hide-overlay
    max-width="180px"
    origin="right bottom"
    persistent
    width="300"
  >
    <v-card dark>
      <v-progress-linear v-if="state === 'started'" class="mb-0" color="blue" indeterminate></v-progress-linear>
      <v-progress-linear v-if="state === 'ending'" :color="`green `" class="mb-0" value="100"></v-progress-linear>
      <v-card-title v-if="state === 'started'" class="started">
        <div>
          <v-icon class="spin">mdi-cached</v-icon>
          Saving...
        </div>
        <v-icon class="close" @click="close">mdi-close</v-icon>
      </v-card-title>
      <v-card-title v-if="state === 'ending'" :class="`state-${variant}`">
        <v-icon class="checked">mdi-check</v-icon>
        {{ endMessage }}
      </v-card-title>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapActions, mapState } from "pinia";

import { useAutoSaveStore } from "@/pinia-store/autoSave";

export default {
  name: "AutoSaveIndicator",
  props: {
    timeout: Number,
    value: Boolean,
  },
  computed: {
    ...mapState(useAutoSaveStore, ["state", "endMessage", "variant"]),
    isActive() {
      return ["started", "ending"].includes(this.state);
    },
    text() {
      return ["started"].includes(this.state) ? "Saving..." : "Saved";
    },
  },
  watch: {
    // todo: change watcher
    "$store.state.autoSave.state"(val) {
      if (val === "ending")
        setTimeout(() => {
          this.end();
        }, 1300);
    },
  },
  methods: {
    ...mapActions(useAutoSaveStore, ["end", "endSave"]),
    close() {
      this.endSave();
    },
  },
};
</script>
<style lang="scss">
.auto-save {
  align-self: flex-end;
  margin-left: auto;
  margin-right: 44px;

  .v-card {
    background: #ffffff;
    border: 1px solid #e7e8f2;
    box-sizing: border-box;
    box-shadow: 0 12px 32px rgba(0, 0, 0, 0.04);
    border-radius: 6px;
  }

  .spin {
    border-radius: 50%;
    font-size: 17px;
    padding: 3px;
    color: white !important;
    margin-right: 10px;
    background-color: #2196f3;
    animation: spin 2s linear infinite;
  }

  .started {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .close {
    border-radius: 50%;
    font-size: 17px;
    padding: 0;
    right: 0;
    color: rgba(0, 0, 0, 0.97) !important;
    margin-right: 10px;
    background-color: #e1dfdf6e;
  }

  @keyframes spin {
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }

  .checked {
    border-radius: 50%;
    font-size: 17px;
    padding: 3px;
    color: white !important;
    margin-right: 10px;
    background-color: #2fcf6f;
    animation: fade 1s linear;
  }

  @keyframes fade {
    0% {
      opacity: 0;
      transform: scale(0.3);
    }
    50% {
      transform: scale(1.2);
    }
    100% {
      transform: scale(1);
      opacity: 1;
    }
  }

  .v-card__title {
    padding: 10px !important;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #33343e !important;
  }

  .state-error {
    .checked {
      background-color: $error;
    }
  }
}

.snackbar {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;

  color: $primaryblack2;

  &-icon {
    opacity: 1 !important;
    margin: 0 7px;

    &-close {
      position: absolute !important;
      top: 50%;
      right: 8px;
      transform: translateY(-50%);
    }
  }

  .v-snack__content {
    border-radius: 4px;
  }

  &-error {
    .v-snack__content {
      border-top: 5px solid $error;
    }
  }

  &-primary {
    .v-snack__content {
      border-top: 5px solid $primary;
    }
  }
}
</style>
