<template>
  <div>
    <RegistrarSettings />
  </div>
</template>

<script>
import RegistrarSettings from "@/components/settings/registrar";
export default {
  name: "registrarSettings",
  components: {
    RegistrarSettings,
  },
};
</script>
