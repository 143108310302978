<template>
  <div>
    <v-data-table
      :headers="headersTranslated"
      :items="items"
      :items-per-page="perPage"
      :loading="loading"
      :page="page"
      :search="search"
      class="data-table patients-list_table"
      hide-default-footer
      mobile-breakpoint="940"
    >
      <template v-slot:item.medicalRecordNumber="{ item }">
        <div class="pointer" @click="redirectToMedicalRecord(item)">
          {{ item.medicalRecordNumber || "" }}
        </div>
      </template>
      <template v-slot:item.firstName="{ item }">
        <div class="pointer" @click="redirectToMedicalRecord(item)">
          {{ item.firstName || "" }}
        </div>
      </template>
      <template v-slot:item.lastName="{ item }">
        <div class="pointer" @click="redirectToMedicalRecord(item)">
          {{ item.lastName || "" }}
        </div>
      </template>
      <template v-slot:item.checkbox="{ item }">
        <v-simple-checkbox v-model="item.checked"></v-simple-checkbox>
      </template>
      <template v-slot:header.data-table-select="{ on, props }">
        <v-simple-checkbox v-bind="props" v-on="on"></v-simple-checkbox>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-menu offset-y>
          <template v-slot:activator="{ on }">
            <v-icon class="mr-2" small v-on="on"> mdi-dots-horizontal</v-icon>
          </template>
          <v-list>
            <v-list-item v-for="action in actions" :key="action.text" @click="action.callback(item)">
              <v-list-item-title>{{ action.text }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
      <template v-slot:footer="{ props }">
        <div class="patients-table-footer mt-8 row align-center justify-space-between">
          <div class="d-flex align-center col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
            <p class="mb-0">
              <span class="sub-heading-3">Showing </span>
              <span class="primary--text font-weight-bold">{{ props.pagination.itemsPerPage }}</span>
              <span class="sub-heading-3"> of </span>
              <span class="font-weight-bold">{{ props.pagination.itemsLength }}</span>
            </p>
            <v-divider class="patients-table-footer__middle-divider mx-5" vertical></v-divider>
            <div class="d-flex align-center">
              <p class="sub-heading-3 mb-0 mr-2">Rows per Page:</p>
              <v-select
                :items="itemsPerPageItems"
                :value="perPage"
                append-icon="mdi-chevron-down"
                class="patients-table-footer__per-page-select"
                dense
                hide-details
                outlined
                @change="onItemsPerPageChange"
              ></v-select>
            </div>
          </div>
          <v-pagination
            v-model="page"
            :length="itemsLength"
            :total-visible="0"
            class="patients-table-pagination col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6"
          ></v-pagination>
        </div>
      </template>
    </v-data-table>
  </div>
</template>
<script>
export default {
  name: "PatientsListTable",
  props: {
    headers: Array,
    items: Array,
    search: String,
    loading: Boolean,
    actions: Array,
  },
  data() {
    return {
      perPage: 10,
      page: 1,
    };
  },
  computed: {
    headersTranslated() {
      return this.headers.map((item) => ({
        ...item,
        text: item.text
          ? this.$te(`patients.table.headers.${item.text}`)
            ? this.$t(`patients.table.headers.${item.text}`)
            : item.text
          : "",
      }));
    },
    itemsLength() {
      return Math.ceil(this.items.length / this.perPage);
    },
    itemsPerPageItems() {
      return [
        {
          text: 5,
          value: 5,
        },
        {
          text: 10,
          value: 10,
        },
        {
          text: 15,
          value: 15,
        },
        {
          text: "All",
          value: this.items.length,
        },
      ];
    },
  },
  methods: {
    async redirectToMedicalRecord(item) {
      await this.$router.push(`/practitioner/record/patient/${item.id}`);
    },
    onItemsPerPageChange(perPage) {
      this.perPage = perPage;
      if (this.page > this.itemsLength) {
        this.page = this.itemsLength;
      }
    },
  },
};
</script>

<style lang="scss">
.pointer {
  cursor: pointer;
}

.patients-table-footer {
  &__per-page-select {
    width: 100px;
    border: 2px solid $primarylight;
    border-radius: 4px;

    fieldset {
      border: none;
    }
  }
}

.patients-table-pagination {
  ul {
    justify-content: flex-end;
    @media screen and (max-width: 960px) {
      justify-content: flex-start;
    }

    li {
      button {
        box-shadow: none;
        border: 2px solid $primarylight;
        box-sizing: border-box;
        border-radius: 4px;

        &.v-pagination__navigation {
          box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.12);
        }

        &.v-pagination__item {
          font-weight: bold;
          color: $primarylight3 !important;
        }

        &.v-pagination__item--active {
          color: #ffffff !important;
        }
      }
    }
  }
}
</style>
