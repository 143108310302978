<template>
  <v-row class="visit-summary-header" justify="space-between">
    <v-col class="visit-summary-header_left">
      <img class="visit-summary-header_left_logo" src="../../assets/logo/color.svg" />
      <div class="visit-summary-header_left-texts">
        <span>
          {{ $t("visits.summary.dateIssued") }}
        </span>
        <span class="visit-summary-header_left-texts--bold">
          {{ visitDate }}
        </span>
      </div>
    </v-col>
    <v-col class="visit-summary-header_right">
      <template v-for="address of addresses">
        <span :key="address">{{ address }}</span>
      </template>
    </v-col>
  </v-row>
</template>
<script>
import { DateTime } from "luxon";
import { mapActions } from "pinia";

import { PatientsApi } from "@/api/patients";
import { useEncountersStore } from "@/pinia-store/encounters";

export default {
  name: "VisitSummaryHeader",
  data: () => ({
    checkout: {},
    patientProfile: null,
    addresses: process.env.VUE_APP_BRAND_ADDRESS.split(","),
  }),
  computed: {
    ...mapActions(useEncountersStore, ["currentEncounter"]),
    visitDate() {
      return DateTime.fromISO(this.currentEncounter.start).toFormat("d/M/yyyy");
    },
  },
  async mounted() {
    this.patientProfile = await PatientsApi.patientGetProfile(this.currentEncounter.patient.id);
  },
};
</script>
<style lang="scss">
.visit-summary-header {
  padding: 30px;

  &_left {
    height: 100px;

    &_logo {
      max-width: 112px;
      height: 32px;
    }

    &-texts {
      margin-top: 26px;
      margin-bottom: 3px;

      &--bold {
        font-weight: 550;
      }
    }
  }

  &_right {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;

    span {
      margin-top: 3px;
    }
  }
}
</style>
