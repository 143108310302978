import cloneDeep from "lodash/cloneDeep";
import moment from "moment-timezone";
import { defineStore } from "pinia";

import { CommunicationApi } from "@/api/communication";
import { EncountersAPI } from "@/api/encounters";
import { PatientsApi } from "@/api/patients";
import { Encounter } from "@/pinia-store/interfaces/Encounter";
import { usePatientVisitScheduleStore } from "@/pinia-store/patientVisitSchedule";
import { EncounterQueryFilterDto } from "@/types/EncounterQueryFilter";
import { Patient } from "@/types/users";

export const initialPatientInfo = {
  address: "",
  birthDate: "",
  email: "",
  firstName: "",
  gender: "",
  id: "",
  lastName: "",
  phoneNumber: "",
  practitioner: {
    firstName: "",
    gender: "",
    id: "",
    lastName: "",
    qualification: "",
  },
  state: "",
};

interface MedicalRecordState {
  patient: Patient;
  encounters: Encounter[];
  loadingPrivateNotes: boolean;
  privateNotes: [];
}

export const useMedicalRecordStore = defineStore({
  id: "medicalRecord",
  state: (): MedicalRecordState => ({
    patient: cloneDeep(initialPatientInfo),
    encounters: [],
    loadingPrivateNotes: false,
    privateNotes: [],
  }),
  actions: {
    async getPatient(payload: string) {
      this.patient = await PatientsApi.patientGetProfile(payload);
    },
    async getEncounters(payload: EncounterQueryFilterDto) {
      const encounters = await EncountersAPI.getAll(payload);
      this.setEncounters(encounters?.results || []);
    },
    setEncounters(payload: Encounter[]) {
      const filteredEncounters = payload
        .filter((encounter: Encounter) => encounter.start && encounter.end)
        .sort((a: Encounter, b: Encounter) => moment(b.start).valueOf() - moment(a.start).valueOf());
      this.encounters = [...filteredEncounters];
    },
    clearAllFields() {
      this.patient = initialPatientInfo;
      const patientVisitScheduleStore = usePatientVisitScheduleStore();
      patientVisitScheduleStore.clearAllFields();
      this.encounters = [];
    },
    async getPrivateNotes(payload: { patientId: string; practitionerId: string }) {
      this.loadingPrivateNotes = true;
      this.privateNotes = await CommunicationApi.getCommunications(payload.patientId, payload.practitionerId);
      this.loadingPrivateNotes = false;
    },
    async createPrivateNote(payload: { patientId: any; privateNote: any; practitionerId: any; encounterId: any }) {
      await CommunicationApi.createCommunication({
        patientId: payload.patientId,
        privateNote: payload.privateNote,
        practitionerId: payload.practitionerId,
        reason: "private note",
        encounterId: payload.encounterId,
      });
    },
    async deletePrivateNote(id: string) {
      await CommunicationApi.deleteCommunication(id);
    },
    async updatePrivateNote(payload: { id: string; text: string }) {
      await CommunicationApi.updateCommunication(payload.id, payload.text);
    },
  },
});
