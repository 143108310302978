<template>
  <div class="ps_details">
    <span class="heading-4">QR-Code</span>
    <v-row v-if="isTurnOn" class="ps_row">
      <span class="heading-5">Your QR code is included</span>
    </v-row>
    <div v-if="!isTurnOn">
      <v-row>
        <img :src="qr" />
      </v-row>
      <v-row class="ps_row">
        <v-col cols="12" md="3">
          <v-text-field
            v-if="qr"
            v-model="code"
            :counter="6"
            :height="$vuetify.breakpoint.xsOnly ? 48 : 60"
            :rules="qrcodeRules"
            outlined
            placeholder="Code Number"
            required
            @input="onInputChange($event)"
          />
          <v-col class="ps_btn-wrapper" cols="12" md="2">
            <PrimaryButton v-if="!qr" size="large" text="QR Code" @onClick="openQr" />
            <PrimaryButton v-if="qr" :disabled="isButtonDisabled" size="large" text="Verify Code" @onClick="sendCode" />
          </v-col>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "pinia";

import { TwoFactorApi } from "@/api/twoFactor";
import { useAuthStore } from "@/pinia-store/auth";
import { useTwoFactorStore } from "@/pinia-store/twoFactor";

import PrimaryButton from "../uikit/PrimaryButton";

export default {
  name: "twoFactorAuthentication",
  components: {
    PrimaryButton,
  },
  data() {
    return {
      isTurnOn: false,
      qr: "",
      code: "",
      qrIsTurnOn: "",
      isButtonDisabled: true,
      qrcodeRules: [
        (v) => !!v || "Completely input 6 number",
        (v) => (v.length <= 6 && Number(v)) || "Code must be 6 numbers",
      ],
    };
  },
  computed: {
    ...mapState(useTwoFactorStore, ["getQrcod", "getSucces"]),
    ...mapState(useAuthStore, ["token", "uid"]),
  },
  async mounted() {
    await this.getQrcode();
    const success = await TwoFactorApi.checkOutQrcode();
    this.isTurnOn = success?.setting?.isTwoFactorAuthenticationEnabled;
  },
  methods: {
    ...mapActions(useTwoFactorStore, ["getQrcode", "sendVerifynumbers"]),
    ...mapActions(useAuthStore, ["refreshToken"]),
    async openQr() {
      let qr = this.getQrcod;
      this.qr = qr.base64String;
    },
    onInputChange(e) {
      this.isButtonDisabled = !(Number(e) && e.length === 6);
    },
    async sendCode() {
      await this.sendVerifynumbers({
        twoFactorAuthenticationCode: this.code,
      });
      const success = await TwoFactorApi.checkOutQrcode();
      this.isTurnOn = success?.setting?.isTwoFactorAuthenticationEnabled;
      let token = await this.getSucces;
      await this.refreshToken(token.authorized.authorized.idToken);
      this.qrIsTurnOn = this.token;
    },
  },
};
</script>
