<template>
  <div class="patients-list">
    <v-row align="center" class="patients-list_header">
      <v-col class="patients-list_header_block" cols="12" lg="4">
        <h1 class="mt-2">{{ $t("patients.title") }}</h1>
      </v-col>
      <v-col class="d-flex align-end justify-end patients-list_header_block" cols="12" lg="8">
        <v-text-field
          v-model="search"
          :placeholder="$t('patients.searchPlaceholder')"
          class="mt-2 patients-list_header_block_wide-input"
          dense
          hide-details
          onfocus="this.removeAttribute('readonly');"
          ontouchstart="this.removeAttribute('readonly');"
          prepend-inner-icon="mdi-magnify"
          solo
        />
        <v-menu
          v-if="tab === 0"
          v-model="advanceSearchMenu"
          :close-on-content-click="false"
          :min-width="$vuetify.breakpoint.mdAndUp ? '524px' : undefined"
          bottom
          class="patients-list_advance-search"
          content-class="advance-search-dialog"
          left
          nudge-bottom="13px"
          offset-y
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              :block="!!$vuetify.breakpoint.xs"
              class="mt-2 btn"
              color="primary"
              height="38"
              v-bind="attrs"
              v-on="on"
            >
              {{ $t("patients.advancedSearch") }}
            </v-btn>
          </template>
          <v-card>
            <v-list>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title class="patients-list_advance-search_title">
                    {{ $t("patients.advancedSearch") }}
                  </v-list-item-title>
                </v-list-item-content>
                <v-list-item-action class="patients-list_advance-search_close-btn" @click="toggleAdvanceSearchMenu">
                  <v-icon>mdi-close</v-icon>
                </v-list-item-action>
              </v-list-item>
              <v-list-item>
                <v-list-item-content class="patients-list_advance-search_row">
                  <v-col cols="4">
                    <v-list-item-title> {{ $t("patients.medicalRecord") }}</v-list-item-title>
                  </v-col>
                  <v-col cols="8">
                    <BaseInput
                      :height="46"
                      :placeholder="$t('general.inputs.all')"
                      :value="filter.medicalRecord"
                      fieldName="medicalRecord"
                      @change="onFilterChange"
                    />
                  </v-col>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content class="patients-list_advance-search_row">
                  <v-col cols="4">
                    <v-list-item-title>{{ $t("general.inputs.firstName") }}</v-list-item-title>
                  </v-col>
                  <v-col cols="8">
                    <BaseInput
                      v-model="filter.firstName"
                      :height="46"
                      :placeholder="$t('general.inputs.all')"
                      fieldName="firstName"
                      @change="onFilterChange"
                    />
                  </v-col>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content class="patients-list_advance-search_row">
                  <v-col cols="4">
                    <v-list-item-title>{{ $t("general.inputs.lastName") }}</v-list-item-title>
                  </v-col>
                  <v-col cols="8">
                    <BaseInput
                      v-model="filter.lastName"
                      :height="46"
                      :placeholder="$t('general.inputs.all')"
                      fieldName="lastName"
                      @change="onFilterChange"
                    />
                  </v-col>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content class="patients-list_advance-search_row">
                  <v-col cols="4">
                    <v-list-item-title>{{ $t("general.inputs.dateOfBirth") }}</v-list-item-title>
                  </v-col>
                  <v-col cols="8">
                    <DatePicker
                      v-model="filter.birthDate"
                      :dense="true"
                      :height="47"
                      :maxDate="new Date().toISOString().substr(0, 10)"
                      :placeholder="$t('general.inputs.all')"
                      hide-details
                      @setDate="onFilterBirthDateChange"
                    />
                  </v-col>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content class="patients-list_advance-search_row">
                  <v-col cols="4">
                    <v-list-item-title>{{ $t("general.inputs.sex") }}</v-list-item-title>
                  </v-col>
                  <v-col cols="8">
                    <Select :items="genders" :value="filter.gender" hide-details @change="onFilterGenderChange" />
                  </v-col>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content class="patients-list_advance-search_row">
                  <v-col cols="4">
                    <v-list-item-title>{{ $t("general.inputs.email") }}</v-list-item-title>
                  </v-col>
                  <v-col cols="8">
                    <BaseInput
                      v-model="filter.email"
                      :height="46"
                      :placeholder="$t('general.inputs.all')"
                      fieldName="email"
                      @change="onFilterChange"
                    />
                  </v-col>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content class="patients-list_advance-search_row">
                  <v-col cols="4">
                    <v-list-item-title>{{ $t("general.inputs.phoneNumber") }}</v-list-item-title>
                  </v-col>
                  <v-col cols="8">
                    <PhoneInput
                      v-model="filter.phoneNumber"
                      :height="46"
                      :placeholder="$t('general.inputs.all')"
                      fieldName="phoneNumber"
                      @change="onFilterChange"
                    />
                  </v-col>
                </v-list-item-content>
              </v-list-item>
            </v-list>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn :block="!!$vuetify.breakpoint.xs" :disabled="filterIsEmpty" outlined text @click="onClearFilter">
                {{ $t("patients.clear") }}
              </v-btn>
              <v-btn :block="!!$vuetify.breakpoint.xs" color="primary" depressed @click="submitFilterPatients">
                {{ $t("patients.apply") }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-menu>

        <v-menu bottom content-class="add-menu" left offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              :block="!!$vuetify.breakpoint.xs"
              class="mt-2 btn"
              color="success"
              height="38"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon class="primary-btn_icon-left" size="20">mdi-plus</v-icon>
              Add patient
            </v-btn>
          </template>
          <v-list>
            <v-list-item @click="toggleDialog">
              <v-list-item-title class="templates__edit">Invite a patient</v-list-item-title>
            </v-list-item>
            <v-list-item @click="pushRegisterPatient">
              <v-list-item-title class="templates__edit">Create new patient profile</v-list-item-title>
            </v-list-item>
            <v-list-item @click="toggleActivationProfileDialog">
              <v-list-item-title class="templates__edit">Send activation link</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>

        <v-btn
          v-if="showImport"
          :block="!!$vuetify.breakpoint.smAndDown"
          class="ml-0 btn"
          color="success"
          height="38"
          @click="toggleImportDialog"
        >
          <v-icon> mdi-import</v-icon>
          {{ $t("patients.import.button") }}
        </v-btn>
      </v-col>
      <v-col v-if="tab === 0 && $vuetify.breakpoint.smAndDown" cols="12">
        <v-chip
          v-for="filterItem in filterSelectedOptions"
          :key="filterItem.key"
          class="mr-2 mb-1 patients-list_advance-chip mobile-size"
          label
          small
          @click="removeFilterItem(filterItem)"
        >
          {{ filterItem.value }}
          <v-icon class="muted" color="primarylight1" small>fa-times</v-icon>
        </v-chip>
      </v-col>
    </v-row>
    <v-tabs v-model="tab" class="tabs" color="primary">
      <v-tab class="tabs_tab fill-height"> {{ $t("patients.activeUsers") }} ({{ patients.length }})</v-tab>
      <v-tab class="tabs_tab fill-height"> {{ $t("patients.invitations") }} ({{ invites.length }})</v-tab>
      <template v-if="tab === 0 && $vuetify.breakpoint.mdAndUp">
        <v-chip
          v-for="filterItem in filterSelectedOptions"
          :key="filterItem.key"
          class="mr-2 mb-1 patients-list_advance-chip"
          label
          small
          @click="removeFilterItem(filterItem)"
        >
          {{ filterItem.value }}
          <v-icon class="muted" color="primarylight1" small>fa-times</v-icon>
        </v-chip>
      </template>
      <v-tab-item class="patients-list_tabs_tab_item">
        <PatientsListCards
          :actions="actions.patients"
          :headers="patientsHeaders"
          :items="tablePatients"
          :loading="loading"
          :search="search"
          @onAssign="openAssignDialog"
        />
      </v-tab-item>
      <v-tab-item class="patients-list_tabs_tab_item">
        <PatientsListTable
          :actions="actions.invites"
          :headers="invitesHeaders"
          :items="tableInvites"
          :loading="loading"
          :search="search"
        />
      </v-tab-item>
    </v-tabs>
    <InvitePatientDialog v-model="dialog" @toggleDialog="toggleDialog" />
    <ActivationPatientProfileDialog v-model="dialogActivationProfile" @toggleDialog="toggleActivationProfileDialog" />
    <ImportPatientDialog v-if="showImport" v-model="importDialog" @toggleDialog="toggleImportDialog" />
    <AssignPatientDialog v-model="assignDialog" :patient="assignPatient" />
    <Confirm
      :cancelText="$t('general.dialog.cancel')"
      :confirmText="$t('patients.cancelDialog.confirm')"
      :dialog="cancelDialog"
      :text="$t('patients.cancelDialog.text')"
      :title="$t('patients.cancelDialog.title')"
      confirm-color="primary"
      @confirm="cancelInvitation"
      @toggleDialog="cancelDialog = false"
    />
    <SuccessDialog
      :dialog="successDialog"
      :text="$t(`patients.invite.resendSuccess`)"
      @on-close="successDialog = false"
    />
  </div>
</template>
<script>
import { DateTime } from "luxon";
import { mapActions, mapState } from "pinia";

import ActivationPatientProfileDialog from "@/components/ActivationPatientProfileDialog";
import AssignPatientDialog from "@/components/AssignPatientDialog/index";
import ImportPatientDialog from "@/components/ImportPatientDialog/index";
import InvitePatientDialog from "@/components/InvitePatientDialog/index";
import PatientsListCards from "@/components/Patients/Cards";
import Confirm from "@/components/shared/Confirm";
import DatePicker from "@/components/shared/DatePicker";
import PhoneInput from "@/components/shared/PhoneInput";
import SuccessDialog from "@/components/shared/SuccessDialog";
import BaseInput from "@/components/uikit/BaseInput";
import Select from "@/components/uikit/Select";
import { useAuthStore } from "@/pinia-store/auth";
import { useInvitesStore } from "@/pinia-store/invites";
import { usePatientsStore } from "@/pinia-store/patients";
import { RolesEnum } from "@/types/Roles.enum";
import { routesEnum } from "@/types/Routes.enum";
import { getStateName } from "@/utils/states.ts";
import { filterSearchOfPatients } from "@/utils/tableHeaders";

import { invitesHeaders, patientsHeaders } from "./constants.ts";
import PatientsListTable from "./Table.vue";

export default {
  name: "PatientsList",
  components: {
    PhoneInput,
    PatientsListCards,
    ActivationPatientProfileDialog,
    DatePicker,
    Select,
    BaseInput,
    PatientsListTable,
    InvitePatientDialog,
    ImportPatientDialog,
    AssignPatientDialog,
    Confirm,
    SuccessDialog,
  },
  data() {
    return {
      dialogActivationProfile: false,
      filterSelectedOptions: [],
      filter: {
        firstName: "",
        lastName: "",
        gender: "",
        email: "",
        phoneNumber: "",
        medicalRecord: "",
        birthDate: null,
      },
      successDialog: false,
      loading: false,
      patientsHeaders,
      invitesHeaders,
      dialog: false,
      importDialog: false,
      cancelDialog: false,
      assignDialog: false,
      assignPatient: null,
      invitationToCancel: null,
      tab: null,
      advanceSearchMenu: false,
      search: "",
      actions: {
        patients: [
          {
            callback: this.openAssignDialog,
            text: this.$t("patients.table.assignPatient"),
          },
          {
            callback: this.redirectToMedicalRecord,
            text: "Medical Record",
          },
        ],
        invites: [
          {
            callback: this.openCancelDialog,
            text: this.$t("patients.table.cancelInvitation"),
          },
          {
            callback: this.resendInvitation,
            text: this.$t("patients.table.resendInvitation"),
          },
        ],
      },
    };
  },
  computed: {
    ...mapState(useAuthStore, ["uid"]),
    ...mapState(useAuthStore, ["role"]),
    ...mapState(useInvitesStore, ["invites"]),
    ...mapState(usePatientsStore, ["patients", "searchValue", "advancedSearch"]),
    filterIsEmpty() {
      return (
        Object.keys(Object.fromEntries(Object.entries(this.filter).filter(([_, v]) => v && v.length))).length === 0
      );
    },
    genders() {
      const options = Object.entries(this.$t("general.genders")).map(([value, text]) => {
        return {
          text,
          value,
        };
      });
      options.unshift({
        text: this.$t("general.inputs.all"),
        value: "",
      });
      return options;
    },
    tablePatients() {
      return filterSearchOfPatients(
        this.search,
        this.patients.map((p) => {
          const practitionerName = p.practitioner
            ? `${p?.practitioner?.firstName || ""} ${p?.practitioner?.lastName || ""}`
            : "";
          return {
            ...p,
            state: getStateName(p.state),
            birthDate: p.birthDate ? DateTime.fromISO(p.birthDate).toFormat("M/dd/yyyy") : "",
            practitioner: practitionerName,
            checked: false,
          };
        }),
      );
    },
    tableInvites() {
      return this.invites.map((i) => ({
        ...i,
        name: `${i.firstName || ""} ${i.lastName || ""}`,
        status: i.isExpired ? this.$t("patients.table.expired") : this.$t("patients.table.pending"),
        checked: false,
      }));
    },
    showImport() {
      return this.role === RolesEnum.Owner || this.role === RolesEnum.Registrar;
    },
  },
  methods: {
    ...mapActions(useInvitesStore, ["sendInvite", "getAllInvites", "deleteInvitation", "resendInvite"]),
    ...mapActions(usePatientsStore, ["getAllPatients"]),
    ...mapActions(usePatientsStore, ["setSearch", "setAdvancedSearch"]),
    removeFilterItem(filterItem) {
      this.filter[filterItem.key] = "";
      this.submitFilterPatients();
    },
    onFilterBirthDateChange(value) {
      this.filter = { ...this.filter, birthDate: value };
    },
    onFilterGenderChange(value) {
      this.filter = { ...this.filter, gender: value };
    },
    onFilterChange(value, fieldName) {
      this.filter = { ...this.filter, [fieldName]: value };
    },
    onClearFilter() {
      this.filter = {
        firstName: "",
        lastName: "",
        gender: "",
        email: "",
        medicalRecord: "",
      };
    },
    toggleAdvanceSearchMenu() {
      this.advanceSearchMenu = !this.advanceSearchMenu;
    },
    toggleDialog() {
      this.dialog = !this.dialog;
    },
    toggleActivationProfileDialog() {
      this.dialogActivationProfile = !this.dialogActivationProfile;
    },
    toggleImportDialog() {
      this.importDialog = !this.importDialog;
    },
    openAssignDialog(item) {
      this.assignDialog = true;
      this.assignPatient = item;
    },
    openCancelDialog(item) {
      this.invitationToCancel = item;
      this.cancelDialog = true;
    },
    async cancelInvitation() {
      await this.deleteInvitation(this.invitationToCancel.id);
      this.cancelDialog = false;
    },
    async resendInvitation(item) {
      await this.resendInvite(item);
      this.successDialog = true;
    },
    async redirectToMedicalRecord(item) {
      await this.$router.push(`/practitioner/record/patient/${item.id}`);
    },
    async submitFilterPatients() {
      if (this.advanceSearchMenu) this.toggleAdvanceSearchMenu();
      await this.setAdvancedSearch(this.filter);
      this.loading = true;
      const items = Object.entries(this.filter).filter(([_, v]) => v && v.length > 0);
      this.filterSelectedOptions = items.map(([key, value]) => ({
        value: key === "gender" ? value.charAt(0).toUpperCase() + value.slice(1) : value,
        key,
      }));
      await this.getAllPatients({ ...this.filter, checkAuthProfile: true });
      this.loading = false;
    },
    async pushRegisterPatient() {
      await this.$router.push({ name: routesEnum.registerPatient });
    },
  },
  async mounted() {
    try {
      this.loading = true;
      await Promise.all([
        this.getAllInvites(this.role === RolesEnum.Practitioner ? { practitionerId: this.uid } : {}),
        this.getAllPatients(),
      ]);
    } catch (err) {
      console.error(err);
    }
    this.loading = false;
    this.search = this.searchValue;
    this.filter = this.advancedSearch;
    this.advanceSearchMenu = false;
  },
  watch: {
    tab(value) {
      if (value !== 0 && this.advanceSearchMenu) this.advanceSearchMenu = false;
    },
    search() {
      this.setSearch(this.search);
    },
  },
};
</script>
<style lang="scss">
.patients-list {
  .v-slide-group__prev,
  .v-slide-group__next {
    display: none !important;
  }

  padding: 0 30px 20px;
  @include tablet {
    padding: 0 14px;
  }
  @media screen and (min-width: 600px) {
    max-width: calc(100vw - 62px);
  }

  &_advance-chip {
    width: fit-content;
    margin-left: 10px;
    display: flex;
    align-self: center;

    &.mobile-size {
      display: inline-flex;
    }

    &:hover {
      cursor: pointer;
    }

    i {
      margin-left: 10px;
      color: rgba(0, 0, 0, 0.87);

      &:before {
        color: rgba(0, 0, 0, 0.87);
      }
    }
  }

  .v-slide-group__content {
    display: flex;
    align-items: center;
  }

  &_header {
    margin-top: 25px;
    margin-bottom: 5px;

    @media (max-width: 480px) {
      flex-direction: column;
      margin: 0;
    }

    &_actions {
      display: flex;
      align-items: flex-start;
    }

    &_block {
      display: flex;
      max-width: 100%;
      gap: 12px;
      @media (max-width: 980px) {
        max-width: 100%;
        flex-wrap: wrap;
      }

      .v-btn {
        box-shadow: none;
      }

      &_wide-input {
        max-width: 286px;

        .v-icon {
          font-size: 14px;
        }

        @include mobile {
          width: 100%;
          max-width: 100%;
        }

        .v-input__slot {
          box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.12) !important;
        }
      }
    }
  }

  &_tabs {
    .v-tabs-bar {
      border-bottom: 1px solid $primarylight;
    }

    &_tab {
      height: 100%;

      #text {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;

        color: $primaryblack;

        mix-blend-mode: normal;
      }

      &_item {
        padding: 0;
      }
    }
  }

  &_advance-search {
    &_title {
      font-weight: 500;
      font-size: 25px;
      line-height: 31px;
      color: $primaryblack;
    }

    &_close-btn {
      cursor: pointer;
      border: 1px solid $primarylight2;
      box-sizing: border-box;
      border-radius: 50%;
      position: absolute;
      top: -14px;
      right: -7px;
      height: 24px;
      justify-content: center;

      i {
        color: #d2d3e1;
        font-size: 6px !important;
        padding: 5px;
      }
    }

    &_row {
      display: flex;
      align-items: center;
      padding-top: 7px;
      padding-bottom: 7px;
      @include tablet {
        flex-direction: column;
        align-items: start;
      }

      .v-list-item__title {
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        color: $primaryblack2;
      }

      .col {
        padding: 0;
        @include tablet {
          max-width: 100%;
          flex-basis: 100%;
        }
      }

      .v-text-field {
        .v-input__control {
          .v-input__slot {
            margin-bottom: 0 !important;
          }
        }
      }
    }
  }
}

.success-dialog {
  display: flex !important;
  flex-direction: column;
  align-items: center;
  padding: 2rem;

  img {
    display: inline-block;
  }

  &_title {
    font-style: normal;
    font-weight: 500;
    font-size: 21px;
    line-height: 26px;
    text-align: center;
    word-break: break-word !important;
  }

  &_close-icon {
    top: 10px;
    right: 10px;
  }
}

.advance-search-dialog {
  .v-card {
    padding-bottom: 34px;
    padding-left: 16px;
    padding-right: 16px;

    .v-card__actions {
      .v-btn {
        text-transform: none;

        &:not(.primary) {
          color: $primaryblack2;
        }
      }
    }
  }

  @media only screen and (max-width: 600px) {
    position: fixed;
    top: 5% !important;
    margin: 0 auto !important;
    left: 0 !important;
    right: 0 !important;
    max-height: calc(100% - 10%);
    overflow-x: auto;
    max-width: 90%;
    .v-card__actions {
      flex-direction: column;

      button {
        margin-top: 16px;
        margin-left: 0 !important;
      }
    }
  }
}
</style>
