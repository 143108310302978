<template>
  <div class="full-width fill-height floating-channel">
    <div class="fill-height full-width d-flex flex-column">
      <div ref="floatingChatContainer" v-chat="{ always: true }" class="floating-chat flex-grow-1">
        <FloatingPreview v-if="!messagesComp.length" :text="$t('chat.preview.noMessages')" />
        <span v-for="msg in messagesComp" v-else :key="msg.index" class="border-radius-inherit">
          <div v-if="!attachmentsTab">
            <FloatingMessageRow
              v-if="!msg.attributes.isHidden"
              :is-floating-chat="isFloatingChat"
              :message="msg"
              :response-delegate="send"
              @action="action"
            />
          </div>
        </span>

        <FloatingMediaRow v-if="attachmentsTab" :channelId="channel.sid" />
        <FloatingStatusRow
          :connected="participantOnline"
          :is-f-a-q-message="showFAQ"
          :is-floating-chat="true"
          :participant-is-bot="channel.isAnyParticipantBot"
          :typing="isTypingIndicatorVisible"
        />
      </div>
      <div v-if="!isArchived" class="input flex-shrink-0 d-flex flex-column">
        <div v-if="files" class="mb-1 d-flex flex-wrap">
          <v-chip v-for="file in files" :key="file.name" class="mr-2 mb-1" color="primary" dark label small>
            {{ file.name }}
          </v-chip>
        </div>
        <div class="input-body d-flex">
          <div class="flex-grow-1">
            <v-textarea
              v-model="message"
              :placeholder="$t('chat.inputPlaceholder')"
              auto-grow
              class="input-body-textarea"
              dense
              flat
              hide-details
              rows="1"
              solo
              v-on:keydown="() => this.channel.typing()"
              v-on:keydown.enter.exact.prevent
              v-on:keyup.enter.exact="send(message, null)"
            />
          </div>
          <div class="input-body-actions d-flex flex-column flex-nowrap">
            <div class="input-body-actions-buttons">
              <input v-show="false" ref="uploads" multiple name="uploads" type="file" @change="updateFileLists" />
              <v-btn color="primary" icon @click="openFileUploads">
                <v-icon>mdi-paperclip</v-icon>
              </v-btn>
              <v-btn color="primary" icon @click="send(message, null)">
                <v-progress-circular v-if="sendLoading" color="primary" indeterminate size="23" width="3" />
                <v-icon v-else>mdi-send</v-icon>
              </v-btn>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Confirm
      :cancelText="$t('general.dialog.cancel')"
      :confirmText="$t('chat.archiveDialog.confirm')"
      :dialog="archiveDialog"
      :loading="archiveLoading"
      :text="$t('chat.archiveDialog.text')"
      :title="$t('chat.archiveDialog.title')"
      confirm-color="primary"
      @confirm="archive"
      @toggleDialog="closeArchiveDialog"
    />
  </div>
</template>

<script>
import { DateTime } from "luxon";
import { mapState } from "pinia";

import FloatingPreview from "@/components/FloatingChat/FloatingChatComponets/FloatingPreview";
import FloatingMediaRow from "@/components/FloatingChat/FloatingChatComponets/Rows/FloatingMediaRow";
import FloatingMessageRow from "@/components/FloatingChat/FloatingChatComponets/Rows/FloatingMessageRow";
import FloatingStatusRow from "@/components/FloatingChat/FloatingChatComponets/Rows/FloatingStatusRow";
import Confirm from "@/components/shared/Confirm";
import { useAuthStore } from "@/pinia-store/auth";
import { scrollToBottom } from "@/utils/scrollToBottom";

export default {
  watch: {
    participant(newVal) {
      if (newVal !== null) {
        this.participantStatusSubscription?.unsubscribe();
        this.participantStatusSubscription = this.channel.participant
          .getChannelStatusObservable(this.channel.sid)
          .subscribe((val) => {
            this.participantOnline = val === "online";
          });
      } else {
        this.participantStatusSubscription?.unsubscribe();
        this.participantStatusSubscription = null;
      }
    },
    channelInstance() {
      this.messages = this.channel.messages ?? [];
      this.scroll();
    },
  },
  mounted: async function () {
    this.messages = this.channel.messages ?? [];
    if (this.channel.participant) {
      const obs = this.channel.participant.getChannelStatusObservable(this.channel.sid);
      this.participantOnline = obs.getValue();
      this.participantStatusSubscription = obs.subscribe((val) => {
        this.participantOnline = val === "online";
      });
    }

    this.$nextTick(function () {
      this.scroll();
    });
  },
  name: "FloatingChannel",
  props: {
    channel: Object,
    active: {
      type: Boolean,
      required: true,
    },
  },
  components: {
    FloatingMediaRow,
    FloatingStatusRow,
    FloatingMessageRow,
    FloatingPreview,
    Confirm,
  },
  data() {
    return {
      message: "",
      files: null,
      sendLoading: false,
      archiveDialog: false,
      archiveLoading: false,
      attachmentsTab: false,
      role: true, //todo investigate how that was determined in previous implementation
      messages: [],
      popupBoxVisible: true,
      participantOnline: true,
      participantStatusSubscription: null,
      showFAQ: false,
      typingVisible: false,
    };
  },
  methods: {
    action(action) {
      this.$emit("action", action);
    },
    openFileUploads() {
      this.$refs.uploads.click();
    },
    updateFileLists() {
      this.files = [];
      for (const file of this.$refs.uploads.files) {
        this.files.push(file);
      }
    },
    scroll() {
      const element = this.$refs.floatingChatContainer;
      scrollToBottom(element, false);
    },
    async send(text, value, respondsTo) {
      this.sendLoading = true;
      await this.channel.sendMessage(text, value, respondsTo, this.files);
      this.message = "";
      this.sendLoading = false;
    },
    closeArchiveDialog() {
      this.archiveDialog = false;
    },
    async archive() {
      this.archiveLoading = true;
      await this.channel.archive();
      this.archiveLoading = false;
      this.archiveDialog = false;
    },
  },
  computed: {
    ...mapState(useAuthStore, ["uid", "isLoggedIn"]),
    isFloatingChat() {
      return this.channel.channel ? this.channel.channel.attributes.isFloatingChat : true;
    },
    isTypingIndicatorVisible() {
      return this.channel.isTyping || this.typingVisible;
    },
    displayName() {
      const displayName = this.channel?.participant?.displayName;
      return displayName ? displayName : "Axis HealthBot";
    },
    messagesComp() {
      // //todo review if needed like that
      const messages = this.messages ?? [];
      if (!messages || messages.length === 0) {
        return [];
      }
      let currAuthor = null;
      for (let i = 0; i < messages.length; i++) {
        if (messages[i].author !== currAuthor) {
          messages[i].head = true;
          currAuthor = messages[i].author;
          if (i > 0) {
            messages[i - 1].last = true;
          }
        } else if (
          i > 0 &&
          DateTime.fromJSDate(messages[i].dateCreated)
            .diff(DateTime.fromJSDate(messages[i - 1].dateCreated), "minutes")
            .as("minutes") > 10
        ) {
          messages[i].head = true;
          messages[i - 1].last = true;
        }
      }
      messages[messages.length - 1].last = true;
      return messages;
    },
    isArchived() {
      return this.channel?.attributes?.archive === true;
    },
  },
};
</script>

<style lang="scss" scoped>
.floating-channel {
  height: calc(100% - 50px);
  overflow-x: auto;
}

.open-in-new-button {
  width: 25px;
  height: 25px;
  position: relative;
  margin: 13px;

  &-icon {
  }
}

.header {
  height: 75px;

  &__actions-menu {
    .v-list {
      padding-top: 0;
      padding-bottom: 0;
    }

    text-align: left;
    background: #ffffff;
    border: 1px solid #eeeff7;
    box-shadow: none;
    box-sizing: border-box;
    border-radius: 4px;

    .v-list-item:not(:last-child) {
      border-bottom: 1px solid #eeeff7;
    }

    .v-list-item:hover {
      cursor: pointer;
      background: rgba(34, 148, 242, 0.08);
    }
  }

  &-c1 {
    overflow: hidden;
  }

  &-body {
    margin: 0 auto;
    //margin-left: 50px;
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 20px;
    /* identical to box height, or 125% */

    text-align: center;

    color: #000000;
  }
}

.floating-chat {
  border-bottom: 1px solid #efeff0;
  border-top: 1px solid #efeff0;
  overflow-y: scroll;
  overflow-x: hidden;
  padding: 0 22px 22px 22px;
  scroll-padding: 22px;
  flex-grow: 1;
  height: 100%;
}

.input {
  padding: 10px;
  min-height: 61px;

  &-body {
    border: 1px solid #d2d3e1;
    box-sizing: border-box;
    border-radius: 5px;
    overflow: hidden;

    &-textarea {
      //margin-bottom: -25px;
    }

    &-actions {
      width: 80px;
      justify-content: center;

      &-buttons {
        margin-bottom: 2px;
      }
    }
  }
}

.hidden {
  display: none !important;
}

.button-wrapper {
  display: flex;
  justify-content: flex-end;
  flex-flow: row-reverse nowrap;
}

.infobox {
  align-self: flex-end;
  position: absolute;
  display: flex;
  right: 330px;
  bottom: 570px;
  flex-flow: row nowrap;
  justify-content: center;
  background: rgba(32, 33, 34, 0.7);
  border-radius: 4px;

  &-text {
    align-self: center;
    margin: 4px 6px 5px 6px;
    color: white;
    white-space: nowrap;

    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
  }
}
</style>
