import { apiRequest } from "@/api/apiRequest";

const add = async (body: Record<any, any> = {}, patientId: string) => {
  const { data } = await apiRequest.post(`/patient/${patientId}/conditions`, body);
  return data;
};

const destroy = async (allergyId: string, patientId: string) => {
  const { data } = await apiRequest.delete(`/patient/${patientId}/conditions/${allergyId}`);
  return data;
};

const search = async (name: string) => {
  const { data } = await apiRequest.get(`/conditions/search`, { params: { name } });
  return data;
};
const getSmokingBehavior = async () => {
  const { data } = await apiRequest.get(`/terminology/smoking-behavior`);
  return data;
};
const getPatientConditions = async (patientId: string) => {
  const { data } = await apiRequest.get(`/patient/${patientId}/conditions`);
  return data;
};
const getConditions = async (filter = {}) => {
  const { data } = await apiRequest.get(`/terminology/conditions`, { params: filter });
  return data;
};
export const ConditionsApi = {
  getConditions,
  getPatientConditions,
  search,
  getSmokingBehavior,
  add,
  destroy,
};
