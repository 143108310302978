<template>
  <div>
    <v-skeleton-loader
      v-if="appointmentInformationLoading"
      :boilerplate="true"
      class="px-3 mx-0 mt-6 full-width"
      elevation="2"
      type="list-item-avatar, divider, card-heading, list-item-three-line, list-item-three-line, list-item-two-line, actions"
    >
    </v-skeleton-loader>
    <div
      v-if="
        (!_appointmentInformation || Object.keys(_appointmentInformation).length === 0) &&
        !appointmentInformationLoading
      "
    >
      <v-card-text class="text--secondary p-0 up_next--divider mb-6">Up next</v-card-text>
      <div class="pa-5 text-center">
        <img alt="No Items" class="mx-auto" src="@/assets/no-items.svg" />
        <p class="heading-4 mt-5">There are no next appointments today</p>
        <p class="text--secondary">Today is empty. Future visits will be displayed here after scheduling.</p>
        <OutlinedButton
          color="primary"
          style="color: #2196f3; opacity: 1; border: 1px solid #2196f3"
          text="Schedule a New Visit"
          @onClick="$emit('openScheduleDialog')"
        />
      </div>
    </div>
    <template
      v-if="
        _appointmentInformation && Object.keys(_appointmentInformation).length !== 0 && !appointmentInformationLoading
      "
    >
      <v-card-text
        v-if="appointmentInformationTab === 'next' || _appointmentInformation.id === nextAppointment.id"
        class="text--secondary p-0 up_next--divider mb-6"
        >Up next
      </v-card-text>
      <v-card-text v-else class="text--secondary p-0 up_next--divider mb-6 pointer" @click="backToNextAppointment">
        <v-icon>mdi-chevron-left</v-icon>
        Back to Upcoming Appointment
      </v-card-text>
      <div class="px-4 pb-4">
        <v-row v-if="isCancelled" align="center" class="mx-0 pb-3">
          <div class="warn-status">
            <img alt="warning" class="sick-modal__icon" src="@/assets/icons/warn-sign.svg" />
            <span> The visit was cancelled </span>
          </div>
        </v-row>
        <v-row align="center" class="mx-0 pb-3">
          <Avatar v-if="_appointmentInformation.practitionerId" :userId="_appointmentInformation.practitionerId" />
          <Avatar v-else src="@/assets/no-avatar.svg" />

          <div class="ml-2">
            <p class="text--secondary mb-0">
              {{ _appointmentInformation.practitionerQualification }}
            </p>
            <h4>
              {{ `Dr. ${_appointmentInformation.participant[0].actor.display}` }}
            </h4>
          </div>
        </v-row>
        <v-divider class="full-width my-2"></v-divider>
        <v-alert
          v-if="_appointmentInformation.status === 'pending'"
          dense
          icon="mdi-alert-outline"
          style="font-size: 11px"
          text
          type="error"
        >
          <template v-if="Boolean(isNeedConfirmByPractitioner(_appointmentInformation.participant))">
            This visit hasn't been confirmed yet
          </template>
          <template v-else>
            The visit hasn't been confirmed by {{ needConfirmBy(_appointmentInformation.participant) }} yet
          </template>
        </v-alert>
        <div>
          <p class="text--secondary mb-0">Patient</p>
          <v-card-title class="pa-0">
            {{ _appointmentInformation.participant[1].actor.display }}
            <img
              :src="require('@/assets/icons/folder.svg')"
              alt="Patient record"
              class="ml-2 pointer"
              title="Patient record"
              @click="gotoPatient(_appointmentInformation.patientId)"
            />
          </v-card-title>
          <a :href="`mailto: ${_appointmentInformation.patientEmail}`" class="text-decoration-none mt-0">
            {{ _appointmentInformation.patientEmail }}
          </a>
          <a
            v-if="_appointmentInformation.phoneNumber"
            :href="`tel:${_appointmentInformation.phoneNumber}`"
            class="text-decoration-none d-block mt-0"
            >{{ _appointmentInformation.phoneNumber }}</a
          >
          <a
            v-if="_appointmentInformation.homePhoneNumber"
            :href="`tel:${_appointmentInformation.homePhoneNumber}`"
            class="text-decoration-none d-block mt-0"
            >{{ _appointmentInformation.homePhoneNumber }}</a
          >
        </div>
        <p v-if="_appointmentInformation.patientBirthDate" class="birthDate">
          {{ formatDate(_appointmentInformation.patientBirthDate).format("MM/DD/YYYY") }}
          <span>
            <v-icon> mdi-circle-small</v-icon>
            {{ age(_appointmentInformation.patientBirthDate) }} years old</span
          >
        </p>
        <p class="text--secondary mt-3 mb-0">Zip Code</p>
        <p class="mt-0">{{ _appointmentInformation.patientZipCode || "" }}</p>
        <div>
          <p class="text--secondary mt-3 mb-0">
            {{
              `${formatDate(_appointmentInformation.start).format("l")}, ${formatDate(
                _appointmentInformation.start,
              ).format("dddd")}`
            }}
          </p>
          <p class="mt-0">
            {{
              `${formatDate(_appointmentInformation.start).format("HH:mm")} - ${formatDate(
                _appointmentInformation.end,
              ).format("HH:mm")}`
            }}
          </p>
        </div>
        <div>
          <p class="text--secondary mb-0">Chief Complaint</p>
          <p class="mt-0">
            {{ translateChiefComplaint(_appointmentInformation.chiefComplaint) }}
          </p>
        </div>
        <div>
          <p class="text--secondary mb-0">Visit Reason Note</p>
          <p>{{ _appointmentInformation.complaintDescription }}</p>
        </div>
        <div>
          <p class="text--secondary mb-0">Visit type</p>
          <p>{{ visitType }}</p>
        </div>
        <div v-if="_appointmentInformation.pharmacy">
          <p class="text--secondary mb-0">Pharmacy</p>
          <p class="mt-0 mb-0">
            {{ _appointmentInformation.pharmacy.name }}
          </p>
          <p class="mt-0">
            {{ _appointmentInformation.pharmacy.address ? `${_appointmentInformation.pharmacy.address},` : "" }}
            {{ _appointmentInformation.pharmacy.city ? `${_appointmentInformation.pharmacy.city},` : "" }}
            {{ _appointmentInformation.pharmacy.state ? `${_appointmentInformation.pharmacy.state} ` : "" }}
            {{ _appointmentInformation.pharmacy.zipCode ? `${_appointmentInformation.pharmacy.zipCode}` : "" }}
          </p>
        </div>
        <v-row class="mx-0 full-width footer-actions">
          <v-btn v-if="allowCancel" color="red" outlined @click="$emit('cancel')">Cancel Visit</v-btn>
          <v-btn
            v-if="_appointmentInformation.status !== 'pending' && _appointmentInformation.status !== 'cancelled'"
            color="blue"
            outlined
            @click="$emit('viewDetails')"
          >
            View Details
          </v-btn>
          <OutlinedButton
            v-if="isNeedConfirmByPractitioner(_appointmentInformation.participant)"
            additionalClassName="outlined-btn__green"
            text="Confirm"
            @onClick="acceptInvitePractitioner"
          />
          <OutlinedButton
            additionalClassName="outlined-btn__green"
            icon="mdi-chevron-left"
            text="Previous"
            @onClick="gotoPreviousAppointment"
          />
          <OutlinedButton
            additionalClassName="outlined-btn__green"
            icon-right="mdi-chevron-right"
            text="Next"
            @onClick="gotoNextAppointment"
          />
        </v-row>
      </div>
    </template>
  </div>
</template>

<script>
import { mapState as mapPiniaState } from "pinia";
import { mapActions as mapPiniaActions } from "pinia";

import { AppointmentsApi } from "@/api/appointment";
import Avatar from "@/components/Avatar/Index";
import OutlinedButton from "@/components/uikit/OutlinedButton.vue";
import { snackBarEventBus, snackBarEventName } from "@/eventBuses/snackBar.eventBus";
import { useAppointmentsStore } from "@/pinia-store/appointments";
import { useAuthStore } from "@/pinia-store/auth";
import { AppointmentInformationTab } from "@/pinia-store/interfaces/CancelAppointment";
import { AppointmentStatusEnum } from "@/types/AppointmentQueryFilter";
import { RolesEnum } from "@/types/Roles.enum";
import { routesEnum } from "@/types/Routes.enum";

export default {
  name: "UpcomingAppointment",
  components: { OutlinedButton, Avatar },
  props: ["appointmentInformation"],
  data() {
    return {
      tab: "next",
      nextAppointment: null,
      previousAppointment: null,
      timeout: null,
    };
  },
  watch: {
    isLoggedIn(val) {
      if (!val && this.timeout) {
        clearInterval(this.timeout);
      }
    },
  },
  computed: {
    ...mapPiniaState(useAuthStore, ["timeZone", "isLoggedIn"]),
    ...mapPiniaState(useAppointmentsStore, ["appointmentInformationTab", "appointmentInformationLoading"]),
    isCancelled() {
      return this._appointmentInformation?.status === AppointmentStatusEnum.cancelled;
    },
    allowCancel() {
      return (
        this._appointmentInformation?.status !== AppointmentStatusEnum.cancelled &&
        this.$moment().isBefore(this.$moment(this._appointmentInformation.start))
      );
    },
    _appointmentInformation() {
      return this.appointmentInformation || {};
    },
    visitType() {
      return this.appointmentInformation?.virtualService === "video" ? "Video visit" : "Request a callback";
    },
  },
  methods: {
    ...mapPiniaActions(useAppointmentsStore, [
      "getAppointmentInformation",
      "setAppointmentInformationTab",
      "setAppointmentInformationLoading",
    ]),
    async acceptInvitePractitioner() {
      const id = this._appointmentInformation.id;
      const link = `/practitioner/encounters/schedule/${id}/cancel`;
      await this.$router.push({ path: link });
    },
    gotoPatient(id) {
      return this.$router.push({ name: routesEnum.patientMedicalRecord, params: { patientId: id } });
    },
    backToNextAppointment() {
      this.setAppointmentInformationLoading(true);
      this.setAppointmentInformationTab(AppointmentInformationTab.next);
      this.getAppointmentInformation(this.nextAppointment.id);
    },
    async gotoNextAppointment() {
      this.setAppointmentInformationTab(AppointmentInformationTab.selected);
      this.setAppointmentInformationLoading(true);
      this.nextAppointment = await AppointmentsApi.getNext(this._appointmentInformation.id);
      if (this.nextAppointment.id) {
        await this.getAppointmentInformation(this.nextAppointment.id);
      } else {
        snackBarEventBus.$emit(snackBarEventName, {
          message: "No more appointments to show!",
          type: "info",
        });
      }
      this.setAppointmentInformationLoading(false);
    },
    async gotoPreviousAppointment() {
      this.setAppointmentInformationTab(AppointmentInformationTab.selected);
      this.setAppointmentInformationLoading(true);
      this.previousAppointment = await AppointmentsApi.getPrevious(this._appointmentInformation.id);
      if (this.previousAppointment.id) {
        await this.getAppointmentInformation(this.previousAppointment.id);
      } else {
        snackBarEventBus.$emit(snackBarEventName, {
          message: "No more appointments to show!",
          type: "info",
        });
      }
      this.setAppointmentInformationLoading(false);
    },
    age(date) {
      return this.$moment().diff(this.$moment(date), "years");
    },
    formatDate(date = undefined) {
      return this.$moment.tz(date, this.timeZone);
    },
    translateChiefComplaint(val) {
      return this.$t("general.chiefComplaints")[val] || val || "-";
    },
    needConfirmBy(participants) {
      const tentative = participants.find((participant) => participant.status === "tentative");
      if (!tentative?.actor) return "";
      return tentative.actor.reference.split("/")[0].toLowerCase();
    },
    isNeedConfirmByPractitioner(participants) {
      const tentative = (participants || []).find((participant) => participant.status === "tentative");
      if (!tentative?.actor) return false;
      return tentative.actor.reference.split("/")[0].toLowerCase() === RolesEnum.Practitioner;
    },
  },
  beforeDestroy() {
    if (this.timeout) clearInterval(this.timeout);
  },
  async mounted() {
    this.setAppointmentInformationLoading(true);
    this.nextAppointment = await AppointmentsApi.getNext();
    if (this.nextAppointment.id) {
      await this.getAppointmentInformation(this.nextAppointment.id);
    }
    this.setAppointmentInformationLoading(false);

    this.timeout = setInterval(async () => {
      this.nextAppointment = await AppointmentsApi.getNext();
      if (this.nextAppointment.id && this.appointmentInformationTab === AppointmentInformationTab.next) {
        await this.getAppointmentInformation(this.nextAppointment.id);
      }
    }, 10000);
  },
};
</script>

<style lang="scss" scoped>
.warn-status {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 8px;
  gap: 10px;
  background: linear-gradient(0deg, rgba(236, 61, 57, 0.1), rgba(236, 61, 57, 0.1)), #ffffff;
  border-radius: 4px;

  span {
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    line-height: 13px;
    color: #e53935;
    opacity: 0.9;
  }
}

.birthDate {
  color: #33343e;
  font-weight: 500;

  span {
    color: #25233a;
    opacity: 0.4;
  }
}

.footer-actions {
  display: flex;
  justify-content: flex-end;
  gap: 14px;
  @include tablet {
    gap: 8px;
  }
}
</style>
