import "firebase/auth";

import * as firebase from "firebase/app";
import { defineStore } from "pinia";

import { AdminAPI } from "@/api/admin";
import { OwnersAPI } from "@/api/owners";
import { PatientsApi } from "@/api/patients";
import { PractitionersAPI } from "@/api/practitioners";
import { RegistrarsAPI } from "@/api/registrars";
import { useAuthStore } from "@/pinia-store/auth";

export const useProfileSettingsStore = defineStore({
  id: "profileSettings",
  state: () => state,
  actions: {
    setAvatar(payload: any) {
      this.avatar = payload;
    },
    setKeyPractitionerProfile(payload: { value: any; fieldName: any }) {
      const { value, fieldName } = payload;
      this.practitionerSettings[fieldName] = value;
    },
    setMedicalInformation(payload: { value: any; fieldName: any }) {
      const { value, fieldName } = payload;
      this.patientSettings.medicalInformation[fieldName] = value;
    },
    async resetPassword({ oldPassword, newPassword }: { oldPassword: string; newPassword: string }) {
      const authStore = useAuthStore();
      const { email, rememberMe } = authStore;
      await authStore.submitLogin({ email, rememberMe, password: oldPassword });
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      await firebase.auth().currentUser!.updatePassword(newPassword);
    },
    async uploadAvatar() {
      const authStore = useAuthStore();
      const { avatar } = authStore;
      if (!avatar) return null;
      const { uid, role } = authStore;
      const data = new FormData();
      data.append("file", avatar);
      let avatarLink;
      switch (role) {
        case "practitioner":
          avatarLink = await PractitionersAPI.avatarUpload(uid, data);
          break;
        case "patient":
          avatarLink = await PatientsApi.avatarUpload(uid, data);
          break;
        case "admin":
          avatarLink = await AdminAPI.adminAvatarUpload(uid, data);
          break;
        case "owner":
          avatarLink = await OwnersAPI.avatarUpload(uid, data);
          break;
        case "registrar":
          avatarLink = await RegistrarsAPI.avatarUpload(uid, data);
          break;
      }
      await authStore.actualizeUserInfo();
      authStore.$patch({ photoURL: avatarLink });
    },
    async getPractitionerSettings() {
      const authStore = useAuthStore();

      const { uid } = authStore;
      const [settings, profile] = await Promise.all([
        PractitionersAPI.practitionerGetSettings(uid),
        PractitionersAPI.getPractitionerById(uid),
      ]);
      this.practitionerSettings = {
        id: profile.id,
        firstName: profile.firstName,
        lastName: profile.lastName,
        email: profile.email,
        qualification: profile.qualification,
        timeZone: settings.timeZone,
        locale: settings.locale,
        clinicalRole: settings.clinicalRole,
        isAutoTimeZone: settings.isAutoTimeZone,
        isAutoAccept: settings.isAutoAccept,
        acceptTasks: settings.acceptTasks,
        acceptPediatricTasks: settings.acceptPediatricTasks,
        acceptAdultTasks: settings.acceptAdultTasks,
        dosespotId: settings.dosespotId,
        workDays: settings.workDays,
        isTestingUser: profile.isTestingUser,
        availableToOtherPatients: settings.availableToOtherPatients,
      };
      authStore.$patch({ timeZone: settings.timeZone });
      authStore.$patch({ isAutoTimeZone: settings.isAutoTimeZone });
    },
    async getOwnerSettings() {
      const authStore = useAuthStore();

      const { uid } = authStore;
      const [settings, profile] = await Promise.all([OwnersAPI.ownerGetSettings(uid), OwnersAPI.getOwnerById(uid)]);
      this.ownerSettings = {
        firstName: profile.firstName,
        lastName: profile.lastName,
        email: profile.email,
        timeZone: settings.timeZone,
        locale: settings.locale,
      };
    },
    async getAdminSettings() {
      const authStore = useAuthStore();
      const { uid } = authStore;
      const [settings, profile] = await Promise.all([AdminAPI.adminGetSettings(uid), AdminAPI.getAdminById(uid)]);
      this.adminSettings = {
        firstName: profile.firstName,
        lastName: profile.lastName,
        email: profile.email,
        timeZone: settings.timeZone,
        locale: settings.locale,
        slotDuration: settings.slotDuration,
        scheduleHorizonLimit: settings.scheduleHorizonLimit,
        scheduleToStartLimit: settings.scheduleToStartLimit,
        scheduleToCancelLimit: settings.scheduleToCancelLimit,
      };
    },
    async getRegistrarSettings() {
      const authStore = useAuthStore();
      const { uid } = authStore;
      const [settings, profile] = await Promise.all([
        RegistrarsAPI.registrarGetSettings(uid),
        RegistrarsAPI.getRegistrarById(uid),
      ]);

      this.registrarSettings = {
        firstName: profile.firstName,
        lastName: profile.lastName,
        email: profile.email,
        timeZone: settings.timeZone,
        locale: settings.locale,
      };
    },
    async getBodyHeights() {
      const authStore = useAuthStore();

      const { uid } = authStore;
      const bodyHeights = await PatientsApi.getBodyHeights(uid);
      if (bodyHeights) {
        this.bodyHeights = bodyHeights;
      }
    },
    async getBodyWeights() {
      const authStore = useAuthStore();

      const { uid } = authStore;
      const data = await PatientsApi.getBodyWeights(uid);
      if (data) {
        this.bodyWeights = data;
      }
    },
    async getSmokingBehaviors() {
      const authStore = useAuthStore();

      const { uid } = authStore;
      const data = await PatientsApi.getSmokingBehaviors(uid);
      if (data) {
        this.smokingBehaviors = data;
      }
    },
    async getPatientSettings() {
      const authStore = useAuthStore();

      const { uid } = authStore;
      const [profile, settings, careTeam] = await Promise.all([
        PatientsApi.patientGetProfile(uid),
        PatientsApi.getSettings(uid),
        PatientsApi.getPatientCareTeam(uid),
      ]);

      const { medicalInformation, generalInformation, locale } = settings;
      const fullPatientSettings = {
        ...state.patientSettings,
        id: uid,
        isTestingUser: profile.isTestingUser,
        firstName: profile.firstName,
        lastName: profile.lastName,
        birthDate: profile.birthDate,
        email: profile.email,
        state: profile.state,
        gender: profile.gender,
        address: profile.address,
        homePhoneNumber: profile.homePhoneNumber,
        timeZone: settings.timeZone,
        isAutoTimeZone: settings.isAutoTimeZone,
        practitioner: profile.practitioner,
        phoneNumber: profile.phoneNumber,
        city: profile.city,
        postalCode: profile.postalCode,
        ...(medicalInformation ? { medicalInformation } : {}),
        ...(generalInformation ? { generalInformation } : {}),
        locale,
        careTeam,
      };
      this.patientSettings = fullPatientSettings;
      authStore.$patch({ timeZone: settings.timeZone });
      authStore.$patch({ isAutoTimeZone: settings.isAutoTimeZone });
      return fullPatientSettings;
    },
    async saveOwnerChanges(settings: { firstName: any; lastName: any; locale: any }) {
      const authStore = useAuthStore();
      const { uid } = authStore;
      const { firstName, lastName, locale } = settings;

      await OwnersAPI.updateProfile(uid, {
        name: { firstName, lastName },
      });
      await OwnersAPI.ownerUpdateSettings(uid, {
        locale,
      });
      await this.uploadAvatar();
      this.ownerSettings = settings;
    },
    async saveAdminChanges(settings: {
      firstName: any;
      lastName: any;
      locale: any;
      slotDuration: any;
      scheduleHorizonLimit: any;
      scheduleToStartLimit: any;
      scheduleToCancelLimit: any;
    }) {
      const authStore = useAuthStore();

      const { uid } = authStore;
      const {
        firstName,
        lastName,
        locale,
        slotDuration,
        scheduleHorizonLimit,
        scheduleToStartLimit,
        scheduleToCancelLimit,
      } = settings;

      await AdminAPI.adminUpdateProfile(uid, {
        name: { firstName, lastName },
      });
      await AdminAPI.adminUpdateSettings(uid, {
        locale,
        slotDuration,
        scheduleHorizonLimit,
        scheduleToStartLimit,
        scheduleToCancelLimit,
      });
      await this.uploadAvatar();
      this.adminSettings = settings;
    },
    async saveRegistrarChanges(settings: { firstName: any; lastName: any; locale: any; timeZone: any }) {
      const authStore = useAuthStore();

      const { uid } = authStore;
      const { firstName, lastName, locale, timeZone } = settings;

      await RegistrarsAPI.updateProfile(uid, {
        name: { firstName, lastName },
      });
      await RegistrarsAPI.registrarUpdateSettings(uid, {
        locale,
        timeZone,
      });
      await this.uploadAvatar();
      this.registrarSettings = settings;
    },
    async savePractitionerChanges(settings: {
      timeZone: any;
      dosespotId?: any;
      firstName?: any;
      lastName?: any;
      locale?: any;
      isAutoTimeZone?: any;
      isAutoAccept?: any;
      clinicalRole?: any;
      qualification?: any;
      workDays?: any;
      acceptPediatricTasks?: any;
      acceptAdultTasks?: any;
      acceptTasks?: any;
      availableToOtherPatients?: any;
    }) {
      const authStore = useAuthStore();

      const { uid } = authStore;
      const {
        dosespotId,
        firstName,
        lastName,
        locale,
        timeZone,
        isAutoTimeZone,
        isAutoAccept,
        clinicalRole,
        qualification,
        workDays,
        acceptPediatricTasks,
        acceptAdultTasks,
        acceptTasks,
        availableToOtherPatients,
      } = settings;
      await PractitionersAPI.updateProfile(uid, {
        name: { firstName, lastName },
        qualification,
      });
      await PractitionersAPI.practitionerUpdateSettings(uid, {
        availabilitySlots: undefined,
        locale,
        acceptTasks,
        acceptPediatricTasks,
        acceptAdultTasks,
        dosespotId,
        workDays,
        timeZone,
        isAutoTimeZone,
        isAutoAccept,
        clinicalRole,
        availableToOtherPatients,
      });
      await this.uploadAvatar();
      this.practitionerSettings = settings;
      authStore.$patch({ timeZone: settings.timeZone });
    },
    async savePatientChanges(settings: {
      profile: any;
      generalInformation: any;
      locale: any;
      timeZone: any;
      isAutoTimeZone: any;
    }) {
      const authStore = useAuthStore();

      const { uid } = authStore;
      const { medicalInformation } = this;
      const { profile, generalInformation, locale, timeZone, isAutoTimeZone } = settings;
      await PatientsApi.updateProfile(uid, profile);

      await PatientsApi.updateSettings(uid, {
        medicalInformation,
        generalInformation,
        locale,
        timeZone,
        isAutoTimeZone,
      });
      await this.uploadAvatar();
    },
    async createPatientSettingsCareTeam(payload: {
      patientId: string;
      name: string;
      address: string;
      city: string;
      type: string;
    }) {
      const authStore = useAuthStore();

      const { uid } = authStore;
      return await PatientsApi.createPatientCareTeam(
        payload.patientId ? payload.patientId : uid,
        payload.name,
        payload.address,
        payload.city,
        payload.type,
      );
    },

    async changeCareTeam(payload: { prevId: string; id: string }) {
      const authStore = useAuthStore();

      const { uid } = authStore;
      await PatientsApi.changeCareTeam(uid, payload.prevId, payload.id);
    },
  },
  getters: {
    medicalInformation: (state) => state.patientSettings.medicalInformation,
    generalInformation: (state) => state.patientSettings.generalInformation,
    locale: (state) => state.practitionerSettings?.locale || state.ownerSettings.locale,
  },
});

import { GenderEnum } from "@/types/PractitionerResponseDto";

export const state = {
  patientSettings: {
    id: "",
    firstName: "",
    lastName: "",
    birthDate: "",
    state: "",
    address: "",
    timeZone: "",
    isAutoTimeZone: true,
    email: "",
    gender: GenderEnum.Unknown,
    phoneNumber: "",
    homePhoneNumber: "",
    city: "",
    generalInformation: {
      heightHistory: [],
      weightHistory: [],
      smokingBehaviorHistory: [],
    },
    practitioner: {
      email: "",
      firstName: "",
      gender: GenderEnum.Unknown,
      id: "",
      lastName: "",
      photoURL: "",
      qualification: "",
      registrars: [],
      state: "",
      timeZone: "",
      isAutoTimeZone: true,
      availableToOtherPatients: true,
    },
    medicalInformation: {
      pmh: [],
      medications: [],
      allergies: [],
    },
    careTeam: [],
  },
  allergies: {},
  practitionerSettings: {
    id: "",
    firstName: "",
    lastName: "",
    email: "",
    timeZone: "",
    locale: "",
    isAutoTimeZone: false,
    isAutoAccept: true,
    workDays: [],
    qualification: "",
  },
  ownerSettings: {
    firstName: "",
    lastName: "",
    email: "",
    timeZone: "",
    locale: "",
  },
  adminSettings: {
    firstName: "",
    lastName: "",
    email: "",
    timeZone: "",
    locale: "",
  },
  registrarSettings: {
    firstName: "",
    lastName: "",
    email: "",
    timeZone: "",
    locale: "",
  },
  avatar: null,
};
