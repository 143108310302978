import { EncounterStatusEnum } from "@/types/EncounterStatusEnum";

export type EncounterStatus = "planned" | "in-progress" | "finished" | "cancelled" | "onleave" | "pending";

export enum EncounterReasonCodeText {
  pended = "pended-note",
  draft = "draft",
}

export interface EncounterQueryPended {
  reasonCode: EncounterReasonCodeText;
  practitionerId?: string;
}

export interface EncounterQueryFilterDto {
  practitionerId?: string;
  patientId?: string;
  status?: EncounterStatusEnum | EncounterStatusEnum[];
  past?: boolean;
  future?: boolean;
  includeDependents?: boolean;
  date?: string;
  startDate?: string;
  endDate?: string;
  _count?: number;
}
