<template>
  <v-row class="visit-room-content patient-visit-note-wrap relative" no-gutters>
    <v-skeleton-loader
      v-if="isLoading"
      type="list-item-avatar, divider, list-item-three-line, card-heading, image"
      width="100%"
    ></v-skeleton-loader>
    <v-col v-else cols="12" sm="12">
      <SignedVisitNote v-if="!userIsPatient" :item="item" />
      <SignedVisitNotePatient v-else :item="item" />
    </v-col>
  </v-row>
</template>

<script>
import { mapState } from "pinia";

import { EncountersAPI } from "@/api/encounters";
import { useAuthStore } from "@/pinia-store/auth";
import SignedVisitNote from "@/views/Practitioner/SignedVisit/SignedVisitNote";
import SignedVisitNotePatient from "@/views/Practitioner/SignedVisitPatient/SignedVisitNotePatient.vue";

export default {
  name: "PatientVisitNote",
  components: { SignedVisitNotePatient, SignedVisitNote },
  data() {
    return {
      isLoading: true,
      item: {},
    };
  },
  computed: {
    ...mapState(useAuthStore, ["userIsPatient"]),
  },
  methods: {
    async getVisitNote() {
      const { encounterId } = this.$route.params;
      this.item = await EncountersAPI.getDocument(encounterId);
      this.isLoading = false;
    },
  },
  async mounted() {
    await this.getVisitNote();
  },
};
</script>

<style lang="scss" scoped>
.patient-visit-note-wrap {
  background-color: #fafafd;
}
</style>
