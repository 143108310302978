<template>
  <div :class="status.className" class="ap-list-item">
    <div class="ap-list-item-wrap">
      <div class="ap-list-item_date justify-center">
        <span class="ap-list-item_date_text">{{ formattedTimePeriod }}</span>
        <span class="ap-list-item_date_text">{{ gmtText }}</span>
        <span class="ap-list-item_date_day">{{ formattedWeekDate }}</span>
        <span v-if="$vuetify.breakpoint.mdAndDown && status.name" class="ap-list-item_status_row">
          <span class="ap-list-item_status_circle" />
          {{ status.name }}
        </span>
      </div>
      <div :class="{ 'flex-column pb-2 pl-2': $vuetify.breakpoint.smAndDown }" class="d-flex">
        <div :class="`ap-list-item_profile`">
          <v-list-item v-if="!userIsPractitioner" class="username-block">
            <v-list-item-avatar v-if="!userIsOwnerOrAdmin">
              <Avatar
                :src="item.status !== 'pending' ? item.practitioner && item.practitioner.photoURL : null"
                :user-id="
                  !!!(item.practitioner && item.practitioner.photoURL)
                    ? item.practitioner && item.practitioner.id
                    : getUserId(
                        item.participant &&
                          item.participant[0] &&
                          item.participant[0].actor &&
                          item.participant[0].actor.reference,
                      )
                "
              />
            </v-list-item-avatar>
            <v-list-item-content>
              <span class="heading-6">
                {{
                  item.status !== "pending" && item.practitioner
                    ? item.practitioner && item.practitioner.name
                    : `${
                        item.participant &&
                        item.participant[0] &&
                        item.participant[0].actor &&
                        item.participant[0].actor.display
                      }`
                }}
              </span>
              <span>
                {{
                  item.practitioner && item.practitioner.qualification
                    ? item.practitioner.qualification
                    : practitioner.qualification
                }}</span
              >
            </v-list-item-content>
          </v-list-item>
          <v-list-item class="username-block">
            <v-list-item-avatar v-if="!userIsOwnerOrAdmin">
              <Avatar :src="item.patient && item.patient.photoURL" />
            </v-list-item-avatar>
            <v-list-item-content>
              <span class="heading-6">
                {{ item.patient && item.patient.name ? item.patient.name : `${item.participant[1].actor.display}` }}
              </span>
              <span class="text--secondary d-flex align-center pointer" @click="gotoPatient(item.patient)">
                {{ item.patient && item.patient.medicalRecordNumber }}
                <img
                  :src="require('@/assets/icons/mrn.svg')"
                  alt="medicalRecordNumber"
                  class="mrn"
                  title="Medical record number"
                />
              </span>
            </v-list-item-content>
          </v-list-item>
        </div>
        <div v-if="$vuetify.breakpoint.smAndDown" class="ap-list-item_complain">
          <span class="name">{{ translateChiefComplaint(item.chiefComplaint) }} </span>
          <span class="text--secondary">Chief Complaint </span>
        </div>
        <div v-if="$vuetify.breakpoint.smAndDown" class="ap-list-item_complain">
          <span class="name">{{ visitType }} </span>
          <span class="text--secondary">Visit type </span>
        </div>
      </div>
      <div v-if="!$vuetify.breakpoint.smAndDown" class="ap-list-item_complain pl-2 pb-2 pt-2">
        <span class="name">{{ translateChiefComplaint(item.chiefComplaint) }} </span>
        <span class="text--secondary">Chief Complaint </span>
        <span class="name">{{ visitType }} </span>
        <span class="text--secondary">Visit type </span>
      </div>
      <div v-if="!$vuetify.breakpoint.mdAndDown" class="ap-list-item_status_row">
        <div class="ap-list-item_status_circle" />
        {{ status.name }}
      </div>
      <div
        :class="{ 'flex-column align-self-center': $vuetify.breakpoint.smAndDown }"
        class="centered-flex ap-list-item_buttons justify-sm-center"
      >
        <button v-if="status.canCancelVisit" class="ap-list-item_cancel-btn" @click="cancel">
          {{ $t("practitioner.appointments.cancelVisit") }}
        </button>
        <div>
          <transition v-if="showModal" name="modal">
            <TestingAudioVideo @onClose="showModal = false" @viewDetailsClick="viewDetailsClick" />
          </transition>
          <OutlinedButton
            v-if="status.canViewDetailsWithVideo"
            :icon="!smBreakpoint ? '' : '$vuetify.icons.details'"
            :text="!smBreakpoint ? (joinNow ? 'Join' : 'View details') : ''"
            additionalClassName="outlined-btn__green"
            @onClick="openModalForTestingCamera"
          />
          <PrimaryButton v-if="status.canViewDetails" text="View details" @onClick="viewDetailsClick" />
          <OutlinedButton
            v-if="status.canResentInvitation"
            :icon="!smBreakpoint ? '' : '$vuetify.icons.details'"
            :text="!smBreakpoint ? $t('patients.table.resendInvitation') : ''"
            additionalClassName="outlined-btn__green"
            @onClick="resendInvite"
          />
          <OutlinedButton
            v-if="status.canAcceptInvite"
            :icon="!smBreakpoint ? '' : '$vuetify.icons.details'"
            :text="
              !smBreakpoint ? ($te('patients.table.acceptInvite') ? $t('patients.table.acceptInvite') : 'Confirm') : ''
            "
            additionalClassName="outlined-btn__green"
            @onClick="acceptInvite"
          />
        </div>
      </div>
      <OutlinedButton
        v-if="!(showMoreDetailsOnId === item.id) && !userIsPatient"
        additionalClassName="absolute-btn more"
        size="small"
        text="More"
        @onClick="openMoreInfo"
      >
        <template v-slot:icon-right>
          <img :src="require('@/assets/icons/menu-down.svg')" alt="" />
        </template>
      </OutlinedButton>
    </div>

    <div v-if="showMoreDetailsOnId === item.id" class="more-details-wrap">
      <OutlinedButton additionalClassName="absolute-btn less" size="small" text="Collapse" @onClick="closeMoreInfo">
        <template v-slot:icon-right>
          <img :src="require('@/assets/icons/close.svg')" alt="" />
        </template>
      </OutlinedButton>
      <AppointmentListItemMoreInfo :item="this.item" />
    </div>
  </div>
</template>
<script>
import { DateTime, Interval } from "luxon";
import { mapActions, mapState } from "pinia";

import { CheckoutApi } from "@/api/checkout";
import AppointmentListItemMoreInfo from "@/components/AppointmentsListItem/MoreInfo";
import Avatar from "@/components/Avatar/Index";
import OutlinedButton from "@/components/uikit/OutlinedButton";
import PrimaryButton from "@/components/uikit/PrimaryButton";
import { snackBarEventBus, snackBarEventName } from "@/eventBuses/snackBar.eventBus";
import { timeZoneMixin } from "@/mixins/timeZone";
import { useAppStore } from "@/pinia-store/app";
import { useAppointmentsStore } from "@/pinia-store/appointments";
import { useAuthStore } from "@/pinia-store/auth";
import { usePractitionersStore } from "@/pinia-store/practitioners";
import { AppointmentStatusEnum } from "@/types/AppointmentQueryFilter";
import { EncounterStatusEnum } from "@/types/EncounterStatusEnum";
import { RolesEnum } from "@/types/Roles.enum";
import { routesEnum } from "@/types/Routes.enum";

import TestingAudioVideo from "../../views/Patient/Visits/TestingAudioVideo.vue";

export default {
  name: "AppointmentsListItem",
  props: ["item", "role", "today", "place"],
  components: {
    AppointmentListItemMoreInfo,
    OutlinedButton,
    PrimaryButton,
    Avatar,
    TestingAudioVideo,
  },
  data: () => ({
    showMoreDetails: false,
    showModal: false,
    isExpired: false,
  }),
  mixins: [timeZoneMixin],
  computed: {
    ...mapState(useAppStore, ["drawer", "sidebarStatus"]),
    ...mapState(useAuthStore, ["timeZone", "userIsPatient", "userIsPractitioner"]),
    ...mapState(usePractitionersStore, ["practitioner"]),
    ...mapState(useAppointmentsStore, ["showMoreDetailsOnId"]),
    isDisabledCancel() {
      return this.$moment().isAfter(this.$moment(this.item.start).subtract(5, "minutes")) && this.userIsPatient;
    },
    roles() {
      return RolesEnum;
    },
    startField() {
      return this.item.status !== "initialized" ? this.item.start : this.item.slot?.start;
    },
    endField() {
      return this.item.status !== "initialized" ? this.item.end : this.item?.slot;
    },
    startTime() {
      return this.startField ? this.$moment(this.startField).tz(this.timeZone).format("HH:mm") : "";
    },
    formattedWeekDate() {
      return this.startField ? this.$moment(this.startField).tz(this.timeZone).format("DD MMMM YYYY") : "";
    },
    formattedTimePeriod() {
      return `${this.startField ? this.$moment(this.startField).tz(this.timeZone).format("HH:mm") : ""} - ${
        this.endField ? this.$moment(this.endField).tz(this.timeZone).format("HH:mm") : ""
      }`;
    },
    visitType() {
      return this.item?.virtualService === "video" ? "Video visit" : "Request a callback";
    },
    userName() {
      return this.role === "practitioner" ? this.item?.patient?.name || " " : this.item?.practitioner?.name || " ";
    },
    userId() {
      return this.item[this.role === "practitioner" ? "patient" : "practitioner"].id;
    },
    userIsOwnerOrAdmin() {
      return this.role === RolesEnum.Owner || this.role === RolesEnum.Admin;
    },
    isToday() {
      if (!this.item) {
        return false;
      }
      const { start } = this.item;
      return DateTime.fromISO(start).startOf("day") === DateTime.local().startOf("day");
    },
    status() {
      const status = {};
      const apStatus = this.item.status;
      const enStatus = this.item.encounterStatus;

      if (apStatus === AppointmentStatusEnum.booked && enStatus === EncounterStatusEnum.planned) {
        return {
          canCancelVisit: true,
          canViewDetailsWithVideo: true,
          className: "ap-list-item_scheduled",
          name: this.$t("general.status.scheduled"),
        };
      }
      if (apStatus === AppointmentStatusEnum.fulfilled && enStatus === EncounterStatusEnum["in-progress"]) {
        return {
          canViewDetailsWithVideo: true,
          className: "ap-list-item_active",
          name: this.$t("general.status.active"),
        };
      }
      if (
        apStatus === AppointmentStatusEnum.fulfilled &&
        [EncounterStatusEnum.triaged, EncounterStatusEnum.onleave].includes(enStatus)
      ) {
        return {
          canViewDetails: !this.userIsPatient,
          className: "ap-list-item_unsigned",
          name: this.$t("general.status.pending"),
        };
      }
      if (apStatus === AppointmentStatusEnum.fulfilled && enStatus === EncounterStatusEnum.finished) {
        return {
          canViewDetails: true,
          className: "ap-list-item_signed",
          name: this.$t("general.status.signed"),
        };
      }
      if (apStatus === AppointmentStatusEnum.pending) {
        let canResentInvitation =
          this.userIsPatient && this.item.confirmedByPatient
            ? true
            : this.userIsPractitioner && this.item.confirmedByPractitioner;
        return {
          canResentInvitation,
          canAcceptInvite: !canResentInvitation,
          canCancelVisit: true,
          className: "ap-list-item_unsigned",
          name: "Pending",
        };
      }
      if (apStatus === AppointmentStatusEnum.cancelled) {
        return {
          className: "ap-list-item_cancelled",
          name: this.$t("general.status.cancelled"),
        };
      }
      if (apStatus === AppointmentStatusEnum.noshow) {
        return {
          className: "ap-list-item_expired",
          name: "Expired",
        };
      }
      switch (this.item.status) {
        case "onleave":
          status.canViewDetails = !this.userIsPatient;
          status.className = "ap-list-item_unsigned";
          status.name = "Pending";
          break;
        case "in-progress":
          status.className = "ap-list-item_active";
          status.name = this.isToday ? this.$t("general.status.upcoming") : this.$t("general.status.active");
          break;
        case "triaged":
          status.canViewDetails = !this.userIsPatient;
          status.className = "ap-list-item_unsigned";
          status.name = this.$t("general.status.pending");
          break;
        case "pending":
          if (
            (this.userIsPatient || this.userIsPractitioner) &&
            this.$moment.tz(this.timeZone).isAfter(this.item.start)
          ) {
            status.className = "ap-list-item_expired";
            status.name = "Expired";
          } else {
            status.className = "ap-list-item_unsigned";
            status.name =
              this.$t("owner.clinician.table.pending").charAt(0).toUpperCase() +
              this.$t("owner.clinician.table.pending").slice(1);
          }
          break;
        case "finished":
          status.className = "ap-list-item_signed";
          status.name = this.$t("general.status.signed");
          break;
        case "fulfilled":
          status.className = "ap-list-item_signed";
          status.name = this.$t("general.status.signed");
          break;
        case "cancelled":
          status.className = "ap-list-item_cancelled";
          status.name = this.$t("general.status.cancelled");
          break;
        case "booked":
          if (
            (this.userIsPatient || this.userIsPractitioner) &&
            this.$moment.tz(this.timeZone).isAfter(this.item.start)
          ) {
            status.className = "ap-list-item_expired";
            status.name = "Expired";
          }
          break;
      }
      return status;
    },
    joinNow() {
      const startDate = DateTime.fromISO(this.item.start);
      const now = DateTime.fromJSDate(new Date());
      const interval = Interval.fromDateTimes(now, startDate);
      return interval.length("minutes") < 3;
    },
    smBreakpoint() {
      return window.outerWidth < 481;
    },
  },
  methods: {
    ...mapActions(usePractitionersStore, ["getPractitionerById"]),
    ...mapActions(useAppointmentsStore, ["setShowMoreDetailsOnId"]),
    gotoPatient(patient) {
      if (patient?.id && this.role !== RolesEnum.Patient) {
        this.$router.push({ name: routesEnum.patientMedicalRecord, params: { patientId: patient?.id } });
      }
    },
    closeMoreInfo() {
      this.setShowMoreDetailsOnId(null);
    },
    openMoreInfo() {
      this.setShowMoreDetailsOnId(this.item.id);
    },
    cancel() {
      this.$emit("onCancel", this.item.status !== "pending" ? this.item.id : this.item.id);
    },
    openModalForTestingCamera() {
      if (this.item.virtualService !== "video") {
        this.viewDetailsClick();
      } else {
        this.showModal = true;
      }
    },
    viewDetailsClick() {
      this.$emit("onButtonClick", this.item.encounterId || this.item.id);
    },
    async resendInvite() {
      try {
        await CheckoutApi.resend(this.item.id);
        snackBarEventBus.$emit(snackBarEventName, {
          message: "Invitation email has been resent",
          type: "success",
        });
      } catch (e) {
        snackBarEventBus.$emit(snackBarEventName, {
          message: "Error sending invitation ",
          type: "error",
        });
        console.error(e);
      }
    },
    async acceptInvite() {
      const link = this.userIsPatient
        ? `/patient/encounters/schedule/${this.item.id}/cancel`
        : `/practitioner/encounters/schedule/${this.item.id}/cancel`;
      await this.$router.push({ path: link });
    },
    translateChiefComplaint(val) {
      return this.$t("general.chiefComplaints")[val] || val || "-";
    },
    getUserId(reference) {
      return reference.split("/")[1];
    },
  },
  async mounted() {
    if (
      this.item.status === "pending" &&
      this.userIsPatient &&
      this.$moment().tz(this.timeZone).isAfter(this.item.start)
    ) {
      this.isExpired = true;
    }
    if (this.item.status === "pending" && this.item?.practitionerId) {
      await this.getPractitionerById(this.item.practitionerId);
    } else if (this.item?.practitioner?.id) {
      await this.getPractitionerById(this.item.practitioner.id);
    }
  },
};
</script>

<style lang="scss">
.ap-list-item {
  display: flex;
  flex-direction: column;

  .ap-list-item-wrap {
    display: flex;
    flex-wrap: wrap;
    position: relative;
    gap: 10px;
    padding-bottom: 8px;
  }

  .more-details-wrap {
    position: relative;
    margin-top: -16px;
  }

  .absolute-btn {
    opacity: 1 !important;
    max-width: 150px;
    text-transform: none !important;
    color: #2196f3;
    background: #fff !important;
    border: 1px solid rgba(33, 150, 243, 0.3);
    border-radius: 100px;
    height: 26px !important;

    &.more {
      position: absolute;
      bottom: -10px;
      width: 80px;
      left: calc(50% - 40px);
    }

    &.less {
      position: sticky;
      top: 65px;
      width: 100px;
      margin: 0 auto;
      display: block;
      z-index: 2;
    }

    .v-btn__content {
      color: #2196f3;
    }
  }

  .mrn {
    margin-left: 8px;
    width: 16px;
    height: 16px;
  }

  &_buttons {
    justify-content: flex-end;
    display: flex;
    gap: 10px;
    align-items: center;
    margin-left: auto;

    &.align-self-center {
      align-self: center;
    }

    @include desktop {
      justify-content: center;
    }
  }

  &_complain {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 16px;
    min-width: 200px;
    flex-grow: 0;
    @include large {
      padding-left: 0;
      min-width: min-content;
    }

    .name {
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      /* identical to box height, or 143% */

      /* Grayscale / Gray Dark */

      color: #33343e;

      opacity: 0.9;
    }

    @include mobile {
      justify-content: start;
      flex-grow: 1;
      flex-basis: 100% !important;
    }
  }

  &_date {
    flex-basis: 150px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-wrap: wrap;
    border-right: 1px solid $primarylight2;
    @include tablet {
      flex-basis: 113px;
    }
    @include mobile {
      flex-basis: 100%;
    }

    .ap-list-item_status_row {
      margin: 0 auto;
    }

    &_time {
      font-style: normal;
      font-weight: 550;
      font-size: 14px;
      line-height: 17px;
      color: $primaryblack2;
      mix-blend-mode: normal;
      margin-bottom: 2px;
    }

    &_text {
      font-weight: 500;
      font-size: 13px;
      line-height: 18px;
      display: flex;
      align-items: center;
      text-align: center;
      text-transform: uppercase;
      color: #33343e;
      font-style: normal;
      mix-blend-mode: normal;
    }

    &_title {
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
    }

    &_time {
      font-weight: 600;
      font-size: 12px;
      line-height: 15px;
      display: flex;
      align-items: center;

      color: #545f7a;

      opacity: 0.8;
    }

    &_day {
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      color: #33343e;
      opacity: 0.8;
      display: flex;
      align-items: center;
      text-align: center;
    }

    @media (max-width: 767.98px) {
      .ap-list-item_status_row {
        align-items: center;
        margin-top: 6px;
        margin-bottom: 12px;
      }
    }
  }

  &_profile {
    min-width: 220px;
    flex-direction: column;

    .username-block {
      width: 100%;
    }

    .v-list-item {
      padding-left: 0;
    }

    @include large {
      min-width: min-content;
      //.v-avatar {
      //  display: none;
      //}
    }
  }
}

.username-block {
  width: min-content;
}

.cc-wrap {
  width: inherit;
}

.call {
  display: block;
  background: #2fcf6f;
  border-radius: 4px;
  margin: auto;
  margin-top: 14px !important;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #ffffff;
  padding: 11px 16px 11px 16px;
}

.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}
</style>
