
import * as AdaptiveCards from "adaptivecards";
import Vue from "vue";

import BaseMessage from "@/components/Chat/Message/MessageTypes/BaseMessage.vue";
export default Vue.extend({
  name: "ChatLogMessageWithAdaptiveCard",
  components: { BaseMessage },
  props: {
    message: {
      type: Object,
      required: true,
    },
    responseDelegate: Function,
  },
  data: () => ({
    value: [] as string[],
    renderedCard: Object as unknown as HTMLElement,
    adaptiveCards: new AdaptiveCards.AdaptiveCard(),
    anySelected: false,
  }),
  created: function () {
    let choicesInputElements: HTMLInputElement[] | null;
    let choicesLabelElements: HTMLLabelElement[] | null;
    this.adaptiveCards.hostConfig = new AdaptiveCards.HostConfig({
      adaptiveCard: {
        allowCustomStyle: true,
      },
      textBlock: {
        wrap: true,
      },
    });

    this.adaptiveCards.parse(this.message.attributes.meta);
    this.renderedCard = this.adaptiveCards.render() as HTMLElement;

    const selectedOption = this.message.attributes?.selectedOption?.payload ?? this.message.attributes?.selectedOption;
    const selectedChoicesArray: any[] = selectedOption?.choices?.split(",");

    const multiSelectElement = this.renderedCard?.querySelector(".ac-choiceSetInput-multiSelect");

    const actionSetElement = this.renderedCard?.querySelector(".ac-actionSet");
    let actionSetButtons: HTMLElement[];

    if (actionSetElement) {
      actionSetButtons = Array.from(actionSetElement.getElementsByTagName("button")) as HTMLElement[];
      if (actionSetButtons.length > 1) {
        actionSetButtons.forEach((button) => {
          button.classList.add("last");
          button.setAttribute("disabled", "disabled");
          button.style.cursor = "default";
          if (this.message.attributes?.selectedOption === button.getAttribute("aria-label")) {
            button.classList.add("clicked");
          }
        });
      } else {
        const button = actionSetButtons[0];
        button.classList.add("last");
        button.setAttribute("disabled", "disabled");
        button.style.cursor = "default";
        if (this.message.lastUpdatedBy !== null) {
          button.classList.add("clicked");
        }
      }
    }

    if (multiSelectElement) {
      choicesInputElements = Array.from(multiSelectElement.getElementsByTagName("input"));
      choicesLabelElements = Array.from(multiSelectElement.getElementsByTagName("label"));

      choicesInputElements.forEach((input) => {
        input.setAttribute("disabled", "true");
        if (selectedChoicesArray?.includes(input.getAttribute("value"))) {
          input.checked = true;
        }
      });
      choicesLabelElements.forEach((label) => {
        label.setAttribute("disabled", "true");
      });
    }
  },
  methods: {
    action(val: string) {
      this.$emit("action", val);
    },
    setValue(value: string) {
      this.value.push(value);
    },
  },
  mounted() {
    if (this.$refs["cardRoot"] && this.renderedCard) {
      const cardRoot = this.$refs["cardRoot"] as HTMLDivElement;
      cardRoot.appendChild(this.renderedCard);
    }
  },
});
