<template>
  <Confirm
    :confirmText="`${form.id ? 'Change' : 'Add'} order`"
    :dialog="formOpened"
    :loading="loading"
    :title="`${form.id ? 'Change' : 'Add'} Blood Pressure order`"
    cancelText="Cancel"
    maxWidth="90%"
    rootClass="order-form"
    text=""
    width="600px"
    @confirm="performStore"
    @toggleDialog="toggleFormOpened"
  >
    <v-container class="no-padding-bottom no-padding-top">
      <v-row>
        <v-col cols="3">
          <v-subheader> Normal Systolic *</v-subheader>
        </v-col>
        <v-col class="inline-inputs" cols="9">
          <BaseInput
            :errors="validateField('systolicLow')"
            :value="form.systolicLow"
            fieldName="systolicLow"
            placeholder="Low normal"
            @change="onFieldChange"
          />
          <v-icon slot="prepend" color="green"> mdi-minus</v-icon>
          <BaseInput
            :errors="validateField('systolicHigh')"
            :value="form.systolicHigh"
            fieldName="systolicHigh"
            placeholder="High normal"
            @change="onFieldChange"
          />
          <span class="text--secondary pl-1">mmHg</span>
        </v-col>
        <v-col cols="3">
          <v-subheader> Normal Diastolic *</v-subheader>
        </v-col>
        <v-col class="inline-inputs" cols="9">
          <BaseInput
            :errors="validateField('diastolicLow')"
            :value="form.diastolicLow"
            fieldName="diastolicLow"
            placeholder="Low normal"
            @change="onFieldChange"
          />
          <v-icon slot="prepend" color="green"> mdi-minus</v-icon>
          <BaseInput
            :errors="validateField('diastolicHigh')"
            :value="form.diastolicHigh"
            fieldName="diastolicHigh"
            placeholder="High normal"
            @change="onFieldChange"
          />
          <span class="text--secondary pl-1">mmHg</span>
        </v-col>
        <v-col cols="3">
          <v-subheader>Frequency</v-subheader>
        </v-col>
        <v-col cols="9">
          <Select
            :errors="validateField('patientInstruction')"
            :hideDetails="true"
            :items="frequencyList"
            :value="form.patientInstruction"
            item-text="name"
            item-value="id"
            label="Select frequency"
            @change="onChangeInstruction"
          />
        </v-col>
        <v-col cols="3">
          <v-subheader> Duration</v-subheader>
        </v-col>
        <v-col class="inline-inputs" cols="9">
          <DatePicker
            :dense="true"
            :errors="validateField('startDate')"
            :height="47"
            :hideDetails="true"
            :value="form.startDate"
            placeholder="Start date"
            @setDate="onChangeStartDate"
          />
          <v-icon slot="prepend" color="green"> mdi-minus</v-icon>
          <DatePicker
            :dense="true"
            :errors="validateField('endDate')"
            :height="47"
            :hideDetails="true"
            :max-date="maxDate"
            :value="form.endDate"
            placeholder="End date"
            @setDate="onChangeEndDate"
          />
        </v-col>
        <v-col cols="3">
          <v-subheader> Notes</v-subheader>
        </v-col>
        <v-col cols="9">
          <Textarea :hideDetails="true" :value="form.note" placeholder="Enter Notes" @change="onNoteChange" />
        </v-col>
      </v-row>
    </v-container>
  </Confirm>
</template>
<script>
import moment from "moment-timezone";
import { mapActions, mapState } from "pinia";

import { ServiceRequestApi } from "@/api/serviceRequest";
import Confirm from "@/components/shared/Confirm";
import DatePicker from "@/components/shared/DatePicker";
import BaseInput from "@/components/uikit/BaseInput";
import Select from "@/components/uikit/Select";
import Textarea from "@/components/uikit/Textarea";
import { BLOOD_PRESSURE_ORDER_ADDED } from "@/eventBuses/monitoringEvents";
import { validatorErrors } from "@/mixins/validatorErrors.js";
import { useAuthStore } from "@/pinia-store/auth";
import { useAutoSaveStore } from "@/pinia-store/autoSave";
import { useBloodPressureOrderStore } from "@/pinia-store/bodyPresureOrder";
import { ServiceRequestStatus } from "@/types/ServiceRequestStatus.enum";

export default {
  name: "AddBloodPressureOrder",
  props: {
    isStatusActive: {
      default: () => false,
      type: Boolean,
    },
  },
  components: {
    Textarea,
    DatePicker,
    BaseInput,
    Confirm,
    Select,
  },
  mixins: [validatorErrors],
  data: () => ({
    maxDate: moment().add(2, "years").toISOString(),
    loading: false,
    frequencyList: [],
  }),
  computed: {
    ...mapState(useAuthStore, ["uid"]),
    ...mapState(useBloodPressureOrderStore, ["formOpened", "form"]),
  },
  methods: {
    ...mapActions(useAutoSaveStore, ["startSave", "endSave"]),
    ...mapActions(useBloodPressureOrderStore, ["changeFormField", "toggleFormOpened"]),
    onNoteChange(value) {
      this.changeFormField({ field: "note", value });
    },
    onFieldChange(value, field) {
      this.changeFormField({ field, value });
    },
    async onChangeInstruction(value) {
      this.changeFormField({ field: "patientInstruction", value });
    },
    onChangeStartDate(value) {
      this.changeFormField({ field: "startDate", value });
    },
    onChangeEndDate(value) {
      this.changeFormField({ field: "endDate", value });
    },
    async performStore() {
      try {
        this.loading = true;
        this.startSave();
        const {
          systolicHigh,
          systolicLow,
          diastolicLow,
          diastolicHigh,
          startDate,
          encounterId,
          note,
          patientInstruction,
          endDate,
          id,
        } = this.form;
        await ServiceRequestApi.createBloodPressure({
          id,
          note,
          patientInstruction,
          startDate,
          endDate,
          encounterId,
          status: this.isStatusActive ? ServiceRequestStatus.active : ServiceRequestStatus.draft,
          systolicHigh: parseFloat(systolicHigh),
          systolicLow: parseFloat(systolicLow),
          diastolicLow: parseFloat(diastolicLow),
          diastolicHigh: parseFloat(diastolicHigh),
        });
        this.loading = false;
        this.toggleFormOpened();
        this.$root.$emit(BLOOD_PRESSURE_ORDER_ADDED);
        this.endSave();
      } catch (err) {
        this.setResponseErrors(err);
        this.loading = false;
        this.endSave();
      }
    },
  },
  mounted() {
    const medicationFrequency = this.$t("general.medicationFrequency");
    if (medicationFrequency) {
      this.frequencyList = Object.keys(medicationFrequency).map((key) => medicationFrequency[key]);
    }
  },
};
</script>
<style lang="scss">
.order-form {
  .inline-inputs {
    flex-direction: row;
    display: flex;
    align-items: center;
  }

  .card-title {
    font-weight: 500 !important;
    font-size: 25px !important;
    line-height: 31px !important;
    color: #25233a;
  }

  .v-card__title + .v-card__text {
    font-size: 14px;
    line-height: 17px;
    color: #33343d;
  }
}
</style>
