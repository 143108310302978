import { DateTime } from "luxon";
import { mapActions, mapState } from "pinia";
import Vue from "vue";

import { useAppointmentsStore } from "@/pinia-store/appointments";
import { useAuthStore } from "@/pinia-store/auth";
import { useEncountersStore } from "@/pinia-store/encounters";

export const todaySchedulePractitionerMixin = Vue.extend({
  data: () => ({
    dialog: false,
    loading: false,
    cancelledId: "",
  }),
  computed: {
    ...mapState(useEncountersStore, ["todayEncounters"]),
    ...mapState(useAppointmentsStore, ["todayAppointments"]),
    ...mapState(useAuthStore, ["role"]),
    ...mapState(useAuthStore, ["uid"]),
    ...mapState(useAuthStore, ["timeZone"]),
    todayItems() {
      let now = this.$moment.tz(this.timeZone);
      const appIds = (this.todayEncounters || []).map((i) => i.appointmentId);
      const appointments = (this.todayAppointments || []).filter((i) => {
        return !appIds.includes(i.id);
      });
      const allItems = [...(this.todayEncounters || []), ...appointments];
      allItems.sort((a, b) => new Date(a.start) - new Date(b.start));
      return allItems
        .filter((e) => this.$moment(e.end).isAfter(now))
        .reduce((acc, val) => {
          const currentDate = DateTime.fromISO(val.start).toFormat("cccc, d MMMM yyyy");
          const foundIndex = acc.findIndex((el) => el.date === currentDate);
          if (foundIndex === -1) {
            acc.push({ date: currentDate, items: [val] });
          } else {
            acc[foundIndex].items.push(val);
          }
          return acc;
        }, [])
        .sort((a, b) => new Date(a.date) - new Date(b.date));
    },
  },
  methods: {
    ...mapActions(useEncountersStore, ["getPractitionerTodayEncounters"]),
    ...mapActions(useAppointmentsStore, ["getTodayAppointments"]),
    joinRoom(encounterId) {
      this.$router.push(`/${this.role}/encounters/${encounterId}`);
    },
    onCancel(id) {
      this.cancelledId = id;
      this.dialog = true;
    },
    async getTodayListEncounters() {
      this.loading = true;
      try {
        await Promise.all([
          // this.getPractitionerTodayEncounters(),
          this.getTodayAppointments(),
        ]);
      } catch (e) {
        console.error(e);
      }
      this.loading = false;
    },
  },
});
