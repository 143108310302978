<template>
  <v-textarea
    class="textarea base-input"
    :value="value"
    solo
    auto-grow
    flat
    :outlined="!!errors.length || outlined"
    dense
    row-height="5"
    :height="height"
    :no-resize="!resize"
    :placeholder="placeholder"
    :error-messages="errors"
    :disabled="disabled"
    :hide-details="hideDetails"
    @blur="onBlur"
    @change="onChange"
    @input="onChange"
    @focus="outlined = true"
  >
  </v-textarea>
</template>
<script>
export default {
  name: "Textarea",
  props: {
    value: String,
    placeholder: String,
    fieldName: String,
    resize: { default: false, type: Boolean },
    errors: { default: () => [], type: Array },
    disabled: { default: false, type: Boolean },
    height: { default: undefined, type: Number },
    hideDetails: { default: false, type: Boolean },
  },
  data: () => ({
    outlined: false,
  }),
  methods: {
    onChange(value) {
      this.$emit("change", value, this.fieldName);
      this.$emit("input", value);
    },
    onBlur() {
      this.$emit("blur");
      this.outlined = false;
    },
  },
};
</script>
<style lang="scss">
.textarea {
  textarea {
    height: 67px;
  }
}
</style>
