import { DateTime } from "luxon";
import { mapActions, mapState } from "pinia";

import { useMeetingRoomStore } from "@/pinia-store/meetingRoom";

export const meetingRoomMixin = {
  data() {
    return {
      loading: false,
      error: null,
      timerInterval: null,
      timer: null,
    };
  },
  computed: {
    ...mapState(useMeetingRoomStore, ["meetingRoom"]),
    dateFormated() {
      return this.meetingRoom?.sentAt ? this.$moment(this.meetingRoom.sentAt).format("DD MMMM Y") : null;
    },
    timeRange() {
      return this.meetingRoom?.sentAt ? this.$moment(this.meetingRoom.sentAt).format("H:mm") : null;
    },
  },
  methods: {
    ...mapActions(useMeetingRoomStore, ["fetchRoom"]),
    async calculateTimer() {
      if (!this.meetingRoom || !this.meetingRoom.sentAt) {
        return;
      }
      const diff = DateTime.fromISO(this.meetingRoom.sentAt).diffNow("seconds").negate();
      if (diff.as("hours") >= 1) {
        return diff.toFormat("hh:mm:ss");
      }
      return diff.toFormat("mm:ss");
    },
    async timerUpdate() {
      this.timer = await this.calculateTimer();
    },

    async clear() {
      console.info("clear");
    },
  },
  async mounted() {
    this.timerInterval = setInterval(this.timerUpdate, 1000);
  },
  beforeDestroy() {
    window.clearInterval(this.timerInterval);
    if (this.timer) {
      window.clearInterval(this.timerInterval);
      this.timerInterval = null;
    }
  },
};
