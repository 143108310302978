import { Participant } from "@twilio/conversations";

import { IParticipantsRepository } from "@/context/twilioContext/ChatConversation/IParticipantsRepository";
import ChatUser from "@/context/twilioContext/TwilioChatMember/ChatUser";
import { ParticipantStatus } from "@/types/BotStatusDto";

class DummyMembersRepository implements IParticipantsRepository {
  constructor(user: ChatUser) {
    this.user = user;
  }

  public isAnyParticipantBot = true;
  public interlocutor: ChatUser | null = null;
  public user: ChatUser;
  isTyping = false;
  participants: ChatUser[] = [];
  participant: ChatUser | null = null;
  participantStatus: ParticipantStatus = "offline";

  public addParticipant = async (user: ChatUser): Promise<void> => {
    if (user.attributes.isBot) this.isAnyParticipantBot = true;
    this.participant = user;
    this.participants.push(user);
  };

  listParticipants(): ChatUser[] {
    return [];
  }

  removeParticipant(member: Participant): Promise<void> {
    return Promise.reject("This is dummy members repository");
  }

  setInterlocutor(uid: string): void {
    return;
  }
}

export default DummyMembersRepository;
