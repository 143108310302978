<template>
  <Confirm
    :dialog="isOpened"
    :disabled-save="$v.$invalid"
    :loading="isLoading"
    confirm-text="Send"
    max-width="90%"
    title="Coordination of Care"
    width="560px"
    @confirm="onSend"
    @toggleDialog="onClose"
  >
    <div class="practitioner-fax">
      <v-row class="body">
        <v-col cols="12">
          <v-tabs :value="tabValue" background-color="transparent" class="tab_methods">
            <v-tab class="tab_methods__tab" @click="method = 'fax'">Send Fax</v-tab>
            <v-tab class="tab_methods__tab" @click="method = 'email'">Send Email</v-tab>
          </v-tabs>
          <PhoneInput
            v-if="method === CareCoordinationMethods.fax"
            v-model="faxNumber"
            :errors="faxNumberErrors"
            class="marginTop12"
            fieldName="faxNumber"
            placeholder="Fax Number *"
            @blur="$v.faxNumber.$touch()"
          />
          <BaseInput
            v-if="method === CareCoordinationMethods.email"
            v-model="email"
            :errors="emailErrors"
            class="marginTop12"
            fieldName="email"
            placeholder="Email *"
            @blur="$v.email.$touch()"
          />
          <Textarea
            v-if="method === CareCoordinationMethods.email"
            v-model="message"
            :errors="messageErrors"
            :hideDetails="true"
            :resize="true"
            class="marginTop12"
            fieldName="message"
            placeholder="Message *"
          />
          <BaseInput
            v-if="method === CareCoordinationMethods.fax"
            v-model="recipientName"
            :errors="recipientNameErrors"
            class="marginTop12"
            fieldName="recipientName"
            placeholder="Recipient name *"
            @blur="$v.recipientName.$touch()"
          />
          <BaseInput
            v-model="subject"
            :errors="subjectErrors"
            class="marginTop12"
            fieldName="subject"
            placeholder="Subject *"
            @blur="$v.subject.$touch()"
          />
          <h3 class="text-title marginTop12">Attachments</h3>
          <MediaUploader class="mt-3 mb-3" @onUploaded="onFileUploaded" />
          <template v-if="files.length">
            <FileRow
              v-for="file in files"
              :key="file.id"
              :editable="false"
              :file="file"
              :hide-rename="true"
              @onRemove="onFileDelete"
            />
          </template>
        </v-col>
      </v-row>
    </div>
  </Confirm>
</template>
<script>
import { mapActions, mapState } from "pinia";

import { CareCoordinationAPI } from "@/api/care-coordination";
import FileRow from "@/components/media/FileRow.vue";
import MediaUploader from "@/components/media/MediaUploader.vue";
import Confirm from "@/components/shared/Confirm.vue";
import PhoneInput from "@/components/shared/PhoneInput.vue";
import BaseInput from "@/components/uikit/BaseInput.vue";
import Textarea from "@/components/uikit/Textarea.vue";
import { snackBarEventBus, snackBarEventName } from "@/eventBuses/snackBar.eventBus";
import { validatorErrors } from "@/mixins/validatorErrors";
import { CareCoordinationMethods, useCareCoordinationStore } from "@/pinia-store/careCoordination";

export default {
  name: "CareCoordination",
  components: { Textarea, FileRow, Confirm, MediaUploader, PhoneInput, BaseInput },
  mixins: [validatorErrors],

  data() {
    return {
      isLoading: false,
    };
  },
  computed: {
    CareCoordinationMethods() {
      return CareCoordinationMethods;
    },
    ...mapState(useCareCoordinationStore, ["isOpened", "form"]),
    method: {
      set(value) {
        this.setFormField({ field: "method", value });
      },
      get() {
        return this.form.method;
      },
    },
    tabValue() {
      return this.form.method === CareCoordinationMethods.fax ? 0 : 1;
    },
    subject: {
      set(value) {
        this.setFormField({ field: "subject", value });
      },
      get() {
        return this.form.subject;
      },
    },
    files() {
      return this.form.files || [];
    },
    email: {
      set(value) {
        this.setFormField({ field: "email", value });
      },
      get() {
        return this.form.email || "";
      },
    },
    message: {
      set(value) {
        this.$v.message.$touch();
        this.setFormField({ field: "message", value });
      },
      get() {
        return this.form.message;
      },
    },
    recipientName: {
      set(value) {
        this.setFormField({ field: "recipientName", value });
      },
      get() {
        return this.form.recipientName;
      },
    },
    faxNumber: {
      set(value) {
        this.setFormField({ field: "faxNumber", value });
      },
      get() {
        return this.form.faxNumber;
      },
    },
  },
  watch: {
    isOpened(val) {
      if (!val) return;
      if (this.method === CareCoordinationMethods.fax) {
        this.validateFields = ["faxNumber", "recipientName", "subject"];
      } else {
        this.validateFields = ["email", "message", "subject"];
      }
    },
    method(val) {
      if (val === CareCoordinationMethods.fax) {
        this.validateFields = ["faxNumber", "recipientName", "subject"];
      } else {
        this.validateFields = ["email", "message", "subject"];
      }
    },
  },
  methods: {
    ...mapActions(useCareCoordinationStore, ["setIsOpened", "setFormField", "clearForm"]),
    onFileUploaded(filesValue) {
      const files = [...(this.form.files || []), ...filesValue];
      this.setFormField({ field: "files", value: files });
    },
    onFileDelete(file) {
      const files = this.form.files;
      const newFiles = files.filter((f) => f.id !== file.id);
      this.setFormField({ field: "files", value: newFiles });
    },
    onClose() {
      if (this.isOpened) {
        this.clearForm();
        this.isLoading = false;
      }
    },
    async onSend() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      this.isLoading = true;
      try {
        const resp = await CareCoordinationAPI.create(this.form);
        if (resp.status) {
          snackBarEventBus.$emit(snackBarEventName, {
            message: "Message was sent successfully!",
            type: "info",
          });
          this.$v.$reset();
          this.clearForm();
        } else {
          snackBarEventBus.$emit(snackBarEventName, {
            message: "Something went wrong.",
            type: "error",
          });
        }
      } catch (err) {
        snackBarEventBus.$emit(snackBarEventName, {
          message: "Something went wrong.",
          type: "error",
        });
        console.error(err);
      }

      this.isLoading = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.practitioner-fax {
}
</style>
