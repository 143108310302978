<template>
  <div>
    <Confirm
      :confirmText="`${bloodPressureForm.id ? 'Change' : 'Add'}`"
      :dialog="bloodPressureFormOpened"
      :loading="loading"
      :title="`${bloodPressureForm.id ? 'Change Blood Pressure' : 'Add Blood Pressure'}`"
      cancelText="Cancel"
      maxWidth="90%"
      rootClass="order-form"
      text=""
      width="600px"
      @confirm="performStore"
      @toggleDialog="toggleBloodPressureFormOpened"
    >
      <v-container class="no-padding-bottom no-padding-top">
        <v-row>
          <v-col cols="3">
            <v-subheader> Systolic Pressure</v-subheader>
          </v-col>
          <v-col class="inline-inputs" cols="9">
            <BaseInput
              :errors="validateField('systolic')"
              :hide-details="false"
              :value="bloodPressureForm.systolic"
              fieldName="systolic"
              placeholder="Systolic"
              @change="onFieldChange"
            />
            <span class="text--secondary pl-1">mmHg</span>
          </v-col>
          <v-col cols="3">
            <v-subheader> Diastolic Pressure *</v-subheader>
          </v-col>
          <v-col class="inline-inputs" cols="9">
            <BaseInput
              :errors="validateField('diastolic')"
              :hide-details="false"
              :value="bloodPressureForm.diastolic"
              fieldName="diastolic"
              placeholder="Diastolic"
              @change="onFieldChange"
            />
            <span class="text--secondary pl-1">mmHg</span>
          </v-col>
          <v-col cols="3">
            <v-subheader> Date</v-subheader>
          </v-col>
          <v-col class="inline-inputs" cols="9">
            <DatePicker
              :dense="true"
              :errors="validateField('date')"
              :height="47"
              :hideDetails="true"
              :show-now="true"
              :value="bloodPressureForm.date"
              placeholder="Date"
              @setDate="onChangeDate"
            />
          </v-col>
          <v-col cols="3">
            <v-subheader> Time</v-subheader>
          </v-col>
          <v-col class="inline-inputs" cols="9">
            <TimePicker :show-now="true" :value="bloodPressureForm.time" fieldName="time" @change="onChangeTime" />
          </v-col>
          <v-col cols="3">
            <v-subheader> Notes</v-subheader>
          </v-col>
          <v-col cols="9">
            <Textarea
              :hideDetails="true"
              :value="bloodPressureForm.note"
              placeholder="Enter Notes"
              @change="onNoteChange"
            />
          </v-col>
        </v-row>
      </v-container>
    </Confirm>
  </div>
</template>
<script>
import { mapActions, mapState } from "pinia";

import { ObservationsApi } from "@/api/observations";
import { timeISO8601OFormat } from "@/components/Clinicians/constants";
import Confirm from "@/components/shared/Confirm";
import DatePicker from "@/components/shared/DatePicker";
import TimePicker from "@/components/shared/TimePicker";
import BaseInput from "@/components/uikit/BaseInput";
import Textarea from "@/components/uikit/Textarea";
import { BLOOD_PRESSURE_ADDED } from "@/eventBuses/monitoringEvents";
import { validatorErrors } from "@/mixins/validatorErrors.js";
import { useAuthStore } from "@/pinia-store/auth";
import { AutoSaveVariants, useAutoSaveStore } from "@/pinia-store/autoSave";
import { useMonitoringStore } from "@/pinia-store/monitoring";
import { useObservationsStore } from "@/pinia-store/observations";
import { ObservationEnum } from "@/types/ObservationEnum";
import { observationTypes } from "@/utils/observationTypes";

export default {
  name: "AddBloodPressure",
  components: {
    TimePicker,
    Textarea,
    DatePicker,
    BaseInput,
    Confirm,
  },
  mixins: [validatorErrors],
  watch: {
    bloodPressureFormOpened: {
      immediate: true,
      handler(val, oldVal) {
        if (val && this.validatorErrors.length && !oldVal) {
          this.validatorErrors = [];
        }
      },
    },
  },
  data: () => ({
    loading: false,
    frequencyList: [],
    startDate: "",
    endDate: "",
    systolicLow: "",
    systolicHigh: "",
    diastolicLow: "",
    diastolicHigh: "",
    patientInstruction: "",
    note: "",
  }),
  computed: {
    ...mapState(useAuthStore, ["uid"]),
    ...mapState(useMonitoringStore, ["orders"]),
    ...mapState(useObservationsStore, ["bloodPressureFormOpened", "bloodPressureForm", "patientId"]),
    isSubmitDisabled() {
      return Boolean(!this.date || !this.value || this.dateErrors.length || this.valueErrors.length);
    },
    selectOptions() {
      return observationTypes.map((ob) => ({ text: ob.display, value: ob.kind }));
    },
  },
  methods: {
    ...mapActions(useAutoSaveStore, ["startSave", "endSave"]),
    ...mapActions(useObservationsStore, [
      "openSickModal",
      "toggleBloodPressureFormOpened",
      "changeBloodPressureFormFormField",
    ]),
    onNoteChange(value) {
      this.changeBloodPressureFormFormField({ field: "note", value });
    },
    onChangeTime(value) {
      this.changeBloodPressureFormFormField({ field: "time", value });
    },
    onFieldChange(value, fieldName) {
      this.changeBloodPressureFormFormField({ field: fieldName, value });
    },
    async onChangeInstruction(value) {
      this.changeBloodPressureFormFormField({ field: "patientInstruction", value });
    },
    onChangeDate(value) {
      this.changeBloodPressureFormFormField({ field: "date", value });
    },
    async performStore() {
      try {
        this.loading = true;
        this.startSave();
        const { date, note, id, patientInstruction, time } = this.bloodPressureForm;
        const systolic = parseInt(this.bloodPressureForm.systolic);
        const diastolic = parseInt(this.bloodPressureForm.diastolic);
        await ObservationsApi.createBloodPressure({
          id,
          patientId: this.patientId,
          note,
          patientInstruction,
          date: this.$moment(date).format(timeISO8601OFormat),
          time,
          systolic: systolic,
          diastolic: diastolic,
        });
        this.loading = false;
        this.toggleBloodPressureFormOpened();
        this.$root.$emit(BLOOD_PRESSURE_ADDED);
        const order = this.orders.find((i) => i.kind === ObservationEnum.bloodPressure);
        let text = [];
        if (order) {
          if (order.systolicLow > systolic) {
            text.push(`You have low systolic blood pressure`);
          }
          if (order.systolicHigh < systolic) {
            text.push(`You have high systolic blood pressure`);
          }
          if (order.diastolicLow > diastolic) {
            text.push(`You have low diastolic blood pressure`);
          }
          if (order.diastolicHigh < diastolic) {
            text.push(`You have high diastolic blood pressure`);
          }
          if (text.length) this.openSickModal(text);
        }
        this.endSave();
        if (!text.length) this.$emit("onSave");
      } catch (err) {
        this.setResponseErrors(err);
        this.loading = false;
        await this.endSave({ variant: AutoSaveVariants.error, endMessage: "Error" });
      }
    },
  },
};
</script>
<style lang="scss">
.order-form {
  .inline-inputs {
    flex-direction: row;
    display: flex;
    align-items: center;
  }

  .card-title {
    font-weight: 500 !important;
    font-size: 25px !important;
    line-height: 31px !important;
    color: #25233a;
  }

  .v-card__title + .v-card__text {
    font-size: 14px;
    line-height: 17px;
    color: #33343d;
  }
}
</style>
