<template>
  <div>
    <v-expansion-panels v-model="panel" accordion class="elevation-0 vitals" flat>
      <v-expansion-panel>
        <v-expansion-panel-header> Vitals</v-expansion-panel-header>
        <v-divider class="full-width"></v-divider>
        <v-expansion-panel-content class="">
          <v-skeleton-loader
            v-if="isLoadingSummary"
            :boilerplatstartTimee="true"
            class="mx-0 full-width"
            type="list-item-three-line, list-item-three-line, list-item-two-line, list-item-two-line, list-item-two-line, list-item-two-line"
          ></v-skeleton-loader>
          <div v-else class="pt-5">
            <ActionField
              :show-add="false"
              :title="`Blood glucose`"
              :unit="(summary.bloodGlucose && summary.bloodGlucose.unit) || ''"
              :value="[
                `before meal: ${(summary.bloodGlucose && summary.bloodGlucose.beforeMeal) || '--'}`,
                `after meal: ${(summary.bloodGlucose && summary.bloodGlucose.afterMeal) || '--'}`,
              ]"
              @onView="toggleBloodGlucoseTableOpened"
            />
            <ActionField
              :show-add="false"
              :title="`Blood pressure`"
              :unit="(summary.bloodPressure && summary.bloodPressure.unit) || ''"
              :value="[
                `diastolic: ${(summary.bloodPressure && summary.bloodPressure.diastolic) || '--'}`,
                `systolic: ${(summary.bloodPressure && summary.bloodPressure.systolic) || '--'}`,
              ]"
              @onView="toggleBloodPressureTableOpened"
            />
            <ActionField
              :show-add="false"
              :title="`Body temperature`"
              :unit="(summary.bodyTemperature && summary.bodyTemperature.unit) || ''"
              :value="(summary.bodyTemperature && summary.bodyTemperature.value) || '--'"
              @onView="toggleBodyTemperatureTableOpened"
            />
            <ActionField
              :show-add="false"
              :title="`Heart rate`"
              :unit="(summary.heartRate && summary.heartRate.unit) || ''"
              :value="(summary.heartRate && summary.heartRate.value) || '--'"
              @onView="toggleHeartRateTableOpened"
            />
            <ActionField
              :show-add="false"
              :title="`Oxygen saturation`"
              :unit="(summary.oxygenSaturation && summary.oxygenSaturation.unit) || ''"
              :value="(summary.oxygenSaturation && summary.oxygenSaturation.value) || '--'"
              @onView="toggleOxygenSaturationTableOpened"
            />
            <ActionField
              :show-add="false"
              :title="`Respiratory rate`"
              :unit="(summary.respiratoryRate && summary.respiratoryRate.unit) || ''"
              :value="(summary.respiratoryRate && summary.respiratoryRate.value) || '--'"
              @onView="toggleRespiratoryRateTableOpened"
            />
          </div>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>

    <DrawerBloodGlucose />
    <DrawerBloodPressure />
    <DrawerBodyTemperature />
    <DrawerHeartRate />
    <DrawerOxygenSaturation />
    <DrawerRespiratoryRate />
  </div>
</template>

<script>
import { mapActions, mapState } from "pinia";

import ActionField from "@/components/VisitNotes/ActionField";
import { useObservationsStore } from "@/pinia-store/observations";
import DrawerBloodGlucose from "@/views/Patient/Monitoring/bloodGlucose/Drawer";
import DrawerBloodPressure from "@/views/Patient/Monitoring/bloodPressure/Drawer";
import DrawerBodyTemperature from "@/views/Patient/Monitoring/bodyTemperature/Drawer";
import DrawerHeartRate from "@/views/Patient/Monitoring/heartRate/Drawer";
import DrawerOxygenSaturation from "@/views/Patient/Monitoring/oxygenSaturation/Drawer";
import DrawerRespiratoryRate from "@/views/Patient/Monitoring/respiratoryRate/Drawer";

export default {
  name: "PatientSummaryVitals",
  components: {
    DrawerRespiratoryRate,
    DrawerOxygenSaturation,
    DrawerHeartRate,
    DrawerBodyTemperature,
    DrawerBloodPressure,
    DrawerBloodGlucose,
    ActionField,
  },
  data() {
    return {
      panel: 1,
    };
  },
  computed: {
    ...mapState(useObservationsStore, ["summary", "isLoadingSummary"]),
  },
  methods: {
    ...mapActions(useObservationsStore, [
      "toggleBloodGlucoseTableOpened",
      "toggleBloodPressureTableOpened",
      "toggleBodyTemperatureTableOpened",
      "toggleHeartRateTableOpened",
      "toggleOxygenSaturationTableOpened",
      "toggleRespiratoryRateTableOpened",
    ]),
  },
};
</script>

<style lang="scss" scoped>
.vitals {
  .card-title {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #33343d;
  }
}
</style>
