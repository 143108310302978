import moment from "moment-timezone";
import { defineStore } from "pinia";

import { EncountersAPI } from "@/api/encounters";
import { useAuthStore } from "@/pinia-store/auth";
import { Encounter } from "@/pinia-store/interfaces/Encounter";
import { Pagination } from "@/types";
import { EncounterQueryFilterDto, EncounterReasonCodeText } from "@/types/EncounterQueryFilter";
import { EncounterStatusEnum } from "@/types/EncounterStatusEnum";

export const defaultPagination: Pagination = {
  page: 1,
  totalPages: 1,
};

interface EncountersState {
  encounters: Encounter[];
  pendedEncounters: Encounter[];
  pendedPendingEncounters: Encounter[];
  pastEncounters: Encounter[];
  todayEncounters: Encounter[];
  currentEncounter: Encounter | null;
  upcomingTabFilter: {
    patientId?: string;
    practitionerId?: string;
    currentDate: string[];
    startDate?: string;
    endDate?: string;
    includeDependents?: boolean;
  };
  pastTabFilter: {
    patientId?: string;
    includeDependents?: boolean;
    practitionerId?: string;
    currentDate: string[];
    startDate?: string;
    endDate?: string;
  };
  pagination: Pagination;
  todayPagination: Pagination;
  pastPagination: Pagination;
}

export const useEncountersStore = defineStore({
  id: "encounters",
  state: (): EncountersState => ({
    todayPagination: defaultPagination,
    todayEncounters: [],
    pendedEncounters: [],
    pendedPendingEncounters: [],
    encounters: [],
    pastEncounters: [],
    currentEncounter: null,
    upcomingTabFilter: {
      currentDate: [moment().format("YYYY-MM-DD"), moment().add(7, "days").format("YYYY-MM-DD")],
    },
    pastTabFilter: {
      includeDependents: true,
      startDate: moment().subtract(7, "days").format("YYYY-MM-DD"),
      endDate: moment().format("YYYY-MM-DD"),
      currentDate: [moment().subtract(7, "days").format("YYYY-MM-DD"), moment().format("YYYY-MM-DD")],
    },
    pagination: {
      page: 1,
      totalPages: 1,
    },
    pastPagination: {
      page: 1,
      totalPages: 1,
    },
  }),
  actions: {
    setUpcomingTabFilter(payload: any) {
      this.upcomingTabFilter = payload;
    },
    setPastTabFilter(payload: { startDate?: any; endDate?: any; date?: any; currentDate?: any }) {
      const { currentDate } = payload;
      const datesSorted = currentDate.sort((a: string, b: string) => moment(a).valueOf() - moment(b).valueOf());
      if (datesSorted.length === 2) {
        payload.startDate = datesSorted[0];
        payload.endDate = datesSorted[1];
        payload.date = null;
      } else {
        payload.date = currentDate[0];
        payload.startDate = null;
        payload.endDate = null;
      }
      this.pastTabFilter = payload;
    },
    setEncounters(payload: any) {
      this.encounters = payload;
    },

    setCurrentEncounter(payload: unknown) {
      this.currentEncounter = payload;
    },
    async getAllEncounters(query?: EncounterQueryFilterDto) {
      const encounters = await EncountersAPI.getAll(query);
      this.encounters = encounters.results;
      this.pagination = encounters.pagination;
    },
    async getPendedEncounters() {
      const encounters = await EncountersAPI.getPended({ reasonCode: EncounterReasonCodeText.draft });
      this.pendedEncounters = encounters.results;
    },
    async getPendedPendingEncounters() {
      const encounters = await EncountersAPI.getPended({ reasonCode: EncounterReasonCodeText.pended });
      this.pendedPendingEncounters = encounters.results;
    },
    async getPractitionerPastEncounters() {
      const authStore = useAuthStore();
      const filters: Partial<EncounterQueryFilterDto> = {
        practitionerId: authStore.uid,
        past: true,
      };
      const { pastTabFilter } = this;
      if (pastTabFilter.patientId) filters.patientId = pastTabFilter.patientId;
      const { currentDate } = pastTabFilter;
      const datesSorted = currentDate.sort((a: string, b: string) => moment(a).valueOf() - moment(b).valueOf());
      if (datesSorted.length === 2) {
        filters.startDate = datesSorted[0];
        filters.endDate = datesSorted[1];
      } else {
        filters.date = currentDate[0];
      }
      const encounters = await EncountersAPI.getAll(filters);
      this.pastEncounters = encounters.results;
      this.pastPagination = encounters.pagination;
    },
    async getPatientPastEncounters() {
      const authStore = useAuthStore();
      const filters: Partial<EncounterQueryFilterDto> = {
        patientId: authStore.uid,
        past: true,
        includeDependents: true,
      };
      const { pastTabFilter } = this;
      if (pastTabFilter.practitionerId) filters.practitionerId = pastTabFilter.practitionerId;
      const { currentDate } = pastTabFilter;
      const datesSorted = currentDate.sort((a: string, b: string) => moment(a).valueOf() - moment(b).valueOf());
      if (datesSorted.length === 2) {
        filters.startDate = datesSorted[0];
        filters.endDate = datesSorted[1];
      } else {
        filters.date = currentDate[0];
      }
      const encounters = await EncountersAPI.getAll(filters);
      this.pastEncounters = encounters.results;
      this.pastPagination = encounters.pagination;
    },
    async getPractitionerUpcomingEncounters() {
      const authStore = useAuthStore();

      const filters: Partial<EncounterQueryFilterDto> = {
        practitionerId: authStore.uid,
        future: true,
      };
      const { upcomingTabFilter } = this;
      if (upcomingTabFilter.patientId) filters.patientId = upcomingTabFilter.patientId;
      const { currentDate } = upcomingTabFilter;
      const datesSorted = currentDate.sort((a: string, b: string) => moment(a).valueOf() - moment(b).valueOf());
      if (datesSorted.length === 2) {
        filters.startDate = datesSorted[0];
        filters.endDate = datesSorted[1];
      } else {
        filters.date = currentDate[0];
      }
      const encounters = await EncountersAPI.getAll(filters);
      this.encounters = encounters.results;
      this.pagination = encounters.pagination;
    },
    async getPractitionerTodayEncounters() {
      const authStore = useAuthStore();

      const currentDate = moment().tz(authStore.timeZone);

      const filters: Partial<EncounterQueryFilterDto> = {
        practitionerId: authStore.uid,
        startDate: currentDate.startOf("day").format("YYYY-MM-DD"),
        endDate: currentDate.endOf("day").format("YYYY-MM-DD"),
      };
      const encounters = await EncountersAPI.getAll(filters);
      this.todayEncounters = encounters.results;
      this.todayPagination = encounters.pagination;
    },
    async getPatientUpcomingEncounters() {
      const authStore = useAuthStore();

      const filters: Partial<EncounterQueryFilterDto> = {
        patientId: authStore.uid,
        future: true,
        includeDependents: true,
      };
      const { upcomingTabFilter } = this;
      if (upcomingTabFilter.practitionerId) filters.practitionerId = upcomingTabFilter.practitionerId;
      const { currentDate } = upcomingTabFilter;
      const datesSorted = currentDate.sort((a: string, b: string) => moment(a).valueOf() - moment(b).valueOf());
      if (datesSorted.length === 2) {
        filters.startDate = datesSorted[0];
        filters.endDate = datesSorted[1];
      } else {
        filters.date = currentDate[0];
      }
      const encounters = await EncountersAPI.getAll(filters);
      this.encounters = encounters.results;
      this.pagination = encounters.pagination;
    },
    async getCurrentEncounterById(encounterId: string) {
      const encounter = await EncountersAPI.getById(encounterId);
      this.currentEncounter = encounter;
      return encounter;
    },
  },
  getters: {
    encounterSumPended: (state) => state.pendedEncounters.length + state.pendedPendingEncounters.length,
    plannedEncounters(state) {
      const now = new Date();
      return state.encounters?.filter
        ? state.encounters.filter((e) => e.status === "planned" && new Date(e.end) >= now)
        : [];
    },
    plannedEncountersPatient(state) {
      const now = new Date();
      return state.encounters?.filter
        ? state.encounters.filter(
            (e) =>
              [EncounterStatusEnum.planned, EncounterStatusEnum.pending].includes(e.status) && new Date(e.end) >= now,
          )
        : [];
    },
  },
});
