import moment from "moment-timezone";
import { mapActions, mapState } from "pinia";

import { useAuthStore } from "@/pinia-store/auth";
import { useAutoSaveStore } from "@/pinia-store/autoSave";

export const monitoringOrder = {
  data: () => ({
    loading: false,
    frequencyList: [],
    maxDate: moment().add(2, "years").toISOString(),
  }),
  computed: {},
  watch: {
    formOpened: {
      immediate: true,
      handler(val) {
        if (val) this.validatorErrors = [];
      },
    },
  },
  methods: {
    ...mapState(useAuthStore, ["uid"]),
    ...mapActions(useAutoSaveStore, ["startSave", "endSave"]),
    onNoteChange(value) {
      this.changeFormField({ field: "note", value });
    },
    onFieldChange(value, field) {
      this.changeFormField({ field, value });
    },
    async onChangeInstruction(value) {
      this.changeFormField({ field: "patientInstruction", value });
    },
    onChangeStartDate(value) {
      this.changeFormField({ field: "startDate", value });
    },
    onChangeEndDate(value) {
      this.changeFormField({ field: "endDate", value });
    },
  },

  created() {
    const medicationFrequency = this.$t("general.medicationFrequency");
    if (medicationFrequency) {
      this.frequencyList = Object.keys(medicationFrequency).map((key) => medicationFrequency[key]);
    }
  },
};
