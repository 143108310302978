<template>
  <div>
    <AdminSettings />
  </div>
</template>

<script>
import AdminSettings from "@/components/settings/admin";
export default {
  name: "adminSettings",
  components: {
    AdminSettings,
  },
};
</script>
