<template>
  <div class="item-wrap">
    <v-icon v-if="!item.seenContent" class="unread-icon"> mdi-circle-small</v-icon>
    <h6 class="text-title">
      {{ item.title }}
    </h6>
    <h6 v-if="item.content" class="text-content">
      {{ item.content }}
    </h6>
    <p class="text-date">
      {{ createdAt }}
      <v-icon v-if="item.fromUser"> mdi-circle-small</v-icon>
    </p>
  </div>
</template>

<script>
import { mapState } from "pinia";

import { useAuthStore } from "@/pinia-store/auth";

export default {
  props: {
    item: {
      type: Object,
      default: () => ({}),
    },
  },
  name: "NotificationItem",
  computed: {
    ...mapState(useAuthStore, ["timeZone"]),
    createdAt() {
      return this.$moment(this.item.createdAt).tz(this.timeZone).fromNow();
    },
  },
};
</script>

<style lang="scss" scoped>
.item-wrap {
  position: relative;
  padding: 15px 28px;
  transition: 0.3s;

  &:hover {
    border-radius: 3px;
    background: rgba(239, 246, 255, 0.4);
    border-color: transparent !important;
  }

  .text-date {
    margin-bottom: 0;
  }

  .unread-icon {
    position: absolute;
    color: $primary;
    font-size: 42px;
    left: -30px;
    top: -4px;
  }

  .text-title {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    text-transform: capitalize;
    color: $primary;
  }

  .text-content {
    margin-top: 3px;
    font-size: 14px;
    line-height: 17px;
    color: $primaryblack2;
  }
}
</style>
