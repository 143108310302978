<template>
  <div>
    <div class="d-schedule-visit">
      <div class="d-schedule-visit__content">
        <Stepper v-if="step !== 5" :isPrevStepRequired="true" :step="step" :stepChange="nextStep" :texts="steps" />
        <div class="go-back-wrapper">
          <div v-if="step !== 1 && step !== 4" class="arrow_back" @click="onPrevStep">
            <v-icon class="mr-1">mdi-arrow-left</v-icon>
          </div>
          <v-progress-linear v-if="loading" color="primary" indeterminate />
          <div v-else class="content-wrapper">
            <v-card v-if="step === 1" class="d-schedule-visit__content__card" max-width="464px" outlined>
              <PastVisitStep :buttonLoading="buttonLoading" @update:buttonLoading="buttonLoading = $event" />
            </v-card>
            <PastVisitChartReview v-if="step === 2" :buttonLoading="buttonLoading" @submit="nextStep" />
            <PastVisitEncounter v-if="step === 3" :buttonLoading="buttonLoading" @submit="nextStep" />
            <PastVisitSummary v-if="step === 4" :buttonLoading="buttonLoading" @submit="nextStep" />
            <v-card
              v-if="step === 5"
              class="d-schedule-visit__content__card"
              max-width="933px"
              outlined
              @submit="nextStep"
            >
              <Success :buttonLoading="buttonLoading" />
            </v-card>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapState } from "pinia";

import PastVisitChartReview from "@/components/AddPastVisit/PastVisitChartReview";
import PastVisitEncounter from "@/components/AddPastVisit/PastVisitEncounter";
import PastVisitStep from "@/components/AddPastVisit/PastVisitStep";
import PastVisitSummary from "@/components/AddPastVisit/PastVisitSummary";
import Success from "@/components/AddPastVisit/Success";
import Stepper from "@/components/shared/Stepper";
import { snackBarEventBus, snackBarEventName } from "@/eventBuses/snackBar.eventBus";
import { useAllergiesStore } from "@/pinia-store/allergies";
import { useAuthStore } from "@/pinia-store/auth";
import { useCheckoutStore } from "@/pinia-store/checkout";
import { useEncountersStore } from "@/pinia-store/encounters";
import { usePastVisitStore } from "@/pinia-store/pastVisit";
import { usePatientStore } from "@/pinia-store/patient";
import { usePatientsStore } from "@/pinia-store/patients";
import { usePractitionersStore } from "@/pinia-store/practitioners";
import { useSlotsStore } from "@/pinia-store/slots";
import { useVisitNoteStore } from "@/pinia-store/visitNote";

export default {
  name: "PastVisit",
  components: {
    PastVisitSummary,
    PastVisitEncounter,
    PastVisitChartReview,
    Stepper,
    PastVisitStep,
    Success,
  },
  data: () => ({
    loading: false,
    buttonLoading: false,
    mode: "create",
  }),
  computed: {
    ...mapState(useAuthStore, ["uid"]),
    ...mapState(usePastVisitStore, ["step"]),
    ...mapState(useCheckoutStore, ["patientId", "slotId"]),
    steps() {
      return {
        _1: "Visit Details",
        _2: "Chart review",
        _3: "Encounter",
        _4: "Visit Summary",
      };
    },
  },
  methods: {
    ...mapActions(useSlotsStore, ["getSlotById", "getPractitionerFreeSlots"]),
    ...mapActions(usePatientStore, ["getPatientProfile"]),
    ...mapActions(usePatientsStore, ["getAllByPractitionerId"]),
    ...mapActions(useCheckoutStore, ["practitionerRequestVisit"]),
    ...mapActions(usePractitionersStore, ["getPractitionerById"]),
    ...mapActions(useVisitNoteStore, ["clearEncounterNotes"]),
    ...mapActions(usePastVisitStore, ["setStep", "setChiefComplaint", "setComplaintDescription", "setDescribeProblem"]),
    ...mapActions(usePastVisitStore, { setPastVisitPatientId: "setPatientId" }),
    ...mapActions(useVisitNoteStore, ["setEncounter", "setPatientId", "setOtherVisitNotes"]),
    ...mapActions(useVisitNoteStore, { setVisitNotePatientId: "setPatientId" }),
    ...mapActions(useAllergiesStore, { setAllergiesPatientId: "setPatientId" }),
    ...mapActions(useEncountersStore, ["setCurrentEncounter"]),

    async submit() {
      this.buttonLoading = true;
      try {
        await this.getPatientProfile(this.patientId);
        await this.getSlotById(this.slotId);
        this.step = 2;
      } catch (err) {
        console.error(err);
        if (err && err.response && err.response.status === 409) {
          snackBarEventBus.$emit(snackBarEventName, {
            message: this.$t("general.errors.slotConflict"),
            type: "error",
          });
        }
        snackBarEventBus.$emit(snackBarEventName, {
          message: (err && err.response && err.response.data && err.response.data.message) || err,
          type: "error",
        });
      }
      this.buttonLoading = false;
    },
    nextStep() {
      this.setStep(this.step + 1);
    },
    onPrevStep() {
      this.setStep(this.step - 1);
    },
    async slotConflict() {
      this.step = 1;
      await this.getPractitionerFreeSlots();
    },
    cleanUp() {
      this.clearEncounterNotes();
      this.setStep(1);
      this.setOtherVisitNotes([]);
      this.setEncounter({});
      this.setPatientId("");
      this.setPastVisitPatientId("");
      this.setVisitNotePatientId("");
      this.setDescribeProblem("");
      this.setChiefComplaint("");
      this.setCurrentEncounter({});
      this.setComplaintDescription("");
    },
  },
  async mounted() {
    if (parseInt(location.href.slice(-1)) === 2) {
      this.step = 2;
    }
    this.cleanUp();
    try {
      await this.getAllByPractitionerId();
      await this.getPractitionerById(this.uid);
    } catch (err) {
      console.error(err);
      snackBarEventBus.$emit(snackBarEventName, {
        message: (err && err.response && err.response.data && err.response.data.message) || err,
        type: "error",
      });
    } finally {
      this.loading = false;
    }
  },
};
</script>
<style lang="scss" scoped>
.go-back-wrapper {
  background-color: #fafafd;
  max-width: calc(100% - 15px);
  width: 100%;
  margin: 0 auto;

  .arrow_back {
    margin-top: 83px;
    width: 36px;
    cursor: pointer;
    flex-basis: 36px;
  }

  .content-wrapper {
    flex-basis: calc(100% - 36px);
  }

  display: flex;
  justify-content: center;
}

.header {
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;

  h4 {
    span {
      color: #6c757d;
    }

    color: #1e1e1e;
    padding-left: 30px;
  }
}
</style>
