import { DateTime } from "luxon";

const formatTime = (value: number) => (value < 10 ? `0${value}` : value);

export const generateTimeslots = (start: string, end: string, interval: number) => {
  const [startHour, startMinute] = start.split(":");
  const [endHour, endMinute] = end.split(":");

  const startDate = DateTime.fromObject({ hour: +startHour, minute: +startMinute });
  const endDate = DateTime.fromObject({ hour: +endHour, minute: +endMinute });
  let updatedDate = startDate;
  const slots = [];
  while (!updatedDate.equals(endDate)) {
    const startStr = `${formatTime(updatedDate.hour)}:${formatTime(updatedDate.minute)}`;
    updatedDate = updatedDate.plus({ minutes: interval });
    slots.push(`${startStr} - ${formatTime(updatedDate.hour)}:${formatTime(updatedDate.minute)}`);
  }

  return slots;
};
