<template>
  <div class="SendSMS">
    <Confirm
      :dialog="sendSmsIsOpened"
      :hide-cancel="true"
      :hide-confirm="true"
      :width="'480px'"
      cancelText="Close"
      max-width="90%"
      title="Send message"
      @toggleDialog="toggleSendSMSDialog"
    >
      <PhoneInput
        :errors="phoneNumberErrors"
        :fullWidth="true"
        :height="48"
        :hide-details="true"
        :value="sendSmsToPhoneNumber"
        class-name="mb-4"
        fieldName="email"
        outlined
        placeholder="Send to phone number"
        required
        @blur="$v.sendSmsToPhoneNumber.$touch()"
        @change="setSendSmsToPhoneNumber"
      />
      <Textarea
        :errors="sendSmsMessageErrors"
        :fullWidth="true"
        :hide-details="true"
        :resize="true"
        :value="sendSmsMessage"
        class="my-2 mb-6"
        fieldName="comments"
        minlength="48"
        placeholder="Write your message"
        @blur="$v.sendSmsMessage.$touch()"
        @change="setSendSmsMessage"
      />
      <PrimaryButton
        :disabled="!sendSmsMessage || !sendSmsToPhoneNumber"
        :loading="isSending"
        full-width
        text="Send message"
        @onClick="sendMessage"
      />
    </Confirm>
  </div>
</template>

<script>
import { mapActions, mapState } from "pinia";
import { required } from "vuelidate/lib/validators";

import Confirm from "@/components/shared/Confirm.vue";
import PhoneInput from "@/components/shared/PhoneInput.vue";
import PrimaryButton from "@/components/uikit/PrimaryButton.vue";
import Textarea from "@/components/uikit/Textarea.vue";
import { snackBarEventBus, snackBarEventName } from "@/eventBuses/snackBar.eventBus";
import { useChatStore } from "@/pinia-store/chat";
import { cleanPhone } from "@/pinia-store/slots";
import { isValidPhoneNumber } from "@/utils/validations";

export default {
  name: "SendSMS",
  components: { PhoneInput, PrimaryButton, Textarea, Confirm },
  data() {
    return {
      isSending: false,
    };
  },
  computed: {
    ...mapState(useChatStore, ["sendSmsMessage", "sendSmsToPhoneNumber", "sendSmsIsOpened"]),
    sendSmsMessageErrors() {
      const errors = [];
      if (!this.$v.sendSmsMessage.$dirty) return errors;
      !this.$v.sendSmsMessage.required && errors.push(this.$t("validation.required", { field: "Message is required" }));
      return errors;
    },
    phoneNumberErrors() {
      const errors = [];
      if (!this.$v.sendSmsToPhoneNumber) return errors;
      if (!this.$v.sendSmsToPhoneNumber.$dirty) return errors;
      if (!isValidPhoneNumber(cleanPhone(this.sendSmsToPhoneNumber)))
        errors.push(this.$t("validation.required", { field: this.$t("general.inputs.phone") }));
      return errors;
    },
  },
  validations: {
    sendSmsToPhoneNumber: { required },
    sendSmsMessage: { required },
  },
  methods: {
    ...mapActions(useChatStore, ["setSendSmsMessage", "setSendSmsToPhoneNumber", "toggleSendSMSDialog"]),
    ...mapActions(useChatStore, ["postSmsMessage"]),
    async sendMessage() {
      if (this.$v.$invalid) return;

      this.isSending = true;
      try {
        await this.postSmsMessage();
        this.setSendSmsMessage("");
        this.setSendSmsToPhoneNumber("");
        this.toggleSendSMSDialog();
        snackBarEventBus.$emit(snackBarEventName, {
          message: "Message was sent successfully",
          type: "info",
        });
      } catch (e) {
        console.error(e);
        snackBarEventBus.$emit(snackBarEventName, {
          message: "There was an error sending the message",
          type: "error",
        });
      } finally {
        this.isSending = false;
      }
      this.$v.$reset();
    },
  },
};
</script>
<style lang="scss" scoped>
.SendSMS {
}
</style>
