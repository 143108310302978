<template>
  <div>
    <div v-if="pendingRoomInfo && Object.keys(pendingRoomInfo).length" class="visit-room">
      <div class="visit-room-header">
        <span class="visit-room-header-select"
          >{{ $t("visits.visitRoomWith") }}
          {{ `${pendingRoomInfo.patient.firstName} ${pendingRoomInfo.patient.lastName}` }}</span
        >
        <div class="visit-room-header-bordered">
          <v-icon color="icon">mdi-calendar-blank</v-icon>
          <p>{{ dateFormated }}</p>
        </div>
        <div class="visit-room-header-bordered">
          <v-icon color="icon">mdi-clock-outline</v-icon>
          <p>{{ timeRange }}</p>
        </div>
      </div>
      <v-row>
        <v-col lg="8" sm="12">
          <v-container class="visit-notes" fluid>
            <v-card>
              <v-row align="center" justify="space-between">
                <v-col>
                  <v-card-text v-if="timer" style="font-size: 16px">
                    <span class="text--secondary">Visit starts in:</span>
                    {{ `${timer.split(":")[0]} minutes, ${timer.split(":")[1]} seconds` }}
                  </v-card-text>
                </v-col>
                <v-col class="text-right mr-3">
                  <OutlinedButton
                    :text="$t('practitioner.appointments.cancelVisit')"
                    @onClick="cancelVisitDialog = true"
                  />
                </v-col>
              </v-row>
            </v-card>
          </v-container>
          <VisitNotes />
        </v-col>
        <v-col lg="4" sm="12">
          <v-alert
            v-if="pendingRoomInfo.status === 'initialized'"
            dense
            icon="mdi-alert-outline"
            style="font-size: 11px"
            text
            type="error"
          >
            The visit wasn't confirmed by a patient yet
          </v-alert>
          <VisitDescription />
        </v-col>
      </v-row>
      <CancelVisit
        v-model="cancelVisitDialog"
        :isFromAppointments="true"
        :slotId="pendingRoomInfo && pendingRoomInfo.slot.id"
        @success="$router.push('/owner/appointments')"
      />
    </div>
    <div v-if="currentEncounter && Object.keys(currentEncounter).length" class="visit-room">
      <div class="visit-room-header">
        <span class="visit-room-header-select"
          >{{ $t("visits.visitRoomWith") }} {{ currentEncounter.patient.name }}</span
        >
        <div class="visit-room-header-bordered">
          <v-icon color="icon">mdi-calendar-blank</v-icon>
          <p>{{ dateFormated }}</p>
        </div>
        <div class="visit-room-header-bordered">
          <v-icon color="icon">mdi-clock-outline</v-icon>
          <p>{{ timeRange }}</p>
        </div>
      </div>
      <v-row>
        <v-col lg="8" sm="12">
          <v-container class="visit-notes" fluid>
            <v-card>
              <v-row align="center" justify="space-between">
                <v-col>
                  <v-card-text v-if="timer" style="font-size: 16px">
                    <span class="text--secondary">Visit starts in:</span>
                    {{ `${timer.split(":")[0]} minutes, ${timer.split(":")[1]} seconds` }}
                  </v-card-text>
                </v-col>
                <v-col class="text-right mr-3">
                  <OutlinedButton
                    :text="$t('practitioner.appointments.cancelVisit')"
                    @onClick="cancelVisitDialog = true"
                  />
                </v-col>
              </v-row>
            </v-card>
          </v-container>
          <VisitNotes />
        </v-col>
        <v-col lg="4" sm="12">
          <VisitDescriptionScheduled />
        </v-col>
      </v-row>
      <CancelVisit
        v-model="cancelVisitDialog"
        :appointmentId="currentEncounter && currentEncounter.appointmentId"
        :isFromAppointments="true"
        @success="$router.push('/owner/appointments')"
      />
    </div>
  </div>
</template>

<script>
import { DateTime } from "luxon";
import { mapActions, mapState } from "pinia";

import CancelVisit from "@/components/CancelVisit";
import OutlinedButton from "@/components/uikit/OutlinedButton";
import VisitDescription from "@/components/VisitDescription/Pending/Practitioner";
import VisitDescriptionScheduled from "@/components/VisitDescription/Practitioner";
import VisitNotes from "@/components/VisitNotes/index.vue";
import { useCheckoutStore } from "@/pinia-store/checkout";
import { useEncountersStore } from "@/pinia-store/encounters";
import { useVisitNoteStore } from "@/pinia-store/visitNote";

export default {
  name: "OwnerVisitDatails",
  components: { VisitDescription, VisitNotes, OutlinedButton, CancelVisit, VisitDescriptionScheduled },
  data() {
    return {
      loading: false,
      error: null,
      timerInterval: null,
      timer: null,
      cancelVisitDialog: false,
    };
  },
  computed: {
    ...mapState(useCheckoutStore, ["pendingRoomInfo"]),
    ...mapActions(useEncountersStore, ["currentEncounter"]),
    currentUrl() {
      return window.location.pathname.split("/")[2];
    },
    dateFormated() {
      if (Object.keys(this.pendingRoomInfo).length) {
        return DateTime.fromISO(this.pendingRoomInfo.slot.start).toFormat("dd MMMM y");
      }
      if (Object.keys(this.currentEncounter).length) {
        return DateTime.fromISO(this.currentEncounter.start).toFormat("dd MMMM y");
      }
      return "";
    },
    timeRange() {
      if (Object.keys(this.pendingRoomInfo).length) {
        const start = DateTime.fromISO(this.pendingRoomInfo.slot.start).toFormat("t");
        const end = DateTime.fromISO(this.pendingRoomInfo.slot.end).toFormat("t");
        return `${start} - ${end}`;
      }
      if (Object.keys(this.currentEncounter).length) {
        const start = DateTime.fromISO(this.currentEncounter.start).toFormat("t");
        const end = DateTime.fromISO(this.currentEncounter.end).toFormat("t");
        return `${start} - ${end}`;
      }
      return "";
    },
  },
  methods: {
    ...mapActions(useVisitNoteStore, ["setAppointmentId"]),
    ...mapActions(useCheckoutStore, ["getPendingRoomInfo"]),
    ...mapActions(useVisitNoteStore, ["getPendingVisitNote", "getVisitNote"]),
    ...mapActions(useEncountersStore, ["getCurrentEncounterById"]),

    async timerUpdate() {
      this.timer = await this.calculateTimer();
    },
    async calculateTimer() {
      if (Object.keys(this.pendingRoomInfo).length) {
        const diff = DateTime.fromISO(this.pendingRoomInfo.slot.start).diffNow("seconds");
        return diff.toFormat("mm:ss");
      }
      if (Object.keys(this.currentEncounter).length) {
        const diff = DateTime.fromISO(this.currentEncounter.start).diffNow("seconds");
        return diff.toFormat("mm:ss");
      }
    },
  },
  async mounted() {
    const { slotId, encounterId } = this.$route.params;
    let currentUrl = window.location.pathname;
    if (currentUrl.includes("pending")) {
      await this.getPendingRoomInfo(slotId);
      if (this.getPendingRoomInfo && Object.keys(this.pendingRoomInfo).length) {
        await this.getPendingVisitNote(this.pendingRoomInfo);
        this.loading = false;
      }
    } else {
      await this.getCurrentEncounterById(encounterId);
      const appointmentId = this.currentEncounter && this.currentEncounter.appointmentId;
      this.setAppointmentId(appointmentId);
      await this.getVisitNote();
    }
    this.loading = false;
    this.timerInterval = setInterval(this.timerUpdate, 1000);
  },
};
</script>

<style scoped></style>
