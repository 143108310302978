<template>
  <div class="patients-history-table">
    <v-row class="mb-5" dense>
      <v-col>
        <v-data-table
          v-if="localItems && Object.keys(localItems).length"
          :class="`table data-table data-table__green-border table-bordered ${className}`"
          :headers="headers"
          :height="'auto'"
          :items="localItems"
          disable-sort
          hide-default-footer
          mobile-breakpoint="0"
        >
          <template v-slot:item.action="{ item }">
            <div class="table_actions">
              <v-menu bottom content-class="patient-body-height__edit-menu" left offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-bind="attrs" v-on="on">mdi-dots-horizontal</v-icon>
                </template>
                <v-list>
                  <v-list-item @click="editItem(item)">
                    <v-list-item-title class="patient-body-height__edit action-btn">
                      {{ $t("general.edit") }}
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="deleteItem(item.id)">
                    <v-list-item-title class="patient-body-height__delete action-btn">
                      {{ $t("general.delete") }}
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
          </template>
          <template v-slot:item.name="{ item }">
            <div>
              {{ item.name }}
            </div>
            <div v-if="item.freeText" class="freeText">Free text</div>
          </template>
          <template v-slot:item.year="{ item }">
            <div>
              {{ item.year }}
            </div>
          </template>
          <template v-slot:no-data>
            <span class="data-table__no-items">
              {{ $t("general.table.noItems") }}
            </span>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <div v-if="editable" :class="className">
      <v-row align-content="center" dense>
        <v-col cols="12" md="7" xs="12">
          <v-autocomplete
            v-model="name"
            :cache-items="false"
            :errors="nameErrors"
            :items="searchResults"
            :loading="isLoadingConditions"
            :placeholder="$t('settings.enterConditions')"
            :search-input.sync="conditionsSearch"
            class="bd-grey patient__allergy-search"
            clearable
            fieldName="name"
            hide-details
            hide-no-data
            label="Enter past illness"
            solo
            @change="onSelectCondition"
          ></v-autocomplete>
        </v-col>
        <v-col cols="12" md="3" xs="12">
          <YearPicker :height="48" :value="year" @change="onChangeDate" />
        </v-col>
        <v-col class="d-flex" cols="12" lg="2" md="3">
          <OutlinedButton
            :disabled="isSubmitDisabled"
            :fullWidth="true"
            :text="$t('general.add')"
            additionalClassName="outlined-btn__green "
            size="large"
            width="80px"
            @onClick="onSubmit"
          />
        </v-col>
      </v-row>
    </div>
    <PatientHistoryTableEdit
      v-model="editDialog"
      :editItem="editableItem"
      :patientId="id"
      @onChange="onChangeEditItem"
      @toggleDialog="toggleEditDialog"
    />
  </div>
</template>
<script>
import debounce from "lodash/debounce";
import { mapActions, mapState } from "pinia";
import shortid from "shortid";
import { required } from "vuelidate/lib/validators";

import { TerminologyApi } from "@/api/terminology";
import YearPicker from "@/components/shared/YearPicker";
import OutlinedButton from "@/components/uikit/OutlinedButton";
import { useCheckoutStore } from "@/pinia-store/checkout";

import PatientHistoryTableEdit from "./Edit";

export default {
  name: "PatientHistoryTable",
  props: {
    items: [],
    place: null,
    id: null,
    tableClassName: null,
    largeLayout: null,
    editable: {
      default: true,
      type: Boolean,
    },
  },
  components: {
    PatientHistoryTableEdit,
    YearPicker,
    OutlinedButton,
  },
  data: () => ({
    searchResults: [],
    editDialog: false,
    editableItem: {},
    name: "",
    isLoadingConditions: false,
    year: null,
    conditionsSearch: null,
    pmhList: [],
    updated: false,
    pmhs: [],
  }),
  mounted() {
    this.pmhs = this.pmh;
  },
  validations: {
    name: { required },
  },
  watch: {
    conditionsSearch: debounce(function (val) {
      if (!val || val === this.name) return;
      this.isLoadingDrugs = true;
      TerminologyApi.getConditions({ name: val }).then((values) => {
        this.isLoadingConditions = false;
        this.searchResults = [val, ...(values.map((value) => value.name) || [])];
      });
    }),
  },
  computed: {
    ...mapState(useCheckoutStore, ["pmh"]),
    isSubmitDisabled() {
      return Boolean(!this.name);
    },
    nameErrors() {
      const errors = [];
      if (!this.$v.name.$dirty) return errors;
      const field = this.$t("visits.medical.name");
      !this.$v.name.required && errors.push(this.$t("validation.required", { field }));
      return errors;
    },
    localItems() {
      const newData = this.items.map((item) => ({
        ...item,
        id: shortid.generate(),
        _id: item.id,
      }));

      return newData;
    },
    className() {
      return this.place === "checkout" ? "ph-table_request-visit" : "ph-table_visit-notes";
    },
    headers() {
      return [
        {
          text: this.$t("settings.profile.pastMedicalHistory"),
          value: "name",
          width: "70%",
        },
        {
          text: this.$t("general.calendar.year"),
          value: "year",
          width: "25%",
          input: "number",
          placeholder: "Add year",
        },
        { text: "", value: "action", width: "5%", align: "right" },
      ];
    },
  },
  methods: {
    ...mapActions(useCheckoutStore, ["setPMH"]),
    onChangeDate(e) {
      this.year = e;
    },
    async onChangeEditItem(selectedValue) {
      this.editDialog = false;
      const newItems = this.localItems.map((i) => (i.id === selectedValue.id ? { ...selectedValue } : { ...i }));
      this.$emit("change", [...newItems]);
    },
    async toggleEditDialog() {
      this.editDialog = false;
    },
    editItem(item) {
      this.editableItem = { ...item };
      setTimeout(() => (this.editDialog = true), 500);
    },
    onSelectCondition(selectedValue) {
      this.name = selectedValue;
    },
    onSubmit() {
      const newItems = this.localItems.map((it) => ({ id: it._id, name: it.name, year: it.year }));
      newItems.push({
        name: this.name,
        year: this.year || "",
      });
      this.$emit("change", [...newItems]);
      setTimeout(() => {
        this.name = "";
        this.year = "";
      }, 50);
    },
    onYearChange({ id, value }) {
      const newItems = this.localItems.map((item) => {
        if (item.id === id) {
          item.year = value;
        }
        return item;
      });
      this.$emit(
        "change",
        newItems.map((item) => ({ id: item._id, name: item.name, year: item.year })),
      );
    },
    translateChiefComplaint(val) {
      return this.$t("general.chiefComplaints")[val] || val || "-";
    },
    deleteItem(id) {
      const newItems = this.localItems.filter((i) => i.id !== id);
      this.$emit(
        "change",
        newItems.map((item) => ({ id: item._id, ...item })),
      );
    },
  },
};
</script>

<style lang="scss">
.patients-history-table {
  width: 100%;

  .v-data-table {
    flex-direction: column;
  }
}

.outlined-btn__green {
  color: var(--v-success-base);

  &:not(:disabled) .v-btn__content {
    color: var(--v-success-base);
  }
}

.checkbox-list {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  margin: 20px;
  width: 100%;
  row-gap: 10px;
  @include mobile {
    grid-template-columns: repeat(1, 1fr);
  }

  &__item {
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;

    label,
    input {
      cursor: pointer;
    }
  }
}

.sub-text {
  width: 100%;
  margin-top: 20px;
  font-weight: bold;
}
</style>
<style lang="scss">
td.text-start {
  width: 50%;
}

.freeText {
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 13px;

  color: #a7a9c0;

  mix-blend-mode: normal;
  opacity: 0.9;
}
</style>
