<template>
  <div class="modal-mask">
    <div class="modal-wrapper">
      <div class="modal-container">
        <div class="modal-container-padding">
          <div class="modal-header">
            <div class="monitoring-form card-title">Check your audio and video</div>
            <!-- <slot name="header">default header</slot> -->
            <button class="modal-default-button danger" @click="$emit('onClose')">
              <img alt="Close" src="@/assets/Close.png" />
            </button>
          </div>

          <div class="modal-body">
            <slot name="body">
              <div class="video-container">
                <div v-if="!isVideo" class="cameraOff">Camera is off</div>
                <video ref="videoElement" autoplay playsinline />
                <v-flex align-center class="control-settings" d-flex justify-center>
                  <v-btn v-if="isAudio" class="mx-2" color="black" fab large outlined @click="toggleAudio">
                    <v-icon color="white">fa-microphone</v-icon>
                  </v-btn>
                  <v-btn v-else class="mx-2" color="#E53935" fab large @click="toggleAudio">
                    <v-icon color="white">fa-microphone-slash</v-icon>
                  </v-btn>
                  <v-btn v-if="isVideo" class="mx-2" color="black" fab large outlined @click="toggleVideo">
                    <v-icon color="white">mdi-video</v-icon>
                  </v-btn>
                  <v-btn v-else class="mx-2" color="#E53935" fab large @click="toggleVideo">
                    <v-icon color="white">mdi-video-off-outline</v-icon>
                  </v-btn>
                </v-flex>
                <div v-if="isAudio" class="pids-wrapper">
                  <div class="pid pid1"></div>
                  <div class="pid pid2"></div>
                  <div class="pid pid3"></div>
                  <div class="pid pid4"></div>
                  <div class="pid pid5"></div>
                  <div class="pid pid6"></div>
                  <div class="pid pid7"></div>
                  <div class="pid pid8"></div>
                  <div class="pid pid9"></div>
                  <div class="pid pid10"></div>
                </div>
              </div>
              <div class="preview">Preview how you’ll look and sound</div>
              <div class="readyToJoin">No one else see this. Ready to join?</div>
              <PrimaryButton :loading="loading" class="call" text="Join Visit" @onClick="$emit('viewDetailsClick')" />
            </slot>
          </div>
        </div>
        <div class="modal-footer">
          <div class="controls-wrap">
            <div class="pa-0 align-center">
              <v-icon dark>mdi-video</v-icon>
              <v-select
                v-model="selectedVideoInput"
                :items="videoInputDevices"
                class="select-with-icon transparent"
                dark
                dense
                hide-details
                item-text="label"
                item-value="deviceId"
                menu-props="auto"
                outlined
              ></v-select>
            </div>
            <div class="pa-0 align-center">
              <v-icon dark>fa-microphone</v-icon>
              <v-select
                v-model="selectedAudioInput"
                :items="audioInputDevices"
                class="select-with-icon transparent"
                dark
                dense
                hide-details
                item-text="label"
                item-value="deviceId"
                menu-props="auto"
                outlined
              ></v-select>
            </div>
            <div class="pa-0 align-center">
              <v-icon dark>fa-volume-up</v-icon>
              <v-select
                v-model="selectedAudioOutput"
                :items="audioOutputDevices"
                class="select-with-icon transparent"
                dark
                dense
                hide-details
                item-text="label"
                item-value="deviceId"
                menu-props="auto"
                outlined
              ></v-select>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { DateTime } from "luxon";
import { mapActions, mapState } from "pinia";

import PrimaryButton from "@/components/uikit/PrimaryButton";
import { snackBarEventBus, snackBarEventName } from "@/eventBuses/snackBar.eventBus";
import { useAuthStore } from "@/pinia-store/auth";
import { useVideoRoomStore } from "@/pinia-store/videoRoom";

export default {
  name: "TestingAudioVideo",
  components: { PrimaryButton },
  // props: ["videoInput", "audioInput", "audioOutput"],
  data() {
    return {
      interval: null,
      timerInterval: null,
      timer: "",
      timerInSeconds: 0,
      cancelDialog: false,
      stream: null,
      analyserStream: null,
      microphone: null,
      dialog: false,
      loading: true,
      average: 0,
      audioOutputDevices: [],
      audioInputDevices: [],
      videoInputDevices: [],
      selectedAudioInput: "",
      selectedAudioOutput: "",
      selectedVideoInput: "",
    };
  },
  computed: {
    ...mapState(useVideoRoomStore, [
      "isVideo",
      "isAudio",
      "room",
      "roomIsStarted",
      "encounter",
      "roomNotFound",
      "roomIsExpired",
      "roomIsReady",
      "settings",
    ]),
    ...mapState(useAuthStore, ["role"]),
  },
  methods: {
    ...mapActions(useVideoRoomStore, ["startRoom", "getRoomStatus", "setDefaultRoomSettings"]),
    ...mapActions(useVideoRoomStore, ["setUserIsReady", "setIsVideo", "setIsAudio", "setRoomSettings"]),
    toggleVideo() {
      this.setIsVideo(!this.isVideo);
      this.$emit("onToggleVideo");
    },
    toggleAudio() {
      this.setIsAudio(!this.isAudio);
      this.$emit("onToggleAudio");
    },
    toggleDialog() {
      this.dialog = !this.dialog;
    },
    async turnOnVideo() {
      const videoElement = this.$refs.videoElement;
      if (videoElement && navigator.mediaDevices.getUserMedia) {
        this.stream = await navigator.mediaDevices.getUserMedia({
          video: true,
        });
        videoElement.srcObject = this.stream;
      }
    },
    async onToggleAudio() {
      if (!this.localParticipant) {
        return;
      }
      if (this.isAudio) {
        await this.audioInputPublish();
      } else {
        await this.audioInputUnPublish();
      }
    },
    async turnOffVideo() {
      const videoElement = this.$refs.videoElement;
      if (videoElement) {
        videoElement.srcObject = null;
      }
      if (this.stream) {
        this.stream.getTracks().forEach((track) => {
          this.stream.removeTrack(track);
          track.stop();
        });
        this.stream = null;
      }
    },
    timerUpdate() {
      if (this.room) {
        let diff = DateTime.fromISO(this.room.expectedStartDate).diffNow("seconds").as("seconds");
        if (diff < 0) {
          diff = 0;
        }
        const hours = Math.floor(diff / 3600);
        const minutes = Math.floor((diff % 3600) / 60);
        const seconds = Math.floor((diff % 3600) % 60);
        const hoursStr = hours ? `${hours} hours, ` : "";
        const minutesStr = minutes ? `${minutes} minutes, ` : "";
        const secondsStr = `${seconds} seconds`;
        this.timer = `${hoursStr}${minutesStr}${secondsStr}`;
        this.timerInSeconds = diff;
        if (!this.timerInSeconds) {
          window.clearInterval(this.timerInterval);
          this.timerInterval = null;
        }
      }
    },
  },
  async beforeDestroy() {
    window.clearInterval(this.interval);

    const tracks = this.stream.getTracks();
    tracks.forEach((track) => {
      this.stream.removeTrack(track);
      track.stop();
    });
    this.stream = null;

    this.analyserStream.getTracks().forEach((track) => {
      this.analyserStream.removeTrack(track);
      track.stop();
    });
    this.analyserStream = null;
    this.microphone.disconnect();
    this.microphone = null;
    window.clearInterval(this.timerInterval);
    this.timerInterval = null;
  },
  async mounted() {
    this.loading = true;
    try {
      await navigator.mediaDevices.getUserMedia({
        video: true,
        audio: true,
      });
    } catch (err) {
      console.error(err, navigator);
      snackBarEventBus.$emit(snackBarEventName, {
        message: `Can't get media devices, please check your audio and video`,
        type: "error",
      });
      console.error("On testing video", err);
    }

    const devices = await navigator.mediaDevices.enumerateDevices();
    this.videoInputDevices = devices.filter((device) => device.kind === "videoinput");
    this.audioInputDevices = devices.filter((device) => device.kind === "audioinput");
    this.audioOutputDevices = devices.filter((device) => device.kind === "audiooutput");
    this.selectedAudioInput = this.audioInputDevices[0] && this.audioInputDevices[0].deviceId;
    this.selectedAudioOutput = this.audioOutputDevices[0] && this.audioOutputDevices[0].deviceId;
    this.selectedVideoInput = this.videoInputDevices[0] && this.videoInputDevices[0].deviceId;
    await this.setDefaultRoomSettings();
    if (this.isVideo) {
      await this.turnOnVideo();
      this.average = 1;
    }
    this.interval = setInterval(() => {
      this.getRoomStatus();
    }, 3000);
    this.timerInterval = setInterval(this.timerUpdate, 1000);
    setTimeout(() => {
      this.loading = false;
    }, 1000);
  },
  watch: {
    async isVideo() {
      if (this.isVideo) {
        await this.turnOnVideo();
      } else {
        await this.turnOffVideo();
      }
    },
    isAudio() {
      if (this.isAudio) {
        this.average = 1;
      }
    },
    audioInput() {
      this.stream = navigator.mediaDevices.getUserMedia({
        audio: { deviceId: this.selectedAudioInput },
      });
      this.analyserStream = navigator.mediaDevices.getUserMedia({
        audio: { deviceId: this.selectedAudioInput },
        video: true,
      });
      this.setRoomSettings({
        videoInput: this.settings && this.settings.videoInput,
        audioInput: this.selectedAudioInput,
        audioOutput: this.settings && this.settings.audioOutput,
      });
    },
    videoInput() {
      this.stream = navigator.mediaDevices.getUserMedia({
        video: { deviceId: this.selectedVideoInput },
      });
      this.setRoomSettings({
        videoInput: this.selectedVideoInput,
        audioInput: this.settings && this.settings.audioInput,
        audioOutput: this.settings && this.settings.audioOutput,
      });
    },
    average() {
      navigator.mediaDevices
        .getUserMedia({
          audio: true,
          video: true,
        })
        .then((stream) => {
          this.analyserStream = stream;
          const audioContext = new AudioContext();
          const analyser = audioContext.createAnalyser();
          this.microphone = audioContext.createMediaStreamSource(this.analyserStream);
          const scriptProcessor = audioContext.createScriptProcessor(2048, 1, 1);
          analyser.smoothingTimeConstant = 0.8;
          analyser.fftSize = 1024;
          this.microphone.connect(analyser);
          analyser.connect(scriptProcessor);
          scriptProcessor.connect(audioContext.destination);
          scriptProcessor.onaudioprocess = function () {
            let numb = 1;
            const array = new Uint8Array(analyser.frequencyBinCount);
            analyser.getByteFrequencyData(array);
            const arraySum = array.reduce((a, value) => a + value, 0);
            this.average = arraySum / array.length;
            const allPids = [...document.querySelectorAll(".pid")];
            const numberOfPidsToColor = Math.round(this.average / 10);
            const pidsToColor = allPids.slice(0, numberOfPidsToColor);
            for (const pid of allPids) {
              pid.style.backgroundColor = "#EEEFF7";
            }
            for (const pid of pidsToColor) {
              if (numb <= 4) {
                pid.style.backgroundColor = "#F2994A";
              } else if (numb >= 5 && numb <= 8) {
                pid.style.backgroundColor = "#2FCF6F";
              } else {
                pid.style.backgroundColor = "#E53935";
              }
              numb = numb + 1;
            }
          };
        })
        .catch(function (err) {
          /* handle the error */
          // console.error(err);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-footer {
  width: 100%;
  background: #1e1e1e;
  border: 1px solid rgba(46, 46, 46, 0.56);
  margin-top: 14px;
}

.modal-default-button {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 34px;
  height: 34px;
  top: 5px;
  right: 5px;
  background: #000000;
  border-radius: 100%;
  font-size: 25px;
  color: #ffffff;
}

.modal-mask {
  position: fixed;
  z-index: 6;
  top: 0;
  left: 0;
  width: 100%;
  max-width: 100vw;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  margin-top: 20px;
  display: flex;
  width: 95%;
  max-width: 840px;
  vertical-align: middle;
}

.video-container {
  text-align: center;
  width: 100%;
  position: relative;
  border: 1px solid white;
  border-radius: 10px;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 70%, #000000 100%);
  overflow: hidden;
  margin-top: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
}

video {
  z-index: 1;
  position: relative;
  height: 550px;
  max-height: 70vh;
  width: 100%;
  max-width: 100%;
  object-fit: cover;
}

.cameraOff {
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  color: #ffffff;
  opacity: 0.68;
  position: absolute;
}

.control-settings {
  position: absolute;
  bottom: 31px;
  width: 100%;
  z-index: 11;
  display: flex !important;
  justify-content: center !important;
  margin-top: 21px;

  button {
    border: 1.5px solid #ffffff;
    border-radius: 100px;
  }
}

.pids-wrapper {
  width: 50%;
  position: absolute;
  left: 25px;
  bottom: 31px;
  display: flex;
  flex-direction: column-reverse;
  z-index: 10;
}

.pid {
  width: 6px;
  height: 17px;
  display: inline-block;
  margin: 5px;
}

.preview {
  margin-top: 14px;
  font-weight: normal;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  color: #ffffff;
}

.readyToJoin {
  font-size: 13px;
  line-height: 16px;
  text-align: center;
  color: #ffffff;
  margin-top: 10px;
  opacity: 0.56;
}

.modal-container {
  position: relative;
  margin: 0 auto;
  background: #1e1e1e !important;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  height: 100%;
  overflow: auto;
}

.modal-container-padding {
  padding: 20px 25px 0 25px;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.card-title {
  &.monitoring-form {
    font-weight: bold;
    font-style: normal;
    font-size: 28px !important;
    line-height: 34px !important;
    color: white !important;
    @include mobile {
      font-size: 20px !important;
    }
  }
}

.controls-wrap {
  display: flex;
  flex-direction: row;
  gap: 18px;
  flex-wrap: wrap;
  padding: 10px 0 10px 25px;

  > div {
    display: flex;
    gap: 18px;
    flex-basis: 250px;
    flex-direction: row;
    @include tablet {
      flex-basis: calc(100% - 20px);
    }

    .v-icon {
      width: 40px;
      margin-right: 10px;
    }

    .select-with-icon {
      width: 240px;
      @include tablet {
        max-width: calc(100% - 100px);
        width: 320px;
      }
    }
  }
}
</style>
