<template>
  <v-flex class="controls">
    <slot name="append" />
    <v-flex align-center class="control-settings" d-flex justify-center>
      <v-btn v-if="isAudio" class="mx-2" color="white" fab large outlined @click="toggleAudio">
        <v-icon color="white">fa-microphone</v-icon>
      </v-btn>
      <v-btn v-else class="mx-2" color="primary" fab large @click="toggleAudio">
        <v-icon color="white">fa-microphone-slash</v-icon>
      </v-btn>
      <v-btn v-if="!isPractitionerAndRoomNotStarted" class="mx-2" color="error" fab large @click="hangup">
        <v-icon color="white">mdi-phone-hangup</v-icon>
      </v-btn>
      <v-btn v-if="isVideo" class="mx-2" color="white" fab large outlined @click="toggleVideo">
        <v-icon color="white">mdi-video</v-icon>
      </v-btn>
      <v-btn v-else class="mx-2" color="primary" fab large @click="toggleVideo">
        <v-icon color="white">mdi-video-off-outline</v-icon>
      </v-btn>
      <SettingsBtn
        @onChangeAudioInput="$emit('onChangeAudioInput')"
        @onChangeAudioOutput="$emit('onChangeAudioOutput')"
        @onChangeVideoInput="$emit('onChangeVideoInput')"
      />
    </v-flex>
    <Confirm
      :cancelText="$t('general.dialog.cancel')"
      :confirmText="$t('visits.finish.button')"
      :dialog="dialog"
      :loading="loading"
      :text="$t('visits.finish.text')"
      :title="$t('visits.finish.title')"
      @confirm="finish"
      @toggleDialog="toggleDialog"
    />
  </v-flex>
</template>
<script>
import { mapActions, mapState } from "pinia";

import SettingsBtn from "@/components/VideoRoom/SettingsBtn.vue";
import { snackBarEventBus, snackBarEventName } from "@/eventBuses/snackBar.eventBus";
import { useAuthStore } from "@/pinia-store/auth";
import { useMeetingRoomStore } from "@/pinia-store/meetingRoom";
import { RolesEnum } from "@/types/Roles.enum";
import { routesEnum } from "@/types/Routes.enum";

import Confirm from "../shared/Confirm.vue";

export default {
  props: {
    participant: Object,
  },
  components: {
    SettingsBtn,
    Confirm,
  },
  data() {
    return {
      dialog: false,
      loading: false,
    };
  },
  computed: {
    ...mapState(useMeetingRoomStore, ["isVideo", "isAudio", "isModalMode", "roomId", "roomIsReady"]),
    ...mapState(useAuthStore, ["role"]),
    isPractitionerAndRoomNotStarted() {
      return this.role === RolesEnum.Practitioner && !this.roomIsReady;
    },
  },
  methods: {
    ...mapActions(useMeetingRoomStore, ["finishRoom", "fetchRoom", "getMeetingRooms"]),
    ...mapActions(useMeetingRoomStore, ["setIsVideo", "setIsAudio"]),
    ...mapActions(useMeetingRoomStore, [
      "setRoomId",
      "setIdentityId",
      "setIsModalMode",
      "setOpenedListDialog",
      "setOpenedMeetingRoom",
    ]),
    toggleVideo() {
      this.setIsVideo(!this.isVideo);
      this.$emit("onToggleVideo");
    },
    toggleAudio() {
      this.setIsAudio(!this.isAudio);
      this.$emit("onToggleAudio");
    },
    toggleDialog() {
      this.dialog = !this.dialog;
    },
    async hangup() {
      if (this.role === RolesEnum.Practitioner) {
        this.toggleDialog();
      } else {
        await this.$router.push({ name: routesEnum.signin });
      }
    },
    async finish() {
      this.loading = true;
      this.$emit("onToggleVideo");
      this.$emit("onToggleAudio");
      try {
        await this.finishRoom();
        await this.fetchRoom({ meetingRoomId: this.isModalMode ? this.roomId : this.$route.params.roomId });
        await this.getMeetingRooms();
        this.setRoomId(null);
        this.setIdentityId(null);
        this.setIsModalMode(false);
        this.setOpenedMeetingRoom(false);
      } catch (e) {
        console.error(e);
        snackBarEventBus.$emit(snackBarEventName, { message: e.message || e, type: "error" });
      }
      this.toggleDialog();
      this.loading = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.controls {
  width: 100%;
  position: absolute;
  bottom: 45px;
  z-index: 2;

  .settings {
    position: absolute;
    z-index: 1;
    left: 20px;
  }

  .control-settings {
    width: 100%;
    left: 0;
    top: 28px;
    text-align: center;

    button {
      width: 45px;
      height: 45px;
      border-color: #666769;

      .v-icon {
        font-size: 18px;
      }
    }
  }
}
</style>
