import { CancelTokenSource } from "axios";

import { apiRequest } from "./apiRequest";

export interface AxisFormData extends FormData {
  file: File;
  patientId: string;
  component?: string;
  componentId?: string;
}

const create = async (body: AxisFormData): Promise<any> => {
  // todo temp FormData argument type investigate if can be replaced with appropiate type
  const { data } = await apiRequest.post("/media", body, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  return data;
};

const uploadUrl = `${process.env.VUE_APP_BASE_URL}media`;

const getFiles = async (filter: Record<any, any> = {}, cancelToken?: CancelTokenSource): Promise<any> => {
  const { data } = await apiRequest
    .get("/media", {
      params: filter,
      ...(cancelToken ? { cancelToken: cancelToken.token } : {}),
    })
    .catch(() => ({ data: [] }));
  return data;
};
const getChatFiles = async (roomId?: string, filter: Record<any, any> = {}): Promise<any> => {
  const { data } = await apiRequest.get("/media/chat", {
    params: { roomId, ...(filter || {}) },
  });
  return data;
};

const getComponentFiles = async (filter: Record<any, any> = {}): Promise<any> => {
  const { data } = await apiRequest.get("/media/component", {
    params: { ...(filter || {}) },
  });
  return data;
};
const toggleClinical = async (fileId: string): Promise<any> => {
  const { data } = await apiRequest.post("/media/clinical", {
    id: fileId,
  });
  return data;
};
const renameFile = async (fileId: string, name: string): Promise<any> => {
  const { data } = await apiRequest.post(`/media/${fileId}/rename`, {
    name,
  });
  return data;
};
const destroy = async (fileId: string): Promise<any> => {
  const { data } = await apiRequest.delete(`/media/${fileId}`);
  return data;
};

export const MediaApi = {
  uploadUrl,
  getChatFiles,
  getComponentFiles,
  destroy,
  renameFile,
  toggleClinical,
  create,
  getFiles,
};
