<template>
  <div class="monitoring-layout">
    <v-progress-linear v-if="loading" color="primary" indeterminate></v-progress-linear>
    <v-row>
      <v-col v-if="!hideHeaders" class="header" cols="12">
        <span class="heading-2">{{ title }}</span>
        <PrimaryButton v-if="userIsPatient" :text="`Add ${title}`" leftIcon="mdi-plus" @onClick="onCreate" />
      </v-col>
      <v-col cols="12">
        <BodyTemperatureTable :disable-edit="!userIsPatient" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapActions, mapState } from "pinia";

import { ServiceRequestApi } from "@/api/serviceRequest";
import PrimaryButton from "@/components/uikit/PrimaryButton";
import { BODY_TEMPERATURE_ADDED } from "@/eventBuses/monitoringEvents";
import { useAuthStore } from "@/pinia-store/auth";
import { useObservationsStore } from "@/pinia-store/observations";
import { RolesEnum } from "@/types/Roles.enum";
import BodyTemperatureTable from "@/views/Patient/Monitoring/bodyTemperature/Table";

export default {
  name: "MonitoringBodyTemperature",
  components: { BodyTemperatureTable, PrimaryButton },
  props: {
    hideHeaders: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    ...mapState(useAuthStore, ["uid", "role"]),
    title() {
      return "Body Temperature";
    },
    userIsPatient() {
      return this.role === RolesEnum.Patient;
    },
  },
  methods: {
    ...mapActions(useObservationsStore, ["getBodyTemperature"]),
    ...mapActions(useObservationsStore, [
      "setBodyTemperatureOrder",
      "setPatientId",
      "clearBodyTemperatureForm",
      "fillBodyTemperatureForm",
      "toggleBodyTemperatureFormOpened",
    ]),
    async reload() {
      this.loading = true;
      await this.getBodyTemperature();
      this.loading = false;
    },
    onCreate() {
      this.clearBodyTemperatureForm();
      this.toggleBodyTemperatureFormOpened();
    },
    async onEdit(item) {
      await this.fillBodyTemperatureForm(item);
      this.toggleBodyTemperatureFormOpened();
    },
  },
  beforeDestroy() {
    this.$root.$off(BODY_TEMPERATURE_ADDED, this.reload);
  },
  async mounted() {
    const patientId = this.userIsPatient ? this.uid : this.$route.params.patientId;
    await this.setPatientId(patientId);
    this.$root.$on(BODY_TEMPERATURE_ADDED, this.reload);
    this.loading = true;
    await this.reload();
    try {
      const order = await ServiceRequestApi.getPatientBodyTemperatureOrder(patientId);
      this.setBodyTemperatureOrder(order);
    } finally {
      this.loading = false;
    }
  },
};
</script>

<style lang="scss">
@import "../monitoring-layout";
</style>
