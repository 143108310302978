import { apiRequest } from "./apiRequest";

const segment = "auth";
const requestRecoverPassword = async (email: string) => {
  const { data } = await apiRequest.post(`/${segment}/request-reset-password`, { email });
  return data;
};

const logout = async (userId: string | undefined, email: string | null | undefined) => {
  const { data } = await apiRequest.post(`/${segment}/logout`, { userId, email });
  return data;
};
const verifyPasswordResetCode = async (token: string) => {
  const { data } = await apiRequest.post(`/${segment}/verify-reset-token`, { token });
  return data;
};
const changePassword = async (body: { token: string; password: string }) => {
  const { data } = await apiRequest.post(`/${segment}/change-password`, body);
  return data;
};
const getUserById = async (id: string) => {
  const { data } = await apiRequest.get(`/${segment}/${id}`);
  return data;
};

const getSSOCustomToken = async (ssoToken: string): Promise<{ customToken: string }> => {
  const { data } = await apiRequest.get("/auth/sso", { params: { token: ssoToken } });
  return data;
};

const getAnonymousCustomToken = async (): Promise<{ customToken: string }> => {
  const { data } = await apiRequest.get("/auth/anonymous-token");
  return data;
};

const getUserTenant = async (email: string) => {
  const { data } = await apiRequest.get(`/auth/user/tenant?email=${email}`);
  return data;
};

const proxyToPatient = async (id: string) => {
  const { data } = await apiRequest.get(`/${segment}/proxy-patient/${id}`);
  return data;
};

const proxyPatients = async () => {
  const { data } = await apiRequest.get(`/${segment}/proxy-patient`);
  return data;
};

export const AuthApi = {
  getSSOCustomToken,
  proxyToPatient,
  proxyPatients,
  getUserTenant,
  changePassword,
  verifyPasswordResetCode,
  requestRecoverPassword,
  getUserById,
  getAnonymousCustomToken,
  logout,
};
