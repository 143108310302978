<template>
  <v-row class="visit-room-content relative" no-gutters>
    <v-skeleton-loader v-if="isLoading" type="list-item-avatar" width="100%"></v-skeleton-loader>
    <v-col v-else cols="12" sm="12">
      <SignedVisitNote :item="item" />
    </v-col>
    <NotFoundCard v-if="notFound" btn-text="Go back" @onClick="redirectToVisitCenter" />
  </v-row>
</template>

<script>
import { mapState } from "pinia";

import { EncountersAPI } from "@/api/encounters";
import NotFoundCard from "@/components/shared/NotFoundCard";
import { ADDENDUM_NOTE_ADDED } from "@/eventBuses";
import { useAuthStore } from "@/pinia-store/auth";
import { RolesEnum } from "@/types/Roles.enum";
import SignedVisitNote from "@/views/Practitioner/SignedVisit/SignedVisitNote";

export default {
  name: "PractitionerVisitNote",
  components: { NotFoundCard, SignedVisitNote },
  data() {
    return {
      isLoading: true,
      notFound: false,
      item: {},
    };
  },
  computed: {
    ...mapState(useAuthStore, ["role"]),
  },
  methods: {
    async redirectToVisitCenter() {
      this.$emit("done");
      return this.role === RolesEnum.Patient
        ? await this.$router.push("/patient/visits/upcoming")
        : await this.$router.push(`/${this.role}/appointments/scheduled`);
    },
    async getVisitNote() {
      this.isLoading = true;
      const { encounterId } = this.$route.params;
      try {
        this.item = await EncountersAPI.getDocument(encounterId);
      } catch (err) {
        this.notFound = true;
      } finally {
        this.isLoading = false;
      }
    },
  },
  async mounted() {
    await this.getVisitNote();
    this.$root.$on(ADDENDUM_NOTE_ADDED, this.getVisitNote);
  },
  beforeDestroy() {
    this.$root.$off(ADDENDUM_NOTE_ADDED, this.getVisitNote);
  },
};
</script>

<style scoped></style>
