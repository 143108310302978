<template>
  <v-flex :class="{ fullscreen: isFullScreen }" class="video-room">
    <v-snackbar
      v-if="connectedParticipant"
      :absolute="true"
      :centered="true"
      :top="true"
      :value="connectedParticipant"
      color="white"
    >
      {{ connectedParticipant.displayName }} joined the visit.
    </v-snackbar>
    <Loading v-if="loading" />
    <ErrorConnection v-if="error" />
    <AllowAccess v-if="!isPermissionsAccepted" />
    <v-flex
      v-if="isPermissionsAccepted && !error"
      ref="container"
      :class="{ fullscreen: isFullScreen }"
      class="video-room-container"
    >
      <div v-if="roomIsReady">
        <VisitFullScreenBtn v-if="!$vuetify.breakpoint.xsOnly" />
        <RemoteParticipant
          v-if="!loading"
          :call-time="callTime"
          :initials="partnerInitials"
          :muted="participantIsMute"
          :participant="partnerParticipant"
          :room="room"
          :title="partnerTitle"
        />
        <LocalParticipant
          v-if="localParticipant && isVideo"
          :class="isVideo ? '' : 'd-none'"
          :participant="localParticipant"
        />
      </div>
      <SettingsBtn
        v-if="$vuetify.breakpoint.xsOnly"
        @onChangeAudioInput="onChangeAudioInput"
        @onChangeAudioOutput="onChangeAudioOutput"
        @onChangeVideoInput="onChangeVideoInput"
      />
      <WaitingRoom v-if="!roomIsReady" />

      <VisitMediaControls @onToggleAudio="onToggleAudio" @onToggleVideo="onToggleVideo">
        <template v-slot:start>
          <VideoRoomMedia />
        </template>
      </VisitMediaControls>
      <VisitMediaControlsSelect
        v-if="!$vuetify.breakpoint.xsOnly && isPermissionsAccepted && !error"
        @onChangeAudioInput="onChangeAudioInput"
        @onChangeAudioOutput="onChangeAudioOutput"
        @onChangeVideoInput="onChangeVideoInput"
      />
    </v-flex>
  </v-flex>
</template>

<script>
import { mapActions, mapState } from "pinia";
import Video, { createLocalAudioTrack, createLocalTracks, createLocalVideoTrack } from "twilio-video";

import { snackBarEventBus, snackBarEventName } from "@/eventBuses/snackBar.eventBus";
import { WS_USER_DISCONNECTED } from "@/eventBuses/socketEvents";
import { useAuthStore } from "@/pinia-store/auth";
import { useVideoRoomStore } from "@/pinia-store/videoRoom";

import VideoRoomMedia from "./media/VideoRoomMedia";

export default {
  name: "VideoRoom",
  components: {
    VideoRoomMedia,
    VisitMediaControls: () => import("./VisitMediaControls"),
    VisitFullScreenBtn: () => import("./FullScreenBtn"),
    SettingsBtn: () => import("./SettingsBtn"),
    VisitMediaControlsSelect: () => import("./VisitMediaControlsSelect.vue"),
    AllowAccess: () => import("./overlays/AllowAccess.vue"),
    Loading: () => import("./overlays/Loading.vue"),
    LocalParticipant: () => import("./LocalParticipant.vue"),
    RemoteParticipant: () => import("./RemoteParticipant/Index.vue"),
    ErrorConnection: () => import("./overlays/ErrorConnection.vue"),
    WaitingRoom: () => import("./WaitingRoom.vue"),
  },
  props: {
    callTime: String,
  },
  data() {
    return {
      opt: {},
      participants: [],
      videoRoom: null,
      isInConnection: false,
      loading: false,
      localParticipant: null,
      error: null,
      participantIsMute: false,
    };
  },
  computed: {
    ...mapState(useVideoRoomStore, [
      "isFullScreen",
      "practitionerProfile",
      "patientProfile",
      "isVideo",
      "isAudio",
      "hasVideoDevice",
      "hasAudioDevice",
      "isPermissionsAccepted",
      "accessToken",
      "room",
      "settings",
      "roomIsReady",
      "encounter",
      "connectedParticipant",
    ]),
    ...mapState(useAuthStore, ["role", "isFromApp", "email"]),
    partnerParticipant() {
      return this.participants[0];
    },
    videoSettings() {
      const deviceId = this.settings && this.settings.videoInput;
      return deviceId
        ? {
            deviceId: { exact: deviceId },
          }
        : null;
    },
    audioSettings() {
      const deviceId = this.settings && this.settings.audioInput;
      return deviceId
        ? {
            deviceId: { exact: deviceId },
          }
        : null;
    },
    partnerInitials() {
      return `${this.partnerFirstName?.[0]?.toUpperCase() || ""} ${this.partnerLastName?.[0]?.toUpperCase() || ""}`;
    },
    partnerTitle() {
      const title = this.role === "patient" ? this.$t("general.titles.dr") : "";
      return `${title} ${this.partnerFirstName} ${this.partnerLastName}`;
    },
    partnerFirstName() {
      if (!this.encounter) {
        return "";
      }
      const partner = this.role === "patient" ? this.encounter.practitioner : this.encounter.patient;
      return partner.name.split(" ")[0];
    },

    partnerLastName() {
      if (!this.encounter) {
        return "";
      }
      const partner = this.role === "patient" ? this.encounter.practitioner : this.encounter.patient;
      return partner.name.split(" ")[1];
    },
  },
  methods: {
    ...mapActions(useVideoRoomStore, ["setIsPermissionsAccepted", "setHasAudioDevice", "setHasVideoDevice"]),
    ...mapActions(useVideoRoomStore, ["getRoomAccessToken", "getRoomStatus", "getConnectedParticipant"]),
    userWSDisconnected(user) {
      if (!this.isInConnection && this.room && this.roomIsReady && this.isPermissionsAccepted) {
        const partner = this.role === "patient" ? this.encounter.practitioner : this.encounter.patient;
        if (partner?.id !== user.uid) return;
        snackBarEventBus.$emit(snackBarEventName, {
          message: `${this.partnerFirstName || ""} ${
            this.partnerLastName || ""
          } don't seem to have an active internet connection. Please wait.`,
          type: "info",
        });
      }
    },
    participantConnected(participant) {
      this.isInConnection = true;
      setTimeout(() => {
        this.isInConnection = false;
      }, 1200);
      this.participants.push(participant);
      this.getConnectedParticipant(participant.identity);
    },
    participantDisconnected(participant) {
      this.participants = this.participants.filter((el) => el.sid !== participant.sid);
    },
    participantMuted(track) {
      if (track.kind !== "audio") return;
      this.participantIsMute = true;
    },
    participantUnmuted(track) {
      if (track.kind !== "audio") return;
      this.participantIsMute = false;
    },
    async onToggleVideo() {
      if (!this.localParticipant) {
        return;
      }
      if (this.isVideo) {
        await this.videoEnable();
      } else {
        await this.videoDisable();
      }
    },
    async onToggleAudio() {
      if (!this.localParticipant) {
        return;
      }
      if (this.isAudio) {
        await this.audioInputPublish();
      } else {
        await this.audioInputUnPublish();
      }
    },
    async videoDisable() {
      await this.localParticipant.videoTracks.forEach((item) => {
        item.track.disable();
        // item.track.detach();
        // stop not enable again transmission
        // item.track.stop();
      });
    },
    async videoEnable() {
      await this.localParticipant.videoTracks.forEach((item) => {
        item.track.enable();
      });
    },
    async videoUnPublish() {
      for (const item of this.localParticipant.videoTracks.values()) {
        item.track.stop();
        await this.localParticipant.unpublishTrack(item.track);
      }
    },

    async videoPublish() {
      const track = await createLocalVideoTrack(this.videoSettings);
      await this.localParticipant.publishTrack(track);
    },
    async audioInputUnPublish() {
      for (const item of this.localParticipant.audioTracks.values()) {
        item.track.stop();
        await this.localParticipant.unpublishTrack(item.track);
      }
    },
    async audioInputPublish() {
      const track = await createLocalAudioTrack(this.audioSettings);
      await this.localParticipant.publishTrack(track);
    },
    async audioOutputSetup(room) {
      if (this.settings && this.settings.audioOutput) {
        room.on("trackSubscribed", (track) => {
          if (track.kind === "audio") {
            const audioElement = track.attach();
            audioElement.setSinkId(this.settings.audioOutput).then(() => document.body.appendChild(audioElement));
          }
        });
      }
    },
    async onChangeAudioInput() {
      if (!this.localParticipant) {
        return;
      }
      await this.audioInputUnPublish();
      if (this.isAudio) {
        await this.audioInputPublish();
      }
    },
    async onChangeAudioOutput() {
      if (!this.localParticipant) {
        return;
      }

      this.onConnect();
    },
    async onChangeVideoInput() {
      if (!this.localParticipant) {
        return;
      }
      await this.videoUnPublish();
      if (this.isVideo) {
        await this.videoPublish();
      }
    },
    async disconnect() {
      if (this.videoRoom) {
        await this.videoUnPublish();
        await this.audioInputUnPublish();
        this.videoRoom.localParticipant.tracks.forEach(function (track) {
          track.stop();
        });
        await this.videoRoom.disconnect();
        this.videoRoom = null;
        this.localParticipant = null;
        this.participants = [];
      }
    },
    async onConnect() {
      if (this.loading) {
        return false;
      }
      this.loading = true;
      try {
        await navigator.mediaDevices.getUserMedia({ audio: true, video: true });
      } catch (err) {
        console.error("On request Media device", err);
      }
      try {
        this.error = null;
        // await this.disconnect();

        const opt = {
          name: this.email,
          networkQuality: {
            local: 1,
            remote: 1,
          },
        };
        if (this.settings) {
          opt.tracks = await createLocalTracks({
            audio: this.settings.audioInput,
            video: this.settings.videoInput,
          });
        }
        this.opt = opt;
        const room = await Video.connect(this.accessToken, opt);
        this.localParticipant = room.localParticipant;
        //
        await this.onToggleAudio();
        await this.onToggleVideo();
        room.participants.forEach((item) => this.participants.push(item));
        await this.audioOutputSetup(room);
        room.on("trackUnpublished", this.participantMuted);
        room.on("trackPublished", this.participantUnmuted);
        room.on("participantConnected", this.participantConnected);
        room.on("participantDisconnected", this.participantDisconnected);
        this.videoRoom = room;
      } catch (e) {
        console.error(e);
        const stream = await navigator.mediaDevices.getUserMedia({ audio: true, video: true });

        if (stream.getVideoTracks().length > 0 && stream.getAudioTracks().length > 0) {
          this.setIsPermissionsAccepted(false);
        }
        if (this.isPermissionsAccepted) this.error = e.message || e;
        snackBarEventBus.$emit(snackBarEventName, { message: this.error, type: "error" });
        await this.disconnect();
      } finally {
        this.loading = false;
      }
    },
    onOffline() {
      snackBarEventBus.$emit(snackBarEventName, {
        message: `No Internet connection. Make sure Wi-Fi or cellular data is turned on, then try again.`,
        type: "error",
      });
    },
    onOnline() {
      snackBarEventBus.$emit(snackBarEventName, {
        message: `Internet connection was restored!`,
        type: "info",
      });
      if (this.room && this.roomIsReady && this.isPermissionsAccepted) this.onConnect();
    },
  },
  watch: {
    async roomIsReady() {
      if (this.room && this.roomIsReady && this.isPermissionsAccepted) {
        await this.getRoomAccessToken();
        await this.onConnect();
      }
    },
    async partnerParticipant() {
      await this.getRoomStatus();
    },
  },

  async mounted() {
    this.sockets.subscribe(WS_USER_DISCONNECTED, this.userWSDisconnected);
    if (!navigator.onLine) this.onOffline();
    window.addEventListener("offline", this.onOffline);

    window.addEventListener("online", this.onOnline);
    this.$userActivityTracker.destroy();

    let stream;
    try {
      stream = await navigator.mediaDevices.getUserMedia({ video: true });
      stream.getTracks().forEach((track) => track.stop());
    } catch (err) {
      this.setHasVideoDevice(false);
    }
    try {
      stream = await navigator.mediaDevices.getUserMedia({
        audio: true,
      });
      stream.getTracks().forEach((track) => track.stop());
      this.setIsPermissionsAccepted(true);
      if (this.room && this.roomIsReady && this.isPermissionsAccepted) {
        await this.getRoomAccessToken();
        await this.onConnect();
      }
    } catch (err) {
      if (this.isFromApp) {
        if (this.hasVideoDevice) {
          this.setIsPermissionsAccepted(true);
          if (this.room && this.roomIsReady && this.isPermissionsAccepted) {
            await this.getRoomAccessToken();
            await this.onConnect();
          }
        }
      } else {
        this.setHasAudioDevice(false);
        this.setIsPermissionsAccepted(false);
      }
    }
  },
  async beforeDestroy() {
    window.removeEventListener("offline", this.onOffline);
    window.removeEventListener("online", this.onOnline);
    this.sockets.unsubscribe(WS_USER_DISCONNECTED);

    await this.disconnect();
    this.$userActivityTracker.startTimer();
  },
};
</script>

<style lang="scss">
$video-room-height: 100%;
.video-room {
  position: relative;
  background: black;
  border-radius: 5px;
  max-height: calc(100vh - 63px);
  min-height: 240px;

  &:fullscreen,
  &.fullscreen {
    max-height: 100vh;
    height: 100vh;
  }

  &-container {
    width: 100%;
    height: calc(100vh - $video-call-header-height - var(--footer-height));
    min-height: 240px;
    border-radius: 5px 5px 0 0;

    &:fullscreen,
    &.fullscreen {
      max-height: 100vh;
    }

    @media (max-width: 767.98px) {
      bottom: 0;
    }

    .participant {
      &-local {
        overflow: hidden;
        position: absolute;
        width: 20%;
        z-index: 2;
        bottom: 75px;
        right: 30px;
        padding: 0;

        video {
          background: #1e1e1e;
          position: relative;
          width: 100%;
          margin: 0;
          border-radius: 4px;
          border: 1px solid $white;
          transform: rotateY(180deg);
        }
      }

      &-remote {
        background: #1e1e1e;
        height: 100%;
        width: 100%;

        &-component {
          background: #1e1e1e;
          height: 100%;
          width: 100%;

          &.fullscreen {
            height: 100vh;
          }

          video {
            height: 100%;
            width: 100%;
          }
        }
      }

      &-waiting {
        background: #1e1e1e;
        height: 100%;
        width: 100%;

        video {
          height: 100%;
          width: 100%;
          opacity: 0.2;
          transform: rotateY(180deg);
        }
      }
    }
  }
}

.v-snack__wrapper {
  top: 40px !important;
  position: fixed;
}

.v-snack__content {
  color: #000000 !important;
  border-bottom: 4px solid #249d54 !important;
}

.v-snack__action {
  margin: 0 !important;
}
</style>
