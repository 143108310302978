<template>
  <div class="file-row">
    <div class="file-row__container">
      <v-icon v-if="file.format === 'document'" class="file-row__icon file-row__attachment-icon">
        mdi-attachment
      </v-icon>
      <v-icon v-else class="file-row__icon"> mdi-image</v-icon>
      <div class="file-row__title-wrap" @click="openFile">
        <div class="file-row__name">{{ file.name }}</div>
        <div class="file-row__size">∙ {{ getSize(file.size) }}</div>
        <v-icon v-if="file.fhirId">$vuetify.icons.radioChecked</v-icon>
      </div>
      <div>
        <div class="table_actions">
          <v-menu
            v-if="hasSomeActions"
            :close-on-click="true"
            :close-on-content-click="true"
            content-class="file-row__edit-menu"
            left
            offset-y
            top
          >
            <template v-slot:activator="{ on, attrs }">
              <v-icon v-bind="attrs" v-on="on">mdi-dots-horizontal</v-icon>
            </template>
            <v-list>
              <v-list-item v-if="editable" @click="toggleClinical">
                <v-list-item-title class="file-row__edit action-btn">
                  {{ file.fhirId ? "Unmark as Clinical file" : "Mark as Clinical file" }}
                </v-list-item-title>
              </v-list-item>
              <v-list-item v-if="!hideRename" @click="edit">
                <v-list-item-title class="file-row__edit action-btn"> Rename</v-list-item-title>
              </v-list-item>
              <v-list-item v-if="!hideDelete" @click="destroy">
                <v-list-item-title class="file-row__delete action-btn"> Delete</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
      </div>
    </div>
    <v-progress-linear v-if="loading" color="primary" indeterminate />
    <Confirm
      v-if="isPdf"
      :dialog="preview"
      :hide-confirm="true"
      max-width="90%"
      text=""
      title=""
      width="1200px"
      @toggleDialog="preview = false"
    >
      <PdfPreview :fileUrl="file.url" />
    </Confirm>
  </div>
</template>

<script>
import { MediaApi } from "@/api/media";
import PdfPreview from "@/components/media/PdfPreview";
import Confirm from "@/components/shared/Confirm";

export default {
  components: { PdfPreview, Confirm },
  props: {
    file: {},
    editable: {
      default: true,
      type: Boolean,
    },
    hideRename: {
      default: false,
      type: Boolean,
    },
    hideDelete: {
      default: false,
      type: Boolean,
    },
  },
  name: "FileRow",
  data() {
    return {
      loading: false,
      preview: false,
    };
  },
  computed: {
    isPdf() {
      return this.file?.url?.endsWith(".pdf");
    },
    hasSomeActions() {
      return this.editable || !this.hideRename || !this.hideDelete;
    },
  },
  methods: {
    getSize: (size) => {
      return `${parseInt(size / 1024)} kb`;
    },
    async edit() {
      this.$emit("onEdit", this.file);
    },
    openFile() {
      if (this.isPdf) {
        this.preview = true;
        return;
      }
      if (this.file?.format === "image") {
        this.$viewerApi({
          images: [this.file.url],
        });
        return;
      }
      window.open(this.file.url, "_blank");
    },
    async destroy() {
      this.loading = true;
      await MediaApi.destroy(this.file.id);
      this.$emit("onRemove", this.file);
      this.loading = false;
    },
    async toggleClinical() {
      this.loading = true;
      const newFile = await MediaApi.toggleClinical(this.file.id);
      this.$emit("onChange", newFile);
      this.loading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.file-row {
  margin-bottom: 20px;

  &__container {
    border: 1px solid #eeeff7;
    box-sizing: border-box;
    border-radius: 4px;
    display: flex;
    padding: 12px 16px;
    justify-content: space-between;
  }

  &__title-wrap {
    cursor: pointer;
    display: flex;
    flex-basis: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__edit-menu {
    text-align: center;
    background: #ffffff;
    border: 1px solid #eeeff7;
    box-shadow: none;
    box-sizing: border-box;
    border-radius: 4px;

    .v-list-item:not(:last-child) {
      border-bottom: 1px solid #eeeff7;
    }

    .v-list {
      padding-top: 0;
      padding-bottom: 0;
    }

    .v-list-item:hover {
      cursor: pointer;
      background: rgba(34, 148, 242, 0.08);

      &:before {
        opacity: 0;
      }
    }
  }

  &__edit {
    color: $primary;
  }

  &__delete {
    color: $error;
  }

  &__attachment-icon {
    transform: rotate(-60deg);
    margin-top: -9px;
  }

  &__icon {
    flex-basis: 36px;
    font-size: 18px;
    color: #2196f3;
    display: flex;
    justify-content: flex-start;
  }

  &__name {
    padding-right: 4px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #33343e;
    word-break: break-word;
    max-width: 100%;
  }

  &__size {
    text-transform: uppercase;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #a7a9c0;
    min-width: 70px;
  }
}
</style>
