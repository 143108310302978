import { Client, ConnectionState, Conversation, Participant, User } from "@twilio/conversations";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { shareReplay } from "rxjs/operators";

import { ChatApi } from "@/api/chat";
import TwilioClientConnectionError from "@/context/twilioContext/errors/TwilioClientConnectionError";
import ITwilioClient from "@/context/twilioContext/ITwilioClient";
import { ConnectionErrorPayload } from "@/context/twilioContext/types/ConnectionErrorPayload";
import { TwilioClientEvent } from "@/context/twilioContext/types/TwilioClientEvent";

class TwilioClient implements ITwilioClient {
  private twilioToken: string | null = null;
  private client: Client | null = null;
  private readonly _clientStatus = new BehaviorSubject<ConnectionState>(this.client?.connectionState ?? "unknown");
  private readonly _clientEvents = new Subject<TwilioClientEvent>();

  get token(): string | null {
    return this.twilioToken;
  }

  get clientStatus$(): Observable<ConnectionState> {
    return this._clientStatus?.pipe(shareReplay(1));
  }

  get clientEvents$(): Observable<TwilioClientEvent> {
    return this._clientEvents.asObservable();
  }

  public async getChannelBySid(sid: string): Promise<Conversation> {
    if (!this.client) throw new TwilioClientConnectionError(this.client);
    return this.client.getConversationBySid(sid);
  }

  public async getUser(identity: string): Promise<User> {
    if (!this.client) throw new TwilioClientConnectionError(this.client);
    return this.client.getUser(identity);
  }

  public async connectClient(uid?: string): Promise<void> {
    this.twilioToken = await ChatApi.access(uid);
    this.client?.shutdown();
    this.client = null;

    this.client = new Client(this.twilioToken);
    this.client.on(Client.connectionStateChanged, (state) => {
      if (state === "connected") {
        this.client?.on(Client.conversationAdded, (payload) => {
          console.log("Twilio events: ", Client.conversationAdded, payload);
          this._clientEvents.next({ type: Client.conversationAdded, payload });
        });
        this.client?.on(Client.conversationRemoved, (payload) => {
          console.log("Twilio events: ", Client.conversationRemoved, payload);
          this._clientEvents.next({ type: Client.conversationRemoved, payload });
        });
        this.client?.on(Client.conversationUpdated, (payload) => {
          console.log("Twilio events: ", Client.conversationUpdated, payload);
          this._clientEvents.next({ type: Client.conversationUpdated, payload });
        });
        this.client?.on(Client.tokenExpired, () => {
          console.log("Twilio events: ", Client.tokenExpired);
          this._clientEvents.next({ type: Client.tokenExpired, payload: undefined });
        });
        this.client?.on(Client.connectionError, (payload: ConnectionErrorPayload) => {
          console.log("Twilio events: ", Client.connectionError, payload);
          this._clientEvents.next({ type: Client.connectionError, payload });
        });
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        this.client?.on(Client.tokenAboutToExpire, (payload: number) =>
          this._clientEvents.next({ type: Client.tokenAboutToExpire, payload }),
        );
        this.client?.on(Client.participantJoined, (payload: Participant) => {
          console.log("Twilio events: ", Client.participantJoined, payload);
          this._clientEvents.next({ type: Client.participantJoined, payload });
        });
        this.client?.on(Client.participantLeft, (payload: Participant) => {
          console.log("Twilio events: ", Client.participantLeft, payload);
          this._clientEvents.next({ type: Client.participantLeft, payload });
        });
      }
      this._clientStatus.next(state);
    });
  }

  public async shutdown(): Promise<void> {
    this.client?.shutdown();
  }

  public async listChannels(): Promise<Conversation[]> {
    if (!this.client) throw new Error("There is no active Twilio Client");
    const conversations: Conversation[] = [];

    await this.client.getSubscribedConversations().then(async (wrappedConversations) => {
      wrappedConversations.items.forEach((conversation) => conversations.push(conversation));
      if (wrappedConversations.hasNextPage) {
        let nextPage = await wrappedConversations.nextPage();
        nextPage.items.forEach((conversation) => conversations.push(conversation));
        while (nextPage.hasNextPage) {
          nextPage = await nextPage.nextPage();
          nextPage.items.forEach((descriptor) => conversations.push(descriptor));
        }
      }
    });

    return conversations;
  }
}

export default TwilioClient;
