import { defineStore } from "pinia";

import { Patient } from "@/types/users";

interface PastVisit {
  patientId: string;
  step: number;
  patientInfo: {
    firstName?: string;
    lastName?: string;
    age?: number;
    sex?: string;
  };
  clock: string;
  chiefComplaint: string;
  describeProblem: string;
  complaintDescription: string;
  dateStart: string;
  dateEnd: string;
  visitStart: string;
  visitEnd: string;
  file: File[];
}

export const usePastVisitStore = defineStore({
  id: "pastVisit",
  state: (): PastVisit => ({
    patientInfo: {
      firstName: "",
      lastName: "",
      sex: "",
    },
    step: 1,
    patientId: "",
    clock: "00:00 - 00:15",
    chiefComplaint: "",
    describeProblem: "",
    complaintDescription: "",
    dateStart: "",
    dateEnd: "",
    visitStart: "",
    visitEnd: "",
    file: [],
  }),
  actions: {
    setPatientInfo(payload: Patient) {
      this.patientInfo = payload;
    },
    setStep(payload: any) {
      this.step = payload;
    },
    setPatientId(payload: string) {
      this.patientId = payload;
    },
    setClock(payload: string) {
      this.clock = payload;
    },
    setChiefComplaint(payload: string) {
      this.chiefComplaint = payload;
    },
    setDescribeProblem(payload: string) {
      this.describeProblem = payload;
    },
    setComplaintDescription(payload: string) {
      this.complaintDescription = payload;
    },
    setDateStart(payload: string) {
      this.dateStart = payload;
    },
    setDateEnd(payload: string) {
      this.dateEnd = payload;
    },
    setVisitStart(payload: string) {
      this.visitStart = payload;
    },
    setVisitEnd(payload: string) {
      this.visitEnd = payload;
    },
    setFile(payload: any) {
      this.file = payload;
    },
  },
  getters: {
    getFile(state) {
      return state.file;
    },
  },
});
