<template>
  <v-row>
    <v-col cols="12">
      <v-progress-linear v-if="loading" color="primary" indeterminate></v-progress-linear>
      <PatientMedia />
    </v-col>
  </v-row>
</template>

<script>
import { mapActions, mapState } from "pinia";

import PatientMedia from "@/components/MedicalRecord/PatientMedia";
import { useMediaStore } from "@/pinia-store/media";
import { useMedicalRecordStore } from "@/pinia-store/medicalRecord";

export default {
  name: "MedicalRecordPatientFiles",
  components: {
    PatientMedia,
  },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    ...mapState(useMedicalRecordStore, ["patient", "encounters"]),
  },
  methods: {
    ...mapActions(useMediaStore, ["fetchFiles"]),
    ...mapActions(useMediaStore, ["setUid", "setComponent", "setFiles"]),
  },
  async mounted() {
    this.loading = true;
    if (this.uid !== this.$route.params.patientId) {
      this.setUid(this.$route.params.patientId);
      this.setFiles([]);
    }
    this.setComponent("");
    await this.fetchFiles();
    this.loading = false;
  },
};
</script>

<style scoped></style>
