import { CancelAppointment } from "@/pinia-store/interfaces/CancelAppointment";
import { PostAppointmentBody } from "@/pinia-store/interfaces/PostAppointmentBody";
import { AppointmentGroup, AppointmentQueryFilterDto } from "@/types/AppointmentQueryFilter";

import { apiRequest } from "./apiRequest";

const create = async (body: PostAppointmentBody) => {
  const { data } = await apiRequest.post("/appointment", body);
  return data;
};

const cancel = async ({ id, cancellationReason }: CancelAppointment) => {
  const { data } = await apiRequest.post(`/appointment/${id}/cancellation`, { cancellationReason });
  return data;
};

const getSummary = async (appointmentId: string) => {
  const { data } = await apiRequest.get(`/appointment/${appointmentId}/summary`);
  return data;
};

const getNext = async (id?: string) => {
  const { data } = await apiRequest.get(`/appointment/next/${id ? id : ""}`);
  return data;
};

const getPrevious = async (id?: string) => {
  const { data } = await apiRequest.get(`/appointment/previous/${id ? id : ""}`);
  return data;
};

const createVisit = async (
  mediaIds: string[],
  patientId: string,
  slotId: string,
  status: string,
  chiefComplaint: string,
  virtualService: string,
  complaintDescription: string,
  practitionerConfirm: boolean,
) => {
  const { data } = await apiRequest.post(`/appointment/visit`, {
    mediaIds,
    patientId,
    slotId,
    status,
    appointmentInformation: { virtualService, chiefComplaint, complaintDescription },
    practitionerConfirm,
  });
  return data;
};

const getAppointmentByStatus = async (query?: AppointmentQueryFilterDto) => {
  const { data } = await apiRequest.get(`/appointment`, {
    params: query,
  });
  return data;
};

const getAppointmentByGroup = async (group: AppointmentGroup, query?: AppointmentQueryFilterDto) => {
  const { data } = await apiRequest.get(`/appointment/group/${group}`, {
    params: query,
  });
  return data;
};
const getAppointmentsBadge = async () => {
  const { data } = await apiRequest.get(`/appointment/badge`);
  return data;
};

const getById = async (appointmentId: string) => {
  const { data } = await apiRequest.get(`/appointment/${appointmentId}`);
  return data;
};

const complete = async (appointmentId: string) => {
  const { data } = await apiRequest.post(`/appointment/${appointmentId}/complete`);
  return data;
};

const markAsArrived = async (appointmentId: string) => {
  const { data } = await apiRequest.post(`/appointment/${appointmentId}/arrived`);
  return data;
};

const update = async (appointmentId: string, payload: any) => {
  const { data } = await apiRequest.put(`/appointment/${appointmentId}`, payload);
  return data;
};

export const AppointmentsApi = {
  getAppointmentsBadge,
  create,
  cancel,
  update,
  getSummary,
  getNext,
  getPrevious,
  createVisit,
  getAppointmentByStatus,
  getById,
  complete,
  markAsArrived,
  getAppointmentByGroup,
};
