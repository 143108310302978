<template>
  <div class="p-success-payment">
    <div class="p-success-payment_top">
      <div class="p-success-payment_top_avatars">
        <div class="p-success-payment_top_avatars_circle_outer p-success-payment_top_avatars_circle_left">
          <div class="p-success-payment_top_avatars_circle_inner">
            <span>{{ partnerInitials }}</span>
          </div>
        </div>
        <Avatar
          :userId="partnerId"
          class="p-success-payment_top_avatars_circle_inner p-success-payment_top_avatars_circle_right"
          size="52"
        />
      </div>
      <div class="p-success-payment_top_background"></div>
      <span v-if="!loading" class="p-success-payment_top_title">
        <img alt="ok" src="@/assets/icons/green-tick-rounded.svg" />
        <span class="heading-4" @click="test">{{ heading }}</span>
      </span>
      <span v-else>
        <span class="d-flex">
          <v-progress-circular class="mr-2" color="primary" indeterminate size="22" width="2"></v-progress-circular>
          <span class="heading-4 mb-4">Pending Request</span>
        </span>
      </span>
      <span class="p-success-payment_top_text">{{ topText }}</span>
    </div>
    <div class="p-success-payment_middle">
      <v-row no-gutters>
        <PatientDetails v-if="!showPaymentDetails" :patient="patientInformation" :practitioner="practitioner()" />
        <v-col class="p-success-payment_middle_right" cols="6">
          <v-row no-gutters>
            <v-col col="2">
              <img alt="calendar" src="@/assets/icons/calendar-blue.svg" />
            </v-col>
            <v-col class="p-success-payment_middle_right_column" cols="10">
              <span class="p-success-payment_middle_left_pre-text">{{ $t("visits.payment.scheduledDate") }}:</span>
              <span>{{ visitStartTopDate }}</span>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col col="2">
              <img alt="time" src="@/assets/icons/time-blue.svg" />
            </v-col>
            <v-col class="p-success-payment_middle_right_column" cols="10">
              <span class="p-success-payment_middle_left_pre-text">{{ $t("visits.payment.visitDuration") }}:</span>
              <span>{{ `${visitStartDate} - ${visitEndDate}` }}</span>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>
    <div class="p-success-payment_bottom">
      <PrimaryButton
        :disabled="loading"
        :fullWidth="true"
        :text="$t('general.buttons.done')"
        size="large"
        @onClick="redirectToVisitCenter"
      />
    </div>
  </div>
</template>

<script>
import { DateTime } from "luxon";
import moment from "moment-timezone";
import { mapState as mapPiniaState } from "pinia";
import { mapActions, mapState } from "pinia";

import { PastVisitApi } from "@/api/pastVisitLog";
import Avatar from "@/components/Avatar/Index";
import PatientDetails from "@/components/Checkout/SuccessPayement/PatientDetails";
import { timeDisplayFormat } from "@/components/Clinicians/constants";
import PrimaryButton from "@/components/uikit/PrimaryButton";
import { useAuthStore } from "@/pinia-store/auth";
import { useCheckoutStore } from "@/pinia-store/checkout";
import { usePastVisitStore } from "@/pinia-store/pastVisit";
import { usePatientStore } from "@/pinia-store/patient";
import { usePatientsStore } from "@/pinia-store/patients";
import { usePractitionersStore } from "@/pinia-store/practitioners";
import { useProfileSettingsStore } from "@/pinia-store/profileSettings";
import { useSlotsStore } from "@/pinia-store/slots";
import { useVisitNoteStore } from "@/pinia-store/visitNote";
import practitioner from "@/router/practitioner";
import { RolesEnum } from "@/types/Roles.enum";

export default {
  name: "Success",
  props: ["step", "mode"],
  components: {
    PrimaryButton,
    Avatar,
    PatientDetails,
  },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    ...mapPiniaState(useAuthStore, ["role", "uid"]),
    ...mapState(usePastVisitStore, [
      "patientId",
      "chiefComplaint",
      "describeProblem",
      "dateStart",
      "dateEnd",
      "visitStart",
      "visitEnd",
      "getFile",
    ]),
    ...mapState(useVisitNoteStore, ["medicalInfo", "patientInfo", "disabledSigning", "encounterNotes"]),
    ...mapState(usePatientStore, ["patient", "patientsPractitioner"]),
    ...mapState(useCheckoutStore, [
      "height",
      "weight",
      "isSmoking",
      "allergies",
      "medications",
      "pmh",
      "confirmedAccurace",
      "practitionerId",
      "chiefComplaint",
      "visitDate",
      "complaintDescription",
    ]),
    ...mapState(usePatientsStore, ["patients"]),
    ...mapState(useSlotsStore, ["slotsArray", "currentSlot"]),
    ...mapPiniaState(useProfileSettingsStore, ["locale"]),
    ...mapState(usePractitionersStore, ["practitioners", "practitioner"]),
    ...mapState(usePastVisitStore, ["getFile"]),
    visitStartDate() {
      return moment(this.visitStart).format("HH:mm");
    },
    visitEndDate() {
      return moment(this.visitEnd).format("HH:mm");
    },
    patientInformation() {
      return this.patientInfo;
    },
    visitStartTopDate() {
      return moment(this.visitStart).format(timeDisplayFormat);
    },
    heading() {
      return this.$t("visit.submitted.patient");
    },
    topText() {
      const practitionerName = `${this.$t("general.titles.dr")} ${this.patientsPractitioner.firstName} ${
        this.patientsPractitioner.lastName
      }`;
      const ops = {
        [RolesEnum.Patient]: `Thank you for booking the visit with ${practitionerName}`,
        [RolesEnum.Practitioner]: this.$t("visit.submitted.message"),
        [RolesEnum.Owner]: this.$t("practitioner.appointments.success.text"),
        [RolesEnum.Registrar]: this.$t("practitioner.appointments.success.text"),
        [RolesEnum.Admin]: this.$t("practitioner.appointments.success.text"),
      };
      return ops[this.role] || "";
    },
    partnerInitials() {
      if (this.patientsPractitioner.id !== "" || this.patient.id !== "") {
        const ops = {
          [RolesEnum.Patient]:
            this.patientsPractitioner.firstName[0].toUpperCase() + this.patientsPractitioner.lastName[0].toUpperCase(),
          [RolesEnum.Practitioner]: this.patient.firstName[0].toUpperCase() + this.patient.lastName[0].toUpperCase(),
          [RolesEnum.Owner]: this.patient.firstName[0].toUpperCase() + this.patient.lastName[0].toUpperCase(),
          [RolesEnum.Registrar]: this.patient.firstName[0].toUpperCase() + this.patient.lastName[0].toUpperCase(),
          [RolesEnum.Admin]: this.patient.firstName[0].toUpperCase() + this.patient.lastName[0].toUpperCase(),
        };
        return ops[this.role] || "";
      }
      return "";
    },
    partnerId() {
      const ops = {
        [RolesEnum.Patient]: this.patientsPractitioner && this.patientsPractitioner.id,
        [RolesEnum.Practitioner]: this.patientInformation && this.patientInformation.id,
        [RolesEnum.Owner]: this.patient && this.patient.id,
        [RolesEnum.Registrar]: this.patient && this.patient.id,
        [RolesEnum.Admin]: this.patient && this.patient.id,
      };
      return ops[this.role];
    },
    price() {
      return this.session ? this.session.price : 0;
    },
    paymentDate() {
      return DateTime.local().toFormat("MMMM d, yyyy");
    },
    showPaymentDetails() {
      return this.role === RolesEnum.Patient;
    },
  },
  methods: {
    practitioner() {
      return practitioner;
    },
    ...mapActions(useCheckoutStore, ["setStringFieldByName", "setVisitDate"]),
    ...mapActions(useCheckoutStore, ["clearAllFields", "submitSecondStep", "submitEditMode"]),
    ...mapActions(useSlotsStore, ["getSlotById"]),
    ...mapActions(usePatientStore, ["getPatientProfile"]),
    async redirectToVisitCenter() {
      this.$emit("done");
      return this.role === RolesEnum.Patient
        ? await this.$router.push("/patient/visits/upcoming")
        : await this.$router.push(`/${this.role}/appointments`);
    },
  },
  mounted() {
    const data = new FormData();
    data.append("patientId", this.patientInfo.id);
    data.append("practitionerId", this.uid);
    data.append("chiefComplaint", this.chiefComplaint);
    data.append("start", this.visitStart);
    data.append("end", this.visitEnd);
    data.append("encounterNotes[dx]", this.encounterNotes.dx);
    data.append("encounterNotes[plan]", this.encounterNotes.plan);
    data.append("encounterNotes[followUp]", this.encounterNotes.followUp);
    data.append("encounterNotes[assessment]", this.encounterNotes.assessment);
    data.append("encounterNotes[chiefComplaint]", this.encounterNotes.chiefComplaint);
    data.append("requestInformation[visitDate]", moment(this.visitStart).format("YYYY-MM-DD"));
    data.append("requestInformation[chiefComplaint]", this.chiefComplaint);
    data.append("requestInformation[generalInformation][height]", this.height);
    data.append("requestInformation[generalInformation][weight]", this.weight);
    data.append("requestInformation[generalInformation][isSmoking]", this.isSmoking);
    data.append("requestInformation[medicalInformation][allergies][]", this.allergies);
    data.append("requestInformation[medicalInformation][document][]", "");
    data.append("patientInformation[state]", this.patientInfo.state);
    data.append("patientInformation[gender]", this.patientInfo.gender);
    data.append("patientInformation[lastName]", this.patientInfo.lastName);
    data.append("patientInformation[firstName]", this.patientInfo.firstName);
    data.append("patientInformation[phoneNumber]", this.patientInfo.phoneNumber);
    this.getFile.map((item) => {
      data.append(`documents`, item.file);
    });
    PastVisitApi.createPastVisit(data);
  },
};
</script>

<style scoped></style>
