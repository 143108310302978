import { apiRequest } from "./apiRequest";

/**
 * Return status about video room.
 */
const getById = async (id: string) => {
  const { data } = await apiRequest.get(`/room/${id}`);
  return data;
};

/**
 * Return access token for video room.
 */
const access = async (id: string, query?: { notifyOnly: boolean }) => {
  const { data } = await apiRequest.get(`/room/${id}/access`, { params: query });
  return data;
};

const getConnectedParticipantById = async (id: string) => {
  const { data } = await apiRequest.get(`/auth/${id}`);
  return data;
};

export const VideoRoomsApi = {
  getById,
  access,
  getConnectedParticipantById,
};
