<template>
  <v-dialog :value="true" max-width="400" @click:outside="toggleDialog">
    <v-card>
      <v-container class="no-padding-bottom no-padding-top generate-dialog">
        <div class="generate-dialog_head">
          <div>
            <v-card-title class="generate-dialog_head_title"> Activation Code Generator</v-card-title>
            <v-card-title class="generate-dialog_head_subtitle">
              The generated codes can be used for new Patients
            </v-card-title>
          </div>

          <v-icon class="generate-dialog_head_close-icon" size="small" @click="toggleDialog">mdi-close</v-icon>
        </div>

        <v-row v-if="isSingleCode" class="justify-between">
          <v-col class="generate-dialog_code" cols="12">
            <div
              v-for="(cell, index) in cells"
              :key="index"
              :class="`generate-dialog_code-cell ${isGenerated && 'filled'}`"
            >
              {{ cell }}
            </div>
          </v-col>
          <v-col class="d-flex align-end justify-end" cols="12">
            <v-btn
              v-if="isGenerated"
              :disabled="loading"
              class="mr-2"
              color="default"
              depressed
              @click.stop="generateCode"
              >Generate another one
            </v-btn>
            <v-btn
              v-else
              :disabled="loading"
              class="mr-2 plain-btn"
              color="default"
              outlined
              @click.stop="onGenerateMoreThatOne"
              >Generate more than one
            </v-btn>
            <v-btn v-if="isGenerated" :loading="loading" color="primary" depressed @click.stop="copyText">Copy</v-btn>
            <v-btn v-else :loading="loading" color="primary" depressed @click.stop="generateCode">Generate</v-btn>
          </v-col>
        </v-row>
        <v-row v-else class="justify-between">
          <v-col v-if="isGenerated" class="file_wrap" cols="12">
            <div class="file" v-on:click="downloadCsv">
              <v-icon>mdi-file-document-box</v-icon>
              {{ downloadFileName }}
            </div>
          </v-col>
          <v-col v-else class="d-flex align-end" cols="12">
            <BaseInput
              v-model="count"
              :errors="codeErrors"
              fieldName="code"
              maskPattern="####"
              placeholder="Numbers of codes"
              @blur="$v.count.$touch()"
              @change="onCountChange"
            />
          </v-col>
          <v-col v-if="isGenerated" class="d-flex align-end justify-end">
            <v-btn :disabled="loading" class="mr-2 plain-btn" color="default" outlined @click.stop="isGenerated = false"
              >Generate more
            </v-btn>
            <v-btn :loading="loading" color="primary" depressed @click.stop="downloadCsv">Download</v-btn>
          </v-col>
          <v-col v-else class="d-flex align-end justify-end">
            <v-btn :disabled="loading" class="mr-2 plain-btn" color="default" outlined @click.stop="isSingleCode = true"
              >Generate only one
            </v-btn>
            <v-btn :loading="loading" color="primary" depressed @click.stop="generateCode">Generate</v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-dialog>
</template>
<script>
import * as clipboard from "clipboard-polyfill/text";
import moment from "moment-timezone";
import { mapActions } from "pinia";
import { required } from "vuelidate/lib/validators";

import { InvitesApi } from "@/api/invites";
import BaseInput from "@/components/uikit/BaseInput";
import { snackBarEventBus, snackBarEventName } from "@/eventBuses/snackBar.eventBus";
import { useInvitesStore } from "@/pinia-store/invites";

export default {
  name: "GenerateCodeDialog",
  props: ["value", "isOpen"],
  validations: {
    count: { required },
  },
  components: {
    BaseInput,
  },
  data: () => ({
    codes: [],
    count: null,
    downloadFileName: "",
    isSingleCode: true,
    isGenerated: false,
    loading: false,
    cells: Array(parseInt(8)).fill(null),
  }),
  computed: {
    codeErrors() {
      const errors = [];
      if (!this.$v.count.$dirty) return errors;
      !this.$v.count.required && errors.push(this.$t("validation.required", { field: "Number of codes" }));
      return errors;
    },
  },
  methods: {
    ...mapActions(useInvitesStore, ["sendInvite", "getAllInvites"]),
    onGenerateMoreThatOne() {
      this.isGenerated = false;
      this.isSingleCode = false;
      this.codes = [];
    },
    downloadCsv() {
      let csvContent = "data:text/csv;charset=utf-8," + this.codes.join("\n");
      let encodedUri = encodeURI(csvContent);
      let link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", this.downloadFileName);
      document.body.appendChild(link); // Required for FF
      link.click();
      link.remove();
    },
    toggleDialog() {
      this.$emit("toggleDialog");
    },
    onCountChange(value) {
      this.count = value;
    },
    async copyText() {
      await clipboard.writeText(this.codes[0]);
      snackBarEventBus.$emit(snackBarEventName, { message: "Code copied!", type: "info" });
    },
    async generateCode() {
      if (this.loading) return;

      try {
        this.loading = true;
        const codes = await InvitesApi.generateCodes(this.isSingleCode ? 1 : this.count);
        this.codes = codes;
        if (this.isSingleCode) {
          const code = codes[0];

          this.cells = Array(parseInt(8))
            .fill(null)
            .map((i, key) => code[key]);
          this.isGenerated = true;
        } else {
          this.downloadFileName = `${this.count}_codes_${moment().format("YYYY.DD.MM")}.csv`;
        }
        this.isGenerated = true;
      } catch (err) {
        if (err.isAxiosError) {
          err.message = err.response.data.message;
        }
        console.error(err);
        snackBarEventBus.$emit(snackBarEventName, { message: err.message || err, type: "error" });
      }
      this.loading = false;
    },
  },
};
</script>

<style lang="scss">
.generate-dialog {
  min-height: 340px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 25px !important;

  &_head {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: flex-start;
    padding: 2rem;
    position: relative;

    &_title {
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 24px;
      text-align: center;
      word-break: break-word !important;
      padding: 0;
    }

    &_subtitle {
      word-break: break-word;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 17px;
      color: $primaryblack3;
      padding: 1rem 0;
    }

    &_close-icon {
      top: -12px;
      right: -12px;
      padding: 12px;
    }
  }

  .file_wrap {
    padding: 2rem;
  }

  .file {
    cursor: pointer;
    background-color: $primarylight1;
    padding: 16px 12px;
    border-radius: 4px;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    color: $primary;

    i {
      color: $primary;
      margin-right: 12px;
    }
  }

  &_code {
    padding: 0 20px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 10px;
  }

  &_code-cell {
    display: flex;
    border: 1px solid #eeeff7;
    box-sizing: border-box;
    border-radius: 4px;
    height: 45px;
    width: 35px;
    justify-content: center;
    align-items: center;

    &:nth-child(4) {
      margin-right: 20px;
    }

    &.filled {
      border-color: $primary;
      font-weight: bold;
      font-size: 22px;
      line-height: 36px;
      text-align: center;
      color: $primary;
    }
  }

  &_inline-field {
    display: flex;
    align-items: center;

    .base-input {
      .v-input_control {
        .v-input_slot {
          margin-bottom: 0;

          input {
            text-align: center;
          }
        }
      }
    }

    &_label {
      flex-basis: 70%;
    }
  }

  .plain-btn {
    text-transform: none;
    opacity: 0.4;
    border: 1px solid $primarylight3;
    box-sizing: border-box;
    border-radius: 4px;
    color: $primaryblack2;
  }
}
</style>
