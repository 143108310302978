<template>
  <div>
    <div v-if="isFloatingDialog && callAccepted" class="voip-floating-wrap">
      <v-btn v-if="!callIsMuted" class="call-action" color="white" dark fab x-small @click="onMute">
        <v-icon color="#2fcf6f">mdi-microphone</v-icon>
      </v-btn>
      <v-btn v-if="callIsMuted" class="call-action" color="red" dark fab x-small @click="onUnmute">
        <v-icon color="white">mdi-microphone-off</v-icon>
      </v-btn>

      <v-btn class="call-action" color="red" dark rounded small @click="outgoingCallHangup">
        <v-icon class="mr-1" color="white" small>mdi-phone-hangup</v-icon>
        End
      </v-btn>
      <v-btn class="call-action" color="white" dark fab x-small @click="maximizeDialog">
        <v-icon color="#2fcf6f">mdi-window-maximize</v-icon>
      </v-btn>
    </div>
    <Confirm
      :dialog="isOpenedDialog"
      :hide-description="true"
      :hide-title="true"
      hide-cancel
      hide-confirm
      max-width="95%"
      title="Call"
      width="420px"
      @toggleDialog="closeDialog"
    >
      <div class="pt-4">
        <template v-if="!callAccepted">
          <BaseInput :value="From" class-name="mb-4 mt-4" fieldName="From" placeholder="From" @change="onFieldChange" />
          <v-text-field
            v-mask="'+1 (###) ###-####'"
            :loading="isLoading"
            :masked="true"
            :value="phoneNumber"
            class="phone-input"
            hide-details
            placeholder="To"
            single-line
            variant="outlined"
            @input="onPhoneChange"
          >
            <v-icon slot="append" color="red" @click="onClearInput"> mdi-close-circle-outline</v-icon>
            <v-icon slot="prepend" color="green"> mdi-phone</v-icon>
          </v-text-field>
          <div class="keyboard-wrap">
            <v-btn v-for="key in keyboard" :key="key" rounded @click="onPhoneAddChange(key)">
              {{ key }}
            </v-btn>
          </div>
        </template>
        <template v-else>
          <v-text-field
            :value="typedDigit"
            class="phone-input"
            hide-details
            single-line
            variant="outlined"
            @input="onDigitsChange"
          >
            <v-icon slot="prepend" color="red" @click="onClearDigits"> mdi-close-circle-outline</v-icon>
            <v-icon slot="append" color="green" @click="sendDigit"> mdi-send</v-icon>
          </v-text-field>
          <div class="keyboard-wrap">
            <v-btn v-for="key in keyboard" :key="key" rounded @click="onDigitsChange(key)">
              {{ key }}
            </v-btn>
          </div>
        </template>
        <div style="display: flex; justify-content: center">
          <template v-if="callAccepted">
            <div class="accepted-call-actions-dialog">
              <v-btn v-if="!callIsMuted" class="call-action" color="white" dark fab x-small @click="onMute">
                <v-icon color="#2fcf6f">mdi-microphone</v-icon>
              </v-btn>
              <v-btn v-if="callIsMuted" class="call-action" color="red" dark fab x-small @click="onUnmute">
                <v-icon color="white">mdi-microphone-off</v-icon>
              </v-btn>
              <v-btn class="call-action" color="red" dark rounded small @click="outgoingCallHangup">
                <v-icon class="mr-1" color="white" small>mdi-phone-hangup</v-icon>
                End
              </v-btn>
              <v-btn class="call-action" color="white" dark fab x-small @click="minimizeDialog">
                <v-icon color="#2fcf6f">mdi-window-minimize</v-icon>
              </v-btn>
            </div>
          </template>
          <PrimaryButton
            v-if="!callAccepted"
            :loading="isLoading"
            left-icon="mdi-phone-outline"
            text="Call"
            @onClick="onInitCall"
          />
        </div>
        <div v-if="deviceLoaded" :class="audioBothToggle && 'devices-toggle'" class="footer-options">
          <div class="align-center d-flex">
            <template v-if="audioInputDevices.length > 2">
              <v-icon class="mr-2">fa-microphone</v-icon>
              <v-select
                :items="audioInputDevices"
                :value="inputDeviceGroupId"
                dense
                hide-details
                item-text="text"
                item-value="groupId"
                @change="changeInputDevice"
              ></v-select>
            </template>
            <v-icon
              v-else-if="audioInputDevices.length === 2"
              :class="isActiveSecondInputDevice && 'active'"
              class="mr-2 toggle-device"
              @click="changeInputDevice"
            >
              fa-microphone
            </v-icon>
          </div>
          <div class="pa-0 align-center d-flex">
            <template v-if="audioOutputDevices.length > 2">
              <v-icon class="mr-2">fa-volume-up</v-icon>
              <v-select
                :items="audioOutputDevices"
                :value="outputDeviceGroupId"
                dense
                hide-details
                item-text="text"
                item-value="groupId"
                @change="changeOutputDevice"
              ></v-select>
            </template>
            <v-icon
              v-else-if="audioOutputDevices.length === 2"
              :class="isActiveSecondOutputDevice && 'active'"
              class="mr-2 toggle-device"
              @click="changeOutputDevice"
            >
              fa-volume-up
            </v-icon>
          </div>
        </div>
      </div>
    </Confirm>
  </div>
</template>

<script>
import { Device } from "@twilio/voice-sdk";
import { mapActions, mapState } from "pinia";
import { DtmfPlayer } from "play-dtmf";
import { mask } from "vue-the-mask";

import { VoIPApi } from "@/api/voip";
import Confirm from "@/components/shared/Confirm.vue";
import BaseInput from "@/components/uikit/BaseInput.vue";
import PrimaryButton from "@/components/uikit/PrimaryButton.vue";
import { useAuthStore } from "@/pinia-store/auth";
import { useVoipStore, voipCurrentState, voipDialogType } from "@/pinia-store/voip";

export default {
  name: "VoIP",
  directives: {
    mask,
  },
  components: { PrimaryButton, BaseInput, Confirm },
  props: {
    item: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      inputDeviceGroupId: null,
      outputDeviceGroupId: null,
      deviceLoaded: false,
      audioInputDevices: [],
      audioOutputDevices: [],
      dtmfPlayer: new DtmfPlayer(),
      keyboard: [1, 2, 3, 4, 5, 6, 7, 8, 9, "*", 0, "#"],
      accessToken: "",
      call: null,
      device: null,
      dialog: false,
      connecting: false,
      typedDigit: "",
      From: "+12058090713",
      callerId: "PN07eb064be08efc0862c815b859281823",
      // To: "+1-(844)-984-2947",
    };
  },
  computed: {
    ...mapState(useAuthStore, ["uid"]),
    ...mapState(useVoipStore, ["dialogType", "state", "phoneNumber"]),
    isOpenedDialog() {
      return this.dialogType === voipDialogType.dialog;
    },
    isFloatingDialog() {
      return this.dialogType === voipDialogType.floating;
    },
    isLoading() {
      return this.state === voipCurrentState.loading;
    },
    callAccepted() {
      return this.state === voipCurrentState.accepted;
    },
    callIsMuted() {
      return this.call && this.call.isMuted();
    },
    audioBothToggle() {
      return this.audioInputDevices.length < 3 && this.audioOutputDevices.length < 3;
    },
    isActiveSecondInputDevice() {
      return this.audioInputDevices?.[1]?.groupId === this.inputDeviceGroupId;
    },
    isActiveSecondOutputDevice() {
      return this.audioOutputDevices?.[1]?.groupId === this.outputDeviceGroupId;
    },
  },
  methods: {
    ...mapActions(useVoipStore, ["setDialog", "changePhone", "changeState"]),

    maximizeDialog() {
      this.setDialog(voipDialogType.dialog);
    },
    minimizeDialog() {
      this.setDialog(voipDialogType.floating);
    },
    onClearInput() {
      if (this.phoneNumber.length) this.changePhone(this.phoneNumber.slice(0, -1));
    },
    outgoingCallHangup() {
      if (this.call) {
        this.call.disconnect();
        this.changeState(voipCurrentState.notStarted);
      }
    },
    handleAcceptCall() {
      if (this.call) this.call.accept();
    },
    sendDigit() {
      if (this.call && this.typedDigit) {
        this.call.sendDigits(this.typedDigit.toString());
        setTimeout(() => {
          this.typedDigit = "";
        }, 400);
      }
    },
    onMute() {
      if (this.call) this.call.mute(true);
    },
    onUnmute() {
      if (this.call) this.call.mute(false);
    },
    onClearDigits() {
      this.typedDigit = "";
    },
    onDigitsChange(value) {
      this.typedDigit = value;
      this.sendDigit();
    },

    async initDevice() {
      this.accessToken = await VoIPApi.grantAccess();
      this.device = new Device(this.accessToken, {
        logLevel: 1,
        // edge: ["roaming"],
        codecPreferences: ["opus", "pcmu"],
      });
      this.addDeviceListeners(this.device);
      this.device.register();

      this.device.on("tokenWillExpire", () => {
        VoIPApi.grantAccess().then((token) => {
          this.device.updateToken(token);
        });
      });
    },
    changeOutputDevice(groupId) {
      let newDevice;
      // if is toggle
      if (this.audioOutputDevices.length < 3) {
        newDevice = this.audioOutputDevices.find((device) => device.groupId !== this.outputDeviceGroupId);
      } else {
        newDevice = this.audioOutputDevices.find((device) => device.groupId === groupId);
      }

      if (newDevice.id) {
        this.device.audio.speakerDevices.set(newDevice.id);
        this.outputDeviceGroupId = newDevice.groupId;
      }
    },
    changeInputDevice(groupId) {
      let newDevice;
      // if is toggle
      if (this.audioInputDevices.length < 3) {
        newDevice = this.audioInputDevices.find((device) => device.groupId !== this.inputDeviceGroupId);
      } else {
        newDevice = this.audioInputDevices.find((device) => device.groupId === groupId);
      }

      if (newDevice) {
        this.device.audio.ringtoneDevices.set(newDevice.id);
        this.inputDeviceGroupId = newDevice.groupId;
      }
    },
    prepareInOutDevices() {
      let devIn = [];
      const ringtoneDevices = this.device.audio.ringtoneDevices.get();
      this.device.audio.availableInputDevices.forEach((device, id) => {
        ringtoneDevices.forEach((spkDev) => {
          if (spkDev.groupId === device.groupId) {
            this.inputDeviceGroupId = device.groupId;
          }
        });
        devIn.push({
          id: id,
          groupId: device.groupId,
          device: { groupId: device.groupId, deviceId: device.deviceId },
          text: device.label,
        });
      });
      devIn = devIn.filter(
        (current, index, array) => !array.slice(0, index).find((obj) => obj.groupId === current.groupId),
      );
      this.audioInputDevices = devIn;
      let devOut = [];
      const speakerDevices = this.device.audio.speakerDevices.get();
      this.device.audio.availableOutputDevices.forEach((device, id) => {
        speakerDevices.forEach((spkDev) => {
          if (spkDev.groupId === device.groupId) {
            this.outputDeviceGroupId = device.groupId;
          }
        });
        devOut.push({
          id: id,
          groupId: device.groupId,
          device: { groupId: device.groupId, deviceId: device.deviceId },
          text: device.label,
        });
      });
      devOut = devOut.filter(
        (current, index, array) => !array.slice(0, index).find((obj) => obj.groupId === current.groupId),
      );

      this.audioOutputDevices = devOut;

      this.deviceLoaded = true;
    },
    addDeviceListeners(device) {
      device.on("registered", () => {
        this.prepareInOutDevices();
      });

      device.on("error", (error) => {
        console.error("Twilio.Device Error: " + error.message);
      });

      device.on("incoming", this.handleIncomingCall);
      // device.audio.on("deviceChange", updateAllAudioDevices.bind(device));
    },
    handleIncomingCall(call) {
      // add event listener to call object
      // call.on("cancel", handleDisconnectedIncomingCall);
      // call.on("disconnect", handleDisconnectedIncomingCall);
      // call.on("reject", handleDisconnectedIncomingCall);
    },
    closeDialog() {
      if (this.state === voipCurrentState.notStarted) {
        this.setDialog(voipDialogType.none);
      } else {
        this.setDialog(voipDialogType.floating);
      }
    },
    onFieldChange(value, fieldName) {
      this[fieldName] = value;
    },
    onPhoneChange(value) {
      this.changePhone(value);
    },
    onPhoneAddChange(value) {
      this.changePhone(`${this.phoneNumber}${value}`);
      this.dtmfPlayer.play(value);
      setTimeout(() => {
        this.dtmfPlayer.stop();
      }, 150);
    },
    async onInitCall() {
      this.typedDigit = "";
      if (!this.device) {
        console.info("Not device");
        return;
      }
      this.changeState(voipCurrentState.loading);

      this.call = await this.device.connect({
        params: {
          callerId: this.callerId,
          identity: this.uid,
          // callerId: "intent",
          // callerId: "7bbdf100-ed5f-458a-b235-01bcc888a728",
          From: this.From,
          To: this.phoneNumber,
        },
      });
      this.prepareInOutDevices();

      this.call.on("error", (err) => {
        this.changeState(voipCurrentState.notStarted);
      });
      this.call.on("accept", () => {
        this.changeState(voipCurrentState.accepted);
      });
      this.call.on("disconnect", () => {
        this.changeState(voipCurrentState.notStarted);
      });
      this.call.on("cancel", () => {
        this.changeState(voipCurrentState.notStarted);
      });
      this.call.on("reject", () => {
        this.changeState(voipCurrentState.notStarted);
      });
      this.call.on("warning", function (warningName, warningData) {
        console.warn(warningData, warningData, "callOnWarning");
      });
      this.call.on("reconnected", () => {
        this.changeState(voipCurrentState.accepted);

        console.info("The call has regained connectivity.");
      });
    },
  },
  async mounted() {
    await this.initDevice();
  },
};
</script>

<style lang="scss" scoped>
.accepted-call-actions-dialog {
  display: flex;
  gap: 16px;
  align-items: center;
}

.keyboard-wrap {
  justify-content: center;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: center;
  justify-items: center;
  max-width: 250px;
  align-content: center;
  margin: 0 auto;
  padding-bottom: 20px;
  grid-gap: 10px;

  :deep button {
    span {
      color: #112c4b;
      font-size: 16px;
      font-weight: 700;
    }
  }
}

.voip-floating-wrap {
  position: fixed;
  bottom: 30px;
  display: flex;
  gap: 13px;
  align-items: center;
  border-radius: 10px;
  padding: 4px 22px;
  background: #414144eb;
  left: 50%;
  transform: translate(0, -50%);

  .call-action {
  }
}

:deep .phone-input {
  text-align: center;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 30px;

  input {
    text-align: center;
  }

  label {
    text-align: center;
  }
}

.footer-options {
  padding-top: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;

  div.v-input {
    width: calc(100% - 40px);
    max-width: 100%;
    margin-top: 0 !important;
  }

  i {
    font-size: 14px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: white;
    color: $primaryblack2;
    padding: 15px;
    cursor: pointer;

    &.toggle-device {
      border: 1px solid $primaryblack2;

      &.active {
        box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
        background: #ababae;
        color: white;
      }
    }
  }

  &.devices-toggle {
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  .toggle-device {
    width: 60px;
    height: 60px;
    align-self: center;
    border: 1px solid $primaryblack2;
    border-radius: 50%;
    padding: 15px;

    &.active {
      box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
      background: $primaryblack2;
      color: white;
    }
  }
}
</style>
