<template>
  <div>
    <Confirm
      :cancelText="$t('general.dialog.cancel')"
      :dialog="value"
      :loading="loading"
      :text="`Please choose a template to fill in the ${$t(`'templates.${type}`)}`"
      confirmText="Select"
      rootClass="templates-dialog"
      title="Choose template"
      @confirm="onSubmit"
      @toggleDialog="toggleDialog"
    >
      <v-container class="no-padding-bottom no-padding-top">
        <v-row dense>
          <v-col cols="12" sm="12">
            <Select
              v-model="templateId"
              :hideDetails="false"
              :items="templateTypes"
              :label="$t('templates.type')"
              itemText="name"
              itemValue="id"
            />
          </v-col>
        </v-row>
      </v-container>
    </Confirm>
  </div>
</template>
<script>
import { mapState } from "pinia";
import { required } from "vuelidate/lib/validators";

import { TemplatesAPI } from "@/api/templates";
import Confirm from "@/components/shared/Confirm";
import Select from "@/components/uikit/Select";
import { useAuthStore } from "@/pinia-store/auth";

export default {
  name: "SelectTemplateDialog",
  props: ["type", "value"],
  components: {
    Confirm,
    Select,
  },
  data: function () {
    return {
      loading: false,
      items: [],
      templateId: "",
    };
  },
  validations: {
    name: { required },
    term: { required },
  },
  computed: {
    ...mapState(useAuthStore, ["uid"]),
    isSubmitDisabled() {
      return Boolean(!this.templateId || this.templateId.length);
    },
    templateTypes() {
      return this.items.filter((template) => template.term === this.type);
    },
  },
  methods: {
    async fetchTemplates() {
      this.items = await TemplatesAPI.getAll();
    },
    toggleDialog() {
      this.$emit("onToggleDialog");
    },
    onSubmit() {
      this.$emit(
        "onSelect",
        this.items.find((i) => i.id === this.templateId),
      );
    },
  },
  async mounted() {
    await this.fetchTemplates();
  },
};
</script>
<style lang="scss">
.templates-dialog {
  &.v-card {
    .card-title {
      font-weight: 500;
      font-size: 25px;
      line-height: 31px;
      color: #25233a;
      padding-left: 12px;
      padding-right: 12px;
    }

    .v-card__title + .v-card__text {
      font-size: 14px;
      line-height: 17px;
      color: #33343d;
      padding-left: 12px;
      padding-right: 12px;
    }
  }
}
</style>
