<template>
  <div class="items-slide">
    <VueSlickCarousel v-if="items.length" v-bind="slideSettings">
      <template #prevArrow>
        <div class="prev-arrow">
          <img src="@/assets/icons/arrow.svg" alt="Prev" />
        </div>
      </template>
      <template #nextArrow>
        <div class="next-arrow">
          <img src="@/assets/icons/arrow.svg" alt="Next" />
        </div>
      </template>
      <div class="slide-wrap" v-for="encounter in items" :key="encounter.id" @click="$emit('onSelect', encounter.id)">
        <div class="slide-cell" :class="{ active: encounter.id === activeId }">
          <div class="elevation-0">
            <p class="item-title">{{ encounter.practitioner.name }}</p>
            <p class="subtitle">
              {{ translateChiefComplaint(encounter.chiefComplaint) }}
            </p>
            <p class="subtitle">
              <img :src="require('@/assets/icons/calendar-alt.svg')" alt="" />
              {{ formatDate(encounter.start).format("DD MMMM YYYY") }}
            </p>
          </div>
        </div>
        <div v-if="encounter.id === activeId" class="slide-bar"></div>
      </div>
    </VueSlickCarousel>
  </div>
</template>

<script>
import VueSlickCarousel from "vue-slick-carousel";

export default {
  name: "EncountersSlide",
  data() {
    return {
      slideSettings: {
        dots: false,
        arrows: true,
        centerMode: false,
        centerPadding: "20px",
        variableWidth: true,
        infinite: false,
      },
    };
  },
  components: {
    VueSlickCarousel,
  },
  props: ["items", "activeId"],
  computed: {},
  methods: {
    formatDate(date = undefined) {
      return this.$moment(date);
    },
    isActive(id) {
      return this.$route.params.roomId === id;
    },
    createdData(date) {
      return date && this.$moment(date).isValid() ? this.$moment(date).fromNow() : "";
    },
    translateChiefComplaint(val) {
      return this.$t("general.chiefComplaints")[val] || val || "-";
    },
  },
};
</script>

<style lang="scss">
.items-slide {
  gap: 16px;
  .slick-slide {
    margin: 0 16px;
  }

  .prev-arrow {
    top: calc(50% - 14px);
    width: 28px;
    height: 28px;
    background: #ffffff;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.12);
    transform: matrix(-1, 0, 0, 1, 0, 0);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;

    img {
      height: 12px;
      width: 12px;
    }
  }

  .next-arrow {
    width: 28px;
    height: 28px;
    background: #ffffff;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.12);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;

    img {
      height: 12px;
      width: 12px;
    }
  }

  .slick-prev:before {
    color: #33343e;
    content: "";
  }

  .slick-next:before {
    color: #33343e;
    content: "";
  }
  .slide-wrap {
    position: relative;
    grid-template-columns: 1fr 4fr;
    -webkit-column-gap: 12px;
    -moz-column-gap: 12px;
    column-gap: 12px;
    margin: 0 22px;
    padding-bottom: 30px;
    transition: 0.3s;
    .slide-bar {
      position: absolute;
      width: 108px;
      height: 6px;
      margin-left: auto;
      left: 0;
      bottom: 4px;
      right: 0;
      margin-right: auto;
      background: #33343e;
      opacity: 0.7;
      border-radius: 100px;
    }
    .slide-cell {
      padding: 20px 24px;
      display: grid;

      background: #ffffff;
      border: 1px solid #e7e8f2;
      border-radius: 6px;
      cursor: pointer;

      &.active {
        background: #eff6ff;
        border: 1px solid rgba(33, 150, 243, 0.4021);
      }
      .item-title {
        font-family: "Inter", serif;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        color: #33343e;
      }
      .subtitle {
        margin-top: 8px;
        display: flex;
        align-items: center;
        gap: 10px;
        font-family: "Inter", serif;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        color: rgba(37, 35, 58, 0.4021);
        img {
          width: 16px;
          height: 16px;
        }
      }
    }
  }

  &__description {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #91909c;
  }

  img {
    width: 200px;
    height: 200px;
    object-fit: cover;
  }

  .v-card__title {
    line-height: 1;
    padding: 8px 16px;
  }
}
</style>
