<template>
  <div>
    <Confirm
      :confirmText="`${bodyHeightForm.id ? 'Change' : 'Add'}`"
      :dialog="bodyHeightFormOpened"
      :loading="loading"
      :title="`${bodyHeightForm.id ? 'Change Body Height' : 'Add Body Height'}`"
      cancelText="Cancel"
      maxWidth="90%"
      rootClass="order-form"
      text=""
      width="600px"
      @confirm="performStore"
      @toggleDialog="toggleBodyHeightFormOpened"
    >
      <v-container class="no-padding-bottom no-padding-top mb-3">
        <v-row>
          <v-col cols="3">
            <v-subheader> Body Height</v-subheader>
          </v-col>
          <v-col class="inline-inputs" cols="9">
            <BaseInput
              :errors="validateField('value')"
              :hide-details="true"
              :value="feet"
              fieldName="feet"
              placeholder="Enter"
              @change="onFeetChange"
            />
            <span class="text--secondary pl-1 pr-2">ft</span>
            <BaseInput
              :errors="validateField('value')"
              :hide-details="true"
              :value="inches"
              fieldName="inches"
              placeholder="Enter"
              @change="onInchesChange"
            />
            <span class="text--secondary pl-1">in</span>
          </v-col>
          <v-col cols="3">
            <v-subheader> Date</v-subheader>
          </v-col>
          <v-col class="inline-inputs" cols="9">
            <DatePicker
              :dense="true"
              :errors="validateField('date')"
              :height="47"
              :hideDetails="true"
              :show-now="true"
              :value="bodyHeightForm.date"
              placeholder="Date"
              @setDate="onChangeDate"
            />
          </v-col>
        </v-row>
      </v-container>
    </Confirm>
  </div>
</template>
<script>
import { mapActions, mapState } from "pinia";

import { ObservationsApi } from "@/api/observations";
import Confirm from "@/components/shared/Confirm";
import DatePicker from "@/components/shared/DatePicker";
import BaseInput from "@/components/uikit/BaseInput";
import { BODY_HEIGHT_ADDED } from "@/eventBuses/monitoringEvents";
import { validatorErrors } from "@/mixins/validatorErrors.js";
import { useAutoSaveStore } from "@/pinia-store/autoSave";
import { initialPatientInfo as bodyHeightForm } from "@/pinia-store/medicalRecord";
import { useObservationsStore } from "@/pinia-store/observations";
import { feetFromFeetInches, feetInchesFromFeet } from "@/utils/conversion";

export default {
  name: "AddBodyHeight",
  components: {
    DatePicker,
    BaseInput,
    Confirm,
  },
  mixins: [validatorErrors],
  data: () => ({
    loading: false,
    feet: 0,
    inches: 0,
  }),
  watch: {
    // todo: fix this
    "$store.state.observations.bodyHeightForm.value": {
      immediate: true,
      async handler(val) {
        if (val) {
          const data = feetInchesFromFeet(val);
          this.feet = data.ft;
          this.inches = data.in;
        } else {
          this.feet = 0;
          this.inches = 0;
        }
      },
    },
  },
  computed: {
    bodyHeightForm() {
      return bodyHeightForm;
    },
    ...mapState(useObservationsStore, ["bodyHeightFormOpened", "bodyHeightForm", "patientId"]),
    isSubmitDisabled() {
      return Boolean(!this.date || !this.feet || !this.inches || this.dateErrors.length);
    },
  },
  methods: {
    ...mapActions(useAutoSaveStore, ["startSave", "endSave"]),
    ...mapActions(useObservationsStore, ["toggleBodyHeightFormOpened", "changeBodyHeightFormFormField"]),

    onFieldChange(value, fieldName) {
      this.changeBodyHeightFormFormField({ field: fieldName, value });
    },
    onFeetChange(value) {
      this.feet = value;
    },
    onInchesChange(value) {
      this.inches = value;
    },
    onChangeDate(value) {
      this.changeBodyHeightFormFormField({ field: "date", value });
    },
    async performStore() {
      try {
        this.loading = true;
        this.startSave();
        const { id, date } = this.bodyHeightForm;
        await ObservationsApi.createBodyHeight({
          patientId: this.patientId,
          id,
          date: this.$moment(date).format("YYYY-MM-DD"),
          value: feetFromFeetInches(parseFloat(this.feet), parseFloat(this.inches)),
        });
        this.loading = false;
        this.toggleBodyHeightFormOpened();
        this.$root.$emit(BODY_HEIGHT_ADDED);
        this.endSave();
        this.$emit("onSave");
      } catch (err) {
        this.setResponseErrors(err);
        // this.loading = false;
        console.error(err);
        this.endSave();
      }
    },
  },
};
</script>
<style lang="scss">
.order-form {
  .inline-inputs {
    flex-direction: row;
    display: flex;
    align-items: center;
  }

  .card-title {
    font-weight: 500 !important;
    font-size: 25px !important;
    line-height: 31px !important;
    color: #25233a;
  }

  .v-card__title + .v-card__text {
    font-size: 14px;
    line-height: 17px;
    color: #33343d;
  }
}
</style>
