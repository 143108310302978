<template>
  <v-row class="past-visit-chart-review mt-4" no-gutters>
    <v-col cols="12" lg="8" md="8" sm="12" xl="8">
      <PractitionerEncounterMedicationReview />
      <v-card class="card pa-4 justify-end d-flex">
        <PrimaryButton className="" rightIcon="mdi-arrow-right" text="Continue to Encounter" @onClick="nextStep" />
      </v-card>
    </v-col>
    <v-col cols="12" lg="4" md="4" sm="12" xl="4">
      <PractitionerVisitDescription />
    </v-col>
  </v-row>
</template>

<script>
import { mapActions, mapState } from "pinia";

import PrimaryButton from "@/components/uikit/PrimaryButton";
import PractitionerVisitDescription from "@/components/VisitDescription/Practitioner";
import PractitionerEncounterMedicationReview from "@/components/VisitNotes/PractitionerEncounterMedicationReview";
import { usePastVisitStore } from "@/pinia-store/pastVisit";

export default {
  name: "PastVisitChartReview",
  components: { PrimaryButton, PractitionerVisitDescription, PractitionerEncounterMedicationReview },
  computed: {
    ...mapState(usePastVisitStore, ["step"]),
  },
  methods: {
    ...mapActions(usePastVisitStore, ["setStep"]),
    nextStep() {
      this.setStep(this.step + 1);
    },
  },
};
</script>

<style lang="scss" scoped>
.past-visit-chart-review {
  /*max-width: 90%;*/
  /*width: 1360px;*/
}
</style>
