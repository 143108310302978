<template>
  <div @click="onClick" class="checkbox-wrap">
    <v-icon v-if="checked" color="primary">mdi-checkbox-marked</v-icon>
    <v-icon v-else>mdi-checkbox-blank-outline</v-icon>
    {{ text }}
  </div>
</template>

<script>
export default {
  name: "Checkbox",
  props: {
    checked: {
      default: false,
      type: Boolean,
    },
    text: {
      default: "",
      type: String,
    },
  },
  methods: {
    onClick() {
      this.$emit("click");
    },
  },
};
</script>

<style lang="scss" scoped>
.checkbox-wrap {
  cursor: pointer;
}
.mdi-checkbox-blank-outline {
  color: #eeeff7;
}
</style>
