<template>
  <div class="clinicians-list">
    <v-row align="center" class="patients-list_header">
      <v-col class="patients-list_header_block" cols="6">
        <h1>{{ $t("owner.clinician.title") }}</h1>
      </v-col>
      <v-col class="d-flex align-start patients-list_header_block" cols="6">
        <v-text-field
          v-model="search"
          :placeholder="$t('patients.searchPlaceholder')"
          dense
          hide-details
          onfocus="this.removeAttribute('readonly');"
          ontouchstart="this.removeAttribute('readonly');"
          prepend-inner-icon="mdi-magnify"
          solo
        >
        </v-text-field>
      </v-col>
    </v-row>
    <v-tabs v-model="tab" active-class="tabs_tab_active" class="tabs" color="primary">
      <v-tab class="tabs_tab"> Doctors ({{ practitioners.length }})</v-tab>
      <v-tab-item class="patients-list_tabs_tab_item">
        <CliniciansTable
          :actions="actions.practitioners"
          :headers="cliniciansHeaders"
          :items="tablePractitioners"
          :loading="loading"
          :search="search"
        />
      </v-tab-item>
    </v-tabs>
    <AssignPractitionerDialog v-model="assignDialog" :practitioner="assignPractitioner" />
  </div>
</template>

<script>
import { mapActions, mapState } from "pinia";

import AssignPractitionerDialog from "@/components/AssignPractitionerDialog";
import { cliniciansHeaders } from "@/components/Clinicians/constants";
import CliniciansTable from "@/components/Clinicians/Table";
import { snackBarEventBus, snackBarEventName } from "@/eventBuses/snackBar.eventBus";
import { usePractitionersStore } from "@/pinia-store/practitioners";
import { getStateName } from "@/utils/states";

export default {
  name: "CliniciansList",
  components: {
    CliniciansTable,
    AssignPractitionerDialog,
  },
  data() {
    return {
      search: "",
      tab: null,
      loading: false,
      assignDialog: false,
      assignPractitioner: null,
      cliniciansHeaders,
      actions: {
        practitioners: [
          {
            callback: this.openAssignDialog,
            text: `${this.$t("owner.clinician.assign.button")} Registrar`,
          },
        ],
      },
    };
  },
  computed: {
    ...mapState(usePractitionersStore, ["practitioners"]),
    tablePractitioners() {
      return this.practitioners?.map
        ? this.practitioners.map((p) => {
            return {
              ...p,
              registrars: p.registrars.map((registrar) => ({
                ...registrar,
                name: `${registrar.firstName} ${registrar.lastName}`,
              })),
              name: `${p.firstName} ${p.lastName}`,
              email: p.email,
              state: getStateName(p.state),
              checked: false,
            };
          })
        : [];
    },
  },
  methods: {
    ...mapActions(usePractitionersStore, ["getPractitioners"]),
    openAssignDialog(item) {
      this.assignDialog = true;
      this.assignPractitioner = item;
    },
  },
  async mounted() {
    try {
      this.loading = true;
      await this.getPractitioners();
    } catch (err) {
      console.error(err);
      snackBarEventBus.$emit(snackBarEventName, { message: err.message || err, type: "error" });
    }
    this.loading = false;
  },
};
</script>

<style lang="scss" scoped>
.clinicians-list {
  padding: 0 30px;
}
</style>
