import { defineStore } from "pinia";

import { PractitionersAPI } from "@/api/practitioners";
import { ReAssignPractitioner } from "@/types";
import { Practitioner } from "@/types/users";

interface PractitionersState {
  practitioners: Practitioner[];
  selectedPractitioner: string;
  practitionersWithSlots: Practitioner[];
  practitioner: Practitioner[];
}

export const usePractitionersStore = defineStore({
  id: "practitioners",
  state: () => ({
    practitioners: [],
    selectedPractitioner: "",
    practitionersWithSlots: [],
    practitioner: [],
  }),

  actions: {
    async getAllPractitioners() {
      this.practitioners = await PractitionersAPI.getAllPractitioners();
    },
    async getPractitionerById(payload: string) {
      this.practitioner = await PractitionersAPI.getPractitionerById(payload);
    },
    async getPractitioners() {
      this.practitioners = await PractitionersAPI.getPractitioners();
    },
    async reAssignPractitioner(payload: ReAssignPractitioner) {
      await PractitionersAPI.updateRegistrar(payload.practitionerId, payload.registrarId);
    },
    async removeAssignedRegistrar(payload: ReAssignPractitioner) {
      await PractitionersAPI.removeAssignedRegistrar(payload.practitionerId, payload.registrarId);
    },
    changeSelectedPractitioner(selectedPractitioner: string) {
      this.selectedPractitioner = selectedPractitioner;
    },
  },

  getters: {
    practitionersName(state) {
      const practitionersName = state.practitioners.map(
        (practitioner: Practitioner) => `${practitioner.firstName} ${practitioner.lastName}`,
      );
      return ["All", ...practitionersName];
    },
    practitionerName(state) {
      if (state.selectedPractitioner && state.selectedPractitioner !== "All") {
        return state.selectedPractitioner;
      } else {
        return state.practitioners.map(({ firstName, lastName, id }) => ({
          text: `${firstName} ${lastName}`,
          value: id,
        }));
      }
    },
  },
});
