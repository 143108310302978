<template>
  <div class="page-not-found">
    <img src="../../assets/waiting-man.svg" />
    <h1 class="heading-2">{{ title }}</h1>
    <v-btn v-if="btnText" color="primary" outlined @click="$emit('onClick')">{{ btnText }}</v-btn>
  </div>
</template>
<script>
export default {
  name: "NotFoundCard",
  props: {
    title: {
      default: "Sorry, the page you’re looking for doesn’t exist.",
      type: String,
    },
    btnText: {
      type: [String, Boolean],
      default: false,
    },
  },
};
</script>
