<template>
  <div>
    <Confirm
      :confirmText="`${bodyTemperatureForm.id ? 'Change' : 'Add'}`"
      :dialog="bodyTemperatureFormOpened"
      :loading="loading"
      :title="`${bodyTemperatureForm.id ? 'Change Body Temperature' : 'Add Body Temperature'}`"
      cancelText="Cancel"
      maxWidth="90%"
      rootClass="order-form"
      text=""
      width="600px"
      @confirm="performStore"
      @toggleDialog="toggleBodyTemperatureFormOpened"
    >
      <v-container class="no-padding-bottom no-padding-top">
        <v-row>
          <v-col cols="3">
            <v-subheader> Body Temperature</v-subheader>
          </v-col>
          <v-col class="inline-inputs" cols="9">
            <BaseInput
              :errors="validateField('value')"
              :hide-details="false"
              :value="bodyTemperatureForm.value"
              class="field-details-absolute"
              fieldName="value"
              placeholder="Enter F"
              @change="onFieldChange"
            />
            <span class="text--secondary pl-1">F</span>
          </v-col>
          <v-col cols="3">
            <v-subheader> Date</v-subheader>
          </v-col>
          <v-col class="inline-inputs" cols="9">
            <DatePicker
              :dense="true"
              :errors="validateField('date')"
              :height="47"
              :hideDetails="true"
              :show-now="true"
              :value="bodyTemperatureForm.date"
              placeholder="Date"
              @setDate="onChangeDate"
            />
          </v-col>
          <v-col cols="3">
            <v-subheader> Time</v-subheader>
          </v-col>
          <v-col class="inline-inputs" cols="9">
            <TimePicker :show-now="true" :value="bodyTemperatureForm.time" fieldName="time" @change="onChangeTime" />
          </v-col>
          <v-col cols="3">
            <v-subheader> Context</v-subheader>
          </v-col>
          <v-col cols="9">
            <Textarea
              :hideDetails="true"
              :value="bodyTemperatureForm.note"
              placeholder="Enter Notes"
              @change="onNoteChange"
            />
          </v-col>
        </v-row>
      </v-container>
    </Confirm>
  </div>
</template>
<script>
import { mapActions, mapState } from "pinia";

import { ObservationsApi } from "@/api/observations";
import { timeISO8601OFormat } from "@/components/Clinicians/constants";
import Confirm from "@/components/shared/Confirm";
import DatePicker from "@/components/shared/DatePicker";
import TimePicker from "@/components/shared/TimePicker";
import BaseInput from "@/components/uikit/BaseInput";
import Textarea from "@/components/uikit/Textarea";
import { BODY_TEMPERATURE_ADDED } from "@/eventBuses/monitoringEvents";
import { validatorErrors } from "@/mixins/validatorErrors.js";
import { useAuthStore } from "@/pinia-store/auth";
import { useAutoSaveStore } from "@/pinia-store/autoSave";
import { useMonitoringStore } from "@/pinia-store/monitoring";
import { useObservationsStore } from "@/pinia-store/observations";
import { ObservationEnum } from "@/types/ObservationEnum";

export default {
  name: "AddBodyTemperature",
  components: {
    TimePicker,
    Textarea,
    DatePicker,
    BaseInput,
    Confirm,
  },
  mixins: [validatorErrors],
  data: () => ({
    loading: false,
    frequencyList: [],
    startDate: "",
    endDate: "",
    systolicLow: "",
    systolicHigh: "",
    diastolicLow: "",
    diastolicHigh: "",
    patientInstruction: "",
    note: "",
  }),
  computed: {
    ...mapState(useAuthStore, ["uid"]),
    ...mapState(useMonitoringStore, ["orders"]),
    ...mapState(useObservationsStore, ["bodyTemperatureFormOpened", "bodyTemperatureForm", "patientId"]),
    isSubmitDisabled() {
      return Boolean(!this.date || !this.value || this.dateErrors.length || this.valueErrors.length);
    },
  },
  methods: {
    ...mapActions(useAutoSaveStore, ["startSave", "endSave"]),
    ...mapActions(useObservationsStore, [
      "openSickModal",
      "toggleBodyTemperatureFormOpened",
      "changeBodyTemperatureFormFormField",
    ]),
    onNoteChange(value) {
      this.changeBodyTemperatureFormFormField({ field: "note", value });
    },
    onChangeTime(value) {
      this.changeBodyTemperatureFormFormField({ field: "time", value });
    },
    onFieldChange(value, fieldName) {
      this.changeBodyTemperatureFormFormField({ field: fieldName, value });
    },
    async onChangeInstruction(value) {
      this.changeBodyTemperatureFormFormField({ field: "patientInstruction", value });
    },
    onChangeDate(value) {
      this.changeBodyTemperatureFormFormField({ field: "date", value });
    },
    async performStore() {
      try {
        this.loading = true;
        this.startSave();
        const { date, note, patientInstruction, time, value } = this.bodyTemperatureForm;
        await ObservationsApi.createBodyTemperature({
          patientId: this.patientId,
          note,
          patientInstruction,
          date: this.$moment(date).format(timeISO8601OFormat),
          time,
          value: parseFloat(value),
        });
        this.loading = false;
        this.toggleBodyTemperatureFormOpened();
        this.$root.$emit(BODY_TEMPERATURE_ADDED);
        const order = this.orders.find((i) => i.kind === ObservationEnum.bodyTemperature);
        let text = [];
        if (order) {
          if (order.normalLow > value) {
            text.push(`You have low body temperature`);
          }
          if (order.normalHigh < value) {
            text.push(`You have high body temperature`);
          }
          if (text.length) this.openSickModal(text);
        }
        this.endSave();
        if (!text.length) this.$emit("onSave");
      } catch (err) {
        this.setResponseErrors(err);
        this.loading = false;
        this.endSave();
      }
    },
  },
};
</script>
<style lang="scss">
.order-form {
  .inline-inputs {
    flex-direction: row;
    display: flex;
    align-items: center;
  }

  .card-title {
    font-weight: 500 !important;
    font-size: 25px !important;
    line-height: 31px !important;
    color: #25233a;
  }

  .v-card__title + .v-card__text {
    font-size: 14px;
    line-height: 17px;
    color: #33343d;
  }
}
</style>
