<template>
  <PractitionerCalendar />
</template>
<script>
import PractitionerCalendar from "@/components/Calendar/PractitionerCalendar";
export default {
  name: "Schedule",
  components: {
    PractitionerCalendar,
  },
};
</script>
