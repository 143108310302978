import identity from "lodash/identity";
import pickBy from "lodash/pickBy";

import { apiRequest } from "./apiRequest";

const search = async ({ name = "" } = {}) => {
  const { data } = await apiRequest.get(`/medication`, { params: { name } });
  return data;
};

const getForEncounter = async (encounterId: string) => {
  const { data } = await apiRequest.get(`/medication/encounter/${encounterId}`);
  return data;
};

const getPatientSelfReported = async (patientId: string) => {
  const { data } = await apiRequest.get(`/medication/patient/${patientId}/selfReported`);
  return data;
};

const addForPractitioner = async (practitionerId: string, body: Record<any, any>) => {
  const { data } = await apiRequest.post(`/medication/practitioner/${practitionerId}`, pickBy(body, identity));
  return data;
};

const addForEncounter = async (encounterId: string, body: Record<any, any>) => {
  const { data } = await apiRequest.post(`/medication/encounter/${encounterId}`, pickBy(body, identity));
  return data;
};

const updateForEncounter = async (encounterId: string, medicationId: string, body: Record<any, any>) => {
  const { data } = await apiRequest.post(
    `/medication/encounter/${encounterId}/medication/${medicationId}`,
    pickBy(body, identity),
  );
  return data;
};

const patch = async (medicationId: string, body: Record<any, any>) => {
  const { data } = await apiRequest.patch(`/medication/${medicationId}`, body);
  return data;
};

const add = async (body: Record<any, any>) => {
  const { data } = await apiRequest.post(`/medication/patient`, body);
  return data;
};

const changeStatus = async (body: { medicationId: string; patientId: string; status: string }) => {
  const { data } = await apiRequest.post(`/medication/patient/status`, body);
  return data;
};

const updateStatus = async (body: { medicationId: string; status: string }) => {
  const { data } = await apiRequest.post(`/medication/status`, body);
  return data;
};

const destroy = async (body: { pharmacyId: string; patientId: string }) => {
  const { data } = await apiRequest.delete(`/medication/patient`, { params: body });
  return data;
};

const getPatientMedication = async (body = {}) => {
  const { data } = await apiRequest.get(`/medication/patient`, { params: body });
  return data;
};

const getPractitionerMedication = async (practitionerId: string) => {
  const { data } = await apiRequest.get(`/medication/practitioner/${practitionerId}`);
  return data;
};

export const MedicationApi = {
  search,
  getPatientSelfReported,
  getForEncounter,
  addForEncounter,
  updateForEncounter,
  patch,
  add,
  addForPractitioner,
  getPatientMedication,
  getPractitionerMedication,
  destroy,
  changeStatus,
  updateStatus,
};
