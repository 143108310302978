import { PMH } from "@/pinia-store/interfaces/checkoutI";
import { CheckoutByStatusFilterDto } from "@/types/CheckoutByStatusFilterDto";
import { Medication } from "@/types/medication";

import { apiRequest } from "./apiRequest";

export interface MedicalInformation {
  allergies?: string[];
  medications?: Medication[];
  pmh?: PMH[];
}

export interface GeneralInformation {
  height: string;
  weight: string;
  isSmoking: boolean;
}

interface Information {
  confirmedAccurace?: boolean;
  chiefComplaint?: string;
  complaintDescription?: string;
  visitDate?: Date | null;
  medicalInformation?: MedicalInformation;
  generalInformation?: GeneralInformation;
}

interface CheckoutCreate {
  slotId: string;
  patientId: string;
  information: Information;
  practitionerId: string;
  practitionerConfirm?: boolean;
  reassignPractitionerId?: string;
}

interface CheckoutPatientCreate {
  slotId: string;
  patientId: string;
}

const create = async (body: CheckoutCreate) => {
  const { data } = await apiRequest.post("/checkout", body);
  return data;
};

const createPatient = async (body: CheckoutPatientCreate) => {
  const { data } = await apiRequest.post("/checkout", body);
  return data;
};

const getById = async (sessionId: string) => {
  const { data } = await apiRequest.get(`/checkout/${sessionId}`);
  return data;
};

const update = async (body: CheckoutCreate, sessionId: string) => {
  const { data } = await apiRequest.put(`/checkout/${sessionId}`, body);
  return data;
};

const complete = async (sessionId: string) => {
  const { data } = await apiRequest.post(`/checkout/${sessionId}/complete`, {});
  return data;
};

const getByAppointment = async (appointmentId: string) => {
  const { data } = await apiRequest.get(`/checkout`, { params: { appointmentId } });
  return data;
};

const getByStatus = async (status: string, query?: CheckoutByStatusFilterDto) => {
  const { data } = await apiRequest.get(`/checkout/status/${status}`, { params: query });
  return data;
};

const resend = async (checkoutId: string) => {
  await apiRequest.post(`/checkout/${checkoutId}/resend`);
};

const getPendingRoomInfo = async (slotId: string) => {
  const { data } = await apiRequest.get(`/checkout/slot/${slotId}`);
  return data;
};

export const CheckoutApi = {
  create,
  createPatient,
  update,
  getById,
  complete,
  getByAppointment,
  getByStatus,
  resend,
  getPendingRoomInfo,
};
