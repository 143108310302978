<template>
  <v-dialog :value="dialog" max-width="400" @click:outside="closeDialog">
    <v-card class="success-dialog">
      <v-btn
        class="success-dialog_close-icon"
        persistent
        fab
        dark
        absolute
        color="success"
        depressed
        width="24"
        height="24"
        @click="closeDialog"
      >
        <v-icon size="small">mdi-close</v-icon></v-btn
      >
      <img v-if="!hideTick" src="../../assets/success-tick.svg" width="50" height="50" />
      <v-card-title class="success-dialog_title">
        {{ text }}
      </v-card-title>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "SuccessDialog",
  props: {
    dialog: Boolean,
    text: String,
    hideTick: Boolean,
  },
  methods: {
    closeDialog() {
      this.$emit("on-close");
    },
  },
};
</script>
