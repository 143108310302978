import moment from "moment-timezone";

export const availabilityHours = (): string[] => {
  const hours = [];
  for (let i = 0; i < 24; i++) {
    let quarter = 15;
    hours.push(moment({ hour: i }).format("HH:mm"));
    while (quarter !== 60) {
      hours.push(moment({ hour: i, minute: quarter }).format("HH:mm"));
      quarter += 15;
    }
  }
  return hours;
};
