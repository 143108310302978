export const scrollToBottom = (el: HTMLElement, smooth: boolean) => {
  if (!el) {
    return;
  }
  if (typeof el.scroll === "function") {
    el.scroll({
      top: el.scrollHeight,
      behavior: smooth ? "smooth" : undefined,
    });
  } else {
    el.scrollTop = el.scrollHeight;
  }
};

export const scrollTo = (el: any, count: number, smooth: boolean) => {
  if (typeof el.scroll === "function") {
    el.scroll({
      top: count,
      behavior: smooth ? "smooth" : "instant",
    });
  } else {
    el.scrollTop = count;
  }
};
