<template>
  <v-list nav>
    <v-list-item
      v-for="tab in navTabs"
      :key="tab.title"
      :class="{ 'd-none': ['monitoring', 'addHealthData'].includes(tab.id) && !orders }"
      :to="tab.to"
      class="justify-start"
      link
      @click="action(tab.action)"
    >
      <v-list-item-icon class="ml-7px mr-17px">
        <v-img :src="require(`@/assets/${tab.icon}`)" />
      </v-list-item-icon>
      <div class="nav-item-content d-flex justify-space-between flex-row align-center">
        <div class="nav-item-content-title">
          {{ tab.title }}
        </div>
        <div
          v-if="tab.id === 'visitCenter' && plannedEncountersCount"
          class="nav-item-content-counter d-flex justify-center align-center"
        >
          {{ plannedEncountersCount }}
        </div>
        <div
          v-if="tab.id === 'messages' && unreadMessagesCount"
          class="nav-item-content-counter d-flex justify-center align-center"
        >
          {{ unreadMessagesCount }}
        </div>
        <div
          v-if="tab.id === 'pended-notes' && encounterSumPended"
          class="nav-item-content-counter d-flex justify-center align-center"
        >
          {{ encounterSumPended }}
        </div>
        <div
          v-if="tab.id === 'monitoring' && monitoringCount"
          class="nav-item-content-counter d-flex justify-center align-center"
        >
          {{ monitoringCount }}
        </div>
        <div
          v-if="tab.id === 'clinicalRequest' && clinicalRequestsDraftCount"
          class="nav-item-content-counter d-flex justify-center align-center"
        >
          {{ clinicalRequestsDraftCount }}
        </div>
      </div>
    </v-list-item>
  </v-list>
</template>

<script>
import { mapState } from "pinia";

import { useEncountersStore } from "@/pinia-store/encounters";
import { useNotificationsStore } from "@/pinia-store/notifications";
import { useTasksStore } from "@/pinia-store/tasks";

export default {
  name: "NavList",
  props: ["navTabs", "plannedEncountersCount", "unreadMessagesCount", "orders"],
  computed: {
    ...mapState(useNotificationsStore, ["monitoringCount"]),
    ...mapState(useEncountersStore, ["encounterSumPended"]),
    ...mapState(useTasksStore, ["clinicalRequestsDraftCount"]),
  },
  methods: {
    action(eventName) {
      if (!eventName) {
        return;
      }
      this.$emit("click", eventName);
    },
  },
};
</script>
<style lang="scss">
.nav-item-content {
  color: #ffffff;
  min-width: 120px;
  width: 100%;

  &-title {
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.25px;
  }

  &-counter {
    width: 28px;
    height: 28px;
    border-radius: 50%;
    margin-right: 4px;
    background: $primaryblack5;
    font-weight: bold;
    font-size: 13px;
    line-height: 16px;
    text-align: center;
    color: $primarylight1;
  }
}
</style>
