<template>
  <div class="p-success-payment">
    <div class="p-success-payment_top">
      <div class="p-success-payment_top_avatars">
        <div class="p-success-payment_top_avatars_circle_outer p-success-payment_top_avatars_circle_left">
          <div class="p-success-payment_top_avatars_circle_inner">
            <span>{{ partnerInitials }}</span>
          </div>
        </div>
        <Avatar
          :userId="patientId"
          class="p-success-payment_top_avatars_circle_inner p-success-payment_top_avatars_circle_right"
          size="52"
        />
      </div>
      <div class="p-success-payment_top_background"></div>
      <span v-if="!loading" class="p-success-payment_top_title">
        <img alt="ok" src="@/assets/icons/green-tick-rounded.svg" />
        <span class="heading-4">{{ heading }}</span>
      </span>
      <span v-else>
        <span class="d-flex">
          <v-progress-circular class="mr-2" color="primary" indeterminate size="22" width="2"></v-progress-circular>
          <span class="heading-4 mb-4">Pending Request</span>
        </span>
      </span>
      <span class="p-success-payment_top_text">{{ topText }}</span>
    </div>
    <div class="p-success-payment_middle">
      <v-row no-gutters>
        <v-col class="p-success-payment_middle_right" cols="12">
          <v-row class="mb-3" no-gutters>
            <v-col col="1">
              <img alt="calendar" src="@/assets/icons/calendar-blue.svg" />
            </v-col>
            <v-col class="p-success-payment_middle_right_column" cols="11">
              <span class="p-success-payment_middle_left_pre-text">{{ $t("visits.payment.scheduledDate") }}:</span>
              <span>{{ visitStartDate }}</span>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col col="1">
              <img alt="time" src="@/assets/icons/time-blue.svg" />
            </v-col>
            <v-col class="p-success-payment_middle_right_column" cols="11">
              <span class="p-success-payment_middle_left_pre-text">{{ $t("visits.payment.visitDuration") }}:</span>
              <span>{{ `${visitStart} - ${visitEnd}` }} {{ gmtText }}</span>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>
    <div class="p-success-payment_bottom">
      <PrimaryButton
        :disabled="loading"
        :fullWidth="true"
        :text="$t('general.buttons.done')"
        size="large"
        @onClick="redirectToVisitCenter"
      />
    </div>
  </div>
</template>
<script>
import { mapActions, mapState } from "pinia";

import Avatar from "@/components/Avatar/Index";
import { timeDisplayFormat } from "@/components/Clinicians/constants";
import PrimaryButton from "@/components/uikit/PrimaryButton";
import { timeZoneMixin } from "@/mixins/timeZone";
import { useAuthStore } from "@/pinia-store/auth";
import { usePatientStore } from "@/pinia-store/patient";
import { usePatientsStore } from "@/pinia-store/patients";
import { usePractitionersStore } from "@/pinia-store/practitioners";
import { usePractitionerVisitScheduleStore } from "@/pinia-store/practitionerVisitSchedule";

export default {
  name: "CompleteVisitSchedule",
  props: ["step", "mode"],
  components: {
    PrimaryButton,
    Avatar,
  },
  data() {
    return {
      loading: false,
      practitionerFullName: {
        firstName: "",
        lastName: "",
      },
    };
  },
  computed: {
    ...mapState(useAuthStore, ["role", "uid"]),
    ...mapState(usePatientStore, ["patient", "patientsPractitioner"]),
    ...mapState(usePractitionerVisitScheduleStore, [
      "slot",
      "practitionerProfile",
      "practitionerId",
      "patientId",
      "chiefComplaint",
      "visitDate",
      "complaintDescription",
      "slotId",
    ]),
    ...mapState(usePatientsStore, ["patients"]),
    ...mapState(usePractitionersStore, ["practitioners", "practitioner"]),
    visitStartDate() {
      if (!this.slot) return "";
      return this.$moment(this.slot.start).tz(this.timeZone).format(timeDisplayFormat);
    },
    visitStart() {
      if (!this.slot) return "";
      return this.$moment(this.slot.start).tz(this.timeZone).format("HH:mm A");
    },
    visitEnd() {
      return this.$moment(this.slot.end).tz(this.timeZone).format("HH:mm A");
    },
    heading() {
      return this.$t("practitioner.appointments.success.title");
    },
    topText() {
      return this.$t("practitioner.appointments.success.text");
    },
    partnerInitials() {
      return `${this.patient?.firstName?.[0]?.toUpperCase() || ""} ${this.patient?.lastName?.[0]?.toUpperCase() || ""}`;
    },
    localStep: {
      get() {
        return this.step;
      },
      set(newValue) {
        this.$emit("update:step", newValue);
      },
    },
  },
  mixins: [timeZoneMixin],
  methods: {
    ...mapActions(usePractitionerVisitScheduleStore, ["submitForm", "getSessionInfo"]),
    ...mapActions(usePractitionerVisitScheduleStore, ["setSessionId"]),
    async redirectToVisitCenter() {
      this.$emit("done");
      return await this.$router.push(`/${this.role}/appointments/scheduled`);
    },
  },
  async mounted() {
    this.loading = true;
    const sessionId = this.$route.params.sessionId;

    if (this.sessionId) {
      this.setSessionId(sessionId);
      await this.getSessionInfo();
    }
    await this.submitForm();
    this.loading = false;
  },
  // beforeDestroy() {
  // this.clearAllFields();
  // },
};
</script>

<style scoped></style>
