import { AxiosError } from "axios";
import orderBy from "lodash/orderBy";
import parseInt from "lodash/parseInt";
import moment from "moment-timezone";
import { defineStore } from "pinia";

import { AppointmentsApi } from "@/api/appointment";
import { MediaApi } from "@/api/media";
import { PatientsApi } from "@/api/patients";
import { SlotsApi } from "@/api/slots";
import { useAuthStore } from "@/pinia-store/auth";
import { PractitionerProfile, VisitSettings } from "@/pinia-store/interfaces/checkoutI";
import { usePatientStore } from "@/pinia-store/patient";
import { useVisitSettingsStore } from "@/pinia-store/visitSettings";
import { SlotItem, SlotOnHold } from "@/types/Availability";
import { RolesEnum } from "@/types/Roles.enum";

export enum stateTypes {
  // showing slots for patient practitioner
  patientPractitionerAvailable = "patientPractitionerAvailable",
  // showing  all slots grouped for patient practitioner
  showAllPatientPractitioner = "showAllPatientPractitioner",
  // showing  all practitioners with grouped slots per days
  showAllPractitioners = "showAllPractitioners",
  medicalInfo = "medicalInfo",
  checkout = "checkout",
  error = "error",
}

export interface IPractitionerWithSlots {
  practitioner?: PractitionerProfile;
  slots: SlotItem[];
  loaded: boolean;
  isLoading: boolean;
  expanded: boolean;
}

interface IPatientVisitSchedule {
  files: { id: string }[];
  step: stateTypes;
  slotsOnHold: SlotOnHold[];
  slotsOnHoldIds: string[];
  practitioners: PractitionerProfile[];
  practitionersWithSlots: IPractitionerWithSlots[];
  confirmedAccurace: boolean;
  isFetchedAllPractitioners: boolean;
  isSessionLoading: boolean;
  isLoadingSlots: boolean;
  isPatientProfileLoaded: boolean;
  chiefComplaint: string;
  complaintDescription: string;
  slotId: string;
  sessionId: string;
  errorType: string;
  slot?: SlotItem;
  practitionerId: string;
  searchDate: string; //DD-MM-YYYY
  // used when are showing all slots for several days
  filterDaysDate: string; //DD-MM-YYYY

  [key: string]: string | boolean | stateTypes | [] | VisitSettings | any;
}

export const usePatientVisitScheduleStore = defineStore({
  id: "patientVisitSchedule",
  state: (): IPatientVisitSchedule => ({
    slotsOnHold: [],
    slotsOnHoldIds: [],
    confirmedAccurace: false,
    status: "",
    id: "",
    errorType: "",
    searchDate: moment().format("YYYY-MM-DD"),
    filterDaysDate: moment().format("YYYY-MM-DD"),
    sessionId: "",
    slotId: "",
    slot: undefined,
    practitionerId: "",
    step: stateTypes.patientPractitionerAvailable,
    practitioners: [],
    patients: [],
    patientId: "",
    practitionersWithSlots: [],
    isPatientProfileLoaded: false,
    isLoadingSlots: false,
    isSessionLoading: false,
    isFetchedAllPractitioners: false,
    isFetchedVisitSettings: false,
    chiefComplaint: "",
    complaintDescription: "",
    virtualService: "video",
    files: [],
  }),

  actions: {
    clearAllFields() {
      this.confirmedAccurace = false;
      this.practitionerId = "";
      this.patientId = "";
      this.practitionersWithSlots = [];
      this.practitioners = [];
      this.errorType = "";
      this.slotId = "";
      this.files = [];
    },
    setFiles(payload: [], replace = false) {
      this.files = replace ? [...payload] : [...this.files, ...payload];
    },
    deleteFile(payload: string) {
      this.files = this.files.filter((i: { id: string }) => i.id !== payload);
    },
    setSessionId(payload: string) {
      this.sessionId = payload;
    },
    setPatientId(payload: string) {
      this.patientId = payload;
    },
    setPatients(payload: Record<any, any>) {
      this.patients = payload;
    },
    setIsLoadingSlots(payload: boolean) {
      this.isLoadingSlots = payload;
    },
    setPractitioners(payload: PractitionerProfile[]) {
      this.practitioners = payload;
      const praciWithSlots = this.practitionersWithSlots;
      payload.forEach((item) => {
        const indexExists = praciWithSlots.findIndex((i: IPractitionerWithSlots) => i?.practitioner?.id === item.id);
        if (indexExists === -1)
          praciWithSlots.push({
            practitioner: { ...item },
            loaded: false,
            isLoading: false,
            expanded: false,
            slots: [],
          });
      });
      this.practitionersWithSlots = praciWithSlots;
      this.isFetchedAllPractitioners = true;
    },
    setPractitionersWithSlots(payload: IPractitionerWithSlots[]) {
      this.practitionersWithSlots = payload;
    },
    setPractitionerId(payload: string) {
      this.practitionerId = payload;
    },
    cleanUp() {
      this.confirmedAccurace = false;
      this.practitionerId = "";
      this.patientId = "";
      this.practitionersWithSlots = [];
      this.practitioners = [];
      this.errorType = "";
      this.searchDate = moment().format("YYYY-MM-DD");
      this.filterDaysDate = moment().format("YYYY-MM-DD");
      this.slotId = "";
      this.slot = undefined;
      this.step = stateTypes.patientPractitionerAvailable;
      this.isPatientProfileLoaded = false;
      this.isFetchedAllPractitioners = false;
      this.isSessionLoading = false;
      this.sessionId = "";
      this.chiefComplaint = "";
      this.complaintDescription = "";
    },
    async preloadPractitioners(): Promise<void> {
      if (this.isFetchedAllPractitioners) {
        return;
      }
      try {
        const data = await PatientsApi.getPractitioners({ excludeRegistrars: true });
        this.practitioners = data;
        //  add practitioners to practitionerWithSlots
        const praciWithSlots = this.practitionersWithSlots;
        data.map((item: IPractitionerWithSlots) => {
          // todo: fix this error
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          const indexExists = praciWithSlots.findIndex((i: IPractitionerWithSlots) => i?.practitioner?.id === item.id);
          if (indexExists === -1)
            praciWithSlots.push({
              practitioner: { ...item },
              loaded: false,
              isLoading: false,
              expanded: false,
              slots: [],
            });
        });
        this.practitionersWithSlots = praciWithSlots;
        this.isFetchedAllPractitioners = true;
      } catch (e) {
        console.trace("Error fetching all practitioners");
      }
    },
    async setSlotOnHold(payload: string): Promise<void> {
      try {
        await SlotsApi.setOnHold(payload);
      } catch (e) {
        console.trace("Error fetching all practitioners");
      }
    },
    setSlotsOnHold(payload: { slots: SlotOnHold[]; patientId?: string }) {
      this.slotsOnHold = payload.slots || [];
      this.slotsOnHoldIds = (payload?.slots || []).map((i) => i.slotId);
      if (!this.slotsOnHold?.length) return this.slotId;

      const slotIsBusy = payload.slots.find((i) => i.slotId === this.slotId && i.userId !== payload.patientId);
      if (slotIsBusy) {
        this.slot = undefined;
        this.slotId = "";
      }
    },
    async getSlotsOnHold(): Promise<void> {
      const authStore = useAuthStore();

      try {
        const data = await SlotsApi.getAllOnHold();

        this.setSlotsOnHold({
          slots: data.filter((i: SlotOnHold) => i.userRole !== RolesEnum.Patient && i.userId !== authStore.uid),
          patientId: authStore.uid,
        });
      } catch (e) {
        console.trace("Error fetching slots On Hold");
      }
    },
    setStringFieldByName(payload: { fieldName: any; value: any }) {
      const { fieldName, value } = payload;
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      this[fieldName] = value;
    },
    changeStep(payload: stateTypes) {
      this.step = payload;
      if ([stateTypes.showAllPatientPractitioner, stateTypes.showAllPractitioners].includes(payload)) {
        this.filterDaysDate = this.searchDate;
      }
    },
    async submitForm() {
      const authStore = useAuthStore();
      const practitionerSettings = this.practitionerInfo;
      const patientId = this.patientId;
      const { uid } = authStore;
      const sessionId = this.sessionId;
      const slotId = this.slotId;
      const chiefComplaint = this.chiefComplaint;
      const complaintDescription = this.complaintDescription;
      const practitionerId = this.practitionerId;
      const virtualService = this.virtualService;
      if (sessionId) {
        try {
          await AppointmentsApi.update(sessionId, {
            mediaIds: (this.files || []).map((i: { id: string }) => i.id),
            virtualService,
            slotId,
            practitionerId,
            chiefComplaint,
            complaintDescription,
          });
          await AppointmentsApi.complete(sessionId);
        } catch (err) {
          if (err instanceof AxiosError)
            if (err?.response?.status === 409) this.setStringFieldByName({ value: "conflict", fieldName: "errorType" });
          this.changeStep(stateTypes.error);
        }
        return;
      }
      try {
        await AppointmentsApi.createVisit(
          (this.files || []).map((i: { id: string }) => i.id),
          // todo: remove uid is user is patient
          patientId ? patientId : uid,
          slotId,
          practitionerSettings.isAutoAccept ? "busy" : "pending",
          chiefComplaint,
          virtualService,
          complaintDescription,
          !practitionerSettings.isAutoAccept,
        );
      } catch (err) {
        if (err instanceof AxiosError && err?.response?.status === 409)
          this.setStringFieldByName({ value: "conflict", fieldName: "errorType" });
        this.changeStep(stateTypes.error);
      }
    },
    async getSessionInfo(): Promise<void> {
      this.isSessionLoading = true;
      const authStore = useAuthStore();

      const appointment = await AppointmentsApi.getById(this.sessionId);
      const files = await MediaApi.getComponentFiles({ componentId: this.sessionId, componentType: "appointments" });
      // getting slot info
      this.setFiles(files, true);
      // getting slot info
      if (!appointment.slotId) {
        this.status = appointment.status;
        this.id = appointment.id;
        this.practitionerId = appointment.practitionerId;
        this.patientId = appointment.patientId;
        this.chiefComplaint = appointment.chiefComplaint;
        this.complaintDescription = appointment.complaintDescription;
        this.isSessionLoading = false;
        this.errorType = "conflict";
        return appointment;
      }
      const [slot] = await Promise.all([
        SlotsApi.getById(appointment.slotId),
        this.getPractitionerSlots({ practitionerId: appointment.practitionerId }),
      ]);
      // add slot to current slots because we have only status=> 'free', from sessionId status=>busy-tentative
      let currentSlots = this.slotsOfPractitioner(appointment.practitionerId);
      currentSlots.push(slot);
      currentSlots = orderBy(currentSlots, (i) => moment(i.start).tz(authStore.timeZone).unix(), ["asc"]);
      const practitionersWithSlots = this.practitionersWithSlots;
      const ind = practitionersWithSlots.findIndex(
        (i: IPractitionerWithSlots) => i?.practitioner?.id === appointment.practitionerId,
      );
      practitionersWithSlots[ind].slots = currentSlots;
      this.practitionersWithSlots = practitionersWithSlots;
      this.status = appointment.status;
      this.id = appointment.id;
      this.practitionerId = appointment.practitionerId;
      this.patientId = appointment.patientId;
      this.chiefComplaint = appointment.chiefComplaint;
      this.complaintDescription = appointment.complaintDescription;
      this.setSlotId({ slotId: appointment.slotId, timeZone: authStore.timeZone });
      this.isSessionLoading = false;

      return appointment;
    },
    setSlotId(payload: { slotId: string; timeZone: string }) {
      const practitionerSlots = this.practitionersWithSlots.find((i: IPractitionerWithSlots) =>
        (i?.slots || []).find((slot: SlotItem) => {
          return slot.id === payload.slotId;
        }),
      );
      if (!practitionerSlots?.slots?.length) return;
      const slot = practitionerSlots.slots.find((slot: SlotItem) => slot.id === payload.slotId);
      if (slot && practitionerSlots?.practitioner?.id) {
        this.slot = slot;
        this.slotId = payload.slotId;
        this.practitionerId = practitionerSlots.practitioner.id;
        this.searchDate = moment(slot.start).tz(payload.timeZone).format("YYYY-MM-DD");
      }
    },
    selectFistSlotFromSearchDate(): void {
      const authStore = useAuthStore();
      const slots = this.slotsOnSearchDate;
      if (slots.length) this.setSlotId({ slotId: slots[0].id, timeZone: authStore.timeZone });
    },
    selectFistAvailableSlotFromPatientPractitioner(): void {
      const authStore = useAuthStore();
      const visitSettingsStore = useVisitSettingsStore();
      const { practitionerId, slotsOnHoldIds } = this;
      const practitionerSlots = this.practitionersWithSlots.find(
        (i: IPractitionerWithSlots) => i?.practitioner?.id === practitionerId,
      );
      if (!practitionerSlots?.slots?.length) {
        this.changeStep(stateTypes.showAllPractitioners);
        return;
      }
      const scheduleToStartLimit = visitSettingsStore.scheduleToStartLimit;
      const now = moment.tz(authStore.timeZone).add({
        hours: parseInt((scheduleToStartLimit || "").split(":")[0]),
        minutes: parseInt((scheduleToStartLimit || "").split(":")[1]),
      });
      const slots = (practitionerSlots?.slots || []).filter((slot: SlotItem) => {
        return moment(slot.start).isAfter(now) && !slotsOnHoldIds.includes(slot.id);
      });
      if (slots.length) {
        this.setSlotId({ slotId: slots[0].id, timeZone: authStore.timeZone });
      } else {
        this.changeStep(stateTypes.showAllPractitioners);
      }
    },
    async getPractitionerSlots(payload: {
      practitionerId: string;
      startDate: string;
      expanded: boolean;
    }): Promise<void> {
      this.isLoadingSlots = true;
      const data = await SlotsApi.getIsAvailable({
        practitionerId: payload.practitionerId,
        startDate: this.minScheduleDate,
        excludeRegistrars: true,
      });
      const slotsData = data?.[0]?.slots || null;
      const resultPractitioner = data?.[0]?.practitioner || {};
      const res = [...this.practitionersWithSlots];
      const existIndex = this.practitionersWithSlots.findIndex(
        (i: IPractitionerWithSlots) => i?.practitioner?.id === payload.practitionerId,
      );
      this.isLoadingSlots = false;

      const practitioner = this.practitioners.find((i: PractitionerProfile) => i.id === payload.practitionerId);
      if (existIndex !== -1) {
        res[existIndex] = {
          ...res[existIndex],
          practitioner: practitioner ? practitioner : resultPractitioner,
          slots: (slotsData || []).filter((slot: SlotItem) => !this.slotsOnHoldIds.includes(slot?.id)),
          loaded: true,
          isLoading: false,
          expanded: payload.expanded || false,
        };
      } else {
        if (slotsData)
          res.push({
            slots: slotsData || [],
            practitioner: practitioner ? practitioner : resultPractitioner,
            loaded: true,
            isLoading: false,
            expanded: payload.expanded || false,
          });
      }
      this.practitionersWithSlots = res;
    },
    slotsOfPractitioner(practitionerId: string) {
      const practitionerSlots = this.practitionersWithSlots.find(
        (i: IPractitionerWithSlots) => i?.practitioner?.id === practitionerId,
      );
      const { slotsOnHoldIds = [] } = this;
      return (practitionerSlots?.slots || []).filter((slot: SlotItem) => !slotsOnHoldIds.includes(slot?.id));
    },
  },
  getters: {
    patientProfile: (state) => state.patients.find(($patient: { id: any }) => $patient.id === state.patientId),
    minScheduleDate(): string {
      const authStore = useAuthStore();
      return moment.tz(authStore.timeZone).format("YYYY-MM-DD");
    },
    timeZone(): string {
      const authStore = useAuthStore();

      return authStore.timeZone;
    },
    maxScheduleDate: () => {
      const authStore = useAuthStore();
      const visitSettingsStore = useVisitSettingsStore();
      return moment.tz(authStore.timeZone).add(visitSettingsStore?.scheduleHorizonLimit, "days").format("YYYY-MM-DD");
    },
    $practitionersWithSlots: (state): IPractitionerWithSlots[] => {
      const patientStore = usePatientStore();
      const patientPractitionerId = patientStore?.patient?.practitioner?.id;
      const { slotsOnHoldIds = [] } = state;
      const slotsWithPr = orderBy(state.practitionersWithSlots, (i) => i?.practitioner?.id === patientPractitionerId, [
        "desc",
      ]);

      return slotsWithPr.map((i) => ({
        ...i,
        slots: (i.slots || []).filter((slot) => !slotsOnHoldIds.includes(slot.id)),
      }));
    },
    $searchDate: (state): string => {
      const authStore = useAuthStore();
      return moment.tz(state.searchDate, authStore.timeZone).format("yyyy-MM-DD");
    },
    isLoading: (state): boolean => !state.isPatientProfileLoaded || state.isSessionLoading || state.isLoadingSlots,
    isLayoutPatientPractitionerAvailable: (state): boolean => state.step === stateTypes.patientPractitionerAvailable,
    isLayoutAllPatientPractitioner: (state) => state.step === stateTypes.showAllPatientPractitioner,
    isLayoutAllPractitioners: (state) => state.step === stateTypes.showAllPractitioners,
    slotsOnSearchDate: (state) => {
      const authStore = useAuthStore();
      const visitSettingsStore = useVisitSettingsStore();
      const scheduleToStartLimit = visitSettingsStore.scheduleToStartLimit;
      const { timeZone } = authStore;

      const nowTime = moment.tz(timeZone).add({
        hours: parseInt((scheduleToStartLimit || "").split(":")[0]),
        minutes: parseInt((scheduleToStartLimit || "").split(":")[1]),
      });
      const { practitionerId, searchDate, slotsOnHoldIds = [] } = state;
      const practitionerSlots = state.practitionersWithSlots.find((i) => i?.practitioner?.id === practitionerId);
      if (!practitionerSlots?.slots?.length) return [];
      const date = moment.tz(searchDate, timeZone);
      return practitionerSlots.slots.filter((slot: SlotItem) => {
        return (
          moment(slot.start).tz(timeZone).isSameOrAfter(date.startOf("day").format()) &&
          moment(slot.start).tz(timeZone).isBefore(date.endOf("day").format()) &&
          moment(slot.start).tz(timeZone).isAfter(nowTime.format()) &&
          !slotsOnHoldIds.includes(slot.id)
        );
      });
    },
    slotsOfPractitioner:
      (state) =>
      (practitionerId: string): SlotItem[] => {
        const practitionerSlots = state.practitionersWithSlots.find((i) => i?.practitioner?.id === practitionerId);
        const { slotsOnHoldIds = [] } = state;
        return (practitionerSlots?.slots || []).filter((slot) => !slotsOnHoldIds.includes(slot?.id));
      },
    practitionerSlotsOfDate:
      (state) =>
      (practitionerId: string, searchDate: string): SlotItem[] => {
        const authStore = useAuthStore();
        const { timeZone } = authStore;
        const visitSettingsStore = useVisitSettingsStore();
        const scheduleToStartLimit = visitSettingsStore.scheduleToStartLimit;
        const { slotsOnHoldIds = [] } = state;
        const date = moment.tz(searchDate, timeZone);
        const slots = state.slotsOfPractitioner(practitionerId);
        const nowTime = moment.tz(authStore.timeZone).add({
          hours: parseInt((scheduleToStartLimit || "").split(":")[0]),
          minutes: parseInt((scheduleToStartLimit || "").split(":")[1]),
        });
        if (!slots.length) return [];
        return slots.filter((slot: SlotItem) => {
          return (
            moment(slot.start).isSameOrAfter(date.startOf("day")) &&
            moment(slot.start).isBefore(date.endOf("day")) &&
            moment(slot.start).isAfter(nowTime) &&
            !slotsOnHoldIds.includes(slot.id)
          );
        });
      },
    practitionerDatesOfSlots:
      (state) =>
      (practitionerId: string): string[] => {
        const slots = state.slotsOfPractitioner(practitionerId);
        const { slotsOnHoldIds = [] } = state;
        const authStore = useAuthStore();
        if (!slots.length) return [];
        return [
          ...new Set<string>(
            slots
              .filter((slot: SlotItem) => !slotsOnHoldIds.includes(slot?.id))
              .map((slot: SlotItem): string => moment(slot.start).tz(authStore.timeZone).format("YYYY-MM-DD")),
          ),
        ];
      },
    practitionerInfo: (state) => {
      return state.practitionersWithSlots.find((i) => i?.practitioner?.id === state.practitionerId)?.practitioner;
    },
    isAcceptButtonEnabled: (state): boolean => {
      return Boolean(
        state.practitionerId && state.complaintDescription.length && state.chiefComplaint.length && state.slot?.id,
      );
    },
  },
});
