<template>
  <v-flex
    :style="{ 'background-image': 'url(' + partnerPhotoUrl + ')', 'background-size': 'contain' }"
    class="participant-remote vh100"
  >
    <div class="shadow-head" />
    <div class="shadow-bottom" />
    <v-layout align-start class="text" column justify-start>
      <div class="text-up">
        <v-chip
          v-if="(isFullScreen || $vuetify.breakpoint.xsOnly) && callTime"
          class="chip"
          color="error"
          dark
          label
          small
        >
          {{ callTime }}
        </v-chip>
        <v-icon v-if="participant && muted" class="muted" color="primarylight1" small>fa-microphone-slash</v-icon>
        <span v-if="!minimized" class="title">{{ title }}</span>
        <v-icon v-if="participant && badConnection && !minimized" color="error">$vuetify.icons.plug</v-icon>
        <v-icon v-if="!participant && !minimized" color="primarylight3">mdi-cancel</v-icon>
      </div>
      <div v-if="roleName" class="sub-title">
        {{ roleName }}
      </div>
    </v-layout>
    <div ref="videoContainer" class="participant-remote-component" />
    <div ref="otherContainer" class="d-none" />
    <NoParticipant
      v-if="(!participant || !isVideoStarted) && !minimized"
      :initials="initials"
      :participantId="participantId"
      :show-text="!participant"
    />
  </v-flex>
</template>
<script>
import { mapState } from "pinia";

import { useAuthStore } from "@/pinia-store/auth";
import { useMeetingRoomStore } from "@/pinia-store/meetingRoom";
import { useVideoRoomStore } from "@/pinia-store/videoRoom";

export default {
  name: "RemoteParticipant",
  components: {
    NoParticipant: () => import("./NoParticipant"),
  },
  props: {
    room: Object,
    participant: Object,
    title: String,
    initials: String,
    roleName: String,
    callTime: String,
    muted: Boolean,
    participantId: String,
  },
  data() {
    return {
      isVideoEnabled: false,
      isVideoStarted: false,
      badConnection: true,
    };
  },
  computed: {
    ...mapState(useVideoRoomStore, ["isFullScreen", "practitionerProfile", "patientProfile", "encounter"]),
    ...mapState(useAuthStore, ["role"]),
    ...mapState(useMeetingRoomStore, ["minimized"]),
    partnerPhotoUrl() {
      if (!this.encounter) {
        return "";
      }
      return this.role === "patient" ? this.practitionerProfile.photoURL : this.patientProfile.photoURL;
    },
  },
  mounted() {
    this.participantConnected(this.participant);
  },
  methods: {
    participantDisconnected() {
      const removeAllChildNodes = (node) => {
        while (node.firstChild) {
          node.removeChild(node.lastChild);
        }
      };
      removeAllChildNodes(this.$refs.otherContainer);
      removeAllChildNodes(this.$refs.videoContainer);
    },
    participantConnected(participant) {
      if (!participant) {
        return;
      }
      this.participantDisconnected();
      this.handleNetworkQuality(participant.networkQualityLevel);
      participant.on("trackSubscribed", this.trackSubscribed);
      participant.on("trackUnsubscribed", this.trackUnsubscribed);
      participant.on("trackEnabled", this.trackPublished);
      participant.on("trackDisabled", this.trackUnpublished);
      participant.on("trackPublished", this.trackPublished);
      participant.on("trackRemoved", this.trackUnpublished);
      participant.on("trackStopped", this.trackUnsubscribed);
      participant.on("trackStarted", this.trackStarted);
      participant.on("networkQualityLevelChanged", this.handleNetworkQuality);
      participant.tracks.forEach((publication) => {
        if (publication.isTrackEnabled) {
          this.trackSubscribed(publication.track);
        }
      });
    },
    trackSubscribed(track) {
      if (track && track.isEnabled) {
        if (track.kind === "video") {
          this.$refs.videoContainer.appendChild(track.attach());
          this.isVideoEnabled = true;
          this.isVideoStarted = track.isStarted;
        } else {
          this.$refs.otherContainer.appendChild(track.attach());
        }
      }
    },
    trackUnsubscribed(track) {
      if (track) {
        if (track.kind === "video") {
          this.isVideoEnabled = false;
          this.isVideoStarted = false;
        }
        track.detach().forEach((element) => element.remove());
      }
    },
    trackPublished(publication) {
      this.trackSubscribed(publication.track);
    },
    trackUnpublished(publication) {
      this.trackUnsubscribed(publication.track);
    },
    trackStarted(track) {
      if (track && track.kind === "video") {
        this.isVideoEnabled = true;
        this.isVideoStarted = true;
      }
    },
    handleNetworkQuality(level) {
      this.badConnection = level < 3;
    },
  },
  watch: {
    participant(to) {
      this.participantConnected(to);
    },
  },
};
</script>
<style lang="scss" scoped>
.chip {
  margin-right: 20px;
}

.participant-remote {
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}

.shadow-head {
  position: absolute;
  width: 100%;
  height: 86px;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
  opacity: 0.14;
  transform: matrix(1, 0, 0, -1, 0, 0);
  z-index: 1;
}

.shadow-bottom {
  position: absolute;
  width: 100%;
  height: 136px;
  bottom: 0;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
  opacity: 0.32;
  z-index: 1;
}

.text {
  position: absolute;
  color: white;
  width: 100%;
  height: 86px;
  padding-top: 20px;
  padding-left: 30px;
  padding-bottom: 13px;
  z-index: 2;

  &-up {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  div {
    padding: 0;
  }

  .title {
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 27px;
    margin-right: 8px;
  }

  .sub-title {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
  }
}

.muted {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: $error;
  margin-right: 10px;
}
</style>
