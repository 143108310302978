<template>
  <PatientList />
</template>
<script>
import PatientList from "@/components/Patients";
export default {
  name: "RegistrarClinicianList",
  components: {
    PatientList,
  },
};
</script>
