<template>
  <v-flex ref="container" :class="minimized ? 'minimized' : ''" class="video-room">
    <Loading v-if="loading" />
    <ErrorConnection v-if="error" />
    <AllowAccess v-if="!isPermissionsAccepted" />
    <template v-if="isPermissionsAccepted && !error">
      <template v-if="roomIsReady && !roomIsFinished">
        <FullScreenBtn v-if="!minimized" />
        <MinimizeBtn v-if="userIsPractitioner && !isFullScreen" />
        <RemoteParticipant
          v-if="!loading && localParticipantAcceptedJoin"
          :call-time="callTime"
          :initials="partnerInitials"
          :muted="participantIsMute"
          :participant="partnerParticipant"
          :room="meetingRoom"
          :title="partnerTitle"
        />
        <LocalParticipant
          v-if="localParticipant && isVideo & !minimized"
          :class="isVideo ? '' : 'd-none'"
          :participant="localParticipant"
        />
      </template>

      <WaitingRoom v-if="(!roomIsReady && !roomIsFinished) || !localParticipantAcceptedJoin" />

      <VisitMediaControls
        v-if="!roomIsFinished && !minimized"
        @onChangeAudioInput="onChangeAudioInput"
        @onChangeAudioOutput="onChangeAudioOutput"
        @onChangeVideoInput="onChangeVideoInput"
        @onToggleAudio="onToggleAudio"
        @onToggleVideo="onToggleVideo"
      />
    </template>
  </v-flex>
</template>

<script>
import { mapActions, mapState } from "pinia";
import { connect, createLocalAudioTrack, createLocalTracks, createLocalVideoTrack } from "twilio-video";

import FullScreenBtn from "@/components/MeetingRoom/FullScreenBtn";
import LocalParticipant from "@/components/MeetingRoom/LocalParticipant";
import MinimizeBtn from "@/components/MeetingRoom/MinimizeBtn.vue";
import RemoteParticipant from "@/components/MeetingRoom/RemoteParticipant/Index.vue";
import VisitMediaControls from "@/components/MeetingRoom/VisitMediaControls";
import WaitingRoom from "@/components/MeetingRoom/WaitingRoom";
import AllowAccess from "@/components/VideoRoom/overlays/AllowAccess";
import ErrorConnection from "@/components/VideoRoom/overlays/ErrorConnection";
import Loading from "@/components/VideoRoom/overlays/Loading";
import { snackBarEventBus, snackBarEventName } from "@/eventBuses/snackBar.eventBus";
import { WS_USER_DISCONNECTED } from "@/eventBuses/socketEvents";
import { meetingRoomMixin } from "@/mixins/meetingRoom";
import { useAuthStore } from "@/pinia-store/auth";
import { MeetingRoomStatus, useMeetingRoomStore } from "@/pinia-store/meetingRoom";
import { RolesEnum } from "@/types/Roles.enum";

export default {
  name: "MeetingVideoRoom",
  components: {
    MinimizeBtn,
    VisitMediaControls,
    FullScreenBtn,
    AllowAccess,
    Loading,
    LocalParticipant,
    RemoteParticipant,
    ErrorConnection,
    WaitingRoom,
  },
  props: {
    callTime: String,
  },
  data() {
    return {
      isInConnection: false,
      participants: [],
      videoRoom: null,
      loading: false,
      loadingMediaDevices: false,
      localParticipant: null,
      error: null,
      participantIsMute: false,
      activityTimer: null,
    };
  },
  mixins: [meetingRoomMixin],

  computed: {
    ...mapState(useMeetingRoomStore, [
      "isVideo",
      "isFullScreen",
      "minimized",
      "isAudio",
      "hasVideoDevice",
      "hasAudioDevice",
      "isPermissionsAccepted",
      "localParticipantAcceptedJoin",
      "token",
      "meetingRoom",
      "settings",
      "roomIsReady",
      "remoteParticipant",
      "roomIsFinished",
      "roomId",
      "identityId",
      "isModalMode",
    ]),
    ...mapState(useMeetingRoomStore, { localStoreParticipant: "localParticipant" }),
    ...mapState(useAuthStore, ["role", "userIsPractitioner"]),
    partnerParticipant() {
      return this.participants[0];
    },
    videoSettings() {
      const deviceId = this.settings && this.settings.videoInput;
      return deviceId
        ? {
            deviceId: { exact: deviceId },
          }
        : null;
    },
    audioSettings() {
      const deviceId = this.settings && this.settings.audioInput;
      return deviceId
        ? {
            deviceId: { exact: deviceId },
          }
        : null;
    },
    partnerInitials() {
      return `${this.partnerFirstName?.[0]?.toUpperCase() || ""} ${this.partnerLastName?.[0]?.toUpperCase() || ""}`;
    },
    partnerTitle() {
      const title = this.role === "patient" ? this.$t("general.titles.dr") : "";
      return `${title} ${this.partnerFirstName} ${this.partnerLastName}`;
    },
    partner() {
      return this.role === RolesEnum.Practitioner ? this.meetingRoom?.recipient : this.meetingRoom?.sender;
    },
    partnerFirstName() {
      return this.partner?.fullName;
    },

    partnerLastName() {
      return this.role === RolesEnum.Practitioner
        ? this.meetingRoom?.recipient?.fullName
        : this.meetingRoom?.sender?.fullName;
    },
  },
  methods: {
    ...mapActions(useMeetingRoomStore, [
      "setUserIsReady",
      "setIsPermissionsAccepted",
      "setConnectedParticipant",
      "resetRoom",
      "setHasAudioDevice",
      "setHasVideoDevice",
      "setRoomIsFinished",
      "setRoomIsNotAvailable",
      "setLocalParticipantAcceptedJoin",
    ]),
    ...mapActions(useMeetingRoomStore, ["getRoomWithAccessToken", "getConnectedParticipant", "fetchRoom"]),
    participantConnected(participant) {
      this.isInConnection = true;
      setTimeout(() => {
        this.isInConnection = false;
      }, 1200);
      if (participant.identity !== this.localParticipant.id) {
        this.setConnectedParticipant(null);
        snackBarEventBus.$emit(snackBarEventName, {
          message: `${this.remoteParticipant?.fullName} has been connected`,
          type: "info",
        });
      }
      this.participants.push(participant);
      this.setConnectedParticipant(participant.identity);
      this.getConnectedParticipant(participant.identity);
    },
    userWSDisconnected(user) {
      if (
        !this.isInConnection &&
        this.meetingRoom &&
        this.roomIsReady &&
        this.isPermissionsAccepted &&
        !this.roomIsFinished
      ) {
        const partner = this.partner;
        if (partner?.id !== user.uid) return;
        snackBarEventBus.$emit(snackBarEventName, {
          message: `${this.partnerFirstName || ""} don't seem to have an active internet connection. Please wait.`,
          type: "info",
        });
      }
    },
    participantDisconnected(participant) {
      this.participants = this.participants.filter((el) => el.identity !== participant.identity);
      this.checkRoomStatus();

      if (participant.identity !== this.localParticipant.id) {
        this.setConnectedParticipant(null);
        snackBarEventBus.$emit(snackBarEventName, {
          message: `${this.remoteParticipant?.fullName || "Participant"} has been disconnected`,
          type: "error",
        });
      }
    },
    participantMuted(track) {
      if (track.kind !== "audio") return;
      this.participantIsMute = true;
    },
    participantUnmuted(track) {
      if (track.kind !== "audio") return;
      this.participantIsMute = false;
    },
    async onToggleVideo() {
      if (!this.localParticipant) {
        return;
      }
      if (this.isVideo) {
        await this.videoEnable();
      } else {
        await this.videoDisable();
      }
    },
    async onToggleAudio() {
      if (!this.localParticipant) {
        return;
      }
      if (this.isAudio) {
        await this.audioInputPublish();
      } else {
        await this.audioInputUnPublish();
      }
    },
    async videoDisable() {
      await this.localParticipant.videoTracks.forEach((item) => {
        item.track.disable();
        // item.track.detach();
        // stop not enable again transmission
        // item.track.stop();
      });
    },
    async videoEnable() {
      await this.localParticipant.videoTracks.forEach((item) => {
        item.track.enable();
      });
    },
    async videoUnPublish() {
      for (const item of this.localParticipant.videoTracks.values()) {
        item.track.stop();
        await this.localParticipant.unpublishTrack(item.track);
      }
    },
    async videoPublish() {
      const track = await createLocalVideoTrack(this.videoSettings);
      await this.localParticipant.publishTrack(track);
    },
    async audioInputUnPublish() {
      for (const item of this.localParticipant.audioTracks.values()) {
        item.track.stop();
        await this.localParticipant.unpublishTrack(item.track);
      }
    },
    async audioInputPublish() {
      const track = await createLocalAudioTrack(this.audioSettings);
      await this.localParticipant.publishTrack(track);
    },
    async audioOutputSetup(room) {
      if (this.settings && this.settings.audioOutput) {
        room.on("trackSubscribed", (track) => {
          if (track.kind === "audio") {
            const audioElement = track.attach();
            audioElement.setSinkId(this.settings.audioOutput).then(() => document.body.appendChild(audioElement));
          }
        });
      }
    },
    async onChangeAudioInput() {
      if (!this.localParticipant) {
        return;
      }
      await this.audioInputUnPublish();
      if (this.isAudio) {
        await this.audioInputPublish();
      }
    },
    async onChangeAudioOutput() {
      if (!this.localParticipant) {
        return;
      }
      await this.onConnect();
    },
    async onChangeVideoInput() {
      if (!this.localParticipant) {
        return;
      }
      await this.videoUnPublish();
      if (this.isVideo) {
        await this.videoPublish();
      }
    },
    async disconnect() {
      if (this.videoRoom) {
        await this.videoUnPublish();
        await this.audioInputUnPublish();
        this.videoRoom.localParticipant.tracks.forEach(function (track) {
          track.stop();
        });
        await this.videoRoom.disconnect();
        this.videoRoom = null;
        this.localParticipant = null;
        this.participants = [];
      }
      this.setUserIsReady(false);
      this.setLocalParticipantAcceptedJoin(false);
    },

    async checkRoomStatus() {
      await this.fetchRoom({
        meetingRoomId: this.isModalMode ? this.roomId : this.$route.params.roomId,
        status: MeetingRoomStatus.opened,
      });
      if (!this.meetingRoom) {
        this.setUserIsReady(false);
        this.setRoomIsFinished(true);
        snackBarEventBus.$emit(snackBarEventName, {
          message: "The visit has been finished. Meeting room is not available.",
          type: "info",
        });
      }
    },
    async prepareDevice() {
      await this.getRoomWithAccessToken({
        meetingRoomId: this.isModalMode ? this.roomId : this.$route.params.roomId,
        identity: this.isModalMode ? this.identityId : this.$route.params.identity,
      });
      if (!this.meetingRoom) {
        snackBarEventBus.$emit(snackBarEventName, {
          message: "The visit has been finished. Meeting room is not available.",
          type: "info",
        });
        this.setRoomIsFinished(true);
        this.setRoomIsNotAvailable(true);
        return;
      }

      // eslint-disable-next-line no-unreachable
      let stream;
      try {
        stream = await navigator.mediaDevices.getUserMedia({ video: true });
        stream.getTracks().forEach((track) => track.stop());
      } catch (err) {
        this.setHasVideoDevice(false);
      }
      try {
        stream = await navigator.mediaDevices.getUserMedia({
          audio: true,
        });
        stream.getTracks().forEach((track) => track.stop());
        this.setIsPermissionsAccepted(true);
        //todo: add && this.roomIsReady
      } catch (err) {
        this.setHasAudioDevice(false);
        this.setIsPermissionsAccepted(false);
      }
      this.loading = false;
      this.setUserIsReady(true);
    },
    async onConnect() {
      if (this.loading) {
        return false;
      }
      this.loading = true;
      try {
        await navigator.mediaDevices.getUserMedia({ audio: true, video: true });
      } catch (err) {
        console.error("On request Media device", err);
      }
      try {
        this.error = null;
        // await this.disconnect();
        const opt = {
          name: this.localStoreParticipant?.fullName,
          preferredVideoCodecs: "auto",
          bandwidthProfile: {
            video: {
              contentPreferencesMode: "auto",
              clientTrackSwitchOffControl: "auto",
            },
          },
          preferredAudioCodecs: [{ codec: "opus", dtx: false }],
          networkQuality: {
            local: 1,
            remote: 1,
          },
        };
        if (this.settings) {
          opt.tracks = await createLocalTracks({
            audio: this.settings.audioInput,
            video: this.settings.videoInput,
          });
        }
        const room = await connect(this.token, opt);
        this.localParticipant = room.localParticipant;
        await this.onToggleAudio();
        await this.onToggleVideo();
        room.participants.forEach((item) => this.participants.push(item));
        await this.audioOutputSetup(room);
        room.on("trackUnpublished", this.participantMuted);
        room.on("trackPublished", this.participantUnmuted);
        room.on("participantConnected", this.participantConnected);
        room.on("participantDisconnected", this.participantDisconnected);
        // room.once("disconnected", () => {
        //   return room.participants.forEach(this.participantDisconnected);
        // });
        this.videoRoom = room;
      } catch (e) {
        console.error(e);
        const stream = await navigator.mediaDevices.getUserMedia({ audio: true, video: true });
        if (stream.getVideoTracks().length > 0 && stream.getAudioTracks().length > 0) {
          this.setIsPermissionsAccepted(false);
        }
        if (this.isPermissionsAccepted) this.error = e.message || e;
        snackBarEventBus.$emit(snackBarEventName, { message: this.error, type: "error" });
        await this.disconnect();
      } finally {
        this.loading = false;
      }
    },
  },
  watch: {
    async $route() {
      await this.disconnect();
      await this.prepareDevice();
    },
    async localParticipantAcceptedJoin(val) {
      if (!val) return;
      if (this.meetingRoom && this.roomIsReady && this.isPermissionsAccepted && !this.roomIsFinished) {
        await this.getRoomWithAccessToken({
          meetingRoomId: this.isModalMode ? this.roomId : this.$route.params.roomId,
          identity: this.isModalMode ? this.identityId : this.$route.params.identity,
        });
        await this.onConnect();
      }
    },
    onOffline() {
      snackBarEventBus.$emit(snackBarEventName, {
        message: `No Internet connection. Make sure Wi-Fi or cellular data is turned on, then try again.`,
        type: "error",
      });
    },
    onOnline() {
      snackBarEventBus.$emit(snackBarEventName, {
        message: `Internet connection was restored!`,
        type: "info",
      });
      if (this.meetingRoom && this.roomIsReady && this.isPermissionsAccepted && !this.roomIsFinished) this.onConnect();
    },
  },

  async mounted() {
    this.sockets.subscribe(WS_USER_DISCONNECTED, this.userWSDisconnected);
    if (!navigator.onLine) this.onOffline();
    window.addEventListener("offline", this.onOffline);

    window.addEventListener("online", this.onOnline);
    this.loading = true;
    await this.disconnect();
    this.$userActivityTracker.destroy();

    await this.prepareDevice();
  },
  async beforeDestroy() {
    this.sockets.unsubscribe(WS_USER_DISCONNECTED);
    await this.disconnect();
    this.$userActivityTracker.startTimer();
  },
};
</script>

<style lang="scss" scoped>
.video-room {
  position: relative;
  background: var(--gray-1, #33343e);
  border-radius: 5px;
  height: calc(100vh - 100px);
  max-height: 720px;
  min-height: 240px;

  &.minimized {
    width: 100px;
    height: 132px;
    min-height: inherit;
  }

  :deep .participant {
    &-local {
      overflow: hidden;
      position: absolute;
      width: 20%;
      z-index: 2;
      bottom: 75px;
      right: 30px;
      padding: 0;

      video {
        background: #1e1e1e;
        position: relative;
        width: 100%;
        margin: 0;
        border-radius: 4px;
        border: 1px solid $white;
        transform: rotateY(180deg);
      }
    }

    &-remote {
      height: 100%;
      width: 100%;

      &-component {
        height: 100%;
        width: 100%;
        position: absolute;

        video {
          height: 100%;
          width: 100%;
          position: absolute;
          left: 0;
          right: 0;
          margin-left: auto;
          margin-right: auto;
        }
      }
    }

    &-waiting {
      background: #33343e;
      width: 100%;
      height: 100%;

      video {
        height: 100%;
        width: 100%;
        opacity: 0.2;
        transform: rotateY(180deg);
      }
    }
  }
}

.v-snack__wrapper {
  top: 40px !important;
  position: fixed;
}

.v-snack__content {
  color: #000000 !important;
  border-bottom: 4px solid #249d54 !important;
}

.v-snack__action {
  margin: 0 !important;
}
</style>
