import moment from "moment-timezone";

export const feetToFeetInchesDisplay = (value?: number): string => {
  if (!value) return "";
  const inc = ((value - Math.trunc(value)) * 12).toFixed();
  return `${Math.trunc(value) || 0}′${inc === "0" ? "" : `${inc}″`}`;
};
export const feetFromFeetInches = (feet?: number, inches?: number): string => {
  return ((feet || 0) + (inches ? inches / 12 : 0)).toFixed(2);
};

export const feetInchesFromFeet = (value?: number): { in: number; ft: number } => {
  if (!value) return { in: 0, ft: 0 };

  const inches = Math.floor((value - Math.trunc(value)) * 12);
  const feet = Math.trunc(value) || 0;

  return {
    in: inches,
    ft: feet,
  };
};

export const errToText = (err: any) => {
  if (Array.isArray(err?.response?.data?.message)) return err?.response?.data?.message[0];
  if (err?.response?.data?.message) return err?.response?.data?.message;
  if (err?.data?.message) return err?.data?.message;
  if (err?.message) return err?.message;

  return err;
};

export const calcAge = (date: string, format?: string | undefined): number =>
  format ? moment().diff(moment(date, format), "years") : moment().diff(moment(date), "years");
