<template>
  <v-select
    :error-messages="errors"
    :outlined="!!errors.length || outlined"
    :value="value"
    class="base-input"
    :solo="!outlined"
    :flat="!outlined"
    :menu-props="menuProps"
    :cache-items="false"
    :items="items"
    :hide-details="hideDetails"
    dense
    :disabled="disabled"
    :clearable="clearable"
    height="47"
    :label="label"
    :item-value="itemValue"
    :item-text="itemText"
    append-icon="mdi-menu-down"
    @blur="onBlur"
    @focus="outlined = true"
    @change="onChange"
  >
    <template v-slot:append>
      <v-icon size="24" color="icon"> mdi-menu-down</v-icon>
    </template>
  </v-select>
</template>
<script>
export default {
  name: "Select",
  props: {
    value: [String, Number],
    label: String,
    clearable: Boolean,
    items: Array,
    itemText: String,
    itemValue: String,
    errors: { default: () => [], type: Array },
    fieldName: { default: "", type: String },
    hideDetails: { default: false, type: Boolean },
    disabled: { default: false, type: Boolean },
    menuProps: { default: () => ({ auto: true }), type: Object },
  },
  data: () => ({
    outlined: false,
  }),
  methods: {
    onChange(value) {
      this.$emit("change", value, this.fieldName);
      this.$emit("input", value);
    },
    onBlur() {
      this.outlined = false;
      this.$emit("blur");
    },
  },
};
</script>
