<template>
  <div class="d-flex border-radius-inherit typing-row row-transition">
    <div class="full-width d-inline-flex flex-column border-radius-inherit">
      <div class="full-width d-inline-flex justify-start border-radius-inherit" :class="{ mar: !isFloatingChat }">
        <div class="typing border-radius-inherit">
          <div class="container-dot border-radius-inherit">
            <span class="dot"></span>
            <span class="dot"></span>
            <span class="dot"></span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TypingRow",
  props: {
    visible: Boolean,
    isFloatingChat: Boolean,
  },
};
</script>

<style scoped lang="scss">
.typing {
  background: #f2f6f9;
  border-radius: 10px;
  display: flex;
}
.typing-row {
  margin-top: 8px;
  width: 100%;
  opacity: 1;
}

.visible {
  opacity: 1;
  transition: all 0.2s;
}

.mar {
  margin-left: 33px;
}

.container-dot {
  height: 37px;
  width: 56px;
  padding-left: 15px;
  display: flex;
  align-items: center;
}
.dot {
  height: 8px;
  width: 8px;
  margin: 0.5px;
  border-radius: 100%;
  display: inline-block;
  background-color: #807f7f;
  animation: 1.2s typing-dot ease-in-out infinite;
}
.dot:nth-of-type(2) {
  animation-delay: 0.15s;
}
.dot:nth-of-type(3) {
  animation-delay: 0.25s;
}
@keyframes typing-dot {
  15% {
    transform: translateY(-35%);
    opacity: 0.5;
  }
  30% {
    transform: translateY(0%);
    opacity: 1;
  }
}
</style>
