import { routesEnum } from "@/types/Routes.enum";
import PatientVisitNote from "@/views/Patient/Visits/PatientVisitNote.vue";

import {
  chatBreadcrumbs,
  dependentsBreadcrumb,
  ifAuthenticated,
  mediaBreadcrumbs,
  medicalRecordBreadcrumbs,
  monitoringBreadcrumbs,
  notificationsBreadcrumbs,
  profileSettingsBreadcrumbs,
  requestVisitBreadcrumbs,
  visitCenterArchiveBreadcrumbs,
  visitCenterUpcomingBreadcrumbs,
} from "./utils";

const patientRoutes = [
  {
    path: "/patient",
    name: routesEnum.patientHome,
    component: (): unknown => import("@/views/Patient/View.vue"),
    meta: {
      authorize: ["patient"],
    },
    redirect: "/patient/encounters/",
    beforeEnter: ifAuthenticated,
    children: [
      {
        path: "chat",
        name: routesEnum.patientChat,
        meta: { breadcrumbs: chatBreadcrumbs },
        component: (): unknown => import("@/views/Patient/Chat/Index.vue"),
      },
      {
        path: "dependents",
        meta: { breadcrumbs: dependentsBreadcrumb },
        component: (): unknown => import("@/views/Patient/Dependents/Index.vue"),
        children: [
          {
            path: "",
            meta: { breadcrumbs: dependentsBreadcrumb },
            name: routesEnum.patientDependents,
            component: (): unknown => import("@/views/Patient/Dependents/List.vue"),
          },
          {
            path: "register",
            meta: { breadcrumbs: dependentsBreadcrumb },

            name: routesEnum.patientDependentsRegister,
            component: (): unknown => import("@/views/Patient/Dependents/Register.vue"),
          },
        ],
      },
      {
        path: "monitoring",
        name: routesEnum.patientMonitoring,
        meta: { breadcrumbs: monitoringBreadcrumbs },
        component: (): unknown => import("@/views/Patient/Monitoring/Index.vue"),
      },
      {
        path: "notifications",
        name: routesEnum.patientNotifications,
        meta: { breadcrumbs: notificationsBreadcrumbs },
        component: (): unknown => import("@/views/Patient/Notifications/Index.vue"),
      },
      {
        path: "monitoring/bloodPressure",
        name: routesEnum.patientMonitoringBloodPressure,
        meta: { breadcrumbs: monitoringBreadcrumbs },
        component: (): unknown => import("@/views/Patient/Monitoring/bloodPressure/Index.vue"),
      },
      {
        path: "monitoring/bodyTemperature",
        name: routesEnum.patientMonitoringBodyTemperature,
        meta: { breadcrumbs: monitoringBreadcrumbs },
        component: (): unknown => import("@/views/Patient/Monitoring/bodyTemperature/Index.vue"),
      },
      {
        path: "monitoring/heartRate",
        name: routesEnum.patientMonitoringHeartRate,
        meta: { breadcrumbs: monitoringBreadcrumbs },
        component: (): unknown => import("@/views/Patient/Monitoring/heartRate/Index.vue"),
      },
      {
        path: "monitoring/oxygenSaturation",
        name: routesEnum.patientMonitoringOxygenSaturation,
        meta: { breadcrumbs: monitoringBreadcrumbs },
        component: (): unknown => import("@/views/Patient/Monitoring/oxygenSaturation/Index.vue"),
      },
      {
        path: "monitoring/respiratoryRate",
        name: routesEnum.patientMonitoringRespiratoryRate,
        meta: { breadcrumbs: monitoringBreadcrumbs },
        component: (): unknown => import("@/views/Patient/Monitoring/respiratoryRate/Index.vue"),
      },
      {
        path: "monitoring/bloodGlucose",
        name: routesEnum.patientMonitoringBloodGlucose,
        meta: { breadcrumbs: monitoringBreadcrumbs },
        component: (): unknown => import("@/views/Patient/Monitoring/bloodGlucose/Index.vue"),
      },
      {
        path: "quick/monitoring",
        name: routesEnum.patientQuickMonitoring,
        component: (): unknown => import("@/views/Patient/Monitoring/QuickMonitoring.vue"),
      },
      {
        path: "media",
        name: routesEnum.patientMedia,
        meta: { breadcrumbs: mediaBreadcrumbs },
        component: (): unknown => import("@/components/media/Index.vue"),
      },
      {
        path: "records",
        name: routesEnum.patientMedicalRecords,
        meta: { breadcrumbs: medicalRecordBreadcrumbs },
        component: (): unknown => import("@/views/Patient/MedicalRecord/View.vue"),
      },
      {
        path: "settings",
        name: routesEnum.patientSettings,
        component: (): unknown => import("@/views/Patient/Settings.vue"),
        meta: { breadcrumbs: profileSettingsBreadcrumbs },
      },
      {
        path: "visits",
        component: (): unknown => import("@/views/Patient/Appointments/View.vue"),
        redirect: "/patient/visits/upcoming/",
        children: [
          {
            path: "upcoming",
            name: routesEnum.patientUpcomingVisits,
            component: (): unknown => import("@/views/Patient/Appointments/Upcoming.vue"),
            meta: { breadcrumbs: visitCenterUpcomingBreadcrumbs, layout: "default" },
          },
          {
            path: "archive",
            component: (): unknown => import("@/views/Patient/Appointments/Archive.vue"),
            meta: { breadcrumbs: visitCenterArchiveBreadcrumbs, layout: "default" },
          },
        ],
      },
      {
        path: "encounters",
        component: (): unknown => import("@/views/MiddleComponent.vue"),
        redirect: "/patient/visits/upcoming",
        children: [
          {
            path: "schedule",
            component: (): unknown => import("@/views/Patient/Visits/Request.vue"),
            meta: { breadcrumbs: requestVisitBreadcrumbs },
          },
          {
            path: "schedule/:sessionId/:status",
            name: routesEnum.patientCheckoutComplete,
            component: (): unknown => import("@/views/Patient/Visits/Request.vue"),
            meta: { breadcrumbs: requestVisitBreadcrumbs },
          },
          {
            path: ":encounterId",
            component: (): unknown => import("@/views/Patient/Visits/Call.vue"),
            name: routesEnum.patientEncounter,
            meta: { collapseSidebar: true, hideNav: true },
          },
          {
            path: ":encounterId/visitNote",
            name: routesEnum.patientVisitNote,
            component: PatientVisitNote,
            meta: { collapseSidebar: true },
          },
          {
            path: ":encounterId/summary",
            component: (): unknown => import("@/views/Patient/Visits/Summary.vue"),
            meta: { collapseSidebar: true },
          },
        ],
      },
    ],
  },
];
export default patientRoutes;
