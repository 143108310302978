<template>
  <v-card v-if="Object.keys(patient).length !== 0" flat outlined>
    <div class="d-visit-desc_header">
      <span>{{ $t("visits.description.title") }}</span>
    </div>
    <div class="d-visit-desc_contacts">
      <span class="subtitle">
        {{ $t("visits.description.patientInformation") }}
      </span>
      <span class="d-visit-desc_contacts_name">{{ `${patient.firstName} ${patient.lastName}` }}</span>
      <div class="patient-info_contacts">
        <v-card-text class="patient-info_email">
          {{ patient.email }}
        </v-card-text>
        <v-icon> mdi-circle-small</v-icon>
        <v-card-text class="patient-info_phone">
          {{ patient.phoneNumber }}
        </v-card-text>
      </div>
      <span>
        <img class="d-visit-desc_tick-icon" src="../../../assets/icons/rounded-sick.svg" /><span class="subtitle"
          >{{ $t("visits.chiefComplaint") }}
        </span></span
      >
      <v-card-text class="patient-info_reason">
        {{ translateChiefComplaint(appointmentInformation.chiefComplaint) }}
      </v-card-text>
      <span class="subtitle">
        {{ $t("visits.description.visitReason") }}
      </span>
      <v-card-text class="patient-info_reason">
        {{ appointmentInformation.complaintDescription }}
      </v-card-text>
    </div>
    <div class="d-visit-desc_mid-header">
      <span>{{ $t("visits.description.generalMedicalInformation") }}</span>
    </div>
    <div class="d-visit-desc_general">
      <div class="general-row">
        <p class="general-row_title">
          {{ $t("general.inputs.height") }}
        </p>
        <v-chip class="general-row-chip" label>
          {{ patientHeight }}
        </v-chip>
      </div>
      <div class="general-row">
        <p class="general-row_title">
          {{ $t("general.inputs.weight") }}
        </p>
        <v-chip class="general-row-chip" label>
          {{ patientWeight }}
        </v-chip>
      </div>
      <div class="general-row">
        <p class="general-row_title">
          {{ $t("general.inputs.smokingStatus") }}
        </p>
        <v-chip class="general-row-chip" label>
          {{
            translateSmokingStatus(patientSettings.generalInformation && patientSettings.generalInformation.isSmoking)
          }}
        </v-chip>
      </div>
    </div>
    <div
      v-if="
        patientSettings.medicalInformation &&
        patientSettings.medicalInformation.pmh &&
        patientSettings.medicalInformation.pmh.length
      "
    >
      <div class="d-visit-desc_mid-header">
        <span>{{ $t("visits.description.pastMedicalHistory") }}</span>
      </div>
      <div v-for="(pmhItem, idx) in patientSettings.medicalInformation.pmh" :key="idx" class="pmh-row row-height" tile>
        <p>
          {{ pmhItem.name }}
        </p>
        <p>
          {{ pmhItem.year }}
        </p>
      </div>
    </div>
    <div
      v-if="
        patientSettings.medicalInformation &&
        patientSettings.medicalInformation.allergies &&
        patientSettings.medicalInformation.allergies.length
      "
    >
      <div class="d-visit-desc_mid-header">
        <span>{{ $t("visits.description.allergies") }}</span>
      </div>
      <div
        v-for="allergy in patientSettings.medicalInformation.allergies"
        :key="allergy"
        class="pmh-row row-height"
        tile
      >
        <p>{{ allergy }}</p>
      </div>
    </div>
  </v-card>
</template>

<script>
import { mapState } from "pinia";

import { PatientsApi } from "@/api/patients";
import { useAppointmentsStore } from "@/pinia-store/appointments";
import { useCheckoutStore } from "@/pinia-store/checkout";

export default {
  name: "PractitionerPendingVisitDescription",
  data() {
    return {
      patient: {},
      patientSettings: {},
      patientHeight: "",
      patientWeight: "",
    };
  },
  computed: {
    ...mapState(useCheckoutStore, ["pendingRoomInfo"]),
    ...mapState(useAppointmentsStore, ["appointmentInformation"]),
  },
  methods: {
    translateChiefComplaint(val) {
      return this.$t("general.chiefComplaints")[val] || val || "-";
    },
    translateSmokingStatus(val) {
      return this.$t("general.smokingStatus")[val] || val || "-";
    },
  },
  async mounted() {
    this.patient = await PatientsApi.patientGetProfile(this.appointmentInformation.patientId);
    this.patientSettings = await PatientsApi.getSettings(this.appointmentInformation.patientId);
    const patientHeights = await PatientsApi.getBodyHeights(this.appointmentInformation.patientId);
    const patientWeights = await PatientsApi.getBodyWeights(this.appointmentInformation.patientId);
    this.patientHeight = patientHeights.sort((a, b) => new Date(a.date) - new Date(b.date))[0].value;
    this.patientWeight = patientWeights.sort((a, b) => new Date(a.date) - new Date(b.date))[0].value;
  },
};
</script>
<style lang="scss" scoped>
.patient-info {
  padding: 20px 20px 30px 20px;

  &_email {
    font-style: normal;
    font-weight: 500;
    line-height: 17px;
    color: #2177e0;
    padding: 0;
  }

  &_phone {
    font-style: normal;
    font-weight: 500;
    line-height: 17px;
    color: $primarylight3;
    padding: 0;
  }

  &_contacts {
    display: flex;
    align-items: center;
    margin-bottom: 25px;
  }

  &_reason {
    padding: 0;
    color: $primaryblack;
    margin-bottom: 5px;
  }
}

.general {
  padding: 20px 10px;

  &-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px;
    margin-top: 10px;

    &_title {
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      margin: 0;
    }

    &-chip {
      width: 50px;
      display: block;
      text-align: center;
    }
  }
}

.pmh {
  padding: 20px;

  &-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;

    p {
      margin: 0;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 17px;
      mix-blend-mode: normal;
      opacity: 0.5;
    }
  }
}

.row-height {
  min-height: 50px;
}

.subtitle {
  padding: 0;
  mix-blend-mode: normal;
  opacity: 0.4;
  margin-bottom: 10px;
}
</style>
