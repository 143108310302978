<template>
  <div>
    <div class="details">
      <div class="value" v-html="item.privateNote || ''"></div>
      <div class="date">
        <template v-if="practitioner">
          {{ practitioner }}
        </template>
        {{ date }}
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "pinia";

import { timeDisplayFormat } from "@/components/Clinicians/constants";
import { useAuthStore } from "@/pinia-store/auth";

export default {
  name: "AddendumNote",
  props: ["item"],
  computed: {
    ...mapState(useAuthStore, ["timeZone"]),
    date() {
      let momentDate = this.$moment(this.item.sent);
      return momentDate.isValid() ? momentDate.tz(this.timeZone).format(`[on] ${timeDisplayFormat} HH:mm`) : "";
    },
    practitioner() {
      return this.item?.practitioner
        ? `Added by: Dr. ${this.item.practitioner?.firstName || ""} ${this.item.practitioner?.lastName || ""}`
        : null;
    },
  },
};
</script>

<style lang="scss" scoped>
.details {
  display: flex;
  flex-direction: column;
  margin: 20px 0 0 0.7em;

  .value {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.32px;
    color: #33343e;
  }

  .date {
    letter-spacing: 0.32px;
    color: #91909c;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    mix-blend-mode: normal;
    margin-top: 5px;
  }
}
</style>
