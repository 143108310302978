<template>
  <v-menu bottom left offset-y>
    <template v-slot:activator="{ on, attrs }">
      <v-btn :height="btnHeight" :max-height="btnHeight" color="success" elevation="0" v-bind="attrs" v-on="on">
        Meet now
      </v-btn>
    </template>
    <v-list>
      <v-list-item @click="inviteToRoom(recipient, 'phone')">
        <v-list-item-title class="templates__edit">Invite via Text Message</v-list-item-title>
      </v-list-item>
      <v-list-item @click="inviteToRoom(recipient, 'email')">
        <v-list-item-title class="templates__edit">Invite via Email</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import { mapActions, mapState } from "pinia";

import { useAuthStore } from "@/pinia-store/auth";
import { useMeetingRoomStore } from "@/pinia-store/meetingRoom";
import { templateString } from "@/utils/locales.helpers";

export default {
  name: "MeetNowMenu",
  props: {
    recipient: {
      type: [Object],
      required: true,
    },
    btnHeight: {
      type: Number,
      default: 36,
    },
  },
  computed: {
    ...mapState(useAuthStore, ["displayName"]),
    ...mapState(useMeetingRoomStore, ["template"]),
  },
  methods: {
    ...mapActions(useMeetingRoomStore, [
      "toggleInviteDialog",
      "setInvitePhoneNumber",
      "setInviteMethod",
      "setInviteFullName",
      "setInviteEmail",
      "setInviteId",
      "setInviteReceiver",
      "setInviteText",
    ]),
    async inviteToRoom(recipient, method) {
      const fullName = `${recipient.fullName || `${recipient.firstName || ""} ${recipient.lastName || ""}`}`;
      this.setInviteMethod(method);
      this.setInviteFullName(fullName);
      this.setInvitePhoneNumber(recipient.phoneNumber || "");
      this.setInviteEmail(recipient.email || "");
      this.setInviteId(recipient.id || "");
      this.setInviteReceiver({
        id: recipient.id,
        firstName: recipient.firstName,
        lastName: recipient.lastName,
        fullName: fullName,
      });

      const inviteText = templateString(this.template, {
        id: recipient.id,
        name: fullName || "",
        practitioner: this.displayName || "",
        link: "%link%",
      });
      this.setInviteText(inviteText);
      this.toggleInviteDialog();
    },
  },
};
</script>
