<template>
  <div class="add-practitioner">
    <Stepper :isPrevStepRequired="true" :step="step" :texts="steps" />
    <v-row v-if="step === 1" class="pa-2">
      <v-col class="header" cols="12">
        <h4 class="title-text">
          We need email address and personal information to give Practitioner access to Axis.Clinic
        </h4>
        <p class="subtitle-text">
          We take confidential data seriously. We’ll use Practitioner email to create account and password.
        </p>
      </v-col>
    </v-row>
    <v-row v-if="step === 1" class="form pa-2">
      <v-col class="ps_input-group" cols="12">
        <h6 class="title-text">Complete Required Information</h6>
        <BaseInput
          v-model="email"
          :errors="emailErrors"
          class="marginTop12"
          fieldName="firstName"
          placeholder="Email"
          @blur="$v.email.$touch()"
        />
        <BaseInput
          v-model="firstName"
          :errors="firstNameErrors"
          class="marginTop12"
          fieldName="firstName"
          placeholder="First Name"
          @blur="$v.firstName.$touch()"
        />
        <BaseInput
          v-model="lastName"
          :errors="lastNameErrors"
          class="marginTop12"
          fieldName="lastName"
          placeholder="Last Name"
          @blur="$v.lastName.$touch()"
        />
        <BaseInput
          v-model="qualification"
          class="marginTop12"
          fieldName="Medical"
          placeholder="Credentials"
          @blur="$v.email.$touch()"
        />
        <StateSelect v-model="state" :errors="stateErrors" @blur="$v.state.$touch()" @change="stateSelectChange" />
      </v-col>
      <v-col cols="12">
        <PrimaryButton
          :disabled="$v.$invalid"
          :full-width="true"
          :loading="buttonLoading"
          text="Add Practitioner"
          @onClick="saveChanges"
        />
      </v-col>
    </v-row>
    <v-row v-if="step === 2" class="step-2 pa-2">
      <v-col class="header" cols="12">
        <h4 class="title-text green--text">Congratulations!</h4>
        <h4 class="title-text">Practitioner have been registered in the system</h4>
        <p class="subtitle-text">Now email with generated password was sent to Practitioner.</p>
        <p class="subtitle-text">
          To add another Practitioner
          <span class="link pointer" @click="onNew">click here</span>
        </p>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { email, required } from "vuelidate/lib/validators";

import { PractitionersAPI } from "@/api/practitioners";
import StateSelect from "@/components/shared/StateSelect";
import Stepper from "@/components/shared/Stepper";
import BaseInput from "@/components/uikit/BaseInput";
import PrimaryButton from "@/components/uikit/PrimaryButton";
import { snackBarEventBus, snackBarEventName } from "@/eventBuses/snackBar.eventBus";
import { defaultLocale } from "@/utils/constants";

export default {
  name: "AddPractitioner",
  components: { Stepper, StateSelect, PrimaryButton, BaseInput },
  data: () => {
    return {
      step: 1,
      buttonLoading: false,
      email: "",
      firstName: "",
      qualification: "",
      state: "",
      lastName: "",
      locale: defaultLocale,
      loading: false,
    };
  },
  validations: {
    state: { required },
    locale: { required },
    email: { required, email },
    firstName: { required },
    lastName: { required },
  },
  computed: {
    stateErrors() {
      const errors = [];
      if (!this.$v.state.$dirty) return errors;
      !this.$v.state.required &&
        errors.push(this.$t("validation.required", { input: this.$t("general.inputs.state") }));
      return errors;
    },
    emailErrors() {
      const errors = [];
      if (!this.$v.email.$dirty) return errors;
      !this.$v.email.email && errors.push(this.$t("validation.email"));
      !this.$v.email.required &&
        errors.push(this.$t("validation.required", { field: this.$t("general.inputs.email") }));
      return errors;
    },
    firstNameErrors() {
      const errors = [];
      if (!this.$v.firstName.$dirty) return errors;
      !this.$v.firstName.required &&
        errors.push(this.$t("validation.required", { field: this.$t("general.inputs.firstName") }));
      return errors;
    },
    lastNameErrors() {
      const errors = [];
      if (!this.$v.lastName.$dirty) return errors;
      !this.$v.lastName.required &&
        errors.push(this.$t("validation.required", { field: this.$t("general.inputs.lastName") }));
      return errors;
    },
    steps() {
      return {
        _1: "Complete Required Information",
        _2: "Create Practitioner account",
      };
    },
  },
  methods: {
    goToPage(number) {
      this.step = number;
    },
    onNew() {
      this.$v.$reset();
      this.step = 1;
      this.buttonLoading = false;
      this.email = "";
      this.firstName = "";
      this.qualification = "";
      this.state = "";
      this.lastName = "";
      this.locale = defaultLocale;
      this.loading = false;
    },
    stateSelectChange(state) {
      this.state = state;
    },
    async saveChanges() {
      try {
        this.buttonLoading = true;
        const body = {
          email: this.email,
          firstName: this.firstName,
          lastName: this.lastName,
          locale: this.locale,
          qualification: this.qualification,
          state: this.state,
        };
        await PractitionersAPI.create(body);
        this.step = 2;
      } catch (err) {
        console.error(err);
        snackBarEventBus.$emit(snackBarEventName, {
          message: (err && err.message) || err,
          type: "error",
        });
      } finally {
        this.buttonLoading = false;
      }
      // this.buttonLoading = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.add-practitioner {
  background-color: white;

  .header {
    margin: 0 auto;

    margin-top: 72px;
    justify-content: center;
    max-width: 680px;
    @include mobile {
      margin-top: 48px;
    }

    .title-text {
      font-style: normal;
      font-weight: 500;
      font-size: 28px;
      line-height: 34px;
      text-align: center;
      color: #33343e;
      @include mobile {
        font-size: 20px;
        line-height: 24px;
      }
    }

    .subtitle-text {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      text-align: center;
      letter-spacing: 0.32px;

      &:first-of-type {
        padding-top: 15px;
      }
    }
  }

  .form {
    max-width: 464px;
    background: #ffffff;
    border: 1px solid #e7e8f2;
    border-radius: 6px;
    margin: 36px auto 0;

    .title-text {
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 24px;
      text-align: center;
      color: #33343e;
    }
  }

  .step-2 {
    .header {
      .title-text {
        font-size: 28px;
        line-height: 34px;
      }

      .subtitle-text {
        margin: 0 auto;
        max-width: 500px;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        text-align: center;
        letter-spacing: 0.32px;
      }
    }
  }
}
</style>
