<template>
  <v-row no-gutters class="past-visit-chart-review mt-4">
    <v-col lg="8" md="8" xl="8" cols="12" sm="12">
      <PractitionerEncounterMedicationOrder />
    </v-col>
    <v-col lg="4" md="4" xl="4" cols="12" sm="12">
      <PractitionerVisitDescription />
    </v-col>
    <PractitionerEncounterOrder />
    <PreviewSignedVisitNote />
  </v-row>
</template>

<script>
import PractitionerVisitDescription from "@/components/VisitDescription/Practitioner";
import PractitionerEncounterMedicationOrder from "@/components/VisitNotes/medicationOrder/PractitionerEncounterMedicationOrder";
import PractitionerEncounterOrder from "@/components/VisitNotes/medicationOrder/PractitionerEncounterOrder";
import PreviewSignedVisitNote from "@/views/Practitioner/SignedVisit/PreviewSignedVisitNote";
export default {
  name: "PastVisitEncounter",
  components: {
    PreviewSignedVisitNote,
    PractitionerEncounterOrder,
    PractitionerEncounterMedicationOrder,
    PractitionerVisitDescription,
  },
};
</script>

<style scoped lang="scss">
.past-visit-chart-review {
  /*min-height: 100%;*/
  /*max-width: 90%;*/
  /*width: 1360px;*/
}
</style>
