<template>
  <v-dialog :value="dialog" max-width="95%" width="420px" @click:outside="closeEventModal">
    <v-card :loading="localLoading" class="dialog card mx-auto px-4 pt-0">
      <v-icon
        class="dialog__head__close-icon"
        size="small"
        style="position: absolute; top: 2px; right: 10px"
        @click="closeEventModal"
        >mdi-close
      </v-icon>
      <v-skeleton-loader
        v-if="localLoading"
        :boilerplatstartTimee="true"
        class="mx-0 mt-6 full-width"
        elevation="2"
        type="list-item-avatar, divider, card-heading, list-item-three-line, list-item-three-line, list-item-two-line, actions"
      >
      </v-skeleton-loader>
      <div v-if="!localLoading && appointmentInformation" class="dialog-content-wrap">
        <v-row v-if="isPending" align="center" class="mx-0 pb-3">
          <div class="warn-status">
            <img alt="warning" class="sick-modal__icon" src="@/assets/icons/warn-sign.svg" />
            <span>
              <template v-if="Boolean(isNeedConfirmByPractitioner(appointmentInformation.participant))">
                This visit hasn't been confirmed yet
              </template>
              <template v-else>
                The visit hasn’t been confirmed by {{ needConfirmBy(appointmentInformation.participant) }} yet
              </template>
            </span>
          </div>
        </v-row>
        <v-row v-if="isCancelled" align="center" class="mx-0 pb-3">
          <div class="warn-status">
            <img alt="warning" class="sick-modal__icon" src="@/assets/icons/warn-sign.svg" />
            <span> The visit was cancelled </span>
          </div>
        </v-row>
        <v-row align="center" class="mx-0 mt-6 pb-3">
          <Avatar v-if="appointmentInformation.practitionerId" :userId="appointmentInformation.practitionerId" />
          <Avatar v-else src="@/assets/no-avatar.svg" />
          <div class="ml-2">
            <p class="text--secondary mb-0">
              {{ appointmentInformation.practitionerQualification }}
            </p>
            <h4>
              {{
                appointmentInformation.participant ? `Dr. ${appointmentInformation.participant[0].actor.display}` : ""
              }}
            </h4>
          </div>
        </v-row>
        <v-divider class="full-width my-2"></v-divider>
        <div>
          <p class="text--secondary mb-0">Patient</p>
          <v-card-title class="pa-0"
            >{{ appointmentInformation.participant ? appointmentInformation.participant[1].actor.display : "" }}
            <img
              :src="require('@/assets/icons/folder.svg')"
              alt="Patient record"
              class="ml-2 pointer"
              title="Patient record"
              @click="gotoPatient(appointmentInformation.patientId)"
            />
          </v-card-title>
          <a :href="`mailto: ${appointmentInformation.patientEmail}`" class="text-decoration-none mt-0">
            {{ appointmentInformation.patientEmail }}
          </a>
          <a
            v-if="appointmentInformation.phoneNumber"
            :href="`tel:${appointmentInformation.phoneNumber}`"
            class="text-decoration-none d-block mt-0"
          >
            {{ appointmentInformation.phoneNumber }}
          </a>
          <a
            v-if="appointmentInformation.homePhoneNumber"
            :href="`tel:${appointmentInformation.homePhoneNumber}`"
            class="text-decoration-none d-block mt-0"
          >
            {{ appointmentInformation.homePhoneNumber }}
          </a>
          <p v-if="appointmentInformation.patientBirthDate" class="birthDate">
            {{ formatDate(appointmentInformation.patientBirthDate).format("MM/DD/YYYY") }}
            <span>
              <v-icon> mdi-circle-small</v-icon>
              {{ age(appointmentInformation.patientBirthDate) }} years old</span
            >
          </p>
        </div>
        <div>
          <p class="text--secondary mt-3 mb-0">Zip Code</p>
          <p class="mt-0">{{ appointmentInformation.patientZipCode || "" }}</p>
          <p class="text--secondary mt-3 mb-0">
            {{
              `${formatDate(appointmentInformation.start).format("l")}, ${formatDate(
                appointmentInformation.start,
              ).format("dddd")}`
            }}
          </p>
          <p class="mt-0">
            {{
              `${formatDate(appointmentInformation.start).format("HH:mm")} - ${formatDate(
                appointmentInformation.end,
              ).format("HH:mm")}`
            }}
          </p>
        </div>
        <div>
          <p class="text--secondary mb-0">Chief Complaint</p>
          <p class="mt-0">
            {{ translateChiefComplaint(appointmentInformation.chiefComplaint) }}
          </p>
        </div>
        <div>
          <p class="text--secondary mb-0">Visit Reason Note</p>
          <p>{{ appointmentInformation.complaintDescription }}</p>
        </div>
        <div>
          <p class="text--secondary mb-0">Visit type</p>
          <p>{{ visitType }}</p>
        </div>
        <div v-if="appointmentInformation.pharmacy">
          <p class="text--secondary mb-0">Pharmacy</p>
          <p class="mt-0 mb-0">
            {{ appointmentInformation.pharmacy.name }}
          </p>
          <p class="mt-0">
            {{ appointmentInformation.pharmacy.address ? `${appointmentInformation.pharmacy.address},` : "" }}
            {{ appointmentInformation.pharmacy.city ? `${appointmentInformation.pharmacy.city},` : "" }}
            {{ appointmentInformation.pharmacy.state ? `${appointmentInformation.pharmacy.state} ` : "" }}
            {{ appointmentInformation.pharmacy.zipCode ? `${appointmentInformation.pharmacy.zipCode}` : "" }}
          </p>
        </div>
        <div class="footer-actions mx-0">
          <v-btn v-if="allowCancel" color="red" outlined @click="cancelVisitDialog = true">Cancel Visit</v-btn>
          <v-btn v-if="allowViewDetails" color="blue" outlined @click="viewDetails">View Details</v-btn>
          <OutlinedButton
            v-if="isNeedConfirmByPractitioner(appointmentInformation.participant)"
            additionalClassName="outlined-btn__green"
            text="Confirm"
            @onClick="acceptInvitePractitioner"
          />
        </div>
      </div>
    </v-card>
    <CancelVisit
      v-if="cancelVisitDialog"
      v-model="cancelVisitDialog"
      :appointmentId="appointmentInformation.id"
      :closeEventModal="closeEventModal"
      :practitionerId="practitionerId"
      :slotId="appointmentInformation.slotId"
      :updateCalendarInfo="updateCalendarInfo"
    />
  </v-dialog>
</template>

<script>
import moment from "moment-timezone";
import { mapState } from "pinia";

import Avatar from "@/components/Avatar/Index";
import CancelVisit from "@/components/CancelVisit";
import OutlinedButton from "@/components/uikit/OutlinedButton.vue";
import { useAppointmentsStore } from "@/pinia-store/appointments";
import { useAuthStore } from "@/pinia-store/auth";
import { AppointmentStatusEnum } from "@/types/AppointmentQueryFilter";
import { RolesEnum } from "@/types/Roles.enum";
import { routesEnum } from "@/types/Routes.enum";

export default {
  props: {
    dialog: Boolean,
    loading: Boolean,
    selectedTime: Object,
    closeEventModal: Function,
    updateCalendarInfo: Function,
    practitionerId: String,
    role: String,
  },
  name: "ModalEdit",
  data: () => ({
    isOpen: this ? this.dialog : false,
    cancelVisitDialog: false,
  }),
  computed: {
    ...mapState(useAppointmentsStore, ["appointmentInformation"]),
    ...mapState(useAuthStore, ["timeZone"]),
    visitType() {
      return this.appointmentInformation?.virtualService === "video" ? "Video visit" : "Request a callback";
    },
    allowCancel() {
      return (
        this.appointmentInformation?.status !== AppointmentStatusEnum.cancelled &&
        this.$moment(this.appointmentInformation.start).tz(this.timeZone).isAfter(this.$moment())
      );
    },
    allowViewDetails() {
      return this.appointmentInformation && this.appointmentInformation.status === "booked";
    },
    isPending() {
      return this.appointmentInformation?.status === AppointmentStatusEnum.pending;
    },
    isCancelled() {
      return this.appointmentInformation?.status === AppointmentStatusEnum.cancelled;
    },
    slotInformation() {
      if (this.role === RolesEnum.Owner) {
        return this.$store.getters["owners/slotInformation"];
      } else {
        return this.$store.getters["slots/slotInformation"];
      }
    },
    localLoading: {
      get() {
        return this.loading;
      },
      set(newValue) {
        this.$emit("update:loading", newValue);
      },
    },
  },
  components: {
    OutlinedButton,
    Avatar,
    CancelVisit,
  },
  methods: {
    async acceptInvitePractitioner() {
      const id = this.appointmentInformation?.id;
      const link = `/practitioner/encounters/schedule/${id}/cancel`;
      await this.$router.push({ path: link });
    },
    age(date) {
      return this.$moment().diff(this.$moment(date), "years");
    },
    gotoPatient(id) {
      return this.$router.push({ name: routesEnum.patientMedicalRecord, params: { patientId: id } });
    },
    formatDate(date = undefined) {
      return moment(date);
    },
    needConfirmBy(participants) {
      const tentative = (participants || []).find((participant) => participant.status === "tentative");
      if (!tentative?.actor) return "";
      return tentative.actor.reference.split("/")[0].toLowerCase();
    },
    isNeedConfirmByPractitioner(participants) {
      const tentative = (participants || []).find((participant) => participant.status === "tentative");
      if (!tentative?.actor) return false;
      return tentative.actor.reference.split("/")[0].toLowerCase() === RolesEnum.Practitioner;
    },
    viewDetails() {
      if (this.appointmentInformation && this.appointmentInformation.status === "booked") {
        this.$router.push(`/${this.role}/encounters/${this.appointmentInformation.encounterId}`);
      } else {
        this.$router.push(`/${this.role}/pending/${this.appointmentInformation.slotId}`);
      }
    },
    translateChiefComplaint(val) {
      return this.$t("general.chiefComplaints")[val] || val || "-";
    },
  },
  watch: {
    dialog(newValue) {
      if (newValue) {
        this.isOpen = newValue;
      }
    },
    loading(newValue) {
      if (newValue) {
        this.localLoading = newValue;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.v-application--is-ltr .v-alert__icon {
  margin-right: 0;
}

.dialog-content-wrap {
  width: 100%;

  .warn-status {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 8px;
    gap: 10px;
    background: linear-gradient(0deg, rgba(236, 61, 57, 0.1), rgba(236, 61, 57, 0.1)), #ffffff;
    border-radius: 4px;
    margin-top: 54px;

    span {
      font-style: normal;
      font-weight: 400;
      font-size: 11px;
      line-height: 13px;
      color: #e53935;
      opacity: 0.9;
    }
  }

  .footer-actions {
    width: 100%;
    display: flex;
    flex-basis: 100%;
    justify-content: space-between;
  }
}
</style>
