import { apiRequest } from "./apiRequest";

interface GetAllArgs {
  practitionerId: string;
  scheduleId?: string;
  status?: string;
  date?: string;
}

const getAllFiltered = async (params: GetAllArgs) => {
  const { data } = await apiRequest.get(`/slot`, { params: { ...(params || {}) } });
  return data;
};

const setOnHold = async (slotId: string) => {
  const { data } = await apiRequest.post(`/slot/${slotId}/on-hold`);
  return data;
};

const getAllOnHold = async () => {
  const { data } = await apiRequest.get(`/slot/on-hold`);
  return data;
};

const destroyOnHold = async (slotId: string) => {
  const { data } = await apiRequest.delete(`/slot/${slotId}/on-hold`);
  return data;
};
const getById = async (slotId: string) => {
  if (!slotId) return null;
  const { data } = await apiRequest.get(`/slot/${slotId}`);
  return data;
};
const getIsAvailable = async (params: {
  dateOfAppointment?: string;
  gender?: string;
  language?: string;
  uid?: string;
  practitionerId?: string;
  startDate?: string;
  endDate?: string;
  excludeRegistrars?: boolean;
}) => {
  const { dateOfAppointment, gender, language, uid, practitionerId, startDate, endDate, excludeRegistrars } = params;
  const { data } = await apiRequest.get(`/slot/available`, {
    params: {
      ...(excludeRegistrars ? { excludeRegistrars: true } : {}),
      ...(dateOfAppointment ? { startDate: dateOfAppointment } : {}),
      ...(dateOfAppointment ? { endDate: dateOfAppointment } : {}),
      ...(language ? { locale: language } : {}),
      ...(gender ? { gender: gender } : {}),
      ...(gender ? { excludeId: uid } : {}),
      ...(practitionerId ? { practitionerId } : {}),
      startDate,
      endDate,
    },
  });
  return data;
};

const getAll = async () => {
  const { data } = await apiRequest.get(`/slot`);
  return data;
};

export const SlotsApi = { getAllFiltered, getById, getAll, getAllOnHold, getIsAvailable, setOnHold, destroyOnHold };
