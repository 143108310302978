import identity from "lodash/identity";
import pickBy from "lodash/pickBy";

import { apiRequest } from "@/api/apiRequest";
import { MonitoringFilters } from "@/api/monitoring";

const segment = "service-request";

const getAll = async (query: MonitoringFilters) => {
  return new Promise((resolve, reject) => {
    apiRequest
      .get(`/${segment}`, { params: query })
      .then((resp) => {
        resolve(resp.data);
      })
      .catch(() => {
        reject([]);
      });
  });
};

const createBloodGlucose = async (body: Record<any, any> = {}) => {
  return new Promise((resolve, reject) => {
    apiRequest
      .post(`/${segment}/blood-glucose`, pickBy(body, identity))
      .then((resp) => {
        resolve(resp.data);
      })
      .catch((err) => {
        reject(err.response && err.response.data);
      });
  });
};
const createBloodPressure = async (body: Record<any, any> = {}) => {
  return new Promise((resolve, reject) => {
    apiRequest
      .post(`/${segment}/blood-pressure`, pickBy(body, identity))
      .then((resp) => {
        resolve(resp.data);
      })
      .catch((err) => {
        reject(err.response && err.response.data);
      });
  });
};

const destroyBloodPressure = async (id: string) => {
  return new Promise((resolve, reject) => {
    apiRequest
      .delete(`/${segment}/blood-pressure/${id}`)
      .then((resp) => {
        resolve(resp.data);
      })
      .catch((err) => {
        reject(err.response && err.response.data);
      });
  });
};

const destroyObservationOrder = async (id: string) => {
  return new Promise((resolve, reject) => {
    apiRequest
      .delete(`/${segment}/observation/${id}`)
      .then((resp) => {
        resolve(resp.data);
      })
      .catch((err) => {
        reject(err.response && err.response.data);
      });
  });
};
const createTemperature = async (body: Record<any, any> = {}) => {
  return new Promise((resolve, reject) => {
    apiRequest
      .post(`/${segment}/body-temperature`, body)
      .then((resp) => {
        resolve(resp.data);
      })
      .catch((err) => {
        reject(err.response && err.response.data);
      });
  });
};
const createOxygenSaturation = async (body: Record<any, any> = {}) => {
  return new Promise((resolve, reject) => {
    apiRequest
      .post(`/${segment}/oxygen-saturation`, body)
      .then((resp) => {
        resolve(resp.data);
      })
      .catch((err) => {
        reject(err.response && err.response.data);
      });
  });
};
const createRespiratoryRate = async (body: Record<any, any> = {}) => {
  return new Promise((resolve, reject) => {
    apiRequest
      .post(`/${segment}/respiratory-rate`, body)
      .then((resp) => {
        resolve(resp.data);
      })
      .catch((err) => {
        reject(err.response && err.response.data);
      });
  });
};
const createHeartRate = async (body: Record<any, any> = {}) => {
  return new Promise((resolve, reject) => {
    apiRequest
      .post(`/${segment}/heart-rate`, body)
      .then((resp) => {
        resolve(resp.data);
      })
      .catch((err) => {
        reject(err.response && err.response.data);
      });
  });
};
const getPatientObservationOrders = async (query: MonitoringFilters) => {
  return new Promise((resolve, reject) => {
    apiRequest
      .get(`/${segment}/patient/observations`, { params: query })
      .then((resp) => {
        resolve(resp.data);
      })
      .catch(() => {
        reject([]);
      });
  });
};

const destroy = async (allergyId: string, patientId: string) => {
  const { data } = await apiRequest.delete(`/${segment}/${patientId}/conditions/${allergyId}`);
  return data;
};
const getPatientBloodPressureOrder = async (patientId: string) => {
  const { data } = await apiRequest
    .get(`/${segment}/patient/blood-pressure`, { params: { patientId } })
    .then((r) => r)
    .catch(() => {
      return { data: {} };
    });
  return data;
};

const getPatientBloodGlucoseOrder = async (patientId: string) => {
  const { data } = await apiRequest.get(`/${segment}/patient/blood-glucose`, { params: { patientId } });
  return data;
};

const getPatientHeartRateOrder = async (patientId: string) => {
  const { data } = await apiRequest.get(`/${segment}/patient/heart-rate`, { params: { patientId } });
  return data;
};

const getPatientOxygenSaturationOrder = async (patientId: string) => {
  const { data } = await apiRequest.get(`/${segment}/patient/oxygen-saturation`, { params: { patientId } });
  return data;
};

const getPatientBodyTemperatureOrder = async (patientId: string) => {
  const { data } = await apiRequest.get(`/${segment}/patient/body-temperature`, { params: { patientId } });
  return data;
};

const getPatientRespiratoryRateOrder = async (patientId: string) => {
  const { data } = await apiRequest.get(`/${segment}/patient/respiratory-rate`, { params: { patientId } });
  return data;
};
const getSmokingBehavior = async () => {
  const { data } = await apiRequest.get(`/${segment}/patient/smoking-behavior`);
  return data;
};
const getPatientConditions = async (patientId: string) => {
  const { data } = await apiRequest.get(`/${segment}/${patientId}/conditions`);
  return data;
};

export const ServiceRequestApi = {
  getAll,
  createHeartRate,
  createBloodPressure,
  destroyBloodPressure,
  getPatientBloodGlucoseOrder,
  getPatientRespiratoryRateOrder,
  getPatientBodyTemperatureOrder,
  getPatientObservationOrders,
  getPatientOxygenSaturationOrder,
  createRespiratoryRate,
  createOxygenSaturation,
  createBloodGlucose,
  createTemperature,
  getPatientHeartRateOrder,
  getPatientConditions,
  getBloodPressureOrder: getPatientBloodPressureOrder,
  getPatientBloodPressureOrder,
  getSmokingBehavior,
  destroy,
  destroyObservationOrder,
};
