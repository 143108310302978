import { mapActions, mapState } from "pinia";

import { useAppointmentsStore } from "@/pinia-store/appointments";
import { useAuthStore } from "@/pinia-store/auth";
import { useEncountersStore } from "@/pinia-store/encounters";
import { useRegistrarsStore } from "@/pinia-store/registrars";
import { RolesEnum } from "@/types/Roles.enum";

export const archiveListMixin = {
  name: "AppointmentsArchive",
  data: () => ({
    loading: false,
  }),
  computed: {
    ...mapState(useAuthStore, ["role", "uid"]),
    ...mapState(useEncountersStore, ["encounters", "pastEncounters"]),
    ...mapState(useRegistrarsStore, ["registrarPractitioners"]),
    ...mapState(useAppointmentsStore, ["pendingAppointments", "pastAppointments", "upcomingAppointment"]),
    archiveItems() {
      return this.pastAppointments.concat().sort((a, b) => {
        return new Date(b.start) - new Date(a.start);
      });
    },
  },
  methods: {
    ...mapActions(useEncountersStore, ["getAllEncounters"]),
    ...mapActions(useRegistrarsStore, ["getRegistrarPractitioners"]),
    ...mapActions(useAppointmentsStore, ["getPendingAppointments", "getPastAppointments"]),
    joinRoom(encounterId) {
      this.$router.push(`/${this.role}/encounters/${encounterId}`);
    },
  },
  async mounted() {
    if (this.role === RolesEnum.Registrar) {
      await this.getRegistrarPractitioners(this.uid);
    }
  },
};
