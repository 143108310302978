<template>
  <v-btn v-if="isVisible" :height="btnHeight" :max-height="btnHeight" color="success" elevation="0" @click="onCall">
    <v-icon dark left> mdi-phone-outline</v-icon>
    Call now
  </v-btn>
</template>

<script>
import { mapActions } from "pinia";

import { useVoipStore, voipDialogType } from "@/pinia-store/voip";

export default {
  name: "CallNowAction",
  props: {
    phone: { default: "", type: String },
    btnHeight: {
      type: Number,
      default: 36,
    },
  },
  computed: {
    isVisible() {
      return this.phone?.length > 0;
    },
  },
  methods: {
    ...mapActions(useVoipStore, ["setDialog", "changePhone", "changeState"]),
    onCall() {
      this.changePhone(this.phone);
      this.setDialog(voipDialogType.dialog);
    },
  },
};
</script>

<style scoped></style>
