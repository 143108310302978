<template>
  <div class="d-schedule-visit">
    <div class="d-schedule-visit__content">
      <Stepper :isPrevStepRequired="true" :step="step" :stepChange="nextStep" :texts="steps" />
      <v-progress-linear v-if="loading" color="primary" indeterminate />
      <div v-else>
        <v-card v-if="step === 1" class="d-schedule-visit__content__card" max-width="464px" outlined>
          <PractitionerScheduleVisitForm :buttonLoading="buttonLoading" @submit="nextStep" />
        </v-card>
        <CompleteVisitSchedule v-if="step === 2" />
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapState } from "pinia";

import Stepper from "@/components/shared/Stepper";
import { snackBarEventBus, snackBarEventName } from "@/eventBuses/snackBar.eventBus";
import { useAuthStore } from "@/pinia-store/auth";
import { useCheckoutStore } from "@/pinia-store/checkout";
import { usePatientStore } from "@/pinia-store/patient";
import { usePractitionersStore } from "@/pinia-store/practitioners";
import { usePractitionerVisitScheduleStore } from "@/pinia-store/practitionerVisitSchedule";
import { useSlotsStore } from "@/pinia-store/slots";
import CompleteVisitSchedule from "@/views/Practitioner/Appoinments/CompleteVisitSchedule/Index.vue";
import PractitionerScheduleVisitForm from "@/views/Practitioner/Appoinments/ScheduleVisitForm/Index";

export default {
  name: "PractitionerScheduleVisitView",
  components: {
    CompleteVisitSchedule,
    PractitionerScheduleVisitForm,
    Stepper,
  },
  data: () => ({
    loading: false,
    buttonLoading: false,
    step: 1,
    mode: "create",
  }),
  computed: {
    ...mapState(useAuthStore, ["uid"]),
    ...mapState(useCheckoutStore, ["patientId", "slotId"]),
    steps() {
      return this.$i18n.t("visits.schedule.practitionerSteps");
    },
  },
  methods: {
    ...mapActions(useSlotsStore, ["getSlotById", "getPractitionerFreeSlots"]),
    ...mapActions(usePractitionerVisitScheduleStore, ["cleanUp"]),
    ...mapActions(usePatientStore, ["getPatientProfile"]),
    ...mapActions(useCheckoutStore, ["setStringFieldByName"]),
    ...mapActions(useCheckoutStore, ["practitionerRequestVisit"]),
    ...mapActions(usePractitionersStore, ["getPractitionerById"]),
    async submit() {
      this.buttonLoading = true;
      try {
        await this.getPatientProfile(this.patientId);
        await this.getSlotById(this.slotId);
        this.step = 2;
      } catch (err) {
        console.error(err);
        if (err && err.response && err.response.status === 409) {
          snackBarEventBus.$emit(snackBarEventName, {
            message: this.$t("general.errors.slotConflict"),
            type: "error",
          });
        }
        snackBarEventBus.$emit(snackBarEventName, {
          message: (err && err.response && err.response.data && err.response.data.message) || err,
          type: "error",
        });
      }
      this.buttonLoading = false;
    },
    nextStep(nextStep) {
      this.step = nextStep;
    },
    async slotConflict() {
      this.step = 1;
      await this.getPractitionerFreeSlots({ status: "free" });
    },
  },
  beforeDestroy() {
    this.cleanUp();
  },
  async mounted() {
    await this.setStringFieldByName({ value: this.uid, fieldName: "practitionerId" });

    if (location.href.slice(-1) == 2) {
      this.step = 2;
    }
    try {
      await this.getPractitionerById(this.uid);
    } catch (err) {
      console.error(err);
    } finally {
      this.loading = false;
    }
  },
};
</script>
