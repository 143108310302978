<template>
  <v-expansion-panels v-model="panel" accordion class="encounter-visit-history" flat>
    <v-expansion-panel>
      <v-expansion-panel-header>
        <span class="visit-notes_card_header_title">
          <img alt="" class="title-icon" src="@/assets/heart.svg" />
          Previous visits
        </span>
      </v-expansion-panel-header>
      <v-expansion-panel-content class="section-two">
        <v-timeline :dense="true" class="timeline-root">
          <v-timeline-item v-for="item in encounters" :key="item.id" large>
            <template v-slot:icon>
              <v-avatar height="52" width="52">
                <img :src="item.practitioner.photoURL" />
              </v-avatar>
            </template>
            <div class="text-h5">
              <span class="date">{{ startDate(item.start) }}</span>
              <span class="with">
                <v-icon large>mdi-circle-small</v-icon>
                Visit with</span
              >
              <span class="doctor"> {{ (item.practitioner && item.practitioner.name) || "" }}</span>
            </div>
            <div class="reason">
              {{ translateChiefComplaint(item.chiefComplaint) }}
            </div>
          </v-timeline-item>
        </v-timeline>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>
<script>
import moment from "moment-timezone";
import { mapActions, mapState } from "pinia";

import { useEncountersStore } from "@/pinia-store/encounters";
import { usePastVisitStore } from "@/pinia-store/pastVisit";
import { useVisitNoteStore } from "@/pinia-store/visitNote";
import { tableHeaders } from "@/utils/tableHeaders.ts";

export default {
  name: "PractitionerEncounterVisitHistory",
  components: {},
  data: () => ({
    ...tableHeaders(),
    fileRecords: [],
    medicalTab: 0,
    panel: 1,
    fileRecordsForUpload: [],
  }),
  watch: {
    // todo: change if used
    "$store.state.visitNote.patientId": {
      immediate: true,
      async handler(val) {
        if (val) {
          await this.getOtherVisitNotes();
        }
      },
    },
  },
  computed: {
    ...mapActions(useEncountersStore, ["encounters", "pagination"]),
    ...mapState(useVisitNoteStore, ["medicalInfo", "patientId", "encounterId", "otherVisitNotes"]),
    ...mapState(usePastVisitStore, ["getFile"]),
  },
  methods: {
    ...mapActions(useVisitNoteStore, ["setMedicalInfoField"]),
    ...mapActions(useVisitNoteStore, ["clearMedicalInfoFields", "autoSave", "getOtherVisitNotes"]),
    ...mapActions(useEncountersStore, ["getAllEncounters"]),
    ...mapActions(usePastVisitStore, ["setFile"]),
    startDate(date) {
      return moment(date).format("DD MMM YYYY");
    },

    translateChiefComplaint(val) {
      return this.$t("general.chiefComplaints")[val] || val || "-";
    },
  },
};
</script>
<style lang="scss" scoped>
.encounter-visit-history {
  //:deep .v-expansion-panel-header {
  //  padding: 32px;
  //}

  //:deep .v-expansion-panel-header__icon {
  //  margin-right: 16px;
  //}

  :deep .v-expansion-panel-content__wrap {
    padding: 0;
  }
}

.timeline-root {
  padding-top: 0;
  margin-top: 20px;

  .v-timeline-item:last-child {
    padding-bottom: 0;
    margin-bottom: 20px;
  }

  .date {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #33343e;
    opacity: 0.9;
  }

  .with,
  .reason {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #25233a;
    mix-blend-mode: normal;
    opacity: 0.5;
  }

  .doctor {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #25233a;
  }
}
</style>
