<template>
  <div class="mt-10">
    <v-tabs v-model="tab" :show-arrows="true" active-class="tabs_tab_active" class="tabs" color="primary">
      <v-tab class="tabs_tab" @click="getListEncounters"> {{ $t("visits.upcoming_short") }}</v-tab>
      <v-tab class="tabs_tab" @click="getPendingCheckoutsList"> {{ $t("owner.clinician.table.pending") }}</v-tab>
      <v-tab class="tabs_tab" @click="getArchiveListEncounters"> Past</v-tab>
      <v-tab-item class="patients-list_tabs_tab_item">
        <Upcoming :isLoading="loading" @toSchedule="redirectToSchedule" />
      </v-tab-item>
      <v-tab-item class="patients-list_tabs_tab_item">
        <Pending :isLoading="loading" @toSchedule="redirectToSchedule" />
      </v-tab-item>
      <v-tab-item class="patients-list_tabs_tab_item">
        <Past :isLoading="loading" @toSchedule="redirectToSchedule" @update:isLoading="loading = $event" />
      </v-tab-item>
    </v-tabs>
  </div>
</template>

<script>
import moment from "moment-timezone";
import { mapActions, mapState } from "pinia";

import Past from "@/components/ScheduledAppointments/Past";
import Pending from "@/components/ScheduledAppointments/Pending";
import Upcoming from "@/components/ScheduledAppointments/Upcoming";
import { useAdminStore } from "@/pinia-store/admin";
import { useAuthStore } from "@/pinia-store/auth";
import { useEncountersStore } from "@/pinia-store/encounters";
import { usePatientsStore } from "@/pinia-store/patients";
import { usePractitionersStore } from "@/pinia-store/practitioners";
import { useRegistrarsStore } from "@/pinia-store/registrars";

export default {
  name: "ScheduledAppointments",
  components: {
    Upcoming,
    Past,
    Pending,
  },
  data() {
    return {
      tab: null,
      practitionersFullNames: [],
      loading: false,
    };
  },
  computed: {
    ...mapState(usePractitionersStore, ["practitionersName"]),
    ...mapState(useAuthStore, ["uid"]),
    ...mapState(useAuthStore, ["role"]),
  },
  methods: {
    ...mapActions(useAdminStore, ["getPractitioners", "getPendingCheckouts"]),
    ...mapActions(usePatientsStore, ["getAllPatients"]),
    ...mapActions(usePractitionersStore, ["getAllPractitioners"]),
    ...mapActions(useRegistrarsStore, ["getRegistrarPractitioners"]),
    ...mapActions(useEncountersStore, ["getAllEncounters"]),
    async getArchiveListEncounters() {
      this.loading = true;
      try {
        await this.getAllEncounters();
      } catch (e) {
        console.error(e);
      }
      this.loading = false;
    },
    async getListEncounters() {
      this.loading = true;
      try {
        await this.getAllEncounters({ future: true });
      } catch (e) {
        console.error(e);
      }
      this.loading = false;
    },
    async getPendingCheckoutsList() {
      const date = moment().startOf("isoWeek").weekday(1).format("YYYY-MM-DD");
      this.loading = true;
      try {
        await this.getPendingCheckouts({ date });
      } catch (e) {
        console.error(e);
      }
      this.loading = false;
    },
    async redirectToSchedule() {
      await this.$router.push({ path: `/${this.role}/encounters/schedule` });
    },
  },
  async mounted() {
    if (this.role === "registrar") {
      await this.getRegistrarPractitioners(this.uid);
    } else {
      await Promise.all([this.getAllPractitioners(), this.getListEncounters()]);
    }
    await this.getAllPatients();
  },
};
</script>

<style scoped></style>
