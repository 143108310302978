import { RouteConfig } from "vue-router/types/router";

import ScheduleView from "@/components/Checkout/OwnerScheduleVisit/index.vue";
import { routesEnum } from "@/types/Routes.enum";
import EditAvailability from "@/views/Admin/Appoinments/EditAvailability.vue";
import ScheduleAppointment from "@/views/Admin/Appoinments/Schedule.vue";
import ScheduledAppointments from "@/views/Admin/Appoinments/ScheduledAppointments.vue";
import AppointmentsView from "@/views/Admin/Appoinments/View.vue";
import Clinician from "@/views/Admin/Clinician.vue";
import Patients from "@/views/Admin/Patients.vue";
import AddPractitioner from "@/views/Admin/Practitioners/Index.vue";
import AdminSettings from "@/views/Admin/Settings.vue";
import Templates from "@/views/Admin/Templates/View.vue";
import Admin from "@/views/Admin/View.vue";
import Schedule from "@/views/Admin/Visits/Request.vue";
import VisitNotes from "@/views/Admin/Visits/VisitDetails.vue";
import MiddleComponent from "@/views/MiddleComponent.vue";
import PractitionerChat from "@/views/Owner/Chat/Index.vue";

import {
  appointmentsBreadcrumbs,
  chatBreadcrumbs,
  clinicianListBreadcrumbs,
  EditAvailabilityBreadcrumbs,
  ifAuthenticated,
  ownerScheduleVisitBreadcrumbs,
  patientListBreadcrumbs,
  practitionerScheduleVisitBreadcrumbs,
  profileSettingsBreadcrumbs,
  scheduledAppointmentsBreadcrumbs,
  templateBreadcrumbs,
} from "./utils";

const AdminRoutes: RouteConfig[] = [
  {
    path: "/admin",
    component: Admin,
    meta: {
      authorize: ["admin"],
    },
    redirect: "/admin/appointments",
    beforeEnter: ifAuthenticated,
    children: [
      {
        path: "templates",
        name: routesEnum.adminTemplates,
        component: Templates,
        meta: { breadcrumbs: templateBreadcrumbs },
      },
      {
        path: "chat",
        name: routesEnum.adminOwnerChat,
        meta: { breadcrumbs: chatBreadcrumbs },
        component: PractitionerChat,
      },
      {
        path: "settings",
        name: routesEnum.adminSettings,
        component: AdminSettings,
        meta: { breadcrumbs: profileSettingsBreadcrumbs },
      },
      {
        path: "patients",
        name: routesEnum.adminOwnerPatients,
        component: Patients,
        meta: { breadcrumbs: patientListBreadcrumbs },
      },
      {
        path: "practitioners",
        name: routesEnum.adminPractitioners,
        component: AddPractitioner,
        meta: { breadcrumbs: templateBreadcrumbs },
      },
      {
        path: "clinicians",
        name: routesEnum.adminOwnerClinicians,
        component: Clinician,
        meta: { breadcrumbs: clinicianListBreadcrumbs },
      },
      {
        path: "appointments",
        component: AppointmentsView,
        children: [
          {
            path: "/",
            name: routesEnum.adminOwnerSchedule,
            component: ScheduleAppointment,
            meta: { breadcrumbs: appointmentsBreadcrumbs, layout: "default" },
          },
          {
            path: "/admin/appointments/scheduled",
            name: routesEnum.adminOwnerScheduledAppointments,
            component: ScheduledAppointments,
            meta: { breadcrumbs: scheduledAppointmentsBreadcrumbs, layout: "default" },
          },
          {
            path: "edit",
            name: routesEnum.adminOwnerEditAvailability,
            component: EditAvailability,
            meta: { breadcrumbs: EditAvailabilityBreadcrumbs, layout: "edit" },
          },
        ],
      },
      {
        path: "makeSchedule",
        name: routesEnum.adminOwnerScheduleVisit,
        component: ScheduleView,
        meta: { breadcrumbs: practitionerScheduleVisitBreadcrumbs },
      },
      {
        path: "encounters",
        component: MiddleComponent,
        children: [
          {
            path: "",
            component: MiddleComponent,
            redirect: "/admin/appointments",
          },
          {
            path: "schedule",
            name: routesEnum.adminOwnerEncounterSchedule,
            component: Schedule,
            meta: { breadcrumbs: ownerScheduleVisitBreadcrumbs },
          },
          {
            name: routesEnum.adminOwnerEncounter,
            path: ":encounterId",
            component: VisitNotes,
            meta: { collapseSidebar: true },
          },
        ],
      },
      {
        name: routesEnum.adminOwnerPending,
        path: "pending/:slotId",
        component: VisitNotes,
        meta: { collapseSidebar: true },
      },
    ],
  },
];
export default AdminRoutes;
