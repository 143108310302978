export type WeekStartDay = "Monday" | "Sunday";

export interface WeekDays {
  _1: string; // Mon
  _2: string; // Tue
  _3: string; // Wed
  _4: string; // Thu
  _5: string; // Fri
  _6: string; // Sat
  _7: string; // Sun
}

/**
 * Convert localization 'dayNames' object into localized array of week days.
 * @param dayNames
 * @param startDay
 */
export const convertDayNames = (dayNames: WeekDays, startDay: WeekStartDay): string[] =>
  Object.keys(dayNames)
    .sort((a, b) => {
      const aVal = parseInt(a.replace("_", ""));
      const bVal = parseInt(b.replace("_", ""));
      const aWeight = startDay === "Sunday" && aVal === 7 ? 0 : aVal;
      const bWeight = startDay === "Sunday" && bVal === 7 ? 0 : bVal;
      return aWeight - bWeight;
    })
    .map((key) => dayNames[key as keyof WeekDays]);

/**
 * "%keywordToReplace% some random text"
 */
export const templateString = (text: string, data: Record<string, any>) => {
  return text.replace(/%(\w*)%/g, (m, key) => {
    return Object.prototype.hasOwnProperty.call(data, key) ? data[key] : "";
  });
};
