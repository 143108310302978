<template>
  <v-row>
    <v-col cols="12">
      <v-progress-linear v-if="loadingPrivateNotes" color="primary" indeterminate></v-progress-linear>
      <PrivateNotes @openAddNoteDialog="openEditNoteDialog" />
    </v-col>
    <AddNote
      :dialog="addNoteDialog"
      :editId="editId"
      :mode="noteMode"
      @close="closeAddNoteDialog"
      @save="reloadNotes"
    />
  </v-row>
</template>

<script>
import { mapActions, mapState } from "pinia";

import AddNote from "@/components/MedicalRecord/PrivateNotes/AddNote";
import PrivateNotes from "@/components/MedicalRecord/PrivateNotes/Index";
import { useAuthStore } from "@/pinia-store/auth";
import { useMedicalRecordStore } from "@/pinia-store/medicalRecord";

export default {
  name: "MedicalRecordPatientPrivateNotes",
  components: {
    PrivateNotes,
    AddNote,
  },
  data() {
    return {
      noteMode: "",
      addNoteDialog: false,
      editId: null,
    };
  },
  computed: {
    ...mapState(useAuthStore, ["uid"]),
    ...mapState(useMedicalRecordStore, ["loadingPrivateNotes"]),
  },
  methods: {
    ...mapActions(useMedicalRecordStore, ["getPrivateNotes"]),
    openEditNoteDialog(id) {
      this.noteMode = "edit";
      this.editId = id;
      this.addNoteDialog = true;
    },
    closeAddNoteDialog() {
      this.addNoteDialog = false;
    },
    async reloadNotes() {
      const { patientId } = this.$route.params;
      await this.getPrivateNotes({ patientId, practitionerId: this.uid });
      if (this.noteMode === "edit") {
        this.noteMode = "";
        this.editId = null;
      }
      this.closeAddNoteDialog();
    },
  },
  async mounted() {
    await this.reloadNotes();
  },
};
</script>

<style scoped></style>
