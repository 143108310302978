<template>
  <Confirm
    :dialog="dialog"
    :hide-description="true"
    :loading="loading"
    hide-cancel
    hide-confirm
    max-width="95%"
    rootClass="schedule-practitioner-dialog"
    text="Visit details"
    title="Schedule a visit"
    width="480px"
    @toggleDialog="closeDialog"
  >
    <Form v-if="dialog">
      <template v-slot:head>
        <v-progress-linear color="#2FCF6F" value="30" />
        <div class="mb-3"></div>
      </template>
      <template v-slot:footer>
        <PrimaryButton
          :disabled="!isContinueButtonEnabled"
          :fullWidth="true"
          :loading="buttonLoading"
          :text="$t(`visits.schedule.second.continue`)"
          className="primary-btn__blue dialog__footer__btn"
          size="large"
          @onClick="submit"
        />
      </template>
    </Form>
  </Confirm>
</template>

<script>
import { mapActions, mapState } from "pinia";

import Confirm from "@/components/shared/Confirm.vue";
import PrimaryButton from "@/components/uikit/PrimaryButton";
import { useAppointmentsStore } from "@/pinia-store/appointments";
import { useAuthStore } from "@/pinia-store/auth";
import { usePatientStore } from "@/pinia-store/patient";
import { usePatientsStore } from "@/pinia-store/patients";
import { usePractitionerVisitScheduleStore } from "@/pinia-store/practitionerVisitSchedule";
import { useProfileSettingsStore } from "@/pinia-store/profileSettings";
import { useSlotsStore } from "@/pinia-store/slots";
import Form from "@/views/Practitioner/Appoinments/ScheduleVisitForm/Form.vue";

export default {
  name: "ScheduleDialog",
  props: {
    dialog: Boolean,
    onCancelVisit: Function,
  },
  components: {
    Form,
    Confirm,
    PrimaryButton,
  },
  data() {
    return {
      visitSettings: {},
      step: 1,
      datepickerOpen: false,
      timeslotsOpen: false,
      buttonLoading: false,
      loading: false,
    };
  },
  computed: {
    ...mapState(useAuthStore, ["uid"]),
    ...mapState(usePatientsStore, ["practitionerOwnPatients"]),
    ...mapState(useProfileSettingsStore, ["locale"]),
    ...mapState(useAppointmentsStore, ["slotId", "complaintDescription", "chiefComplaint", "visitDate", "patientId"]),
    ...mapState(useSlotsStore, ["slotsArray", "freeSlots"]),
    ...mapState(usePractitionerVisitScheduleStore, ["isContinueButtonEnabled"]),
  },
  methods: {
    ...mapActions(useAppointmentsStore, ["setStringFieldByName"]),
    ...mapActions(usePatientsStore, ["getAllByPractitionerId"]),
    ...mapActions(useSlotsStore, ["getPractitionerFreeSlots", "getSlotById"]),
    ...mapActions(usePatientStore, ["getPatientProfile"]),
    ...mapActions(usePractitionerVisitScheduleStore, ["cleanUp"]),
    closeDialog() {
      this.$emit("on-close");
      this.cleanUp();
      this.step = 1;
    },

    async submit() {
      this.buttonLoading = true;
      this.$router.push("/practitioner/encounters/schedule?step=2");
    },
    onDone() {
      this.closeDialog();
    },
  },
};
</script>

<style lang="scss">
.schedule-practitioner-dialog {
  padding: 14px 28px !important;

  .schedule-visit-form {
    padding: 0 !important;
  }
}

.dialog {
  display: flex !important;
  flex-direction: column;
  align-items: flex-start;
  padding: 2rem;
  position: relative;

  img {
    display: inline-block;
  }

  &__head {
    display: flex;
    justify-content: space-between;
    width: 100%;
    position: relative;

    &__title {
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 24px;
      text-align: center;
      word-break: break-word !important;
      padding: 0;
    }

    &__close-icon {
      top: -12px;
      right: -12px;
      padding: 12px;
    }
  }

  &__stepper {
    width: 100%;
    border-bottom: none;
    height: 40px;
  }

  &__details {
    background-color: $primarylight1;
    border-radius: 4px;
    padding: 10px;
    margin-top: 24px;
    width: 100%;
    position: relative;

    & > div {
      margin: 0;
    }

    &__label {
      margin: 0 10px;
      font-size: 16px;
      color: $primaryblack2;
      opacity: 0.6;
    }

    &__text {
      font-size: 16px;
      color: $primaryblack2;
    }

    &__date-picker {
      position: absolute;
      z-index: 5;
      left: 50%;
      top: 35px;
      transform: translateX(-50%);
      box-shadow: 0px 18px 24px rgba(0, 0, 0, 0.14);
    }

    &__select-block {
      position: relative;
    }

    &__select {
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      opacity: 0;
    }
  }

  &__select {
    width: 100%;
    font-size: 16px !important;
  }

  &__footer {
    width: 100%;
    margin-top: 24px;
    position: relative;

    &:before {
      content: "";
      position: absolute;
      left: -28px;
      top: 0;
      width: calc(100% + 56px);
      height: 1px;
      background-color: #d2d3e1;
    }

    &__btn {
      margin-top: 24px;
    }
  }
}
</style>
