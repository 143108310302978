<template>
  <div>
    <v-expansion-panels v-model="panel" accordion class="elevation-0 allergies" flat>
      <v-expansion-panel>
        <v-expansion-panel-header> Past Medical History</v-expansion-panel-header>
        <v-divider class="full-width"></v-divider>
        <v-expansion-panel-content class="">
          <v-skeleton-loader
            v-if="isLoadingPatientConditions"
            :boilerplatstartTimee="true"
            class="mx-0 full-width"
            type="list-item-two-line"
          ></v-skeleton-loader>
          <div v-else class="pt-5 pb-5 body-wrap">
            <template v-if="patientConditions.length">
              <div v-for="item in patientConditions" :key="item.id" class="name">
                {{ item.name }}
              </div>
            </template>
            <div v-else>Not available</div>
          </div>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
import { mapState } from "pinia";

import { useConditionsStore } from "@/pinia-store/conditions";

export default {
  name: "PatientSummaryPastMedicalInfo",
  data() {
    return {
      panel: 0,
    };
  },
  computed: {
    ...mapState(useConditionsStore, ["patientConditions", "isLoadingPatientConditions"]),
  },
};
</script>

<style lang="scss" scoped>
.allergies {
  .body-wrap {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;

    .name {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      align-items: center;
      margin-bottom: 12px;
      color: #33343e;
      background: #eeeff7;
      border-radius: 4px;
      padding: 6px;
    }
  }
}
</style>
