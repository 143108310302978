<template>
  <Form>
    <template v-slot:footer>
      <PrimaryButton
        :disabled="!isContinueButtonEnabled"
        :fullWidth="true"
        :loading="buttonLoading"
        className="primary-btn__blue mt17"
        size="large"
        text="Continue"
        @onClick="onSubmit"
      />
    </template>
  </Form>
</template>
<script>
import { mapState } from "pinia";

import PrimaryButton from "@/components/uikit/PrimaryButton.vue";
import { usePractitionerVisitScheduleStore } from "@/pinia-store/practitionerVisitSchedule";

import Form from "./Form";

export default {
  name: "PractitionerScheduleVisitForm",
  props: {
    mode: String,
    buttonLoading: { default: false, type: Boolean },
  },
  data: () => ({
    specialty: [
      {
        code: "408443003",
        display: "Credentials",
      },
    ],
    languageValue: "",
  }),
  components: {
    PrimaryButton,
    Form,
  },
  computed: {
    ...mapState(usePractitionerVisitScheduleStore, ["isContinueButtonEnabled"]),
  },
  methods: {
    onSubmit() {
      this.$emit("submit", 2);
    },
  },
  created() {
    window.scrollTo({ top: 0, behavior: "auto" });
  },
};
</script>
