<template>
  <div>
    <v-tabs :value="selectedTab" active-class="tabs_tab_active" class="tabs" color="primary" @change="onTabChange">
      <v-tab class="tabs_tab"> Default hours</v-tab>
      <v-tab class="tabs_tab"> Custom hours</v-tab>
    </v-tabs>
    <div class="pa-5" style="background: #fafafa">
      <DefaultHours v-if="selectedTab === 0" />
      <CustomHours v-if="selectedTab === 1" />
    </div>
    <Confirm
      :cancelText="$t('general.dialog.cancel')"
      :confirmText="$t('practitioner.appointments.leave.button')"
      :dialog="dialog"
      :text="$t('practitioner.appointments.leave.text')"
      :title="$t('practitioner.appointments.leave.title')"
      @confirm="onConfirmDialog"
      @toggleDialog="onToggleDialog"
    />
  </div>
</template>
<script>
import { mapActions, mapState } from "pinia";

import CustomHours from "@/components/EditAvailability/CustomHours";
import DefaultHours from "@/components/EditAvailability/DefaultHours";
import Confirm from "@/components/shared/Confirm";
import { snackBarEventBus, snackBarEventName } from "@/eventBuses/snackBar.eventBus";
import { useSlotsStore } from "@/pinia-store/slots";

export default {
  name: "EditAvailability",
  components: {
    Confirm,
    CustomHours,
    DefaultHours,
  },
  data: () => ({
    redirectPath: "",
    dialog: false,
    selectedTab: 0,
  }),
  computed: {
    ...mapState(useSlotsStore, [
      "availabilities",
      "initialAvailabilities",
      "customAvailabilities",
      "customInitialAvailabilities",
    ]),
  },
  methods: {
    ...mapActions(useSlotsStore, ["getPractitionerAvailability"]),

    async onConfirmDialog() {
      await this.$router.push({ path: this.redirectPath });
    },
    onTabChange(event) {
      this.selectedTab = event;
    },
    onToggleDialog() {
      this.redirectPath = "";
      this.dialog = false;
    },
  },
  async mounted() {
    try {
      await this.getPractitionerAvailability();
    } catch (err) {
      console.error(err);
      snackBarEventBus.$emit(snackBarEventName, { message: err.message || err, type: "error" });
    }
  },
  // beforeRouteLeave(to, from, next) {
  //   const diffAvailabilities = isEqual(this.availabilities, this.initialAvailabilities);
  //   const customAvailabilities = cleanShortId(this.customAvailabilities);
  //   const diffCustomAvailabilities = isEqual(customAvailabilities, this.customInitialAvailabilities);
  //   if (!this.redirectPath && (!diffAvailabilities || !diffCustomAvailabilities)) {
  //     this.redirectPath = to.path;
  //     this.dialog = true;
  //     return;
  //   }
  //   next();
  // },
};
</script>
