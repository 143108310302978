<template>
  <v-tooltip color="white" v-model="localIsOpen" right class="password-helper_tooltip">
    <template v-slot:activator="{}">
      <slot />
    </template>
    <div class="password-helper">
      <div class="password-helper_pointer"></div>
      <div class="password-helper_strengths">
        <span class="password-helper_strengths__title">{{ this.$t("settings.changePassword.hint.strength") }}:</span>
        <div
          class="password-helper_chip"
          :style="{ background: chip.background }"
          v-for="(chip, idx) in strength.chips"
          :key="idx"
        ></div>
        <span :style="{ color: strength.background }" class="password-helper_strengths__text">{{
          this.determineStrengthText(strength.id)
        }}</span>
      </div>
      <div v-for="item in passwordChecks" :key="item.text">
        <div class="password-helper_checks_item">
          <v-icon x-small class="password-helper_checks_item_icon" v-if="item.value" color="success">mdi-check</v-icon>
          <v-icon x-small class="password-helper_checks_item_icon" v-else color="error">mdi-close</v-icon>
          <span
            :class="{
              'password-helper_checks_item_text': true,
              'password-helper_checks_item_text_completed': item.value,
            }"
            >{{ item.text }}</span
          >
        </div>
      </div>
    </div>
  </v-tooltip>
</template>
<script>
const initialStrengths = [
  {
    background: "#f2c94c",
  },
  {
    background: "#e7e8f2",
  },
  {
    background: "#e7e8f2",
  },
];
export default {
  name: "PatientSignUpFirstStepForm",
  props: {
    isOpen: Boolean,
    passwordRootChecks: { default: () => [], type: Array },
    password: String,
  },
  computed: {
    strengthTexts() {
      return {
        weak: this.$t("settings.changePassword.hint.weak"),
        medium: this.$t("settings.changePassword.hint.medium"),
        strong: this.$t("settings.changePassword.hint.strong"),
      };
    },
    strength() {
      const filtered = this.passwordChecks.filter((pc) => pc.value);
      if (filtered.length === 3) {
        return {
          id: "strong",
          background: "#2fcf6f",
          chips: initialStrengths.map((s) => ({
            background: "#2fcf6f",
          })),
        };
      }
      if (filtered.length === 2 && filtered.find((i) => i.id === "characters")) {
        return {
          id: "medium",
          background: "#f2994a",
          chips: initialStrengths.map((s, idx, arr) => (idx === arr.length - 1 ? s : { background: "#f2994a" })),
        };
      }
      return {
        id: "weak",
        background: "#f2c94c",
        chips: initialStrengths,
      };
    },
    passwordChecks() {
      return this.passwordRootChecks.length
        ? this.passwordRootChecks
        : [
            {
              value: this.password.length >= 8,
              text: this.$t("settings.changePassword.hint.length"),
              id: "characters",
            },
            {
              value: /(?=.*[a-z])(?=.*[A-Z])/.test(this.password),
              text: this.$t("settings.changePassword.hint.uppercase"),
              id: "letters",
            },
            {
              value: /(?=.*\d)/.test(this.password),
              text: this.$t("settings.changePassword.hint.number"),
              id: "digits",
            },
          ];
    },
    localIsOpen: {
      get() {
        return this.isOpen;
      },
      set(newValue) {
        this.$emit("update:isOpen", newValue);
      },
    },
  },
  methods: {
    determineStrengthText(id) {
      return this.strengthTexts[id];
    },
  },
};
</script>
<style lang="scss">
.password-helper {
  color: $black;
  padding: 11px 0 10px 0;
  position: relative;

  &_pointer {
    position: absolute;
    z-index: 10;
    top: 50%;
    width: 0;
    height: 0;
    border-left: 10px solid $white;
    border-top: 10px solid transparent;
    transform: rotate(45deg);
    left: -20px;
  }

  &_chip {
    width: 17px;
    height: 5px;
    background: $primarylight2;
    margin-right: 2px;
  }

  &_strengths {
    display: flex;
    align-items: center;
    margin-bottom: 10px;

    &__title {
      font-style: normal;
      font-weight: 500;
      font-size: 0.9rem;
      line-height: 16px;
      color: $primaryblack2;
      mix-blend-mode: normal;
      margin-right: 9px;
    }

    &__text {
      margin-left: 9px;
      font-weight: 500;
      font-size: 0.9rem;
      line-height: 16px;
    }
  }

  &__weak {
    color: $yellow;
    background: $yellow;
  }

  &__medium {
    color: $orange;
    background: $orange;
  }

  &__strong {
    color: $success;
    background: $success;
  }

  &_checks {
    &_item {
      display: flex;
      margin-bottom: 3px;
      margin-left: 3px;
      &_text {
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 14px;
        display: flex;
        align-items: center;

        color: $primaryblack2;
        opacity: 0.42;
        mix-blend-mode: normal;

        &_completed {
          opacity: 1;
          color: $primaryblack2;
        }
      }
      &_icon {
        margin-right: 2px;
        opacity: 1 !important;
      }
    }
  }
}
.v-tooltip__content {
  border: 1px solid $primarylight2 !important;
}
</style>
