export type AppointmentStatus =
  | "proposed"
  | "pending"
  | "booked"
  | "arrived"
  | "fulfilled"
  | "cancelled"
  | "noshow"
  | "entered-in-error"
  | "checked-in"
  | "waitlist";

export enum AppointmentStatusEnum {
  proposed = "proposed",
  pending = "pending",
  booked = "booked",
  arrived = "arrived",
  fulfilled = "fulfilled",
  cancelled = "cancelled",
  noshow = "noshow",
  "entered-in-error" = "entered-in-error",
  "checked-in" = "checked-in",
  "waitlist" = "waitlist",
}

export interface AppointmentQueryFilterDto {
  practitionerId?: string;
  patientId?: string;
  status?: AppointmentStatus;
  startDate?: string;
  endDate?: string;
  date?: string;
  past?: boolean;
  future?: boolean;
  includeDependents?: boolean;
}

export enum AppointmentGroup {
  upcoming = "upcoming",
  past = "past",
  today = "today",
  pending = "pending",
}
