<template>
  <div>
    <v-tabs :value="value" class="tabs" :show-arrows="arrows">
      <v-tab
        class="tabs_tab"
        exact-active-class="tabs_tab_active"
        v-for="tab in tabs"
        :key="tab.text"
        @click="goto(tab.to)"
      >
        {{ tab.text }}
        <v-chip v-if="tab.chip > 0" color="primary" small class="ma-2 tabs_tab_chip">{{ tab.chip }}</v-chip>
      </v-tab>
    </v-tabs>
    <slot />
  </div>
</template>
<script>
export default {
  name: "Tabs",
  props: {
    value: { type: Number, default: 0 },
    tabs: { required: true, type: Array },
    arrows: { default: false, type: Boolean },
  },
  methods: {
    goto(link) {
      this.$router.push(link);
    },
  },
};
</script>
