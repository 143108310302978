import { defineStore } from "pinia";

import { AuthApi } from "@/api/auth";
import { MedicationApi } from "@/api/medication";
import { PatientsApi } from "@/api/patients";
import { PharmacyApi } from "@/api/pharmacy";
import { PractitionersAPI } from "@/api/practitioners";
import { useAuthStore } from "@/pinia-store/auth";

export const usePatientStore = defineStore({
  id: "patient",
  state: () => ({
    proxyAccounts: [],
    selfReportedMedication: [],
    isLoadingPharmacies: false,
    pharmacies: [],
    patient: {
      id: "",
      address: "",
      postalCode: "",
      email: "",
      homePhoneNumber: "",
      phoneNumber: "",
      practitioner: {
        id: "",
        slots: [],
      },
    },
  }),

  actions: {
    setPatient(payload: any) {
      this.patient = payload;
    },

    setProxyAccounts(payload: any) {
      this.proxyAccounts = payload;
    },

    async getPatientProfile(payload?: string) {
      const auth = useAuthStore();
      const { uid } = auth;
      const payloadId = payload || uid;

      if (this.patient?.id !== payloadId) this.patient = {};
      try {
        this.patient = await PatientsApi.patientGetProfile(payloadId);
      } catch (e) {
        console.error(e);
      }
    },
    async getAllPractitioners() {
      this.practitioners = await PractitionersAPI.getAllPractitioners();
    },
    async getPatientPharmacies(payload: string) {
      this.isLoadingPharmacies = true;
      try {
        this.pharmacies = await PharmacyApi.getPatientPharmacies({ patientId: this.patient.id || payload });
      } catch (e) {
        console.info("Patient doesn't have pharmacies");
      }
      this.isLoadingPharmacies = false;
    },
    async addPatientPharmacy(payload: { pharmacyId: string; primary?: boolean }) {
      try {
        const data = await PharmacyApi.add({
          pharmacyId: payload.pharmacyId,
          patientId: this.patient.id,
          primary: payload.primary || false,
        });
        this.pharmacies = this.pharmacies.concat(data);
      } catch (e) {
        console.trace(e);
      }
    },

    async addSelfReportedMedication(payload: Record<any, any>) {
      const data = await MedicationApi.add({
        ...payload,
        informationSourceId: this.patient.id,
        subject: "patient",
        subjectId: this.patient.id,
        informationSource: "patient",
      });
      this.selfReportedMedication = [...this.selfReportedMedication, data];
    },
    async setPatientPharmacyActive(payload: string) {
      try {
        await PharmacyApi.setActive({ pharmacyId: payload, patientId: this.patient.id });
      } catch (e) {
        console.trace(e);
      }
    },
    async getPatientProxyAccounts() {
      const auth = useAuthStore();
      if (auth.userIsPatient && auth.isLoggedIn) {
        this.proxyAccounts = await AuthApi.proxyPatients();
      }
    },
    async removePatientPharmacy(payload: string) {
      await PharmacyApi.destroy({ pharmacyId: payload, patientId: this.patient.id });
      this.pharmacies = this.pharmacies.filter((i: { id: string }) => i.id !== payload);
    },
    async getSelfReportedMedication() {
      this.selfReportedMedication = await MedicationApi.getPatientMedication({
        patientId: this.patient.id,
        informationSource: "patient",
        informationSourceId: this.patient.id,
      });
    },
  },
  getters: {
    patientsPractitioner(state) {
      return state.patient?.practitioner || {};
    },
  },
});
