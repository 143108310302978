<template>
  <div class="d-flex justify-center align-center flex-column fill-height full-width">
    <div>
      <img :src="require('@/assets/chat/no-messages.svg')" alt="no-messages.svg" />
    </div>
    <div class="messages-preview-title">{{ text }}</div>
  </div>
</template>

<script>
import Vue from "vue";

export default Vue.extend({
  name: "FloatingChatMessagePreview",
  props: ["text"],
});
</script>

<style scoped lang="scss">
.messages-preview-title {
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: $primaryblack2;
  opacity: 0.75;
}
</style>
