import { apiRequest } from "@/api/apiRequest";
import { NotificationTypes } from "@/types/notificationTypes";

export type NotificationFilterDto = {
  userId?: string;
  seenBadge?: boolean;
  notSeenBadge?: boolean;
  seenContent?: boolean;
  notSeenContent?: boolean;
  kind?: NotificationTypes[];
};

export type NotificationSeenDto = {
  id?: string;
  kind?: NotificationTypes[];
  seenBadge?: boolean;
  seenContent?: boolean;
};

const getNotifications = async (filter?: NotificationFilterDto) => {
  const { data } = await apiRequest.get(`/notifications`, { params: filter || {} });
  return data;
};

const markSeen = async (body: NotificationSeenDto) => {
  const { data } = await apiRequest.put(`/notifications`, body);
  return data;
};

export const NotificationsApi = {
  getNotifications,
  markSeen,
};
