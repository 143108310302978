import { ObservationEnum } from "@/types/ObservationEnum";

import { apiRequest } from "./apiRequest";

export type MonitoringFilters = {
  patientId: string;
  encounterId?: string;
  active?: boolean;
  kind?: ObservationEnum[];
};

const getOrders = async (query: MonitoringFilters) => {
  const { data } = await apiRequest.get(`/service-request/observations`, { params: query });
  return data;
};

export const MonitoringApi = {
  getOrders,
};
