<template>
  <component :is="component" :is-floating-chat="isFloatingChat" />
</template>

<script>
import FloatingFaqMessageRow from "./FloatingFaqMessageRow";
import FloatingNoServiceRow from "./FloatingNoServiceRow";
import FloatingPlaceholderRow from "./FloatingPlaceholderRow";
import FloatingTypingRow from "./FloatingTypingRow";
export default {
  name: "FloatingStatusRow",
  components: { FloatingNoServiceRow, FloatingTypingRow, FloatingPlaceholderRow, FloatingFaqMessageRow },
  props: {
    typing: Boolean,
    connected: Boolean,
    isFloatingChat: Boolean,
    participantIsBot: Boolean,
    isFAQMessage: Boolean,
  },
  computed: {
    component() {
      if (this.isFAQMessage) return "FloatingFaqMessageRow";
      if (this.typing && this.connected) {
        return "FloatingTypingRow";
      } else if (!this.connected && this.participantIsBot) {
        return "FloatingNoServiceRow";
      }
      return "FloatingPlaceholderRow";
    },
  },
};
</script>

<style scoped></style>
