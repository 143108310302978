<template>
  <v-dialog :value="dialog" width="400" @click:outside="closeHoursDialog">
    <v-card v-if="updatedEvent.start">
      <div class="dialog__head px-7 pt-7 d-flex justify-space-between align-center">
        <v-card-title class="dialog__head__title">{{ formatDate(start).format("MMMM D") }}</v-card-title>
        <v-icon class="pa-2" size="medium" @click="closeHoursDialog">mdi-close</v-icon>
      </div>
      <v-container class="px-7 pb-7">
        <p class="pa-0 my-3">{{ formatDate(start).format("MMMM D, YYYY") }}</p>

        <p class="pa-0 my-3">What hours are you available</p>
        <v-list>
          <div>
            <v-list-item class="justify-space-between align-baseline">
              <p v-if="!availabilities.length" class="text--secondary mb-0 ml-7">Unavailable</p>
              <v-col class="pa-0">
                <div v-if="availabilities.length">
                  <div v-for="(availability, index) in availabilities" :key="index" class="d-flex align-center mb-3">
                    <v-autocomplete
                      :hide-details="true"
                      :items="options"
                      :value="formatAvailabilityHours(availability.start)"
                      append-icon=""
                      dense
                      full-width
                      outlined
                      @change="onAvailableHoursChange($event, 'start', index)"
                    ></v-autocomplete>
                    <span class="mx-3"><v-icon small>mdi-minus</v-icon></span>
                    <v-autocomplete
                      :hide-details="true"
                      :items="options"
                      :value="formatAvailabilityHours(availability.end)"
                      append-icon=""
                      dense
                      full-width
                      outlined
                      type="text"
                      @change="onAvailableHoursChange($event, 'end', index)"
                    ></v-autocomplete>
                    <v-btn class="ml-4" fab small text @click="deleteAvailability(index)">
                      <v-icon>mdi-trash-can-outline</v-icon>
                    </v-btn>
                  </div>
                </div>
              </v-col>
              <v-btn fab small text @click="onPlusButtonClick()">
                <v-icon small>mdi-plus</v-icon>
              </v-btn>
            </v-list-item>
          </div>
        </v-list>
        <p class="pa-0 my-3">Repeat on</p>
        <Select :items="repeatItems" :value="frequencyType" hide-details @change="onRepeatChange" />
        <v-chip-group v-if="frequencyType === 'weekdays'" class="weekdays-chip-group">
          <v-chip
            v-for="index in weekDays"
            :key="index"
            :color="repeatWeekDays.includes(index) ? 'primary' : ''"
            @click="onRepeatDayClick(index)"
          >
            {{ getWeekDay(index) }}
          </v-chip>
        </v-chip-group>
      </v-container>
      <v-divider></v-divider>
      <v-card-actions class="justify-space-between px-7 py-5">
        <OutlinedButton
          :text="$t('general.buttons.delete')"
          additional-class-name="pa-5"
          style="color: #e53935; border: 1px solid #e53935; opacity: 1"
          @onClick="openDeleteDialog"
        />
        <div>
          <OutlinedButton
            :text="$t('general.dialog.cancel')"
            additional-class-name="pa-5"
            @onClick="closeHoursDialog"
          />
          <PrimaryButton
            :disabled="isDisabledUpdateButton"
            class-name="primary-btn__blue px-7 py-5 ml-3"
            color="primary"
            text="Update"
            @onClick="onSaveUpdate"
          />
        </div>
      </v-card-actions>
    </v-card>
    <Delete
      :closeDialog="closeDeleteModal"
      :closeUpdateHoursDialog="closeDialog"
      :date="start"
      :dialog="deleteModal"
      :updatedEvent="updatedEvent"
      @onConfirm="onDeleteClick"
    />
  </v-dialog>
</template>

<script>
import { DateTime } from "luxon";
import moment from "moment-timezone";
import { mapActions, mapState } from "pinia";

import Delete from "@/components/EditAvailability/Modals/Delete";
import OutlinedButton from "@/components/uikit/OutlinedButton";
import PrimaryButton from "@/components/uikit/PrimaryButton";
import Select from "@/components/uikit/Select";
import { useSlotsStore } from "@/pinia-store/slots";
import { availabilityHours } from "@/utils/availabilityHours";

export default {
  name: "UpdateHours",
  components: {
    Select,
    OutlinedButton,
    PrimaryButton,
    Delete,
  },
  props: ["dialog", "start", "closeDialog", "updatedEvent", "closeUpdateHoursDialog"],
  watch: {
    updatedEvent: {
      immediate: true,
      handler(val) {
        if (val.source) {
          this.repeatWeekDays = [
            ...(val.source?.frequencyType === "weekdays" ? val.source?.frequencyConfig?.days || [] : []),
          ];
          this.frequencyType = val.source?.frequencyType ?? "none";
          this.availabilities = val.source?.hours || [];
        }
      },
    },
  },
  data() {
    return {
      options: availabilityHours(),
      repeatItems: [
        { value: "none", text: "Never" },
        { value: "weekdays", text: "Every week" },
      ],
      availabilities: [],
      showRepeatWeekdays: false,
      frequencyType: "none",
      weekDays: [1, 2, 3, 4, 5, 6, 7],
      repeatWeekDays: [],
      deleteModal: false,
      changedStartDate: null,
      updateModal: false,
    };
  },
  computed: {
    ...mapState(useSlotsStore, ["customAvailabilities", "customInitialAvailabilities"]),
    isDisabledUpdateButton() {
      return !(typeof this.updatedEvent.start === "number" || typeof this.updatedEvent.end === "number");
    },
  },
  methods: {
    ...mapActions(useSlotsStore, ["setAvailabilities"]),
    ...mapActions(useSlotsStore, ["setAvailabilities", "setCustomAvailabilities"]),
    ...mapActions(useSlotsStore, ["getPractitionerAvailability"]),
    onSaveUpdate() {
      const newAvailabilities = [...this.customAvailabilities];
      let indexEvent = newAvailabilities.findIndex((i) => {
        return i.id === this.updatedEvent.source.id;
      });
      const body = {
        date: this.start,
        frequencyType: this.frequencyType,
        frequencyConfig: this.frequencyType === "weekdays" ? { days: this.repeatWeekDays } : {},
        hours: [...this.availabilities],
      };

      newAvailabilities[indexEvent] = { ...body };
      this.setCustomAvailabilities([...newAvailabilities]);
      this.closeDialog();
    },
    openDeleteDialog() {
      this.deleteModal = true;
    },
    onDeleteClick() {
      const newCustomAvailabilities = this.customAvailabilities.filter((i) => i.id !== this.updatedEvent.source.id);
      this.setCustomAvailabilities([...newCustomAvailabilities]);
      this.closeDialog();
      this.closeDeleteModal();
    },
    onRepeatChange(e) {
      this.showRepeatWeekdays = e === "Every week";
    },
    getWeekDay(number) {
      return moment().day(number).format("dd")[0];
    },
    onRepeatDayClick(index) {
      if (this.repeatWeekDays.includes(index)) this.repeatWeekDays = this.repeatWeekDays.filter((i) => i !== index);
      else this.repeatWeekDays.push(index);
    },
    formatDate(date = undefined) {
      return moment(date);
    },
    closeDeleteModal() {
      this.deleteModal = false;
    },
    closeHoursDialog() {
      this.changedStartDate = null;
      this.closeDialog();
    },
    formatAvailabilityHours(datetime) {
      if (typeof datetime === "number") {
        return DateTime.fromMillis(datetime).toFormat("HH:mm");
      } else {
        return DateTime.fromISO(datetime).toFormat("HH:mm");
      }
    },
    onPlusButtonClick() {
      this.availabilities.push({
        date: this.start,
        start: "",
        end: "",
        repeat: [],
      });
    },
    onAvailableHoursChange(e, type, index) {
      if (type === "start") {
        this.availabilities[index].start = e;
      } else {
        this.availabilities[index].end = e;
      }
    },
  },
};
</script>

<style lang="scss">
.weekdays-chip-group {
  .v-slide-group__content {
    justify-content: space-between;
  }
}
</style>
