<template>
  <div class="visit-summary_encounter-notes_item">
    <span class="visit-summary_section-title visit-summary_encounter-notes_item_title">
      {{ title }}
    </span>
    <div class="visit-summary_section-text">
      {{ textOrDash }}
    </div>
  </div>
</template>
<script>
export default {
  name: "EncounterNote",
  props: ["title", "text"],
  computed: {
    textOrDash() {
      return !this.text ? "-" : this.text;
    },
  },
};
</script>
