<template>
  <div class="monitoring-layout">
    <v-progress-linear v-if="loading" color="primary" indeterminate></v-progress-linear>
    <v-row>
      <v-col v-if="!hideHeaders" class="header" cols="12">
        <span class="heading-2">{{ title }}</span>
        <PrimaryButton v-if="userIsPatient" :text="`Add ${title}`" leftIcon="mdi-plus" @onClick="onCreate" />
      </v-col>
      <v-col cols="12">
        <BloodGlucoseTable :disable-edit="!userIsPatient" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapActions, mapState } from "pinia";

import { ServiceRequestApi } from "@/api/serviceRequest";
import PrimaryButton from "@/components/uikit/PrimaryButton";
import { BLOOD_GLUCOSE_ADDED } from "@/eventBuses/monitoringEvents";
import { useAuthStore } from "@/pinia-store/auth";
import { useObservationsStore } from "@/pinia-store/observations";
import { RolesEnum } from "@/types/Roles.enum";
import BloodGlucoseTable from "@/views/Patient/Monitoring/bloodGlucose/Table";

export default {
  name: "MonitoringBloodGlucose",
  components: { BloodGlucoseTable, PrimaryButton },
  props: {
    hideHeaders: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      loading: false,
      tab: "medical-record",
      observationType: {},
      search: "",
      searchActive: false,
      recentSearches: [],
      searchResult: [],
      searchLoading: false,
      noResults: false,
    };
  },
  computed: {
    ...mapState(useObservationsStore, { order: "bloodGlucoseOrder" }),
    ...mapState(useObservationsStore, ["bloodGlucose"]),
    ...mapState(useAuthStore, ["uid", "role"]),
    title() {
      return "Blood Glucose";
    },
    userIsPatient() {
      return this.role === RolesEnum.Patient;
    },
  },
  methods: {
    ...mapActions(useObservationsStore, ["getBloodGlucose"]),
    ...mapActions(useObservationsStore, [
      "setBloodGlucoseOrder",
      "setPatientId",
      "clearBloodGlucoseForm",
      "toggleBloodGlucoseFormOpened",
    ]),
    async reload() {
      this.loading = true;
      await this.getBloodGlucose();
      this.loading = false;
    },
    onCreate() {
      this.clearBloodGlucoseForm();
      this.toggleBloodGlucoseFormOpened();
    },
  },
  beforeDestroy() {
    this.$root.$off(BLOOD_GLUCOSE_ADDED, this.reload);
  },
  async mounted() {
    this.loading = true;
    const patientId = this.userIsPatient ? this.uid : this.$route.params.patientId;
    await this.setPatientId(patientId);
    this.$root.$on(BLOOD_GLUCOSE_ADDED, this.reload);

    await this.reload();
    try {
      const order = await ServiceRequestApi.getPatientBloodGlucoseOrder(patientId);
      this.setBloodGlucoseOrder(order);
    } finally {
      this.loading = false;
    }
  },
};
</script>

<style lang="scss">
.v-data-table-header {
  background: rgba(231, 232, 242, 0.21);
}

.table_actions {
  cursor: pointer;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  text-transform: uppercase;
  color: #2196f3;
}

.monitoring-layout {
  .mark-row {
    display: flex;
    align-items: center;
    gap: 5px;

    &.danger {
      color: red;

      i {
        color: red;
      }
    }
  }

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
</style>
