<template>
  <v-expansion-panels v-model="panel" accordion class="elevation-0 pharmacies" flat>
    <v-expansion-panel>
      <v-expansion-panel-header> Pharmacies</v-expansion-panel-header>
      <v-divider class="full-width"></v-divider>
      <v-expansion-panel-content class="">
        <v-skeleton-loader
          v-if="isLoadingPharmacies"
          :boilerplatstartTimee="true"
          class="mx-0 full-width"
          type="list-item-two-line"
        ></v-skeleton-loader>
        <v-row v-else class="pt-5 pb-5 body-wrap">
          <template v-if="pharmacies.length">
            <v-col v-for="item in pharmacies" :key="item.id" class="item-container" cols="12" md="4">
              <div class="name">{{ item.name || "--" }}</div>
              <div class="subtitle">{{ item.address || "--" }}</div>
              <div class="subtitle">{{ item.city || "--" }}</div>
              <div class="subtitle">{{ item.state || "--" }}</div>
              <div class="subtitle">{{ item.zipCode || "--" }}</div>
            </v-col>
          </template>
          <v-col v-else>Not available</v-col>
        </v-row>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import { mapState } from "pinia";

import { usePatientStore } from "@/pinia-store/patient";

export default {
  name: "PatientSummaryPharmacies",
  computed: {
    ...mapState(usePatientStore, ["pharmacies", "isLoadingPharmacies"]),
  },
  data() {
    return {
      panel: 1,
    };
  },
};
</script>

<style lang="scss" scoped>
.pharmacies {
  .card-title {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #33343e;
  }

  .body-wrap {
    display: flex;
    flex-wrap: wrap;

    .name {
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      color: #33343e;
      align-items: center;
      margin-bottom: 12px;
    }

    .subtitle {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      letter-spacing: 0.32px;
      color: #25233a;
      opacity: 0.5;
      margin-bottom: 4px;
    }
  }
}
</style>
