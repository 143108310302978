<template>
  <v-btn class="full-screen-btn" color="rgba(15,15,15, 0.36)" fab small @click="onToggleFullscreen">
    <v-icon v-if="!isFullScreen" color="white" dark>mdi-fullscreen</v-icon>
    <v-icon v-else color="white" dark>mdi-fullscreen-exit</v-icon>
  </v-btn>
</template>
<script>
import { mapActions, mapState } from "pinia";

import { useMeetingRoomStore } from "@/pinia-store/meetingRoom";

export default {
  name: "VisitFullScreenBtn",
  computed: {
    ...mapState(useMeetingRoomStore, ["isFullScreen"]),
  },
  mounted() {
    document.addEventListener("fullscreenchange", this.onChangeFullSCreen);
    document.addEventListener("webkitfullscreenchange", this.onChangeFullSCreen);
    document.addEventListener("mozfullscreenchange", this.onChangeFullSCreen);
    document.addEventListener("MSFullscreenChange", this.onChangeFullSCreen);
  },
  destroyed() {
    document.removeEventListener("fullscreenchange", this.onChangeFullSCreen);
    document.removeEventListener("webkitfullscreenchange", this.onChangeFullSCreen);
    document.removeEventListener("mozfullscreenchange", this.onChangeFullSCreen);
    document.removeEventListener("MSFullscreenChange", this.onChangeFullSCreen);
  },
  methods: {
    ...mapActions(useMeetingRoomStore, ["setIsFullScreen"]),
    onChangeFullSCreen() {
      this.setIsFullScreen(!this.isFullScreen);
    },
    onToggleFullscreen() {
      const elem = this.$parent.$refs.container;
      if (!this.isFullScreen) {
        if (elem.requestFullscreen) {
          elem.requestFullscreen();
        } else if (elem.mozRequestFullScreen) {
          /* Firefox */
          elem.mozRequestFullScreen();
        } else if (elem.webkitRequestFullscreen) {
          /* Chrome, Safari and Opera */
          elem.webkitRequestFullscreen();
        } else if (elem.msRequestFullscreen) {
          /* IE/Edge */
          elem.msRequestFullscreen();
        }
      } else {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.mozCancelFullScreen) {
          /* Firefox */
          document.mozCancelFullScreen();
        } else if (document.webkitExitFullscreen) {
          /* Chrome, Safari and Opera */
          document.webkitExitFullscreen();
        } else if (document.msExitFullscreen) {
          /* IE/Edge */
          document.msExitFullscreen();
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.full-screen-btn {
  position: absolute;
  top: 21px !important;
  right: 20px;
  z-index: 3;
  background: rgba(15, 15, 15, 0.36);
}
</style>
