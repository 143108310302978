<template>
  <div>
    <Calendar />
    <Confirm
      :cancelText="$t('general.dialog.cancel')"
      :confirmText="$t('practitioner.appointments.leave.button')"
      :dialog="dialog"
      :text="$t('practitioner.appointments.leave.text')"
      :title="$t('practitioner.appointments.leave.title')"
      @confirm="onConfirmDialog"
      @toggleDialog="dialog = false"
    />
  </div>
</template>
<script>
import differenceWith from "lodash/differenceWith";
import isEqual from "lodash/isEqual";
import { mapState } from "pinia";

import Calendar from "@/components/EditAvailabilityCalendar";
import Confirm from "@/components/shared/Confirm";
import { useSlotsStore } from "@/pinia-store/slots";

export default {
  name: "EditAvailability",
  components: {
    Calendar,
    Confirm,
  },
  data: () => ({
    redirectPath: "",
    dialog: false,
  }),
  computed: {
    ...mapState(useSlotsStore, ["availabilities", "initialAvailabilities"]),
  },
  methods: {
    async onConfirmDialog() {
      await this.$router.push({ path: this.redirectPath });
    },
  },
  beforeRouteLeave(to, from, next) {
    const diff = differenceWith(this.availabilities, this.initialAvailabilities, isEqual);
    if (!this.redirectPath && diff.length) {
      this.redirectPath = to.path;
      this.dialog = true;
      return;
    }
    next();
  },
};
</script>
