<template>
  <v-text-field
    v-mask="'+1 (###) ###-####'"
    :class="className"
    :dense="dense"
    :disabled="disabled"
    :error="!!errors.length"
    :error-messages="errors"
    :flat="!outlined"
    :height="height"
    :hide-details="true"
    :label="placeholder"
    :outlined="!!errors.length || outlined"
    :solo="!outlined"
    :value="value"
    class="base-input"
    light
    @blur="onBlur"
    @focus="outlined = true"
    @input="onChange"
  ></v-text-field>
</template>
<script>
import { mask } from "vue-the-mask";

export default {
  name: "PhoneInput",
  directives: {
    mask,
  },
  props: {
    value: String,
    fieldName: String,
    className: { default: "", type: String },
    height: { default: 47, type: Number },
    placeholder: { default: "", type: String },
    errors: { default: () => [], type: Array },
    dense: { default: true, type: Boolean },
    disabled: { default: false, type: Boolean },
  },
  data: () => ({
    outlined: false,
  }),
  methods: {
    onChange(value) {
      this.$emit("change", value, this.fieldName);
      this.$emit("input", value);
    },
    onBlur() {
      this.outlined = false;
      this.$emit("blur");
    },
  },
};
</script>
