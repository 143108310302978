<template>
  <v-icon class="close-icon" color="icon" size="medium" @click="$emit('onClick')"> mdi-close</v-icon>
</template>

<script>
export default {
  name: "CardCloseIcon",
};
</script>

<style lang="scss" scoped>
.close-icon {
  position: sticky !important;
  top: 17px;
  right: 16px;
  width: 21px;
  height: 21px;
  background: #ffffff;
  border: 1px solid #e7e8f2;
  padding: 10px;
  border-radius: 100%;
  border-top-left-radius: 100% !important;
  border-top-right-radius: 100% !important;
  left: auto;
  margin-right: 10px;
  margin-left: auto;
  margin-top: 17px;
  float: right;
  z-index: 2;
}
</style>
