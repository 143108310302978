<template>
  <div :class="minimized ? 'minimized' : ''" class="floating-meeting-room-wrap">
    <div class="floating-meeting-room">
      <v-icon v-if="!minimized" class="close-icon" color="icon" size="medium" @click="onClose"> mdi-close</v-icon>
      <MeetingVideoRoom />
    </div>
  </div>
</template>
<script>
import { mapActions, mapState } from "pinia";

import MeetingVideoRoom from "@/components/MeetingRoom/MeetingVideoRoom.vue";
import { useMeetingRoomStore } from "@/pinia-store/meetingRoom";

export default {
  name: "FloatingMeetingRoom",
  components: { MeetingVideoRoom },
  props: {},
  computed: {
    ...mapState(useMeetingRoomStore, ["minimized"]),
  },
  methods: {
    ...mapActions(useMeetingRoomStore, ["finishRoom", "fetchRoom", "getMeetingRooms"]),
    ...mapActions(useMeetingRoomStore, [
      "setIsVideo",
      "setIsAudio",
      "setRoomId",
      "setIdentityId",
      "setIsModalMode",
      "setOpenedListDialog",
      "setOpenedMeetingRoom",
      "setLocalParticipantAcceptedJoin",
    ]),
    async onClose() {
      this.setRoomId(null);
      this.setIdentityId(null);
      this.setIsModalMode(false);
      this.setOpenedMeetingRoom(false);
      this.setLocalParticipantAcceptedJoin(false);
    },
  },
};
</script>
<style lang="scss" scoped>
.floating-meeting-room-wrap {
  position: fixed;
  z-index: 5;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  transition: opacity 0.3s ease;

  &.minimized {
    top: inherit;
    left: inherit;
    transform: none;
    width: 100px;
    height: 132px;
    right: 20px;
    bottom: 20px;
  }
}

.floating-meeting-room {
  //background: var(--gray-1, #33343e);
  //padding: 10px;
  position: relative;
  width: 96%;
  border-radius: 4px;
  max-width: 520px;
  display: flex;
  justify-content: center;
  transition: opacity 0.3s ease;
  align-self: center;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  align-content: center;

  .close-icon {
    position: absolute;
    top: -33px;
    right: -10px;
    width: 30px;
    height: 30px;
    background: #ffffff;
    border: 1px solid #e7e8f2;
    padding: 10px;
    border-radius: 100%;
    border-top-left-radius: 100% !important;
    border-top-right-radius: 100% !important;
    left: auto;
    margin-right: 10px;
    margin-left: auto;
    margin-top: 17px;
    float: right;
    z-index: 2;
  }
}
</style>
