import { AxiosError } from "axios";
import parseInt from "lodash/parseInt";
import moment from "moment-timezone";
import { defineStore } from "pinia";

import { AppointmentsApi } from "@/api/appointment";
import { MediaApi } from "@/api/media";
import { PatientsApi } from "@/api/patients";
import { PractitionersAPI } from "@/api/practitioners";
import { SlotsApi } from "@/api/slots";
import { useAuthStore } from "@/pinia-store/auth";
import { PractitionerProfile, VisitSettings } from "@/pinia-store/interfaces/checkoutI";
import { IMedia } from "@/pinia-store/media";
import { useVisitSettingsStore } from "@/pinia-store/visitSettings";
import { SlotItem } from "@/types/Availability";
import { Patient, Practitioner } from "@/types/users";

export enum stateTypes {
  // showing slots for patient practitioner
  patientPractitionerAvailable = "patientPractitionerAvailable",
  // showing  all slots grouped for patient practitioner
  showAllPatientPractitioner = "showAllPatientPractitioner",
  // showing  all practitioners with grouped slots per days
  showAllPractitioners = "showAllPractitioners",
  medicalInfo = "medicalInfo",
  checkout = "checkout",
  error = "error",
}

export interface PractitionerWithSlots {
  practitioner?: PractitionerProfile;
  slots: SlotItem[];
  loaded: boolean;
  isLoading: boolean;
  expanded: boolean;
}

interface PractitionerVisitSchedule {
  step: stateTypes;
  slots: SlotItem[];
  patients: Patient[];
  files: IMedia[];
  patientProfile: Partial<Patient>;
  practitionerProfile: Partial<Practitioner>;
  isFetchedPractitionerProfile: boolean;
  isFetchedAllPatients: boolean;
  isSessionLoading: boolean;
  isLoadingSlots: boolean;
  isPatientProfileLoaded: boolean;
  chiefComplaint: string;
  complaintDescription: string;
  slotId: string;
  sessionId: string;
  errorType: string;
  slot?: SlotItem;
  practitionerId: string;
  patientId: string;
  searchDate: string; //DD-MM-YYYY
  visitDate: string; //DD-MM-YYYY
  // used when are showing all slots for several days
  filterDaysDate: string; //DD-MM-YYYY

  [key: string]: string | boolean | stateTypes | [] | VisitSettings | any;
}

export const usePractitionerVisitScheduleStore = defineStore({
  id: "practitionerVisitSchedule",
  state: (): PractitionerVisitSchedule => ({
    slots: [],
    files: [],
    status: "",
    id: "",
    errorType: "",
    visitDate: moment().format("YYYY-MM-DD"),
    searchDate: moment().format("YYYY-MM-DD"),
    filterDaysDate: moment().format("YYYY-MM-DD"),
    patientProfile: {},
    practitionerProfile: {},
    sessionId: "",
    slotId: "",
    slot: undefined,
    practitionerId: "",
    step: stateTypes.patientPractitionerAvailable,
    patients: [],
    patientId: "",
    isPatientProfileLoaded: false,
    isLoadingSlots: false,
    isSessionLoading: false,
    isFetchedAllPatients: false,
    isFetchedPractitionerProfile: false,
    isFetchedVisitSettings: false,
    chiefComplaint: "",
    complaintDescription: "",
  }),
  actions: {
    replaceFile(newFile: IMedia) {
      this.files = this.files.map((file: IMedia) => {
        return file.id === newFile.id ? { ...newFile } : { ...file };
      });
    },
    setFiles(payload: [], replace = false) {
      this.files = replace ? [...payload] : [...this.files, ...payload];
    },
    async getPractitionerSlots(): Promise<void> {
      this.isLoadingSlots = true;
      const authStore = useAuthStore();
      const data = await SlotsApi.getIsAvailable({
        practitionerId: authStore.uid as string,
        startDate: this.minScheduleDate,
        excludeRegistrars: true,
      });
      const slotsData = data?.[0]?.slots || [];
      this.isLoadingSlots = false;

      if (!slotsData.length) {
        this.slots = [];
        return;
      }
      this.slots = slotsData;
      if (!this.sessionId) {
        const firstSlot = slotsData[0];
        this.searchDate = moment(firstSlot.start).format("YYYY-MM-DD");
      }
    },
    cleanUp() {
      this.slots = [];
      this.sessionId = "";
      this.slotId = "";
      this.slot = undefined;
      this.practitionerId = "";
      this.patientId = "";
      this.chiefComplaint = "";
      this.complaintDescription = "";
      this.isFetchedAllPatients = false;
      this.isFetchedPractitionerProfile = false;
      this.isFetchedVisitSettings = false;
    },
    async preloadPatients(): Promise<void> {
      const authStore = useAuthStore();
      if (this.isFetchedAllPatients || !authStore.uid) {
        return;
      }
      try {
        this.patients = await PatientsApi.getAllByPractitionerId(authStore.uid);
        this.isFetchedAllPatients = true;
      } catch (e) {
        console.trace("Error fetching all practitioners");
      }
    },
    async preloadPractitionerProfile(): Promise<void> {
      const authStore = useAuthStore();

      if (this.isFetchedPractitionerProfile || !authStore.uid) {
        return;
      }
      try {
        this.practitionerProfile = await PractitionersAPI.getPractitionerById(authStore.uid);
      } catch (e) {
        console.trace("Error fetching practitionerProfile");
      }
    },
    async setSlotOnHold(payload: string): Promise<void> {
      try {
        await SlotsApi.setOnHold(payload);
      } catch (e) {
        console.trace("Error fetching all practitioners");
      }
    },
    async submitForm() {
      const sessionId = this.sessionId;
      const slotId = this.slotId;
      const chiefComplaint = this.chiefComplaint;
      const complaintDescription = this.complaintDescription;
      const patientId = this.patientId;
      const virtualService = this.virtualService;
      if (sessionId) {
        try {
          await AppointmentsApi.complete(sessionId);
        } catch (err) {
          if (err instanceof AxiosError) if (err?.response?.status === 409) this.errorType = "conflict";
          this.step = stateTypes.error;
          if ([stateTypes.showAllPatientPractitioner, stateTypes.showAllPractitioners].includes(stateTypes.error)) {
            this.filterDaysDate = this.searchDate;
          }
        }
        return;
      }
      try {
        await AppointmentsApi.createVisit(
          (this.files || []).map((file: { id: string }) => file.id),
          patientId,
          slotId,
          "pending",
          chiefComplaint,
          virtualService,
          complaintDescription,
          false,
        );
      } catch (err) {
        if (err instanceof AxiosError && err?.response?.status === 409) this.errorType = "conflict";
        this.step = stateTypes.error;
        if ([stateTypes.showAllPatientPractitioner, stateTypes.showAllPractitioners].includes(stateTypes.error)) {
          this.filterDaysDate = this.searchDate;
        }
      }
    },
    async getSessionInfo(): Promise<void> {
      this.isSessionLoading = true;
      const appointment = await AppointmentsApi.getById(this.sessionId);
      const files = await MediaApi.getComponentFiles({ componentId: this.sessionId, componentType: "appointments" });
      // getting slot info
      this.setFiles(files, true);

      const slotInfo = await SlotsApi.getById(appointment.slotId);
      this.patientProfile = await PatientsApi.patientGetProfile(appointment.patientId);
      this.preloadPractitionerProfile();
      this.slots = [slotInfo];
      const authStore = useAuthStore();

      const slot = this.slots.find((slot: SlotItem) => slot.id === appointment.slotId);
      if (slot) {
        this.slot = slot;
        this.slotId = appointment.slotId;
        this.searchDate = moment(slot.start).tz(authStore.timeZone).format("YYYY-MM-DD");
        this.visitDate = moment(slot.start).tz(authStore.timeZone).format("YYYY-MM-DD");
      }

      this.status = appointment.status;
      this.id = appointment.id;
      this.practitionerId = appointment.practitionerId;
      this.patientId = appointment.patientId;
      this.chiefComplaint = appointment.chiefComplaint;
      this.complaintDescription = appointment.complaintDescription;
      this.isSessionLoading = false;
      return appointment;
    },
    selectFistSlotFromSearchDate(): void {
      const slots = this.slotsOnSearchDate;
      const authStore = useAuthStore();
      if (slots.length) {
        this.setSlotId({ slotId: slots[0].id, timeZone: authStore.timeZone });
      }
    },
    setStringFieldByName(payload: { fieldName: string; value: string }) {
      const { fieldName, value } = payload;
      this[fieldName] = value;
    },
    setSlotId(payload: { slotId: string; timeZone: string }) {
      const slot = this.slots.find((slot: SlotItem) => slot.id === payload.slotId);
      if (slot) {
        this.slot = slot;
        this.slotId = payload.slotId;
        this.searchDate = moment(slot.start).tz(payload.timeZone).format("YYYY-MM-DD");
        this.visitDate = moment(slot.start).tz(payload.timeZone).format("YYYY-MM-DD");
      }
    },
  },
  getters: {
    minScheduleDate(): string {
      const authStore = useAuthStore();
      return moment.tz(authStore.timeZone).format("YYYY-MM-DD");
    },
    timeZone(): string {
      const authStore = useAuthStore();

      return authStore.timeZone;
    },
    maxScheduleDate: () => {
      const authStore = useAuthStore();
      const visitSettingsStore = useVisitSettingsStore();

      return moment.tz(authStore.timeZone).add(visitSettingsStore.scheduleHorizonLimit, "days").format("YYYY-MM-DD");
    },
    $searchDate: (state): string => {
      const authStore = useAuthStore();

      return moment.tz(state.searchDate, authStore.timeZone).format("yyyy-MM-DD");
    },
    isLoading: (state): boolean => !state.isPatientProfileLoaded || state.isSessionLoading || state.isLoadingSlots,
    slotsOnSearchDate: (state) => {
      const authStore = useAuthStore();
      const visitSettingsStore = useVisitSettingsStore();
      const scheduleToStartLimit = visitSettingsStore.scheduleToStartLimit;
      const { timeZone } = authStore;

      const nowTime = moment.tz(timeZone).add({
        hours: parseInt((scheduleToStartLimit || "").split(":")[0]),
        minutes: parseInt((scheduleToStartLimit || "").split(":")[1]),
      });
      const { searchDate } = state;
      const date = moment.tz(searchDate, timeZone);
      return state.slots.filter((slot: SlotItem) => {
        return (
          moment(slot.start).tz(timeZone).isSameOrAfter(date.startOf("day").format()) &&
          moment(slot.start).tz(timeZone).isBefore(date.endOf("day").format()) &&
          moment(slot.start).tz(timeZone).isAfter(nowTime.format())
        );
      });
    },

    datesOfSlots: (state): string[] => {
      const slots = state.slots;
      const authStore = useAuthStore();
      if (!slots.length) return [];
      return [
        ...new Set<string>(
          slots.map((slot: SlotItem): string => moment(slot.start).tz(authStore.timeZone).format("YYYY-MM-DD")),
        ),
      ];
    },
    isContinueButtonEnabled: (state): boolean => {
      return Boolean(
        state.practitionerId && state.complaintDescription.length && state.chiefComplaint.length && state.slot?.id,
      );
    },
  },
});
