<template>
  <div class="pdf-preview">
    <Pagination :index="currentPage - 1" :total="pageCount" @onNext="onNextPage" @onPrev="onPrevPage" />
    <pdf :page="currentPage" :src="fileUrl" @num-pages="pageCount = $event" @page-loaded="currentPage = $event"></pdf>
    <Pagination :index="currentPage - 1" :total="pageCount" @onNext="onNextPage" @onPrev="onPrevPage" />
  </div>
</template>

<script>
import pdf from "vue-pdf";

import Pagination from "@/components/Pagination/View";

export default {
  name: "PdfPreview",
  components: { Pagination, pdf },
  props: ["fileUrl"],
  data: () => {
    return {
      pageCount: 1,
      currentPage: 1,
    };
  },
  methods: {
    async onPrevPage() {
      if (this.currentPage === 1) return;
      this.currentPage -= 1;
    },
    async onNextPage() {
      if (this.currentPage === this.pageCount) return;
      this.currentPage += 1;
    },
  },
};
</script>

<style lang="scss" scoped>
.pdf-preview {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 16px;

  :deep .pagination {
    justify-content: center;
  }
}
</style>
