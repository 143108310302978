export const invitesHeaders = [
  {
    text: "Name",
    value: "name",
    sortable: false,
  },
  {
    text: "Email",
    value: "email",
    sortable: false,
  },
  {
    text: "Status",
    value: "status",
  },
  {
    text: "",
    value: "actions",
    sortable: false,
  },
];
export const conversation = [
  {
    text: "User Name",
    value: "userName",
    sortable: true,
    width: 100,
  },
  {
    text: "Email",
    value: "email",
    sortable: true,
    width: 100,
  },
  {
    text: "Date",
    value: "date",
    sortable: true,
    width: 200,
  },
  {
    text: "Total Message",
    value: "totalMessage",
    sortable: true,
    width: 200,
  },
  {
    text: "Chat duration",
    value: "chatDuration",
    sortable: true,
    width: 200,
  },
  {
    text: "",
    value: "view",
    sortable: false,
    width: 200,
  },
];
export const patientsHeaders = [
  {
    text: "Medical Record ID",
    value: "medicalRecordNumber",
    sortable: false,
  },
  {
    text: "First Name",
    value: "firstName",
    sortable: false,
  },
  {
    text: "Last Name",
    value: "lastName",
    sortable: false,
    width: 200,
  },
  {
    text: "Email",
    value: "email",
    sortable: false,
  },
  {
    text: "State",
    value: "state",
    width: 200,
  },
  {
    text: "DOB",
    value: "birthDate",
    width: 200,
  },
  {
    text: "Current Practitioner",
    value: "practitioner",
    width: 200,
  },
  {
    text: "",
    value: "actions",
    sortable: false,
  },
];
