import { defineStore } from "pinia";

import { ObservationsApi } from "@/api/observations";
import { ObservationsState } from "@/types";

export const useObservationsStore = defineStore({
  id: "observations",
  state: (): ObservationsState => ({
    isLoadingSummary: false,
    summary: {},
    sickModal: false,
    sickModalText: [],
    formKindSelector: false,

    bodyWeightTableOpened: false,
    bodyWeightForm: {},
    bodyWeightFormOpened: false,
    bodyWeight: [],

    smokingBehaviorTableOpened: false,
    smokingBehaviorForm: {},
    smokingBehaviorFormOpened: false,
    smokingBehavior: [],

    bodyHeightTableOpened: false,
    bodyHeightForm: {},
    bodyHeightFormOpened: false,
    bodyHeight: [],

    bloodGlucoseTableOpened: false,
    respiratoryRateTableOpened: false,
    oxygenSaturationTableOpened: false,
    bodyTemperatureTableOpened: false,
    heartRateTableOpened: false,
    bloodPressureTableOpened: false,

    bloodGlucose: [],
    bloodGlucoseOrder: {},
    bloodGlucoseOrderForm: {},
    bloodGlucoseForm: {},
    bloodGlucoseFormOpened: false,
    bloodGlucoseOrderFormOpened: false,

    respiratoryRate: [],
    respiratoryRateOrder: {},
    respiratoryRateOrderForm: {},
    respiratoryRateForm: {},
    respiratoryRateFormOpened: false,
    respiratoryRateOrderFormOpened: false,

    oxygenSaturationForm: {},
    oxygenSaturationOrderForm: {},
    oxygenSaturationFormOpened: false,
    oxygenSaturationOrderFormOpened: false,
    oxygenSaturationOrder: {},
    oxygenSaturation: [],

    bodyTemperatureForm: {
      date: "",
    },
    bodyTemperatureFormOpened: false,
    bodyTemperatureOrderFormOpened: false,
    bodyTemperatureOrder: {},
    bodyTemperatureOrderForm: {},
    bodyTemperature: [],

    bloodPressureOrder: {},
    heartRateOrder: {},
    history: [],
    orders: [],
    bloodPressure: [],
    heartRate: [],
    bloodPressureForm: {},
    bloodPressureFormOpened: false,
    heartRateForm: {},
    heartRateFormOpened: false,
    patientId: "",
    editForm: {
      date: "",
      time: "",
      kind: "",
      display: "",
      value: "",
      note: "",
      showOptions: false,
    },
    editFormOpened: false,
  }),
  actions: {
    setIsLoadingSummary(payload: boolean) {
      this.isLoadingSummary = payload;
    },
    setSummary(payload: any) {
      this.summary = payload;
    },
    openSickModal(payload: string[]) {
      this.sickModalText = payload;
      this.sickModal = true;
    },
    closeSickModal() {
      this.sickModalText = [];
      this.sickModal = false;
    },
    toggleFormKindSelector() {
      this.formKindSelector = !this.formKindSelector;
    },
    setBloodGlucoseOrder(payload: any) {
      this.bloodGlucoseOrder = payload;
    },
    setBloodPressureOrder(payload: any) {
      this.bloodPressureOrder = payload;
    },
    setBodyTemperatureOrder(payload: any) {
      this.bodyTemperatureOrder = payload;
    },
    setOxygenSaturationOrder(payload: any) {
      this.oxygenSaturationOrder = payload;
    },
    setHeartRateOrder(payload: any) {
      this.heartRateOrder = payload;
    },
    setRespiratoryRateOrder(payload: any) {
      this.respiratoryRateOrder = payload;
    },
    setRespiratoryRate(payload: any) {
      this.respiratoryRate = payload;
    },
    setBodyWeight(payload: any) {
      this.bodyWeight = payload;
    },
    setSmokingBehavior(payload: any) {
      this.smokingBehavior = payload;
    },
    setBodyHeight(payload: any) {
      this.bodyHeight = payload;
    },
    setHistory(payload: any) {
      this.history = payload;
    },
    setOrders(payload: any[]) {
      this.orders = payload;
    },
    setPatientId(payload: any) {
      this.patientId = payload;
    },
    changeEditFormField(payload: { field: any; value: any }) {
      this.editForm[payload.field] = payload.value;
    },
    changeBloodPressureFormFormField(payload: { field: any; value: any }) {
      this.bloodPressureForm[payload.field] = payload.value;
    },

    changeOxygenSaturationFormFormField(payload: { field: any; value: any }) {
      this.oxygenSaturationForm[payload.field] = payload.value;
    },
    openMonitoringFormByKind(kind: any) {
      this.formKindSelector = false;
      switch (kind) {
        case "bloodGlucose": {
          this.bloodGlucoseForm = {};
          this.bloodGlucoseFormOpened = true;
          break;
        }
        case "oxygenSaturation": {
          this.oxygenSaturationForm = {};
          this.oxygenSaturationFormOpened = true;
          break;
        }
        case "bodyTemperature": {
          this.bodyTemperatureForm = {};
          this.bodyTemperatureFormOpened = true;
          break;
        }
        case "respiratoryRate": {
          this.respiratoryRateForm = {};

          this.respiratoryRateFormOpened = true;
          break;
        }
        case "heartRate": {
          this.heartRateForm = {};
          this.heartRateFormOpened = true;
          break;
        }
        case "bloodPressure": {
          this.bloodPressureForm = {};
          this.bloodPressureFormOpened = true;
          break;
        }
      }
    },
    changeBodyTemperatureFormFormField(payload: { field: any; value: any }) {
      this.bodyTemperatureForm[payload.field] = payload.value;
    },
    toggleBloodPressureFormOpened() {
      this.bloodPressureFormOpened = !this.bloodPressureFormOpened;
    },
    toggleSmokingBehaviorTableOpened() {
      this.smokingBehaviorTableOpened = !this.smokingBehaviorTableOpened;
    },
    toggleBodyWeightFormOpened() {
      this.bodyWeightFormOpened = !this.bodyWeightFormOpened;
    },
    toggleBodyHeightFormOpened() {
      this.bodyHeightFormOpened = !this.bodyHeightFormOpened;
    },
    toggleOxygenSaturationFormOpened() {
      this.oxygenSaturationFormOpened = !this.oxygenSaturationFormOpened;
    },
    fillBloodPressureForm(payload: any) {
      this.bloodPressureForm = payload;
    },
    fillSmokingBehaviorForm(payload: any) {
      this.smokingBehaviorForm = payload;
    },
    fillBodyWeightForm(payload: any) {
      this.bodyWeightForm = payload;
    },
    fillBodyHeightForm(payload: any) {
      this.bodyHeightForm = payload;
    },
    fillRespiratoryRateForm(payload: any) {
      this.respirationRateForm = payload;
    },
    clearBloodPressureForm() {
      this.bloodPressureForm = {};
    },
    clearSmokingBehaviorForm() {
      this.smokingBehaviorForm = {};
    },
    clearRespiratoryRateForm() {
      this.respiratoryRateForm = {};
    },
    clearBloodGlucoseForm() {
      this.bloodGlucoseForm = {};
    },
    clearOxygenSaturationForm() {
      this.oxygenSaturationForm = {};
    },
    changeHeartRateFormFormField(payload: { field: any; value: any }) {
      this.heartRateForm[payload.field] = payload.value;
    },
    changeBodyWeightFormFormField(payload: { field: any; value: any }) {
      this.bodyWeightForm[payload.field] = payload.value;
    },
    changeBodyHeightFormFormField(payload: { field: any; value: any }) {
      this.bodyHeightForm[payload.field] = payload.value;
    },
    changeBloodGlucoseFormFormField(payload: { field: any; value: any }) {
      this.bloodGlucoseForm[payload.field] = payload.value;
    },
    changeRespiratoryRateFormFormField(payload: { field: any; value: any }) {
      this.respiratoryRateForm[payload.field] = payload.value;
    },
    toggleHeartRateFormOpened() {
      this.heartRateFormOpened = !this.heartRateFormOpened;
    },
    toggleSmokingBehaviorFormOpened() {
      this.smokingBehaviorFormOpened = !this.smokingBehaviorFormOpened;
    },
    toggleBloodGlucoseTableOpened() {
      this.bloodGlucoseTableOpened = !this.bloodGlucoseTableOpened;
    },
    toggleBodyWeightTableOpened() {
      this.bodyWeightTableOpened = !this.bodyWeightTableOpened;
    },
    toggleBodyHeightTableOpened() {
      this.bodyHeightTableOpened = !this.bodyHeightTableOpened;
    },
    toggleRespiratoryRateTableOpened() {
      this.respiratoryRateTableOpened = !this.respiratoryRateTableOpened;
    },
    toggleOxygenSaturationTableOpened() {
      this.oxygenSaturationTableOpened = !this.oxygenSaturationTableOpened;
    },
    toggleBodyTemperatureTableOpened() {
      this.bodyTemperatureTableOpened = !this.bodyTemperatureTableOpened;
    },
    toggleHeartRateTableOpened() {
      this.heartRateTableOpened = !this.heartRateTableOpened;
    },
    toggleBloodPressureTableOpened() {
      this.bloodPressureTableOpened = !this.bloodPressureTableOpened;
    },
    toggleBodyTemperatureFormOpened() {
      this.bodyTemperatureFormOpened = !this.bodyTemperatureFormOpened;
    },
    toggleRespiratoryRateFormOpened() {
      this.respiratoryRateFormOpened = !this.respiratoryRateFormOpened;
    },
    toggleBloodGlucoseFormOpened() {
      this.bloodGlucoseFormOpened = !this.bloodGlucoseFormOpened;
    },
    fillHeartRateForm(payload: any) {
      this.heartRateForm = payload;
    },
    fillOxygenSaturationForm(payload: any) {
      this.oxygenSaturationForm = payload;
    },
    clearHeartRateForm() {
      this.heartRateForm = {};
    },
    clearBodyTemperatureForm() {
      this.bodyTemperatureForm = {};
    },
    clearBodyWeightForm() {
      this.bodyWeightForm = {};
    },
    clearBodyHeightForm() {
      this.bodyHeightForm = {};
    },
    fillEditForm(payload: any) {
      this.editForm = payload;
    },
    fillBodyTemperatureForm(payload: any) {
      this.bodyTemperatureForm = payload;
    },
    fillBloodGlucoseForm(payload: any) {
      this.bloodGlucoseForm = payload;
    },
    clearEditForm() {
      this.editForm = {};
    },
    toggleEditFormOptions() {
      this.editForm.showOptions = !this.editForm.showOptions;
    },

    changeSmokingBehaviorFormFormField(payload: { field: any; value: any }) {
      this.smokingBehaviorForm[payload.field] = payload.value;
    },

    async getSummary(payload?: string) {
      this.isLoadingSummary = true;
      this.summary = await ObservationsApi.getSummary(payload || this.patientId);
      this.isLoadingSummary = false;
    },
    async getBloodPressure() {
      this.bloodPressure = await ObservationsApi.getBloodPressure(this.patientId);
    },
    async getBodyWeight() {
      this.bodyWeight = await ObservationsApi.getBodyWeight(this.patientId);
    },
    async getSmokingBehavior() {
      this.smokingBehavior = await ObservationsApi.getSmokingBehavior(this.patientId);
    },
    async getBodyHeight() {
      this.bodyHeight = await ObservationsApi.getBodyHeight(this.patientId);
    },
    async getBloodGlucose() {
      this.bloodGlucose = await ObservationsApi.getBloodGlucose(this.patientId);
    },
    async getRespiratoryRate() {
      this.respiratoryRate = await ObservationsApi.getRespiratoryRate(this.patientId);
    },
    async getBodyTemperature() {
      this.bodyTemperature = await ObservationsApi.getBodyTemperature(this.patientId);
    },
    async getHeartRate() {
      this.heartRate = await ObservationsApi.getHeartRate(this.patientId);
    },
    async getOxygenSaturation() {
      this.oxygenSaturation = await ObservationsApi.getOxygenSaturation(this.patientId);
    },

    async fetchHistory(payload: { patientId: string; kind: string }) {
      this.history = await ObservationsApi.getHistory(payload.patientId, payload.kind);
    },
  },
});
