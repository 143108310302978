<template>
  <Confirm
    :dialog="bloodPressureTableOpened"
    :hide-cancel="true"
    :title="title"
    confirm-text="Close"
    maxWidth="90%"
    rootClass="monitoring-drawer"
    text=""
    width="1100px"
    @confirm="toggleBloodPressureTableOpened"
    @toggleDialog="toggleBloodPressureTableOpened"
  >
    <v-progress-linear v-if="loading" color="primary" indeterminate></v-progress-linear>
    <BloodPressureTable :disable-edit="disableEdit" />
  </Confirm>
</template>

<script>
import { mapActions, mapState } from "pinia";

import Confirm from "@/components/shared/Confirm";
import { useObservationsStore } from "@/pinia-store/observations";
import BloodPressureTable from "@/views/Patient/Monitoring/bloodPressure/Table";

export default {
  name: "DrawerBloodPressure",
  components: { BloodPressureTable, Confirm },
  props: {
    disableEdit: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      title: "Blood Pressure",
      loading: true,
    };
  },
  watch: {
    // todo: fix
    "$store.state.observations.bloodPressureTableOpened": {
      immediate: true,
      async handler(val) {
        if (val) {
          this.loading = true;
          await this.getBloodPressure();
          this.loading = false;
        }
      },
    },
  },
  computed: {
    ...mapState(useObservationsStore, ["bloodPressureTableOpened"]),
  },
  methods: {
    ...mapActions(useObservationsStore, ["toggleBloodPressureTableOpened"]),
    ...mapActions(useObservationsStore, ["getBloodPressure"]),
  },
};
</script>
<style lang="scss">
@import "../monitoring-drawer";
</style>
