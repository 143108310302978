import identity from "lodash/identity";
import pickBy from "lodash/pickBy";

import { apiRequest } from "@/api/apiRequest";

const getSummary = async (patientId: string) => {
  const { data } = await apiRequest.get(`/observations/summary`, { params: { patientId } });
  return data;
};

const getBloodPressure = async (patientId: string) => {
  const { data } = await apiRequest.get(`/observations/blood-pressure`, { params: { patientId } });
  return data;
};

const getBloodGlucose = async (patientId: string) => {
  const { data } = await apiRequest.get(`/observations/blood-glucose`, { params: { patientId } });
  return data;
};

const getRespiratoryRate = async (patientId: string) => {
  const { data } = await apiRequest.get(`/observations/respiratory-rate`, { params: { patientId } });
  return data;
};

const getBodyWeight = async (patientId: string) => {
  const { data } = await apiRequest.get(`/observations/body-weight`, { params: { patientId } });
  return data;
};

const getSmokingBehavior = async (patientId: string) => {
  const { data } = await apiRequest.get(`/observations/smoking-behavior`, { params: { patientId } });
  return data;
};
const getBodyHeight = async (patientId: string) => {
  const { data } = await apiRequest.get(`/observations/body-height`, { params: { patientId } });
  return data;
};

const getBodyTemperature = async (patientId: string) => {
  const { data } = await apiRequest.get(`/observations/body-temperature`, { params: { patientId } });
  return data;
};

const createBloodPressure = async (body: Record<string, any>) => {
  return new Promise((resolve, reject) => {
    apiRequest
      .post(`/observations/blood-pressure`, pickBy(body, identity))
      .then((resp) => {
        resolve(resp.data);
      })
      .catch((err) => {
        reject(err.response && err.response.data);
      });
  });
};

const createOxygenSaturation = async (body: Record<string, any>) => {
  return new Promise((resolve, reject) => {
    apiRequest
      .post(`/observations/oxygen-saturation`, pickBy(body, identity))
      .then((resp) => {
        resolve(resp.data);
      })
      .catch((err) => {
        reject(err.response && err.response.data);
      });
  });
};

const createRespiratoryRate = async (body: Record<string, any>) => {
  return new Promise((resolve, reject) => {
    apiRequest
      .post(`/observations/respiratory-rate`, pickBy(body, identity))
      .then((resp) => {
        resolve(resp.data);
      })
      .catch((err) => {
        reject(err.response && err.response.data);
      });
  });
};
const createBodyTemperature = async (body: Record<string, any>) => {
  return new Promise((resolve, reject) => {
    apiRequest
      .post(`/observations/body-temperature`, pickBy(body, identity))
      .then((resp) => {
        resolve(resp.data);
      })
      .catch((err) => {
        reject(err.response && err.response.data);
      });
  });
};

const getHeartRate = async (patientId: string) => {
  const { data } = await apiRequest.get(`/observations/heart-rate`, { params: { patientId } });
  return data;
};

const getOxygenSaturation = async (patientId: string) => {
  const { data } = await apiRequest.get(`/observations/oxygen-saturation`, { params: { patientId } });
  return data;
};

const createHeartRate = async (body: Record<string, any>) => {
  return new Promise((resolve, reject) => {
    apiRequest
      .post(`/observations/heart-rate`, pickBy(body, identity))
      .then((resp) => {
        resolve(resp.data);
      })
      .catch((err) => {
        reject(err.response && err.response.data);
      });
  });
};
const createBloodGlucose = async (body: Record<string, any>) => {
  return new Promise((resolve, reject) => {
    apiRequest
      .post(`/observations/blood-glucose`, pickBy(body, identity))
      .then((resp) => {
        resolve(resp.data);
      })
      .catch((err) => {
        reject(err.response && err.response.data);
      });
  });
};

const createBodyWeight = async (body: Record<string, any>) => {
  return new Promise((resolve, reject) => {
    apiRequest
      .post(`/observations/body-weight`, { ...body, value: parseFloat(body?.value || 0) })
      .then((resp) => {
        resolve(resp.data);
      })
      .catch((err) => {
        reject(err.response && err.response.data);
      });
  });
};
const createBodyHeight = async (body: Record<string, any>) => {
  return new Promise((resolve, reject) => {
    apiRequest
      .post(`/observations/body-height`, { ...body, value: parseFloat(body?.value || 0) })
      .then((resp) => {
        resolve(resp.data);
      })
      .catch((err) => {
        reject(err.response && err.response.data);
      });
  });
};
const createSmokingBehavior = async (body: Record<string, any>) => {
  return new Promise((resolve, reject) => {
    apiRequest
      .post(`/observations/smoking-behavior`, pickBy(body, identity))
      .then((resp) => {
        resolve(resp.data);
      })
      .catch((err) => {
        reject(err.response && err.response.data);
      });
  });
};
const create = async (body: Record<string, any>) => {
  return new Promise((resolve, reject) => {
    apiRequest
      .post(`/observations`, pickBy(body, identity))
      .then((resp) => {
        resolve(resp.data);
      })
      .catch((err) => {
        reject(err.response && err.response.data);
      });
  });
};
const getHistory = async (patientId: string, kind: string) => {
  const { data } = await apiRequest.get(`/observations/history`, { params: { patientId, kind } });
  return data;
};

const update = async (id: string, body: Record<string, any>) => {
  const { data } = await apiRequest.post(`/observations/${id}`, body);
  return data;
};

export const ObservationsApi = {
  getSummary,
  getSmokingBehavior,
  getBodyHeight,
  getBodyWeight,
  getBodyTemperature,
  getBloodGlucose,
  getRespiratoryRate,
  getOxygenSaturation,
  getBloodPressure,
  getHistory,
  getHeartRate,
  update,
  create,
  createSmokingBehavior,
  createBodyHeight,
  createBodyWeight,
  createBloodGlucose,
  createBloodPressure,
  createRespiratoryRate,
  createBodyTemperature,
  createOxygenSaturation,
  createHeartRate,
};
