<template>
  <div>
    <Confirm
      :confirmText="`${bodyWeightForm.id ? 'Change' : 'Add'}`"
      :dialog="bodyWeightFormOpened"
      :loading="loading"
      :title="`${bodyWeightForm.id ? 'Change Body Weight' : 'Add Body Weight'}`"
      cancelText="Cancel"
      maxWidth="90%"
      rootClass="order-form"
      text=""
      width="600px"
      @confirm="performStore"
      @toggleDialog="toggleBodyWeightFormOpened"
    >
      <v-container class="no-padding-bottom no-padding-top mb-3">
        <v-row>
          <v-col cols="3">
            <v-subheader> Body Weight</v-subheader>
          </v-col>
          <v-col class="inline-inputs" cols="9">
            <BaseInput
              :errors="validateField('value')"
              :hide-details="false"
              :value="bodyWeightForm.value"
              class="field-details-absolute"
              fieldName="value"
              placeholder="Enter"
              @change="onFieldChange"
            />
            <span class="text--secondary pl-1">lbs</span>
          </v-col>
          <v-col cols="3">
            <v-subheader> Date</v-subheader>
          </v-col>
          <v-col class="inline-inputs" cols="9">
            <DatePicker
              :dense="true"
              :errors="validateField('date')"
              :height="47"
              :hideDetails="true"
              :show-now="true"
              :value="bodyWeightForm.date"
              placeholder="Date"
              @setDate="onChangeDate"
            />
          </v-col>
        </v-row>
      </v-container>
    </Confirm>
  </div>
</template>
<script>
import { mapActions, mapState } from "pinia";

import { ObservationsApi } from "@/api/observations";
import Confirm from "@/components/shared/Confirm";
import DatePicker from "@/components/shared/DatePicker";
import BaseInput from "@/components/uikit/BaseInput";
import { BODY_WEIGHT_ADDED } from "@/eventBuses/monitoringEvents";
import { validatorErrors } from "@/mixins/validatorErrors.js";
import { AutoSaveVariants, useAutoSaveStore } from "@/pinia-store/autoSave";
import { useObservationsStore } from "@/pinia-store/observations";

export default {
  name: "AddBodyWeight",
  components: {
    DatePicker,
    BaseInput,
    Confirm,
  },
  mixins: [validatorErrors],
  data: () => ({
    loading: false,
  }),
  computed: {
    ...mapState(useObservationsStore, ["bodyWeightFormOpened", "bodyWeightForm", "patientId"]),
    isSubmitDisabled() {
      return Boolean(!this.date || !this.value || this.dateErrors.length || this.valueErrors.length);
    },
  },
  methods: {
    ...mapActions(useAutoSaveStore, ["startSave", "endSave"]),
    ...mapActions(useObservationsStore, ["toggleBodyWeightFormOpened", "changeBodyWeightFormFormField"]),
    onChangeTime(value) {
      this.changeBodyWeightFormFormField({ field: "time", value });
    },
    onFieldChange(value, fieldName) {
      this.changeBodyWeightFormFormField({ field: fieldName, value });
    },
    onChangeDate(value) {
      this.changeBodyWeightFormFormField({ field: "date", value });
    },
    async performStore() {
      try {
        this.loading = true;
        this.startSave();
        const { id, date, value } = this.bodyWeightForm;
        await ObservationsApi.createBodyWeight({
          patientId: this.patientId,
          id,
          date: this.$moment(date).format("YYYY-MM-DD"),
          value: parseFloat(value),
        });
        this.loading = false;
        this.toggleBodyWeightFormOpened();
        this.$root.$emit(BODY_WEIGHT_ADDED);
        this.endSave();
        this.$emit("onSave");
      } catch (err) {
        this.setResponseErrors(err);
        this.loading = false;
        await this.endSave({ state: AutoSaveVariants.error, endMessage: "Error" });
      }
    },
  },
};
</script>
<style lang="scss">
.order-form {
  .inline-inputs {
    flex-direction: row;
    display: flex;
    align-items: center;
  }

  .card-title {
    font-weight: 500 !important;
    font-size: 25px !important;
    line-height: 31px !important;
    color: #25233a;
  }

  .v-card__title + .v-card__text {
    font-size: 14px;
    line-height: 17px;
    color: #33343d;
  }
}
</style>
