<template>
  <div :class="{ 'fill-height': $vuetify.breakpoint.xsOnly }" class="dialog-container d-flex flex-column">
    <div class="d-flex justify-space-between full-width">
      <div class="dialog-container-title">{{ $t("chat.startConversationModal.title") }}</div>
      <v-btn class="close-btn" icon @click="close">
        <img alt="Close" src="@/assets/icons/close-light.svg" />
      </v-btn>
    </div>
    <div class="full-width">
      <div v-if="userIsRegistrar" class="dialog-container-text mt-6 mb-3">
        {{ $t("chat.startConversationModal.choosePractitioner") }}
      </div>
      <div v-if="userIsPractitioner" class="dialog-container-text mt-6 mb-3">
        {{ $t("chat.startConversationModal.choosePatient") }}
      </div>
    </div>
    <div class="mb-4">
      <v-autocomplete
        v-model="participantId"
        :disabled="usersLoading"
        :filter="customFilter"
        :items="participantOptions"
        :loading="usersLoading"
        append-icon
        dense
        height="46"
        hide-details
        outlined
        single-line
      >
        <template v-slot:selection="data">
          <v-chip color="white">
            <Avatar :src="data.item.photoURL" :userId="data.item.value" class="mr-2" />
            {{ data.item.name }}
          </v-chip>
        </template>
        <template v-slot:item="data">
          <template>
            <v-list-item-avatar>
              <Avatar :src="data.item.photoURL" :userId="data.item.value" />
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>{{ data.item.name }}</v-list-item-title>
            </v-list-item-content>
          </template>
        </template>
      </v-autocomplete>
    </div>
    <div>
      <v-btn :disabled="isDisabled" :loading="finishLoading" block color="primary" depressed @click="finish">
        {{ $t("chat.startConversationModal.start") }}
      </v-btn>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "pinia";

import { PatientsApi } from "@/api/patients";
import Avatar from "@/components/Avatar/Index.vue";
import { snackBarEventBus, snackBarEventName } from "@/eventBuses/snackBar.eventBus";
import { useAuthStore } from "@/pinia-store/auth";
import { useChatStore } from "@/pinia-store/chat";
import { usePatientStore } from "@/pinia-store/patient";
import { usePatientsStore } from "@/pinia-store/patients";
import { useProfileSettingsStore } from "@/pinia-store/profileSettings";
import { useRegistrarsStore } from "@/pinia-store/registrars";

import DialogNamesEnum from "./Enums/dialogNamesEnum";

export default {
  name: "StartNewConversation",
  components: {
    Avatar,
  },
  inject: ["injectable"],
  data() {
    return {
      twilioContext: this.injectable.twilioContext,
      participantId: null,
      finishLoading: false,
      usersLoading: false,
      storeLoaded: false,
      practitionersFullData: [],
      registrarChatParticipants: [],
    };
  },
  computed: {
    ...mapState(useAuthStore, [
      "uid",
      "timeZone",
      "role",
      "userIsPatient",
      "userIsPractitioner",
      "userIsRegistrar",
      "userIsAdmin",
    ]),
    ...mapState(usePatientStore, ["patient"]),
    ...mapState(useChatStore, ["startConversationParticipants"]),
    ...mapState(usePatientsStore, ["practitionerOwnPatients"]),
    ...mapState(useRegistrarsStore, ["registrarPractitioners"]),
    ...mapState(useProfileSettingsStore, ["patientSettings", "practitionerSettings"]),
    isDisabled() {
      return !(this.participantId && !this.usersLoading);
    },
    participantOptions() {
      if (this.userIsRegistrar) {
        return this.registrarChatParticipantsData;
      } else {
        return this.startConversationParticipants.map((user) => ({
          name: user.displayName,
          photoURL: user.photoURL,
          value: user.uid,
        }));
      }
    },
    patients() {
      return this.practitionerOwnPatients.map(({ firstName, lastName, id, photoURL }) => ({
        name: `${firstName} ${lastName}`,
        photoURL: photoURL,
        value: id,
      }));
    },
    registrarChatParticipantsData() {
      return this.registrarChatParticipants.map((practitioner) => ({
        name: `${practitioner.firstName} ${practitioner.lastName}`,
        photoURL: practitioner.photoURL,
        value: practitioner.id,
      }));
    },
    userProfile() {
      if (this.userIsPractitioner) {
        return this.practitionerSettings;
      }
      return this.patientSettings;
    },
    participantProfile() {
      if (this.userIsPractitioner) {
        return this.startConversationParticipants.find((patient) => patient.id === this.participantId);
      } else return null;
    },
  },

  methods: {
    ...mapActions(usePatientsStore, ["getAllByPractitionerId"]),
    ...mapActions(usePatientStore, ["getPatientProfile"]),
    ...mapActions(useChatStore, ["getStartConversationParticipants", "setCurrentGroup"]),
    ...mapActions(useProfileSettingsStore, ["getPatientSettings", "getPractitionerSettings"]),
    ...mapActions(useRegistrarsStore, ["getRegistrarPractitioners"]),
    close() {
      this.checkbox = false;
      this.chiefComplaint = null;
      this.$emit("close");
    },
    customFilter(item, queryText) {
      const name = (item?.name || "").toLowerCase();
      const searchText = queryText.toLowerCase();
      return name.indexOf(searchText) > -1;
    },
    async finish() {
      this.finishLoading = true;
      const chatCreateRequest = {
        userTimeZone: this.timeZone,
        participantProfile: this.startConversationParticipants.find((i) => this.participantId === i.uid),
        isFloatingChat: false,
        dialogType: DialogNamesEnum.MedicalDialog,
        userProfile: this.userProfile,
        isAnonymous: false,
      };
      await this.injectable.twilioContext.createNewConversation(chatCreateRequest);
      this.finishLoading = false;
      this.setCurrentGroup("active");
      this.close();
    },
    async medicalInformationLink() {
      const routeData = this.$router.resolve({ name: "patientSettings" });
      window.open(routeData.href, "_blank");
    },
  },
  async created() {
    this.usersLoading = true;
    try {
      if (this.userIsPractitioner) {
        await this.getPractitionerSettings();
        await this.getStartConversationParticipants();
      } else if (this.userIsRegistrar) {
        await this.getRegistrarPractitioners(this.uid);
        this.registrarChatParticipants = this.registrarPractitioners;
        const patients = await Promise.all(
          this.registrarPractitioners.map(async (practitioner) => {
            return await PatientsApi.getAllByPractitionerId(practitioner.id);
          }),
        );
        patients.forEach((item) => {
          this.registrarChatParticipants.push(...item);
        });
      } else {
        snackBarEventBus.$emit(snackBarEventName, {
          message: "User role is not appropriate for that operation!",
          type: "error",
        });
      }
    } catch (err) {
      console.error(err);
      snackBarEventBus.$emit(snackBarEventName, { message: err.message || err, type: "error" });
    } finally {
      this.usersLoading = false;
    }
  },
};
</script>

<style lang="scss" scoped>
.dialog-container {
  background-color: white;
  padding: 30px;
  position: relative;

  .close-btn {
    padding: 0;
    width: 26px;
    height: 26px;
    position: absolute !important;
    top: 13px;
    right: 13px;
  }

  &-title {
    font-weight: 500;
    font-size: 16px;
    color: $primaryblack;
  }

  &-text {
    font-weight: 500;
    font-size: 14px;
    color: $primaryblack2;
    mix-blend-mode: normal;
    opacity: 0.88;
  }

  &-subtext {
    font-size: 13px;
    color: $primaryblack2;
    mix-blend-mode: normal;
    opacity: 0.88;
  }

  &-chief {
    height: 46px;

    &-wd {
      width: 120px;
    }

    &-select {
      width: 70%;
    }
  }

  &-link {
    font-weight: 500;
    color: $primary;
    cursor: pointer;
  }

  :deep .v-btn__content {
    letter-spacing: 0;
    text-transform: capitalize;
  }
}
</style>
