import { LastMessage, Message } from "@twilio/conversations";
import { DateTime } from "luxon";
import * as lz from "lzutf8";
import { Observable, Subject } from "rxjs";
import { shareReplay } from "rxjs/operators";

import { IParticipantsRepository } from "@/context/twilioContext/ChatConversation/IParticipantsRepository";
import ParticipantsRepository from "@/context/twilioContext/ChatConversation/ParticipantsRepository";
import { ITwilioMessage } from "@/context/twilioContext/interfaces/ITwilioMessage";
import ChatUser from "@/context/twilioContext/TwilioChatMember/ChatUser";
import { TwilioChatMessageAttributes } from "@/context/twilioContext/types/TwilioChatMessageAttributes";
import { ChatMessageType } from "@/types/ChatMessage";

export type MessagesRepositoryStatus = "loading" | "loaded" | "error";

class MessagesRepository implements IMessagesRepository {
  private readonly uid: string;
  private _members?: ChatUser[];
  private readonly _repositoryStatus$ = new Subject<MessagesRepositoryStatus>();
  private readonly _messages: ITwilioMessage[] = [];

  constructor(uid: string, members?: ChatUser[]) {
    this.uid = uid;
    this._members = members ?? [];
  }

  get repositoryStatus$(): Observable<MessagesRepositoryStatus> {
    return this._repositoryStatus$.pipe(shareReplay(1));
  }

  get lastMessageMeta(): LastMessage {
    return {
      dateCreated: this.lastMessageInstance?.dateCreated ? new Date(this.lastMessageInstance.dateCreated) : undefined,
      index: this.lastMessageInstance?.index,
    };
  }

  get lastMessageInstance(): ITwilioMessage {
    return this._messages[this._messages.length - 1];
  }

  public update(membersRepository: ParticipantsRepository): MessagesRepository {
    this._members = membersRepository.listParticipants();
    return this;
  }

  public addMessage(rawMessage: Message): void {
    if (!this._members) throw new Error("This method is unavailable for this type of conversation.");
    const message = this.getTwilioMessage(rawMessage);
    this._messages.push(message);
  }

  public addITwilioMessage(message: ITwilioMessage): void {
    if (!this._members) throw new Error("This method is unavailable for this type of conversation.");
    this._messages.push(message);
  }

  public addDummyMessage(
    body: string,
    fromBot: boolean,
    attributes?: TwilioChatMessageAttributes,
    authorIdentity?: string,
  ): void {
    const dummyMessage: ITwilioMessage = {
      author: fromBot
        ? process.env.VUE_APP_BOT_UID
        : authorIdentity
        ? authorIdentity
        : process.env.VUE_APP_TWILIO_ANONYMOUS_USERNAME,
      body,
      index: -9999 + this._messages.length,
      dateCreated: new Date(Date.now()).toISOString(),
      type: "text",
      attributes: {
        isHidden: false,
        msgType: "BaseMessage",
        meta: null,
        ...attributes,
      },
    } as ITwilioMessage;
    this._messages.push(dummyMessage);
  }

  public listMessages(): ITwilioMessage[] {
    return this._messages;
  }

  public removeMessage(msgId: string): void {
    this._messages.find((msg) => msg.sid === msgId);
  }

  public updateMessage(updatedMessage: Message): void {
    const foundMsgIndex = this._messages.findIndex((message) => message.sid === updatedMessage.sid);
    if (foundMsgIndex) {
      this._messages[foundMsgIndex] = this.getTwilioMessage(updatedMessage);
    }
  }

  private getTwilioMessage(message: Message): ITwilioMessage {
    if (this._members === undefined) throw new Error("This method is unavailable for this type of conversation.");
    const msgAttributes = (message?.attributes || {}) as TwilioChatMessageAttributes;
    const creationDateTime = message.dateCreated ? DateTime.fromJSDate(message.dateCreated).toFormat("t") : "unknown";

    if (message.author === "system")
      return {
        sid: message.sid,
        author: message.author,
        body: message.body,
        dateUpdated: message.dateUpdated?.toISOString() ?? null,
        index: message.index,
        lastUpdatedBy: message.lastUpdatedBy,
        dateCreated: message.dateCreated?.toISOString() ?? null,
        type: message.type,
        media: message.attachedMedia,
        memberSid: message.sid,
        attributes: msgAttributes,
        creationDateTime,
        my: false,
        authorMeta: null,
      };

    let msgType = msgAttributes?.msgType ? msgAttributes.msgType : ChatMessageType.BaseMessage;
    let meta = null;

    const chatMember = this._members.find((member) => member.uid === message.author);
    const my = this.uid === message.author || chatMember?.displayName === "Anonymous";
    const messageAuthor = my ? null : chatMember?.meta;

    const attributesKeys = Object.keys(msgAttributes);
    if (msgAttributes && attributesKeys.includes("meta") && attributesKeys.includes("msgType")) {
      msgType = msgAttributes.msgType;
      const decompressedMetaString = lz.decompress(msgAttributes.meta, {
        inputEncoding: "BinaryString",
        outputEncoding: "String",
      });
      meta = JSON.parse(decompressedMetaString);
    }
    return {
      sid: message.sid,
      author: message.author,
      body: message.body,
      dateUpdated: message.dateUpdated?.toISOString() ?? null,
      index: message.index,
      lastUpdatedBy: message.lastUpdatedBy,
      dateCreated: message.dateCreated?.toISOString() ?? null,
      type: message.type,
      media: message.attachedMedia,
      memberSid: message.sid,
      attributes: {
        ...msgAttributes,
        meta,
        msgType,
      },
      creationDateTime,
      my,
      authorMeta: messageAuthor,
    };
  }
}

export interface IMessagesRepository {
  lastMessageInstance: ITwilioMessage;
  lastMessageMeta: LastMessage;
  repositoryStatus$: Observable<MessagesRepositoryStatus>;

  update(membersRepository: IParticipantsRepository): MessagesRepository;

  addMessage(rawMessage: Message): void;

  addDummyMessage(
    body: string,
    fromBot: boolean,
    attributes?: TwilioChatMessageAttributes,
    authorIdentity?: string,
  ): void;

  removeMessage(msgId: string): void;

  listMessages(): ITwilioMessage[];

  updateMessage(updatedMessage: Message): void;

  updateMessage(message: Message): void;
}

export default MessagesRepository;
