export const WS_NOTIFICATION = "notification";
export const WS_MEETING_ROOMS_ADDED = "WS_MEETING_ROOMS_ADDED";
export const WS_MEETING_ROOMS_UPDATE = "WS_MEETING_ROOMS_UPDATE";
export const WS_MEETING_ROOMS_JOINED = "WS_MEETING_ROOMS_JOINED";
export const WS_SLOTS_ON_HOLD_UPDATE = "WS_SLOTS_ON_HOLD_UPDATE";
export const WS_MAINTENANCE = "WS_MAINTENANCE";
export const WS_TASKS_UPDATED = "WS_TASKS_UPDATED";
export const WS_TASKS_CREATED = "WS_TASKS_CREATED";
export const WS_TASKS_NOTIFY_WAITING_COUNT = "WS_TASKS_NOTIFY_WAITING_COUNT";
export const UPDATE_CHAT_VISIT_NOTE_FIELD = "update:chatVisitNote:field";
export const INIT_CHAT_VISIT_NOTE_FIELD = "init:chatVisitNote:field";
export const WS_USER_DISCONNECTED = "WS_USER_DISCONNECTED";
