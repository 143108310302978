<template>
  <div>
    <OwnerSettings />
  </div>
</template>

<script>
import OwnerSettings from "@/components/settings/owner";
export default {
  name: "ownerSettings",
  components: {
    OwnerSettings,
  },
};
</script>
