<template>
  <div class="full-width slide-y fill-height floating-channel-list">
    <ChannelItem
      v-for="channel in channelsList"
      :key="channel.channelSid"
      :active="channel.sid === selectedChannelSid"
      :channel="channel"
      v-on:click.native="selectChannel(channel)"
    />
  </div>
</template>

<script>
import { mapActions, mapState } from "pinia";

import ChannelItem from "@/components/Chat/ChannelsList/ChannelItem.vue";
import { FloatingChatTabs, useChatStore } from "@/pinia-store/chat";

export default {
  inject: ["injectable"],
  data: () => {
    return {
      channelsList: [],
    };
  },
  async mounted() {
    if (this.injectable.twilioContext) {
      this.channelsList = this.injectable.twilioContext.channelRepository.getTicketChannels();
    }
  },
  name: "FloatingChannelList",
  components: {
    ChannelItem,
  },
  methods: {
    ...mapActions(useChatStore, ["setFloatingChatTab"]),
    selectChannel(channel) {
      if (channel.sid !== this.selectedChannelSid) {
        this.injectable.twilioContext.setCurrentFloatingChannel(channel);
        channel.consumeMessages();
      }
      this.setFloatingChatTab(FloatingChatTabs.channel);
    },
  },
  computed: {
    ...mapState(useChatStore, ["getCurrentFloatingChannel"]),
    selectedChannelSid() {
      return this.getCurrentFloatingChannel?.sid;
    },
  },
};
</script>

<style lang="scss" scoped>
.open-in-new-button {
  width: 25px;
  height: 25px;
  position: relative;
  margin: 13px;

  &-icon {
  }
}

.floating-channel-list {
  height: calc(100% - 50px);
  overflow-x: auto;
}

.floating-chat {
  border-bottom: 1px solid #efeff0;
  border-top: 1px solid #efeff0;
  overflow-y: scroll;
  overflow-x: hidden;
  padding: 0 22px 22px 22px;
  scroll-padding: 22px;
  flex-grow: 1;
  height: 100%;
}

.input {
  padding: 10px;
  min-height: 61px;

  &-body {
    border: 1px solid #d2d3e1;
    box-sizing: border-box;
    border-radius: 5px;
    overflow: hidden;

    &-textarea {
      margin-bottom: -25px;
    }

    &-actions {
      width: 80px;

      &-buttons {
        margin-bottom: 2px;
      }
    }
  }
}

.hidden {
  display: none !important;
}

.button-wrapper {
  display: flex;
  justify-content: flex-end;
  flex-flow: row-reverse nowrap;
}

.infobox {
  align-self: flex-end;
  position: absolute;
  display: flex;
  right: 330px;
  bottom: 570px;
  flex-flow: row nowrap;
  justify-content: center;
  background: rgba(32, 33, 34, 0.7);
  border-radius: 4px;

  &-text {
    align-self: center;
    margin: 4px 6px 5px 6px;
    color: white;
    white-space: nowrap;

    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
  }
}
</style>
