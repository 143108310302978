<template>
  <div class="wrapper">
    <v-data-table
      class="visit-summary_med-info_table"
      disable-sort
      hide-default-footer
      :headers="headers"
      :items="items"
    >
    </v-data-table>
  </div>
</template>
<script>
export default {
  name: "VisitSummaryTable",
  props: {
    title: String,
    headers: Array,
    items: Array,
  },
};
</script>
