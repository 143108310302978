<template>
  <div class="action-field">
    <div class="title-text">
      {{ title }}
    </div>
    <div class="right-block" v-for="value in valueItems" :key="value">
      <div class="action-btn value">
        {{ value }}
        <div v-if="unit" class="unit">
          {{ unit }}
        </div>
      </div>
      <v-btn v-if="showAdd" @click="onAdd" :ripple="false" :width="30" class="action-btn fixed add" outlined>
        <img src="@/assets/icons/add.svg" />
      </v-btn>
      <v-btn v-if="showMore" @click="onView" :ripple="false" :width="30" class="action-btn fixed" outlined>
        <img src="@/assets/icons/textBox.svg" />
      </v-btn>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    showAdd: {
      default: true,
      type: Boolean,
    },
    showMore: {
      default: true,
      type: Boolean,
    },
    title: {
      default: "",
      type: [String, Number],
    },
    unit: {
      default: "",
      type: [String, Number],
    },
    value: {
      default: "",
      type: [String, Number, Array],
    },
  },
  computed: {
    valueItems() {
      return Array.isArray(this.value) ? this.value : [this.value];
    },
  },
  methods: {
    onAdd() {
      this.$emit("onAdd");
    },
    onView() {
      this.$emit("onView");
    },
  },
  name: "ActionField",
};
</script>

<style lang="scss" scoped>
.action-field {
  display: flex;
  flex-basis: 100%;
  align-items: flex-start;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 16px;
  margin-bottom: 16px;
  .title-text {
    font-style: normal;
    line-height: 17px;
    color: #33343e;
    font-weight: 500;
    font-size: 14px;
  }
  .right-block {
    flex-shrink: 0;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 16px;
  }
  .action-btn {
    height: 30px;
    border-radius: 4px;
    border: none;
    min-width: auto;
    font-size: 12px;
    display: flex;
    align-items: center;
    &.fixed {
      width: 30px;
      background: #eff6ff;
      align-items: center;
      align-self: center;
      display: flex;
      font-weight: 700;
    }
    &.value {
      flex-grow: 1;
      display: flex;
      justify-content: center;
      font-weight: bold;
      font-style: normal;
      font-size: 12px;
      line-height: 15px;
      align-items: center;
      text-align: center;
      letter-spacing: 0.32px;
      color: #33343e;
      padding: 8px;
      gap: 10px;
      background: #eeeff7;
      border-radius: 4px;
      .unit {
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 15px;
        align-items: center;
        text-align: center;
        letter-spacing: 0.32px;
        color: rgba(51, 52, 62, 0.5);
        margin-left: 4px;
      }
    }
    img {
      height: 18px;
    }
    &.add {
      img {
        height: 10px;
      }
    }
  }
}
</style>
