<template>
  <div class="white">
    <div v-if="encounters.length" class="block-separator">
      <hr class="hr-separator" />
      <PractitionerEncounterVisitHistory />
    </div>
    <div class="block-separator">
      <hr class="hr-separator" />
      <VisitNotes :editable="editable" />
    </div>
    <div class="block-separator">
      <hr class="hr-separator" />
      <MedicationReconciliation :editable="editable" />
    </div>
  </div>
</template>
<script>
import { mapActions, mapState } from "pinia";

import VisitNotes from "@/components/VisitNotes";
import MedicationReconciliation from "@/components/VisitNotes/medicationReconsiliation/MedicationReconciliation";
import { useAllergiesStore } from "@/pinia-store/allergies";
import { useEncountersStore } from "@/pinia-store/encounters";
import { usePastVisitStore } from "@/pinia-store/pastVisit";
import { useVisitNoteStore } from "@/pinia-store/visitNote";
import { EncounterStatusEnum } from "@/types/EncounterStatusEnum";
import { tableHeaders } from "@/utils/tableHeaders.ts";

import PractitionerEncounterVisitHistory from "./PractitionerEncounterVisitHistory";

export default {
  name: "PractitionerEncounterMedicationReview",
  components: {
    MedicationReconciliation,
    PractitionerEncounterVisitHistory,
    VisitNotes,
  },
  props: {
    editable: {
      default: true,
      type: Boolean,
    },
  },
  data: () => ({
    ...tableHeaders(),
    fileRecords: [],
    medicalTab: 0,
    uploadHeaders: { "X-Test-Header": "vue-file-agent" },
    fileRecordsForUpload: [],
  }),
  computed: {
    ...mapState(useVisitNoteStore, ["medicalInfo", "patientId", "otherVisitNotes"]),
    ...mapState(usePastVisitStore, ["getFile"]),
    ...mapState(useEncountersStore, ["pagination", "encounters"]),
  },
  methods: {
    ...mapActions(useAllergiesStore, ["getPatientAllergies"]),
    ...mapActions(useEncountersStore, ["getAllEncounters"]),
    ...mapActions(usePastVisitStore, ["setFile"]),
  },

  async mounted() {
    const visitNoteStore = useVisitNoteStore();

    visitNoteStore.$subscribe((state) => {
      if (state.patientId) {
        this.getPatientAllergies(state.patientId);
        this.getAllEncounters({
          patientId: state.patientId,
          past: true,
          status: [EncounterStatusEnum.finished, EncounterStatusEnum.onleave],
          _count: 4,
          _page_token: this.pagination.nextPageToken,
        });
      }
    });
  },
};
</script>
