<template>
  <div class="visit-room">
    <v-progress-linear v-if="loading" color="primary" indeterminate />
    <div v-if="encounter && !encounterIsCancelled">
      <div class="visit-room-header">
        <v-chip v-if="roomIsStarted && !roomIsFinished && timer" color="red darken-1" dark label>
          {{ timer }}
        </v-chip>

        <span class="visit-room-header-select">{{ $t("visits.visitRoomWith") }} {{ encounter.patient.name }}</span>
        <div class="visit-room-header-bordered">
          <v-icon color="icon">mdi-calendar-blank</v-icon>
          <p>{{ dateFormated }}</p>
        </div>
        <div class="visit-room-header-bordered">
          <v-icon color="icon">mdi-clock-outline</v-icon>
          <p>{{ timeRange }}</p>
        </div>
      </div>
      <div class="tabs-router">
        <router-link :to="{ name: 'PractitionerEncounterReview', params: { encounterId } }" exact-active-class="active"
          >1. Chart review
        </router-link>
        <router-link
          :to="{ name: 'PractitionerEncounterMedication', params: { encounterId } }"
          exact-active-class="active"
        >
          2. Encounter
        </router-link>
      </div>
      <v-row class="visit-room-content relative" no-gutters>
        <v-col cols="12" lg="8" md="8" sm="12" xl="8">
          <div class="video-room-wrapper-root">
            <VideoRoom
              v-if="!roomIsFinished && room && encounter.virtualService !== 'callback'"
              :call-time="timer"
              class="visit-room-content-video"
            />
            <div v-if="!roomIsFinished && room && encounter.virtualService === 'callback'" class="callback-wrap">
              <p class="callback">Patient requested a Phone Call consult</p>

              <v-btn
                v-if="isPractitioner && !roomIsReady"
                class="buttons-start"
                color="success"
                dark
                depressed
                @click="startRoom"
              >
                {{ $t("visits.waiting.startVisit") }}
              </v-btn>
              <v-btn
                v-if="isPractitioner && roomIsStarted"
                class="buttons-start"
                color="success"
                dark
                depressed
                rounded
                @click="finishRoom"
              >
                Finish visit
              </v-btn>
            </div>
          </div>
          <router-view></router-view>
        </v-col>
        <v-col cols="12" lg="4" md="4" sm="12" xl="4">
          <VisitDescription />
        </v-col>
        <PreviewSignedVisitNote />
        <PractitionerEncounterOrder v-if="showOrder" />
      </v-row>
    </div>
    <ExpiredEncounter v-if="encounterIsCancelled" />
  </div>
</template>
<script>
import { mapActions, mapState } from "pinia";

import VideoRoom from "@/components/VideoRoom";
import VisitDescription from "@/components/VisitDescription/Practitioner";
import PractitionerEncounterOrder from "@/components/VisitNotes/medicationOrder/PractitionerEncounterOrder";
import ExpiredEncounter from "@/components/VisitSummary/ExpiredEncounter.vue";
import { visitRoomMixin } from "@/mixins/visitRoom";
import { useAllergiesStore } from "@/pinia-store/allergies";
import { useAuthStore } from "@/pinia-store/auth";
import { useEncountersStore } from "@/pinia-store/encounters";
import { useVideoRoomStore } from "@/pinia-store/videoRoom";
import { useVisitNoteStore } from "@/pinia-store/visitNote";
import { EncounterStatusEnum } from "@/types/EncounterStatusEnum";
import { RolesEnum } from "@/types/Roles.enum";
import { routesEnum } from "@/types/Routes.enum";
import PreviewSignedVisitNote from "@/views/Practitioner/SignedVisit/PreviewSignedVisitNote";

export default {
  components: {
    ExpiredEncounter,
    PreviewSignedVisitNote,
    PractitionerEncounterOrder,
    VideoRoom,
    VisitDescription,
  },
  mixins: [visitRoomMixin],
  data() {
    return {
      notesMode: "notes",
      innerLoading: false,
    };
  },
  computed: {
    ...mapState(useEncountersStore, ["currentEncounter"]),
    ...mapState(useVideoRoomStore, ["room", "roomIsFinished", "roomIsStarted", "roomIsReady"]),
    ...mapState(useVisitNoteStore, ["status", "patientInfo", "medicationStatement", "encounterNotes"]),
    ...mapState(useAllergiesStore, ["patientAllergies"]),
    ...mapState(useAuthStore, ["role"]),
    encounterId() {
      return this.$route.params?.encounterId;
    },
    showOrder() {
      return this.$route.name === routesEnum.practitionerEncounterMedication;
    },
    isPractitioner() {
      return this.role === RolesEnum.Practitioner;
    },
    encounterIsCancelled() {
      return this.currentEncounter?.status === EncounterStatusEnum.cancelled;
    },
  },

  methods: {
    ...mapActions(useVideoRoomStore, ["startRoom", "finishRoom"]),
    ...mapActions(useVisitNoteStore, ["getVisitNote"]),
    ...mapActions(useEncountersStore, ["getCurrentEncounterById"]),
    ...mapActions(useVisitNoteStore, ["setAppointmentId", "setEncounterId", "clearEncounterNotes"]),
    ...mapActions(useVisitNoteStore, ["getVisitSummary"]),
    changeNotesMode(newMode) {
      this.notesMode = newMode;
    },
    async previewDraft() {
      this.notesMode = "summary";
    },
    async fetchVisitNote() {
      try {
        const { encounterId } = this.$route.params;
        await this.getCurrentEncounterById(encounterId);
        const appointmentId = this.currentEncounter && this.currentEncounter.appointmentId;
        this.setAppointmentId(appointmentId);
        this.setEncounterId(this.currentEncounter.id);
        await this.getVisitNote(encounterId);
      } catch (err) {
        console.error(err, "ERR");
      }
    },
  },
  async mounted() {
    const encounterStore = useEncountersStore();
    encounterStore.$subscribe((state, mutation) => {
      console.info("Encounter store", state, mutation);
    });
    this.clearEncounterNotes();
    await this.fetchVisitNote();
  },
  beforeDestroy() {
    this.clearEncounterNotes();
  },
  watch: {
    encounter: {
      immediate: true,
      async handler(val) {
        if (val && val?.status === "finished") await this.$router.push({ name: routesEnum.practitionerVisitNote });
      },
    },
  },
};
</script>

<style lang="scss">
@import "call";

.tabs-router {
  border-bottom: 1px solid #e7e8f2;
  position: sticky;
  top: 0;
  z-index: 3;

  a:first-child {
    border-right: 1px solid #e7e8f2;
  }
}

.video-room-wrapper-root {
  height: max-content;

  .visit-room-content {
    background: $white-light-1;

    &-video {
      max-height: calc(100vh - 63px);
      min-height: 240px;
      margin-left: 12px;
      margin-right: 12px;
    }
  }
}

.callback-wrap {
  padding-top: 40px;
  padding-bottom: 40px;
  padding-left: 16px;
  padding-right: 16px;
}

.callback {
  align-self: center;
  font-size: 22px;
  font-weight: 500;
}
</style>
