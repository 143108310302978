<template>
  <div>
    <Confirm
      :confirmText="`${smokingBehaviorForm.id ? 'Change' : 'Add'}`"
      :dialog="smokingBehaviorFormOpened"
      :loading="loading"
      :title="`${smokingBehaviorForm.id ? 'Change Smoking Behavior' : 'Add Smoking Behavior'}`"
      cancelText="Cancel"
      maxWidth="90%"
      rootClass="order-form"
      text=""
      width="600px"
      @confirm="performStore"
      @toggleDialog="toggleSmokingBehaviorFormOpened"
    >
      <v-container class="no-padding-bottom no-padding-top mb-3">
        <v-row>
          <v-col cols="12">
            <Select
              :hideDetails="true"
              :items="options"
              :label="$t('settings.profile.smokingStatus')"
              :value="selectedOption"
              class="select"
              item-text="name"
              item-value="id"
              @change="onSelect"
            />
          </v-col>
          <v-col class="inline-inputs" cols="12">
            <DatePicker
              :dense="true"
              :errors="validateField('date')"
              :height="47"
              :hideDetails="true"
              :show-now="true"
              :value="smokingBehaviorForm.date"
              placeholder="Date"
              @setDate="onChangeDate"
            />
          </v-col>
        </v-row>
      </v-container>
    </Confirm>
  </div>
</template>
<script>
import { mapActions, mapState } from "pinia";

import { ObservationsApi } from "@/api/observations";
import { timeISO8601OFormat } from "@/components/Clinicians/constants";
import Confirm from "@/components/shared/Confirm";
import DatePicker from "@/components/shared/DatePicker";
import Select from "@/components/uikit/Select";
import { SMOKING_BEHAVIOR_ADDED } from "@/eventBuses/monitoringEvents";
import { validatorErrors } from "@/mixins/validatorErrors.js";
import { useAutoSaveStore } from "@/pinia-store/autoSave";
import { useObservationsStore } from "@/pinia-store/observations";
import { useTerminologyStore } from "@/pinia-store/terminology";

export default {
  name: "AddSmokingBehavior",
  components: {
    DatePicker,
    Confirm,
    Select,
  },
  mixins: [validatorErrors],
  data: () => ({
    loading: false,
  }),
  computed: {
    ...mapState(useTerminologyStore, { options: "smokingBehavior" }),
    ...mapState(useObservationsStore, ["smokingBehaviorFormOpened", "smokingBehaviorForm", "patientId"]),
    isSubmitDisabled() {
      return Boolean(!this.date || !this.value || this.dateErrors.length || this.valueErrors.length);
    },
    selectedOption() {
      const index = this.options.findIndex && this.options.findIndex((i) => i.code === this.smokingBehaviorForm?.code);
      return index !== -1 ? parseInt(this.options[index].id) : "";
    },
  },
  methods: {
    ...mapActions(useAutoSaveStore, ["startSave", "endSave"]),
    ...mapActions(useTerminologyStore, ["preloadSmokingBehavior"]),
    ...mapActions(useObservationsStore, ["toggleSmokingBehaviorFormOpened", "changeSmokingBehaviorFormFormField"]),
    onSelect(value) {
      const index = this.options.findIndex((i) => i.id === value);
      if (index !== -1) {
        this.changeSmokingBehaviorFormFormField({ field: "name", value: this.options[index].name });
        this.changeSmokingBehaviorFormFormField({ field: "code", value: this.options[index].code });
      }
    },
    onChangeDate(value) {
      this.changeSmokingBehaviorFormFormField({ field: "date", value });
    },
    async performStore() {
      try {
        this.loading = true;
        this.startSave();
        const { id, date, code, name } = this.smokingBehaviorForm;
        await ObservationsApi.createSmokingBehavior({
          patientId: this.patientId,
          id,
          date: this.$moment(date).format(timeISO8601OFormat),
          name,
          code,
        });
        this.loading = false;
        this.toggleSmokingBehaviorFormOpened();
        this.$root.$emit(SMOKING_BEHAVIOR_ADDED);
        this.endSave();
        this.$emit("onSave");
      } catch (err) {
        this.setResponseErrors(err);
        this.loading = false;
        this.endSave();
      }
    },
  },
  async mounted() {
    await this.preloadSmokingBehavior();
  },
};
</script>
<style lang="scss">
.order-form {
  .inline-inputs {
    flex-direction: row;
    display: flex;
    align-items: center;
  }

  .card-title {
    font-weight: 500 !important;
    font-size: 25px !important;
    line-height: 31px !important;
    color: #25233a;
  }

  .v-card__title + .v-card__text {
    font-size: 14px;
    line-height: 17px;
    color: #33343d;
  }
}
</style>
