
import Vue from "vue";

import BaseMessage from "@/components/Chat/Message/MessageTypes/BaseMessage.vue";

export default Vue.extend({
  name: "ChatLogMessageWithCheckList",
  components: {
    BaseMessage,
  },
  props: {
    message: Object,
    responseDelegate: {
      type: Function,
      required: true,
    },
  },
  data: () => ({
    value: "",
    options: "",
  }),
  methods: {
    async sendResponse(): Promise<void> {
      this.value = Object.keys(this.options)
        .filter((key: any) => this.options[key])
        .join(", ");
      return this.responseDelegate(this.value);
    },
  },
});
