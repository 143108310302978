<template>
  <div class="medication-edit">
    <v-row>
      <v-col cols="3">
        <v-subheader> Medication</v-subheader>
      </v-col>
      <v-col cols="9">
        <h3>{{ name }}</h3>
      </v-col>
      <v-col cols="3">
        <v-subheader> Effective Date</v-subheader>
      </v-col>
      <v-col cols="9">
        <DatePicker
          :dense="true"
          :height="47"
          :hideDetails="true"
          :placeholder="$t('general.inputs.date')"
          :value="startDate"
          @setDate="onChangeDate"
        />
      </v-col>
      <v-col cols="3">
        <v-subheader> Patient Directions *</v-subheader>
      </v-col>
      <v-col cols="9">
        <Textarea
          :hideDetails="true"
          :placeholder="$t('visits.schedule.first.visitReason')"
          :value="patientInstruction"
          @change="onNoteChange"
        />
      </v-col>
      <v-col cols="3">
        <v-subheader> Quantity</v-subheader>
      </v-col>
      <v-col cols="3">
        <BaseInput
          :value="quantity"
          fieldName="quantity"
          label="Enter your blood glucose values"
          @change="onFieldChange"
        />
      </v-col>
      <v-col cols="3">
        <v-subheader>Dispense Unit *</v-subheader>
      </v-col>
      <v-col cols="3">
        <Select
          :hideDetails="true"
          :items="dispenseUnits"
          :value="dispenseUnitId"
          item-text="name"
          item-value="id"
          label="Dispense Unit"
          @change="onChangeDispenseUnitId"
        />
      </v-col>
      <v-col cols="3">
        <v-subheader> Refills</v-subheader>
      </v-col>
      <v-col cols="3">
        <BaseInput
          :value="refills"
          fieldName="refills"
          label="Enter your blood glucose values"
          @change="onFieldChange"
        />
      </v-col>
      <v-col cols="3">
        <v-subheader>Days Supply</v-subheader>
      </v-col>
      <v-col cols="3">
        <BaseInput
          :value="daysSupply"
          fieldName="daysSupply"
          label="Enter your blood glucose values"
          @change="onFieldChange"
        />
      </v-col>
      <v-col class="actions" cols="12">
        <v-btn depressed text @click="onSaveAsFavorite">
          <img :src="require('@/assets/icons/star-bold.svg')" alt="" />
          Save as Favorite
        </v-btn>
        <div class="buttons">
          <v-btn depressed @click="$emit('onCancel')"> Cancel</v-btn>
          <v-btn color="primarylight1" depressed @click="onSave"> Save</v-btn>
        </div>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import moment from "moment-timezone";
import { mapActions, mapState } from "pinia";

import { ObservationsApi } from "@/api/observations";
import DatePicker from "@/components/shared/DatePicker";
import BaseInput from "@/components/uikit/BaseInput";
import Select from "@/components/uikit/Select";
import Textarea from "@/components/uikit/Textarea";
import { snackBarEventBus, snackBarEventName } from "@/eventBuses/snackBar.eventBus";
import { useAuthStore } from "@/pinia-store/auth";
import { useObservationsStore } from "@/pinia-store/observations";
import { useTerminologyStore } from "@/pinia-store/terminology";
import { observationTypes } from "@/utils/observationTypes";

export default {
  name: "MedicationEdit",
  components: {
    Textarea,
    DatePicker,
    BaseInput,
    Select,
  },
  watch: {
    item: {
      immediate: true,
      handler(val) {
        if (val) {
          Object.keys(val).map((key) => {
            if (key === "date") return;

            this[key] = val[key];
          });
        }
        let date = moment(val?.startDate);
        this.startDate = date.isValid() ? date.toDate() : moment().toDate();
      },
    },
  },
  props: {
    item: {
      default: () => ({}),
      type: Object,
    },
  },
  data: () => ({
    startDate: moment().toDate(),
    patientInstruction: "",
    quantity: "1",
    daysSupply: "1",
    loading: false,
    name: "",
    dosespotId: "",
    dispenseUnitId: "",
  }),
  computed: {
    ...mapState(useAuthStore, ["uid"]),
    ...mapActions(useTerminologyStore, ["dispenseUnits"]),
    ...mapState(useObservationsStore, ["editForm", "editFormOpened", "patientId"]),
    isSubmitDisabled() {
      return Boolean(!this.date || !this.value || this.dateErrors.length || this.valueErrors.length);
    },
    kind() {
      return this.editForm.kind;
    },
    display() {
      return observationTypes.find((ob) => ob.kind === this.editForm.kind)?.display;
    },
  },
  methods: {
    ...mapActions(useObservationsStore, ["toggleEditFormOpened"]),
    ...mapActions(useTerminologyStore, ["preloadDispenseUnits"]),
    ...mapActions(useObservationsStore, ["changeEditFormField"]),
    async onSave() {
      this.$emit("onSave", {
        id: this.id,
        quantity: this.quantity,
        name: this.name,
        daysSupply: this.daysSupply,
        patientInstruction: this.patientInstruction,
        dispenseUnitId: this.dispenseUnitId,
        startDate: this.startDate,
        refills: this.refills,
      });
    },
    async onSaveAsFavorite() {
      this.$emit("onSaveAsFavorite", {
        id: this.id,
        quantity: this.quantity,
        name: this.name,
        daysSupply: this.daysSupply,
        patientInstruction: this.patientInstruction,
        dispenseUnitId: this.dispenseUnitId,
        startDate: this.startDate,
        refills: this.refills,
      });
    },
    onFieldChange(value, fieldName) {
      this[fieldName] = value;
    },
    onNoteChange(value) {
      this.patientInstruction = value;
    },

    onChangeDispenseUnitId(value) {
      this.dispenseUnitId = value;
    },

    onChangeDate(value) {
      this.changeEditFormField({ field: "date", value });
    },
    async performStore() {
      try {
        this.loading = true;
        if (this.editForm.id)
          await ObservationsApi.update(this.editForm.id, {
            ...this.editForm,
            patientId: this.patientId,
          });
        else await ObservationsApi.create({ ...this.editForm, patientId: this.patientId });
        this.$emit("onSave");
        this.loading = false;
      } catch (err) {
        console.error(err);
        if (err && err.response && err.response.status === 409) {
          snackBarEventBus.$emit(snackBarEventName, {
            message: this.$t("general.errors.err"),
            type: "error",
          });
        }
      }
      this.$emit("onChange");
      this.loading = false;
    },
  },
  async mounted() {
    await this.preloadDispenseUnits();
  },
};
</script>
<style lang="scss">
.medication-edit {
  .row {
    align-items: center;
  }

  .v-input .v-input__slot {
    margin-top: 0 !important;
  }

  .actions {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .buttons {
      display: flex;
      gap: 16px;
    }
  }
}

.monitoring-form {
  .card-title {
    font-weight: 500 !important;
    font-size: 25px !important;
    line-height: 31px !important;
    color: #25233a;
  }

  .v-card__title + .v-card__text {
    font-size: 14px;
    line-height: 17px;
    color: #33343d;
  }
}
</style>
