<template>
  <div class="details">
    <span class="name">{{ name }}</span>
    <span class="text">
      <template v-if="item.kind === 'bloodPressure'">
        systolic : {{ item.systolicLow || "--" }}-{{ item.systolicHigh || "--" }} {{ item.unit }}, diastolic :
        {{ item.diastolicLow || "--" }}-{{ item.diastolicHigh || "--" }} {{ item.unit }}
      </template>
      <template v-if="item.kind === 'bloodGlucose'">
        Before meal: {{ item.beforeMealLow || "--" }}-{{ item.beforeMealHigh || "--" }} {{ item.unit }}, After meal -
        {{ item.afterMealLow || "--" }}-{{ item.afterMealHigh || "--" }} {{ item.unit }}
      </template>
      <template v-if="item.kind === 'bodyTemperature'">
        Normal: {{ item.normalLow || "--" }}-{{ item.normalHigh || "--" }} {{ item.unit }}
      </template>
      <template v-if="['oxygenSaturation', 'respiratoryRate', 'heartRate'].includes(item.kind)">
        Normal: {{ item.normalLow || "--" }}-{{ item.normalHigh || "--" }} {{ item.unit }}
      </template>
    </span>
    <span v-if="item.patientInstruction" class="text">{{ item.patientInstruction || "" }}</span>
    <span v-if="startDate || endDate" class="text">From {{ startDate || "" }}, to {{ endDate || "" }}</span>
    <span v-if="item.note" class="text">{{ item.note || "" }}</span>
    <span v-if="item.practitioner" class="name"
      >Ordered by: {{ (item.practitioner && item.practitioner.firstName) || "" }}
      {{ (item.practitioner && item.practitioner.lastName) || "" }}</span
    >
  </div>
</template>

<script>
import moment from "moment-timezone";

import { getObservationNameByKind } from "@/utils/observationTypes";

export default {
  name: "ServiceRequestObservationDetails",
  props: {
    item: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  computed: {
    startDate() {
      const date = moment(this.item.startDate);
      return date.isValid() ? date.format("MMM, DD YYYY") : "--";
    },
    endDate() {
      const date = moment(this.item.endDate);
      return date.isValid() ? date.format("MMM, DD YYYY") : "--";
    },
    name() {
      return getObservationNameByKind(this.item.kind);
    },
  },
};
</script>

<style lang="scss" scoped>
.details {
  display: flex;
  flex-direction: column;
  .name {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #33343e;
    opacity: 0.9;
  }
  .text {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #25233a;
    mix-blend-mode: normal;
    opacity: 0.5;
  }
}
</style>
