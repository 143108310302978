<template>
  <Confirm
    :dialog="value"
    :hide-cancel="!dosespotLoaded"
    :hide-confirm="true"
    :width="dosespotLoaded ? '1500px' : '480px'"
    cancelText="Close"
    max-width="90%"
    title="DoseSpot"
    @toggleDialog="toggleDosespotDialog"
  >
    <v-container v-if="!dosespotLoaded" class="no-padding-bottom no-padding-top">
      <v-row>
        <v-col cols="12">
          <Select
            :items="selectPatients"
            :value="patientInfo.id"
            item-value="id"
            label="Patient"
            @input="onPatientSelect"
          />
        </v-col>
        <v-col cols="12">
          <PrimaryButton
            :disabled="!patientInfo.id"
            :loading="loadingDosespot"
            full-width
            text="Open Dosespot"
            @onClick="openDosespotDialog"
          />
        </v-col>
      </v-row>
    </v-container>
    <v-container v-if="dosespotLoaded" class="no-padding-bottom no-padding-top">
      <template v-if="dosespotPatientId">
        <embed
          id="dosespot-ember-src"
          :src="`${dosespotUIUrl}?b=2&SingleSignOnClinicId=${clinicId}&SingleSignOnUserId=${clinicianId}&SingleSignOnPhraseLength=32&SingleSignOnCode=${encryptedClinicId}&SingleSignOnUserIdVerify=${encryptedUserId}&Prefix=&PatientId=${dosespotPatientId}&HeightMetric=inch`"
          :style="{ width: '100%', 'min-height': embedHeight }"
          type=""
        />
      </template>
      <template v-else>
        <embed
          :src="`${dosespotUIUrl}?b=2&SingleSignOnClinicId=${clinicId}&SingleSignOnUserId=${clinicianId}&SingleSignOnPhraseLength=32&SingleSignOnCode=${encryptedClinicId}&SingleSignOnUserIdVerify=${encryptedUserId}&Prefix=&FirstName=${
            dosespotProfile.firstName
          }&MiddleName=&LastName=${dosespotProfile.lastName}&Suffix=${
            dosespotProfile.birthDate ? `&DateOfBirth=${encodeURIComponent(dosespotProfile.birthDate)}` : ''
          }${dosespotProfile.gender ? `&Gender=${dosespotGender}` : ''}${
            dosespotProfile.address ? `&Address1=${encodeURIComponent(dosespotProfile.address)}` : ''
          }${dosespotProfile.city ? `&City=${encodeURIComponent(dosespotProfile.city)}` : ''}${
            dosespotProfile.state ? `&State=${encodeURIComponent(dosespotProfile.state)}` : ''
          }${
            dosespotProfile.medicalRecordNumber
              ? `&NonDoseSpotMedicalRecordNumber=${dosespotProfile.medicalRecordNumber}`
              : ''
          }${dosespotProfile.postalCode ? `&ZipCode=${encodeURIComponent(dosespotProfile.postalCode)}` : ''}${
            dosespotProfile.homePhoneNumber && cleanDosespotPhone
              ? `&PrimaryPhone=${encodeURIComponent(cleanDosespotPhone)}&PrimaryPhoneType=Home`
              : ''
          }${
            !cleanDosespotPhone && dosespotProfile.phoneNumber && cleanDosespotCellPhone
              ? `&PrimaryPhone=${encodeURIComponent(cleanDosespotCellPhone)}&PrimaryPhoneType=Cell`
              : ''
          }&MRN=&Weight=140&WeightMetric=lb&Height=400&HeightMetric=inch`"
          style="width: 100%; min-height: 100vh"
          type=""
        />
      </template>
    </v-container>
  </Confirm>
</template>

<script>
import orderBy from "lodash/orderBy";
import { mapActions, mapState } from "pinia";

import { DosespotApi } from "@/api/dosespot";
import Confirm from "@/components/shared/Confirm";
import PrimaryButton from "@/components/uikit/PrimaryButton";
import Select from "@/components/uikit/Select";
import { snackBarEventBus, snackBarEventName } from "@/eventBuses/snackBar.eventBus";
import { usePatientsStore } from "@/pinia-store/patients";
import { errToText } from "@/utils/conversion";

export default {
  props: ["value"],
  name: "DosespotUIDialog",
  components: { PrimaryButton, Select, Confirm },
  data: () => ({
    embedHeight: "100vh",

    loadingEligibility: false,
    eligibility: false,
    patientInfo: {},
    dosespotDialog: false,
    dosespotPatientId: false,

    dosespotUIUrl: "",
    dosespotLoaded: false,
    loadingDosespot: false,
    dosespotProfile: {},
    clinicId: "",
    clinicianId: "",
    encryptedClinicId: "",
    encryptedUserId: "",
  }),
  computed: {
    ...mapState(usePatientsStore, ["patients"]),
    selectPatients() {
      return orderBy(
        this.patients.map((i) => ({
          ...i,
          text: `${i.firstName || ""} ${i.lastName || " "}`,
          sortColumn: `${i.firstName || ""} ${i.lastName || " "}`.toLowerCase(),
        })),
        ["sortColumn"],
      );
    },
    cleanDosespotPhone() {
      //required :  111 111 1111
      if (!this.dosespotProfile?.homePhoneNumber) return null;
      return this.dosespotProfile.homePhoneNumber
        .replace("+1", "")
        .replace("(", "")
        .replace(")", " ")
        .replaceAll("-", "");
    },
    cleanDosespotCellPhone() {
      if (!this.dosespotProfile?.phoneNumber) return null;
      return this.dosespotProfile.phoneNumber.replace("+1", "").replace("(", "").replace(")", " ").replaceAll("-", "");
    },
  },
  watch: {
    value: {
      immediate: true,
      async handler(val) {
        if (val) {
          this.dosespotLoaded = false;
          await this.getAllPatients();
        }
      },
    },
  },
  methods: {
    ...mapActions(usePatientsStore, ["getAllPatients"]),
    observeEmbedSize() {
      const observer = new MutationObserver((mutations) => {
        mutations.forEach((node) => {
          let { addedNodes } = node;

          addedNodes.forEach((node, key) => {
            // For each added script tag
            // if (node.nodeType === 1 && node.tagName === 'SCRIPT') {
            if (node.tagName === "SCRIPT") {
              console.info(node);
            }
          });
        });
      });
      let element = document.getElementById("dosespot-ember-src");
      //todo: check other sollutions, [wip]
      // Starts the monitoring
      this.embedHeight = `${Math.max(element.clientHeight, element.offsetHeight)}px`;
      observer.observe(element, {
        attributes: true, // required
        childList: true, // required
        characterData: true, // required
        subtree: true, // get updates from the descendants, not just the children
      });
    },
    onPatientSelect(value) {
      const patient = this.patients.find((i) => i.id === value);
      if (patient) {
        this.patientInfo = patient;
      }
    },
    toggleDosespotDialog() {
      this.$emit("toggleDialog");
    },
    async openDosespotDialog() {
      if (!this.patientInfo.id) return;

      if (this.dosespotLoaded) {
        this.dosespotDialog = !this.dosespotDialog;
        return;
      }
      this.loadingDosespot = true;
      try {
        let data = await DosespotApi.initUI(this.patientInfo.id);
        this.encryptedUserId = encodeURIComponent(data.encryptedUserId);
        this.encryptedClinicId = encodeURIComponent(data.encryptedClinicId);
        this.dosespotUIUrl = data.url;
        this.clinicianId = data.clinicianId;
        this.clinicId = data.clinicId;
        this.dosespotProfile = data.patientProfile || {};
        this.dosespotPatientId = data.dosespotPatientId ?? null;
        this.dosespotLoaded = true;
        this.dosespotDialog = true;
      } catch (e) {
        this.loadingDosespot = false;
        this.dosespotLoaded = false;
        this.dosespotPatientId = null;

        console.error(e);
        snackBarEventBus.$emit(snackBarEventName, {
          message: errToText(e),
          type: "error",
        });
      } finally {
        setTimeout(() => {
          this.loadingDosespot = false;
        }, 3000);
      }
    },
  },
};
</script>

<style scoped></style>
