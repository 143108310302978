import { apiRequest } from "./apiRequest";

const segment = "/voip";
const grantAccess = async () => {
  const { data } = await apiRequest.get(`${segment}/access`);
  return data;
};
const voiceToText = async (formData: FormData): Promise<{ text: string; isDetectedText: boolean }> => {
  const { data } = await apiRequest.post(`${segment}/voice-to-text`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  return data;
};

export const VoIPApi = {
  grantAccess,
  voiceToText,
};
