<template>
  <v-dialog :value="isOpened" max-width="710" width="90%" @click:outside="onClose">
    <v-card class="visit-note-dialog">
      <span class="visit-notes_card_header_title">
        <img alt="" class="title-icon" src="@/assets/med-cross.svg" />
        Visit note
      </span>
      <v-row class="align-center mb-5 mt-4" no-gutters>
        <v-col cols="12" sm="4"> Patient</v-col>
        <v-col cols="12" sm="8">
          <Avatar :src="patient?.photoURL || ''" class="mr-3" size="36" />
          {{ patient?.displayName || "" }}
        </v-col>
      </v-row>
      <v-row class="align-center mb-5 mt-4" no-gutters>
        <v-col class="d-flex align-center gap-1" cols="12" sm="4">
          <img :src="require('@/assets/icons/calendar-blue.svg')" alt="calendar" />
          <span>Choose a Date</span>
        </v-col>
        <v-col cols="12" sm="8">
          <DatePicker
            v-model="start"
            :dense="true"
            :hide-details="true"
            :isYearFirst="false"
            @setDate="changeCurrentDate"
          />
        </v-col>
      </v-row>
      <v-row class="align-center mb-5 mt-4" no-gutters>
        <v-col class="d-flex align-center gap-1" cols="12" sm="4">
          <img :src="require('@/assets/clock.svg')" alt="Clock" />
          <span>When did you call?</span>
        </v-col>
        <v-col cols="12" sm="8">
          <SelectComponent
            :hideDetails="true"
            :items="time"
            :value="clock"
            label=" Choose Visit Duration"
            @change="handleOnTime"
          />
        </v-col>
      </v-row>
      <v-row class="align-center mb-5" no-gutters>
        <v-col cols="12" sm="4">
          {{ $t("visits.chiefComplaint") }}
        </v-col>
        <v-col cols="12" sm="8">
          <BaseInput
            :value="chiefComplaint"
            fieldName="chiefComplaint"
            placeholder="Chief complaint"
            @change="onChange"
          />
        </v-col>
      </v-row>

      <v-row class="align-center mb-5" no-gutters>
        <v-col class="mb-5" cols="12" sm="4">{{ $t("visits.notes.dx") }}</v-col>
        <v-col cols="12" sm="8">
          <BaseInput
            :placeholder="$t('visits.notes.enterAbbreviation')"
            :value="dx"
            fieldName="dx"
            @change="onChange"
          />
        </v-col>
      </v-row>
      <v-row class="align-center mb-5" no-gutters>
        <v-col cols="12">
          <v-textarea
            :value="complaintDescription"
            auto-grow
            hide-details
            label="Complaint description"
            name="complaintDescription"
            outlined
            row-height="20"
            rows="5"
            @input="onComplaintDescription"
          />
        </v-col>
      </v-row>
      <div class="template-wrap">
        <span class="heading-5 quill-label">
          {{ $t("templates.assessment") }}
        </span>
        <quill-editor v-model="assessment" @change="onAssessmentChange"></quill-editor>
      </div>
      <div class="template-wrap">
        <span class="heading-5 quill-label">
          {{ $t("visits.notes.plan") }}
        </span>
        <quill-editor v-model="plan" @change="onPlanChange"></quill-editor>
      </div>
      <div class="template-wrap mb-4">
        <span class="heading-5 quill-label">
          {{ $t("visits.notes.followUpNote") }}
        </span>
        <quill-editor ref="quillEditor" v-model="followUp" @change="onFollowChange"></quill-editor>
      </div>
      <v-row class="align-center" no-gutters>
        <v-col class="bottom-actions justify-space-between" cols="12">
          <div>
            <OutlinedButton color="danger" text="Close" @onClick="onClose" />
            <IconicButton :danger="true" left-icon="mdi-trash-can-outline" text="Clear" @onClick="cleanUp" />
          </div>
          <div>
            <PrimaryButton
              :disabled="isSubmitDisabled"
              :loading="loading"
              left-icon="mdi-send"
              text="Sign & Send"
              @onClick="onSubmit"
            />
            <PrimaryButton
              :disabled="isSubmitDisabled"
              :loading="loadingGoToEncounter"
              right-icon="mdi-arrow-right"
              text="Continue to encounter"
              @onClick="onSubmitToEncounter"
            />
          </div>
        </v-col>
      </v-row>
    </v-card>
  </v-dialog>
</template>
<script>
import moment from "moment-timezone";
import { mapActions, mapState } from "pinia";

import { EncountersAPI } from "@/api/encounters";
import { PastVisitApi } from "@/api/pastVisitLog";
import Avatar from "@/components/Avatar/Index.vue";
import DatePicker from "@/components/shared/DatePicker.vue";
import BaseInput from "@/components/uikit/BaseInput";
import IconicButton from "@/components/uikit/IconicButton.vue";
import OutlinedButton from "@/components/uikit/OutlinedButton.vue";
import PrimaryButton from "@/components/uikit/PrimaryButton.vue";
import SelectComponent from "@/components/uikit/Select.vue";
import { snackBarEventBus, snackBarEventName } from "@/eventBuses/snackBar.eventBus";
import { INIT_CHAT_VISIT_NOTE_FIELD, UPDATE_CHAT_VISIT_NOTE_FIELD } from "@/eventBuses/socketEvents";
import { useAuthStore } from "@/pinia-store/auth";
import { useChatStore } from "@/pinia-store/chat";
import { useEncountersStore } from "@/pinia-store/encounters";
import { usePastVisitStore } from "@/pinia-store/pastVisit";
import { useVisitNoteStore } from "@/pinia-store/visitNote";
import { generateTimeslots } from "@/utils/generateTimeslots";

export default {
  name: "ChatVisitNote",
  components: {
    DatePicker,
    SelectComponent,
    OutlinedButton,
    PrimaryButton,
    IconicButton,
    Avatar,
    BaseInput,
  },
  data() {
    return {
      isOpened: false,
      templateDialog: false,
      loading: false,
      loadingGoToEncounter: false,
      plan: "",
      assessment: "",
      followUp: "",
      chiefComplaint: "",
      dx: "",
      channelId: null,
      patientId: null,
      complaintDescription: "",
      patient: {},
      picker: new Date().toISOString().substr(0, 10),
      time: generateTimeslots("00:00", "23:45", 15),
      clock: "00:00 - 00:15",
      start: new Date(),
    };
  },
  computed: {
    ...mapState(useChatStore, ["startedVisitNoteChannel"]),
    ...mapState(useVisitNoteStore, ["encounterNotes"]),
    ...mapState(useAuthStore, ["isLoggedIn", "uid", "timeZone"]),
    editor() {
      return this.$refs.quillEditor.quill;
    },
    isSubmitDisabled() {
      return (
        !this.patientId?.length || !this.chiefComplaint?.length || !this.complaintDescription?.length || !this.start
      );
    },
  },
  watch: {
    isLoggedIn: {
      handler(value) {
        if (!value) {
          this.cleanUp();
        }
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions(useChatStore, ["setStartedVisitNoteChannel"]),
    ...mapActions(useEncountersStore, ["setCurrentEncounter"]),
    ...mapActions(useVisitNoteStore, { setVisitNotePatientId: "setPatientId" }),
    ...mapActions(useVisitNoteStore, ["setEncounter"]),
    ...mapActions(usePastVisitStore, ["setStep", "setClock"]),

    changeCurrentDate(date) {
      this.start = this.$moment(date).tz(this.timeZone).format();
      this.end = this.$moment(date).tz(this.timeZone).add(15, "minutes").format();
    },

    handleOnTime(value) {
      this.start = this.picker + "T" + value.split(" - ")[0] + ":00.000Z";
      this.end = this.picker + "T" + value.split(" - ")[1] + ":00.000Z";
    },
    onClose() {
      this.isOpened = false;
    },
    toggleDialog() {
      this.templateDialog = false;
    },
    onFollowChange({ html }) {
      if (html === this.followUp) return;
      this.followUp = html;
    },
    onPlanChange({ html }) {
      if (html === this.plan) return;
      this.plan = html;
    },
    onAssessmentChange({ html }) {
      if (html === this.assessment) return;
      this.assessment = html;
    },
    onChange(value, fieldName) {
      this[fieldName] = value;
    },
    onComplaintDescription(value) {
      this.complaintDescription = value;
    },
    async onSubmit() {
      this.loading = true;
      try {
        const data = await EncountersAPI.createPastVisitEncounter({
          start: this.start,
          end: this.$moment(this.start).add(15, "minutes").format(),
          patientId: this.patientId,
          practitionerId: this.uid,
          chiefComplaint: this.chiefComplaint,
          complaintDescription: this.complaintDescription,
        });
        const visitData = {
          start: this.start,
          end: this.$moment(this.start).add(15, "minutes").format(),
          encounterId: data.id,
          patientId: this.patientId,
          practitionerId: this.uid,
          chiefComplaint: this.chiefComplaint,
          encounterNotes: {
            ...this.visitNote,
            plan: this.plan,
            assessment: this.assessment,
            followUp: this.followUp,
            start: this.start,
            end: this.end,
          },
          requestInformation: {
            visitDate: moment(this.start).format("YYYY-MM-DD"),
            chiefComplaint: this.chiefComplaint,
            generalInformation: {
              document: [],
            },
          },

          patientInformation: {
            ...this.patient,
          },
        };
        await PastVisitApi.createPastVisit(visitData);
        this.setStartedVisitNoteChannel("");
        this.isOpened = false;
        snackBarEventBus.$emit(snackBarEventName, {
          message: "Visit was successfully signed",
          type: "info",
        });
      } catch (e) {
        console.error(e);
      } finally {
        this.loading = false;
      }
    },
    async onSubmitToEncounter() {
      this.loadingGoToEncounter = true;
      try {
        const data = await EncountersAPI.createPastVisitEncounter({
          start: this.start,
          end: this.start,
          patientId: this.patientId,
          practitionerId: this.uid,
          chiefComplaint: this.chiefComplaint,
          complaintDescription: this.complaintDescription,
        });
        await EncountersAPI.saveNotes(data.id, {
          followUp: this.followUp,
          plan: this.plan,
          assessment: this.assessment,
          dx: this.dx,
          chiefComplaint: this.chiefComplaint,
        });
        this.cleanUp();
        this.loadingGoToEncounter = false;

        this.$router.push(`/practitioner/encounters/${data.id}/medicationReconsiliation`);
      } catch (e) {
        console.error(e);
      } finally {
        this.loadingGoToEncounter = false;
      }
    },
    cleanUp() {
      this.isOpened = false;
      this.channelId = null;
      this.patientId = null;
      this.followUp = "";
      this.channelId = "";
      this.dx = "";
      this.complaintDescription = "";
      this.patient = {};
      this.assessment = "";
      this.plan = "";
      this.chiefComplaint = "";
      this.setStartedVisitNoteChannel("");
    },
  },
  destroyed() {
    this.$off(UPDATE_CHAT_VISIT_NOTE_FIELD);
  },
  mounted() {
    this.$root.$on(INIT_CHAT_VISIT_NOTE_FIELD, () => {
      this.cleanUp();
    });
    this.$root.$on(UPDATE_CHAT_VISIT_NOTE_FIELD, ({ field, value }) => {
      if (field === "assessment") {
        this.assessment = `${this.assessment || ""}<p>${value}</p>`;
        return;
      }
      if (field === "plan") {
        this.plan = `${this.plan || ""}<p>${value}</p>`;
        return;
      }
      if (field === "followUp") {
        this.followUp = `${this.followUp || ""}<p>${value}</p>`;
        return;
      }
      this[field] = value;
    });
  },
};
</script>
<style lang="scss">
.bottom-actions {
  justify-content: flex-end;
  display: flex;
  flex-wrap: wrap;
  gap: 16px;

  > div {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
  }
}

.visit-note-dialog {
  width: 100%;
  padding: 18px 26px;
}

.panel-wrap-encounter-notes {
  .v-expansion-panel-content__wrap {
    padding-left: 0;
    padding-right: 0;
  }
}

.template-wrap {
  position: relative;
}

.template-btn {
  min-width: 30px !important;
  position: absolute;
  right: 8px;
  bottom: 15px;
  width: 30px !important;
  height: 30px !important;
  border-radius: 4px;
  background: $primarylight1;
  border: none;
  padding: 0 !important;

  img {
    width: 12px;
    aspect-ratio: 1;
  }
}
</style>
