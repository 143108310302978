import { apiRequest } from "@/api/apiRequest";
import { ChatLogRecordDto } from "@/context/twilioContext/TwilioChannelLog";
import ArchiveChannelDto from "@/context/twilioContext/types/ArchiveChannelDto";
import ChatLogUpdateTimestampDto from "@/context/twilioContext/types/ChatLogUpdateTimestampDto";
import { ChatMemberDto } from "@/context/twilioContext/types/ChatMemberDto";
import AnonymousChatCreateRequest from "@/types/AnonymousChatCreateRequest";
import BotStatusDto from "@/types/BotStatusDto";
import ChatCreateRequest from "@/types/CreateChatRequestDto";
import CreateChatResponseDto from "@/types/CreateChatResponseDto";
import { TicketCreateChatRequestDto } from "@/types/TicketCreateChatRequestDto";

export interface ChatCredentials {
  userId: string;
  channelSid: string;
}

const access = async (uid?: string): Promise<string> => {
  let response;
  if (uid) response = await apiRequest.get(`/chat/access/${uid}`);
  else response = await apiRequest.get(`/chat/access/`);
  return response.data;
};

const getCustomToken = async (body: ChatCredentials): Promise<string> => {
  const { data } = await apiRequest.get(`/chat/token`, { params: body });
  return data;
};

const chatMember = async (id: string): Promise<ChatMemberDto> => {
  try {
    const { data } = await apiRequest.get(`/chat/identity/${id}`);
    return data;
  } catch {
    const errorMessage = `User with ${id} has not been found. `;
    return Promise.reject(errorMessage);
  }
};

const getAvailableIdentities = async (): Promise<ChatMemberDto[]> => {
  try {
    const { data } = await apiRequest.get(`/chat/identity/new-conversation`);
    return data;
  } catch {
    const errorMessage = `Error fetching data`;
    return Promise.reject(errorMessage);
  }
};

const create = async (request: ChatCreateRequest): Promise<CreateChatResponseDto> => {
  const { data } = await apiRequest.post(`/chat`, request);
  return data;
};
const sendSms = async ({ message, toPhoneNumber }: { message: string; toPhoneNumber: string }): Promise<void> => {
  const { data } = await apiRequest.post(`/chat/sms`, { message, toPhoneNumber });
  return data;
};
const createAnonymous = async (request: AnonymousChatCreateRequest): Promise<CreateChatResponseDto> => {
  const { data } = await apiRequest.post(`/chat/anonymous`, request);
  return data;
};

const createTicket = async (request: TicketCreateChatRequestDto): Promise<CreateChatResponseDto> => {
  const { data } = await apiRequest.post(`/chat/ticket`, request);
  return data;
};

const updateChannel = async (
  channelId: string,
  {
    chiefComplaint,
  }: {
    chiefComplaint: string;
  },
): Promise<CreateChatResponseDto> => {
  const { data } = await apiRequest.put(`/chat/channel/${channelId}`, { chiefComplaint });
  return data;
};

const isBotOnline = async (channelSid: string): Promise<BotStatusDto> => {
  const { data } = await apiRequest.get(`/chat/bot-status/${channelSid}`);
  return data;
};

const archiveChannel = async (archiveRequest: ArchiveChannelDto): Promise<void> => {
  await apiRequest.post(`/chat/archive`, archiveRequest);
};

const getChatLogs = async (): Promise<ChatLogRecordDto[]> => {
  const { data } = await apiRequest.get(`/chat/listLogs`);
  return data;
};
const getChatLogById = async (sid: string): Promise<ChatLogRecordDto | null> => {
  const { data } = await apiRequest.get(`/chat/getLog/${sid}`);
  return data;
};
const getChatLogsByUserId = async (uid: string): Promise<ChatLogRecordDto[]> => {
  const { data } = await apiRequest.get(`/chat/getLogByUserId/${uid}`);
  return data;
};

const getChatLogsUpdateTimestamp = async (): Promise<ChatLogUpdateTimestampDto> => {
  const { data } = await apiRequest.get(`/chat/getLogsUpdateTimestamp`);
  return data;
};

const notifyPractitioner = async (sid: string, authorId: string, kind?: string) => {
  const { data } = await apiRequest.post(`/chat/notifyPractitioner`, { sid, authorId, kind });
  return data;
};

export const ChatApi = {
  updateChannel,
  sendSms,
  getCustomToken,
  getAvailableIdentities,
  access,
  chatMember,
  create,
  isBotOnline,
  createAnonymous,
  createTicket,
  archiveChannel,
  getChatLogById,
  listAllChatLogs: getChatLogs,
  listChatLogsByUserId: getChatLogsByUserId,
  getChatLogsUpdateTimestamp,
  notifyPractitioner,
};
