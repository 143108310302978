<template>
  <div>
    <v-expansion-panels v-model="panel" accordion class="encounter-visit-history" flat>
      <v-expansion-panel>
        <v-expansion-panel-header>
          <span class="visit-notes_card_header_title">
            <img alt="" class="title-icon" src="../../assets/med-cross.svg" />
            {{ $t("visits.summary.encounterNotes") }}
          </span>
        </v-expansion-panel-header>
        <v-expansion-panel-content class="section-two visit-notes_card_encounter-notes panel-wrap-encounter-notes">
          <v-row class="align-center mb-5" no-gutters>
            <v-col cols="4">
              {{ $t("visits.chiefComplaint") }}
            </v-col>
            <v-col>
              <Select
                v-model="encounterNotes.chiefComplaint"
                :hideDetails="true"
                :items="chiefComplaints"
                :label="$t('practitioner.appointments.selectComplaint')"
                fieldName="chiefComplaint"
                @change="onChange"
              />
            </v-col>
          </v-row>
          <v-row class="align-center" no-gutters>
            <v-col class="mb-5" cols="4">{{ $t("visits.notes.dx") }}</v-col>
            <v-col>
              <BaseInput
                v-model="encounterNotes.dx"
                :placeholder="$t('visits.notes.enterAbbreviation')"
                fieldName="dx"
                @change="onChange"
              />
            </v-col>
          </v-row>
          <div class="template-wrap">
            <span class="heading-5 quill-label">
              {{ $t("templates.assessment") }}
            </span>
            <quill-editor
              ref="quillEditor"
              v-model="encounterNotes.assessment"
              @change="onAssessmentChange"
            ></quill-editor>
            <v-btn :ripple="false" :width="30" class="template-btn" outlined @click="openAssessment">
              <img src="@/assets/icons/add-template.svg" />
            </v-btn>
          </div>
          <div class="template-wrap">
            <span class="heading-5 quill-label">
              {{ $t("visits.notes.plan") }}
            </span>
            <quill-editor ref="quillEditor" v-model="encounterNotes.plan" @change="onPlanChange"></quill-editor>

            <v-btn :ripple="false" :width="30" class="template-btn" outlined @click="openPlan">
              <img src="@/assets/icons/add-template.svg" />
            </v-btn>
          </div>
          <div class="template-wrap mb-4">
            <span class="heading-5 quill-label">
              {{ $t("visits.notes.followUpNote") }}
            </span>
            <quill-editor ref="quillEditor" v-model="encounterNotes.followUp" @change="onFollowChange"></quill-editor>
            <v-btn :ripple="false" :width="30" class="template-btn" outlined @click="openFollowUp">
              <img src="@/assets/icons/add-template.svg" />
            </v-btn>
          </div>
          <SelectTemplateDialog
            v-model="templateDialog"
            :type="templateTerm"
            @onSelect="onSelectTemplate"
            @onToggleDialog="toggleDialog"
          />
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>
<script>
import debounce from "lodash/debounce";
import { mapActions, mapState } from "pinia";

import { DosespotApi } from "@/api/dosespot";
import { TemplatesAPI } from "@/api/templates";
import BaseInput from "@/components/uikit/BaseInput";
import Select from "@/components/uikit/Select";
import { useVisitNoteStore } from "@/pinia-store/visitNote";
import { templateString } from "@/utils/locales.helpers";
import SelectTemplateDialog from "@/views/Admin/Templates/SelectTemplateDialog.vue";

export default {
  name: "EncounterNotes",
  components: {
    SelectTemplateDialog,
    BaseInput,
    Select,
  },
  data() {
    return {
      panel: 1,
      dosespotDialog: false,
      dosespotLoaded: false,
      dosespotProfile: {},
      clinicId: "",
      clinicianId: "",
      templateTerm: "",
      templates: [],
      templateDialog: false,
      fileRecordsForUpload: [],
      fileRecordsNewlySelected: [],
    };
  },
  computed: {
    ...mapState(useVisitNoteStore, ["encounterNotes", "patientInfo", "patientId"]),
    chiefComplaints() {
      const chiefComplaints = this.$t("general.chiefComplaints");
      if (!chiefComplaints) {
        return [];
      }
      return Object.keys(chiefComplaints).map((key) => ({ text: chiefComplaints[key], value: key }));
    },
  },
  methods: {
    ...mapActions(useVisitNoteStore, ["clearEncounterNoteFields", "autoSave"]),
    ...mapActions(useVisitNoteStore, ["setEncounterNotesField"]),
    toggleDialog() {
      this.templateDialog = false;
    },
    openFollowUp() {
      this.templateTerm = "followUp";
      this.templateDialog = true;
    },
    openPlan() {
      this.templateTerm = "plan";
      this.templateDialog = true;
    },
    openAssessment() {
      this.templateTerm = "assessment";
      this.templateDialog = true;
    },
    async getTemplates() {
      this.templates = await TemplatesAPI.getAll();
    },
    async onSelectTemplate(template) {
      const dataToReplace = {
        firstName: this.patientInfo?.firstName || "",
        lastName: this.patientInfo?.lastName || "",
        sex: this.patientInfo?.sex || "",
      };
      if (this.templateTerm === "assessment") {
        const assessment = templateString(template?.content, { ...dataToReplace });
        await this.setEncounterNotesField({ value: assessment, fieldName: "assessment" });
      }

      if (this.templateTerm === "followUp") {
        const followUp = templateString(template?.content, { ...dataToReplace });
        await this.setEncounterNotesField({ value: followUp, fieldName: "followUp" });
      }

      if (this.templateTerm === "plan") {
        const plan = templateString(template?.content, { ...dataToReplace });
        await this.setEncounterNotesField({ value: plan, fieldName: "plan" });
      }
      this.templateDialog = false;
    },

    async onFollowChange({ html }) {
      await this.setEncounterNotesField({ value: html, fieldName: "followUp" });
      this.debounceSave();
    },
    debounceSave: debounce(function () {
      this.autoSave();
    }, 500),
    async onPlanChange({ html }) {
      await this.setEncounterNotesField({ value: html, fieldName: "plan" });
      this.debounceSave();
    },
    async onAssessmentChange({ html }) {
      await this.setEncounterNotesField({ value: html, fieldName: "assessment" });
      this.debounceSave();
    },
    async getDosespotDetails() {
      let data = await DosespotApi.initUI(this.patientInfo.id);
      this.encryptedUserId = encodeURIComponent(data.encryptedUserId);
      this.encryptedClinicId = encodeURIComponent(data.encryptedClinicId);
      this.dosespotProfile = data.patientProfile;
      this.clinicId = data.clinicId;
      this.clinicianId = data.clinicianId;
      this.dosespotLoaded = true;
    },
    onChange(value, fieldName) {
      this.setEncounterNotesField({ value, fieldName });
      this.debounceSave();
    },
  },
  async mounted() {
    this.panel = this.$vuetify.breakpoint.smAndUp ? 0 : 1;
    await this.getTemplates();
    await this.getDosespotDetails();
  },
};
</script>
<style lang="scss">
.panel-wrap-encounter-notes {
  .v-expansion-panel-content__wrap {
    padding-left: 0;
    padding-right: 0;
  }
}

.template-wrap {
  position: relative;
}

.template-btn {
  min-width: 30px !important;
  position: absolute;
  right: 8px;
  bottom: 15px;
  width: 30px !important;
  height: 30px !important;
  border-radius: 4px;
  background: $primarylight1;
  border: none;
  padding: 0 !important;

  img {
    width: 12px;
    aspect-ratio: 1;
  }
}
</style>
