import ChatConversation from "@/context/twilioContext/ChatConversation/ChatConversation";

class ConversationRepository {
  public readonly _activeChannelsList: ChatConversation[] = [];
  public readonly _archivedChannelsList: ChatConversation[] = [];
  private readonly _floatingChats: ChatConversation[] = [];

  private activeChannelSortTimeout: NodeJS.Timeout | null = null;
  private archivedChannelSortTimeout: NodeJS.Timeout | null = null;

  public getChannel = (sid: string): ChatConversation => {
    let foundChannel = this._activeChannelsList.find((channel) => channel.sid === sid);
    if (!foundChannel) foundChannel = this._floatingChats.find((channel) => channel.sid === sid);
    if (!foundChannel) foundChannel = this._archivedChannelsList.find((channel) => channel.sid === sid);
    if (!foundChannel) throw new Error(`Channel with ${sid} not found.`);
    return foundChannel;
  };

  public addChannel = async (newChannel: ChatConversation): Promise<ChatConversation> => {
    if (newChannel.channelAttributes.isFloatingChat || newChannel.channelAttributes.isAnonymous) {
      this._floatingChats.push(newChannel);
      return newChannel;
    } else if (!newChannel.channelAttributes.isHidden && newChannel.chatConversationType === "full") {
      return this.addActiveChannel(newChannel);
    } else if (newChannel.chatConversationType === "log") {
      return this.addArchivedChannel(newChannel);
    }
    return newChannel;
    // throw new Error(`Channel ${newChannel.sid} can not be listed.`);
  };

  public removeChannel = async (channelSid: string) => {
    let removedChannel = await this.removeFloatingChannel(channelSid);

    if (!removedChannel) removedChannel = await this.removeActiveChannel(channelSid);
    if (!removedChannel) removedChannel = await this.removeArchivedChannel(channelSid);
    if (!removedChannel) console.error(`Unable to remove channel ${channelSid}. Channel has not been found.`);
  };
  public addActiveChannel = async (newChannel: ChatConversation): Promise<ChatConversation> => {
    const foundChannel = this._activeChannelsList.find((channel) => channel.sid === newChannel.sid);
    if (!foundChannel) {
      this._activeChannelsList.push(newChannel);
      await this.sortActiveChannels();
      return newChannel;
    }
    await this.sortActiveChannels();
    return foundChannel;
  };

  public moveToArchive = async (channelSid: string) => {
    const channel = await this.removeActiveChannel(channelSid);
    if (!channel)
      throw new Error(`Unable to move channel ${channelSid}. Channel has not been found in active channels list.`);
    await channel.archive();
    await this.addArchivedChannel(channel);
  };

  public getFilteredActiveChannels = (filterOptions?: ChannelFilterOptions): ChatConversation[] | null => {
    if (filterOptions) {
      return this.channelsFilter(this._activeChannelsList, filterOptions);
    }
    return this._activeChannelsList;
  };

  public getTicketChannels = (): ChatConversation[] | null => {
    return this._activeChannelsList.filter((i) => i.channelAttributes.isTicket);
  };

  public getFilteredArchivedChannels(filterOptions?: ChannelFilterOptions): ChatConversation[] | null {
    if (filterOptions) {
      return this.channelsFilter(this._archivedChannelsList, filterOptions);
    }
    return this._archivedChannelsList;
  }

  public async sortActiveChannels(): Promise<void> {
    if (this.activeChannelSortTimeout) clearTimeout(this.activeChannelSortTimeout);
    this.activeChannelSortTimeout = setTimeout(async () => {
      this._activeChannelsList.sort((a, b) => {
        try {
          const aDate = new Date(a?.lastMessageMeta?.dateCreated || a?.dateCreated || 0).valueOf();
          const bDate = new Date(b?.lastMessageMeta?.dateCreated || b?.dateCreated || 0).valueOf();
          return bDate - aDate;
        } catch (e) {
          return 0;
        }
      });
    }, 700);
  }

  public async sortArchivedChannels(): Promise<void> {
    if (this.archivedChannelSortTimeout) clearTimeout(this.archivedChannelSortTimeout);
    this.archivedChannelSortTimeout = setTimeout(async () => {
      this._archivedChannelsList.sort((a, b) => {
        try {
          const aDate = new Date(a?.lastMessageMeta?.dateCreated || a?.dateCreated || 0).valueOf();
          const bDate = new Date(b?.lastMessageMeta?.dateCreated || b?.dateCreated || 0).valueOf();
          return bDate - aDate;
        } catch (e) {
          return 0;
        }
      });
    }, 500);
  }

  private addArchivedChannel = async (newChannel: ChatConversation): Promise<ChatConversation> => {
    const foundChannel = this._archivedChannelsList.find((channel) => channel.sid === newChannel.sid);
    if (!foundChannel) {
      this._archivedChannelsList.push(newChannel);

      await this.sortArchivedChannels();
      return newChannel;
    }
    await this.sortArchivedChannels();
    return foundChannel;
  };

  private channelsFilter(
    channelList: ChatConversation[],
    filterOptions: ChannelFilterOptions,
  ): ChatConversation[] | null {
    return channelList.filter((channel) => {
      let hasMatch = false;
      const searchTerm = filterOptions?.query?.length ? filterOptions.query.toLowerCase().trim() : false;
      const numberOfMessages = searchTerm
        ? channel.messages.filter((message) => {
            return message.body?.toLowerCase().indexOf(searchTerm) !== -1;
          }).length
        : 0;
      if (numberOfMessages) hasMatch = true;

      // removed
      // if (
      //   searchTerm &&
      //   channel.interlocutor?.displayName &&
      //   channel.interlocutor?.displayName?.toLowerCase().trim().indexOf(searchTerm) !== -1
      // )
      //   hasMatch = true;
      if (
        searchTerm &&
        channel?.channelAttributes?.chiefComplaint &&
        channel?.channelAttributes?.chiefComplaint.toLowerCase().trim().indexOf(searchTerm) !== -1
      )
        hasMatch = true;

      let hasParticipantMatch = filterOptions.participantName?.length ? false : true;
      if (filterOptions.participantName?.length) {
        if (
          channel.interlocutor?.displayName
            ?.toLowerCase()
            .trim()
            .indexOf(filterOptions.participantName.toLowerCase().trim()) !== -1
        )
          hasParticipantMatch = true;

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        if (
          channel?._participantsRepository?.user?.displayName
            ?.toLowerCase()
            .trim()
            .indexOf(filterOptions.participantName.toLowerCase().trim()) !== -1
        )
          hasParticipantMatch = true;
      }

      return (
        (searchTerm ? hasMatch : true) &&
        (filterOptions.participantName?.length ? hasParticipantMatch : true) &&
        (filterOptions.timeSpan?.startDate
          ? channel.dateCreated.valueOf() > filterOptions.timeSpan.startDate.valueOf()
          : true) &&
        (filterOptions.timeSpan?.endDate
          ? channel.dateCreated.valueOf() < filterOptions.timeSpan.endDate.valueOf()
          : true)
      );
    });
  }

  private async removeActiveChannel(sid: string): Promise<ChatConversation | null> {
    const removedChannelIndex = this._activeChannelsList.findIndex((channel) => channel.sid === sid);
    if (removedChannelIndex === -1) {
      return null;
    }
    const removedChannel = this._activeChannelsList[removedChannelIndex];
    this._activeChannelsList.splice(removedChannelIndex, 1);
    return removedChannel;
  }

  private async removeFloatingChannel(sid: string): Promise<ChatConversation | null> {
    const removedChannelIndex = this._floatingChats.findIndex((channel) => channel.sid === sid);
    if (removedChannelIndex === -1) {
      return null;
    }
    const removedChannel = this._floatingChats[removedChannelIndex];
    this._floatingChats.splice(removedChannelIndex, 1);
    return removedChannel;
  }

  private async removeArchivedChannel(sid: string): Promise<ChatConversation | null> {
    const removedChannelIndex = this._archivedChannelsList.findIndex((channel) => channel.sid === sid);
    if (removedChannelIndex === -1) {
      return null;
    }
    const removedChannel = this._archivedChannelsList[removedChannelIndex];
    this._archivedChannelsList.splice(removedChannelIndex, 1);
    return removedChannel;
  }
}

export default ConversationRepository;

export type ChannelFilterOptions = {
  participantName?: string;
  query?: string;
  timeSpan?: {
    startDate?: Date;
    endDate?: Date;
  };
};
