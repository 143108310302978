import identity from "lodash/identity";
import pickBy from "lodash/pickBy";

import { GetPatientQuery, PractitionersSearchFilter, RegisterPatientBody } from "@/types";
import { Patient } from "@/types/users";

import { apiRequest } from "./apiRequest";

const signUpPatient = async (patient: FormData) => {
  return new Promise((resolve, reject) => {
    apiRequest
      .post("/patient", patient, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((r) => {
        resolve(r);
      })
      .catch((err) => {
        reject(err?.response?.data);
      });
  });
};
const registerNewPatient = async (patient: RegisterPatientBody) => {
  return new Promise((resolve, reject) => {
    apiRequest
      .post("/patient/register", {
        ...pickBy(patient, identity),
      })
      .then((r) => {
        resolve(r);
      })
      .catch((err) => {
        reject(err?.response?.data);
      });
  });
};
// todo: add type
const getPatients = async (query: GetPatientQuery = {}) => {
  const { data } = await apiRequest.get("/patient", { params: query });
  return data;
};

const getPractitioners = async (query?: PractitionersSearchFilter) => {
  const { data } = await apiRequest.get("/practitioner", { params: query });
  return data;
};

const patientGetProfile = async (patientId: string) => {
  const { data } = await apiRequest.get(`/patient/${patientId}`);
  return data;
};

const getSettings = async (patientId: string) => {
  const { data } = await apiRequest.get(`/patient/${patientId}/settings`);
  return data;
};

const getBodyHeights = async (patientId: string) => {
  const { data } = await apiRequest.get(`/patient/${patientId}/body-heights`);
  return data;
};
const getBodyWeights = async (patientId: string) => {
  const { data } = await apiRequest.get(`/patient/${patientId}/body-weights`);
  return data;
};
const getSmokingBehaviors = async (patientId: string) => {
  const { data } = await apiRequest.get(`/patient/${patientId}/smoking-behaviors`);
  return data;
};

const createBodyHeight = async (body: { value: number; data?: string }, patientId: string) => {
  const { data } = await apiRequest.put(`/patient/${patientId}/height`, body);
  return data;
};

const updateBodyHeight = async (body: { id: string; value: number; data?: string }, patientId: string) => {
  const { data } = await apiRequest.post(`/patient/${patientId}/height`, body);
  return data;
};

const getEligibility = async (patientId: string) => {
  const { data } = await apiRequest.get(`/patient/${patientId}/eligibility`);
  return data;
};

const destroyBodyHeight = async (id: string, patientId: string) => {
  const { data } = await apiRequest.delete(`/patient/${patientId}/height/${id}`);
  return data;
};
const createBodyWeight = async (body: { value: number; data?: string }, patientId: string) => {
  const { data } = await apiRequest.put(`/patient/${patientId}/weight`, body);
  return data;
};

const updateBodyWeight = async (body: { id: string; value: number; data?: string }, patientId: string) => {
  const { data } = await apiRequest.post(`/patient/${patientId}/weight`, body);
  return data;
};
const destroyBodyWeight = async (id: string, patientId: string) => {
  const { data } = await apiRequest.delete(`/patient/${patientId}/weight/${id}`);
  return data;
};
const createSmokingBehavior = async (body: { value: number; data?: string }, patientId: string) => {
  const { data } = await apiRequest.put(`/patient/${patientId}/smoking-behavior`, body);
  return data;
};

const updateSmokingBehavior = async (body: { id: string; value: number; data?: string }, patientId: string) => {
  const { data } = await apiRequest.post(`/patient/${patientId}/smoking-behavior`, body);
  return data;
};

const destroySmokingBehavior = async (id: string, patientId: string) => {
  const { data } = await apiRequest.delete(`/patient/${patientId}/smoking-behavior/${id}`);
  return data;
};

const getAllByPractitionerId = async (practitionerId: string) => {
  const { data } = await apiRequest.get(`patient`, { params: { practitionerId } });
  return data;
};

const avatarUpload = async (patientId: string, formData: FormData) => {
  const { data } = await apiRequest.post(`/patient/${patientId}/avatar`, formData, {
    headers: { "Content-Type": "multipart/form-data" },
  });
  return data;
};

const updateProfile = async (patientId: string, body: any) => {
  return new Promise((resolve, reject) => {
    apiRequest
      .put(`/patient/${patientId}`, body)
      .then((r) => resolve(r.data))
      .catch((err) => {
        reject(err?.response?.data || err?.response || err);
      });
  });
};

const patchProfile = async (patientId: string, body: Partial<Patient>) => {
  return new Promise((resolve, reject) => {
    apiRequest
      .patch(`/patient/${patientId}`, body)
      .then((r) => resolve(r.data))
      .catch((err) => reject(err?.response?.data || err?.response || err));
  });
};

const updateSettings = async (patientId: string, body: any) => {
  return new Promise((resolve, reject) => {
    apiRequest
      .put(`/patient/${patientId}/settings`, body)
      .then((r) => resolve(r.data))
      .catch((err) => reject(err?.response?.data || err?.response || err));
  });
};

const sendInfoWeight = async (patientId: string, body: any, code: string) => {
  const { data } = await apiRequest.put(`/patient/${patientId}/observations/epic?code=${code}`, body);
  return data;
};
const sendInfoHeight = async (patientId: string, body: any, code: string) => {
  const { data } = await apiRequest.put(`/patient/${patientId}/observations/epic?code=${code}`, body);
  return data;
};
const sendInfoSmoking = async (patientId: string, body: any, code: string) => {
  const { data } = await apiRequest.put(`/patient/${patientId}/observations/epic?code=${code}`, body);
  return data;
};

const getObservationsSummary = async (patientId: string) => {
  const { data } = await apiRequest.get(`/patient/${patientId}/observations/summary`);
  return data;
};
const updatePractitioner = async (patientId: string, practitionerId: string) => {
  const { data } = await apiRequest.put(`/patient/${patientId}/practitioner`, {
    practitionerId,
  });
  return data;
};

const createPatientCareTeam = async (patientId: string, name: string, address: string, city: string, type: string) => {
  const { data } = await apiRequest.post(`/patient/${patientId}/settings/careTeam`, {
    name,
    address,
    city,
    type,
  });
  return data;
};

const getPatientCareTeam = async (patientId: string) => {
  const { data } = await apiRequest.get(`/patient/${patientId}/settings/careTeam`);
  return data;
};

const changeCareTeam = async (patientId: string, prevId: string, id: string) => {
  const { data } = await apiRequest.put(`/patient/${patientId}/settings/careTeam`, {
    prevId,
    id,
  });
  return data;
};

const openEpic = async () => {
  return apiRequest.get(`/patient/epic/endpoints`);
};

export const PatientsApi = {
  patchProfile,
  getObservationsSummary,
  registerNewPatient,
  signUpPatient,
  getPatients,
  patientGetProfile,
  getAllByPractitionerId,
  getSettings,
  avatarUpload,
  updateProfile,
  updateSettings,
  updatePractitioner,
  getPractitioners,
  getBodyHeights,
  createBodyHeight,
  updateBodyHeight,
  getBodyWeights,
  createBodyWeight,
  updateBodyWeight,
  createSmokingBehavior,
  updateSmokingBehavior,
  getSmokingBehaviors,
  destroySmokingBehavior,
  destroyBodyWeight,
  destroyBodyHeight,
  sendInfoWeight,
  sendInfoHeight,
  sendInfoSmoking,
  createPatientCareTeam,
  getPatientCareTeam,
  changeCareTeam,
  openEpic,
  getEligibility,
};
