import { apiRequest } from "./apiRequest";

const sign = async (visitNoteId: string) => {
  const { data } = await apiRequest.post(`/visitNote/${visitNoteId}/sign`);
  return data;
};

const getById = async (appointmentId: string, allPractitionersVisits: boolean) => {
  const { data } = await apiRequest.get(
    `/visitNote?appointmentId=${appointmentId}&allPractitionersVisits=${allPractitionersVisits}`,
  );
  return data;
};

const searchVisitNote = async (patientId: string, search: string) => {
  const { data } = await apiRequest.post(`/visitNote/search`, {
    patientId,
    search,
  });
  return data;
};

const getPatientVisits = async (patientId: string, filter: Record<any, any> = {}) => {
  const { data } = await apiRequest.get(`visitNote/visits/patient/${patientId}`, { params: filter });
  return data;
};

export const VisitNoteAPI = {
  sign,
  getById,
  searchVisitNote,
  getPatientVisits,
};
