<template>
  <v-menu
    content-class="date-picker"
    v-model="menu"
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
    min-width="290px"
  >
    <template v-slot:activator="{ on }">
      <v-text-field
        :value="formattedDate"
        :class="{ 'base-input': true, [className]: true }"
        light
        flat
        solo
        :dense="dense"
        :height="height"
        :margin-bottom="'0'"
        :label="placeholder"
        :error-messages="errors"
        hide-details
        readonly
        @blur="onBlur"
        v-on="on"
      >
        <template v-slot:append>
          <v-icon v-on="on" color="icon" size="15">$vuetify.icons.calendar</v-icon>
        </template>
      </v-text-field>
    </template>
    <v-date-picker
      ref="picker"
      reactive
      :max="maxDate"
      :min="minDate"
      class="picker"
      :value="picker"
      :no-title="hideTitle"
      @click:date="updateDate"
      color="primary"
      :disabled="disabled"
    />
  </v-menu>
</template>

<script>
import { DateTime } from "luxon";

export default {
  name: "AdvancedSearchDatePicker",
  props: {
    className: { default: "", type: String },
    dense: { default: false, type: Boolean },
    height: { default: 47, type: Number },
    placeholder: String,
    errors: { default: () => [], type: Array },
    value: Date,
    maxDate: { default: new Date().toISOString().substr(0, 10), type: String },
    isYearFirst: { default: true, type: Boolean },
    inputValue: { default: null, type: Date },
    hideDetails: { default: false, type: Boolean },
    hideTitle: { default: false, type: Boolean },
    minDate: { default: undefined, type: String },
    disabled: { default: false, type: Boolean },
  },
  watch: {
    menu(val) {
      this.isYearFirst && val && this.$nextTick(() => (this.$refs.picker.activePicker = "YEAR"));
    },
  },
  data: () => ({
    picker: new Date().toISOString().substr(0, 10),
    menu: false,
    outlined: false,
  }),
  computed: {
    formattedDate() {
      if (!this.value || !this.inputValue) return null;
      return this.inputValue
        ? DateTime.fromJSDate(this.inputValue).toUTC().toFormat("MM.dd.yyyy")
        : DateTime.fromJSDate(this.value).toUTC().toFormat("MM.dd.yyyy");
    },
  },
  methods: {
    updateDate(date) {
      this.menu = false;
      const parsedDate = new Date(DateTime.fromFormat(date, "yyyy-MM-dd").toISODate());
      this.$emit("setDate", parsedDate);
      this.$emit("input", parsedDate);
      this.picker = date;
    },
    onBlur() {
      this.outlined = false;
      this.$emit("blur");
    },
  },
};
</script>

<style lang="scss" scoped>
.v-text-field {
  display: flex;
  flex-flow: row;
  max-width: 165px;
}
.v-input__control {
  margin-bottom: 0;
}
.v-date-picker-title {
  display: flex;
  justify-content: center;
}
.v-application--is-ltr .v-date-picker-title .v-picker__title__btn {
  text-align: center !important;
}
.date-picker {
  @media (max-width: 480px) {
    left: 50% !important;
    transform: translateX(-50%);
  }
}
</style>
