<template>
  <component :is="component" :is-floating-chat="isFloatingChat" />
</template>

<script>
import NoServiceRow from "./NoServiceRow";
import PlaceholderRow from "./PlaceholderRow";
import TypingRow from "./TypingRow";
export default {
  name: "StatusRow",
  components: { NoServiceRow, TypingRow, PlaceholderRow },
  props: {
    typing: Boolean,
    connected: Boolean,
    isFloatingChat: Boolean,
    participantIsBot: Boolean,
    isServiceOnline: Boolean,
  },
  computed: {
    component() {
      if (this.typing && this.connected) {
        return "TypingRow";
      } else if (!this.isServiceOnline && this.participantIsBot) {
        return "NoServiceRow";
      }
      return "PlaceholderRow";
    },
  },
};
</script>

<style scoped></style>
