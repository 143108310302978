import Vue from "vue";
import VueRouter from "vue-router";
import { RouteConfig } from "vue-router/types/router";

import admin from "@/router/admin";
import { routesEnum } from "@/types/Routes.enum";

import ownerRoutes from "./owner";
import patientRoutes from "./patient";
import practitionerRoutes from "./practitioner";
import registrarRoutes from "./registrar";
import { ifNotAuthenticated, logOutGuard, roomGuard } from "./utils";

Vue.use(VueRouter);

export const routes: RouteConfig[] = [
  {
    path: "/",
    component: () => import("../views/Landing/View.vue"),
  },
  {
    path: "/start",
    component: () => import("../views/Landing/Start.vue"),
  },
  {
    path: "/activate",
    component: () => import("../views/Landing/Activate.vue"),
  },
  {
    path: "/brokers",
    component: () => import("../views/Landing/Brokers.vue"),
  },
  {
    path: "/employers",
    component: () => import("../views/Landing/Employers.vue"),
  },
  {
    path: "/patients",
    component: () => import("../views/Landing/Patients.vue"),
  },
  {
    path: "/about",
    component: () => import("../views/Landing/About.vue"),
  },
  {
    path: "/support",
    component: () => import("../views/Landing/Support.vue"),
  },
  {
    path: "/auth",
    component: () => import("../views/Auth/View.vue"),
    children: [
      {
        path: "signin",
        name: routesEnum.signin,
        beforeEnter: ifNotAuthenticated,
        component: () => import("../views/Auth/SignIn/View.vue"),
      },
      {
        path: "sso",
        name: routesEnum.sso,
        beforeEnter: ifNotAuthenticated,
        component: () => import("../views/Auth/SSO/View.vue"),
      },
      {
        path: "code",
        name: routesEnum.code,
        component: () => import("../components/SignInLayout/TwoFactorAuthentication.vue"),
      },
      {
        path: "change-password",
        name: routesEnum.resetPassword,
        beforeEnter: ifNotAuthenticated,
        component: () => import("../views/Auth/ResetPassword/View.vue"),
      },
      {
        path: "request-reset-password",
        name: routesEnum.requestResetPassword,
        beforeEnter: ifNotAuthenticated,
        component: () => import("../views/Auth/RequestResetPassword/View.vue"),
      },
      {
        path: "signup",
        name: routesEnum.signup,
        beforeEnter: logOutGuard,
        component: () => import("../views/Auth/SignUp/View.vue"),
        children: [
          {
            path: ":inviteId",
            name: routesEnum.patientSignUp,
            component: () => import("../views/Auth/SignUp/Patient/View.vue"),
          },
        ],
      },
    ],
  },
  {
    path: "/room/:roomId",
    name: routesEnum.publicRoomCall,
    beforeEnter: roomGuard,
    component: () => import("../views/Public/Room/View.vue"),
  },
  {
    path: "/meeting-room/:roomId/:identity",
    name: routesEnum.publicMeetingRoomCall,
    component: () => import("../views/Public/MeetingRoom/View.vue"),
  },
  {
    path: "/chat-room/:channelSid/:userId",
    name: routesEnum.publicChatRoomCall,
    component: () => import("../views/Public/ChatRoom/View.vue"),
  },
  ...ownerRoutes,
  ...practitionerRoutes,
  ...patientRoutes,
  ...registrarRoutes,
  ...admin,
  {
    path: "/uikit",
    component: () => import("../views/UiKit.vue"),
  },
  {
    path: "/consent",
    component: () => import("../views/Terms/Consent.vue"),
  },
  {
    path: "/terms",
    component: () => import("../views/Terms/Terms.vue"),
  },
  {
    path: "/*",
    redirect: "/",
  },
];

// router.beforeEach((to, from, next) => {
//   if (["/auth/signin"].includes(to.path)) {
//     const authStore = useAuthStore();
//     const { role, isLoggedIn } = authStore;
//     if (!isLoggedIn || !role) {
//       return next();
//     }
//     return next({ path: `/${role}` });
//   }
// });
// const originalPush = VueRouter.prototype.push;
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
// VueRouter.prototype.push = function push(location, onResolve, onReject) {
//   if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject);
//   // eslint-disable-next-line @typescript-eslint/ban-ts-comment
//   // @ts-ignore
//   return originalPush.call(this, location).catch((err: any) => {
//     if (VueRouter.isNavigationFailure(err)) {
//       // resolve err
//       return err;
//     }
//     // rethrow error
//     return Promise.reject(err);
//   });
// };
