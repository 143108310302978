<template>
  <div class="pa-0">
    <div class="dialog__head">
      <v-card-title class="dialog__head__title">
        {{ $t("visits.schedule.first.title") }}
      </v-card-title>
    </div>
    <template v-if="userIsRegistrar">
      <span class="heading-5 p-schedule-visit__content__visit-reason__title mt-6 d-inline-block">
        Select Clinician
        <span class="p-schedule-visit__content__required">*</span>
      </span>
      <Select
        :items="practitionersList"
        :value="this.reassignPractitionerId ? this.reassignPractitionerId : ''"
        class="dialog__select"
        label="Select clinician"
        @change="onPractitionerChange"
      />
    </template>
    <div :class="{ 'red-border': !isPractitionerAvailable, 'mt-0': userIsRegistrar }" class="dialog__details">
      <v-row class="mb-1">
        <img src="@/assets/icons/calendar-blue.svg" />
        <span class="dialog__details__label">{{ $t("visits.schedule.second.date") }}:</span>
        <span class="dialog__details__text" @click="toggleDatepicker">{{ date }}</span>
        <v-date-picker
          v-if="datepickerOpen"
          ref="picker"
          :allowed-dates="allowedDates"
          :max="maxScheduleDate"
          :min="minScheduleDate"
          :no-title="true"
          :value="visitDate"
          class="dialog__details__date-picker"
          color="primary"
          reactive
          @click:date="updateDate"
        />
      </v-row>
      <v-row>
        <img src="@/assets/icons/time-blue.svg" />
        <span class="dialog__details__label">{{ $t("visits.schedule.second.time") }}:</span>
        <div class="dialog__details__select-block">
          <span v-if="slotsDates.length && timeslot" class="dialog__details__text">{{ timeslot }}</span>
          <span v-else-if="slotsDates.length === 0" class="dialog__details__text">No data available</span>
          <span v-else-if="slotsDates.length && !timeslot" class="dialog__details__text"
            >$t("owner.schedule.suggestedTimes"</span
          >
          <v-select
            :items="slotsDates"
            :placeholder="timeslot"
            :value="timeslot"
            class="dialog__details__select"
            dense
            item-text="time"
            item-value="id"
            @change="updateSlotId"
          ></v-select>
        </div>
      </v-row>
    </div>

    <div v-if="!userIsRegistrar">
      <span class="heading-5 p-schedule-visit__content__visit-reason__title d-inline-block mt-6">
        {{ $t("owner.schedule.selectDoctor") }}
        <span class="p-schedule-visit__content__required">*</span>
      </span>
      <Select
        v-if="this.selectedTime"
        :hideDetails="false"
        :items="practitionersList"
        :label="$t('owner.schedule.selectDoctorFromList')"
        :value="this.selectedTime && this.selectedTime.category ? this.selectedTime.category.value : ''"
        class="dialog__select"
        @change="onPractitionerChange"
      />
      <Select
        v-else
        :hideDetails="false"
        :items="practitionersList"
        :label="$t('owner.schedule.selectDoctorFromList')"
        :value="this.reassignPractitionerId ? this.reassignPractitionerId : ''"
        class="dialog__select"
        @change="onPractitionerChange"
      />
    </div>

    <span
      :class="{ 'mt-6': userIsRegistrar }"
      class="heading-5 p-schedule-visit__content__visit-reason__title d-inline-block"
    >
      {{ $t("practitioner.schedule.choosePatient") }}
      <span class="p-schedule-visit__content__required">*</span>
    </span>
    <Select
      :hideDetails="false"
      :items="patientsOptions"
      :label="$t('practitioner.schedule.choosePatientFromList')"
      :value="patientId"
      class="dialog__select"
      @change="onPatientChange"
    />

    <v-checkbox
      v-if="!practitionerOwnsPatient"
      v-model="reassignPatient"
      :label="$t('owner.schedule.reassignPatient')"
      class="mt-0"
    ></v-checkbox>

    <span class="heading-5 p-schedule-visit__content__visit-reason__title d-inline-block">
      {{ $t("visits.chiefComplaint") }}
      <span class="p-schedule-visit__content__required">*</span>
    </span>
    <Select
      :hideDetails="false"
      :items="chiefComplaints"
      :label="$t('practitioner.schedule.choosePatientComplaint')"
      :value="chiefComplaint"
      class="dialog__select"
      @change="onChiefComplaintChange"
    />

    <span class="heading-5 p-schedule-visit__content__visit-reason__title d-inline-block">
      {{ $t("visits.schedule.first.describeProblem") }}
      <span class="p-schedule-visit__content__required">*</span>
    </span>
    <Textarea
      :height="67"
      :hideDetails="true"
      :placeholder="$t('visits.schedule.first.visitReason')"
      :value="complaintDescription"
      class="dialog__select"
      @change="onVisitReasonChange"
    />
    <div class="dialog__footer">
      <PrimaryButton
        :disabled="isButtonDisabled"
        :fullWidth="true"
        :loading="buttonLoading"
        :text="$t('visits.schedule.first.title')"
        className="primary-btn__blue dialog__footer__btn"
        size="large"
        @onClick="onSubmit"
      />
    </div>
  </div>
</template>

<script>
import { DateTime } from "luxon";
import { mapActions, mapState } from "pinia";

import { AdminAPI } from "@/api/admin";
import PrimaryButton from "@/components/uikit/PrimaryButton";
import Select from "@/components/uikit/Select";
import Textarea from "@/components/uikit/Textarea";
import { useAppointmentsStore } from "@/pinia-store/appointments";
import { useAuthStore } from "@/pinia-store/auth";
import { useCheckoutStore } from "@/pinia-store/checkout";
import { useOwnersStore } from "@/pinia-store/owners";
import { usePatientStore } from "@/pinia-store/patient";
import { usePatientsStore } from "@/pinia-store/patients";
import { usePractitionersStore } from "@/pinia-store/practitioners";
import { useProfileSettingsStore } from "@/pinia-store/profileSettings";
import { useRegistrarsStore } from "@/pinia-store/registrars";
import { useSlotsStore } from "@/pinia-store/slots";
import { RolesEnum } from "@/types/Roles.enum";

export default {
  props: {
    dialog: Boolean,
    selectedTime: Object,
    closeScheduleDialog: Function,
    isRedirectNeeded: Boolean,
    step: Function,
  },
  components: {
    Select,
    Textarea,
    PrimaryButton,
  },
  data() {
    return {
      visitSettings: {},
      texts: {
        _1: "View Details",
        _2: "Medical Information",
      },
      datepickerOpen: false,
      timeslotsOpen: false,
      buttonLoading: false,
    };
  },
  computed: {
    ...mapState(useAuthStore, ["uid"]),
    ...mapState(useAuthStore, ["role"]),
    ...mapState(usePatientsStore, ["patients"]),
    ...mapState(useProfileSettingsStore, ["locale"]),
    ...mapState(useAppointmentsStore, [
      "slotId",
      "complaintDescription",
      "chiefComplaint",
      "visitDate",
      "patientId",
      "reassign",
      "reassignPractitionerId",
    ]),
    ...mapState(useSlotsStore, ["slotsArray", "currentSlot"]),
    ...mapState(usePractitionersStore, ["practitioners"]),
    practitionersWithSlots() {
      if (this.role === RolesEnum.Owner) {
        return this.$store.getters["owners/practitionersWithSlots"];
      } else if (this.role === RolesEnum.Registrar) {
        return this.$store.getters["registrars/practitionersWithSlots"];
      }
      return [];
    },
    practitionerFreeSlots() {
      if (this.role === RolesEnum.Owner) {
        return this.$store.getters["owners/practitionerFreeSlots"];
      } else if (this.role === RolesEnum.Registrar) {
        return this.$store.getters["registrars/practitionerFreeSlots"];
      }
      return [];
    },
    timeslot() {
      if (this.practitionerFreeSlots && this.slotId) {
        const slot = this.practitionerFreeSlots.find((slot) => slot.id === this.slotId);
        if (slot) {
          return slot.time;
        } else return this.$t("visits.schedule.second.selectTime");
      } else {
        return this.$t("visits.schedule.second.selectTime");
      }
    },
    date() {
      if (!this.visitDate) return this.$t("visits.schedule.second.selectDate");
      return DateTime.fromJSDate(new Date(this.visitDate)).setLocale(this.locale).toLocaleString({
        day: "numeric",
        month: "long",
        weekday: "long",
        year: "numeric",
      });
    },
    patientsOptions() {
      return this.patients.map(({ firstName, lastName, id }) => ({
        text: `${firstName} ${lastName}`,
        value: id,
      }));
    },
    chiefComplaints() {
      const chiefComplaints = this.$t("general.chiefComplaints");
      if (!chiefComplaints) {
        return [];
      }
      return Object.keys(chiefComplaints).map((key) => ({ text: chiefComplaints[key], value: key }));
    },
    userIsRegistrar() {
      return this.role === RolesEnum.Registrar;
    },
    slotsDates() {
      if (this.visitDate) {
        if (this.practitionerFreeSlots.length) {
          return this.practitionerFreeSlots
            .filter((slot) => {
              return slot.start.split("T")[0] === this.visitDate;
            })
            .filter((slot, index, arr) => arr.findIndex((s) => s.start === slot.start) === index);
        } else return [];
      } else {
        return [];
      }
    },
    maxScheduleDate() {
      return this.$moment().add(this.visitSettings.scheduleHorizonLimit, "days").format("YYYY-MM-DD");
    },
    minScheduleDate() {
      return DateTime.fromJSDate(new Date()).toJSDate().toISOString().substr(0, 10);
    },
    availableDates() {
      const dates = [];
      const date = DateTime.fromJSDate(new Date());
      for (let i = 0; i < 8; i++) {
        dates.push(date.plus({ days: i }).day);
      }

      return dates;
    },
    isButtonDisabled() {
      if (this.selectedTime && this.selectedTime.category) {
        return (
          !this.slotId ||
          !this.complaintDescription ||
          !this.chiefComplaint ||
          !this.visitDate ||
          !this.selectedTime.category.value ||
          !this.patientId
        );
      } else {
        return (
          !this.slotId ||
          !this.complaintDescription ||
          !this.chiefComplaint ||
          !this.visitDate ||
          !this.reassignPractitionerId ||
          !this.patientId
        );
      }
    },
    practitionersList() {
      return this.practitionersWithSlots.map(({ firstName, lastName, id }) => ({
        text: `${firstName} ${lastName}`,
        value: id,
      }));
    },
    isPractitionerAvailable() {
      if (this.reassignPractitionerId && this.visitDate && this.slot) {
        return this.practitionersFreeSlots[this.reassignPractitionerId]?.some((slot) => {
          return slot.start === `${this.visitDate}T${this.slot.slice(0, 5)}:00.000Z`;
        });
      } else {
        return true;
      }
    },
    reassignPatient: {
      get() {
        return this.reassign;
      },
      set(value) {
        this.setStringFieldByName({ value, fieldName: "reassign" });
      },
    },
    practitionerOwnsPatient() {
      const pat = this.patients.find((p) => p.id === this.patientId);
      return pat?.practitioner?.id === this.reassignPractitionerId;
    },
  },
  methods: {
    ...mapActions(useAppointmentsStore, ["setStringFieldByName"]),
    ...mapActions(usePatientsStore, ["getAllPatients"]),
    ...mapActions(usePatientStore, ["getPatientProfile"]),
    ...mapActions(usePatientsStore, ["getAllByPractitionerId"]),
    ...mapActions(useCheckoutStore, ["practitionerRequestVisit"]),
    ...mapActions(useOwnersStore, ["chosePractitioner", "getAllPractitioners"]),
    ...mapActions(useRegistrarsStore, ["getRegistrarPractitionersWithSlots"]),
    ...mapActions(useAppointmentsStore, ["clearAllFields"]),
    closeDialog() {
      let value = "";
      this.setStringFieldByName({ value, fieldName: "slotId" });
      this.$emit("on-close");
    },
    onSubmit() {
      this.step(2);
      this.$emit("submit", 2);
    },
    async makeSchedule() {
      if (this.isRedirectNeeded) {
        await this.$router.push({ path: `/${this.role}/makeSchedule?step=2` });
      } else {
        this.step(2);
      }
    },
    onPractitionerChange(value) {
      this.setStringFieldByName({ value: "", fieldName: "slotId" });
      if (this.role === RolesEnum.Owner) {
        this.$store.dispatch("owners/chosePractitioner", value);
      } else if (this.role === RolesEnum.Registrar) {
        this.$store.dispatch("registrars/chosePractitioner", value);
      }
      this.setStringFieldByName({ value, fieldName: "reassignPractitionerId" });
    },
    onPatientChange(value) {
      this.setStringFieldByName({ value, fieldName: "patientId" });
    },
    onChiefComplaintChange(value) {
      this.setStringFieldByName({ value, fieldName: "chiefComplaint" });
    },
    onVisitReasonChange(value) {
      this.setStringFieldByName({ value, fieldName: "complaintDescription" });
    },
    toggleDatepicker() {
      this.datepickerOpen = !this.datepickerOpen;
    },
    updateDate(newDate) {
      this.toggleDatepicker();
      this.setStringFieldByName({ value: newDate, fieldName: "visitDate" });
      let value = "";
      this.setStringFieldByName({ value, fieldName: "slotId" });
    },
    updateSlotId(value) {
      this.setStringFieldByName({ value, fieldName: "slotId" });
    },
    allowedDates(val) {
      return this.availableDates.includes(DateTime.fromISO(val).day);
    },
    nextStep() {
      this.setStringFieldByName({
        value: this.practitionersFreeSlots[this.reassignPractitionerId].find(
          (s) => s.start === `${this.visitDate}T${this.slot.slice(0, 5)}:00.000Z`,
        ).id,
        fieldName: "slotId",
      });
      this.$router.push({ path: "/owner/encounters/schedule?step=2" });
    },
    onDone() {
      this.closeDialog();
      this.clearAllFields();
    },
    onSuggestedTime(value) {
      const [date, timeSlice] = value.start.split("T");
      this.setStringFieldByName({ value: date, fieldName: "visitDate" });
      this.slot = `${timeSlice.slice(0, 5)} - ${value.end.split("T")[1].slice(0, 5)}`;
    },
  },

  async mounted() {
    await this.getAllPatients();
    this.visitSettings = await AdminAPI.getVisitSettings();

    if (this.role === RolesEnum.Owner) {
      await this.getAllPractitioners();
    } else if (this.role === RolesEnum.Registrar) {
      await this.getRegistrarPractitionersWithSlots(this.uid);
    }
    if (this.selectedTime && this.selectedTime.category) {
      await this.setStringFieldByName({ value: this.selectedTime.category.value, fieldName: "reassignPractitionerId" });
    }
  },
};
</script>

<style lang="scss">
.dialog {
  display: flex !important;
  flex-direction: column;
  align-items: flex-start;
  padding: 2rem;
  position: relative;

  img {
    display: inline-block;
  }

  &__unavailable {
    color: red;
    font-size: 11px;
    line-height: 13px;
  }

  &__head {
    display: flex;
    justify-content: space-between;
    width: 100%;
    position: relative;

    &__title {
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 24px;
      text-align: center;
      word-break: break-word !important;
      padding: 0;
    }

    &__close-icon {
      top: -12px;
      right: -12px;
      padding: 12px;
    }
  }

  &__stepper {
    width: 100%;
    border-bottom: none;
    height: 40px;
  }

  &__details {
    background-color: $primarylight1;
    border-radius: 4px;
    padding: 10px;
    margin-top: 24px;
    width: 100%;
    position: relative;

    & > div {
      margin: 0;
    }

    &__label {
      margin: 0 10px;
      font-size: 16px;
      color: $primaryblack2;
      opacity: 0.6;
    }

    &__text {
      font-size: 16px;
      color: $primaryblack2;

      &--black {
        @extend .dialog__details__text;
        margin-left: 10px;
      }
    }

    &__date-picker {
      position: absolute;
      z-index: 5;
      left: 50%;
      top: 35px;
      transform: translateX(-50%);
      box-shadow: 0px 18px 24px rgba(0, 0, 0, 0.14);
    }

    &__select-block {
      position: relative;
    }

    &__select {
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      opacity: 0;
    }

    &.red-border {
      border: 1px solid red;
      background-color: $white;
    }
  }

  &__select {
    width: 100%;
    font-size: 16px !important;
  }

  &__footer {
    width: 100%;
    margin-top: 24px;
    position: relative;

    &:before {
      content: "";
      position: absolute;
      left: -28px;
      top: -20px !important;
      width: calc(100% + 56px);
      height: 1px;
      background-color: #d2d3e1;
    }

    &__btn {
      margin-top: 24px;
    }
  }
}
</style>
