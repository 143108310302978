import ChatConversation from "@/context/twilioContext/ChatConversation/ChatConversation";
import DummyMembersRepository from "@/context/twilioContext/ChatConversation/DummyMembersRepository";
import { IParticipantsRepository } from "@/context/twilioContext/ChatConversation/IParticipantsRepository";
import { ITwilioConversation } from "@/context/twilioContext/ChatConversation/ITwilioConversation";
import MessagesRepository, { IMessagesRepository } from "@/context/twilioContext/ChatConversation/MessagesRepository";
import { ChatLogRecordDto } from "@/context/twilioContext/TwilioChannelLog";
import ChatUser from "@/context/twilioContext/TwilioChatMember/ChatUser";
import { TwilioChatMessageAttributes } from "@/context/twilioContext/types/TwilioChatMessageAttributes";

class ChatConversationBuilder {
  private user: ChatUser | null = null;

  private dummyConversation: ChatConversation | undefined;
  private membersRepository: IParticipantsRepository | undefined;
  private messagesRepository: IMessagesRepository | undefined;
  private twilioChannel: ITwilioConversation | undefined;

  constructor(dummyConversation?: ChatConversation) {
    this.dummyConversation = dummyConversation;
  }

  public setUser(user: ChatUser): ChatConversationBuilder {
    this.user = user;
    return this;
  }

  public addTwilioChannel(twilioChannel: ITwilioConversation): ChatConversationBuilder {
    this.twilioChannel = twilioChannel;
    return this;
  }

  public addMembersRepository(membersRepository: IParticipantsRepository): ChatConversationBuilder {
    this.membersRepository = membersRepository;
    return this;
  }
  public addMessagesRepository(messagesRepository: IMessagesRepository): ChatConversationBuilder {
    this.messagesRepository = messagesRepository;
    return this;
  }

  public async build(): Promise<ChatConversation> {
    if (!this.user?.uid || !this.twilioChannel || !this.messagesRepository || !this.membersRepository)
      throw Error("ChatConversationBuilder: Missing uid or channel.");

    return new ChatConversation(
      "full",
      this.user.uid,
      this.messagesRepository,
      this.membersRepository,
      this.twilioChannel,
    );
  }

  public async buildConversationLogFrom(conversationLog: ChatLogRecordDto): Promise<ChatConversation> {
    if (!this.user?.uid || !this.messagesRepository || !this.membersRepository)
      throw Error("ChatConversationBuilder.buildConversationLog: Missing uid, messageRepository or membersRepository.");

    return new ChatConversation(
      "log",
      this.user.uid,
      this.messagesRepository,
      this.membersRepository,
      undefined,
      conversationLog,
    );
  }

  public async buildDummyConversation(): Promise<ChatConversation> {
    if (!this.user?.uid) {
      throw Error("ChatConversationBuilder: Unable to build dummy channel. Missing uid or participant.");
    }

    this.messagesRepository = new MessagesRepository(this.user.uid);
    this.membersRepository = new DummyMembersRepository(this.user);
    this.messagesRepository.addDummyMessage(
      "Hello! If you feel chest pain please contact emergency services on 911 as soon as possible.",
      true,
    );
    this.messagesRepository.addDummyMessage("How can I help you today?", true, {
      isHidden: false,
      msgType: "MessageWithButtons",
      meta: {
        buttons: [
          {
            label: "FAQ",
            type: "imBack",
            value: "startAnonymous|FAQ",
          },
          {
            label: "Technical Questions",
            type: "imBack",
            value: "startAnonymous|Technical",
          },
        ],
      },
    } as TwilioChatMessageAttributes);
    return new ChatConversation("dummy", this.user.uid, this.messagesRepository, this.membersRepository);
  }

  public upgradeToFull(): ChatConversation {
    if (this.dummyConversation?.chatConversationType !== "dummy")
      throw new Error("Unable to upgrade from dummy conversation");
    if (!this.twilioChannel || !this.membersRepository)
      throw new Error("Unable to upgrade this conversation. TwilioChannel or Members Repository missing.");
    return this.dummyConversation.updateWith(this.twilioChannel, this.membersRepository);
  }
}

export default ChatConversationBuilder;
