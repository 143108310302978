
import Vue from "vue";

import ChatButton from "@/components/Chat/Message/ChatButton.vue";
import BaseMessage from "@/components/Chat/Message/MessageTypes/BaseMessage.vue";
import ButtonDto from "@/types/ButtonDto";

export default Vue.extend({
  name: "MessageWithButtons",

  components: { BaseMessage, ChatButton },

  props: {
    message: {
      type: Object,
      required: true,
    },
    responseDelegate: {
      type: Function,
      required: true,
    },
    isArchived: {
      type: Boolean,
      required: true,
    },
  },
  mounted() {
    if (this.isArchived) this.allDisabled = true;
  },
  data: () => ({
    value: "",
    clickedButtons: {} as Record<string | number, boolean>,
    allDisabled: false,
  }),
  methods: {
    hasBeenClicked(button: ButtonDto) {
      const payload = this.message.attributes.selectedOption?.payload ?? this.message.attributes.selectedOption;
      if (payload === button.value) {
        this.allDisabled = true;
        return true;
      }
      return false;
    },
    onButtonClick(text: string, value?: any) {
      this.responseDelegate(
        text,
        {
          payload: value,
          botMsgId: this.message.attributes.botMsgId,
        },
        this.message.sid,
      );
      this.allDisabled = true;
    },
    isLastButton(buttons: [], index: number): boolean {
      return index === buttons.length - 1;
    },
  },
});
