<template>
  <div>
    <Confirm
      :cancelText="$t('general.dialog.cancel')"
      :confirmText="$t('general.edit')"
      :dialog="value"
      :loading="loading"
      rootClass="templates-dialog"
      text="Please assign a relevant official name"
      title="Menage free text"
      @confirm="onSubmit"
      @toggleDialog="toggleDialog"
    >
      <v-container class="no-padding-bottom no-padding-top">
        <v-row dense>
          <v-col cols="12" sm="12">
            <BaseInput
              v-model="name"
              :errors="nameErrors"
              placeholder="Menage free text"
              @blur="$v.name.$touch()"
              @change="onFieldChange"
            />
          </v-col>
          <v-col cols="12" sm="12">
            <v-autocomplete
              v-if="related === 'medicationReconsiliation'"
              v-model="relatedName"
              :errors="nameErrors"
              :items="drugs"
              :label="$t('visits.medical.medicationPlaceholder')"
              :loading="isLoadingDrugs"
              :placeholder="$t('visits.medical.medicationPlaceholder')"
              :search-input.sync="drugSearch"
              cache-items
              className="bd-grey"
              clearable
              fieldName="name"
              hide-details
              hide-no-data
              solo
              @change="onSelectMedication"
            ></v-autocomplete>
            <v-autocomplete
              v-if="related === 'allergy'"
              v-model="relatedName"
              :cache-items="false"
              :items="allergyResults"
              :label="$t('visits.medical.medicationPlaceholder')"
              :loading="isLoadingAllergies"
              :placeholder="$t('settings.enterAllergies')"
              clearable
              fieldName="name"
              hide-details
              hide-no-data
              solo
              @change="onSelectAllergy"
            ></v-autocomplete>
            <v-autocomplete
              v-if="related === 'condition'"
              v-model="relatedName"
              :items="conditionResults"
              :label="$t('visits.medical.medicationPlaceholder')"
              :placeholder="$t('settings.enterAllergies')"
              clearable
              fieldName="name"
              hide-details
              hide-no-data
              solo
              @change="onConditionAllergy"
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" sm="12"></v-col>
        </v-row>
      </v-container>
    </Confirm>
  </div>
</template>
<script>
import orderBy from "lodash/orderBy";
import { mapState } from "pinia";
import { required } from "vuelidate/lib/validators";

import { SynonymsApi } from "@/api/synonyms";
import { TerminologyApi } from "@/api/terminology";
import Confirm from "@/components/shared/Confirm";
import BaseInput from "@/components/uikit/BaseInput";
import { snackBarEventBus, snackBarEventName } from "@/eventBuses/snackBar.eventBus";
import { useAuthStore } from "@/pinia-store/auth";

export default {
  name: "EditSynonymDialog",
  props: ["item", "value"],
  components: {
    Confirm,
    BaseInput,
  },
  data: function () {
    return {
      drugs: [],
      drugsSearchResult: [],
      conditions: [],
      allergies: [],
      isLoadingAllergies: false,
      conditionSearch: null,
      allergyName: "",
      conditionName: "",
      drugSearch: null,
      isLoadingDrugs: false,
      loading: false,
      templateTypes: [
        {
          value: "assessment",
          name: this.$t("templates.assessment"),
        },
        {
          value: "plan",
          name: this.$t("templates.plan"),
        },
        {
          value: "followUp",
          name: this.$t("templates.followUp"),
        },
      ],
      id: "",
      name: "",
      related: "",
      relatedName: "",
      relatedId: "",
    };
  },
  validations: {
    name: { required },
    term: { required },
  },
  watch: {
    item(val, oldVal) {
      this.id = val.id;
      this.name = val.name;
      this.related = val.related;
      this.relatedName = val.relatedName;
      if (val.related === "medicationReconsiliation") {
        TerminologyApi.searchDrugs(val.relatedName).then((values) => {
          this.isLoadingDrugs = false;
          if (!values?.length) return;
          this.drugsSearchResult = values;
          const newValues = [val, ...values.map((value) => value.name)];
          this.drugSearch = val.relatedName;
          this.drugs = orderBy(newValues, [(name) => name.toLowerCase()], ["asc"]);
        });
      }
      if (val.related === "condition" && !this.conditions.length) {
        this.fetchConditions();
      }
      if (val.related === "allergy" && !this.allergies.length) {
        this.fetchAllergies();
      }
      this.relatedId = val.relatedId;
      this.relatedName = val.relatedName;
    },
    drugSearch(val) {
      if (!val || val === this.name) return;
      clearTimeout(this._timerId);
      this._timerId = setTimeout(() => {
        this.isLoadingDrugs = true;
        TerminologyApi.searchDrugs(val).then((values) => {
          this.isLoadingDrugs = false;
          this.drugsSearchResult = values;
          const newValues = [val, ...values.map((value) => value.name)];

          this.drugs = orderBy(newValues, [(name) => name.toLowerCase()], ["asc"]);
        });
      }, 300);
    },
  },
  computed: {
    ...mapState(useAuthStore, ["uid"]),
    conditionResults() {
      return this.conditions.map((i) => i.name);
    },
    allergyResults() {
      return this.allergies.map((i) => i.name);
    },
    isSubmitDisabled() {
      return Boolean(!this.name || this.nameErrors.length);
    },
    nameErrors() {
      const errors = [];
      if (!this.$v.name.$dirty) return errors;
      const field = this.$t("templates.name");
      !this.$v.name.required && errors.push(this.$t("validation.required", { field }));
      return errors;
    },
  },

  methods: {
    async fetchConditions() {
      this.conditions = await TerminologyApi.getConditions();
    },
    async fetchAllergies() {
      this.allergies = await TerminologyApi.getAllergies();
    },
    toggleDialog() {
      this.$emit("onToggleDialog");
    },
    onSelectMedication(selectedValue) {
      const item = this.drugsSearchResult.find((result) => result.name === selectedValue);
      if (item) {
        this.relatedId = item.id || "";
      }
    },
    onSelectAllergy(selectedValue) {
      this.relatedName = selectedValue;
      const item = this.allergies.find((i) => i.name === selectedValue);
      if (item) this.relatedId = item.id;
    },
    onConditionAllergy(selectedValue) {
      this.relatedName = selectedValue;
      const item = this.conditions.find((i) => i.name === selectedValue);
      if (item) this.relatedId = item.id;
    },
    onFieldChange(value, fieldName) {
      this[fieldName] = value;
    },
    async onSubmit() {
      this.loading = true;
      try {
        const body = {
          name: this.name,
          related: this.related,
          relatedId: this.relatedId,
        };
        await SynonymsApi.update(this.id, body);
        this.$v.$reset();
        snackBarEventBus.$emit(snackBarEventName, {
          message: this.$t("settings.success"),
          type: "success",
        });
      } catch (err) {
        console.error(err);
        if (err && err.response && err.response.status === 400) {
          snackBarEventBus.$emit(snackBarEventName, {
            message: this.$t("templates.saveError"),
            type: "error",
          });
        }
      }
      this.loading = false;
    },
  },
};
</script>
<style lang="scss">
.templates-dialog {
  &.v-card {
    .card-title {
      font-weight: 500;
      font-size: 25px;
      line-height: 31px;
      color: #25233a;
      padding-left: 12px;
      padding-right: 12px;
    }

    .v-card__title + .v-card__text {
      font-size: 14px;
      line-height: 17px;
      color: #33343d;
      padding-left: 12px;
      padding-right: 12px;
    }
  }
}
</style>
