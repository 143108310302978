<template>
  <v-dialog :value="openedListDialog" :width="470" max-width="90%" @click:outside="toggleOpenedListDialog">
    <CardCloseIcon @onClick="toggleOpenedListDialog" />
    <v-card class="meeting-room-wrap">
      <v-row justify="space-between" no-gutters>
        <v-col
          :class="{
            'justify-content-between': !$vuetify.breakpoint.smAndDown,
          }"
          class="d-flex align-center justify-space-between pt-3"
          cols="12"
        >
          <span class="heading-2"> Meeting Room </span>
          <IconicButton
            :block="$vuetify.breakpoint.smAndDown"
            :class="{ 'mt-2': $vuetify.breakpoint.smAndDown }"
            :lite="true"
            class="btn"
            color="success"
            height="38"
            text="New invite"
            @onClick="openInviteDialog"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col class="wrap-slider" cols="12">
          <ParticipantBox v-for="room in meetingRooms" :key="room.id" :item="room" />
        </v-col>
      </v-row>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapActions, mapWritableState } from "pinia";

import ParticipantBox from "@/components/MeetingRoom/ParticipantBox.vue";
import CardCloseIcon from "@/components/uikit/CardCloseIcon.vue";
import IconicButton from "@/components/uikit/IconicButton.vue";
import { useMeetingRoomStore } from "@/pinia-store/meetingRoom";

export default {
  name: "MeetingRoomListDialog",
  components: {
    IconicButton,
    CardCloseIcon,
    ParticipantBox,
  },
  data() {
    return {
      slideSettings: {
        dots: false,
        arrows: true,
        centerMode: true,
        centerPadding: "20px",
        variableWidth: true,
      },
    };
  },
  computed: {
    ...mapWritableState(useMeetingRoomStore, ["meetingRooms", "openedListDialog", "openedMeetingRoom", "inviteDialog"]),
  },
  methods: {
    ...mapActions(useMeetingRoomStore, ["getMeetingRooms"]),
    ...mapActions(useMeetingRoomStore, ["setInviteDialog", "setOpenedMeetingRoom", "setOpenedListDialog"]),
    createdData(date) {
      return date && this.$moment(date).isValid() ? this.$moment(date).fromNow() : "";
    },
    toggleOpenedListDialog() {
      this.setOpenedListDialog(false);
    },
    openInviteDialog() {
      this.openedListDialog = false;
      this.openedMeetingRoom = false;
      setTimeout(() => {
        this.inviteDialog = true;
      }, 300);
    },
  },
  async mounted() {
    await this.getMeetingRooms();
  },
};
</script>

<style lang="scss" scoped>
.meeting-room-wrap {
  min-height: 400px;

  > .row {
    padding-left: 20px;
    padding-right: 20px;
  }

  .wrap-slider {
    display: flex;
    gap: 16px;
    flex-direction: column;
    overflow-x: auto;
  }
}
</style>
