<template>
  <div class="templates">
    <v-row class="templates__header" justify="space-between" no-gutters>
      <v-col class="templates__header_title">
        <span class="heading-2">{{ $t("templates.title") }}</span>
      </v-col>
      <v-col class="templates__header__actions">
        <PrimaryButton :text="$t('templates.createNew')" leftIcon="mdi-plus" size="medium" @onClick="createNew" />
      </v-col>
    </v-row>
    <v-data-table
      v-if="loaded"
      v-model="selected"
      :class="`table data-table data-table__green-border table-bordered ${className}`"
      :fixed-header="true"
      :footer-props="{
        showFirstLastPage: true,
      }"
      :header-props="{ sortIcon: selected ? null : 'mdi-chevron-down' }"
      :headers="headers"
      :items="tableItems"
      :sort-by="['updateDate']"
      mobile-breakpoint="0"
      show-select
    >
      <template v-slot:header.data-table-select="{ on, props }">
        <v-simple-checkbox v-if="!selected.length" color="purple" v-bind="props" v-on="on" />
        <div v-else class="templates__header-action">
          <v-simple-checkbox color="purple" v-bind="props" v-on="on" />
        </div>
      </template>
      <template v-slot:header.removeAction>
        <v-btn class="templates__remove-btn" fab small text @click="performDelete">
          <v-icon>mdi-delete</v-icon>
        </v-btn>
      </template>
      <template v-slot:item="props">
        <tr>
          <td class="templates__checkbox-cell">
            <v-checkbox :input-value="props.isSelected" @change="props.select($event)"></v-checkbox>
          </td>
          <td class="templates__name-cell">{{ props.item.name }}</td>
          <td>{{ props.item.term }}</td>
          <td>{{ props.item.updateDate }}</td>
          <td class="text-end templates__action-wrap">
            <div class="table__actions">
              <v-menu content-class="templates__edit-menu" left offset-y top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-bind="attrs" v-on="on">mdi-dots-horizontal</v-icon>
                </template>
                <v-list>
                  <v-list-item @click="editItem(props.item)">
                    <v-list-item-title class="templates__edit action-btn">
                      {{ $t("general.edit") }}
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="deleteItem(props.item.id)">
                    <v-list-item-title class="templates__delete action-btn">
                      {{ $t("general.delete") }}
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
          </td>
        </tr>
      </template>
      <template v-slot:no-data>
        <span class="data-table__no-items">
          {{ $t("general.table.noItems") }}
        </span>
      </template>
    </v-data-table>
    <v-progress-linear v-if="loading" color="primary" indeterminate />
    <CreateTemplateDialog v-model="dialog" :item="editableItem" @onToggleDialog="toggleDialog" />
  </div>
</template>
<script>
import moment from "moment-timezone";
import { mapState } from "pinia";
import { required } from "vuelidate/lib/validators";

import { TemplatesAPI } from "@/api/templates";
import PrimaryButton from "@/components/uikit/PrimaryButton";
import { snackBarEventBus, snackBarEventName } from "@/eventBuses/snackBar.eventBus";
import { useAuthStore } from "@/pinia-store/auth";

import CreateTemplateDialog from "./CreateTemplateDialog";

export default {
  name: "Templates",
  props: ["className"],
  components: {
    PrimaryButton,
    CreateTemplateDialog,
  },
  validations: {
    name: { required },
  },

  data: function () {
    return {
      editableItem: {},
      selected: [],
      items: [],
      loading: true,
      loaded: false,
      dialog: false,
    };
  },
  computed: {
    ...mapState(useAuthStore, ["uid"]),
    tableItems() {
      return this.items.map((item) => ({
        id: item.id,
        name: item.name,
        term: this.$t(`templates.${item.term}`),
        updateDate: moment(item.updateDate).format("MM/DD/YYYY HH:mm"),
      }));
    },

    headers() {
      if (this.selected.length)
        return [
          {},
          {},
          {},
          {
            text: this.$t("templates.name"),
            value: "removeAction",
            align: "end",
          },
        ];
      return [
        {
          text: this.$t("templates.name"),
          value: "name",
          width: "30%",
          align: "start",
        },
        {
          text: this.$t("templates.type"),
          value: "term",
          width: "30%",
        },
        {
          text: this.$t("templates.modifiedAt"),
          value: "updateDate",
          width: "30%",
        },
        { text: "", value: "action", width: 28, align: "end" },
      ];
    },

    nameErrors() {
      const errors = [];
      if (!this.$v.name.$dirty) return errors;
      const field = this.$t("general.inputs.height");
      !this.$v.name.required && errors.push(this.$t("validation.required", { field }));
      return errors;
    },
  },
  methods: {
    async performDelete() {
      this.loading = true;
      try {
        const ids = this.selected.map((i) => i.id);
        await TemplatesAPI.bulkDelete(ids);
      } catch (err) {
        console.error(err);
        if (err && err.response && err.response.status === 409) {
          snackBarEventBus.$emit(snackBarEventName, {
            message: this.$t("general.errors.err"),
            type: "error",
          });
        }
      }
      await this.getTemplates();
      this.loading = false;
    },
    async getTemplates() {
      const data = await TemplatesAPI.getAll();
      this.items = data || [];
    },
    async toggleDialog() {
      this.dialog = !this.dialog;
      await this.getTemplates();
    },
    editItem(item) {
      const data = this.items.find((i) => i.id === item.id);
      this.editableItem = { ...data };
      this.dialog = true;
    },
    async deleteItem(id) {
      this.loading = true;
      try {
        await TemplatesAPI.destroy(id);
        await this.getTemplates();
      } catch (err) {
        console.error(err);
        if (err && err.response && err.response.status === 409) {
          snackBarEventBus.$emit(snackBarEventName, {
            message: this.$t("general.errors.err"),
            type: "error",
          });
        }
      }
      this.loading = false;
    },
    createNew() {
      this.editableItem = {};
      this.dialog = true;
    },
  },
  async mounted() {
    await this.getTemplates();
    this.loaded = true;
    this.loading = false;
  },
};
</script>

<style lang="scss">
.templates {
  min-height: calc(100vh - 60px);
  overflow: auto;
  padding: 20px;
  @media (max-width: 767.98px) {
    padding: 20px;
  }

  .v-data-table-header {
    th:nth-of-type(1) {
      padding-right: 0 !important;
    }

    th:nth-of-type(2) {
      padding-left: 0 !important;
    }
  }

  &__header-action {
    display: flex;
    justify-content: space-between;
  }

  &__checkbox-cell {
    padding-right: 0 !important;
  }

  &__remove-btn {
    .v-btn__content {
      opacity: 1;

      .v-icon {
        color: red !important;
      }
    }
  }

  &__name-cell {
    padding-left: 0 !important;
  }

  &__header {
    margin-bottom: 28px;

    @media (max-width: 768px) {
      flex-direction: column;
    }

    &__title {
      display: flex;
      justify-content: space-between;
      @media (max-width: 768px) {
        flex-direction: column;
        max-width: fit-content;
      }
    }

    &__align-right {
      display: flex;
      justify-content: flex-end;
    }

    &__actions {
      display: flex;
      justify-content: flex-end;
      @media (max-width: 768px) {
        margin-top: 10px;
        justify-content: space-between;
      }
      @media (max-width: 480px) {
        flex-direction: column;
        max-width: 100%;
        width: 100%;
        button {
          width: 100%;
        }
      }

      &__margin {
        margin-right: 12px;

        @media (max-width: 480px) {
          margin-bottom: 12px;
          margin-right: 0;
        }
      }
    }
  }

  &__edit-menu {
    .v-list {
      padding-top: 0;
      padding-bottom: 0;
    }

    text-align: center;
    background: #ffffff;
    border: 1px solid #eeeff7;
    box-shadow: none;
    box-sizing: border-box;
    border-radius: 4px;

    .v-list-item:not(:last-child) {
      border-bottom: 1px solid #eeeff7;
    }

    .v-list-item:hover {
      cursor: pointer;
      background: rgba(34, 148, 242, 0.08);
    }
  }

  &__edit {
    color: $primary;
  }

  &__action-wrap {
    .table__actions {
      margin-right: 8px;
    }
  }

  &__delete {
    color: $error;
  }

  &__bottom-actions {
    justify-content: space-between;
    display: flex;
    gap: 15px;
  }

  &__form {
    .label {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      color: #33343e;
      opacity: 0.88;
    }

    label .v-text-field__details {
      display: none;
    }

    padding: 16px 24px;
  }
}
</style>
