<template>
  <v-dialog
    v-model="isActive"
    content-class="preview-signed-note-root-preview"
    max-width="80%"
    width="1100"
    @click:outside="onClosePreview"
  >
    <SignedVisitNote :item="signedNote" />
  </v-dialog>
</template>

<script>
import { mapActions, mapState } from "pinia";

import { useVisitNoteStore } from "@/pinia-store/visitNote";
import SignedVisitNote from "@/views/Practitioner/SignedVisit/SignedVisitNote";

export default {
  name: "PreviewSignedVisitNote",
  components: { SignedVisitNote },
  methods: {
    ...mapActions(useVisitNoteStore, ["togglePreviewSignNote"]),
    onClosePreview() {
      this.togglePreviewSignNote();
    },
  },
  computed: {
    ...mapState(useVisitNoteStore, [
      "previewSignNote",
      "medicationStatement",
      "patientInfo",
      "encounterNotes",
      "practitionerInfo",
    ]),
    signedNote() {
      return {
        visitNote: this.encounterNotes,
        patient: this.patientInfo,
        practitioner: this.practitionerInfo,
        allergies: this.patientAllergies,
        medicationStatement: this.medicationStatement,
      };
    },
    isActive() {
      return this.previewSignNote;
    },

    pmh() {
      return this.item?.pmh ? this.item?.pmh.map((i) => i.name).join(", ") : undefined;
    },
  },
};
</script>

<style lang="scss">
.preview-signed-note-root-preview {
  background-color: white;

  .v-card {
    background: #ffffff;
    border: 1px solid #e7e8f2;
    box-sizing: border-box;
    box-shadow: 0px 12px 32px rgba(0, 0, 0, 0.04);
    border-radius: 6px;
  }
}

.signed-visit-note {
  padding: 20px 0;

  .head-section {
    padding: 6px 28px;
    color: #2196f3;
    background: #eff6ff;
  }

  p {
    margin-bottom: 3px;
  }

  .label {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #000000;
  }

  .value {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #000000;
  }

  width: 100%;

  .pad {
    padding: 40px 30px;
  }

  .section-one {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    flex-direction: row;
  }

  .section-two {
    .label {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      color: #9c9c9c;
    }

    .value {
      font-style: normal;
      font-weight: 500;
      font-size: 15px;
      line-height: 17px;
      color: #33343e;
    }

    .value-pb {
      padding-bottom: 20px;
    }

    .name {
      font-style: normal;
      font-weight: 400;
      font-size: 19.5px;
      line-height: 23px;
      display: flex;
      align-items: center;
      color: #000000;
      opacity: 0.92;
    }
  }
}
</style>
