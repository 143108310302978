<template>
  <div class="pt-10 scheduled">
    <v-tabs v-model="tab" :show-arrows="true" active-class="tabs_tab_active" class="tabs" color="primary">
      <v-tab class="tabs_tab" @click="getTodayListEncounters"> Today's visits</v-tab>
      <v-tab class="tabs_tab" @click="getListEncounters"> {{ $t("visits.upcoming_short") }}</v-tab>
      <v-tab class="tabs_tab" @click="getPendingAppointmentsList">
        {{ $t("owner.clinician.table.pending") }}
        <v-chip v-if="practitionerPendingAppointmentsCount > 0" class="ma-2" color="primary" small
          >{{ practitionerPendingAppointmentsCount }}
        </v-chip>
      </v-tab>
      <v-tab class="tabs_tab" @click="getArchiveListEncounters"> Past</v-tab>
      <v-tab-item class="patients-list_tabs_tab_item">
        <Today
          :getListScheduledEncounters="getListEncounters"
          :isLoading="loading"
          :isSwitchTab="switchBetweenTabs$"
          @toSchedule="redirectToSchedule"
        />
      </v-tab-item>
      <v-tab-item class="patients-list_tabs_tab_item">
        <Upcoming
          :getListScheduledEncounters="getListEncounters"
          :isLoading="loading"
          :isSwitchTab="switchBetweenTabs$"
          @toSchedule="redirectToSchedule"
        />
      </v-tab-item>
      <v-tab-item class="patients-list_tabs_tab_item">
        <Pending :isLoading="loading" :isSwitchTab="switchBetweenTabs$" @toSchedule="redirectToSchedule" />
      </v-tab-item>
      <v-tab-item class="patients-list_tabs_tab_item">
        <Past
          :isLoading="loading"
          :isSwitchTab="switchBetweenTabs$"
          @toSchedule="redirectToSchedule"
          @update:isLoading="loading = $event"
        />
      </v-tab-item>
    </v-tabs>
  </div>
</template>

<script>
import moment from "moment-timezone";
import { mapActions, mapState } from "pinia";
import { Subject } from "rxjs";

import { snackBarEventBus, snackBarEventName } from "@/eventBuses/snackBar.eventBus";
import { todaySchedulePractitionerMixin } from "@/mixins/todaySchedulePractitioner";
import { useAppointmentsStore } from "@/pinia-store/appointments";
import { useAuthStore } from "@/pinia-store/auth";
import { useEncountersStore } from "@/pinia-store/encounters";
import { useOwnersStore } from "@/pinia-store/owners";
import { usePractitionersStore } from "@/pinia-store/practitioners";
import Past from "@/views/Practitioner/Appoinments/ScheduledAppointmentComponents/Past";
import Pending from "@/views/Practitioner/Appoinments/ScheduledAppointmentComponents/Pending";
import Today from "@/views/Practitioner/Appoinments/ScheduledAppointmentComponents/Today";
import Upcoming from "@/views/Practitioner/Appoinments/ScheduledAppointmentComponents/Upcoming";

export default {
  name: "ScheduledAppointments",
  components: {
    Today,
    Upcoming,
    Past,
    Pending,
  },
  data() {
    return {
      tab: null,
      practitionersFullNames: [],
      loading: false,
      switchBetweenTabs$: new Subject(),
    };
  },
  mixins: [todaySchedulePractitionerMixin],
  computed: {
    ...mapState(useEncountersStore, ["upcomingTabFilter", "pastTabFilter"]),
    ...mapState(usePractitionersStore, ["practitionersName"]),
    ...mapState(useAuthStore, ["uid"]),
    ...mapState(useAuthStore, ["role"]),
    ...mapState(useAppointmentsStore, [
      "pendingAppointments",
      "pendingAppointmentsPagination",
      "practitionerPendingAppointmentsCount",
    ]),
    ...mapState(useAuthStore, ["timeZone"]),
  },
  methods: {
    ...mapActions(useOwnersStore, ["getPendingCheckouts"]),
    ...mapActions(useAppointmentsStore, ["getPendingAppointments", "getPastAppointments", "getUpcomingAppointments"]),
    ...mapActions(usePractitionersStore, ["getAllPractitioners"]),
    ...mapActions(useEncountersStore, [
      "getAllEncounters",
      "getPractitionerPastEncounters",
      "getPractitionerTodayEncounters",
    ]),

    async getArchiveListEncounters() {
      this.switchBetweenTabs$.next("Past");
      let { currentDate } = this.pastTabFilter;
      const datesSorted = currentDate.sort((a, b) => moment(a).valueOf() - moment(b).valueOf());
      this.loading = true;
      let queryParams = {};
      if (datesSorted.length === 2) {
        queryParams.startDate = datesSorted[0];
        queryParams.endDate = datesSorted[1];
      } else queryParams.date = currentDate[0];

      try {
        await Promise.all([
          // this.getPractitionerPastEncounters(),
          this.getPastAppointments({ ...queryParams, practitionerId: this.uid, past: true }),
        ]);
      } catch (e) {
        console.error(e);
        snackBarEventBus.$emit(snackBarEventName, { message: e.message || e, type: "error" });
      }
      this.loading = false;
    },
    async getListEncounters() {
      this.switchBetweenTabs$.next(this.$t("visits.upcoming_short"));
      try {
        this.loading = true;
        let { currentDate } = this.upcomingTabFilter;
        const datesSorted = currentDate.sort((a, b) => moment(a).valueOf() - moment(b).valueOf());
        let queryParams = {
          future: true,
          practitionerId: this.uid,
          patientId: this.upcomingTabFilter.patientId,
        };
        if (datesSorted.length === 2) {
          queryParams.startDate = datesSorted[0];
          queryParams.endDate = datesSorted[1];
        }
        await this.getUpcomingAppointments(queryParams);
      } catch (e) {
        console.error(e);
        snackBarEventBus.$emit(snackBarEventName, { message: e.message || e, type: "error" });
      }
      this.loading = false;
    },
    async getPendingAppointmentsList() {
      this.switchBetweenTabs$.next(this.$t("owner.clinician.table.pending"));
      const date = this.$moment.tz(this.timeZone).startOf("isoWeek").weekday(1).format("YYYY-MM-DD");
      this.loading = true;
      try {
        await this.getPendingAppointments({ status: "pending", startDate: date, practitionerId: this.uid });
      } catch (e) {
        console.error(e);
        snackBarEventBus.$emit(snackBarEventName, { message: e.message || e, type: "error" });
      }
      this.loading = false;
    },
    async redirectToSchedule() {
      await this.$router.push({ path: `/${this.role}/encounters/schedule` });
    },
  },
  async mounted() {
    await this.getPendingAppointmentsList();
  },
};
</script>

<style lang="scss" scoped>
:deep .v-window {
  /*allow sticky position*/
  overflow: inherit !important;
}
</style>
