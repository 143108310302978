import { apiRequest } from "@/api/apiRequest";

export type CommunicationBody = {
  patientId: string;
  practitionerId: string;
  privateNote: string;
  reason: string;
  encounterId?: string;
};
const createCommunication = async (body: CommunicationBody) => {
  const { data } = await apiRequest.post(`/communication`, body);
  return data;
};

const getCommunications = async (patientId: string, practitionerId: string) => {
  const { data } = await apiRequest.get(`/communication?patientId=${patientId}&practitionerId=${practitionerId}`);
  return data;
};

const deleteCommunication = async (id: string) => {
  await apiRequest.delete(`/communication/${id}`);
};

const updateCommunication = async (id: string, text: string) => {
  await apiRequest.put(`/communication/${id}`, { text });
};

const getById = async (id: string) => {
  const { data } = await apiRequest.get(`/communication/${id}`);
  return data;
};

export const CommunicationApi = {
  createCommunication,
  getCommunications,
  updateCommunication,
  deleteCommunication,
  getById,
};
