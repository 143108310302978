<template>
  <div :class="containerClassName" class="visit-summary-wrapper">
    <v-card class="visit-summary">
      <VisitSummaryActions />
      <VisitSummaryPractitionerHeader :status="status" @onToggleMode="toggleMode" @submit="submitSummary" />
      <VisitSummaryHeader />
      <div class="visit-summary_title-line">
        <span>{{ $t("visits.summary.patientInformation") }}</span>
      </div>
      <VisitSummaryPatientInfo />
      <div class="visit-summary_title-line">
        <span>{{ $t("visits.summary.medicalInformation") }}</span>
      </div>
      <div class="visit-summary_med-info">
        <span class="visit-summary_section-title">
          {{ $t("visits.summary.allergies") }}
        </span>
        <v-card-text> {{ allergiesRow }}</v-card-text>
        <v-divider></v-divider>
        <span class="visit-summary_section-title">
          {{ $t("visits.summary.pmh") }}
        </span>
        <VisitSummaryTable :headers="headersPMH" :items="medicalInfo.pmh" />
        <span class="visit-summary_section-title">
          {{ $t("visits.summary.psh") }}
        </span>
        <VisitSummaryTable :headers="headersPSH" :items="medicalInfo.psh" />
        <span class="visit-summary_section-title">
          {{ $t("visits.summary.medicalInfo") }}
        </span>
        <VisitSummaryTable :headers="medicationTableHeaders" :items="medicationsTableItems" />
        <span class="visit-summary_section-title">Document</span>
        <div class="wrapper">
          <span v-if="!medicalInfo.document" class="text-muted">No data available</span>
          <v-chip
            v-for="item in medicalInfo.document"
            v-else
            :key="item.id"
            :href="item"
            :link="true"
            class="text-decoration-none"
            color="success"
            target="_blank"
            text-color="white"
          >
            <v-icon>mdi-file-move-outline</v-icon>
            {{ item.split(/\b[0-9a-f]{8}\b-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-\b[0-9a-f]{12}\/\b/g)[1] }}
          </v-chip>
        </div>
      </div>
      <div class="visit-summary_title-line">
        <span>{{ $t("visits.summary.encounterNotes") }}</span>
      </div>
      <div class="visit-summary_encounter-notes">
        <EncounterNote
          :text="translateChiefComplaint(encounterNotes.chiefComplaint)"
          :title="$t('visits.chiefComplaint')"
        />
        <EncounterNote :text="encounterNotes.dx" :title="$t('visits.summary.dx')" />
        <EncounterNote :text="encounterNotes.assessment" :title="$t('visits.notes.assessment')" />
        <EncounterNote :text="encounterNotes.plan" :title="$t('visits.notes.plan')" />
      </div>
      <v-divider></v-divider>
      <VisitSummaryLetter />
    </v-card>
  </div>
</template>
<script>
import { DateTime } from "luxon";
import { mapState } from "pinia";

import { useVisitNoteStore } from "@/pinia-store/visitNote";
import { tableHeaders } from "@/utils/tableHeaders.ts";

import VisitSummaryActions from "./Actions.vue";
import EncounterNote from "./EncounterNote";
import VisitSummaryHeader from "./Header.vue";
import VisitSummaryLetter from "./Letter.vue";
import VisitSummaryPatientInfo from "./PatientInfo.vue";
import VisitSummaryPractitionerHeader from "./PractitionerHeader.vue";
import VisitSummaryTable from "./Table.vue";

export default {
  name: "PractitionerVisitSummary",
  props: ["containerClassName"],
  components: {
    VisitSummaryHeader,
    VisitSummaryPatientInfo,
    VisitSummaryTable,
    VisitSummaryLetter,
    VisitSummaryActions,
    VisitSummaryPractitionerHeader,
    EncounterNote,
  },
  data: () => ({
    ...tableHeaders(false),
    status: "draft",
  }),
  computed: {
    ...mapState(useVisitNoteStore, ["medicalInfo", "encounterNotes"]),
    allergiesRow() {
      return !this.medicalInfo.allergies.length
        ? "-"
        : this.medicalInfo.allergies.reduce((acc, val, idx, arr) => {
            return acc.concat(`${val}${idx === arr.length - 1 ? "" : ","} `);
          }, "");
    },
    medicationTableHeaders() {
      return [
        {
          text: this.$t("visits.summary.medicationReconsiliation"),
          value: "name",
        },
        {
          text: this.$t("visits.summary.dose"),
          value: "dosage",
        },
        {
          text: this.$t("visits.summary.startDate"),
          value: "startDate",
        },
      ];
    },
    medicationsTableItems() {
      return this.medicalInfo.medications?.map((item) => ({
        name: item.name,
        dosage:
          !item.dosage || !item.dosageType || !item.dosageFrequency
            ? "-"
            : `${item.dosage} ${item.dosageType}, ${item.dosageFrequency}`,
        startDate: item.startDate ? DateTime.fromISO(item.startDate).toFormat("M/d/yyyy") : "-",
      }));
    },
  },
  methods: {
    submitSummary() {
      this.status = "sent";
      this.$emit("submit");
    },
    toggleMode() {
      this.$emit("changeNotesMode", "notes");
    },
    translateChiefComplaint(val) {
      return this.$t("general.chiefComplaints")[val] || val || "-";
    },
  },
};
</script>
