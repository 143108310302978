<template>
  <div class="medical-record-monitoring-wrap">
    <v-tabs
      :hide-slider="true"
      :show-arrows="false"
      :value="typeTab"
      background-color="transparent"
      class="mb-4 tab-buttons-wrap"
      color="primary"
    >
      <template v-for="obType in types">
        <v-tab
          v-if="hasOrder(obType.kind)"
          :key="obType.kind"
          class="text-capitalize tab_item"
          @click="type = obType.kind"
        >
          {{ obType.display }}
        </v-tab>
      </template>
    </v-tabs>
    <MonitoringCard
      v-if="showServiceOrder"
      :item="serviceOrder"
      :show-add="false"
      :show-delete="false"
      :show-edit="false"
      :show-view="false"
      :show-view-details="true"
      @onEdit="onEditOrder(serviceOrder.kind)"
      @onViewDetails="onViewDetails(serviceOrder)"
    />
    <MonitoringBloodGlucose v-if="type === 'bloodGlucose'" :hide-headers="true" />
    <MonitoringBloodPressure v-if="type === 'bloodPressure'" :hide-headers="true" />
    <MonitoringBodyTemperature v-if="type === 'bodyTemperature'" :hide-headers="true" />
    <MonitoringHeartRate v-if="type === 'heartRate'" :hide-headers="true" />
    <MonitoringOxygenSaturation v-if="type === 'oxygenSaturation'" :hide-headers="true" />
    <MonitoringRespiratoryRate v-if="type === 'respiratoryRate'" :hide-headers="true" />
    <AddBloodPressureOrder />
    <AddTemperatureOrder />
    <AddOxygenSaturationOrder />
    <AddRespiratoryRateOrder />
    <AddHeartRateOrder />
    <AddBloodGlucoseOrder />
  </div>
</template>

<script>
import orderBy from "lodash/orderBy";
import uniqBy from "lodash/uniqBy";
import { mapActions, mapState } from "pinia";

import { ServiceRequestApi } from "@/api/serviceRequest";
import AddBloodGlucoseOrder from "@/components/VisitNotes/bloodGlucoseOrder/Add";
import AddBloodPressureOrder from "@/components/VisitNotes/bloodPressureOrder/Add";
import AddHeartRateOrder from "@/components/VisitNotes/heartRateOrder/Add";
import AddOxygenSaturationOrder from "@/components/VisitNotes/oxygenSaturationOrder/Add";
import AddRespiratoryRateOrder from "@/components/VisitNotes/respiratoryRateOrder/Add";
import AddTemperatureOrder from "@/components/VisitNotes/temperatureOrder/Add";
import {
  BLOOD_GLUCOSE_ORDER_ADDED,
  BLOOD_PRESSURE_ORDER_ADDED,
  BODY_TEMPERATURE_ORDER_ADDED,
  HEART_RATE_ORDER_ADDED,
  OXYGEN_SATURATION_ORDER_ADDED,
  RESPIRATORY_RATE_ORDER_ADDED,
} from "@/eventBuses/monitoringEvents";
import { useAuthStore } from "@/pinia-store/auth";
import { useAutoSaveStore } from "@/pinia-store/autoSave";
import { useBloodGlucoseOrderStore } from "@/pinia-store/bodyGlucoseOrder";
import { useBloodPressureOrderStore } from "@/pinia-store/bodyPresureOrder";
import { useBodyTemperatureOrderStore } from "@/pinia-store/bodyTemperatureOrder";
import { useHeartRateOrderStore } from "@/pinia-store/heartRateOrder";
import { useMonitoringStore } from "@/pinia-store/monitoring";
import { useObservationsStore } from "@/pinia-store/observations";
import { useOxygenSaturationOrderStore } from "@/pinia-store/oxygenStaturationOrder";
import { useRespiratoryRateOrderStore } from "@/pinia-store/respiratoryRateOrder";
import { linkToPractitionerMonitoringListKindOrders } from "@/router/linkGenerator";
import { observationsEnum, observationTypes } from "@/utils/observationTypes";
import MonitoringBloodGlucose from "@/views/Patient/Monitoring/bloodGlucose/Index";
import MonitoringBloodPressure from "@/views/Patient/Monitoring/bloodPressure/Index";
import MonitoringBodyTemperature from "@/views/Patient/Monitoring/bodyTemperature/Index";
import MonitoringCard from "@/views/Patient/Monitoring/Card";
import MonitoringHeartRate from "@/views/Patient/Monitoring/heartRate/Index";
import MonitoringOxygenSaturation from "@/views/Patient/Monitoring/oxygenSaturation/Index";
import MonitoringRespiratoryRate from "@/views/Patient/Monitoring/respiratoryRate/Index";

export default {
  name: "PatientHealthData",
  components: {
    AddBloodGlucoseOrder,
    AddHeartRateOrder,
    AddRespiratoryRateOrder,
    AddOxygenSaturationOrder,
    AddTemperatureOrder,
    AddBloodPressureOrder,
    MonitoringCard,
    MonitoringRespiratoryRate,
    MonitoringOxygenSaturation,
    MonitoringHeartRate,
    MonitoringBloodPressure,
    MonitoringBloodGlucose,
    MonitoringBodyTemperature,
  },
  props: {
    obType: { default: null, type: String },
  },
  data() {
    return {
      type: "bloodPressure",
      serviceOrder: {},
      patientId: null,
    };
  },

  computed: {
    ...mapState(useMonitoringStore, ["orders"]),
    ...mapState(useAuthStore, ["userIsPatient", "uid"]),
    typeTab() {
      return observationTypes.findIndex((ob) => ob.kind === this.type);
    },
    types() {
      return observationTypes.filter((ob) => ob.kind !== "bloodGlucoseBM" && ob.kind !== "bloodGlucoseAM");
    },
    showServiceOrder() {
      return Boolean(this.serviceOrder && Object.keys(this.serviceOrder).length);
    },
  },
  watch: {
    obType() {
      if (this.obType) this.type = this.obType;
    },
    type: {
      immediate: true,
      handler(val) {
        if (val) {
          this.serviceOrder = this.orders.find((i) => i.kind === val);
        }
      },
    },
  },
  methods: {
    ...mapActions(useMonitoringStore, ["setOrders"]),
    ...mapActions(useObservationsStore, ["setPatientId"]),
    ...mapActions(useBloodPressureOrderStore, { toggleBPFormOpened: "toggleFormOpened" }),
    ...mapActions(useBloodPressureOrderStore, { fillBPForm: "fillForm" }),
    ...mapActions(useHeartRateOrderStore, { fillHeartRateOrderForm: "fillForm" }),
    ...mapActions(useHeartRateOrderStore, { toggleHeartRateOrderFormOpened: "toggleFormOpened" }),
    ...mapActions(useBodyTemperatureOrderStore, { fillBodyTemperatureOrderForm: "fillForm" }),
    ...mapActions(useBodyTemperatureOrderStore, { toggleBodyTemperatureOrderFormOpened: "toggleFormOpened" }),
    ...mapActions(useRespiratoryRateOrderStore, { fillRespiratoryRateOrderForm: "fillForm" }),
    ...mapActions(useRespiratoryRateOrderStore, { toggleRespiratoryRateOrderFormOpened: "toggleFormOpened" }),
    ...mapActions(useBloodGlucoseOrderStore, { fillBloodGlucoseOrderForm: "fillForm" }),
    ...mapActions(useBloodGlucoseOrderStore, { toggleBloodGlucoseOrderFormOpened: "toggleFormOpened" }),
    ...mapActions(useOxygenSaturationOrderStore, { fillOxygenSaturationOrderForm: "fillForm" }),
    ...mapActions(useOxygenSaturationOrderStore, { toggleOxygenSaturationOrderFormOpened: "toggleFormOpened" }),
    ...mapActions(useAutoSaveStore, ["endSave", "startSave"]),
    hasOrder(kind) {
      return this.orders.some((i) => i.kind === kind);
    },
    async load() {
      try {
        const orders = uniqBy(
          orderBy(await ServiceRequestApi.getPatientObservationOrders({ patientId: this.patientId, active: true }), [
            "startDate",
          ]),
          (i) => i.kind,
        );
        this.setOrders(orders);

        this.serviceOrder = orders.find((i) => i.kind === this.type);
      } catch (err) {
        console.error(err);
      } finally {
        this.loading = false;
      }
    },
    onEditOrder(kind) {
      switch (kind) {
        case observationsEnum.bloodPressure: {
          this.fillBPForm({ ...this.serviceOrder });
          this.toggleBPFormOpened();
          break;
        }
        case observationsEnum.heartRate: {
          this.fillHeartRateOrderForm({ ...this.serviceOrder });
          this.toggleHeartRateOrderFormOpened();
          break;
        }
        case observationsEnum.bodyTemperature: {
          this.fillBodyTemperatureOrderForm({ ...this.serviceOrder });
          this.toggleBodyTemperatureOrderFormOpened();
          break;
        }
        case observationsEnum.respiratoryRate: {
          this.fillRespiratoryRateOrderForm({ ...this.serviceOrder });
          this.toggleRespiratoryRateOrderFormOpened();
          break;
        }
        case observationsEnum.bloodGlucose: {
          this.fillBloodGlucoseOrderForm({ ...this.serviceOrder });
          this.toggleBloodGlucoseOrderFormOpened();
          break;
        }
        case observationsEnum.oxygenSaturation: {
          this.fillOxygenSaturationOrderForm({ ...this.serviceOrder });
          this.toggleOxygenSaturationOrderFormOpened();
          break;
        }
      }
    },
    async onViewDetails(order) {
      await this.$router.push(linkToPractitionerMonitoringListKindOrders(this.patientId, order.kind));
    },
  },
  beforeDestroy() {
    this.$root.$off(
      [
        BLOOD_PRESSURE_ORDER_ADDED,
        HEART_RATE_ORDER_ADDED,
        BODY_TEMPERATURE_ORDER_ADDED,
        RESPIRATORY_RATE_ORDER_ADDED,
        BLOOD_GLUCOSE_ORDER_ADDED,
        OXYGEN_SATURATION_ORDER_ADDED,
      ],
      this.load,
    );
  },
  async mounted() {
    this.patientId = this.userIsPatient ? this.uid : this.$route.params.patientId;
    this.$root.$on(
      [
        BLOOD_PRESSURE_ORDER_ADDED,
        HEART_RATE_ORDER_ADDED,
        BODY_TEMPERATURE_ORDER_ADDED,
        RESPIRATORY_RATE_ORDER_ADDED,
        BLOOD_GLUCOSE_ORDER_ADDED,
        OXYGEN_SATURATION_ORDER_ADDED,
      ],
      this.load,
    );
    await this.setPatientId(this.patientId);
    await this.load();
    this.serviceOrder = this.orders.find((i) => i.kind === this.type);
  },
};
</script>

<style lang="scss">
.medical-record-monitoring-wrap {
  .monitoring-layout {
    padding: 20px 0;
  }

  .tab-buttons-wrap {
    .v-slide-group__prev,
    .v-slide-group__next {
      display: none !important;
    }

    .tab_item {
      background-color: #fff !important;
      letter-spacing: normal;
      text-transform: capitalize;
      border-radius: 4px;
      border: 1px solid #eeeff7;
      font-size: 14px;
      color: #91909c;
      margin: 4px 4px 4px 0;

      &.v-tab--active {
        border: 1px solid #2196f3;
        background-color: #eff6ff !important;
        color: #2196f3;
      }
    }
  }

  .monitoring-table {
    margin-top: 26px;
  }

  .theme--light.v-data-table {
    .text--secondary {
      color: #33343e67 !important;
    }
  }
}
</style>
