<template>
  <div class="practitioner-header">
    <div class="status-div">
      <div
        :class="{
          status,
          'status--draft': renderedStatus === 'draft',
          'status--sent': renderedStatus === 'sent',
        }"
      >
        <span
          >{{ $t("visits.summary.status") }}
          {{ renderedStatus === "draft" ? $t("visits.summary.draft") : $t("visits.summary.sent") }}</span
        >
      </div>
      <div
        :class="{
          triangle: true,
          'triangle--draft': renderedStatus === 'draft',
          'triangle--sent': renderedStatus === 'sent',
        }"
      ></div>
    </div>
    <v-btn
      v-if="status !== 'signed'"
      class="btn btn-margin"
      color="rgb(239,246,255)"
      depressed
      large
      @click="changeMode"
    >
      <v-icon color="#2177e0" dark size="small"> mdi-pencil</v-icon>
      <span class="btn-blue">{{ $t("visits.summary.editDraft") }}</span>
    </v-btn>
    <v-btn
      v-if="status !== 'signed'"
      :disabled="disabledSigning"
      class="btn"
      color="success"
      dark
      depressed
      large
      @click="submitSummary"
    >
      {{ $t("visits.summary.signSend") }}
    </v-btn>
  </div>
</template>
<script>
import { mapState } from "pinia";

import { useVisitNoteStore } from "@/pinia-store/visitNote";

export default {
  name: "VisitSummaryPractitionerHeader",
  computed: {
    ...mapState(useVisitNoteStore, ["status", "disabledSigning"]),
    renderedStatus() {
      return this.status === "signed" ? "sent" : "draft";
    },
  },
  methods: {
    changeMode() {
      this.$emit("onToggleMode");
    },
    submitSummary() {
      this.$emit("submit");
    },
  },
};
</script>
<style lang="scss" scoped>
.practitioner-header {
  position: relative;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 30px 30px 0 30px;
}

.status-div {
  position: absolute;
  top: 30px;
  left: -7px;
}

.status {
  width: 110px;
  height: 30px;
  padding: 10px;
  color: $white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 12px;
  text-align: center;
  letter-spacing: 0.2px;
  text-transform: uppercase;

  &--draft {
    background: $orange;
  }

  &--sent {
    background: $success;
  }
}

.triangle {
  &--draft {
    width: 0;
    height: 0;
    border-left: 7px solid transparent;
    border-top: solid 7px #c67d3d;
  }

  &--sent {
    width: 0;
    height: 0;
    border-left: 7px solid transparent;
    border-top: solid 7px #249d54;
  }
}

.btn {
  text-transform: capitalize;
  font-weight: 550;

  &-blue {
    color: #2177e0;
  }
}

.btn-margin {
  margin-right: 10px;
}
</style>
