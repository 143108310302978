import { defineStore } from "pinia";

import { AllergiesApi } from "@/api/allerigies";

export interface AllergiesState {
  search?: Record<any, any>[];
  isLoadingPatientAllergies: boolean;
  patientAllergies: Record<any, any>[];
  patientId: string;
}

export const useAllergiesStore = defineStore({
  id: "allergies",
  state: (): AllergiesState => ({
    search: [],
    isLoadingPatientAllergies: false,
    patientAllergies: [],
    patientId: "",
  }),
  actions: {
    setPatientId(payload?: string) {
      this.patientId = payload;
    },
    setAllergiesPatientId(payload?: string) {
      this.patientId = payload;
    },
    async getPatientAllergies(payload?: string) {
      this.isLoadingPatientAllergies = true;
      const patientId = payload ? payload : this.patientId;
      if (patientId) {
        this.patientId = patientId;
        this.patientAllergies = await AllergiesApi.getPatientAllergies(patientId);
      }
      this.isLoadingPatientAllergies = false;
    },
    async addPatientAllergy(payload?: Record<any, any>) {
      const allergy = await AllergiesApi.add(payload, this.patientId);
      this.patientAllergies.push(allergy);
    },
    async removePatientAllergy(payload: string) {
      try {
        await AllergiesApi.destroy(payload, this.patientId);
      } catch (e) {
        console.error(e);
      }
      this.patientAllergies = this.patientAllergies.filter((i: { id: string }) => i.id !== payload);
    },
  },
});
