<template>
  <div class="visit-room">
    <v-progress-linear v-if="loading" color="primary" indeterminate />
    <div v-if="Object.keys(appointmentInformation).length">
      <div class="visit-room-header">
        <span class="visit-room-header-select"
          >{{ $t("visits.visitRoomWith") }} {{ `${appointmentInformation.participant[1].actor.display}` }}</span
        >
        <div class="visit-room-header-bordered">
          <v-icon color="icon">mdi-calendar-blank</v-icon>
          <p>{{ dateFormated }}</p>
        </div>
        <div class="visit-room-header-bordered">
          <v-icon color="icon">mdi-clock-outline</v-icon>
          <p>{{ timeRange }}</p>
        </div>
      </div>
      <v-row class="visit-room-content">
        <v-col cols="12" lg="8" md="8" sm="12" xl="8">
          <div class="pending-visit-call-content">
            <div class="overlay">
              <div class="overlay__content">
                <Avatar :src="photoURL" :userId="uid" />
                <p style="color: rgba(255, 255, 255, 0.7)">Waiting time</p>
                <p v-if="timer" class="heading-4 white--text font-weight-medium">
                  {{ `${timer.split(":")[0]} minutes, ${timer.split(":")[1]} seconds` }}
                </p>
                <v-btn
                  :ripple="false"
                  color="rgba(255, 255, 255, 0.7)"
                  large
                  outlined
                  @click="cancelVisitDialog = true"
                >
                  {{ $t("practitioner.appointments.cancelVisit") }}
                </v-btn>
              </div>
            </div>
          </div>
        </v-col>
        <v-col cols="12" lg="4" md="4" sm="12" xl="4">
          <v-alert
            v-if="appointmentInformation.status === 'pending'"
            dense
            icon="mdi-alert-outline"
            style="font-size: 11px"
            text
            type="error"
          >
            The visit wasn't confirmed by a patient yet
          </v-alert>
          <PractitionerPendingVisitDescription />
        </v-col>
      </v-row>
    </div>
    <CancelVisit
      v-model="cancelVisitDialog"
      :appointment-id="appointmentInformation.id"
      :isFromAppointments="true"
      @success="$router.push('/practitioner/appointments')"
    />
  </div>
</template>
<script>
import { DateTime } from "luxon";
import { mapActions, mapState } from "pinia";

import { CheckoutApi } from "@/api/checkout";
import Avatar from "@/components/Avatar/Index";
import CancelVisit from "@/components/CancelVisit";
import PractitionerPendingVisitDescription from "@/components/VisitDescription/Pending/Practitioner";
import { useAppointmentsStore } from "@/pinia-store/appointments";
import { useAuthStore } from "@/pinia-store/auth";
import { useCheckoutStore } from "@/pinia-store/checkout";

export default {
  name: "PractitionerPendingVisit",
  components: {
    Avatar,
    CancelVisit,
    PractitionerPendingVisitDescription,
  },
  data() {
    return {
      loading: false,
      loadingResendInvite: false,
      error: null,
      timerInterval: null,
      timer: null,
      cancelVisitDialog: false,
    };
  },
  computed: {
    ...mapState(useCheckoutStore, ["pendingRoomInfo"]),
    ...mapState(useAppointmentsStore, ["appointmentInformation"]),
    ...mapState(useAuthStore, ["uid", "timeZone", "photoURL"]),
    dateFormated() {
      if (!this.appointmentInformation) {
        return "";
      }
      return DateTime.fromISO(this.appointmentInformation.start).toFormat("dd MMMM y");
    },
    timeRange() {
      if (!this.appointmentInformation) {
        return "";
      }
      const start = DateTime.fromISO(this.appointmentInformation.start).toFormat("t");
      const end = DateTime.fromISO(this.appointmentInformation.end).toFormat("t");
      return `${start} - ${end}`;
    },
  },
  methods: {
    ...mapActions(useCheckoutStore, ["getPendingRoomInfo"]),
    ...mapActions(useAppointmentsStore, ["getAppointmentInformation"]),
    async timerUpdate() {
      this.timer = await this.calculateTimer();
    },
    async calculateTimer() {
      if (!this.pendingRoomInfo) {
        return;
      }
      const diff = DateTime.fromISO(this.appointmentInformation.start).diffNow("seconds");
      if (this.$moment().isAfter(this.$moment(this.appointmentInformation.start))) return;

      return diff.toFormat("mm:ss");
    },
    async resendInvite() {
      this.loadingResendInvite = true;
      await CheckoutApi.resend(this.pendingRoomInfo.id);
      this.loadingResendInvite = false;
    },
  },
  async mounted() {
    const { appointmentId } = this.$route.params;
    this.loading = true;
    await this.getAppointmentInformation(appointmentId);
    this.loading = false;
    this.timerInterval = setInterval(this.timerUpdate, 1000);
  },

  beforeDestroy() {
    if (this.timerInterval) window.clearInterval(this.timerInterval);
  },
};
</script>

<style lang="scss" scoped>
.visit-room-content {
  padding: 30px 12px 12px 12px;
  background: $white-light-1;
  height: 100%;

  &-video {
    min-height: 600px;

    height: 80vh;
    margin-left: 12px;
    margin-right: 12px;
  }
}

.pending-visit-call-content {
  .avatar-wrap {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  position: relative;
  width: 100%;
  height: 100%;

  .v-btn {
    font-weight: 500;

    &--outlined {
      text-transform: capitalize;
    }
  }

  img {
    width: 100%;
    height: 100%;
  }

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.4);
    text-align: center;

    .overlay__content {
      position: absolute;
      top: 50%;
      left: 0;
      width: 100%;
      transform: translateY(-50%);
      color: #ffffff;
    }
  }
}
</style>
