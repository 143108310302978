import { isValidPhoneNumber as libIsValidPhoneNumber } from "libphonenumber-js";

export const isValidZip = (value: string): boolean => {
  return /(^\d{5}$)|(^\d{5}-\d{4}$)/.test(value);
};
export const isValidPhoneNumber = (value: string): boolean => {
  return value ? libIsValidPhoneNumber(value) : false;
};
export const isValidSSN = (value: string): boolean => {
  if (!value || !value.length) return false;
  const chunk = value.split("-");

  return chunk[chunk.length - 1].length === 4;
};
export const isValidEmail = (email: string) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    );
};
