import ScheduleView from "@/components/Checkout/OwnerScheduleVisit/index.vue";
import { routesEnum } from "@/types/Routes.enum";
import MiddleComponent from "@/views/MiddleComponent.vue";
import ScheduledAppointments from "@/views/Owner/Appoinments/ScheduledAppointments.vue";
import VisitNotes from "@/views/Owner/Visits/VisitDetails.vue";
import AppointmentsArchive from "@/views/Registrar/Appoinments/Archive.vue";
import EditAvailability from "@/views/Registrar/Appoinments/EditAvailability.vue";
import ScheduleAppointment from "@/views/Registrar/Appoinments/Schedule.vue";
import AppointmentsView from "@/views/Registrar/Appoinments/View.vue";
import Clinician from "@/views/Registrar/Clinician.vue";
import RegistrarSettings from "@/views/Registrar/Settings.vue";
import RegistrarView from "@/views/Registrar/View.vue";
import RegistrarVisitCall from "@/views/Registrar/Visits/Call.vue";
import Schedule from "@/views/Registrar/Visits/Request.vue";

import {
  appointmentsArchiveBreadcrumbs,
  appointmentsBreadcrumbs,
  chatBreadcrumbs,
  EditAvailabilityBreadcrumbs,
  ifAuthenticated,
  patientListBreadcrumbs,
  practitionerScheduleVisitBreadcrumbs,
  profileSettingsBreadcrumbs,
  registrarScheduleVisitBreadcrumbs,
  scheduledAppointmentsBreadcrumbs,
} from "./utils";

const RegistrarRoutes = [
  {
    path: "/registrar",
    component: RegistrarView,
    meta: {
      authorize: ["registrar"],
    },
    redirect: "/registrar/appointments",
    beforeEnter: ifAuthenticated,
    children: [
      {
        path: "chat",
        name: routesEnum.registrarChat,
        meta: { breadcrumbs: chatBreadcrumbs },
        component: (): unknown => import("@/views/Registrar/Chat/Index.vue"),
      },
      {
        path: "settings",
        name: routesEnum.registrarSettings,
        component: RegistrarSettings,
        meta: { breadcrumbs: profileSettingsBreadcrumbs },
      },
      {
        path: "clinician",
        name: routesEnum.registrarClinician,
        component: Clinician,
        meta: { breadcrumbs: patientListBreadcrumbs },
      },
      {
        path: "appointments",
        component: AppointmentsView,
        children: [
          {
            path: "/",
            name: routesEnum.registrarSchedule,
            component: ScheduleAppointment,
            meta: { breadcrumbs: appointmentsBreadcrumbs, layout: "default" },
          },
          {
            path: "scheduled",
            name: routesEnum.registrarScheduledAppointments,
            component: ScheduledAppointments,
            meta: { breadcrumbs: scheduledAppointmentsBreadcrumbs, layout: "default" },
          },
          {
            path: "archive",
            name: routesEnum.registrarArchive,
            component: AppointmentsArchive,
            meta: { breadcrumbs: appointmentsArchiveBreadcrumbs, layout: "default" },
          },
          {
            path: "edit",
            name: routesEnum.registrarEditAvailability,
            component: EditAvailability,
            meta: { breadcrumbs: EditAvailabilityBreadcrumbs, layout: "edit" },
          },
        ],
      },
      {
        path: "makeSchedule",
        name: routesEnum.registrarScheduleVisit,
        component: ScheduleView,
        meta: { breadcrumbs: practitionerScheduleVisitBreadcrumbs },
      },
      {
        path: "encounters",
        component: MiddleComponent,
        children: [
          {
            path: "",
            component: MiddleComponent,
            redirect: "/registrar/appointments",
          },
          {
            path: "schedule",
            name: routesEnum.registrarEncounterSchedule,
            component: Schedule,
            meta: { breadcrumbs: registrarScheduleVisitBreadcrumbs },
          },
          {
            name: routesEnum.registrarEncounter,
            path: ":encounterId",
            component: RegistrarVisitCall,
            meta: { collapseSidebar: true },
          },
        ],
      },
      {
        name: routesEnum.registrarPending,
        path: "pending/:slotId",
        component: VisitNotes,
        meta: { collapseSidebar: true },
      },
    ],
  },
];
export default RegistrarRoutes;
