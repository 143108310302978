<template>
  <div v-if="floatingChatVisible" class="floating-chat-wrapper">
    <div class="header-c1">
      <div class="button-wrapper">
        <div class="action-btn" @click="toggleChatList">
          <v-icon class="open-in-new-button-icon" color="blue" size="25">mdi-menu</v-icon>
        </div>
      </div>
      <div class="header-title">
        <v-badge
          v-if="floatingChatActiveTab === FloatingChatTabs.channel"
          :value="participantOnline"
          color="success"
          dot
          offset-x="18"
          offset-y="9"
        >
          <Avatar
            v-if="getCurrentFloatingChannel.interlocutor"
            :user="getCurrentFloatingChannel.interlocutor && getCurrentFloatingChannel.interlocutor.meta"
            class="mr-3"
            size="36"
          />
        </v-badge>
        <span class="display-name">{{ displayName }}</span>
      </div>
      <div
        v-if="floatingChatActiveTab !== FloatingChatTabs.start"
        class="action-btn"
        @click="setFloatingChatTab(FloatingChatTabs.start)"
      >
        <v-icon class="open-in-new-button-icon" color="blue" size="25"> mdi-pen</v-icon>
      </div>
      <div class="close-btn" @click="toggleFloatingChatVisible">
        <img alt="Close" src="@/assets/icons/close-light.svg" />
      </div>
    </div>

    <FloatingChannel
      v-if="floatingChatActiveTab === FloatingChatTabs.channel"
      :active="true"
      :channel="getCurrentFloatingChannel"
      class="floating-chat-channel"
    />
    <FloatingChannelList v-if="floatingChatActiveTab === FloatingChatTabs.channelsList" class="floating-chat-channel" />
    <FloatingStartChannel
      v-if="floatingChatActiveTab === FloatingChatTabs.start"
      :active="true"
      class="floating-chat-channel"
    />
  </div>
</template>

<script>
import { mapActions, mapState } from "pinia";

import Avatar from "@/components/Avatar/Index.vue";
import FloatingChannel from "@/components/FloatingChat/FloatingChannel";
import FloatingChannelList from "@/components/FloatingChat/FloatingChannelList.vue";
import FloatingStartChannel from "@/components/FloatingChat/FloatingStartChannel.vue";
import { useAuthStore } from "@/pinia-store/auth";
import { FloatingChatTabs, useChatStore } from "@/pinia-store/chat";

export default {
  name: "FloatingChat",
  components: { Avatar, FloatingChannelList, FloatingStartChannel, FloatingChannel },
  inject: ["injectable"],
  data: () => {
    return {
      token: "",
      participantOnline: false,
      participantStatusSubscription: null,
      activeTab: "channelList",
      channelsList: [],
      isOpen: false,
      currentFloatingChannel: null,
      timer: null,
      loading: false,
    };
  },
  updated() {
    this.isOpen = this.currentFloatingChannel && this.isOpen;
  },

  methods: {
    ...mapActions(useChatStore, ["toggleFloatingChatVisible", "setFloatingChatTab"]),
    toggleChatList() {
      if (this.floatingChatActiveTab === FloatingChatTabs.channelsList) {
        if (this.currentFloatingChannel) this.setFloatingChatTab(FloatingChatTabs.channel);
        else this.setFloatingChatTab(FloatingChatTabs.start);
      } else {
        this.setFloatingChatTab(FloatingChatTabs.channelsList);
      }
    },
  },

  computed: {
    FloatingChatTabs() {
      return FloatingChatTabs;
    },
    ...mapState(useChatStore, [
      "floatingChatVisible",
      "loadingConversationParticipants",
      "floatingChatActiveTab",
      "getCurrentFloatingChannel",
    ]),
    ...mapState(useAuthStore, ["isLoggedIn"]),
    displayName() {
      if (this.floatingChatActiveTab === FloatingChatTabs.channelsList) return "Conversations";
      if (this.floatingChatActiveTab === FloatingChatTabs.start) return "New Conversation";
      const displayName = this.getCurrentFloatingChannel?.interlocutor?.displayName;
      return displayName ? (displayName.length > 60 ? displayName.substring(0, 60) + "..." : displayName) : "Chat Room";
    },
  },
  watch: {
    getCurrentFloatingChannel(val) {
      if (val?.interlocutor) {
        const obs = val.interlocutor.getChannelStatusObservable(val.sid);
        this.participantOnline = obs.getValue();
        this.participantStatusSubscription = obs.subscribe((val) => {
          this.participantOnline = val === "online";
        });
      }
    },
    async loadingConversationParticipants() {
      if (this.injectable.twilioContext) {
        const test = await this.injectable.twilioContext.client.listChannels();
        // this.channelsList = test.filter(
        //   (r) => r.attributes.isTicket === true && ["clinical", "techSupport"].includes(r.attributes?.ticketCategory),
        // );
        this.channelsList = test;
      }
    },
    isLoggedIn(newVal, oldVal) {
      if (newVal === false && oldVal === true) {
        this.injectable.twilioContext.setCurrentFloatingChannel(null);
        if (this.timer) {
          clearTimeout(this.timer);
        }
      } else if (newVal === true && oldVal === false) {
        if (this.timer) {
          clearTimeout(this.timer);
        }
        this.injectable.twilioContext.setCurrentFloatingChannel(null);
      }
    },
  },
};
</script>

<style lang="scss">
.floating-chat-wrapper {
  box-shadow: -3px -2px 20px 4px rgb(0 0 0 / 12%);
  background: white;
  border: rgba(30, 30, 30, 0.2) solid 1px;
  height: 582px;
  max-height: calc(100vh - 50px);
  position: fixed;
  bottom: 0;
  right: 0;
  max-width: 360px;
  width: 360px;
  flex-flow: row nowrap;
  justify-content: right;
  box-sizing: border-box;
  border-radius: 4px;
  @include mobile {
    max-width: 100%;
  }

  .header-c1 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    position: relative;
    height: 50px;
    border-bottom: 1px solid #d2d2d2;

    .button-wrapper {
      @include mobile {
        margin-left: 30px;
      }
    }

    .action-btn {
      width: 25px;
      height: 25px;
      margin: 13px;
      cursor: pointer;
    }

    .header-title {
      margin: 0 auto;
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 20px;
      color: #000000;
      align-items: center;
      display: flex;

      .display-name {
        font-size: 15px;
      }
    }

    .close-btn {
      cursor: pointer;
      box-sizing: border-box;
      border-radius: 50%;
      position: absolute;
      top: -14px;
      left: -11px;
      height: 24px;
      justify-content: center;
      @include mobile {
        top: 11px;
        left: 8px;
      }

      i {
        color: #d2d3e1;
        font-size: 6px !important;
        padding: 5px;
      }
    }
  }
}

.floating-chat-channel {
  background: white;
  border: rgba(30, 30, 30, 0.2) solid 1px;
  border-top: 0;
  height: 582px;

  &-placeholder {
    box-shadow: -3px -2px 20px 4px #0000001f;
    border: rgba(30, 30, 30, 0.2) solid 1px;
    height: 582px;
    width: 360px;
    background: white;
  }
}

.opened {
  transition: right 2s;
  right: 0;
}

.closed {
  right: -360px;
  transition: right 2s;
}
</style>
