<template>
  <div class="patient__allergy-wrap">
    <v-chip
      v-for="allergy in patientAllergies"
      :key="allergy.id"
      class="patient__allergy-chip"
      label
      @click="deleteItem(allergy.id)"
    >
      {{ allergy.name }}
      <v-icon v-if="editable" class="muted" color="primarylight1" small>fa-times</v-icon>
    </v-chip>
  </div>
</template>

<script>
import { mapActions as mapPiniaActions, mapState as mapPiniaGetters } from "pinia";
import { mapActions, mapState } from "pinia";

import { useAllergiesStore } from "@/pinia-store/allergies";
import { useAuthStore } from "@/pinia-store/auth";
import { useAutoSaveStore } from "@/pinia-store/autoSave";

export default {
  name: "PatientAllergiesView",
  props: {
    editable: {
      default: true,
      type: Boolean,
    },
    className: {},
    patientId: {},
    largeLayout: {
      default: false,
      type: Boolean,
    },
  },
  components: {},
  computed: {
    ...mapState(useAuthStore, ["uid"]),
    ...mapPiniaGetters(useAllergiesStore, ["patientAllergies"]),
  },
  methods: {
    ...mapActions(useAutoSaveStore, ["startSave", "endSave"]),
    ...mapPiniaActions(useAllergiesStore, ["removePatientAllergy"]),
    async deleteItem(id) {
      if (!this.editable) return;
      this.startSave();
      await this.removePatientAllergy(id);
      this.endSave();
    },
  },
};
</script>

<style lang="scss">
.outlined-btn__green {
  color: var(--v-success-base);

  &:not(:disabled) .v-btn__content {
    color: var(--v-success-base);
  }
}

.patient__allergy-chip {
  background: #eeeff7 !important;
}
</style>
