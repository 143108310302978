<template>
  <v-dialog :value="dialog" width="500" @click:outside="closeDialog">
    <v-card>
      <div class="dialog__head px-7 pt-7 d-flex justify-space-between align-center">
        <v-card-title class="dialog__head__title">Reset your availability</v-card-title>
        <v-icon class="pa-2" size="medium" @click="closeDialog">mdi-close</v-icon>
      </div>
      <v-container class="px-7">
        <p>
          Resetting your availability will remove all future preferences set beyond today' date. This cannot be undone.
        </p>
        <p>Do you want to delete all future preferences?</p>
      </v-container>
      <v-card-actions class="justify-end pr-7 py-5">
        <OutlinedButton :text="$t('general.dialog.cancel')" color="danger" @onClick="closeDialog" />
        <PrimaryButton style="background: #e53935 !important" text="Reset calendar" @onClick="onResetClick" />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import moment from "moment-timezone";
import { mapActions, mapState } from "pinia";

import OutlinedButton from "@/components/uikit/OutlinedButton";
import PrimaryButton from "@/components/uikit/PrimaryButton";
import { useSlotsStore } from "@/pinia-store/slots";

export default {
  name: "Reset",
  props: ["dialog", "closeDialog"],
  computed: {
    ...mapState(useSlotsStore, ["customAvailabilities"]),
  },
  components: {
    OutlinedButton,
    PrimaryButton,
  },
  methods: {
    ...mapActions(useSlotsStore, ["setCustomAvailabilities"]),
    onResetClick() {
      const today = moment().format("YYYY-MM-DD");
      const newCustomAvailabilities = this.customAvailabilities.filter((availability) => availability.date === today);
      this.setCustomAvailabilities(newCustomAvailabilities);
      this.closeDialog();
    },
  },
};
</script>

<style scoped></style>
