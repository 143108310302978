
import * as AdaptiveCards from "adaptivecards";
import Vue from "vue";

export default Vue.extend({
  name: "MessageWithVisitNoteReason",
  props: {
    message: Object,
    responseDelegate: Function,
    isArchived: {
      type: Boolean,
      required: true,
    },
  },
  data: () => ({
    value: [] as string[],
    renderedCard: Object as unknown as HTMLElement,
    adaptiveCards: new AdaptiveCards.AdaptiveCard(),
    anySelected: false,
  }),

  methods: {
    action(val: string) {
      this.$emit("action", val);
    },
    setValue(value: string) {
      this.value.push(value);
    },
  },
});
