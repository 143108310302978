<template>
  <v-snackbar
    :class="{ snackbar: true, 'snackbar-error': type === 'error', 'snackbar-primary': type === 'primary' }"
    :timeout="!timeout ? 100000000 : timeout"
    :value="value"
    color="white"
    light
    top
    @input="onSnackbarChange"
  >
    <v-icon v-if="type === 'success'" class="snackbar-icon" color="success">mdi-check-circle</v-icon>
    <v-icon v-if="type === 'error'" class="snackbar-icon" color="error">mdi-alert-circle</v-icon>
    <v-icon v-if="type === 'primary'" class="snackbar-icon" color="primary">mdi-alert-circle</v-icon>
    {{ text }}
    <v-icon class="snackbar-icon-close" color="#A7A9C0" size="16" @click="onSnackbarChange(false)">mdi-close</v-icon>
  </v-snackbar>
</template>
<script>
export default {
  name: "Snackbar",
  props: {
    type: { default: "error", type: [String, Error] },
    text: [String, Error],
    timeout: Number,
    value: Boolean,
  },
  methods: {
    onSnackbarChange(val) {
      this.$emit("input", val);
    },
  },
};
</script>
<style lang="scss">
.v-snack--absolute {
  z-index: 999 !important;
}

.snackbar {
  z-index: 9999;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;

  color: $primaryblack2;

  &-icon {
    opacity: 1 !important;
    margin: 0 7px;

    &-close {
      position: absolute !important;
      top: 50%;
      right: 8px;
      transform: translateY(-50%);
    }
  }

  .v-snack__content {
    border-radius: 4px;
    padding-right: 30px;
  }

  &-error {
    .v-snack__content {
      border-top: 5px solid $error;
    }
  }

  &-primary {
    .v-snack__content {
      border-top: 5px solid $primary;
    }
  }
}
</style>
