import { defineStore } from "pinia";

export const useAppStore = defineStore({
  id: "app",
  state: () => ({
    layoutClass: "",
    drawer: false,
    sidebarStatus: "collapsed",
  }),
  actions: {
    setLayoutClass(payload: string) {
      this.layoutClass = payload;
    },
    setDrawer(token: boolean) {
      this.drawer = token;
    },
    setSidebarStatus(token: string) {
      this.sidebarStatus = token;
    },
  },
});
