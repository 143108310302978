<template>
  <div class="app-header">
    <div v-if="$vuetify.breakpoint.smAndDown && !this.hideNav" class="navigation">
      <v-btn class="menu" color="white" icon @click="setDrawer(!drawer)">
        <v-icon>{{ drawer ? "mdi-close" : "mdi-menu" }}</v-icon>
      </v-btn>
      <img alt class="visit-summary-header_left_logo" src="../../assets/logo/expanded.svg" />
    </div>
    <div class="sub-header-wrap">
      <v-breadcrumbs v-if="breadcrumbs.length" :items="breadcrumbs">
        <template v-slot:divider>
          <v-icon class="v-breadcrumbs__divider__icon" size="20">mdi-menu-right</v-icon>
        </template>
      </v-breadcrumbs>
      <NotificationHeader v-if="(userIsPatient || userIsPractitioner) && breadcrumbs.length" />
    </div>
  </div>
</template>
<script>
import { mapActions as mapPiniaActions, mapState as mapPiniaGetters } from "pinia";
import { mapState } from "pinia";

import NotificationHeader from "@/components/Notifications/NotificationHeader.vue";
import { useAppStore } from "@/pinia-store/app";
import { useAuthStore } from "@/pinia-store/auth";

export default {
  name: "Header",
  components: { NotificationHeader },
  data: () => ({
    breadcrumbs: [],
    hideNav: false,
  }),
  computed: {
    ...mapPiniaGetters(useAppStore, ["drawer"]),
    ...mapState(useAuthStore, ["userIsPatient", "userIsPractitioner"]),
    ...mapState(useAuthStore, ["role"]),
  },
  watch: {
    $route() {
      this.onChange();
    },
  },
  mounted() {
    this.onChange();
  },
  methods: {
    ...mapPiniaActions(useAppStore, ["setDrawer"]),
    async onChange() {
      const breadcrumbs = this.$route.meta.breadcrumbs;
      if (breadcrumbs) {
        this.breadcrumbs = breadcrumbs.map((item, index) => {
          return {
            text: this.$t(item.text),
            link: !!item.link,
            href: item.link ? `/${this.role}/${item.link}` : "",
            disabled: index + 1 === breadcrumbs.length,
          };
        });
      } else {
        this.breadcrumbs = [];
      }
      this.hideNav = this.$route.meta.hideNav;
    },
  },
};
</script>
<style lang="scss">
.app-header {
  box-shadow: 0 3px 8px rgba(238, 239, 247, 0.32);
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 6;
  background: white;

  .sub-header-wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .navigation {
    background: $primary;
    display: flex;
    justify-content: center;
    height: $header-mobile-height;
    align-items: center;

    .menu {
      position: absolute;
      left: 20px;
    }
  }

  .v-breadcrumbs {
    flex: 1;
    padding-top: 15px;
    padding-bottom: 15px;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.06);
    mix-blend-mode: normal;
  }

  .theme--light.v-breadcrumbs .v-breadcrumbs {
    &__item {
      color: $primaryblack2;
      opacity: 0.5;
      font-weight: 500;
      font-size: 13px;
      line-height: 16px;

      &--disabled {
        opacity: 0.8;
      }
    }

    &__divider {
      padding: 0 2px;

      &__icon {
        color: $primaryblack2;
        opacity: 0.5;
      }
    }
  }
}
</style>
