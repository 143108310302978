<template>
  <div class="schedule-visit-form">
    <slot name="head"></slot>

    <div class="label">
      <img :src="require('@/assets/icons/calendar-blue.svg')" alt="Date" class="label-icon" />
      Date
    </div>
    <v-skeleton-loader v-if="isLoadingSlots" type="date-picker-options" width="100%"></v-skeleton-loader>
    <DatePicker
      v-else
      :custom-dates="datesOfSlots"
      :dense="true"
      :height="50"
      :hideDetails="true"
      :inputValue="currentDateMoment"
      :isYearFirst="false"
      :maxDate="maxScheduleDate"
      :minDate="minScheduleDate"
      :value="searchDate"
      className="mb17"
      @setDate="onSearchDateChange"
    />
    <div class="label">
      <img :src="require('@/assets/icons/time-blue.svg')" alt="time" class="label-icon" />
      Time
    </div>
    <v-skeleton-loader v-if="isLoadingSlots" type="date-picker-options" width="100%"></v-skeleton-loader>
    <Select
      v-else
      :hideDetails="true"
      :items="slotOptions"
      :label="selectPlaceholder"
      :value="slotId"
      class="mb17"
      @change="onSelectSlot"
    />

    <div class="label">
      {{ $t("practitioner.schedule.choosePatient") }}
      <span class="required">*</span>
    </div>
    <v-autocomplete
      :items="patientsOptions"
      :label="$t('practitioner.schedule.choosePatientFromList')"
      :value="patientId"
      class="mb17"
      dense
      height="46"
      hide-details
      outlined
      single-line
      @change="onPatientChange"
    ></v-autocomplete>
    <div class="label">
      {{ $t("visits.chiefComplaint") }}
      <span class="required">*</span>
    </div>
    <Select
      :hideDetails="true"
      :items="chiefComplaints"
      :label="chiefComplaintLabel"
      :value="chiefComplaint"
      class="mb17"
      @change="onChiefComplaintChange"
    />
    <div class="label">
      {{ $t("visits.schedule.first.describeProblem") }}
      <span class="required">*</span>
    </div>
    <Textarea
      :height="67"
      :hideDetails="true"
      :placeholder="$t('visits.schedule.first.visitReason')"
      :value="complaintDescription"
      class="mb17"
      @change="onVisitReasonChange"
    />
    <span class="label"> Attachments </span>
    <FileRow v-for="file in files" :key="file.id" :file="file" @onEdit="onFileEdit" />
    <MediaUploader v-if="patientId" @onUploaded="onFileLoaded" />
    <MediaRename
      v-model="mediaRenameDialog"
      :file="editFile"
      @onChange="onFileChange"
      @toggleDialog="toggleRenameDialog"
    />
    <slot name="footer"></slot>
  </div>
</template>

<script>
import { sortBy } from "lodash";
import { mapActions, mapState } from "pinia";

import FileRow from "@/components/media/FileRow.vue";
import MediaRename from "@/components/media/MediaRename.vue";
import MediaUploader from "@/components/media/MediaUploader.vue";
import DatePicker from "@/components/shared/DatePicker.vue";
import Select from "@/components/uikit/Select.vue";
import Textarea from "@/components/uikit/Textarea.vue";
import { useAuthStore } from "@/pinia-store/auth";
import { useMediaStore } from "@/pinia-store/media";
import { usePractitionerVisitScheduleStore } from "@/pinia-store/practitionerVisitSchedule";

export default {
  name: "Form",
  components: { MediaRename, DatePicker, Select, Textarea, FileRow, MediaUploader },

  data() {
    return {
      mediaRenameDialog: false,
      editFile: null,
    };
  },
  computed: {
    ...mapState(useAuthStore, ["timeZone", "uid"]),
    ...mapState(usePractitionerVisitScheduleStore, [
      "sessionId",
      "maxScheduleDate",
      "minScheduleDate",
      "isLoadingSlots",
      "complaintDescription",
      "datesOfSlots",
      "files",
      "searchDate",
      "patients",
      "slotsOnSearchDate",
      "slotId",
      "patientId",
      "chiefComplaint",
    ]),
    currentDateMoment() {
      return this.$moment(this.searchDate).format("dddd, MMM DD");
    },
    patientsOptions() {
      return sortBy(
        this.patients.map(({ firstName, lastName, id }) => ({
          text: `${(firstName || "").trim()} ${lastName || ""}`,
          value: id,
        })),
        (value) => value.text,
      );
    },
    selectPlaceholder() {
      return `${this.$t("visits.schedule.first.availableTimeFor")} ${this.$moment(this.searchDate)
        .tz(this.timeZone)
        .format("dddd, MMM DD")}`;
    },
    slotOptions() {
      return this.slotsOnSearchDate.map(($slot) => ({
        ...$slot,
        text: `${this.$moment($slot.start).tz(this.timeZone).format("HH:mm A")} - ${this.$moment($slot.end)
          .tz(this.timeZone)
          .format("HH:mm A")}`,
        value: $slot.id,
      }));
    },
    chiefComplaints() {
      const chiefComplaints = this.$t("general.chiefComplaints");
      if (!chiefComplaints) {
        return [];
      }
      return Object.keys(chiefComplaints).map((key) => ({ text: chiefComplaints[key], value: key }));
    },
    chiefComplaintLabel() {
      return this.$t("practitioner.schedule.choosePatientComplaint") || "";
    },
  },
  methods: {
    ...mapActions(usePractitionerVisitScheduleStore, [
      "preloadPractitionerProfile",
      "preloadPatients",
      "getPractitionerSlots",
      "selectFistSlotFromSearchDate",
    ]),
    ...mapActions(useMediaStore, ["setUid"]),
    ...mapActions(usePractitionerVisitScheduleStore, ["setStringFieldByName", "replaceFile", "setFiles", "setSlotId"]),
    onFileLoaded(files) {
      this.setFiles(files);
    },
    toggleRenameDialog() {
      this.mediaRenameDialog = false;
    },
    onFileChange(file) {
      this.replaceFile(file);
    },
    async onSearchDateChange(value) {
      this.loading = true;
      value = this.$moment(value).format("YYYY-MM-DD");
      this.setStringFieldByName({ value, fieldName: "searchDate" });
    },
    async onSelectSlot(value) {
      this.setSlotId({ slotId: value, timeZone: this.timeZone });
    },
    onPatientChange(value) {
      this.setUid(value);

      this.setStringFieldByName({ value, fieldName: "patientId" });
    },
    onChiefComplaintChange(value) {
      this.setStringFieldByName({ value, fieldName: "chiefComplaint" });
    },
    onVisitReasonChange(value) {
      this.setStringFieldByName({ value, fieldName: "complaintDescription" });
    },
    onFileEdit(file) {
      this.editFile = file;
      this.mediaRenameDialog = true;
    },
  },
  async mounted() {
    await Promise.all([this.preloadPatients(), this.getPractitionerSlots(), this.preloadPractitionerProfile()]);
    if (!this.sessionId) {
      this.selectFistSlotFromSearchDate();
      this.setStringFieldByName({ value: this.uid, fieldName: "practitionerId" });
    }
  },
};
</script>

<style lang="scss" scoped>
.schedule-visit-form {
  padding: 23px 22px;

  .required {
    color: $error;
  }

  :deep .mb11,
  .mb11 {
    margin-bottom: 11px !important;
  }

  :deep .mb17,
  .mb17 {
    margin-bottom: 17px !important;
  }

  :deep .mt17,
  .mt17 {
    margin-top: 17px !important;
  }

  .label {
    font-weight: 500;
    font-size: 13px;
    line-height: 18px;
    color: #0f1b2d;
    display: flex;
    align-items: center;
    margin-bottom: 11px !important;

    .label-icon {
      height: 16px;
      margin-right: 19px;
    }
  }

  .value {
    cursor: pointer;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    color: #33343e;
  }
}
</style>
