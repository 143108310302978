<template>
  <v-dialog v-model="dialog" max-width="420" scrollable @click:outside="closeReminder">
    <v-card class="visit-reminder-dialog">
      <v-btn :ripple="false" class="visit-reminder-dialog_icon-close" icon text @click="closeReminder">
        <v-icon color="icon" size="large"> mdi-close</v-icon>
      </v-btn>
      <v-card-title class="visit-reminder-dialog_title"> Meeting room notification</v-card-title>
      <v-card-text class="visit-reminder-dialog_message">{{ fullName }} joined the meeting room</v-card-text>
      <v-card-actions class="mt-4 justify-end">
        <div class="mx-0 justify-end d-flex">
          <v-btn
            :ripple="false"
            class="visit-reminder-dialog_action"
            color="primary"
            depressed
            large
            @click="onReminderConfirm"
          >
            Join
          </v-btn>
        </div>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapState } from "pinia";

import join from "@/assets/sounds/visit/soon.mp3";
import { WS_MEETING_ROOMS_JOINED } from "@/eventBuses/socketEvents";
import { useAuthStore } from "@/pinia-store/auth";
import { useMeetingRoomStore } from "@/pinia-store/meetingRoom";
import { RolesEnum } from "@/types/Roles.enum";
import { routesEnum } from "@/types/Routes.enum";
import { audioVolume } from "@/utils/constants";

export default {
  name: "MeetingReminder",
  data() {
    return {
      message: "",
      item: null,
      status: null,
      dialog: false,
      titleInterval: null,
      sound: "",
    };
  },
  computed: {
    ...mapState(useAuthStore, ["role", "uid"]),
    ...mapState(useMeetingRoomStore, ["roomId"]),
    fullName() {
      if (this.role === RolesEnum.Practitioner) return this.item?.recipient?.fullName;
      return this.item?.sender?.fullName;
    },
  },
  methods: {
    ...mapActions(useMeetingRoomStore, [
      "setRoomId",
      "setIdentityId",
      "setIsModalMode",
      "setOpenedListDialog",
      "setOpenedMeetingRoom",
    ]),
    onReminderConfirm() {
      this.closeReminder();
      if (this.role === RolesEnum.Practitioner) {
        this.setRoomId(this.item.id);
        this.setIdentityId(this.uid);
        this.setIsModalMode(true);
        this.setOpenedListDialog(false);
        this.setOpenedMeetingRoom(true);
      }

      if (this.role === RolesEnum.Patient)
        this.$router.push({
          name: routesEnum.publicMeetingRoomCall,
          params: { roomId: this?.item.id, identity: this.uid },
        });
    },
    closeReminder() {
      this.dialog = false;
    },
  },
  mounted() {
    this.sockets.subscribe(WS_MEETING_ROOMS_JOINED, (data) => {
      if (this.roomId === data.id) return;
      if (this.role === RolesEnum.Practitioner && data.sentBy !== this.uid) return;
      if (this.role === RolesEnum.Patient && data?.recipient?.id !== this.uid) return;

      this.status = data.status;
      this.item = data;
      let audio;
      audio = new Audio(join);
      this.dialog = true;
      audio.volume = audioVolume;
      audio.play();
    });
  },
};
</script>

<style lang="scss" scoped>
.visit-reminder-dialog {
  position: relative;

  &_icon-close {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 26px;
    height: 26px;
    border: 1px solid #e7e8f2;
    border-radius: 50%;
  }

  &_title {
    font-size: 25px !important;
  }

  &_message {
    font-size: 14px;
  }

  &_action {
    font-size: 16px;
  }
}
</style>
