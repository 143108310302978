import { defineStore } from "pinia";

export interface RespiratoryRateOrderState {
  formOpened: boolean;
  form: Record<string, any>;
}

export const useRespiratoryRateOrderStore = defineStore({
  id: "respiratoryRateOrder",
  state: (): RespiratoryRateOrderState => ({
    formOpened: false,
    form: {},
  }),
  actions: {
    changeFormField(payload: { field: string | number; value: any }) {
      this.form[payload.field] = payload.value;
    },
    fillForm(payload: any) {
      this.form = payload;
    },
    clearForm() {
      this.form = {};
    },
    toggleFormOpened() {
      this.formOpened = !this.formOpened;
    },
  },
});
