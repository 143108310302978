<template>
  <div class="media-list">
    <v-card class="mx-auto">
      <v-progress-linear v-if="isLoading" class="mt-5" color="primary" indeterminate />
      <v-list three-line>
        <template v-for="file in files">
          <v-list-item :key="file.id" class="media-list__item">
            <v-list-item-avatar class="media-list__image" rounded="sm" size="120">
              <v-img :src="file.url" @click="show(file.url)"></v-img>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>
                {{ file.name }}
              </v-list-item-title>
              <v-list-item-subtitle>
                {{ createdData(file.createDate) }}
                <v-icon>mdi-circle-small</v-icon>
                {{ file.creator.displayName }}
                <v-icon>mdi-circle-small</v-icon>
                {{ file.mimetype }}
              </v-list-item-subtitle>
              <v-list-item-subtitle>{{ file.mimetype }}</v-list-item-subtitle>
              <v-list-item-subtitle>{{ "File size: " + file.size + " bit" }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-list>
    </v-card>
    <Confirm
      v-if="fileUrl"
      :dialog="preview"
      :hide-confirm="true"
      max-width="90%"
      text=""
      title=""
      width="1200px"
      @toggleDialog="preview = false"
    >
      <PdfPreview :fileUrl="fileUrl" />
    </Confirm>
  </div>
</template>

<script>
import { mapActions, mapState } from "pinia";

import PdfPreview from "@/components/media/PdfPreview.vue";
import Confirm from "@/components/shared/Confirm.vue";
import { useAuthStore } from "@/pinia-store/auth";
import { useMediaStore } from "@/pinia-store/media";
import { RolesEnum } from "@/types/Roles.enum";

export default {
  name: "MediaList",
  components: { Confirm, PdfPreview },
  props: {
    disableInitialFetch: {
      default: false,
      type: Boolean,
    },
  },
  data: () => {
    return {
      loading: false,
      preview: false,
      fileUrl: "",
    };
  },
  methods: {
    ...mapActions(useMediaStore, ["setUid"]),
    ...mapActions(useMediaStore, ["fetchFiles"]),
    show(image) {
      if (image.endsWith(".pdf")) {
        this.fileUrl = image;
        this.preview = true;
        return;
      }
      this.$viewerApi({
        images: [image],
      });
    },
    createdData(date) {
      let momentDate = this.$moment(date).tz(this.timeZone);
      return date && !momentDate.isValid() ? momentDate.fromNow() : momentDate.format("dd MMMM yyyy");
    },
  },
  show(image) {
    this.$viewerApi({
      images: [image],
    });
  },
  computed: {
    ...mapState(useMediaStore, ["files", "uid", "isLoading"]),
    ...mapState(useAuthStore, ["role", "timeZone"]),
    isPatient() {
      return this.role === RolesEnum.Patient;
    },
  },
  async mounted() {
    if (!this.disableInitialFetch) await this.fetchFiles();
  },
};
</script>

<style lang="scss" scoped>
.media-list {
  overflow: auto;

  &__image {
    cursor: pointer;
    width: 125px;
    aspect-ratio: 1;
  }

  &__item {
    padding-left: 0;
    padding-right: 0;
    cursor: pointer;
  }

  .v-card__title {
    line-height: 1;
    padding: 8px 16px;
  }
}
</style>
