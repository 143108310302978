<template>
  <div :class="`stepper ${className || ''} ${compact && 'compact-view'}`">
    <v-progress-linear :value="progress" color="success" height="2"></v-progress-linear>
    <v-row class="stepper_row ma-0 flex-nowrap mobileButtonStyle justify-center align-center">
      <v-col class="padding12_20">
        <v-btn
          class="rounded-circle buttonRound d-flex justify-center"
          elevation="2"
          style="transform: rotate(-180deg)"
          @click="stepFunc(false)"
        >
          <img src="../../assets/icons/right.png" />
        </v-btn>
      </v-col>
      <v-col class="col-7 padding12_20">
        <template v-for="(item, index) in texts">
          <div v-if="index.split('_')[1].toString() === stepData.toString()" :key="item">
            <v-col
              v-if="(!$vuetify.breakpoint.xs && !$vuetify.breakpoint.sm) || +index.split('_')[1] === stepData"
              :md="md"
              class="stepper_step relative ma-auto"
              col
              cols="12"
              style="max-width: initial"
            >
              <div
                :style="[
                  step.toString() === index.split('_')[1].toString()
                    ? { color: '#2FCF6F !important' }
                    : { color: '#91909C !important' },
                ]"
                class="ma-auto fontWeight500 fontSize14"
              >
                {{ index | keyFilter }}. {{ item }}
              </div>
            </v-col>
          </div>
        </template>
      </v-col>
      <v-col class="d-flex padding12_20 justify-end">
        <v-btn class="rounded-circle buttonRound d-flex justify-center" elevation="2" @click="stepFunc(true)">
          <img src="../../assets/icons/right.png"
        /></v-btn>
      </v-col>
    </v-row>

    <v-row class="stepper_row mt-0 noteButtonStyle">
      <template v-for="(item, index) in texts">
        <v-col
          v-if="(!$vuetify.breakpoint.xs && !$vuetify.breakpoint.sm) || +index.split('_')[1] === step"
          :key="item"
          :md="md"
          class="stepper_step relative"
          col
          cols="12"
        >
          <!--          <v-btn-->
          <!--            class="absolute"-->
          <!--            style="left: 40px"-->
          <!--            fab-->
          <!--            small-->
          <!--            :ripple="false"-->
          <!--            text-->
          <!--            outlined-->
          <!--            color="grey"-->
          <!--            v-if="-->
          <!--              isPrevStepRequired && ($vuetify.breakpoint.xs || $vuetify.breakpoint.sm) && step === 2 && index === '_2'-->
          <!--            "-->
          <!--            @click="stepChange(1)"-->
          <!--          >-->
          <!--            <v-icon>mdi-chevron-left</v-icon>-->
          <!--          </v-btn>-->
          <div
            :class="{
              stepper_step_text: true,
              stepper_step_text_stepped: +index.split('_')[1] === step,
              stepper_step_text_border: +index.split('_')[1] === 2,
            }"
          >
            {{ index | keyFilter }}. {{ item }}
          </div>
        </v-col>
      </template>
    </v-row>
  </div>
</template>
<script>
export default {
  name: "Stepper",
  data: () => ({
    stepData: 1,
  }),
  watch: {
    step: function () {
      this.stepData = this.step;
    },
  },
  methods: {
    stepFunc(bool) {
      if (!bool && this.stepData > 1) {
        this.stepData--;
      } else if (bool && this.stepData < Object.keys(this.texts).length) {
        this.stepData++;
      }
    },
  },
  props: {
    compact: {
      type: Boolean,
      default: () => false,
    },
    step: Number,
    texts: Object,
    className: String,
    isPrevStepRequired: Boolean,
    stepChange: Function,
  },
  computed: {
    progress() {
      return (this.step * 100) / Object.keys(this.texts).length;
    },
    md() {
      return 12 / Object.keys(this.texts).length;
    },
  },
  filters: {
    keyFilter(val) {
      if (val) {
        return val.replace("_", "");
      }
      return val;
    },
  },
};
</script>
<style lang="scss">
.stepper {
  border-bottom: 1px solid $primarylight2;
  height: 67px;

  .fontWeight500 {
    font-weight: 500;
  }

  .fontSize14 {
    font-size: 14px !important;
  }

  .buttonRound {
    padding: 0px !important;
    //margin: 0px 0px 0px 20px !important;
    min-width: 36px !important;
    min-height: 36px;
    text-align: center;
    display: flex;
    background: #ffffff !important;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.12) !important;
    justify-content: flex-end;
  }

  .padding12_20 {
    padding: 12px 20px 12px 20px !important;
  }

  .mobileButtonStyle {
    display: none;
  }

  .noteButtonStyle {
    display: flex;
  }

  .colorGray {
    color: gray !important;
  }

  .fontSize21 {
    font-size: 21px !important;
  }

  &_row {
    height: 100%;
  }

  &.compact-view {
    .mobileButtonStyle {
      display: flex;
    }

    .noteButtonStyle {
      display: none;
    }
  }

  @media only screen and (max-width: 980px) {
    .mobileButtonStyle {
      display: flex;
    }
    .noteButtonStyle {
      display: none;
    }
  }

  &_step {
    display: flex;
    align-items: center;

    &_text {
      width: 100%;
      font-style: normal;
      font-weight: 500;
      font-size: 1rem;
      line-height: 17px;
      color: $primarylight3;
      text-align: center;

      &_stepped {
        color: $success;
      }

      &_border {
        border-left: 1px solid rgba(15, 15, 15, 0.1);
        border-right: 1px solid rgba(15, 15, 15, 0.1);
      }
    }
  }
}
</style>
