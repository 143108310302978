<template>
  <div class="chat-switch d-flex justify-center align-center" @click="click" :class="{ 'chat-switch-active': active }">
    <div class="chat-switch-box d-flex flex-column">
      <div>
        <v-icon :color="iconColor" :size="iconSize">{{ icon }}</v-icon>
      </div>
      <div class="mt-2">{{ text }}</div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";

export default Vue.extend({
  name: "SwitchItem",
  props: ["active", "icon", "iconSize", "text"],
  computed: {
    iconColor() {
      return this.active ? "primary" : "primarylight3";
    },
  },
  methods: {
    click() {
      this.$emit("click");
    },
  },
});
</script>

<style scoped lang="scss">
.chat-switch {
  width: 50%;
  height: 100%;
  cursor: pointer;
  color: $primarylight3;
  &-box {
    font-size: 13px;
    line-height: 16px;
    text-align: center;
    mix-blend-mode: normal;
  }
  &-active {
    color: $primary;
    font-weight: 500;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.06);
  }
}
</style>
