import { apiRequest } from "@/api/apiRequest";
import { CareCoordinationForm } from "@/pinia-store/careCoordination";

const create = async (body: CareCoordinationForm): Promise<{ status: boolean }> => {
  const { data } = await apiRequest.post(`/care-coordination`, body);
  return data;
};

export const CareCoordinationAPI = {
  create,
};
