import { mapState } from "pinia";
import Vue from "vue";

import { useAuthStore } from "@/pinia-store/auth";

export const timeZoneMixin = Vue.extend({
  computed: {
    ...mapState(useAuthStore, ["timeZone"]),
    gmtText() {
      return `GMT${this.$moment().tz(this.timeZone).format("Z")}`;
    },
  },
});
