export enum NotificationTypes {
  OXYGEN_SATURATION_ORDER_ADDED = "OXYGEN_SATURATION_ORDER_ADDED",
  HEART_RATE_ORDER_ADDED = "HEART_RATE_ORDER_ADDED",
  RESPIRATORY_RATE_ORDER_ADDED = "RESPIRATORY_RATE_ORDER_ADDED",
  BODY_TEMPERATURE_ORDER_ADDED = "BODY_TEMPERATURE_ORDER_ADDED",
  BLOOD_GLUCOSE_ORDER_ADDED = "BLOOD_GLUCOSE_ORDER_ADDED",
  BLOOD_PRESSURE_ORDER_ADDED = "BLOOD_PRESSURE_ORDER_ADDED",
  APPOINTMENT_ADDED = "APPOINTMENT_ADDED",
  APPOINTMENT_CANCELLED = "APPOINTMENT_CANCELLED",
  UNREAD_MESSAGE = "UNREAD_MESSAGE",
  CONVERSATION_STARTED = "CONVERSATION_STARTED",
  REQUEST_CONSULTATION = "REQUEST_CONSULTATION",
  ANY = "ANY",
}
