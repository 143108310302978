<template>
  <div class="chat">
    <v-row class="fill-height" justify="start" no-gutters>
      <v-col
        v-show="!($vuetify.breakpoint.xsOnly && getCurrentChannel)"
        class="chat-c1 fill-height align-center"
        lg="3"
        md="4"
        sm="5"
        xs="12"
      >
        <div class="fill-height full-width d-inline-flex flex-column">
          <div class="chat-c1-head d-flex flex-row justify-space-between flex-shrink-0">
            <div class="d-flex justify-space-between align-center">
              <div>{{ $t("chat.title") }}</div>
            </div>
            <PrimaryButton
              v-if="loadingConversationParticipants ? true : showStartConversation"
              :disabled="!canStartConversation"
              :loading="loadingConversationParticipants"
              :text="$t('chat.startConversation')"
              class="start-btn"
              color="secondary"
              left-icon="mdi-pencil"
              size="small"
              @onClick="action('startConversation')"
            />
          </div>
          <div v-if="showChatFilter" class="d-flex chat-c3 flex-shrink-1 flex-grow-0">
            <ChatFilter
              :participants="twilioContext.listActiveUsers()"
              height="36"
              placeholder="Search"
              prepend-inner-icon="mdi-magnify"
              @filter-channels="handleFilteringRequest"
            />
          </div>

          <div class="chat-c1-list flex-grow-1">
            <ChannelsList
              v-show="true"
              :channelsList="currentChannelList"
              :loading="loading"
              :selectedChannelSid="selectedChannelSid"
              @selectChannel="selectChannel"
            />
          </div>
          <div class="chat-c1-bottom d-flex flex-shrink-0">
            <SwitchItem
              :active="activeGroup === currentGroup"
              :text="$t('chat.activeChats')"
              icon="$vuetify.icons.chatMessages"
              icon-size="20"
              @click="selectGroup(activeGroup)"
            />
            <SwitchItem
              :active="archiveGroup === currentGroup"
              icon="$vuetify.icons.chatArchive"
              icon-size="18"
              text="Past Conversations"
              @click="selectGroup(archiveGroup)"
            />
          </div>
        </div>
      </v-col>
      <v-col
        v-show="!($vuetify.breakpoint.xsOnly && !currentChannel)"
        class="chat-c2 fill-height"
        lg="9"
        md="8"
        sm="7"
        xs="12"
      >
        <Preview v-if="!currentChannel" :text="$t('chat.preview.noChatSelected')" />
        <ChatChannel
          v-else
          :key="selectedChannelSid"
          :active="!!currentChannel"
          :channel="currentChannel"
          @action="action"
          @back="setCurrentChannelNull"
        />
      </v-col>
    </v-row>
    <ChatVisitNote />
  </div>
</template>

<script>
import { mapActions, mapState } from "pinia";

import { PatientsApi } from "@/api/patients";
import ChatVisitNote from "@/components/Chat/ChatVisitNote/ChatVisitNote.vue";
import PrimaryButton from "@/components/uikit/PrimaryButton.vue";
import TwilioContext from "@/context/twilioContext/TwilioContext";
import { snackBarEventBus, snackBarEventName } from "@/eventBuses/snackBar.eventBus";
import { useAuthStore } from "@/pinia-store/auth";
import { useChatStore } from "@/pinia-store/chat";
import { usePatientStore } from "@/pinia-store/patient";
import { usePatientsStore } from "@/pinia-store/patients";
import { useProfileSettingsStore } from "@/pinia-store/profileSettings";
import { useRegistrarsStore } from "@/pinia-store/registrars";

import ChatChannel from "./Channel/ChatChannel.vue";
import Preview from "./Channel/Preview.vue";
import ChannelsList from "./ChannelsList/List.vue";
import ChatFilter from "./ChatFilter/ChatFilter";
import DialogNamesEnum from "./Enums/dialogNamesEnum";
import SwitchItem from "./SwitchItem.vue";

export default {
  name: "Chat",
  components: {
    PrimaryButton,
    ChatVisitNote,
    ChatFilter,
    ChatChannel,
    ChannelsList,
    SwitchItem,
    Preview,
  },
  inject: ["injectable"],
  data: () => ({
    message: "",
    header: "Chat",
    currentChannelList: [],
    selectedChannel: null,
    activeGroup: "active",
    archiveGroup: "archive",
    finishLoading: false,
    twilioContext: TwilioContext | null,
    availableMembers: [],
    practitionersFullData: [],
    userLoading: false,
  }),
  async created() {
    this.twilioContext = this.injectable.twilioContext;

    this.usersLoading = true;
    try {
      if (this.userIsPatient) {
        this.usersLoading = false;
      } else if (this.userIsPractitioner) {
        await this.getPractitionerSettings();
        await this.getAllByPractitionerId();
      } else if (this.userIsRegistrar) {
        await this.getRegistrarPractitioners(this.uid);
        this.registrarChatParticipants = this.registrarPractitioners;
        const patients = await Promise.all(
          this.registrarPractitioners.map(async (practitioner) => {
            return await PatientsApi.getAllByPractitionerId(practitioner.id);
          }),
        );
        patients.forEach((item) => {
          this.registrarChatParticipants.push(...item);
        });
      } else {
        snackBarEventBus.$emit(snackBarEventName, {
          message: "User role is not appropriate for that operation!",
          type: "error",
        });
      }
    } catch (err) {
      console.error(err);
    } finally {
      this.usersLoading = false;
    }
  },
  async mounted() {
    await this.init();
  },
  methods: {
    ...mapActions(useChatStore, ["setUnreadChannels", "setCurrentGroup"]),
    ...mapActions(useChatStore, ["getStartConversationParticipants"]),
    ...mapActions(usePatientsStore, ["getAllByPractitionerId"]),
    ...mapActions(usePatientStore, ["getPatientProfile"]),
    ...mapActions(useProfileSettingsStore, ["getPatientSettings", "getPractitionerSettings"]),
    ...mapActions(useRegistrarsStore, ["getRegistrarPractitioners"]),

    async startConversation() {
      this.finishLoading = true;
      const chatCreateRequest = {
        userTimeZone: this.timeZone,
        participantProfile: this.patientSettings.practitioner,
        isFloatingChat: false,
        dialogType: DialogNamesEnum.MedicalDialog,
        userProfile: this.patientSettings,
        isAnonymous: false,
      };
      await this.injectable.twilioContext
        .createNewConversation(chatCreateRequest)
        .catch(() => (this.finishLoading = false));
      this.finishLoading = false;
    },
    handleFilteringRequest(payload) {
      if (this.currentGroup === "active") {
        this.currentChannelList = this.injectable.twilioContext.channelRepository.getFilteredActiveChannels(payload);
      } else if (this.currentGroup === "archive") {
        this.currentChannelList = this.injectable.twilioContext.channelRepository.getFilteredArchivedChannels(payload);
      }
    },
    selectChannel(channel) {
      this.twilioContext.setCurrentChannel(channel);
    },
    async setCurrentChannelNull() {
      this.twilioContext.setCurrentChannel(null);
    },
    async init() {
      this.currentChannelList = this.twilioContext.channelRepository.getFilteredActiveChannels();
      this.availableMembers = this.twilioContext.listActiveUsers();
      await this.getStartConversationParticipants();
    },
    selectGroup(val) {
      this.setCurrentGroup(val);
    },
    action(val) {
      this.$emit("action", val);
    },
  },
  computed: {
    ...mapState(useAuthStore, [
      "uid",
      "timeZone",
      "role",
      "userIsPatient",
      "userIsPractitioner",
      "userIsRegistrar",
      "userIsAdmin",
    ]),
    ...mapState(useProfileSettingsStore, ["patientSettings", "practitionerSettings"]),
    ...mapState(useChatStore, [
      "currentGroup",
      "getCurrentChannel",
      "currentChannel",
      "getCurrentFloatingChannel",
      "showStartConversation",
      "loadingConversationParticipants",
    ]),
    ...mapState(useRegistrarsStore, ["registrarPractitioners"]),
    showChatFilter() {
      return this.userIsPractitioner ? true : this.archiveGroup === this.currentGroup;
    },
    selectedChannelSid() {
      return this.currentChannel?.sid;
    },
    canStartConversation() {
      return !this.finishLoading && !this.userLoading && !this.injectable.twilioContext.channelsLoading;
    },
    loading() {
      return this.injectable.twilioContext.channelsLoading;
    },
  },
  watch: {
    currentGroup() {
      if (this.currentGroup === "active") {
        this.currentChannelList = this.injectable.twilioContext.channelRepository.getFilteredActiveChannels();
      } else if (this.currentGroup === "archive") {
        this.currentChannelList = this.injectable.twilioContext.channelRepository.getFilteredArchivedChannels();
      }
    },
  },
  deactivated() {
    if (!this.getCurrentFloatingChannel)
      this.injectable.twilioContext.setCurrentFloatingChannel(this.getCurrentChannel);
    this.setCurrentChannelNull();
  },
};
</script>

<style lang="scss" scoped>
.chat {
  padding: 0;
  background: #ffffff;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.08);
  border-radius: 3px;
  overflow: hidden;

  &-c1 {
    border-right: 1px solid #efeff0;

    &-head {
      font-weight: 500;
      font-size: 20px;
      color: $primaryblack2;
      padding: 25px 16px;
      border-bottom: 1px solid #efeff0;

      :deep .start-btn {
        text-transform: none;
        background: $primarylight1 !important;
        color: $primary;
        padding: 0 10px !important;

        i {
          font-size: 14px !important;
        }
      }
    }

    &-list {
      overflow-y: scroll;
      overflow-x: hidden;
    }

    &-bottom {
      height: 62px;
      border-top: 1px solid #efeff0;
    }
  }

  &-c2 {
    border-right: 1px solid #efeff0;
    border-bottom: 1px solid #efeff0;
  }

  &-c3 {
    width: 100%;
    border-right: 1px solid #efeff0;
    border-bottom: 1px solid #efeff0;
  }
}

.preview-text {
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: $primaryblack2;
  opacity: 0.75;
}
</style>
