import { defineStore } from "pinia";

import { PatientsApi } from "@/api/patients";
import { useAuthStore } from "@/pinia-store/auth";
import { GetPatientQuery, PostPatientBody, ReAssignPatient } from "@/types";

interface PatientsState {
  patients: any[];
  practitionerOwnPatients: any[];
  practitioners: any[];
  searchValue: string;
  filter: {
    firstName: string;
    lastName: string;
    gender: string;
    email: string;
    medicalRecord: string;
    birthDate: null | string;
  };
}

export const usePatientsStore = defineStore({
  id: "patients",
  state: () => ({
    patients: [],
    practitionerOwnPatients: [],
    practitioners: [],
    searchValue: "",
    filter: {
      firstName: "",
      lastName: "",
      gender: "",
      email: "",
      medicalRecord: "",
      birthDate: null,
    },
  }),
  actions: {
    async setPatients(payload: any) {
      this.patients = payload;
    },
    async setPractitioners(payload: any) {
      this.practitioners = payload;
    },
    async setSearch(payload: any) {
      this.searchValue = payload;
    },
    async setAdvancedSearch(payload: any) {
      this.filter = payload;
    },
    async getAllPatients(payload?: GetPatientQuery) {
      this.patients = await PatientsApi.getPatients(payload);
    },
    async signUpPatient(payload: PostPatientBody) {
      const formData = new FormData();
      formData.append("inviteId", payload.inviteId);
      if (payload.password && payload.password.length) formData.append("password", payload.password);
      formData.append("patient[firstName]", payload.patient.firstName);
      formData.append("patient[lastName]", payload.patient.lastName);
      if (payload.patient.city) formData.append("patient[city]", payload.patient.city);
      if (payload.patient.postalCode) formData.append("patient[postalCode]", payload.patient.postalCode);
      formData.append("patient[gender]", payload.patient.gender);
      formData.append("patient[birthDate]", payload.patient.birthDate.toString());
      if (payload.patient.homePhoneNumber.length)
        formData.append("patient[homePhoneNumber]", payload.patient.homePhoneNumber);
      formData.append("patient[phoneNumber]", payload.patient.phoneNumber);
      formData.append("patient[state]", payload.patient.state);
      formData.append("patient[address]", payload.patient.address);
      if (payload.patient.idFileDriverLicenseFront?.size > 2)
        formData.append("patient[idFileDriverLicenseFront]", payload.patient.idFileDriverLicenseFront);
      if (payload.patient.idFileDriverLicenseBack?.size > 2)
        formData.append("patient[idFileDriverLicenseBack]", payload.patient.idFileDriverLicenseBack);

      await PatientsApi.signUpPatient(formData);
    },
    async getAllByPractitionerId(id?: string) {
      const authStore = useAuthStore();
      const { uid } = authStore;
      this.practitionerOwnPatients = await PatientsApi.getAllByPractitionerId(id || uid);
    },
    async reAssignPatient(payload: ReAssignPatient) {
      await PatientsApi.updatePractitioner(payload.patientId, payload.practitionerId);
    },
    async getAllPractitioners() {
      this.practitioners = await PatientsApi.getPractitioners();
    },
  },
  getters: {
    advancedSearch(state) {
      return state.filter;
    },
  },
});
