<template>
  <Confirm
    :dialog="recordingOpened"
    :disabled-save="true"
    :hide-cancel="true"
    :hide-confirm="true"
    :loading="false"
    confirm-text="Send"
    max-width="90%"
    title="Speech to Text"
    width="560px"
    @confirm="onClose"
    @toggleDialog="onClose"
  >
    <div class="root-speech-to-text">
      <v-card-text class="d-flex justify-center align-center gap-2">
        <v-btn v-if="!isRecording" height="44" icon width="44" @click="startRecording">
          <v-icon size="36"> mdi-microphone</v-icon>
        </v-btn>
        <v-btn
          v-if="isRecording && isPaused"
          class="resumeRecording"
          height="44"
          icon
          width="44"
          @click="resumeRecording"
        >
          <v-icon color="white" size="36"> mdi-microphone</v-icon>
        </v-btn>
        <v-btn v-if="isRecording && !isPaused" class="pulse" height="44" icon width="44" @click="pauseRecording">
          <v-icon color="white" size="36">mdi-pause</v-icon>
        </v-btn>
        <v-btn :disabled="!isRecording" icon @click="stopRecording">
          <v-icon>mdi-stop</v-icon>
        </v-btn>
      </v-card-text>
      <!--          <v-card-text class="d-flex justify-center pt-0">-->
      <!--            <p v-if="!isRecording && !isPaused" class="state-text">Start recording</p>-->
      <!--            <p v-if="isRecording && !isPaused" class="state-text">Recording...</p>-->
      <!--            <p v-if="isRecording && isPaused" class="state-text">Pause</p>-->
      <!--          </v-card-text>-->
      <div v-if="isRecording || isPaused" class="d-flex justify-center">
        <v-card-text class="text-center heading-2">
          {{ recordingDuration }}
        </v-card-text>
      </div>
      <div v-if="audioSrc && showAudioRecorder">
        <v-card-text :class="isLoadedMetadata ? '' : 'd-invisible'" class="d-flex justify-center pt-3">
          <audio ref="audioPlayer" :src="audioSrc" controls preload="auto" @loadedmetadata="onAudioLoadedMetadata" />
        </v-card-text>
        <v-card-text class="d-flex justify-center pt-3 gap-2">
          <PrimaryButton :loading="loadingResult" text="Transcribe" @onClick="onTranscribe" />
          <OutlinedButton v-if="transcriptionResult.length" text="Copy text" @onClick="onCopy" />
        </v-card-text>
        <v-card-text v-if="transcriptionResult.length" class="d-flex justify-center pt-3 align-center">
          <Textarea v-model="transcriptionResult" />
        </v-card-text>
      </div>
    </div>
  </Confirm>
</template>

<script>
import { mapActions, mapState } from "pinia";

import { VoIPApi } from "@/api/voip";
import Confirm from "@/components/shared/Confirm.vue";
import OutlinedButton from "@/components/uikit/OutlinedButton.vue";
import PrimaryButton from "@/components/uikit/PrimaryButton.vue";
import Textarea from "@/components/uikit/Textarea.vue";
import { snackBarEventBus, snackBarEventName } from "@/eventBuses/snackBar.eventBus";
import { useVoipStore } from "@/pinia-store/voip";

export default {
  name: "SpeechToText",
  components: { OutlinedButton, Textarea, PrimaryButton, Confirm },
  data() {
    return {
      transcriptionResult: "",
      loadingResult: false,
      tab: null,
      items: ["Audio Recorder", "Upload file"],
      showAudioRecorder: false,
      isLoadedMetadata: false,
      isRecording: false,
      isPaused: false,
      mediaRecorder: null,
      audioChunks: [],
      audioSrc: null, // Store audio source
      recordingStartTime: null,
      lastRecordingDuration: 0,
      recordingDuration: "0:00", // Initialize duration as 0:00
    };
  },
  computed: {
    ...mapState(useVoipStore, ["recordingOpened"]),
  },
  watch: {
    recordingOpened(newVal) {
      if (newVal) {
        // this.audioSrc = null;
        // this.showAudioRecorder = false;
        if (this.isRecording || this.isPaused) {
          this.stopRecording();
        }
      } else {
        this.isRecording = false;
        this.isPaused = false;
      }
    },
  },
  methods: {
    ...mapActions(useVoipStore, ["setRecordingOpened"]),
    onCopy() {
      navigator.clipboard.writeText(this.transcriptionResult);
      snackBarEventBus.$emit(snackBarEventName, { message: "Text copied to clipboard", type: "success" });
    },
    async onTranscribe() {
      this.loadingResult = true;
      try {
        const formData = new FormData();
        formData.append("audio", new Blob(this.audioChunks, { type: "audio/wav" }));
        const data = await VoIPApi.voiceToText(formData);
        this.transcriptionResult = data.text;
        if (!data.isDetectedText) {
          snackBarEventBus.$emit(snackBarEventName, {
            message: "Text not detected from audio",
            type: "info",
          });
        }
      } catch (e) {
        console.error(e);
      } finally {
        this.loadingResult = false;
      }
    },
    onClose() {
      this.setRecordingOpened(false);
    },
    onAudioLoadedMetadata() {
      this.isLoadedMetadata = true;
    },
    async startRecording() {
      this.audioChunks = [];

      this.showAudioRecorder = false;
      this.isLoadedMetadata = false;
      this.audioSrc = null;

      let stream;

      if (navigator.mediaDevices.getUserMedia) {
        // Use standard API
        stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      } else if (navigator.getUserMedia) {
        // Handle older versions of the API
        stream = await new Promise((resolve, reject) => {
          navigator.getUserMedia(
            { audio: true },
            (stream) => resolve(stream),
            (error) => reject(error),
          );
        });
      } else {
        throw new Error("getUserMedia is not supported in this browser");
      }

      this.mediaRecorder = new MediaRecorder(stream);

      this.mediaRecorder.ondataavailable = (event) => {
        if (event.data.size > 0) {
          this.audioChunks.push(event.data);
        }
      };

      this.mediaRecorder.onstop = () => {
        const audioBlob = new Blob(this.audioChunks, { type: "audio/wav" });
        this.audioSrc = URL.createObjectURL(audioBlob); // Update audio source

        this.showAudioRecorder = true;
      };

      this.mediaRecorder.start();
      this.isRecording = true;
      this.isPaused = false;

      this.recordingStartTime = new Date(); // Record the start time
      this.updateRecordingDuration();
    },

    pauseRecording() {
      this.mediaRecorder.pause();
      this.isPaused = true;
      this.recordingStartTime = null;
    },

    resumeRecording() {
      this.mediaRecorder.resume();
      this.isPaused = false;
      this.recordingStartTime = new Date(new Date() - this.lastRecordingDuration * 1000);
      this.updateRecordingDuration();
    },
    updateRecordingDuration() {
      if (this.isRecording && !this.isPaused) {
        const currentTime = new Date();
        const durationInSeconds = Math.floor((currentTime - this.recordingStartTime) / 1000);
        const minutes = Math.floor(durationInSeconds / 60);
        const seconds = durationInSeconds % 60;
        this.recordingDuration = `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
        this.lastRecordingDuration = durationInSeconds;
        requestAnimationFrame(this.updateRecordingDuration);
      }
    },
    stopRecording() {
      this.mediaRecorder.stop();
      this.isRecording = false;
      this.isPaused = false;
      this.recordingStartTime = null;
      this.recordingDuration = "0:00";
      this.lastRecordingDuration = 0;
      // Release the microphone stream
      const tracks = this.mediaRecorder.stream.getTracks();
      tracks.forEach((track) => track.stop());

      // Reset the mediaRecorder instance
      this.mediaRecorder = null;
    },
  },
};
</script>
<style lang="scss">
.root-speech-to-text {
  min-height: 300px;
}

.d-invisible {
  visibility: hidden;
}

.state-text {
  font-size: 1.2rem;
}

$redColor: rgba(255, 107, 100, 1);

.pulse {
  animation: pulse 1.2s infinite;
  background: $redColor;
}

.resumeRecording {
  background: $redColor;
}

@keyframes pulse {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(255, 107, 100, 0.6);
    box-shadow: 0 0 0 0 rgba(255, 107, 100, 0.6);
  }
  70% {
    -moz-box-shadow: 0 0 0 10px rgba(255, 107, 100, 0);
    box-shadow: 0 0 0 10px rgba(255, 107, 100, 0);
  }
  100% {
    -moz-box-shadow: 0 0 0 0 rgba(255, 107, 100, 0);
    box-shadow: 0 0 0 0 rgba(255, 107, 100, 0);
  }
}
</style>
