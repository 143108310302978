<template>
  <div>
    <Confirm
      v-if="!successDialog"
      :cancelText="$t('general.dialog.cancel')"
      :confirmText="$t('patients.import.chooseFile')"
      :dialog="value"
      :loading="loading"
      :text="$t('patients.import.text')"
      :title="$t('patients.import.title')"
      @confirm="chooseFile"
      @toggleDialog="toggleDialog"
    >
      <v-container class="no-padding-bottom no-padding-top">
        <input ref="uploader" accept=".csv" class="d-none" type="file" @change="onFileChanged" />
      </v-container>
    </Confirm>
    <SuccessDialog
      :dialog="successDialog"
      :text="$t(`patients.import.success`, { amount: imported.invited.length })"
      @on-close="successDialog = false"
    />
  </div>
</template>
<script>
import { mapActions } from "pinia";

import Confirm from "@/components/shared/Confirm";
import SuccessDialog from "@/components/shared/SuccessDialog";
import { snackBarEventBus, snackBarEventName } from "@/eventBuses/snackBar.eventBus";
import { useInvitesStore } from "@/pinia-store/invites";

export default {
  name: "ImportPatientDialog",
  props: ["value"],
  components: {
    Confirm,
    SuccessDialog,
  },
  data: () => ({
    successDialog: false,
    loading: false,
    imported: {
      invited: [],
      skipped: [],
    },
  }),
  methods: {
    ...mapActions(useInvitesStore, ["importPatients", "getAllInvites"]),
    toggleDialog() {
      this.$emit("toggleDialog");
    },
    toggleSuccessDialog() {
      this.successDialog = !this.successDialog;
    },
    async onFileChanged(e) {
      const file = e.target.files[0];
      const reader = new FileReader();
      reader.onload = (e) => {
        this.localAvatar = e.target.result;
      };
      reader.readAsDataURL(file);

      try {
        this.loading = true;
        this.imported = await this.importPatients(file);
        this.toggleDialog();
        this.toggleSuccessDialog();
        await this.getAllInvites();
        this.$emit("success");
      } catch (err) {
        console.error(err);
        snackBarEventBus.$emit(snackBarEventName, { message: err.message || err, type: "error" });
      } finally {
        if (this.$refs.uploader) {
          this.$refs.uploader.value = "";
        }
      }
      this.loading = false;
    },
    async chooseFile() {
      this.$refs.uploader.click();
    },
  },
};
</script>
